import React from "react";
import * as Yup from "yup";
import { IPaymentLink } from "@clearabee/api-schemas";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Modal,
  theme,
  Text,
  Button,
  Form,
  Field,
  Input,
} from "@clearabee/ui-library";
import { validEmail } from "validation/common";
import { LoadingOverlay } from "components/common/components";

interface ResendPaymentLinkModalProps {
  resendPaymentLink: IPaymentLink | null;
  setResendPaymentLink: (value: IPaymentLink | null) => void;
  isLoading?: boolean;
  onResendPaymentLink: ({
    token,
    email,
  }: {
    token: string;
    email?: string;
  }) => void;
}

export const ResendPaymentLinkModal = ({
  resendPaymentLink,
  setResendPaymentLink,
  onResendPaymentLink,
  isLoading = false,
}: ResendPaymentLinkModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { orderRef, basketToken: token, email } = resendPaymentLink || {};

  return (
    <Modal
      width={450}
      modalVisible={!!resendPaymentLink}
      onClose={() => setResendPaymentLink(null)}
      styles={{
        padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
        paddingBottom: theme.spacing.xlarge,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.large} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.large,
        },
      }}
    >
      {/* MODAL LOADING OVERLAY */}
      {isLoading && (
        <LoadingOverlay
          iconSize="xlarge2"
          backgroundLoadingContainerStyles={{
            borderRadius: theme.spacing.small,
          }}
        />
      )}
      <Form
        initialValues={{ email }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(validEmail),
        })}
        onSubmit={({ email }) => {
          onResendPaymentLink({
            token: token || "",
            email,
          });
        }}
      >
        {({}) => (
          <Box className="mt-8">
            <Heading color="brand" level={4}>
              {translate("paymentLink.headings.resendPaymentLink")}
            </Heading>
            <Text color="brand" className="font-bold">
              ({orderRef || ""})
            </Text>
            <Field
              styles={{
                flex: 1,
                textAlign: "left",
              }}
              name="email"
              label={translate("paymentLink.form.labels.email")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={translate("paymentLink.form.placeholders.email")}
                />
              )}
            </Field>
            <Box className="flex justify-center mt-8">
              <Button size="small" color="accent" type="submit">
                {translate("paymentLink.buttons.resend")}
              </Button>
            </Box>
          </Box>
        )}
      </Form>
    </Modal>
  );
};
