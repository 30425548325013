import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  useJsApiLoader,
  GoogleMap,
  GoogleMapProps,
  LoadScriptProps,
  MarkerClusterer,
  MarkerClustererProps,
} from "@react-google-maps/api";
import {
  Box,
  Heading,
  theme,
  Form,
  Field,
  Input,
  Button,
  Icon,
  Panel,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { statusColors } from "helpers/statusColors";
import { Job } from "models";
import { JobPin, VehiclePin } from "./components";
import { useTranslation } from "react-i18next";

const libraries: LoadScriptProps["libraries"] = ["geometry"];

export const ViewMap = (): React.ReactElement => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [translate] = useTranslation("jobs");
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD79Gc_JkcnMRPbv2X04keuLfV-mClEBiA",
    libraries,
  });

  const validationSchema = Yup.object().shape({
    date: Yup.string().matches(/^[^a-zA-Z]+$/, translate("viewMap.validDate")),
  });

  const refetchInterval = 20000;

  const {
    data: bigChangeData,
    isLoading: isLoadingBigChangeData,
    isRefetching,
    refetch,
  } = useQuery(
    "getTracking",
    async () => {
      return (await instance.jobs.getJobsFromBigChange("Live")).data.Result;
    },
    {
      // refetchInterval: refetchInterval,
      retry: false,
    },
  );

  const vehicles =
    bigChangeData?.filter(
      (vehicle: any) =>
        !!Number(vehicle?.PositionLatitude) &&
        !!Number(vehicle?.PositionLongitude),
    ) ?? [];

  const { data: jobs, isLoading: isLoadingJobs } = useQuery(
    ["jobs", selectedDate],
    async () => {
      return (
        await instance.jobs.getJobs({
          params: {
            "date:gte": selectedDate
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            "date:lte": selectedDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            limit: 3000,
          },
        })
      ).data.items;
    },
  );

  const mapOptions = useMemo(() => {
    const options: GoogleMapProps["options"] = {
      zoom: 6,
      center: {
        lat: 55,
        lng: -5,
      },
    };
    return options;
  }, []);

  const mapContainerStyles = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    [],
  );

  const jobsClustererStyles: MarkerClustererProps["styles"] = [
    {
      url:
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <circle fill="#FFBF0A" cx="10" cy="10" r="10" />
              <circle fill="#FFCF48" cx="10" cy="10" r="8" />
              <circle fill="#FFDA73" cx="10" cy="10" r="6" />
            </g>
          </svg>
          `),
      height: 50,
      width: 50,
      textColor: theme.colors.dark.base,
      fontWeight: "600",
      anchorText: [-8, -8],
      textSize: 15,
    },
  ];

  const vehiclesClustererStyles: MarkerClustererProps["styles"] = [
    {
      url:
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <circle fill="#8645EA" cx="10" cy="10" r="10" />
              <circle fill="#8F4EF3" cx="10" cy="10" r="8" />
              <circle fill="#9754FF" cx="10" cy="10" r="6" />
            </g>
          </svg>
          `),
      height: 50,
      width: 50,
      textColor: theme.colors.light.base,
      fontWeight: "600",
      anchorText: [-8, -8],
      textSize: 15,
    },
  ];

  return (
    <Box className="w-full h-screen">
      <Panel className="mb-5">
        <Heading level={3} fontSize="large" color="brand">
          {translate("viewMap.heading")}
        </Heading>
        <Form
          validationSchema={validationSchema}
          initialValues={{
            date: dayjs().format("YYYY-MM-DD"),
          }}
          onSubmit={(values) => {
            setSelectedDate(dayjs(values.date));
          }}
        >
          <Box className="flex justify-between items-center gap-5">
            <Box className="flex-grow h-24">
              <Field name="date" label="Date">
                {({ field }) => (
                  <Input.Date {...field} dateFormat="YYYY-MM-DD" collapsable />
                )}
              </Field>
            </Box>
            <Box className="-mb-6">
              <Button
                type="submit"
                size="small"
                color="accent"
                className="flex justify-center"
              >
                {isLoadingJobs ? <Icon.Loading size="small" /> : "Submit"}
              </Button>
            </Box>
          </Box>
        </Form>
        <Box className="flex justify-end">
          <Button
            size="small"
            className="flex justify-center"
            disabled={isRefetching || isLoadingBigChangeData}
            onClick={() => refetch()}
          >
            {isRefetching || isLoadingBigChangeData ? (
              <Icon.Loading size="small" />
            ) : (
              "Refresh vehicles"
            )}
          </Button>
        </Box>
      </Panel>
      {isLoaded && (
        <GoogleMap mapContainerStyle={mapContainerStyles} options={mapOptions}>
          <MarkerClusterer styles={jobsClustererStyles}>
            {(clusterer) => (
              <>
                {jobs?.map(
                  ({ addressLatLng, status, ref, addressPostcode }, index) => {
                    const markerColour =
                      theme.colors[
                        statusColors[status as Job["status"]] ?? "brand"
                      ].base;
                    return (
                      <JobPin
                        key={index}
                        clusterer={clusterer}
                        color={markerColour}
                        coordinates={{
                          lat: addressLatLng?.lat ?? 0,
                          lng: addressLatLng?.lng ?? 0,
                        }}
                        jobRef={ref}
                        status={status}
                        postcode={addressPostcode}
                      />
                    );
                  },
                )}
              </>
            )}
          </MarkerClusterer>
          <MarkerClusterer styles={vehiclesClustererStyles}>
            {(clusterer) => (
              <>
                {vehicles.map((vehicle: any, index: number) => (
                  <VehiclePin
                    key={`${index}-${vehicle.PositionLatitude}`}
                    backgroundColor={theme.colors.brand.base}
                    clusterer={clusterer}
                    color={theme.colors.light.base}
                    coordinates={{
                      lat: vehicle.PositionLatitude ?? 0,
                      lng: vehicle?.PositionLongitude ?? 0,
                    }}
                    vehicleReg={vehicle.AssetRegistration}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      )}
      <Box className="py-4" />
    </Box>
  );
};
