import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import clipboardCopy from "clipboard-copy";
import { ApiResponseData, camelToCapital, instance } from "@clearabee/ui-sdk";
import {
  Text,
  Heading,
  Button,
  FlexGrid,
  formatCurrency,
  Panel,
  theme,
  Box,
} from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { AdditionalInfoLine } from "./additionalInfoLine";

interface AddtionalInfoProps {
  data: ApiResponseData<typeof instance.jobs.getJobByRef> & {
    timestamp?: string;
  };
  setBookJobAgainOpen: (value: boolean) => void;
  canBookJobAgain: boolean;
  additionalActions?: React.ReactElement;
}

const MetaValue = ({
  value,
  enabled,
  // Please remove returnBoolean when API-3093 is merged https://clearabee.atlassian.net/browse/API-3093
  returnBoolean,
  name,
}: {
  enabled: boolean;
  value: string;
  returnBoolean: boolean;
  name: string;
}): React.ReactElement => {
  if (enabled && !value) return <>No data</>;

  if (dayjs(value, "YYYY-MM-DDTHH:mm:ss*").isValid())
    return <>{dayjs(value).format("dddd, MMMM Do YYYY")}</>;

  if (returnBoolean) return <>{!!value ? "Yes" : "No"}</>;

  if (name === "what3words") {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary border-b-2 border-primary"
        href={`https://what3words.com/${value}`}
      >
        {value}
      </a>
    );
  }

  if (name === "reportsMeta") {
    return (
      <pre style={{ ...theme.fontDefaults.small }}>
        {JSON.stringify(value, null, 1)}
      </pre>
    );
  }

  return <>{value}</>;
};

export const AdditionalInfo = ({
  data,
  setBookJobAgainOpen,
  canBookJobAgain,
  additionalActions,
}: AddtionalInfoProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { doesUserHaveRole } = useAuthContext();
  const isClearabee = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_MANAGER,
    roles.CLEARABEE_CUSTOMER_SERVICE,
    roles.CLEARABEE_OPERATIONS,
  ]);

  const completionDateTimestamp = data.audits?.find(
    ({ action, valueAfter }) =>
      action === "STATUS_UPDATE" && valueAfter === "Completed",
  )?.timestamp;
  const completionDate =
    completionDateTimestamp && dayjs(completionDateTimestamp).startOf("day");
  const completionRange =
    !!data.originalRequestedDate &&
    !!completionDate &&
    completionDate.diff(
      dayjs(data.originalRequestedDate).startOf("day"),
      "days",
    );

  const { data: orderTypes, isLoading } = useQuery(
    ["getOrderTypes"],
    async () => (await instance.catalogues.getOrderTypes()).data,
    {
      enabled: isClearabee,
    },
  );
  const timeSlotData = [
    new Set(
      [
        data.timeslot,
        data.timePreference,
        data.timeslotJson
          ? data.timeslotJson.from + " - " + data.timeslotJson.to
          : undefined,
      ].filter(Boolean),
    ),
  ];

  const doesTimeSlotExist = timeSlotData.some((timeSlot) => !!timeSlot.size);

  const updatedMeta =
    data.meta &&
    Object.entries(data.meta).filter(
      ([key]) => !["jobResult", "operator"].includes(key),
    );

  return (
    <>
      <Box className="flex flex-row justify-between items-center mb-8">
        <Box className="flex gap-5 items-center">
          <Heading color="brand" level={5}>
            {translate("headings.additonalInformation")}
          </Heading>
          {additionalActions}
        </Box>

        {canBookJobAgain && (
          <Button
            size="small"
            color="brand"
            type="button"
            styles={{ marginLeft: theme.spacing.small }}
            onClick={() => setBookJobAgainOpen(true)}
          >
            {translate("update.buttons.bookAgain")}
          </Button>
        )}
      </Box>
      <Panel
        styles={{
          paddingTop: theme.spacing.xsmall,
          paddingBottom: theme.spacing.xsmall,
        }}
        className="mb-12"
        shadow={false}
      >
        <Box className="grid grid-cols-4">
          <FlexGrid.Cell>
            <AdditionalInfoLine label={translate("labels.dateCreated")}>
              {data.timestamp
                ? dayjs(data.timestamp).format("dddd, MMMM Do YYYY")
                : undefined}
            </AdditionalInfoLine>
          </FlexGrid.Cell>

          <FlexGrid.Cell>
            <AdditionalInfoLine label={translate("labels.jobResult")}>
              {data.jobResult}
            </AdditionalInfoLine>
          </FlexGrid.Cell>
          <FlexGrid.Cell>
            <AdditionalInfoLine label={translate("labels.operator")}>
              {data.meta?.operator}
            </AdditionalInfoLine>
          </FlexGrid.Cell>
        </Box>
      </Panel>
      {isClearabee && (
        <Panel className="mb-12" shadow={false}>
          <Box className="grid gap-x-1 grid-cols-4">
            <>
              <FlexGrid.Cell>
                <AdditionalInfoLine label={translate("labels.orderType")}>
                  {isLoading
                    ? "Loading..."
                    : !!data.items?.[0]?.orderTypeId &&
                      orderTypes?.items.find(
                        ({ id }) => id === data.items?.[0].orderTypeId,
                      )?.name}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
              <FlexGrid.Cell>
                <AdditionalInfoLine label={translate("labels.creditUsed")}>
                  {data?.creditUsed
                    ? formatCurrency(data?.creditUsed)
                    : undefined}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
              <FlexGrid.Cell>
                <AdditionalInfoLine label={translate("labels.bigChangeId")}>
                  {typeof data.bcId !== "undefined" ? (
                    <Box className="flex items-center">
                      <Text fontSize="small">
                        {!!data.bcId ? data.bcId : translate("noBigChangeId")}
                      </Text>
                      <Button
                        onClick={async () => {
                          await clipboardCopy(data.bcId!);
                        }}
                        className="ml-2"
                        color="accent"
                        size="xsmall"
                      >
                        {translate("copy")}
                      </Button>
                    </Box>
                  ) : undefined}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
              {data.originalRequestedDate && (
                <FlexGrid.Cell>
                  <AdditionalInfoLine
                    label={translate("labels.originalRequestedDate")}
                  >
                    {dayjs(data.originalRequestedDate).format(
                      "dddd, MMMM Do YYYY",
                    )}
                  </AdditionalInfoLine>
                </FlexGrid.Cell>
              )}
              {!!data.originalRequestedDate && !!completionDate && (
                <FlexGrid.Cell>
                  <AdditionalInfoLine
                    label={translate("labels.completionRange")}
                  >
                    {completionRange ? `${completionRange} day(s)` : "Same day"}
                  </AdditionalInfoLine>
                </FlexGrid.Cell>
              )}
              <FlexGrid.Cell>
                <AdditionalInfoLine label={translate("labels.timeSlot")}>
                  {doesTimeSlotExist &&
                    timeSlotData.map((timeslot, index) => (
                      <p key={index}>{Array.from(timeslot).join(" - ")}</p>
                    ))}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
              <FlexGrid.Cell>
                <AdditionalInfoLine label={translate("labels.products")}>
                  {!!data.items?.length && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          data?.items
                            ?.map(
                              ({ qty, lineCost, title }) =>
                                `${qty} x ${title} - ${formatCurrency(
                                  lineCost,
                                )}`,
                            )
                            .join("<br/>") || "",
                      }}
                    />
                  )}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
              <FlexGrid.Cell className="col-span-2">
                <AdditionalInfoLine label={translate("labels.meta")}>
                  {updatedMeta?.length ? (
                    <Box
                      backgroundColor="greyscale.lightest"
                      className="p-2 rounded-md"
                    >
                      <Box className="max-h-24 overflow-y-scroll">
                        {updatedMeta.map(([key, value]) => {
                          return (
                            <Fragment key={`${key}-${value}`}>
                              <p
                                className={`flex ${
                                  key === "reportsMeta" && "flex-col"
                                }`}
                              >
                                <strong className="inline-block mr-2 text-sm">
                                  {camelToCapital(key)}:
                                </strong>
                                <MetaValue
                                  name={key}
                                  enabled={key
                                    .toLocaleLowerCase()
                                    .includes("orderref")}
                                  value={value}
                                  returnBoolean={key
                                    .toLocaleLowerCase()
                                    .includes("readytocollect")}
                                />
                              </p>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : undefined}
                </AdditionalInfoLine>
              </FlexGrid.Cell>
            </>
          </Box>
        </Panel>
      )}
    </>
  );
};
