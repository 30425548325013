import * as Yup from "yup";
import { stringRequired } from "validation/common";

export interface SelectOptions {
  value: string;
  label: string;
}

interface FormValuesProps {
  driverEmails: string[];
  vehicleReg: string;
}

export const initialValues: FormValuesProps = {
  driverEmails: [],
  vehicleReg: "",
};

export const validationSchema = Yup.object().shape({
  vehicleReg: stringRequired,
  driverEmails: Yup.array(Yup.string()),
});
