import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { instance } from "@clearabee/ui-sdk";
import {
  Heading,
  Panel,
  Table,
  Button,
  theme,
  Box,
  Icon,
  Text,
} from "@clearabee/ui-library";
import { useHistory, useLocation } from "react-router-dom";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { buildQuery } from "helpers/api";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { IPaginatedResults, TFilters } from "api/types";
import { IFailedJob } from "@clearabee/api-schemas";
import { LoadingOverlay } from "components/common/components";
import { useScreenWidth } from "hooks";
import { PayloadModal, ReasonModal } from "./components";

// initial results per page for pagination
const initialResultsPerPage = 10;

export const FailedJobs = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  const [total, setTotal] = useState(0);
  const [activeFailedJob, setActiveFailedJob] = useState<IFailedJob | null>(
    null,
  );
  const [payload, setPayload] = useState<IFailedJob["payload"] | null>(null);
  const [reason, setReason] = useState<IFailedJob["message"] | null>(null);

  const { isMobile, isDesktop } = useScreenWidth();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const readFailedJobs = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IFailedJob>> => {
    const { data } = await instance.jobs.getFailedJobs({
      params: {
        // this will change when we have filters in queryserivce, eg: "{"attempts:gte" : 1}"
        attempts: 1,
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<IFailedJob>;

    setTotal(response?.pagination?.total || 0);

    return response;
  };

  const {
    PaginationComponent,
    updateFilters,
    setCurrentPage,
    setResultsPerPage,
    paginatedData,
    currentPage,
    resultsPerPage,
    query: { isFetching, isLoading, refetch, isSuccess },
  } = usePaginatedQuery(
    readFailedJobs,
    "readFailedJobs",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoading || isFetching) && <LoadingOverlay />}

      <Box className="max-w-6xl mx-auto py-10 relative">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
          }}
          shadow={false}
        >
          <Box className="flex items-center justify-between">
            <Heading level={4} color="brand">
              {translate("failedJobs.headings.failedJobs", {
                count: total,
              })}
            </Heading>
            <Button
              size="small"
              color="negative"
              variant="outline"
              type="button"
              onClick={() => {
                updateFilters("");
                setCurrentPage(1);
                setResultsPerPage(initialResultsPerPage);
                history.push(
                  `/jobs/failed?page=1&resultsPerPage=${initialResultsPerPage}`,
                );
                refetch();
              }}
            >
              Reset
            </Button>
          </Box>
        </Panel>
        <Box
          className="flex justify-center items-center gap-x-2 px-2 py-2 mt-4 rounded-md"
          backgroundColor="warning.base"
        >
          <Icon.Attention size="medium" />
          <Text fontSize="base">{translate("failedJobs.info")}</Text>
        </Box>

        <Box className="relative flex flex-1">
          {/* DISPLAY NO DATA MESSAGE */}
          {!isLoading && !isFetching && !paginatedData.length && isSuccess && (
            <Box className="w-full flex justify-center mt-8">
              <Heading level={4}>
                {translate("failedJobs.errors.noDataMessage")}
              </Heading>
            </Box>
          )}
          {!!paginatedData.length && (
            <Box>
              <Table
                className="mt-10"
                styles={{
                  tableLayout: "fixed",
                  "th:last-of-type > p": {
                    textAlign: "center",
                  },
                }}
              >
                {!isMobile && (
                  <>
                    <colgroup>
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "40%" }} />
                      <col style={{ width: "8%" }} />
                      <col style={{ width: "8%" }} />
                      <col style={{ width: "16%" }} />
                      <col style={{ width: "12%" }} />
                    </colgroup>
                    <Table.Header
                      className="text-center"
                      fontSize={isDesktop ? "xsmall" : "xsmall2"}
                      headings={[
                        translate("failedJobs.table.columns.orderRef"),
                        translate("failedJobs.table.columns.topicArn"),
                        translate("failedJobs.table.columns.attempts"),
                        translate("failedJobs.table.columns.processed"),
                        translate("failedJobs.table.columns.createdOn"),
                        translate("failedJobs.table.columns.actions"),
                      ]}
                    />
                  </>
                )}
                <Table.Body>
                  {paginatedData?.map((item) => (
                    <Table.Row
                      key={`table-row-${item.id}`}
                      className={
                        isMobile
                          ? "flex flex-col justify-center items-center w-full"
                          : ""
                      }
                    >
                      <Table.Cell.Text className="truncate flex flex-wrap items-center justify-center gap-1">
                        {isMobile && (
                          <span className="font-semibold">
                            {translate("failedJobs.table.cells.orderRef")}
                          </span>
                        )}
                        {item.orderRef}
                      </Table.Cell.Text>
                      <Table.Cell.Text className="truncate flex flex-wrap items-center justify-center gap-1">
                        {isMobile && (
                          <span className="font-semibold">
                            {translate("failedJobs.table.cells.topicArn")}
                          </span>
                        )}
                        {item.topicArn}
                      </Table.Cell.Text>
                      <Table.Cell.Text>
                        {isMobile && (
                          <span className="font-semibold">
                            {translate("failedJobs.table.cells.attempts")}
                          </span>
                        )}
                        {item.attempts}
                      </Table.Cell.Text>
                      <Table.Cell.Text>
                        {isMobile && (
                          <span className="font-semibold">
                            {translate("failedJobs.table.cells.processed")}
                          </span>
                        )}
                        {String(item.processed)}
                      </Table.Cell.Text>
                      <Table.Cell.Text className="truncate">
                        {isMobile && (
                          <span className="font-semibold">
                            {translate("failedJobs.table.cells.createdOn")}
                          </span>
                        )}
                        {dayjs(item.createdOn).format("DD/MM/YYYY HH:mm:ss")}
                      </Table.Cell.Text>
                      <Table.Cell>
                        <Box className="flex items-center justify-between gap-x-2 truncate">
                          {!!item.message && (
                            <Button
                              size="xsmall"
                              type="button"
                              color="negative"
                              onClick={() => {
                                setActiveFailedJob(item);
                                setReason(item.message);
                              }}
                            >
                              {translate("failedJobs.buttons.viewReason")}
                            </Button>
                          )}
                          <Button
                            size="xsmall"
                            type="button"
                            onClick={() => {
                              setActiveFailedJob(item);
                              setPayload(item.payload);
                            }}
                          >
                            {translate("failedJobs.buttons.viewPayload")}
                          </Button>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {!!paginatedData.length && (
                <Box className="mt-6">
                  <PaginationComponent />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* REASON MODAL */}
      <ReasonModal
        failedJob={activeFailedJob}
        reason={reason}
        setReasonModalOpen={setReason}
      />

      {/* PAYLOAD MODAL */}
      <PayloadModal
        failedJob={activeFailedJob}
        setPayloadModalOpen={setPayload}
        payload={payload}
        onRefetch={() => refetch()}
      />
    </>
  );
};
