import React from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toasts } from "helpers/toasts";
import { instance } from "@clearabee/ui-sdk";
import { TVehicleRegionPostBody } from "@clearabee/api-schemas";
import {
  Heading,
  Button,
  Form,
  Field,
  Input,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { stringRequired } from "validation/common";
import { LoadingOverlay } from "components/common/components";

dayjs.extend(utc);

interface CreateRegionModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  refetchRegions: () => void;
}

export const CreateRegionModal = ({
  showModal,
  setShowModal,
  refetchRegions,
}: CreateRegionModalProps): React.ReactElement => {
  const { mutate: createRegion, isLoading: createRegionIsLoading } =
    useMutation(
      ["createRegion"],
      async (body: TVehicleRegionPostBody) => {
        return instance.vehicles.postVehicleRegion(body);
      },
      {
        retry: 1,
        onSuccess: () => {
          setShowModal(false);
          refetchRegions();
          toasts.success({
            message: "Region created successfully.",
          });
        },
        onError: () => {
          toasts.error({
            message: "Something went wrong.",
          });
        },
      },
    );

  return (
    <>
      {showModal && (
        <Modal
          width={500}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Heading color="brand" level={4}>
            Create Region
          </Heading>

          {createRegionIsLoading && (
            <LoadingOverlay
              iconSize="xlarge2"
              backgroundLoadingContainerStyles={{
                borderRadius: theme.spacing.small,
              }}
            />
          )}

          <Form
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object().shape({
              name: stringRequired,
            })}
            onSubmit={(values) =>
              createRegion({
                name: values.name,
              })
            }
            className="flex flex-col w-full justify-center items-center text-left mb-3"
          >
            <Field
              styles={{
                width: "75%",
              }}
              name="name"
              label="Name"
            >
              {({ field }) => <Input.Text {...field} />}
            </Field>

            <Button type="submit" size="small">
              Submit
            </Button>
          </Form>
        </Modal>
      )}
    </>
  );
};
