import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as Yup from "yup";
import { OptionTypeBase } from "react-select";
import { IVehicleScheduleResponse } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Text,
  theme,
  Button,
  Input,
  Field,
  Form,
  Modal,
  Table,
} from "@clearabee/ui-library";
import { stringRequired } from "validation/common";

dayjs.extend(utc);

interface TableViewProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  dates: string[];
  schedule: IVehicleScheduleResponse[];
  drivers: OptionTypeBase[];
}

const initialValues = {
  date: "",
};

const validationSchema = Yup.object().shape({
  date: stringRequired,
});

export const TableView = ({
  dates,
  schedule,
  showModal,
  setShowModal,
  drivers,
}: TableViewProps): React.ReactElement => {
  const [filteredSchedule, setFilteredSchedule] = useState<
    IVehicleScheduleResponse[]
  >([]);

  const options = useMemo(() => {
    if (!dates || !dates?.length) return [];

    return dates.map((date) => ({
      value: date,
      label: dayjs.utc(date).format("DD/MM/YYYY"),
    }));
  }, [dates]);

  const handleSubmit = (values: typeof initialValues) => {
    const allFilteredSchedule = schedule.map((vehicle) => {
      const filteredSchedule = vehicle.schedule.filter(
        (schedule) => String(schedule.date) === values.date,
      );

      return {
        ...vehicle,
        schedule: filteredSchedule,
      };
    });

    setFilteredSchedule(allFilteredSchedule);
  };

  const getUser = (userId: number) => {
    const foundDriver = drivers.find((driver) => driver.value == userId)?.label;

    return !!foundDriver ? foundDriver : "Not Found";
  };

  useEffect(() => {
    setFilteredSchedule([]);

    if (options.length === 1) {
      handleSubmit({ date: dates[0] });
    }
  }, [showModal]);

  return (
    <>
      {showModal && (
        <Modal
          width={1300}
          styles={{
            maxWidth: "90vw",
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.medium,
            },
          }}
          onClose={() => {
            setShowModal(false);
            setFilteredSchedule([]);
          }}
        >
          <Box className="flex flex-row justify-between items-center">
            <Heading color="brand" level={3}>
              Table View
            </Heading>

            {options?.length > 1 && (
              <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
                className="flex flex-row gap-6 items-center text-left mb-3"
              >
                <Box className="w-48">
                  <Field name="date">
                    {({ field }) => (
                      <Input.Select
                        {...field}
                        options={options}
                        placeholder="Date"
                      />
                    )}
                  </Field>
                </Box>

                <Box>
                  <Button size="small" type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Box>

          <Box
            styles={{
              maxHeight: "70vh",
              overflowY: "scroll",
              textAlign: "left",
            }}
          >
            {filteredSchedule.length > 0 && (
              <Table
                className="mt-10"
                styles={{
                  position: "relative",
                }}
              >
                <colgroup>
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>

                <Table.Header
                  fontSize="small"
                  headings={["Name", "Time", "Message", "Users"]}
                />

                <Table.Body>
                  {filteredSchedule.map((vehicle) => {
                    const foundTime = vehicle.schedule.find(
                      (schedule) => schedule.time,
                    )?.time;

                    const foundMessage = vehicle.schedule.find(
                      (schedule) => schedule.time,
                    )?.message;

                    const foundUsers = vehicle.schedule.map((schedule) => {
                      return schedule.userId;
                    });

                    return (
                      <Table.Row key={`table-row-${vehicle.vehicleId}`}>
                        {/* NAME */}
                        <Table.Cell.Text className="truncate">
                          {vehicle.name}
                        </Table.Cell.Text>

                        {/* TIME */}
                        <Table.Cell.Text className="truncate">
                          {!!foundTime ? foundTime : "None"}
                        </Table.Cell.Text>

                        {/* MESSAGE */}
                        <Table.Cell.Text className="truncate">
                          {!!foundMessage ? foundMessage : "None"}
                        </Table.Cell.Text>

                        {/* USERS */}
                        <Table.Cell
                          styles={{
                            fontSize: theme.fontDefaults.xsmall.fontSize,
                          }}
                          className="truncate"
                        >
                          {!!foundUsers?.length
                            ? foundUsers.map((user) => {
                                return (
                                  <Text
                                    fontSize="xsmall"
                                    key={`table-row-${vehicle.vehicleId}-user-${user}`}
                                  >
                                    {getUser(user)}
                                  </Text>
                                );
                              })
                            : "None"}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};
