import React from "react";

/**
 * Import components.
 */
import { Steps } from "./components";

export const AdditionalJobPayment = (): React.ReactElement => {
  return <Steps />;
};
