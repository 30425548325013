import React from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { IPostTipBody, ITip } from "@clearabee/api-schemas";
import { instance, readAddresses } from "@clearabee/ui-sdk";
import {
  Form,
  Heading,
  Modal,
  Field,
  Input,
  Box,
  Button,
  theme,
  Message,
} from "@clearabee/ui-library";
import { validationSchema, initialValues } from "./validation";
import { toasts } from "helpers/toasts";
import { LoadingOverlay } from "components/common/components";
import { getErrorMessage } from "helpers";

interface CreateTipProps {
  isVisible: boolean;
  groupOptions: { label: string; value: string }[];
  onClose: () => void;
  onSuccess: (data: ITip) => void;
}

export const CreateTip = ({
  isVisible,
  groupOptions,
  onClose,
  onSuccess,
}: CreateTipProps): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");

  const { mutate, error, isLoading, reset } = useMutation(
    "Create tip",
    async (values: IPostTipBody) => {
      const { latitude, longitude } = await readAddresses(values.postcode);

      return (
        await instance.jobs.postTip({
          ...values,
          latitude,
          longitude,
          rate: Number(values?.rate ?? 0),
        })
      ).data;
    },
    {
      onSuccess: (data: ITip) => {
        onSuccess(data);
        reset();
        toasts.success({
          message: translate("tips.success.post"),
        });
      },
    },
  );

  return (
    <Modal
      width={600}
      styles={{
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
        },
      }}
      modalVisible={isVisible}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onReset={() => reset()}
        onSubmit={(values) => {
          mutate(values);
        }}
        className="text-left"
      >
        {({ submitCount, errors, setFieldValue, values }) => {
          const hasErrors = Object.keys(errors).length > 0;

          return (
            <>
              {/* MODAL LOADING OVERLAY */}
              {isLoading && (
                <LoadingOverlay
                  backgroundLoadingContainerStyles={{
                    borderRadius: theme.spacing.small,
                  }}
                />
              )}
              {/* HEADING */}
              <Box className="mt-5 mb-2">
                <Heading color="brand" level={4}>
                  {translate("tips.headings.add")}
                </Heading>
              </Box>

              {/* DIVIDER */}
              <Box className="border-t border-gray-300 my-1 flex flex-col h-1" />

              <Box styles={{ maxHeight: "600px", overflowY: "scroll" }}>
                <Box className="flex gap-x-2">
                  {/* NAME */}
                  <Field
                    styles={{
                      flex: 1,
                    }}
                    name="name"
                    label={translate("tips.form.labels.name")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("tips.form.placeholders.name")}
                      />
                    )}
                  </Field>
                  <Box className="w-1/4 flex justify-center">
                    {/* ACTIVE */}
                    <Field
                      name="active"
                      label={translate("tips.form.labels.active")}
                    >
                      {({ field }) => (
                        <Input.Toggle {...field} checked={values.active} />
                      )}
                    </Field>
                  </Box>
                </Box>
                {/* COMPANY CODE */}
                <Field
                  styles={{
                    flex: 1,
                    marginTop: theme.spacing.xsmall,
                    marginBottom: theme.spacing.small,
                  }}
                  name="companyCode"
                  label={translate("tips.form.labels.companyCode")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "tips.form.placeholders.companyCode",
                      )}
                    />
                  )}
                </Field>
                {/* GROUP */}
                <Field
                  styles={{
                    flex: 1,
                    marginTop: theme.spacing.xsmall,
                    marginBottom: theme.spacing.small,
                  }}
                  name="group"
                  label={translate("tips.form.labels.group")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={groupOptions}
                      placeholder={translate("tips.form.placeholders.group")}
                    />
                  )}
                </Field>
                <Box className="flex gap-x-2">
                  {/* PERMIT NUMBER */}
                  <Field
                    styles={{ flex: 1 }}
                    name="permitNumber"
                    label={translate("tips.form.labels.permitNumber")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "tips.form.placeholders.permitNumber",
                        )}
                      />
                    )}
                  </Field>
                  {/* WASTE MANAGEMENT LICENSE */}
                  <Field
                    styles={{ flex: 1 }}
                    name="wasteManagementLicense"
                    label={translate("tips.form.labels.wasteManagementLicense")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "tips.form.placeholders.wasteManagementLicense",
                        )}
                      />
                    )}
                  </Field>
                </Box>
                {/* LANDFILL DIVERSION RATE */}
                <Field
                  styles={{ flex: 1, marginBottom: theme.spacing.medium }}
                  name="landfillDiversionRate"
                  label={translate("tips.form.labels.landfillDiversionRate")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "tips.form.placeholders.landfillDiversionRate",
                      )}
                    />
                  )}
                </Field>
                {/* RATE */}
                <Field
                  styles={{ flex: 1, marginBottom: theme.spacing.medium }}
                  name="rate"
                  label={translate("tips.form.labels.rate")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      type="number"
                      placeholder={translate("tips.form.placeholders.rate")}
                      onBlur={(e) => {
                        e.preventDefault();

                        const value = Number(values?.rate ?? 0);

                        setFieldValue(
                          "rate",
                          (
                            Math.round((value < 0 ? value * -1 : value) * 100) /
                            100
                          ).toFixed(2),
                        );
                      }}
                    />
                  )}
                </Field>
                {/* ADDRESS */}
                <Field
                  styles={{ flex: 1 }}
                  name="address"
                  label={translate("tips.form.labels.address")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("tips.form.placeholders.address")}
                    />
                  )}
                </Field>
                <Box className="flex gap-x-2">
                  {/* POSTCODE */}
                  <Field
                    styles={{ flex: 1 }}
                    name="postcode"
                    label={translate("tips.form.labels.postcode")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "tips.form.placeholders.postcode",
                        )}
                      />
                    )}
                  </Field>
                  {/* CITY */}
                  <Field
                    styles={{ flex: 1 }}
                    name="city"
                    label={translate("tips.form.labels.city")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("tips.form.placeholders.city")}
                      />
                    )}
                  </Field>
                </Box>
                {/* NOTES */}
                <Field
                  styles={{ flex: 1 }}
                  name="notes"
                  label={translate("tips.form.labels.notes")}
                >
                  {({ field }) => (
                    <Input.Textarea
                      {...field}
                      autoGrow={false}
                      styles={{ height: 80 }}
                      placeholder={translate("tips.form.placeholders.notes")}
                    />
                  )}
                </Field>
              </Box>
              {/* DISPLAY FIELDS ERROR */}
              {submitCount > 0 && hasErrors && (
                <Box className="flex gap-8 mt-2">
                  <Message type="error" className="text-left">
                    {translate("tips.errors.fieldMissing")}
                  </Message>
                </Box>
              )}
              {/* SUBMIT BUTTON */}
              <Box className="flex justify-center mt-2">
                <Button
                  type="submit"
                  color="warning"
                  disabled={isLoading}
                  size="small"
                >
                  {translate("tips.buttons.submit")}
                </Button>
              </Box>
              {/* DISPLAY ERROR */}
              {!!error && (
                <Box className="flex justify-center">
                  <Box
                    className="px-2 py-px rounded-md mt-3"
                    backgroundColor="negative"
                    color="light"
                  >
                    {getErrorMessage(error)}
                  </Box>
                </Box>
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
