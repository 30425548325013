import React, { useState } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { IUser, IJob } from "@clearabee/api-schemas";
import { buildQuery } from "helpers/api";
import {
  Button,
  Field,
  Form,
  Heading,
  Input,
  Panel,
  theme,
  Text,
  Message,
  Box,
  Pill,
  UnstyledButton,
} from "@clearabee/ui-library";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { LoadingOverlay } from "components/common/components";

const validationSchema = Yup.object().shape({
  vehicle: Yup.string().required(),
  date: Yup.string().required(),
});

const findAsset = (driver: IUser) => {
  return driver.attributes?.find((attr) => attr.attrKey === "asset")?.attrValue;
};
type SelectOption = {
  label: string;
  value: string;
};

const getPillColor = (status: string): AllowedColorKeys => {
  switch (status) {
    case "pending":
      return "usp";
    case "processed":
      return "accent";
    case "started":
      return "brand";
    case "open":
      return "warning";
    case "on the way":
      return "accent";
    case "failed":
      return "negative";
    case "started":
      return "brand";
    case "completed":
      return "positive";
    case "cancelled":
      return "negative";
    default:
      return "brand";
  }
};

export const VehicleJobs = (): React.ReactElement => {
  const params = new URLSearchParams(location.search.replace("?", ""));
  const filterParams = {
    asset: params.get("asset"),
    date: params.get("date"),
  };
  const [initialValues, setInitialValues] = useState({
    vehicle: filterParams.asset || "",
    date: filterParams.date || "",
  });
  type FormValues = typeof initialValues;
  const [translate] = useTranslation("vehicles");
  const [formValues, setFormValues] = useState<FormValues>();
  const [drivers, setDrivers] = useState<IUser[]>([]);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [vehicleJobs, setVehicleJobs] = useState<IJob[]>([]);
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const history = useHistory();

  /**
   * get all drivers for dropdown
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isError: isErrorAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver",
            limit: 1000,
          },
        })
      ).data.items,
  );

  const { isLoading: allVehiclesIsLoading, isError: allVehiclesIsError } =
    useQuery(
      "getAllVehicles",
      async () =>
        (
          await instance.vehicles.getVehicles({
            params: {
              limit: 1000,
            },
          })
        ).data.items,
      {
        onSuccess: (data) => {
          setOptions(
            data
              .sort((a, b) => a.registration.localeCompare(b.registration) || 0)
              .map(({ registration }) => ({
                label: registration,
                value: registration,
              })),
          );
        },
      },
    );

  /**
   * get jobs with associated vehicle registration
   */
  const {
    data: getVehicleJobs,
    isLoading: getVehicleJobsIsLoading,
    isError: getVehicleJobsIsError,
  } = useQuery(
    ["getVehicleJobs", formValues],
    async () =>
      (
        await instance.jobs.getJobsByAsset(
          formValues?.vehicle || filterParams.asset || "",
          {
            params: {
              date:
                formValues?.date ||
                dayjs(filterParams.date, "DD/MM/YYYY").toISOString(),
              "include-related": true,
            },
          },
        )
      ).data,
    {
      enabled: !!formValues || !!filterParams.asset || !!filterParams.date,
      cacheTime: 0,
      onSuccess: (data) => {
        const drivers = allDriversData?.filter((driver) => {
          const asset = findAsset(driver);
          return asset === formValues?.vehicle;
        });
        setDrivers(drivers || []);
        setVehicleJobs(data);
      },
    },
  );

  const handleSubmit = async (values: FormValues) => {
    setFormValues({
      ...values,
      date: dayjs.utc(values.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    });

    setHasBeenReset(false);

    const updatedFilterObject = {
      ...(!!values.vehicle ? { asset: values.vehicle } : {}),
      ...(!!values.date ? { date: values.date } : {}),
    };

    const queryParam = buildQuery(updatedFilterObject);
    history.push(`/vehicles/jobs?${queryParam}`);
  };

  return (
    <Box className=" mx-auto py-10 relative">
      {/* LOADING */}
      {(getVehicleJobsIsLoading ||
        isLoadingAllDrivers ||
        allVehiclesIsLoading) && <LoadingOverlay />}

      <Panel className="flex flex-col gap-x-6 justify-between" shadow={false}>
        <Box className="flex justify-between">
          <Heading fontSize="large" color="brand">
            {translate("vehicleJobs.heading")}
          </Heading>
        </Box>
        <Form
          className="border-t border-grey-200 mt-4 pt-5"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ resetForm }) => {
            return (
              <>
                <Box className="flex flex-row items-center justify-center w-full gap-x-3">
                  <Box className="w-5/6">
                    {/* Vehicles */}
                    <Field
                      name="vehicle"
                      className="flex-1 min-w-full"
                      label={translate("vehicleJobs.form.vehicle")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isClearable
                          defaultValue={initialValues.vehicle}
                          isSearchable
                          options={options}
                        />
                      )}
                    </Field>
                  </Box>

                  {/* DATE */}
                  <Box className="w-1/4">
                    <Field
                      name="date"
                      label={translate("vehicleJobs.form.date")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Date
                          {...field}
                          placeholder={translate("vehicleJobs.form.date")}
                          collapsable
                        />
                      )}
                    </Field>
                  </Box>

                  <Box className="flex gap-3 w-1/6">
                    {/* RESET */}
                    <Button
                      className="mt-5"
                      size="small"
                      color="negative"
                      disabled={getVehicleJobsIsLoading}
                      type="reset"
                      onClick={() => {
                        history.push(`/vehicles/jobs`);
                        setInitialValues({ vehicle: "", date: "" });
                        setFormValues(undefined);
                        resetForm();
                        setHasBeenReset(true);
                        setVehicleJobs([]);
                      }}
                    >
                      {translate("vehicleJobs.buttons.reset")}
                    </Button>

                    {/* SUBMIT */}
                    <Button className="mt-5" size="small" type="submit">
                      {translate("vehicleJobs.buttons.search")}
                    </Button>
                  </Box>
                </Box>

                {/* VEHICLE INFORMATION */}
                <Box>
                  {!!formValues && (
                    <Box className="flex flex-row items-start w-5/6 justify-start gap-x-5 mt-5">
                      <Box>
                        <Text fontSize="xsmall" className="font-bold">
                          {translate("vehicleJobs.table.registration")}
                        </Text>
                        <Text fontSize="xsmall">{formValues.vehicle}</Text>
                      </Box>

                      {!getVehicleJobsIsLoading && !!vehicleJobs?.length && (
                        <Box className="min-w-16">
                          <Text fontSize="xsmall" className="font-bold">
                            {translate("vehicleJobs.table.totalJobs")}
                          </Text>
                          <Text fontSize="xsmall">{vehicleJobs?.length}</Text>
                        </Box>
                      )}

                      {!!drivers?.length && (
                        <Box>
                          <Text fontSize="xsmall" className="font-bold">
                            {translate("vehicleJobs.table.drivers")}
                          </Text>
                          {drivers.map((driver) => {
                            return (
                              <Text
                                fontSize="xsmall"
                                key={driver.email}
                              >{`${driver.firstName} ${driver.lastName} (${driver.email})`}</Text>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            );
          }}
        </Form>
      </Panel>

      {/* ERRORS */}
      <Box className="flex items-center justify-center w-full">
        {!!getVehicleJobs && !vehicleJobs?.length && !hasBeenReset && (
          <Message className="mt-2" type="error" background>
            {translate("vehicleJobs.errors.noJobs")}
          </Message>
        )}
        {getVehicleJobsIsError && (
          <Message className="mt-2" type="error" background>
            {translate("vehicleJobs.errors.fetchJobs")}
          </Message>
        )}
        {isErrorAllDrivers && (
          <Message className="mt-2" type="error" background>
            {translate("vehicleJobs.errors.fetchDrivers")}
          </Message>
        )}

        {allVehiclesIsError && (
          <Message className="mt-2" type="error" background>
            {translate("vehicleJobs.errors.fetchVehicles")}
          </Message>
        )}
      </Box>

      <Box className="relative flex flex-1">
        {!getVehicleJobsIsLoading && !!vehicleJobs && !!vehicleJobs?.length && (
          <Box className="w-full">
            {vehicleJobs
              .sort(
                (a, b) =>
                  dayjs(a.plannedStartTime).unix() -
                  dayjs(b.plannedStartTime).unix(),
              )
              .map((job, index) => (
                <Panel
                  shadow={false}
                  key={index}
                  className="flex flex-row my-5 w-full"
                  style={{
                    paddingTop: theme.spacing.small,
                    paddingBottom: theme.spacing.small,
                  }}
                >
                  <Box className="flex flex-row items-center justify-between w-full gap-x-3">
                    <Box className="gap-y-3 w-16">
                      <Text
                        fontSize="xsmall"
                        className="font-bold"
                        styles={{ marginBottom: theme.spacing.xsmall2 }}
                      >
                        {translate("vehicleJobs.table.status")}
                      </Text>
                      <Pill
                        color={getPillColor(job.status.toLocaleLowerCase())}
                      >
                        {job.status}
                      </Pill>
                    </Box>

                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.reference")}
                      </Text>
                      <UnstyledButton
                        onClick={() =>
                          history.push(
                            `/jobs/update/${encodeURIComponent(
                              String(job.id ?? ""),
                            )}`,
                          )
                        }
                      >
                        <Text color="brand" fontSize="xsmall">
                          {job.ref}
                        </Text>
                      </UnstyledButton>
                    </Box>

                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.date")}
                      </Text>
                      <Text fontSize="xsmall">
                        {dayjs(job.date).format("DD/MM/YYYY")}
                      </Text>
                    </Box>

                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.postcode")}
                      </Text>
                      <Text fontSize="xsmall">{job.addressPostcode}</Text>
                    </Box>

                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.bcId")}
                      </Text>
                      <Text fontSize="xsmall">{job.bcId}</Text>
                    </Box>

                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.statusId")}
                      </Text>
                      <Text fontSize="xsmall">{job.statusId}</Text>
                    </Box>

                    <Box className="gap-y-3 w-26">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.assetId")}
                      </Text>
                      <Text fontSize="xsmall">{job.asset}</Text>
                    </Box>

                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.type")}
                      </Text>
                      <Text fontSize="xsmall">{job.type}</Text>
                    </Box>

                    <Box className="gap-y-3 w-24">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("vehicleJobs.table.orderTypeId")}
                      </Text>
                      <Text fontSize="xsmall">{job.orderTypeId}</Text>
                    </Box>
                  </Box>
                </Panel>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
