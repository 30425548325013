import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Icon,
  Modal,
  UnstyledButton,
} from "@clearabee/ui-library";
import { toasts } from "helpers";

interface PreviewSchemaProps {
  isVisible: boolean;
  schema: string;
  heading: string;
  isSchemaCopyable?: boolean;
  arn?: string;
  onClose: () => void;
}

const schemaToObject = (
  schema: any,
  definitions: any = {},
): Record<string, any> => {
  if (schema.$ref) {
    const ref = schema.$ref.replace("#/definitions/", "");
    return schemaToObject(definitions[ref], definitions);
  }

  if (schema.type === "object") {
    const obj: any = {};
    for (const prop in schema.properties) {
      obj[prop] = schemaToObject(schema.properties[prop], definitions);
    }
    return obj;
  }

  if (schema.type === "array") {
    return [schemaToObject(schema.items, definitions)];
  }

  return schema.type;
};

export const PreviewSchema = ({
  isVisible,
  schema,
  heading,
  isSchemaCopyable = false,
  arn,
  onClose,
}: PreviewSchemaProps): React.ReactElement => {
  const [translate] = useTranslation("notifications");
  const [jsonSchema, setJsonSchema] = useState<any>({});

  useEffect(() => {
    if (!!schema) {
      import(`@clearabee/api-schemas/json/${schema}.json`)
        .then((jsonSchema) => {
          setJsonSchema(jsonSchema.default || jsonSchema);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setJsonSchema({});
    }
  }, [schema]);

  return (
    <Modal modalVisible={isVisible} width={750} onClose={() => onClose()}>
      <Box className="-mt-4">
        {isSchemaCopyable ? (
          <UnstyledButton
            className="flex w-full justify-center items-center gap-1"
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(
                  schemaToObject(jsonSchema, jsonSchema.definitions) ||
                    translate("upsertNotification.messages.noSchemaFound"),
                  null,
                  2,
                ),
              );
              toasts.success({
                message: translate(
                  "upsertNotification.toasts.serviceSchemaCopied",
                ),
              });
            }}
          >
            <Heading level={3} color="brand">
              {translate("upsertNotification.headings.serviceSchema")}
            </Heading>
            <Icon.Copy size="medium" color="brand" />
          </UnstyledButton>
        ) : (
          <Heading className="text-center" level={3} color="brand">
            {heading}
          </Heading>
        )}
        <Box
          backgroundColor="dark.base"
          color="light"
          styles={{
            maxHeight: "60vh",
          }}
          className="text-left mt-5 p-4 rounded-md overflow-scroll"
        >
          <pre>
            {JSON.stringify(
              schemaToObject(jsonSchema, jsonSchema.definitions) ||
                translate("upsertNotification.messages.noSchemaFound"),
              null,
              2,
            )}
          </pre>
        </Box>
        {!!arn && (
          <Box
            backgroundColor="greyscale.lightest"
            className="text-left mt-2 flex gap-1 py-1 rounded-sm px-2"
          >
            {arn}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
