import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Input,
  Modal,
  Panel,
  Table,
  Text,
  theme,
} from "@clearabee/ui-library";
import { ITip } from "@clearabee/api-schemas";
import { toasts } from "helpers/toasts";
import { buildQuery } from "helpers/api";
import { SearchIcon } from "images";
import { useScreenWidth } from "hooks";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { IPaginatedResults, TFilters } from "api/types";
import { TipDetails, CreateTip } from "./components";
import { LoadingOverlay } from "components/common/components";
import { paginationStyles } from "components/common/resusablePaginationStyles";

interface InitialValues {
  name: string;
  group: { label: string; value: string }[];
  status: string;
}

const groupOptions = [
  {
    label: "1c. Tipping - Haz & WEEE Facilities",
    value: "1c. Tipping - Haz & WEEE Facilities",
  },
  {
    label: "1. Tipping - Preferred Locations",
    value: "1. Tipping - Preferred Locations",
  },
  {
    label: "1b. Tipping - Metal Facilities",
    value: "1b. Tipping - Metal Facilities",
  },
  {
    label: "2. Tipping - Second Choice Locations",
    value: "2. Tipping - Second Choice Locations",
  },
  {
    label: "3. Tipping - Other",
    value: "3. Tipping - Other",
  },
  {
    label: "1a. Tipping - ABP Facilities",
    value: "1a. Tipping - ABP Facilities",
  },
  {
    label: "4. Tipping - Emergency use only",
    value: "4. Tipping - Emergency use only",
  },
  {
    label: "Suez",
    value: "Suez",
  },
];

// initial results per page for pagination
const initialResultsPerPage = 10;

export const Tips = (): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");

  const [total, setTotal] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [createTipVisible, setCreateTipVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedTip, setSelectedTip] = useState<ITip>();

  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  /**
   *  function to read the tip and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readTips = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<ITip>> => {
    const filterParams = new URLSearchParams(filters);

    //tip filters
    const name = filterParams?.get("name:like") ?? "";
    const group = filterParams?.get("group:in") ?? "";
    const status = filterParams?.get("active:eq") ?? "";

    const { data } = await instance.jobs.getTips({
      params: {
        ...(!!name ? { "name:like": `%${name}%` } : {}),
        ...(!!group ? { "group:in": `${group}` } : {}),
        ...(!!status ? { "active:eq": `${status}` } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<ITip>;

    setTotal(response?.pagination?.total || 0);

    return response;
  };

  /**
   * Mutation to delete tip
   */
  const { mutate: mutateDeleteTip, isLoading: isLoadingMutateDeleteTip } =
    useMutation(
      "Delete tip",
      async (id: string) => {
        await instance.jobs.deleteTip(id);
      },
      {
        onSuccess: () => {
          setDeleteModalVisible(false);
          refetchTips();
          toasts.success({
            message: translate("tips.success.delete"),
          });
        },
      },
    );

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResultsPerPage,
    query: {
      isFetching: isFetchingTips,
      isLoading: isLoadingTips,
      refetch: refetchTips,
      isSuccess: isSuccessReadTips,
    },
  } = usePaginatedQuery(
    readTips,
    "readTipsPaginated",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * getInitialValues
   */
  const getInitialValues = (): InitialValues => {
    // tip filters
    const name = params?.get("name:like") ?? "";
    const groupValues = params?.get("group:in") ?? "";
    const status = params?.get("active:eq") ?? "";

    const group = !!groupValues.length
      ? groupValues.split(",").map((item) => ({ label: item, value: item }))
      : [];

    return {
      name,
      group,
      status,
    };
  };

  const handleSubmit = (values: InitialValues) => {
    const { name, group: groupValue, status } = values;
    const group = groupValue?.map((item) => item.value).join(",");

    const updatedFilterObject = {
      ...(!!name ? { "name:like": name } : {}),
      ...(!!group ? { "group:in": group } : {}),
      ...(!!status ? { "active:eq": status } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/subcontractors/tips?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoadingTips || isFetchingTips) && <LoadingOverlay />}

      <Box className="max-w-screen-lg mx-auto py-10 relative">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
            display: "flex",
            flexDirection: "column",
          }}
          className="flex flex-col lg:flex-row gap-x-6 justify-between"
        >
          <Box className="flex items-center justify-between">
            <Heading fontSize="large" color="brand">
              {translate("tips.headings.search")} ({total})
            </Heading>
            <Button
              type="button"
              size="small"
              onClick={() => setCreateTipVisible(true)}
            >
              {translate("tips.buttons.add")}
            </Button>
          </Box>
          <Form
            className="border-t border-grey-200 mt-4 pt-5"
            initialValues={getInitialValues()}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              group: Yup.array().of(Yup.string()).nullable(),
              status: Yup.string(),
            })}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <>
                <Box className="flex gap-x-3  w-full flex-1">
                  <Box className="w-full flex-grow">
                    <Field
                      name="name"
                      className="flex-1 min-w-full"
                      label={translate("tips.form.labels.name")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("tips.form.placeholders.name")}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="w-full flex-grow">
                    <Field
                      name="group"
                      label={translate("tips.form.labels.group")}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          multiple
                          closeMenuOnSelect={false}
                          placeholder={translate(
                            "tips.form.placeholders.group",
                          )}
                          options={groupOptions}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="min-w-40">
                    <Field
                      name="status"
                      label={translate("tips.form.labels.status")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder={translate(
                            "tips.form.placeholders.status",
                          )}
                          options={[
                            { label: "Active", value: "1" },
                            { label: "Pending", value: "0" },
                          ]}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Box className="flex justify-end mb-3">
                  <Button
                    className="md:ml-6 flex items-center relative top-3"
                    size="small"
                    color="accent"
                    type="submit"
                  >
                    <SearchIcon className="mr-2" />
                    {translate("tips.buttons.search")}
                  </Button>
                  <Button
                    className="ml-3 relative top-3"
                    size="small"
                    color="negative"
                    type="reset"
                    onClick={() => {
                      resetForm();
                      updateFilters("");
                      setCurrentPage(1);
                      setResultsPerPage(initialResultsPerPage);
                      history.push(
                        `/subcontractors/tips?page=1&resultsPerPage=${initialResultsPerPage}`,
                      );
                    }}
                  >
                    {translate("tips.buttons.reset")}
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </Panel>
        <Box className="relative flex flex-1">
          {/* DISPLAY NO DATA MESSAGE */}
          {!isLoadingTips &&
            !isFetchingTips &&
            !paginatedData.length &&
            isSuccessReadTips && (
              <Box className="w-full flex justify-center mt-8">
                <Heading level={4}>{translate("tips.errors.noData")}</Heading>
              </Box>
            )}
          {!!paginatedData.length && (
            <Box>
              <Table
                className="mt-10"
                styles={{
                  tableLayout: "fixed",
                  "th:last-of-type > p": {
                    textAlign: "center",
                  },
                }}
              >
                <colgroup>
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <Table.Header
                  fontSize={isDesktop ? "xsmall" : "xsmall2"}
                  headings={[
                    translate("tips.table.active"),
                    translate("tips.table.name"),
                    translate("tips.table.postcode"),
                    translate("tips.table.rate"),
                    translate("tips.table.group"),
                    translate("tips.table.actions"),
                  ]}
                  styles={{ textAlign: "center" }}
                />
                <Table.Body>
                  {paginatedData?.map((item) => (
                    <Table.Row key={`table-row-${item.id}`}>
                      <Table.Cell>
                        <Box className="w-2/3">
                          {item.active ? (
                            <Box
                              className="rounded-3xl flex font-semibold py-2 justify-center"
                              backgroundColor="positive"
                              color="light"
                            >
                              <Text fontSize="xsmall">
                                {translate("tips.statuses.active")}
                              </Text>
                            </Box>
                          ) : (
                            <Box
                              backgroundColor="warning"
                              className="rounded-3xl flex font-semibold py-2 justify-center"
                            >
                              <Text fontSize="xsmall">
                                {translate("tips.statuses.pending")}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </Table.Cell>
                      <Table.Cell.Text>{item.name}</Table.Cell.Text>
                      <Table.Cell.Text>{item.postcode}</Table.Cell.Text>
                      <Table.Cell.Text>
                        {item.rate || translate("tips.errors.NA")}
                      </Table.Cell.Text>
                      <Table.Cell.Text>{item.group}</Table.Cell.Text>
                      <Table.Cell>
                        <Box className="flex justify-center gap-x-2">
                          <Button
                            size="xsmall"
                            type="button"
                            onClick={() => {
                              setSelectedTip(item);
                              setModalVisible(true);
                            }}
                            className="text-center"
                          >
                            {translate("tips.buttons.edit")}
                          </Button>
                          <Button
                            size="xsmall"
                            type="button"
                            color="negative"
                            onClick={() => {
                              setSelectedTip(item);
                              setDeleteModalVisible(true);
                            }}
                            className="text-center"
                          >
                            {translate("tips.buttons.delete")}
                          </Button>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              {!!paginatedData.length && (
                <Box className="mt-10">
                  <PaginationComponent />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <TipDetails
          isVisible={modalVisible}
          groupOptions={groupOptions}
          tip={selectedTip}
          onClose={() => setModalVisible(false)}
          onChange={() => {
            refetchTips();
          }}
        />
        <CreateTip
          groupOptions={groupOptions}
          onSuccess={(data) => {
            setCreateTipVisible(false);
            setSelectedTip(data);
            setModalVisible(true);
            refetchTips();
          }}
          isVisible={createTipVisible}
          onClose={() => setCreateTipVisible(false)}
        />
      </Box>
      {/* Confirm Delete tip modal */}
      <Modal
        width={500}
        onClose={() => setDeleteModalVisible(false)}
        modalVisible={deleteModalVisible}
        styles={{
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.medium} ${theme.spacing.large}`,
          },
        }}
      >
        {/* MODAL LOADING OVERLAY */}
        {isLoadingMutateDeleteTip && (
          <LoadingOverlay
            iconSize="xlarge2"
            backgroundLoadingContainerStyles={{
              borderRadius: theme.spacing.small,
            }}
          />
        )}
        <Box className="mt-16">
          <Heading level={4} color="brand">
            {translate("tips.headings.confirmDelete")}
          </Heading>
        </Box>
        <Box className="flex mt-8 justify-center gap-4">
          <Button
            onClick={() => setDeleteModalVisible(false)}
            color="negative"
            size="small"
          >
            {translate("tips.buttons.cancel")}
          </Button>
          <Button
            onClick={() =>
              !!selectedTip && mutateDeleteTip(String(selectedTip.id))
            }
            color="positive"
            className="flex justify-center"
            disabled={isLoadingMutateDeleteTip}
            size="small"
          >
            {translate("tips.buttons.delete")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
