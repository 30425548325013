import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import { IUserAttribute, IUserAttributeArray } from "@clearabee/api-schemas";
import { ApiRequestData, instance, snakeToNormal } from "@clearabee/ui-sdk";
import { DeleteConfirmationModal } from "../common/components/deleteConfirmationModal";
import { InputInternationalPhoneNumber } from "components/common/components";
import { useAuthContext } from "../../hooks";
import { readRoles, userCompanies } from "api";
import {
  toasts,
  getErrorMessage,
  isCountryCode,
  isValidInternationalNumber,
} from "helpers";
import { updateUser as validation } from "./validation";
import {
  parseRolesDataForSelect,
  parseCompaniesDataForSelect,
  OptionForSelect,
} from "./parser";
import roles from "constants/roles";
import {
  Button,
  Field,
  Heading,
  Input,
  Panel,
  theme,
  Icon,
  Form,
  useModal,
  Text,
  FlexGrid,
  displayErrorMessage,
  Message,
  UnstyledButton,
} from "@clearabee/ui-library";
import { LeftChevron } from "images";
import { BeeLoyalTransactions } from "./components/beeLoyalTransactions";
import { UserJobs } from "./components/userJobs";

interface UpdateUserInitialValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  companies: OptionForSelect[] | null;
  defaultCompany: OptionForSelect[] | null;
  roles: OptionForSelect[] | null;
}

const constantAttrKeyNames = {
  beeLoyalMember: "bee_loyal_member",
  beeLoyalStart: "bee_loyal_start",
  beeLoyalEnd: "bee_loyal_end",
  beeLoyalCredit: "bee_loyal_credit",
  defaultCompany: "default_company",
};

export const UpdateUser = (): React.ReactElement => {
  const { doesUserHaveRole, forgotPassword, getCurrentUserCompanies } =
    useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);

  const history = useHistory();

  const { id: email } = useParams<{ id: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [translate] = useTranslation("users");
  const [SuccessModal, setSuccessModal] = useModal();
  const { data: rolesData, isLoading: isLoadingRoles } = useQuery(
    "readRoles",
    readRoles,
  );
  const rolesOptions = parseRolesDataForSelect(
    rolesData?.items || [],
    isClearabeeAdmin ? true : false,
    isClearabeeManager ? true : false,
  );
  const rolesChoices = rolesOptions && rolesOptions?.length > 0;

  // get all companies
  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery(
    "userCompanies",
    userCompanies,
  );
  /**
   * Companies data.
   */
  const companiesOptions = companiesData
    ? parseCompaniesDataForSelect(
        isClearabeeAdmin || isClearabeeManager
          ? companiesData
          : getCurrentUserCompanies(),
      )
    : [];
  const companiesChoices = companiesOptions && companiesOptions.length > 0;

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserData,
  } = useQuery(
    ["getUser", email],
    async () => (await instance.users.getUser(email)).data,
    {
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
    },
  );
  const { firstName, lastName, companies, phoneNumber } = userData || {};

  /**
   * Get user company
   */
  const userCompany: OptionForSelect[] =
    companies && companies.length > 0
      ? companies.map((item) => ({
          label: item.name,
          value: String(item.id),
        }))
      : [];

  /**
   * Get companies that belong when view user but not to company admin
   */
  const companiesNotBelongToCurrentUser: OptionForSelect[] = !isClearabeeAdmin
    ? userCompany.filter(
        ({ label }) => !JSON.stringify(companiesOptions).includes(label),
      )
    : [];

  const isCompaniesNotBelongExist =
    companiesNotBelongToCurrentUser &&
    !!companiesNotBelongToCurrentUser?.length;

  const checkCompaniesNotBelong = (company: string): boolean => {
    return companiesNotBelongToCurrentUser.some(
      ({ label }) => label === company,
    );
  };

  /**
   * Get user defaultCompany
   */
  const attributeDefaultCompanyValue =
    userData?.attributes?.find(
      ({ attrKey }) => attrKey === constantAttrKeyNames.defaultCompany,
    )?.attrValue ?? null;

  const defaultCompanyInitialValue =
    userCompany?.filter(
      ({ value }) => value === String(attributeDefaultCompanyValue),
    ) ?? [];

  /**
   * Get user roles
   */
  const { roles: userRoles } = userData || {};

  /**
   * check user is a subcontractor user if has role Subcontractor, this includes subcontractor staff and subcontractor admin
   * user can have multiple roles, but if has one role which is Subcontractor, then we will delete
   */
  const isSubcontractorUser =
    !!userRoles &&
    userRoles.length === 1 &&
    !!userRoles.find(({ name }) =>
      name.toLowerCase().includes("subcontractor"),
    );

  /**
   * check user is a clearabee driver user if has one role which is Clearabee Driver
   * user can have multiple roles, but if has one role which is Clearabee Driver, then we will delete
   */
  const isClearabeeDriverUser =
    !!userRoles &&
    userRoles.length === 1 &&
    !!userRoles.find(({ name }) => name.toLowerCase().includes("driver"));

  const existingRoles =
    userRoles && userRoles.length
      ? userRoles
          .map(({ id, name }) => ({ label: name, value: String(id) }))
          /**
           * this will sort existingRoles based on the role value id on database in terms of ascending order
           */
          .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      : [];

  const rolesNotBelongToCurrentUser: OptionForSelect[] = !isClearabeeAdmin
    ? existingRoles.filter(
        ({ label }) => !JSON.stringify(rolesOptions).includes(label),
      )
    : [];

  const isRolesNotBelongExist =
    rolesNotBelongToCurrentUser && !!rolesNotBelongToCurrentUser?.length;

  const { mutate: mutatePasswordReset, isLoading: isPasswordResetLoading } =
    useMutation(
      () => forgotPassword(email, { originUrl: window.location.origin }),
      {
        retry: false,
        onSuccess: () => {
          toasts.success({
            message: translate("user.toasts.sendPasswordResetLinkSuccess"),
          });
        },
        onError: (error: AxiosError) => {
          toasts.error({ message: getErrorMessage(error) || error.message });
        },
      },
    );

  const { mutate: mutateDeleteUser, isLoading: isDeleting } = useMutation(
    async () =>
      await instance.users.deleteUser(email, {
        headers: {
          ...(isSubcontractorUser
            ? {
                actionableuserpoolid:
                  process.env.REACT_APP_PARTNERS_USER_POOL_ID || "",
              }
            : {}),
          ...(isClearabeeDriverUser
            ? {
                actionableuserpoolid:
                  process.env.REACT_APP_DRIVERS_USER_POOL_ID || "",
              }
            : {}),
        },
      }),
    {
      onSuccess: () => {
        history.push(`/users?deleted=${email}`);
      },
      onError: () => {
        toasts.error({
          message: "Failed to delete",
        });
      },
    },
  );

  const {
    mutate: mutateTemporaryPasswordReset,
    isLoading: isLoadingTemporaryPasswordReset,
  } = useMutation(
    () =>
      instance.users.postResendUserTempPassword({
        email,
      }),
    {
      onSuccess: () => {
        toasts.success({
          message: translate(
            "user.toasts.sendTemporaryPasswordResetLinkSuccess",
          ),
        });
      },
      onError: () => {
        toasts.error({
          message: translate("user.toasts.cannotSendTemporaryPassword"),
        });
      },
    },
  );

  const {
    mutate: mutateAttributes,
    isLoading: isLoadingAttributes,
    error: errormutateAttributes,
  } = useMutation(
    async (attributes: IUserAttributeArray) => {
      await instance.users.putUserAttributes(email, {
        attributes: attributes,
      });
    },
    {
      onError: () => {
        toasts.error({
          message: translate("user.toasts.cannotUpdateAttributes"),
        });
      },
      onSuccess: () => {
        setSuccessModal(true);
        refetchUserData();
      },
    },
  );

  const {
    mutate: mutateUser,
    isSuccess: isSuccessUpdate,
    isLoading,
    reset: resetMutationUser,
  } = useMutation(
    (user: ApiRequestData<typeof instance.users.patchUser>) =>
      instance.users.patchUser(email, user),
    {
      onError: () => {
        toasts.error({
          message: translate("user.toasts.cannotUpdateUser"),
        });
      },
      onSuccess: () => {
        setSuccessModal(true);
        refetchUserData();
      },
    },
  );

  const initialValues: UpdateUserInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companies: [],
    defaultCompany: [],
    roles: [],
  };

  const handleSubmit = (values: typeof initialValues) => {
    const { roles, defaultCompany, companies, ...rest } = values;

    const updatedRoles: OptionForSelect[] = roles ?? [];

    if (!isClearabeeAdmin && isRolesNotBelongExist) {
      // check whether final roles value after submit does contain rolesNotBelongExist or not
      const check = rolesNotBelongToCurrentUser.every(({ label }) =>
        JSON.stringify(roles).includes(label),
      );

      if (!check) {
        updatedRoles.push(...rolesNotBelongToCurrentUser);
      }
    }

    // filter default_company and keep the rest of attributes
    const attributes: IUserAttribute[] =
      userData?.attributes?.filter(
        ({ attrKey }) => attrKey !== constantAttrKeyNames.defaultCompany,
      ) ?? [];

    // add updated default_company to attributes if defaultCompany is not empty
    // this defaultCompany is coming from the form, not from the user attributes
    if (!!defaultCompany && defaultCompany.length > 0) {
      attributes.push({
        attrKey: constantAttrKeyNames.defaultCompany,
        attrValue: defaultCompany[0].value,
        userId: userData?.id ?? 0,
      });
    }

    const body = {
      ...rest,
      phoneNumber: isCountryCode(rest.phoneNumber)
        ? phoneNumber
        : rest.phoneNumber,
      roles: updatedRoles.map(({ value }) => parseInt(value)) ?? [],
      company: companies?.[0]?.label ?? "",
      companies:
        companies?.map((item: OptionForSelect) => Number(item.value)) ?? [],
      attributes,
    };

    mutateUser(body);
  };

  /**
   * function to check if attribute exists
   * @param attrKeyName
   * @param attributes
   * @returns
   */
  const checkAttributeExists = (
    attrKeyName: string,
    attributes: IUserAttribute[],
  ) => attributes.some(({ attrKey: key }) => key === attrKeyName);

  /**
   *  function to update attributes for website user
   * @param userId
   * @param attributes
   * @returns
   */
  const updateAttributesForWebUser = (
    userId: number,
    attributes: IUserAttribute[],
  ): IUserAttribute[] => {
    const updatedAttributes = attributes;

    //add beeLoyalMember attribute if it doesn't exist
    if (
      !checkAttributeExists(constantAttrKeyNames.beeLoyalMember, attributes)
    ) {
      updatedAttributes.push({
        attrKey: constantAttrKeyNames.beeLoyalMember,
        attrValue: false,
        userId: userId,
      });
    }

    //add beeLoyalStart attribute if it doesn't exist
    if (!checkAttributeExists(constantAttrKeyNames.beeLoyalStart, attributes)) {
      updatedAttributes.push({
        attrKey: constantAttrKeyNames.beeLoyalStart,
        attrValue: "",
        userId: userId,
      });
    }

    //add beeLoyalEnd attribute if it doesn't exist
    if (!checkAttributeExists(constantAttrKeyNames.beeLoyalEnd, attributes)) {
      updatedAttributes.push({
        attrKey: constantAttrKeyNames.beeLoyalEnd,
        attrValue: "",
        userId: userId,
      });
    }

    //add beeLoyalCredit attribute if it doesn't exist
    if (
      !checkAttributeExists(constantAttrKeyNames.beeLoyalCredit, attributes)
    ) {
      updatedAttributes.push({
        attrKey: constantAttrKeyNames.beeLoyalCredit,
        attrValue: 0,
        userId: userId,
      });
    }

    return updatedAttributes;
  };

  /**
   * check if user is a web user
   * web user doesn't have roles and companies
   */
  const isWebUser = !userData?.roles?.length && !userData?.companies?.length;

  const userAttributes = !isWebUser
    ? userData?.attributes
    : updateAttributesForWebUser(userData?.id ?? 0, userData?.attributes ?? []);

  const pickAttributeInput = (
    attrKey: string,
    attrValue: string,
    field: any,
  ) => {
    switch (attrKey) {
      case constantAttrKeyNames.defaultCompany:
        return (
          <Input.Text
            {...field}
            disabled
            value={
              userCompany?.find(
                ({ value }) => String(value) === String(attrValue),
              )?.label
            }
          />
        );
      case constantAttrKeyNames.beeLoyalMember:
        return (
          <Input.Toggle
            {...field}
            value={String(!!attrValue)}
            defaultChecked={!!attrValue}
          />
        );
      case constantAttrKeyNames.beeLoyalCredit:
        return (
          <Input.Text
            {...field}
            disabled={
              ![
                constantAttrKeyNames.beeLoyalCredit,
                constantAttrKeyNames.beeLoyalMember,
              ].includes(attrKey)
            }
            value={attrValue}
          />
        );
      case constantAttrKeyNames.beeLoyalStart:
        const utcStartDate = !!attrValue?.length
          ? dayjs(attrValue).toISOString()
          : new Date().toISOString();
        return (
          <Input.Date
            {...field}
            initialValue={dayjs(utcStartDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD",
            )}
            dateFormat="YYYY-MM-DD"
            collapsable
            required={false}
            onReset={undefined}
          />
        );
      case constantAttrKeyNames.beeLoyalEnd:
        const utcEndDate = !!attrValue?.length
          ? dayjs(attrValue).toISOString()
          : new Date().toISOString();
        return (
          <Input.Date
            {...field}
            initialValue={dayjs(utcEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")}
            dateFormat="YYYY-MM-DD"
            collapsable
            onReset={undefined}
          />
        );
      default:
        return <Input.Text {...field} disabled value={attrValue} />;
    }
  };

  const handleSubmitUpdateAttributes = (values: IUserAttributeArray) => {
    const attributesWithRequiredValues = [
      constantAttrKeyNames.beeLoyalStart,
      constantAttrKeyNames.beeLoyalEnd,
    ];

    const attributes = values
      .filter(
        ({ attrValue, attrKey }) =>
          (attrKey !== constantAttrKeyNames.beeLoyalStart &&
            attrKey !== constantAttrKeyNames.beeLoyalEnd) ||
          !!attrValue,
      )
      .map((item: IUserAttribute) => {
        if (!!attributesWithRequiredValues.includes(item.attrKey)) {
          return { ...item, attrValue: dayjs(item.attrValue).toISOString() };
        }
        return item;
      });

    mutateAttributes(attributes);
  };

  return (
    <div className="py-5">
      {isLoadingUser || isLoadingCompanies ? (
        <Icon.Loading color="brand" styles={{ margin: "auto" }} />
      ) : (
        <div className="mb-10">
          <UnstyledButton
            onClick={() => history.goBack()}
            className="text-sm inline-flex items-center justify-start"
          >
            <span>
              <LeftChevron />
            </span>
            <span className="ml-2">
              {translate("user.buttons.backToUsers")}
            </span>
          </UnstyledButton>

          <>
            <Form
              initialValues={{
                ...initialValues,
                firstName: firstName ?? "",
                lastName: lastName ?? "",
                email,
                companies: userCompany ?? [],
                phoneNumber: isValidInternationalNumber(phoneNumber)
                  ? phoneNumber
                  : "",
                defaultCompany: defaultCompanyInitialValue ?? [],
                roles: existingRoles,
              }}
              validationSchema={validation}
              isInitialValid
              onSubmit={handleSubmit}
            >
              {({ isValid, values, setFieldValue }) => (
                <div>
                  <Panel styles={{ marginTop: theme.spacing.small }}>
                    <Heading color="brand" level={2} fontSize="xlarge">
                      {translate("user.titles.manageUser")}
                    </Heading>
                    <div className="flex flex-row flex-wrap -mx-3">
                      <div className="w-1/3 p-3">
                        <Field
                          name="firstName"
                          label={translate("user.form.labels.firstName")}
                        >
                          {({ field }) => <Input.Text {...field} />}
                        </Field>
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          name="lastName"
                          label={translate("user.form.labels.lastName")}
                        >
                          {({ field }) => <Input.Text {...field} />}
                        </Field>
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          name="email"
                          label={translate("user.form.labels.emailAddress")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              data-lpignore="true"
                              autoComplete="off"
                              onChange={() =>
                                setFieldValue("email", values.email)
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          name="phoneNumber"
                          label={translate("user.form.labels.phoneNumber")}
                        >
                          {({ field }) => (
                            <InputInternationalPhoneNumber {...field} />
                          )}
                        </Field>

                        {!!phoneNumber &&
                          !isValidInternationalNumber(phoneNumber) && (
                            <div>
                              <Message background type="warn" small>
                                {`The phone number for this user (${phoneNumber}) is not a valid international phone number, please update it.`}
                              </Message>
                            </div>
                          )}
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          styles={{
                            flex: 1,
                          }}
                          name="companies"
                          label={translate("user.form.placeholders.companies")}
                        >
                          {({ field }) => (
                            <Select
                              {...field}
                              placeholder={
                                isLoadingCompanies
                                  ? "Loading..."
                                  : translate(
                                      "user.form.placeholders.searchCompaniesValue",
                                    )
                              }
                              isClearable
                              isSearchable
                              isMulti
                              isDisabled={!companiesChoices}
                              options={companiesOptions}
                              onChange={(options: OptionForSelect[]) => {
                                if (options === null) {
                                  // if companiesNotBelongToCurrentUser exist, set it as the value of companies and first item in a list to defaultCompany
                                  if (isCompaniesNotBelongExist) {
                                    setFieldValue(
                                      "companies",
                                      companiesNotBelongToCurrentUser,
                                    );

                                    // only reset defaultCompany if it's not in the list of companiesNotBelongToCurrentUser
                                    if (
                                      !companiesNotBelongToCurrentUser.find(
                                        ({ label }) =>
                                          label ===
                                          values.defaultCompany?.[0]?.label,
                                      )
                                    ) {
                                      setFieldValue("defaultCompany", []);
                                    }
                                    return;
                                  }

                                  setFieldValue("companies", []);
                                  setFieldValue("defaultCompany", []);
                                  return;
                                }

                                // If defaultCompany is not in the list of companies, remove it
                                if (
                                  !!values?.defaultCompany &&
                                  !!values.defaultCompany.length &&
                                  !options?.find(
                                    ({ label }) =>
                                      label ===
                                      values?.defaultCompany?.[0].label,
                                  )
                                ) {
                                  setFieldValue("defaultCompany", []);
                                }

                                setFieldValue("companies", options);
                              }}
                              className="text-base"
                              styles={{
                                multiValue: (base, state) => {
                                  return isCompaniesNotBelongExist &&
                                    checkCompaniesNotBelong(state.data.label)
                                    ? { ...base, backgroundColor: "gray" }
                                    : base;
                                },
                                multiValueLabel: (base, state) => {
                                  return isCompaniesNotBelongExist &&
                                    checkCompaniesNotBelong(state.data.label)
                                    ? {
                                        ...base,
                                        fontWeight: "bold",
                                        color: "white",
                                        paddingRight: 6,
                                      }
                                    : base;
                                },
                                multiValueRemove: (base, state) => {
                                  return isCompaniesNotBelongExist &&
                                    checkCompaniesNotBelong(state.data.label)
                                    ? { ...base, display: "none" }
                                    : base;
                                },
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          name="roles"
                          label={translate("user.form.labels.roles")}
                        >
                          {({ field }) => (
                            <Select
                              {...field}
                              isClearable
                              isSearchable
                              isMulti
                              isDisabled={
                                isLoadingRoles ||
                                !rolesChoices ||
                                isCompaniesNotBelongExist
                              }
                              placeholder={
                                isLoadingRoles
                                  ? "Loading..."
                                  : translate(
                                      "user.form.placeholders.selectRoles",
                                    )
                              }
                              options={rolesOptions ?? []}
                              onChange={(values: OptionForSelect[]) => {
                                if (values === null)
                                  return setFieldValue("roles", []);

                                setFieldValue("roles", values);
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="w-1/3 p-3">
                        <Field
                          styles={{
                            flex: 1,
                          }}
                          name="defaultCompany"
                          label={translate("user.form.labels.defaultCompany")}
                        >
                          {({ field }) => (
                            <Select
                              {...field}
                              placeholder={
                                isLoadingCompanies
                                  ? "Loading..."
                                  : translate(
                                      "user.form.placeholders.searchDefaultCompanyValue",
                                    )
                              }
                              isMulti={false}
                              isClearable
                              isSearchable
                              isDisabled={!companiesChoices}
                              options={values?.companies ?? []}
                              onChange={(options: OptionForSelect[]) => {
                                if (options === null) {
                                  return setFieldValue("defaultCompany", []);
                                }

                                setFieldValue("defaultCompany", [options]);
                              }}
                              className="text-base"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </Panel>
                  <div
                    style={{
                      marginTop: theme.spacing.xlarge,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      gap: theme.spacing.small,
                    }}
                  >
                    <Button
                      type="submit"
                      color="accent"
                      size="small"
                      onClick={() => handleSubmit(values)}
                      disabled={
                        !isValid ||
                        isLoading ||
                        isSuccessUpdate ||
                        isClearabeeManager
                      }
                    >
                      {translate("user.buttons.updateUser")}
                    </Button>
                    <Button
                      onClick={() => mutatePasswordReset()}
                      size="small"
                      variant="outline"
                      type="button"
                      disabled={isPasswordResetLoading || isSuccessUpdate}
                    >
                      {translate("user.buttons.passwordResetLink")}
                    </Button>
                    {isClearabeeAdmin && !!email && (
                      <Button
                        onClick={() => mutateTemporaryPasswordReset()}
                        size="small"
                        variant="outline"
                        type="button"
                        className="flex gap-x-3"
                        disabled={
                          isLoadingTemporaryPasswordReset || isSuccessUpdate
                        }
                      >
                        {isLoadingTemporaryPasswordReset && (
                          <Icon.Loading size="small" color="brand" />
                        )}
                        {translate("user.buttons.temporaryPasswordReset")}
                      </Button>
                    )}
                    <Button
                      onClick={() => setShowDeleteModal(true)}
                      size="small"
                      type="button"
                      color="negative"
                      disabled={
                        isDeleting || isSuccessUpdate || isClearabeeManager
                      }
                    >
                      {translate("user.buttons.deleteUser")}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
            {!!userAttributes?.length && (
              <Form
                onSubmit={(values) => {
                  handleSubmitUpdateAttributes(values.attributes);
                }}
                initialValues={{
                  attributes: userAttributes ?? [],
                }}
              >
                {({ values, setValues }) => {
                  // reset values when isSuccessUpdate is true
                  useEffect(() => {
                    if (
                      isSuccessUpdate &&
                      !!userAttributes &&
                      !!userAttributes.length
                    ) {
                      setValues({ attributes: userAttributes });
                    }
                  }, [isSuccessUpdate, userData?.attributes]);

                  return (
                    <>
                      {isClearabeeAdmin && (
                        <Panel className="mt-10">
                          <>
                            <div className="flex justify-between items-center">
                              <Heading
                                color="brand"
                                level={2}
                                fontSize="xlarge"
                              >
                                {translate("user.titles.userAttributes")}
                              </Heading>
                              <Button type="submit" size="small">
                                {isLoadingAttributes ? (
                                  <Icon.Loading
                                    color="brand"
                                    size="small"
                                    styles={{ margin: "auto" }}
                                  />
                                ) : (
                                  translate("user.buttons.updateAttributes")
                                )}
                              </Button>
                            </div>
                            {!values.attributes?.length && (
                              <Text>
                                {translate("errors.noUserAttributes")}
                              </Text>
                            )}
                            {!!values.attributes?.length && (
                              <FlexGrid cellSpacing="small" rowSpacing="small">
                                {values.attributes.map(
                                  ({ attrKey, attrValue }, index) => {
                                    return (
                                      <FlexGrid.Cell
                                        key={`attributes-${attrKey}-${index}`}
                                      >
                                        <Field
                                          name={`attributes[${index}].attrValue`}
                                          label={snakeToNormal(attrKey)}
                                        >
                                          {({ field }) => (
                                            <>
                                              {pickAttributeInput(
                                                attrKey,
                                                attrValue,
                                                field,
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FlexGrid.Cell>
                                    );
                                  },
                                )}
                              </FlexGrid>
                            )}
                            {!!errormutateAttributes && (
                              <div className="flex w-full mt-5">
                                {displayErrorMessage(
                                  errormutateAttributes ??
                                    translate("errors.updateAttributes"),
                                  ({ children }) => (
                                    <div className="flex items-center justify-center w-full mb-4 overflow-scroll">
                                      <Message type="error" background>
                                        {children}
                                      </Message>
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </>
                        </Panel>
                      )}
                    </>
                  );
                }}
              </Form>
            )}
          </>
        </div>
      )}

      {!isLoadingUser && isClearabeeAdmin && (
        <>
          <FlexGrid>
            <FlexGrid.Cell base="100%" xlarge="50%">
              <Panel>
                <UserJobs email={email} />
              </Panel>
            </FlexGrid.Cell>

            <FlexGrid.Cell base="100%" xlarge="50%">
              <Panel>
                <BeeLoyalTransactions email={email} />
              </Panel>
            </FlexGrid.Cell>
          </FlexGrid>
        </>
      )}

      <SuccessModal
        onClose={() => {
          setSuccessModal(false);
          resetMutationUser();
        }}
        heading={translate("user.modals.headings.updateUserSuccess")}
        width={500}
        actions={
          <Button
            className="mt-3"
            size="medium"
            onClick={() => history.push("/users")}
          >
            {translate("user.buttons.backToUsers")}
          </Button>
        }
      />

      <DeleteConfirmationModal
        title="Are you sure you want to delete this user?"
        visible={showDeleteModal}
        deleteCallback={() => mutateDeleteUser()}
        hideCallback={() => {
          setShowDeleteModal(false);
        }}
        yesButtonLabel="Delete"
      />
    </div>
  );
};
