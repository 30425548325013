/**
 * Import types
 */
import { ISupplier } from "components/suppliers/types";
import {
  ICompany,
  ICompanyUser,
  IPaginatedResults,
  IUserRole,
} from "../../api/types";

export type OptionForSelect = {
  label: string;
  value: string;
};

export const parseCompaniesDataForSelect = (
  items: ICompany[],
): OptionForSelect[] => {
  return items.map(({ name, id }) => ({
    label: name,
    value: String(id),
  }));
};

export const parseSuppliersDataForSelect = (
  items: ISupplier[],
): OptionForSelect[] => {
  return items.map(({ name, id }) => ({
    label: name,
    value: String(id),
  }));
};

export const parseCompaniesDataForFilter = (
  items: ICompany[],
): OptionForSelect[] => {
  return items.map(({ name, companyCode }) => ({
    label: name,
    value: String(companyCode),
  }));
};

export const parseRolesDataForSelect = (
  items: IUserRole[],
  isClearabeeAdmin: boolean,
  isClearabeeManager?: boolean,
): OptionForSelect[] => {
  /**
   * this array is fixed for company Admin user, only change if there are updates of new roles for company admin
   *  Company Admin
   *  Company Staff
   *  View Only
   *  Book Only
   *  Analyst
   *  Finance Analyst
   */
  const exclusiveRolesName: string[] = [
    "Company Administrator",
    "Company Staff",
    "View Only",
    "Book Only",
    "Analyst",
    "Finance Analyst",
  ];

  const allRoles = items.map(({ id, name }) => ({
    label: name,
    value: String(id),
  }));

  if (isClearabeeAdmin || isClearabeeManager) return allRoles;

  return allRoles.filter(({ label }) => exclusiveRolesName.includes(label));
};

export const parseUsersDataForSelect = ({
  items,
}: IPaginatedResults<ICompanyUser>): OptionForSelect[] => {
  const users: OptionForSelect[] = [];

  if (items?.length > 0) {
    items.forEach((user) => {
      const { firstName, lastName, email } = user;
      const option = {
        label: `${firstName} ${lastName}`,
        value: email,
      };

      users.push(option);
    });
  }

  return users;
};
