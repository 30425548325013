import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Box, Text, Panel, theme } from "@clearabee/ui-library";
import { CatalogueItem } from "models";
import { useMultiFormContext } from "../../../../hooks";
import { Quantity } from "../../../core";
import { Item } from "../../../../models/item";
import { ExtendedBasketItem, IFormState } from "../types";

interface IProductPriceInput {
  sku: string;
  originalPrice: number;
  onPriceOverride: (sku: string, price: number) => void;
}

interface ViewProductsProps {
  products: CatalogueItem[];
}

const roundPrice = (price: number): number => {
  return Math.round(price * 100) / 100;
};

const ProductPriceInput = ({
  sku,
  originalPrice,
  onPriceOverride,
}: IProductPriceInput): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState<string | undefined>(
    originalPrice.toString(),
  );
  const defaultClasses = "bg-gray-300 text-black font-bold p-1";
  const [inputClasses, setInputClasses] = useState(defaultClasses);
  const floatVal = parseFloat(price || "0");

  const handleProductPriceChange = (value: string) => {
    setPrice((parseFloat(value) || 0).toString());
    if (value.charAt(value.length - 1) === "." && !price?.includes("."))
      setPrice(value);
  };

  const handleBlur = () => {
    if (floatVal !== originalPrice) onPriceOverride(sku, floatVal);
    setInputClasses(defaultClasses);
  };

  return (
    <span className="pl-2 bg-gray-300 font-bold text-black inline-flex items-center">
      £
      <input
        type="text"
        className={cx("inline-block px-1", inputClasses)}
        ref={inputRef}
        value={price ? price : ""}
        onFocus={() => {
          setInputClasses("bg-gray-300 text-black p-1 font-bold");
        }}
        onChange={(e) => handleProductPriceChange(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setPrice(roundPrice(floatVal || 0).toString());
            if (inputRef.current) inputRef.current.blur();
          }
        }}
      />
    </span>
  );
};

/**
 * view product options.
 */
export const ViewProducts = ({
  products,
}: ViewProductsProps): React.ReactElement => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("jobs");

  const { formState, pushState } = useMultiFormContext<IFormState>();
  const { items: formStateItems } = formState;

  const [priceOverrides, setPriceOverrides] = useState<{
    [sku: string]: number;
  }>({});
  const [items, setItems] = useState<ExtendedBasketItem[]>(
    formStateItems || [],
  );

  const addItem = (newItem: ExtendedBasketItem): void => {
    if (!newItem.quantity) return;

    const isAlreadyInItems =
      items.filter((basketItem) => basketItem.sku === newItem.sku).length > 0;

    const basketCopy = [...items];

    if (newItem.quantity < 0 && !isAlreadyInItems) {
      throw Error("You cant add an item to the basket with negative quantity!");
    }

    if (newItem.quantity > 100) {
      newItem.quantity = 100;
    }

    if (!isAlreadyInItems) {
      // add to basket
      return setItems([...basketCopy, newItem]);
    }

    // update quantity in basket
    const itemIndex = basketCopy.findIndex(
      (basketItem) => basketItem.sku === newItem.sku,
    );
    if (itemIndex === -1) {
      // failed to find item in array..
      throw Error(
        "Cant find the index of the item, even though it exists in our array",
      );
    }
    basketCopy[itemIndex].quantity = newItem.quantity;
    basketCopy[itemIndex].price = newItem.price;
    setItems(basketCopy);
  };

  const getItem = (sku: string): ExtendedBasketItem | null => {
    return items.find((item) => item.sku === sku) || null;
  };

  const updateItemPrice = (sku: string, newPrice: number): void => {
    const existing = getItem(sku);

    if (!existing) return;

    existing.price = newPrice;

    const filteredBasketItems = items.filter(
      ({ sku: itemSku }) => sku !== itemSku,
    );

    setItems([...filteredBasketItems, existing]);
  };

  const deleteItem = (itemSku: ExtendedBasketItem["sku"]): void => {
    const basketItemsCopy = [...items];

    const remainingItems = basketItemsCopy.filter(
      (basketItem) => basketItem.sku !== itemSku,
    );

    setItems(
      remainingItems.length === 1 && remainingItems[0].isCalloutCharge
        ? []
        : remainingItems,
    );
  };

  /**
   * Handle qty change
   */
  const handleQtyChange = (product: Item, qty: number) => {
    if (!qty) deleteItem(product.sku);

    const foundProduct = formStateItems.find(
      (item) => item.sku === product.sku,
    );

    addItem({
      title: product.title,
      sku: product.sku,
      quantity: qty,
      price:
        foundProduct?.price ||
        priceOverrides[product.sku as keyof typeof priceOverrides] ||
        product.price ||
        0,
    });
  };

  const handlePriceOverride = (sku: string, newPrice: number) => {
    const copy = priceOverrides;
    copy[sku] = newPrice;
    setPriceOverrides(copy);
    // also amend price of existing items in basket
    updateItemPrice(sku, newPrice);
  };

  useEffect(() => {
    pushState({
      ...formState,
      items: items,
    });
  }, [JSON.stringify(items)]);

  return (
    <Box className="w-full max-w-screen-sm md:px-16 mx-auto">
      {products.map((product) => {
        const foundProduct = formStateItems.find(
          (item) => item.sku === product.sku,
        );

        const isProductSelected = !!foundProduct && foundProduct.quantity > 0;

        return (
          <Panel
            key={product.sku}
            style={{
              display: "flex",
              justifyContent: "start",
              gap: theme.spacing.small,
              padding: theme.spacing.small,
              marginBottom: theme.spacing.xsmall,
              borderRadius: theme.spacing.xsmall,
              backgroundColor: isProductSelected
                ? theme.colors.warning.base
                : "",
            }}
            shadow={false}
          >
            <Box className="flex items-center">
              <Quantity
                start={foundProduct?.quantity || 0}
                updateQuantity={(qty) => handleQtyChange(product, qty)}
              />
            </Box>
            <Box className="w-2/3 flex justify-start items-center truncate">
              <Text fontSize="small" className="font-semibold truncate">
                {product.title || "N/A"}
              </Text>
            </Box>
            <Box className="w-1/3 overflow-hidden">
              <ProductPriceInput
                originalPrice={foundProduct?.price || product.price || 0}
                sku={product.sku}
                onPriceOverride={handlePriceOverride}
              />
            </Box>
          </Panel>
        );
      })}
    </Box>
  );
};
