import {
  getBasket,
  getBasketImagePresignedUrl,
  uploadImageToS3,
} from "../../../api";
import { IImage } from "../types";

/**
 * Upload a single image to S3 bucket.
 */
export const uploadImage = async (
  file: File,
  basketToken: string,
): Promise<string> => {
  const { name: filename } = file;
  const presignedUrlResponse = await getBasketImagePresignedUrl(
    basketToken,
    /**
     * replace ' character in filename as it will break JSON object
     * Error message:  "message": "Could not parse request body into json:
     * Could not parse payload into json: Unrecognized character escape \'\'\'
     */
    encodeURIComponent(filename.replace("'", "")),
    "photo",
  );
  const { url, fields } = presignedUrlResponse;

  return uploadImageToS3(file, url, fields);
};

/**
 * Polls a basket for image uploads being attached to it since this is done asynchronously in ms.
 * Times out after 9 seconds (3 attempts)
 */
export const pollBasketForUploads = async (
  basketToken: string,
  imageCount: number,
  tries = 0,
): Promise<boolean> => {
  const basket = await getBasket(basketToken);

  if (!basket) throw Error("No basket!");

  if (basket.uploads.length === imageCount) return true;

  // Return false if no images after 3 attempts
  if (tries > 2) return false;
  // wait 3 seconds
  await new Promise((res) => setTimeout(() => res(true), 3000));

  return pollBasketForUploads(basketToken, imageCount, tries + 1);
};

/**
 * Helper function to upload all images selected by user.
 * Also waits and checks images have been attached to the basket
 */
export const uploadImages = async (
  images: IImage[],
  basketToken: string,
): Promise<void> => {
  if (!images.length) {
    return;
  }

  const promises = images.map(async (image: IImage) => {
    return uploadImage(image.file, basketToken);
  });

  await Promise.all(promises);
  const imagesUploaded = await pollBasketForUploads(basketToken, images.length);

  if (!imagesUploaded)
    throw Error(`Images not found on basket for basket [${basketToken}]`);
};
