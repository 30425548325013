import * as Yup from "yup";

export const reportValidationSchema = Yup.object().shape({
  purchaseOrderNumber: Yup.string(),
  invoiceNumber: Yup.string(),
  invoiceAmount: Yup.string(),
  creditAmount: Yup.string(),
  weight: Yup.string(),
  volume: Yup.string(),
  mattress: Yup.string(),
  fridges: Yup.string(),
  tvMonitor: Yup.string(),
  smallPops: Yup.string(),
  largePops: Yup.string(),
  footstool: Yup.string(),
  armchair: Yup.string(),
  sofaTwoSeat: Yup.string(),
  sofaThreeSeat: Yup.string(),
  bed: Yup.string(),
  sofabed: Yup.string(),
  cornerSofa: Yup.string(),
  otherItems: Yup.string(),
  description: Yup.string(),
});
