import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import {
  Box,
  Button,
  Field,
  Input,
  Panel,
  theme,
  Text,
  Icon,
} from "@clearabee/ui-library";
import { useMutation } from "react-query";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";

type Job = ApiResponseData<typeof instance.jobs.getJobByRef>;

interface AddJobProps {
  jobs: Job[];
  addJob: (job: Job) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const AddJob = ({
  jobs,
  addJob,
  isDisabled = false,
  isLoading = false,
}: AddJobProps): React.ReactElement => {
  const {
    mutate: mutateJobData,
    isLoading: jobIsLoading,
    isError: isGetJobFailed,
  } = useMutation(
    async (jobRef: string) => {
      return (await instance.jobs.getJobByRef(encodeURIComponent(jobRef))).data;
    },
    {
      onSuccess: (data) => {
        setFieldValue(`jobs[${values.jobs.length}]`, {
          invoice: 0,
        });

        addJob(data);
        setFieldValue("searchedRef", "");
      },
    },
  );

  const [translate] = useTranslation("subcontractors");
  const { values, setFieldValue }: FormikProps<FormikValues> =
    useFormikContext();
  const preventFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const jobAlreadyExists = jobs.some(
    ({ ref }) => ref === values.searchedRef.replace(/\s/g, ""),
  );

  return (
    <Box className="relative">
      {isLoading && (
        <Box className="absolute w-full h-full z-10 flex justify-center items-center">
          <Icon.Loading />
        </Box>
      )}
      <Box
        backgroundColor="light.base"
        className={isLoading ? "opacity-40" : ""}
      >
        <Panel
          styles={{
            padding: `${theme.spacing.small} ${theme.spacing.large}`,
            marginBottom: theme.spacing.xlarge2,
          }}
        >
          <Box className="flex justify-between items-center">
            <Box className="flex min-h-28">
              <Field
                label={translate("allocateJobs.labels.jobReference")}
                name="searchedRef"
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    onKeyDown={preventFormSubmit}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Box>
            <Box>
              <Button
                size="medium"
                type="button"
                className="flex justify-center"
                onClick={() => {
                  mutateJobData(values.searchedRef.replace(/\s/g, ""));
                }}
                disabled={
                  !values.searchedRef || jobIsLoading || jobAlreadyExists
                }
              >
                {jobIsLoading ? (
                  <Icon.Loading size="small" />
                ) : (
                  translate("allocateJobs.buttons.search")
                )}
              </Button>
            </Box>
          </Box>
          {isGetJobFailed && (
            <Box className="flex -mt-3 items-center gap-2">
              <Icon.Attention size="small" color="negative" />
              <Text fontSize="xsmall" color="negative">
                {translate("allocateJobs.errors.jobIsNotFound")}
              </Text>
            </Box>
          )}
        </Panel>
      </Box>
    </Box>
  );
};
