import { statusMap } from "./updateWorksheetHelpers";
import type { IWorksheet } from "@clearabee/api-schemas";

export type InitialValues = {
  name: string;
  statusId?: keyof typeof statusMap | "";
  includeForSnsEvents: boolean;
  orderTypeIds: number[];
  showForSubcontractor: boolean;
};

export const getInitialValues = (worksheet: IWorksheet): InitialValues => {
  return {
    name: worksheet.name ?? "",
    statusId: worksheet.statusId ?? "",
    includeForSnsEvents: worksheet.includeForSnsEvents ?? false,
    orderTypeIds: worksheet.orderTypes.map(({ id }) => id as number),
    // since showForSubcontractor value is 1 or 0, we can use the logical not operator to convert it to a boolean
    showForSubcontractor: Boolean(worksheet.showForSubcontractor ?? false),
  };
};
