import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  editContainer: {
    maxHeight: "80vh",
    overflowY: "scroll",
    width: "100%",
    textAlign: "left",
  },
  labelText: {
    ...theme.fontDefaults.small,
    paddingTop: theme.spacing.small,
    fontWeight: 800,
  },
  editModalStyles: {
    textAlign: "center",
    [`@media (min-width: ${theme.screens.medium})`]: {
      padding: `0 0 ${theme.spacing.xlarge} 0`,
    },
  },
});
