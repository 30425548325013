import React from "react";
import {
  Box,
  Icon,
  UnstyledButton,
  Heading,
  Text,
} from "@clearabee/ui-library";
import { ProgressBar } from "../../../core";
import { useMultiFormContext } from "../../../../hooks";
import { useTranslation } from "react-i18next";

interface StepsWrapperProps {
  children: React.ReactNode;
}

export const StepsWrapper = ({
  children,
}: StepsWrapperProps): React.ReactElement => {
  const [t] = useTranslation("jobs");
  const { activeStep, steps, setStep, previousStep } = useMultiFormContext();

  const step = steps[activeStep];

  const progressBarItems: string[] = steps.map((step) => step.name || "");

  return (
    <>
      <Box
        styles={{
          display: "flex",
          alignItems: "center",
          justifyContent: activeStep > 0 ? "space-between" : "flex-end",
        }}
      >
        {activeStep > 0 && (
          <UnstyledButton
            className="flex items-center gap-x-1 w-full md:pr-5 md:w-1/3"
            onClick={() => previousStep()}
          >
            <Icon.Chevron
              size="small"
              styles={{
                transform: "rotate(180deg)",
              }}
            />
            {t("create.buttons.back")}
          </UnstyledButton>
        )}
        <ProgressBar
          items={progressBarItems}
          currentItem={activeStep}
          setStep={setStep}
        />
      </Box>
      <Box className="py-12 md:pb-6 text-center">
        <Heading level={2} color="brand">
          {step.name}
        </Heading>
        <Box className="mt-1">
          <Text>{step.description}</Text>
        </Box>
      </Box>
      {children}
    </>
  );
};
