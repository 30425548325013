import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useAuthContext } from "hooks";
import { handleException } from "helpers/handleException";
import { IBasketContact } from "models/basket";
import { ApiResponseData, instance, stringToDate } from "@clearabee/ui-sdk";
import {
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Message,
  Text,
  createResponsiveStyles,
  theme,
  useModal,
} from "@clearabee/ui-library";
import { getErrorMessage } from "helpers";

const initialValues = {
  newCollectionDate: "",
};

type BookJobAgainProps = {
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const BookJobAgain = ({
  jobData,
  isOpen,
  setIsOpen,
}: BookJobAgainProps): React.ReactElement => {
  const history = useHistory();
  const [translate] = useTranslation("jobs");
  const [DuplicateJobModal, setDuplicateJobModal] = useModal();
  const [newRef, setNewRef] = useState("");

  const { getCurrentUserCurrentCompany } = useAuthContext();
  const { sicCode } = getCurrentUserCurrentCompany() || {};

  const companyCode = jobData?.companyCode ?? "";

  const items =
    jobData.items?.map((item) => {
      return {
        sku: item.sku,
        qty: item.qty,
      };
    }) ?? [];

  // get company catalouge Id
  const { data: companyCatalogueIdData } = useQuery(
    ["getCompanyCatalogueId", companyCode],
    async () =>
      (await instance.catalogues.getCatalogueByCompanyCode(companyCode)).data[
        "id"
      ],
    {
      retry: false,
      enabled: !!companyCode,
    },
  );

  const companyCatalogueId = companyCatalogueIdData ?? "portal";

  // get new job id by ref
  const { data: newJobId } = useQuery(
    ["getNewJobId", newRef],
    async () =>
      (await instance.jobs.getJobByRef(encodeURIComponent(newRef))).data["id"],
    {
      enabled: !!newRef,
    },
  );

  // get blackouts
  const { data: disabledBlackouts, isLoading: isLoadingDisabledBlackouts } =
    useQuery(
      ["readItemsBlackouts", items, companyCatalogueId],
      async () => {
        const blackoutDates: any[] = [];
        const blackoutDays: any[] = [];
        const cutoffTime: any[] = [];

        for await (const item of items) {
          const blackoutItem = (
            await instance.catalogues.getCatalogueItemBlackouts(
              companyCatalogueId ?? "portal",

              item.sku,
            )
          ).data;

          for (const element of blackoutItem) {
            if (typeof element === "string") blackoutDates.push(element);

            if (
              typeof element === "object" &&
              element.hasOwnProperty("before")
            ) {
              cutoffTime.push(element);
            }
            if (
              typeof element === "object" &&
              element.hasOwnProperty("daysOfWeek")
            ) {
              blackoutDays.push(element);
            }
          }
        }

        const updatedBlackoutDays = blackoutDays.flatMap(
          ({ daysOfWeek }: { daysOfWeek: number[] }) => daysOfWeek,
        );

        const updatedCutoffTime = cutoffTime
          .flatMap(({ before }: { before: string }) =>
            Number(dayjs.utc(before).format("x")),
          )
          .sort((a, b) => a - b);

        const formattedCutoffTime = stringToDate(
          dayjs(updatedCutoffTime[0]).format("YYYY-MM-DD"),
        );

        return [
          ...(!!blackoutDates.length ? stringToDate(blackoutDates) : []),
          {
            daysOfWeek: !!updatedBlackoutDays.length
              ? updatedBlackoutDays
              : [0],
          },
          { before: formattedCutoffTime ?? new Date() },
        ];
      },
      {
        enabled: !!items.length && !!companyCatalogueId,
      },
    );

  // open modal when state changes
  useEffect(() => {
    if (isOpen) setDuplicateJobModal(true);
  }, [isOpen]);

  const {
    isLoading,
    isSuccess,
    error,
    reset: resetBookJobAgain,
    mutate: mutateBookJobAgain,
  } = useMutation(
    async (values: typeof initialValues) => {
      const { companyCode, description, accessInformation, meta } = jobData;

      const customCustomer = {
        firstName: jobData.contactFirstName,
        lastName: jobData.contactLastName,
        email: jobData.contactEmail,
        phoneNumber: jobData.contactPhoneNumber,
      } as IBasketContact;

      const collectionAddress = {
        line1: jobData.addressLine1 as string,
        line2: jobData.addressLine2 as string,
        city: jobData.addressCity,
        county: jobData?.addressCounty ?? "",
        postcode: jobData.addressPostcode,
      };

      const customFields =
        !!jobData?.customFields && !!jobData.customFields.length
          ? jobData.customFields
          : [];

      const data = {
        catalogueId: companyCatalogueId,
        deliveryAddress: collectionAddress,
        date: dayjs.utc(values?.newCollectionDate).toISOString(),
        contact:
          !customCustomer.firstName &&
          !customCustomer.lastName &&
          !customCustomer.email &&
          !customCustomer.phoneNumber
            ? undefined
            : customCustomer,
        // deliveryCharge, timeslot, timeslotpreferene will be changed when we add extra field
        items,
        description,
        accessInformation,
        meta: { ...meta, bookingSource: "Portal book again flow" },
        companyCode,
        sicCode,
        customFields,
      };

      // create new basket
      const { data: basket } = await instance.catalogues.postBasket(data);

      // handle invoice
      await instance.catalogues.postBasketInvoice(basket.basketToken, {
        purchaseOrderNumber: meta?.poNumber,
      });

      setNewRef(basket?.orderRef);
    },
    {
      onError: (error) => {
        handleException(
          error,
          {},
          { type: "Create Job - Portal book again flow" },
          "",
          false,
        );
      },
    },
  );

  return (
    <DuplicateJobModal
      width={700}
      heading={translate(
        `bookJobAgain.headings.${isSuccess ? "bookSuccess" : "book"}`,
      )}
      styles={{
        backgroundColor: theme.colors.light.base,
        ":before": { backgroundColor: theme.colors.light.base },
        ...createResponsiveStyles({
          medium: {
            padding: theme.spacing.xlarge2,
          },
        }),
      }}
      onClose={() => {
        setNewRef("");
        setDuplicateJobModal(false);
        setIsOpen(false);
        resetBookJobAgain();
      }}
    >
      <Form
        renderFormElement={false}
        initialValues={initialValues}
        onSubmit={() => {
          return;
        }}
      >
        {({ resetForm, values }) => (
          <>
            {!!error ? (
              <div className="w-full mt-5">
                <div className="flex items-center justify-center w-full mb-4 overflow-scroll">
                  <Message type="error" background>
                    {getErrorMessage(error)}
                  </Message>
                </div>
              </div>
            ) : (
              <>
                <div className="w-full flex flex-row justify-center items-center mt-5">
                  {((isLoading && !isSuccess) ||
                    isLoadingDisabledBlackouts) && (
                    <Icon.Loading size="large" color="brand.base" />
                  )}
                  {isSuccess && (
                    <div className="flex flex-row justify-between items-center gap-x-3 mt-5 mb-2">
                      <Heading level={4} color="dark">
                        {translate("bookJobAgain.form.newReference")}
                      </Heading>
                      <Text color="dark">{newRef}</Text>
                    </div>
                  )}
                  {!isLoading && !isSuccess && !isLoadingDisabledBlackouts && (
                    <Field name="newCollectionDate">
                      {({ field }) => (
                        <Input.Date
                          {...field}
                          dateFormat="YYYY-MM-DD"
                          disabledDays={
                            disabledBlackouts ?? { before: dayjs().toDate() }
                          }
                          multiMonth={true}
                          firstDay={1}
                        />
                      )}
                    </Field>
                  )}
                </div>
                <div className="mt-6">
                  {!isLoading && !isSuccess && (
                    <>
                      <Button
                        onClick={() => {
                          setDuplicateJobModal(false);
                          setIsOpen(false);
                        }}
                        size="medium"
                        color="negative"
                        className="mr-5"
                        disabled={isLoading}
                      >
                        {translate("bookJobAgain.buttons.cancel")}
                      </Button>
                      <Button
                        type="button"
                        color="accent"
                        size="medium"
                        onClick={() => {
                          mutateBookJobAgain(values);
                          resetForm();
                        }}
                        disabled={
                          isLoading ||
                          isSuccess ||
                          JSON.stringify(values) ===
                            JSON.stringify(initialValues)
                        }
                      >
                        {translate("bookJobAgain.buttons.create")}
                      </Button>
                    </>
                  )}
                  {isSuccess && (
                    <Button
                      size="medium"
                      color="accent"
                      disabled={!newJobId}
                      onClick={() => {
                        setNewRef("");

                        setDuplicateJobModal(false);
                        setIsOpen(false);
                        history.push(`/jobs/update/${newJobId}`);
                      }}
                    >
                      {translate("bookJobAgain.buttons.viewJob")}
                    </Button>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </Form>
    </DuplicateJobModal>
  );
};
