import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  address: stringRequired,
  city: Yup.string(),
  postcode: stringRequired,
  wasteManagementLicense: Yup.string(),
  permitNumber: Yup.string(),
  landfillDiversionRate: Yup.string(),
  group: Yup.string(),
  companyCode: Yup.string(),
  active: Yup.boolean(),
  rate: Yup.number(),
  notes: Yup.string(),
});

export const initialValues = {
  name: "",
  address: "",
  city: "",
  postcode: "",
  wasteManagementLicense: "",
  permitNumber: "",
  landfillDiversionRate: "",
  group: "",
  companyCode: "",
  rate: 0,
  notes: "",
  active: true,
};
