import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useAuthContext } from "hooks";
import { handleException } from "helpers/handleException";
import { instance, stringToDate } from "@clearabee/ui-sdk";
import {
  Button,
  Field,
  Form,
  Input,
  Message,
  Text,
  theme,
  Modal,
  Box,
  Heading,
} from "@clearabee/ui-library";
import { getErrorMessage, toasts } from "helpers";
import { createBasketPaymentLink } from "api";
import { IBasketResponse, IPaymentLink } from "@clearabee/api-schemas";
import { LoadingOverlay } from "components/common/components";

const initialValues = {
  newCollectionDate: "",
};

type BookAgainPaymentLinkModalProps = {
  activeBookAgainLink: IPaymentLink | null;
  setActiveBookAgainLink: (value: IPaymentLink | null) => void;
};

export const BookAgainPaymentLinkModal = ({
  activeBookAgainLink,
  setActiveBookAgainLink,
}: BookAgainPaymentLinkModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();

  const [newBasket, setNewBasket] = useState<Pick<
    IBasketResponse,
    "basketToken" | "orderRef"
  > | null>(null);

  const { getCurrentUserCurrentCompany } = useAuthContext();
  const { sicCode } = getCurrentUserCurrentCompany() || {};

  /**
   * get basket data
   */
  const { data: basket, isLoading: isLoadingBasket } = useQuery(
    ["getBasket", activeBookAgainLink?.basketToken],
    async () =>
      (
        await instance.catalogues.getBasket(
          activeBookAgainLink?.basketToken || "",
        )
      ).data,
    {
      enabled: !!activeBookAgainLink?.basketToken,
      retry: false,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      onError: (error) => toasts.errors({ message: getErrorMessage(error) }),
    },
  );

  const companyCode = basket?.companyCode ?? "";

  const items =
    basket?.items?.map((item) => {
      return {
        sku: item.sku,
        qty: item.qty,
      };
    }) ?? [];

  // get company catalouge Id
  const { data: companyCatalogueIdData } = useQuery(
    ["getCompanyCatalogueId", companyCode],
    async () =>
      (await instance.catalogues.getCatalogueByCompanyCode(companyCode)).data[
        "id"
      ],
  );

  const companyCatalogueId = companyCatalogueIdData ?? "portal";

  // get blackouts
  const { data: disabledBlackouts, isLoading: isLoadingDisabledBlackouts } =
    useQuery(
      ["readItemsBlackouts", items, companyCatalogueId],
      async () => {
        const blackoutDates: any[] = [];
        const blackoutDays: any[] = [];
        const cutoffTime: any[] = [];

        for await (const item of items) {
          const blackoutItem = (
            await instance.catalogues.getCatalogueItemBlackouts(
              companyCatalogueId ?? "portal",

              item.sku,
            )
          ).data;

          for (const element of blackoutItem) {
            if (typeof element === "string") blackoutDates.push(element);

            if (
              typeof element === "object" &&
              element.hasOwnProperty("before")
            ) {
              cutoffTime.push(element);
            }
            if (
              typeof element === "object" &&
              element.hasOwnProperty("daysOfWeek")
            ) {
              blackoutDays.push(element);
            }
          }
        }

        const updatedBlackoutDays = blackoutDays.flatMap(
          ({ daysOfWeek }: { daysOfWeek: number[] }) => daysOfWeek,
        );

        const updatedCutoffTime = cutoffTime
          .flatMap(({ before }: { before: string }) =>
            Number(dayjs.utc(before).format("x")),
          )
          .sort((a, b) => a - b);

        const formattedCutoffTime = stringToDate(
          dayjs(updatedCutoffTime[0]).format("YYYY-MM-DD"),
        );

        return [
          ...(!!blackoutDates.length ? stringToDate(blackoutDates) : []),
          {
            daysOfWeek: !!updatedBlackoutDays.length
              ? updatedBlackoutDays
              : [0],
          },
          { before: formattedCutoffTime ?? new Date() },
        ];
      },
      {
        enabled: !!items.length && !!companyCatalogueId,
      },
    );

  const {
    isLoading: isLoadingBookJobAgain,
    isSuccess: isSuccessBookJobAgain,
    error,
    reset: resetBookJobAgain,
    mutate: mutateBookJobAgain,
  } = useMutation(
    async (values: typeof initialValues) => {
      const { companyCode, description, accessInformation, meta } =
        basket || {};

      const customFields =
        !!basket?.customFields && !!basket.customFields.length
          ? basket.customFields
          : [];

      const data = {
        catalogueId: companyCatalogueId,
        deliveryAddress: basket?.deliveryAddress || undefined,
        date: dayjs.utc(values?.newCollectionDate).toISOString(),
        contact: basket?.contact || undefined,
        // deliveryCharge, timeslot, timeslotpreferene will be changed when we add extra field
        items,
        description,
        accessInformation,
        meta: { ...meta, bookingSource: "Portal payment link book again flow" },
        companyCode,
        sicCode,
        customFields,
      };

      // create new basket
      return (await instance.catalogues.postBasket(data)).data;
    },
    {
      onSuccess: (data) => {
        if (!!data) {
          setNewBasket(data);
          createPaymentLink(data.basketToken);
        }
      },
      onError: (error) => {
        handleException(
          error,
          {},
          { type: "payment link - Portal book again flow" },
          "",
          false,
        );
      },
    },
  );

  const {
    mutate: createPaymentLink,
    isLoading: isPaymentLinkLoading,
    reset: resetCreatePaymentLink,
  } = useMutation(
    async (basketToken: string) => await createBasketPaymentLink(basketToken),
    {
      onError: (error, basketToken) => {
        handleException(
          error,
          { basketToken },
          { type: "payment link - create payment link" },
          translate("errors.paymentLink"),
        );
      },
    },
  );

  const isLoading =
    isLoadingBasket ||
    isLoadingDisabledBlackouts ||
    isPaymentLinkLoading ||
    isLoadingBookJobAgain;

  return (
    <Modal
      modalVisible={!!activeBookAgainLink}
      width={650}
      styles={{
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.medium} ${theme.spacing.large}`,
        },
      }}
      onClose={() => {
        setNewBasket(null);
        setActiveBookAgainLink(null);
        resetBookJobAgain();
        resetCreatePaymentLink();
      }}
    >
      <Form
        renderFormElement={false}
        initialValues={initialValues}
        onSubmit={() => {
          return;
        }}
      >
        {({ resetForm, values }) => (
          <>
            {/* MODAL LOADING OVERLAY */}
            {isLoading && (
              <LoadingOverlay
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}
            <Heading level={3} color="brand">
              {translate(
                `paymentLink.headings.${
                  isSuccessBookJobAgain ? "success" : "selectDate"
                }`,
              )}
            </Heading>
            {!error && (
              <>
                <Box className="w-full flex flex-row justify-center items-center mt-5">
                  {isSuccessBookJobAgain && (
                    <Box className="flex flex-col items-center gap-y-5">
                      <Text>
                        Your new payment link reference is{" "}
                        <span className="font-bold">{newBasket?.orderRef}</span>
                      </Text>
                      <Button
                        size="medium"
                        onClick={() => {
                          resetCreatePaymentLink();
                          resetBookJobAgain();
                          setActiveBookAgainLink(null);
                          history.push(
                            `/jobs/payment-links/update/${newBasket?.basketToken}`,
                          );
                        }}
                      >
                        {translate("paymentLink.buttons.viewPaymentLink")}
                      </Button>
                    </Box>
                  )}
                  {!isSuccessBookJobAgain && (
                    <Field name="newCollectionDate">
                      {({ field }) => (
                        <Input.Date
                          {...field}
                          dateFormat="YYYY-MM-DD"
                          disabledDays={
                            disabledBlackouts ?? { before: dayjs().toDate() }
                          }
                          multiMonth={true}
                          firstDay={1}
                        />
                      )}
                    </Field>
                  )}
                </Box>
                <Box className="mt-6">
                  {!isSuccessBookJobAgain && (
                    <>
                      <Button
                        onClick={() => {
                          setActiveBookAgainLink(null);
                        }}
                        size="medium"
                        color="negative"
                        className="mr-5"
                        disabled={isLoading}
                      >
                        {translate("paymentLink.buttons.cancel")}
                      </Button>
                      <Button
                        type="button"
                        color="accent"
                        size="medium"
                        onClick={() => {
                          mutateBookJobAgain(values);
                          resetForm();
                        }}
                        disabled={
                          isLoading ||
                          isSuccessBookJobAgain ||
                          JSON.stringify(values) ===
                            JSON.stringify(initialValues)
                        }
                      >
                        {translate("paymentLink.buttons.submit")}
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
            {!!error && (
              <Box className="w-full mt-5">
                <Box className="flex items-center justify-center w-full mb-4 overflow-scroll">
                  <Message type="error" background>
                    {getErrorMessage(error)}
                  </Message>
                </Box>
              </Box>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};
