import { Box, Icon } from "@clearabee/ui-library";
import React, { useState, useEffect, useRef } from "react";

/** Subload hook, which checks if the item is in viewPort */
const useSubLoad = (containerSelector: string, handleLoading = false) => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;

          if (isIntersecting) {
            setLoaded(true);

            if (handleLoading === false) {
              observer.disconnect();
            }
          } else if (handleLoading) {
            setLoaded(false);
          }
        });
      },
      {
        root: document.querySelector(containerSelector),
      },
    );

    if (ref.current) observer.observe(ref.current);
  }, []);

  return [loaded, ref];
};
/** Subload hook, which checks if the item is in viewPort */

/** SubLoad component, which render item if it is in viewPort */
export const SubLoad = ({
  containerSelector,
  children,
}: {
  containerSelector: string;
  children: React.ReactNode;
}): React.ReactElement => {
  const [loaded, ref] = useSubLoad(containerSelector, true);

  return (
    <Box ref={ref as React.RefObject<HTMLDivElement>}>
      {loaded ? (
        <>{children}</>
      ) : (
        <Box className="w-full flex justify-center items-center mb-5 h-64">
          <Icon.Loading size="large" color="brand" />
        </Box>
      )}
    </Box>
  );
};
