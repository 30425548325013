import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { OptionTypeBase } from "react-select";
import { instance } from "@clearabee/ui-sdk";
import {
  IVehicleScheduleResponse,
  IVehicleSchedulePostBody,
} from "@clearabee/api-schemas";
import {
  Button,
  Box,
  theme,
  Panel,
  Form,
  Field,
  Input,
  Message,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { toasts } from "helpers/toasts";
import { SubLoad } from "./helpers/useLoad";
import {
  getDates,
  BulkAmendItem,
  transformInput,
  FlattenedScheduleDate,
  flattenScheduleDates,
} from "./helpers/helpers";
import {
  VehicleRow,
  DateCell,
  CreateScheduleModal,
  BulkAmendModal,
  CreateStartOfDayJobsModal,
  CloneScheduleModal,
  AmendSlackRoomsModal,
  TableView,
} from "./components";
import { stringRequired } from "validation/common";

dayjs.extend(utc);

const initialValues = {
  date: "",
};

export const VehiclesSchedule = (): React.ReactElement => {
  const [vehicleNameFilter, setVehicleNameFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [driverOptions, setDriverOptions] = useState<OptionTypeBase[]>([]);
  const [schedule, setSchedule] = useState<IVehicleScheduleResponse[]>([]);
  const [flatScheduleDates, setFlatScheduleDates] = useState<
    FlattenedScheduleDate[]
  >([]);
  const [bulkAmendArray, setBulkAmendArray] = useState<BulkAmendItem[]>([]);
  const [showBulkAmendModal, setShowBulkAmendModal] = useState(false);
  const [showTableViewModal, setShowTableViewModal] = useState(false);
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);
  const [showCloneScheduleModal, setShowCloneScheduleModal] = useState(false);
  const [showAmendSlackRoomsModal, setShowAmendSlackRoomsModal] =
    useState(false);
  const [showCreateStartOfDayJobsModal, setShowCreateStartOfDayJobsModal] =
    useState(false);
  const [removeWeekendRotation, setRemoveWeekendRotation] = useState(0);

  const { isLoading: driversLoading } = useQuery(
    ["getDrivers"],
    async () =>
      (
        await instance.users.getUsers({
          params: {
            limit: 10000,
            "roles.name:in": "Clearabee Driver",
          },
        })
      ).data.items,
    {
      cacheTime: 0,
      onSuccess: (data) => {
        const options = data.map(({ id, firstName, lastName, attributes }) => {
          const weekendRotation =
            attributes?.find(({ attrKey }) => attrKey === "weekendRotation")
              ?.attrValue ?? 0;

          return {
            label: `${firstName} ${lastName} ${
              !!weekendRotation ? `(${weekendRotation})` : ""
            }`,
            value: String(id),
          };
        });

        setDriverOptions(options);
      },
      onError: () => {
        toasts.error({
          message: "Failed to fetch drivers.",
        });
      },
    },
  );

  const { isLoading: getScheduleIsLoading, refetch: refetchSchedule } =
    useQuery(
      ["getSchedule", selectedDates],
      async () => {
        const fromDate = dayjs.utc(selectedDates[0]).toISOString();
        const toDate = dayjs
          .utc(selectedDates[selectedDates.length - 1])
          .toISOString();

        return (
          await instance.vehicles.getVehicleSchedules(
            fromDate,
            selectedDates.length > 1 ? toDate : fromDate,
            { params: { limit: 10000 } },
          )
        ).data.items;
      },
      {
        cacheTime: 0,
        enabled: !!selectedDates.length,
        onSuccess: (data) => {
          setFlatScheduleDates(flattenScheduleDates(data, driverOptions));
          setSchedule(data);
        },
        onError: () => {
          toasts.error({
            message: "Failed to fetch schedule.",
          });
        },
      },
    );

  const { mutate: createSchedule, isLoading: createScheduleIsLoading } =
    useMutation(
      ["createAssetDates"],
      async (body: IVehicleSchedulePostBody) => {
        return instance.vehicles.postVehicleSchedule(body);
      },
      {
        retry: 1,
        onSuccess: () => {
          refetchSchedule();
          setUnsavedChanges(false);
          toasts.success({
            message: "Schedule created successfully.",
          });
        },
        onError: () => {
          toasts.error({
            message: "Failed to create schedule.",
          });
        },
      },
    );

  const updateFlateScheduleDates = useCallback(
    (date: string, reg: string, assetDates: FlattenedScheduleDate[]) => {
      // Everytime a date cell is updated, we need to update the flat schedule dates
      // Here we remove the old values revelant to that date cell and add the new ones

      setFlatScheduleDates((prev) => {
        const filteredOut = prev.filter((ad) => {
          return ad.date !== date || ad.asset !== reg;
        });

        return [...filteredOut, ...assetDates];
      });
    },
    [setFlatScheduleDates],
  );

  const handleFormSubmit = async (values: typeof initialValues) => {
    if (values.date.includes(" - ")) {
      const splitDates = values.date.split(" - ");
      const startDate = splitDates[0];
      const endDate = splitDates[1];

      setSelectedDates(
        getDates(
          dayjs.utc(startDate, "DD/MM/YYYY").toDate(),
          dayjs.utc(endDate, "DD/MM/YYYY").toDate(),
        ),
      );
      return;
    }

    setSelectedDates([dayjs.utc(values.date, "DD/MM/YYYY").toISOString()]);
  };

  const handleBulkAmend = (value: BulkAmendItem[]) => {
    setBulkAmendArray(value);
    setShowBulkAmendModal(false);
  };

  const handleCreateSchedule = () => {
    createSchedule(
      transformInput(
        flatScheduleDates,
        selectedDates,
        schedule.map((vehicle) => vehicle.vehicleId),
      ),
    );
    setShowCreateScheduleModal(false);
  };

  const handleSetUnsavedChanges = (value: boolean) => {
    setUnsavedChanges(value);
  };

  useEffect(() => {
    if (removeWeekendRotation !== 0) {
      setFlatScheduleDates((prev) => {
        return prev.filter((value) => {
          return !value.user.includes(`(${removeWeekendRotation})`);
        });
      });

      setRemoveWeekendRotation(0);
    }

    if (!bulkAmendArray.length || !schedule.length) {
      return;
    }

    // this array contains the vehicle asset and vehicleId from schedule
    // in order to pass values to create a new object with empty user, empty userId in flatScheduleDates
    const vehiclesArray = schedule.map(({ asset, vehicleId }) => ({
      asset,
      vehicleId,
    }));

    setFlatScheduleDates((prev) => {
      // filter out the asset and date that does not exist in flatScheduleDates from bulkAmendArray
      const assetNotExistInFlatScheduleDates: BulkAmendItem[] =
        bulkAmendArray.filter(
          ({ registration, date }) =>
            !prev.find(
              (flatValue) =>
                flatValue.asset === registration &&
                dayjs.utc(flatValue.date).toISOString() ===
                  dayjs.utc(date).toISOString(),
            ),
        );

      // create new object with empty user, empty userId from assetNotExistInFlatScheduleDates
      const newEmptyObjectArray: FlattenedScheduleDate[] =
        assetNotExistInFlatScheduleDates.map(
          ({ date, time, registration, message }) => {
            return {
              asset: registration,
              vehicleId:
                vehiclesArray.find((vehicle) => vehicle.asset === registration)
                  ?.vehicleId ?? 0,
              date,
              time: time || "06:00",
              message: message,
              userId: 0,
              user: "",
            };
          },
        );

      // filter out the asset and date that exist in flatScheduleDates from bulkAmendArray
      const shared: FlattenedScheduleDate[] = prev.filter(({ asset, date }) =>
        bulkAmendArray.find(
          (bulkValue) =>
            bulkValue.registration === asset &&
            dayjs.utc(bulkValue.date).toISOString() ===
              dayjs.utc(date).toISOString(),
        ),
      );

      // override the shared values with the time and message from bulkAmendArray
      const overideShared: FlattenedScheduleDate[] = shared.map(
        (sharedValue) => {
          const found = bulkAmendArray.find(
            ({ registration, date }) =>
              registration === sharedValue.asset &&
              dayjs.utc(date).toISOString() ===
                dayjs.utc(sharedValue.date).toISOString(),
          );

          if (found) {
            return {
              ...sharedValue,
              time: found.time || sharedValue.time,
              message: found.message,
            };
          }

          return sharedValue;
        },
      );

      // if newEmptyObjectArray is not empty and overideShared is empty
      if (!!newEmptyObjectArray.length && !overideShared.length) {
        // return the newEmptyObjectArray and keep the prev values
        return [...prev, ...newEmptyObjectArray];
      }

      // if overideShared is not empty and newEmptyObjectArray is empty
      if (!!overideShared.length && !newEmptyObjectArray.length) {
        // filter out the duplicate values from overideShared and prev, meaning if the asset and date are the same in both arrays, remove the duplicate from prev
        const filteredOutDuplicateFromOverideSharedToPrev = prev.filter(
          ({ asset, date }) =>
            !overideShared.find(
              (overideSharedValue) =>
                overideSharedValue.asset === asset &&
                dayjs.utc(overideSharedValue.date).toISOString() ===
                  dayjs.utc(date).toISOString(),
            ),
        );

        // return the filteredOutDuplicateFromOverideSharedToPrev and overideShared
        return [
          ...filteredOutDuplicateFromOverideSharedToPrev,
          ...overideShared,
        ];
      }

      // if both newEmptyObjectArray and overideShared are not empty
      if (!!overideShared.length && !!newEmptyObjectArray.length) {
        // filter out the duplicate values from overideShared and prev, meaning if the asset and date are the same in both arrays, remove the duplicate from prev
        const filteredOutDuplicateFromOverideSharedToPrev = prev.filter(
          ({ asset, date }) =>
            !overideShared.find(
              (overideSharedValue) =>
                overideSharedValue.asset === asset &&
                dayjs.utc(overideSharedValue.date).toISOString() ===
                  dayjs.utc(date).toISOString(),
            ),
        );

        // return the filteredOutDuplicateFromOverideSharedToPrev, overideShared and newEmptyObjectArray
        return [
          ...filteredOutDuplicateFromOverideSharedToPrev,
          ...overideShared,
          ...newEmptyObjectArray,
        ];
      }

      return prev;
    });
  }, [
    JSON.stringify(bulkAmendArray),
    JSON.stringify(schedule),
    removeWeekendRotation,
  ]);

  return (
    <>
      {/* LOADING OVERLAY */}
      {(driversLoading || getScheduleIsLoading || createScheduleIsLoading) && (
        <LoadingOverlay />
      )}
      <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
        <Form
          initialValues={{
            date: dayjs.utc().format("DD/MM/YYYY"),
          }}
          validationSchema={Yup.object().shape({
            date: stringRequired,
          })}
          onSubmit={handleFormSubmit}
        >
          {({ values }) => (
            <>
              {/* USEFUL LINKS */}
              <Box className="flex flex-row gap-6 pl-2 pb-4">
                <Link
                  className="underline font-semibold text-primary hover:text-blue-800"
                  to="/vehicles/preferences"
                >
                  Vehicle Preferences
                </Link>

                <Link
                  className="underline font-semibold text-primary hover:text-blue-800"
                  to="/drivers/preferences"
                >
                  Driver Preferences
                </Link>
              </Box>

              {/* FEATURES PANEL */}
              <Panel
                shadow={false}
                styles={{
                  marginBottom: theme.spacing.small,
                  padding: theme.spacing.xsmall,
                }}
              >
                <Box className="flex justify-between items-center gap-6 flex-wrap">
                  <Box className="flex items-center gap-x-3">
                    {/* CLONE SCHEDULE BUTTON */}
                    <Button
                      size="xsmall"
                      color="warning"
                      type="button"
                      disabled={!!schedule.length}
                      onClick={() => {
                        setShowCloneScheduleModal(true);
                      }}
                    >
                      Clone Schedule
                    </Button>

                    {/* BULK AMEND BUTTON */}
                    <Button
                      size="xsmall"
                      color="warning"
                      type="button"
                      disabled={!schedule.length}
                      onClick={() => {
                        setShowBulkAmendModal(true);
                      }}
                    >
                      Bulk Amend
                    </Button>

                    {/* SHOW TABLE VIEW BUTTON */}
                    <Button
                      size="xsmall"
                      color="warning"
                      type="button"
                      disabled={!schedule.length || unsavedChanges}
                      onClick={() => {
                        setShowTableViewModal(true);
                      }}
                    >
                      Table View
                    </Button>

                    {/* AMEND SLACK ROOMS BUTTON */}
                    <Button
                      size="xsmall"
                      color="warning"
                      type="button"
                      disabled={!schedule.length || unsavedChanges}
                      onClick={() => {
                        setShowAmendSlackRoomsModal(true);
                      }}
                    >
                      Amend Slack Rooms
                    </Button>
                    {/* CREATE START OF DAY JOBS BUTTON */}
                    <Button
                      size="xsmall"
                      color="warning"
                      type="button"
                      disabled={!schedule.length || unsavedChanges}
                      onClick={() => {
                        setShowCreateStartOfDayJobsModal(true);
                      }}
                    >
                      Create Jobs
                    </Button>
                  </Box>

                  <Box className="flex items-center gap-x-3">
                    {/* DATE FIELD */}
                    <Field
                      styles={{
                        padding: 0,
                        margin: 0,
                        width: "320px",
                      }}
                      name="date"
                    >
                      {({ field }) => (
                        <Input.RangedDate
                          {...field}
                          collapsable
                          placeholder="Select a date"
                          initialValue={[field.value]}
                          disabledDays={{
                            after:
                              !values.date.includes(" - ") &&
                              dayjs
                                .utc(values.date, "DD/MM/YYYY")
                                .add(2, "day")
                                .toDate(),
                          }}
                        />
                      )}
                    </Field>

                    {/* SUMBIT */}
                    <Button size="small" color="brand" type="submit">
                      Search
                    </Button>

                    {/* CREATE SCHEDULE BUTTON*/}
                    <Button
                      size="small"
                      color="accent"
                      type="button"
                      disabled={!schedule.length}
                      onClick={() => {
                        setShowCreateScheduleModal(true);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Panel>
              {/* FILTERS PANEL */}
              {!!schedule.length && (
                <Panel
                  shadow={false}
                  styles={{
                    marginBottom: theme.spacing.medium,
                    padding: theme.spacing.xsmall,
                  }}
                >
                  <Box className="flex flex-row justify-between items-center">
                    {/* UNSAVED CHANGES WARNING */}
                    <Box className="w-1/2">
                      {unsavedChanges && (
                        <Message type="error">You have unsaved changes</Message>
                      )}
                    </Box>

                    <Box className="flex flex-row gap-3 justify-end items-center">
                      {/* USER FILTER FIELD */}
                      <Box
                        styles={{
                          width: "266px",
                        }}
                      >
                        <Input.Text
                          placeholder="Filter user name"
                          onChange={(e) => {
                            setVehicleNameFilter("");
                            setUserFilter(e.target.value);
                          }}
                          value={userFilter}
                        />
                      </Box>

                      {/* VEHICLE NAME FILTER FIELD */}
                      <Box
                        styles={{
                          width: "266px",
                        }}
                      >
                        <Input.Text
                          placeholder="Filter vehicle name"
                          onChange={(e) => {
                            setUserFilter("");
                            setVehicleNameFilter(e.target.value);
                          }}
                          value={vehicleNameFilter}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Panel>
              )}
            </>
          )}
        </Form>

        {/* VEHICLE ROWS */}
        {!!selectedDates.length && schedule && !getScheduleIsLoading && (
          <Box
            className="flex flex-col gap-3 overflow-y-scroll list-container"
            styles={{
              maxHeight: "75vh",
              minHeight: "75vh",
            }}
          >
            {schedule
              .filter(({ name, asset }) => {
                if (!!userFilter) {
                  const filteredFlatDates = flatScheduleDates.filter(
                    (flatDate) =>
                      flatDate.user
                        .toLowerCase()
                        .includes(userFilter.toLowerCase()),
                  );

                  return filteredFlatDates.some(
                    (flatDate) => flatDate.asset === asset,
                  );
                }

                if (!!vehicleNameFilter) {
                  return name
                    .toLowerCase()
                    .includes(vehicleNameFilter.toLowerCase());
                }

                return true;
              })
              .map((vehicle, index) => (
                <SubLoad
                  key={`${vehicle.asset}-${index}`}
                  containerSelector=".list-container"
                >
                  <VehicleRow
                    key={vehicle.vehicleId}
                    vehicleName={vehicle.name}
                    vehicleReg={vehicle.asset}
                    // off the road on vehicles is not available yet
                    // offTheRoad={vehicle.asset === "AB3 CDE" && true}
                  >
                    {selectedDates.map((date) => {
                      return (
                        <DateCell
                          setUnsavedChanges={handleSetUnsavedChanges}
                          key={`${vehicle.asset} - ${date}`}
                          date={date}
                          vehicleId={vehicle.vehicleId}
                          vehicleReg={vehicle.asset}
                          vehicleSchedule={vehicle}
                          updateFlatScheduleDates={updateFlateScheduleDates}
                          flatScheduleDates={flatScheduleDates}
                          options={driverOptions}
                          disabledOptions={flatScheduleDates
                            .filter((flatDate) => flatDate.date === date)
                            .map((flatDate) => flatDate.userId)}
                        />
                      );
                    })}
                  </VehicleRow>
                </SubLoad>
              ))}
          </Box>
        )}
      </Box>

      {/* CREATE SCHEDULE MODAL */}
      <CreateScheduleModal
        dates={selectedDates}
        onConfirm={handleCreateSchedule}
        showModal={showCreateScheduleModal}
        setShowModal={setShowCreateScheduleModal}
      />

      {/* AMEND SLACK ROOMS MODAL */}
      <AmendSlackRoomsModal
        dates={selectedDates}
        allVehicleIds={schedule.map((vehicle) => vehicle.vehicleId)}
        showModal={showAmendSlackRoomsModal}
        setShowModal={setShowAmendSlackRoomsModal}
        schedule={schedule}
      />

      {/* CREATE START OF DAY JOBS MODAL */}
      <CreateStartOfDayJobsModal
        dates={selectedDates}
        schedule={schedule}
        showModal={showCreateStartOfDayJobsModal}
        setShowModal={setShowCreateStartOfDayJobsModal}
      />

      {/* CLONE ASSET DATES MODAL */}
      <CloneScheduleModal
        driverOptions={driverOptions}
        unSavedChanges={unsavedChanges}
        setUnsavedChanges={handleSetUnsavedChanges}
        showModal={showCloneScheduleModal}
        setShowModal={setShowCloneScheduleModal}
      />

      {/* BULK AMEND MODAL */}
      <BulkAmendModal
        dates={selectedDates}
        vehicles={schedule.map(({ name, asset }) => ({
          name,
          asset,
        }))}
        onChangeBulkAmend={handleBulkAmend}
        showModal={showBulkAmendModal}
        setShowModal={setShowBulkAmendModal}
        removeWeekend={(value) => {
          setRemoveWeekendRotation(value);
          setUnsavedChanges(true);
          setShowBulkAmendModal(false);
        }}
      />

      {/* TABLE VIEW MODAL */}
      <TableView
        showModal={showTableViewModal}
        setShowModal={setShowTableViewModal}
        schedule={schedule}
        dates={selectedDates}
        drivers={driverOptions}
      />
    </>
  );
};
