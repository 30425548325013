import { theme, StyleSheet } from "@clearabee/ui-library";

export const styles = StyleSheet({
  pannel: {
    padding: `${theme.spacing.small} ${theme.spacing.xlarge}`,
  },
  jobsLabel: {
    ...theme.fontDefaults.small,
    paddingBottom: theme.spacing.xsmall2,
    fontWeight: 600,
  },
  jobsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: theme.spacing.xsmall,
    borderRadius: theme.spacing.xsmall2,
    padding: theme.spacing.xsmall,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
  },
  emailContainer: {
    whiteSpace: "nowrap",
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  modalStyles: {
    textAlign: "center",
    [`@media (min-width: ${theme.screens.medium})`]: {
      padding: `${theme.spacing.xlarge} ${theme.spacing.xlarge2} ${theme.spacing.xlarge} ${theme.spacing.xlarge2}`,
    },
  },
});
