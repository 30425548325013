import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  UnstyledButton,
  Text,
  Input,
  Icon,
  theme,
  UserStylesProps,
} from "@clearabee/ui-library";

type OptionType = { label: string; value: string };

interface CheckboxSelectProps {
  options: OptionType[];
  // isLoadingOptions is required to handle the first render
  isLoadingOptions: boolean;
  // disabledOptions are the options that are selected but disabled
  disabledOptions?: OptionType[];
  value: OptionType[];
  selectAllOptionsLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (selected: OptionType[], isAllSelected?: boolean) => void;
  onClearAll?: () => void;
  // styles for the menu list
  menuListStyles?: UserStylesProps["styles"];
}

export const CheckboxSelect = ({
  options,
  isLoadingOptions,
  disabledOptions = [],
  value: selectedOptions,
  selectAllOptionsLabel,
  placeholder = "Select",
  disabled,
  onChange,
  onClearAll,
  menuListStyles,
}: CheckboxSelectProps): React.ReactElement => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [isInputFiltering, setIsInputFiltering] = useState(false);
  const [sortedOptions, setSortedOptions] = useState([
    ...options,
    ...disabledOptions,
  ]);
  const isFirstRender = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  //list of options to be displayed
  const optionsElements = useMemo(() => {
    const allOptions = selectAllOptionsLabel && {
      value: "all",
      label:
        sortedOptions.length === options.length + disabledOptions.length
          ? selectAllOptionsLabel
          : "All filtered options",
    };

    const allSelectedOptionsValues = selectedOptions.map(({ value }) => value);
    const allSortedOptionsValues = sortedOptions.map(({ value }) => value);

    //checks if all visible options are selected
    const isAllOptionSelected = (option: OptionType) =>
      option.value === "all" &&
      allSortedOptionsValues.every((value) =>
        allSelectedOptionsValues.includes(value),
      );

    // if no options found, display a message
    if (!sortedOptions.length) {
      return (
        <Box className="flex p-2 rounded-sm gap-3 h-8 items-center justify-center opacity-40">
          <Text>No options</Text>
        </Box>
      );
    }

    return (allOptions ? [allOptions, ...sortedOptions] : sortedOptions).map(
      (option, index) => {
        const isCheckBoxDisabled = disabledOptions.some(
          ({ value }) => value === option.value,
        );

        const isCheckBoxChecked =
          isAllOptionSelected(option) ||
          selectedOptions.some(({ value }) => value === option.value);

        return (
          <UnstyledButton
            key={index}
            disabled={isCheckBoxDisabled}
            styles={{
              opacity: isCheckBoxDisabled ? 0.5 : 1,
              backgroundColor: isCheckBoxDisabled
                ? theme.colors.greyscale.lightest
                : "",
            }}
            onClick={() => {
              //keep input focused after selecting an option
              inputRef.current?.focus();

              if (option.value === "all") {
                const isAllSelected = isAllOptionSelected(option);

                const isAllFilteredOption =
                  option.label === "All filtered options";

                if (!!disabledOptions.length) {
                  onChange(
                    isAllSelected ? disabledOptions : sortedOptions,
                    isAllFilteredOption ? false : !isAllSelected,
                  );
                  return;
                }

                onChange(
                  isAllSelected ? [] : sortedOptions,
                  isAllFilteredOption ? false : !isAllSelected,
                );
                return;
              }

              if (allSelectedOptionsValues.includes(option.value)) {
                onChange(
                  selectedOptions.filter(({ value }) => value !== option.value),
                  false,
                );
                return;
              }

              onChange(
                [...selectedOptions, option],
                !isInputFiltering &&
                  Boolean(selectedOptions.length === sortedOptions.length - 1),
              );
            }}
          >
            <Box className="flex p-2 rounded-sm hover:bg-gray-300 gap-3 h-8 items-center justify-start">
              <input
                type="checkbox"
                checked={isCheckBoxChecked}
                //onChange is required for the checkbox with provided checked prop
                onChange={() => {
                  return;
                }}
              />
              <Box key={option.value}>
                <Text>{option.label}</Text>
              </Box>
            </Box>
          </UnstyledButton>
        );
      },
    );
  }, [
    JSON.stringify(sortedOptions),
    JSON.stringify(selectedOptions),
    JSON.stringify(disabledOptions),
    isInputFiltering,
  ]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      setOptionsVisible(false);
    }
  };

  //handles the click outside of the dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  /**
   * first render to check if all option is selected, then update the options
   * Sorts the options based on the selected options every time when close the dropdown
   * selectedOptions always displayed at the top of the list
   */
  useEffect(() => {
    if (isLoadingOptions) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (selectedOptions.some(({ value }) => value === "all")) {
        onChange([...options, ...disabledOptions], true);
      }

      return;
    }

    if (!optionsVisible) {
      const allSelectedOptionsArray = [...options, ...disabledOptions].filter(
        (option) =>
          selectedOptions?.some(({ value }) => value === option.value),
      );

      const allUnselectedOptionsArray = options.filter(
        (option) =>
          !selectedOptions?.some(({ value }) => value === option.value),
      );

      setSortedOptions([
        ...allSelectedOptionsArray,
        ...allUnselectedOptionsArray,
      ]);
    }

    //scroll to the top of the options list when open the dropdown
    if (optionsVisible && optionsRef.current) {
      optionsRef.current.scrollTop = 0;
    }
  }, [optionsVisible, JSON.stringify(selectedOptions), isLoadingOptions]);

  const selectedOptionsCount = `${selectedOptions.length}/${
    options.length + (disabledOptions?.length ?? 0)
  }`;

  return (
    <Box ref={wrapperRef} className="w-full relative">
      {!!selectedOptions.length && !disabled && (
        <Box className="flex justify-end w-full">
          <Box
            className={`${
              !onClearAll && "pr-3"
            } absolute min-h-8 flex pl-3 mt-1 mr-1 rounded-sm gap-2 items-center`}
            backgroundColor="greyscale.lightest"
          >
            <Text fontSize="small">
              Options selected: {selectedOptionsCount}
            </Text>
            {!!onClearAll && (
              <Box
                className="p-3 rounded-sm"
                onClick={() => {
                  onClearAll();
                }}
                styles={{
                  "&&:hover": {
                    backgroundColor: theme.colors.negative.base,
                    color: theme.colors.light.base,
                  },
                }}
              >
                <Icon.Close size="xsmall" />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <UnstyledButton
        className="w-full"
        //use onMouseDown because onClick is also getting triggered when we press space
        onMouseDown={() => {
          !disabled && setOptionsVisible(!optionsVisible);
        }}
      >
        <Input.Text
          disabled={disabled}
          placeholder={placeholder}
          ref={inputRef}
          onChange={(event) => {
            // everytime when we type in the input, keep the dropdown visible
            setOptionsVisible(true);

            // if the input is empty, set the filtering to false
            // this state is used to manipulate the isAllSelected value on onchange
            // in a case when user type something and click all Filters options, the onchange should not return isAllSelected as true
            setIsInputFiltering(event.target.value === "" ? false : true);

            // options and disabledOptions are combined to filter the options based on the input value
            const filteredOptions = [...options, ...disabledOptions].filter(
              ({ label }) =>
                label.toLowerCase().includes(event.target.value.toLowerCase()),
            );

            setSortedOptions(filteredOptions);
          }}
        />
      </UnstyledButton>

      <Box
        backgroundColor="light"
        ref={optionsRef}
        className={`w-full ${
          !optionsVisible && "hidden"
        } flex rounded-md z-30 mt-3 flex-col border-gray-300 border-2 p-1 overflow-y-scroll max-h-88 absolute`}
        // override the default styles
        styles={menuListStyles}
      >
        {optionsElements}
      </Box>
    </Box>
  );
};
