import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { ICompany, IJobPatchBody, IJobsPool } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "helpers";
import {
  Table,
  theme,
  Panel,
  Heading,
  Input,
  Button,
  Form,
  Field,
  Text,
  Box,
  Icon,
} from "@clearabee/ui-library";
import { IPaginatedResults, TFilters } from "api/types";
import { LoadingOverlay } from "components/common/components";
import { TableRow } from "./components/tableRow";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { buildQuery } from "helpers/api";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { useScreenWidth } from "hooks/useScreenWidth";

dayjs.extend(utc);

export const initialValues = {
  dates: "",
  jobStatus: [],
  poolStatus: [],
  jobReference: "",
  addressPostcode: "",
  companyCode: [],
};

const jobStatusSelectOptions = [
  { value: "Open", label: "Open" },
  { value: "Scheduled", label: "Scheduled" },
  { value: "On the way", label: "On The Way" },
  { value: "Started", label: "Started" },
  { value: "Completed", label: "Completed" },
  { value: "Failed", label: "Failed" },
  { value: "Cancelled", label: "Cancelled" },
];

const poolStatusSelectOptions = [
  { value: "Open", label: "Open" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
];

export const ReadSubcontractorJobs = (): React.ReactElement => {
  const { t } = useTranslation("subcontractors");
  const [total, setTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  /**
   * This query is used to fetch all the subcontractors
   */
  const { data: subcontractorsData, isLoading: isLoadingSubcontractors } =
    useQuery<ICompany[]>(
      "getReadSubcontractorsJobs",
      async () =>
        (
          await axios.get(
            `${process.env.REACT_APP_MS_API_BASE_URL}/companies`,
            {
              params: {
                "active:eq": "1",
                eager: "[postcodes]",
                limit: 5000,
              },
            },
          )
        ).data.items,
      {
        keepPreviousData: true,
      },
    );

  const subcontractors =
    subcontractorsData &&
    subcontractorsData.filter(
      (company) => company.type === "subcontractor" || !!company.resourceId,
    );

  /**
   * This is the options for the subcontractors select
   */
  const subcontractorsOptions =
    subcontractors?.map(({ companyCode, name }) => ({
      label: name,
      value: companyCode,
    })) ?? [];

  /**
   * This mutation is used to unassign a job from a subcontractor
   */
  const { mutate: unassignJob, isLoading: unassignJobIsLoading } = useMutation(
    "unassignJob",
    async (body: IJobPatchBody) =>
      await instance.jobs.patchJob(String(body.jobId), {
        subcontractorCodes: [],
      }),
    {
      onSuccess: () => {
        toasts.success({
          message: t("jobs.toasts.success"),
        });
        refetchJobsPool();
      },
      onError: () => {
        toasts.error({
          message: t("jobs.toasts.error"),
        });
      },
    },
  );

  /**
   *  function to read the job pool and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readJobPool = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IJobsPool>> => {
    try {
      const filterParams = new URLSearchParams(filters);

      // job filters
      const jobStatus = filterParams?.get("job.status:in") ?? "";
      const fromDate = filterParams?.get("job.date:gte") ?? "";
      const toDate = filterParams?.get("job.date:lte") ?? "";
      const ref = filterParams?.get("job.ref:eq") ?? "";
      const addressPostcode =
        filterParams?.get("job.addressPostcode:like") ?? "";

      // pool filters
      const poolStatus = filterParams?.get("status:in") ?? "";
      const companyCode = filterParams?.get("companyCode:in") ?? "";

      const { data } = await instance.jobs.getPool({
        params: {
          // job filters
          ...(!!fromDate && !!toDate
            ? {
                "job.date:gte": dayjs
                  .utc(decodeURIComponent(fromDate), "DD/MM/YYYY")
                  .startOf("day")
                  .toISOString(),
                "job.date:lte": dayjs
                  .utc(decodeURIComponent(toDate), "DD/MM/YYYY")
                  .endOf("day")
                  .toISOString(),
              }
            : {}),
          ...(!!ref ? { "job.ref:eq": ref } : {}),
          ...(!!addressPostcode
            ? {
                "job.addressPostcode:like": `${addressPostcode.replace(
                  /\s/g,
                  "",
                )}%`,
              }
            : {}),
          ...(!!jobStatus ? { "job.status:in": jobStatus } : {}),
          // pool filters
          ...(!!poolStatus ? { "status:in": poolStatus } : {}),
          ...(!!companyCode ? { "companyCode:in": companyCode } : {}),
          limit,
          offset: currentPage * limit,
          orderByDesc: "job.date",
        },
      });

      const response = data as unknown as IPaginatedResults<IJobsPool>;
      setTotal(response?.pagination?.total || 0);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    query: {
      isFetching: isFetchingReadJobsPool,
      isLoading: isLoadingReadJobsPool,
      refetch: refetchJobsPool,
      isSuccess: isSuccessReadJobsPool,
    },
  } = usePaginatedQuery(
    readJobPool,
    "readJobsPoolPaginated",
    "orderByDesc=job.date",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: isSubmitting,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: 20,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // job filters
    const jobStatus = params?.get("job.status:in") ?? "";
    const fromDate = params?.get("job.date:gte") ?? "";
    const toDate = params?.get("job.date:lte") ?? "";
    const jobReference = params?.get("job.ref:eq") ?? "";
    const addressPostcode = params?.get("job.addressPostcode:like") ?? "";
    // pool filters
    const poolStatus = params?.get("status:in") ?? "";
    const companyCode = params?.get("companyCode:in") ?? "";

    const parsedJobStatus = jobStatus
      ?.split(",")
      .map((value) => ({
        value,
        label:
          jobStatusSelectOptions.find((option) => option.value === value)
            ?.label ?? "",
      }))
      .filter((value) => !!value.label);

    const parsedPoolStatus = poolStatus
      ?.split(",")
      .map((value) => ({
        value,
        label:
          poolStatusSelectOptions.find((option) => option.value === value)
            ?.label ?? "",
      }))
      .filter((value) => !!value.label);

    const parsedCompanyCode = companyCode
      ?.split(",")
      .map((value) => ({
        value,
        label:
          subcontractorsOptions.find((option) => option.value === value)
            ?.label ?? "",
      }))
      .filter((value) => !!value.label);

    return {
      jobReference,
      addressPostcode,
      dates:
        !!fromDate && !!toDate
          ? `${dayjs
              .utc(decodeURIComponent(fromDate), "DD/MM/YYYY")
              .format("DD/MM/YYYY")} - ${dayjs
              .utc(decodeURIComponent(toDate), "DD/MM/YYYY")
              .format("DD/MM/YYYY")}`
          : "",
      jobStatus: parsedJobStatus?.length ? parsedJobStatus : [],
      poolStatus: parsedPoolStatus?.length ? parsedPoolStatus : [],
      companyCode: parsedCompanyCode?.length ? parsedCompanyCode : [],
    } as typeof initialValues;
  };

  const handleSubmit = (values: typeof initialValues) => {
    setIsSubmitting(true);

    let fromDate!: string;
    let toDate!: string;
    const {
      addressPostcode,
      companyCode,
      dates,
      jobReference: ref,
      jobStatus,
      poolStatus,
    } = values;

    if (!!dates) {
      const isRange = dates.includes(" - ");

      if (isRange) {
        const dates = values.dates.split(" - ");
        fromDate = dates[0].trim();
        toDate = dates[1].trim();
      }
      if (!isRange) {
        fromDate = dayjs.utc(dates, "DD/MM/YYYY").format("DD/MM/YYYY");
        toDate = dayjs.utc(dates, "DD/MM/YYYY").format("DD/MM/YYYY");
      }
    }

    const parsedJobStatus =
      !!jobStatus && jobStatus.length
        ? jobStatus.map(({ value }) => value).join(",")
        : "";

    const parsedPoolStatus =
      !!poolStatus && poolStatus.length
        ? poolStatus.map(({ value }) => value).join(",")
        : "";

    const parsedCompanyCode =
      !!companyCode && companyCode.length
        ? companyCode.map(({ value }) => value).join(",")
        : "";

    const updatedFilterObject = {
      // job filters
      ...(!!fromDate && !!toDate
        ? { "job.date:gte": fromDate, "job.date:lte": toDate }
        : {}),
      ...(!!ref ? { "job.ref:eq": ref } : {}),
      ...(!!addressPostcode
        ? {
            "job.addressPostcode:like": addressPostcode.replace(/\s/g, ""),
          }
        : {}),
      ...(!!parsedJobStatus ? { "job.status:in": parsedJobStatus } : {}),
      // pool filters
      ...(!!parsedPoolStatus ? { "status:in": parsedPoolStatus } : {}),
      ...(!!parsedCompanyCode ? { "companyCode:in": parsedCompanyCode } : {}),
      page: 1,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    history.push(`/subcontractors/jobs?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    const doesIncludePageParam = currentUrlWithoutBase.includes("page=");

    if (!!doesIncludePageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase.replace(
        /page=\d+/g,
        `page=${currentPage}`,
      );

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
    }
  }, [currentPage]);

  /**
   * This useEffect is used to refetch pool jobs when screen changes/ page refresh
   */
  useEffect(() => {
    const paramObject = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {},
    );

    if (Object.keys(paramObject).length === 0) return;

    setIsSubmitting(true);

    updateFilters(buildQuery(paramObject));
  }, []);

  return (
    <>
      <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
        <Form
          initialValues={getInitialValues()}
          enableReinitialize
          validationSchema={Yup.object().shape({
            dates: Yup.string(),
            jobStatus: Yup.array(),
            poolStatus: Yup.array(),
            jobReference: Yup.string(),
            addressPostcode: Yup.string(),
            companyCode: Yup.array(),
          })}
          onSubmit={handleSubmit}
        >
          {({ resetForm, setFieldValue, values }) => (
            <>
              <Panel shadow={false}>
                <div className="flex flex-col">
                  <div className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                    <Heading
                      level={5}
                      color="brand"
                      className="pr-4 w-full sm:w-auto"
                    >
                      {t("jobs.heading")} ({total})
                    </Heading>

                    {/* BUTTONS */}
                    <div className="flex justify-end self-end col-start-3 gap-x-3">
                      {/* RESET */}
                      <Button
                        size="small"
                        color="negative"
                        disabled={isLoadingReadJobsPool}
                        type="reset"
                        onClick={() => {
                          resetForm();
                          updateFilters("orderByDesc=job.date");
                          setCurrentPage(1);
                          history.push("/subcontractors/jobs");
                        }}
                      >
                        {t("jobs.buttons.reset")}
                      </Button>
                      {/* SUMBIT */}
                      <Button
                        size="small"
                        color="accent"
                        type="submit"
                        disabled={isLoadingReadJobsPool}
                        className="flex items-center gap-x-1"
                      >
                        <Icon.Search2 size="small" />
                        {t("jobs.buttons.search")}
                      </Button>
                    </div>
                  </div>

                  <div className="grid grid-rows-1 grid-cols-3 gap-2">
                    {/* JOB REFERENCE */}
                    <Field
                      name="jobReference"
                      label={t("jobs.form.jobRef")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("jobs.form.jobRef")}
                        />
                      )}
                    </Field>
                    {/* POSTCODE */}
                    <Field
                      name="addressPostcode"
                      label={t("jobs.form.postcode")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("jobs.form.postcode")}
                        />
                      )}
                    </Field>
                    {/* DATES */}
                    <Field
                      name="dates"
                      label={t("jobs.form.date")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.RangedDate
                          {...field}
                          acceptSingleDate
                          dateFormat="DD/MM/YYYY"
                          initialValue={[
                            values.dates?.split(" - ")?.[0] ?? "",
                            values.dates?.split(" - ")?.[1] ?? "",
                          ]}
                          placeholder={t("jobs.form.datePlaceholder")}
                          collapsable
                        />
                      )}
                    </Field>
                  </div>

                  <div className="grid grid-rows-1 grid-cols-3 gap-2">
                    {/* COMPANYCODE */}
                    <Field
                      name="companyCode"
                      label={t("jobs.form.companyCode")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isDisabled={isLoadingSubcontractors}
                          isMulti
                          placeholder={t("jobs.form.companyCodePlaceholder")}
                          options={subcontractorsOptions}
                          className="text-base"
                          onChange={(options) => {
                            if (options === null)
                              return setFieldValue("companyCode", []);

                            setFieldValue("companyCode", options);
                          }}
                        />
                      )}
                    </Field>
                    {/* POOL JOB STATUS */}
                    <Field
                      name="jobStatus"
                      label={t("jobs.form.jobStatus")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isMulti
                          placeholder={t("jobs.form.jobStatusPlaceholder")}
                          options={jobStatusSelectOptions}
                          className="text-base"
                          onChange={(options) => {
                            if (options === null)
                              return setFieldValue("jobStatus", []);

                            setFieldValue("jobStatus", options);
                          }}
                        />
                      )}
                    </Field>
                    {/* JOB STATUS */}
                    <Field
                      name="poolStatus"
                      label={t("jobs.form.poolStatus")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isMulti
                          placeholder={t("jobs.form.poolStatusPlaceholder")}
                          options={poolStatusSelectOptions}
                          className="text-base"
                          onChange={(options) => {
                            if (options === null)
                              return setFieldValue("poolStatus", []);

                            setFieldValue("poolStatus", options);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>
              {!paginatedData.length &&
                !isLoadingReadJobsPool &&
                isSuccessReadJobsPool && (
                  <Panel className="flex justify-center flex-row gap-1 mt-5">
                    <Text>{t("jobs.noJobsFound")}</Text>
                  </Panel>
                )}
            </>
          )}
        </Form>
      </Box>

      {/* LOADING */}
      {(isLoadingReadJobsPool ||
        unassignJobIsLoading ||
        isFetchingReadJobsPool) && <LoadingOverlay />}

      {/* JOBS */}
      {!!paginatedData.length && (
        <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "14%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "11%" }} />
          </colgroup>
          <Table.Header
            fontSize={isDesktop ? "xsmall" : "xsmall2"}
            headings={[
              t("jobs.table.poolStatus"),
              t("jobs.table.jobStatus"),
              t("jobs.table.jobId"),
              t("jobs.table.jobRef"),
              t("jobs.table.jobDetails"),
              t("jobs.table.assignedSubcontractor"),
              t("jobs.table.collectionDate"),
              t("jobs.table.postcode"),
              t("jobs.table.invoiceAmount"),
              t("jobs.table.actions"),
            ]}
          />
          <Table.Body>
            {paginatedData.map((jobPool, index) => {
              return (
                <TableRow
                  key={index}
                  jobPool={jobPool}
                  unassignJob={() =>
                    unassignJob({
                      jobId: Number(jobPool.jobId),
                    })
                  }
                />
              );
            })}
          </Table.Body>
        </Table>
      )}

      {!!paginatedData.length && (
        <div className="mt-10">
          <PaginationComponent />
        </div>
      )}
    </>
  );
};
