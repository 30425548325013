import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const initialValues = {
  vehicle: "",
  searchedRef: "",
};

export const validationSchema = Yup.object().shape({
  vehicle: stringRequired,
  searchedRef: Yup.string(),
});
