import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Panel,
  Input,
  Button,
  UnstyledButton,
  Form,
  Field,
  Text,
  Icon,
  theme,
} from "@clearabee/ui-library";
import { validationSchema, initialValues } from "./validation";
import { toasts } from "helpers";
import { PreviewSchema } from "../components";

interface Notification {
  name: string;
  active: boolean;
  description: string;
  trigger: string;
}

export const CreateNotification = (): React.ReactElement => {
  const [showSchemaModal, setShowSchemaModal] = useState(false);
  const history = useHistory();
  const [translate] = useTranslation("notifications");

  const { data: triggers, isLoading: isLoadingTriggers } = useQuery(
    "getTriggersCreateNotification",
    async () => {
      return (await instance.notifications.getTriggers()).data;
    },
  );

  const { mutate, isLoading, isSuccess } = useMutation(
    async (values: Notification) => {
      return await instance.notifications.postNotification({
        ...values,
        triggerId: Number(values.trigger),
      });
    },
    {
      onSuccess: (data) => {
        toasts.success({
          message: translate("upsertNotification.toasts.notificationCreated"),
        });
        history.push(`/notifications/update/${data.data.id}`);
      },
    },
  );

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutate(values);
      }}
    >
      {({ values }) => {
        const selectedTrigger = triggers?.items.find(
          (trigger) => trigger.id === Number(values.trigger),
        );

        return (
          <>
            <Panel
              styles={{
                padding: theme.spacing.large,
              }}
            >
              <Box className="flex justify-between items-center mb-2">
                <Heading level={4} color="brand">
                  {translate("upsertNotification.headings.createNotification")}
                </Heading>
                <Button
                  disabled={isLoading || isSuccess}
                  className="flex justify-center"
                  size="small"
                  color="accent"
                  type="submit"
                >
                  {isLoading ? (
                    <Icon.Loading size="small" />
                  ) : (
                    translate("upsertNotification.buttons.save")
                  )}
                </Button>
              </Box>
              <Box className="flex gap-4 items-top">
                <Box className="flex-grow">
                  <Field name="name" label="Name">
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>
                <Box className="flex-grow">
                  <Field name="trigger" label="Trigger">
                    {({ field }) => (
                      <Input.Select
                        {...field}
                        isLoading={isLoadingTriggers}
                        options={
                          !!triggers?.items
                            ? triggers?.items.map((trigger) => ({
                                label: trigger.name,
                                value: trigger.id,
                              }))
                            : []
                        }
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Field name="active" label="Active">
                    {({ field }) => <Input.Toggle {...field} />}
                  </Field>
                </Box>
              </Box>
              <Box className="flex -mt-5">
                <Box className="w-full">
                  <Field name="description" label="Description">
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        autoGrowInitialHeight={120}
                        autoGrow
                      />
                    )}
                  </Field>
                </Box>
              </Box>
              <UnstyledButton
                className={!!values.trigger ? "visible" : "invisible"}
                disabled={!values.trigger}
                onClick={() => setShowSchemaModal(true)}
              >
                <Text
                  color="brand"
                  styles={{
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textUnderlineOffset: theme.spacing.xsmall2,
                  }}
                  fontSize="small"
                >
                  {translate("upsertNotification.links.previewTrigger")}
                </Text>
              </UnstyledButton>
            </Panel>

            {selectedTrigger && (
              <PreviewSchema
                isVisible={showSchemaModal}
                schema={selectedTrigger.schema}
                arn={selectedTrigger.arn}
                heading={translate("upsertNotification.headings.triggerSchema")}
                onClose={() => setShowSchemaModal(false)}
              />
            )}
          </>
        );
      }}
    </Form>
  );
};
