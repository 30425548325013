export default {
  CLEARABEE_ADMIN: "Clearabee Administrator",
  CLEARABEE_MANAGER: "Clearabee Manager",
  CLEARABEE_CUSTOMER_SERVICE: "Clearabee Customer Services",
  CLEARABEE_OPERATIONS: "Clearabee Operations",
  COMPANY_ADMIN: "Company Administrator",
  COMPANY_STAFF: "Company Staff",
  COMPANY_ASSISTANT: "Company Assistant",
  BOOK_ONLY: "Book Only",
  VIEW_ONLY: "View Only",
  Analyst: "Analyst",
  FINANCE_ANALYST: "Finance Analyst",
};
