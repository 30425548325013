import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import dayjs from "dayjs";
import {
  Form,
  Button,
  Field,
  Input,
  Icon,
  Panel,
  theme,
  Heading,
  Box,
  UnstyledButton,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { TFilters } from "api/types";
import {
  OptionForSelect,
  parseCompaniesDataForFilter,
} from "components/users/parser";
import { SearchIcon } from "images";
import { readCompaniesForTables } from "api";
import { buildQuery } from "helpers/api";
import { useAuthContext } from "hooks";
import roles from "constants/roles";

interface JobsFilters {
  status: string;
  companyCode: string;
  ref: string;
  addressPostcode: string;
  date: string;
  createdAt: string;
  purchaseOrder: string;
  missingTip?: string;
  invoiceNumber: string;
}

/**
 * CustomMenu component is used to render the custom menu for the Companies select dropdown
 * Since we added a load more button to the Companies select dropdown, we need to create a custom menu
 */
const CustomMenu = ({ onClick, isLoading, hideLoadMore, ...rest }: any) => {
  return (
    <Fragment>
      <components.Menu {...rest}>
        {rest.children}
        {Boolean(hideLoadMore) === false && (
          <Box className="w-full flex justify-center">
            <UnstyledButton
              styles={{
                ...theme.fontDefaults.small,
                color: theme.colors.brand.base,
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineOffset: theme.spacing.xsmall2,
                marginTop: theme.spacing.xsmall,
                marginBottom: theme.spacing.small,
              }}
              onClick={onClick}
              className="flex justify-center items-center gap-x-2"
            >
              Load more
              {Boolean(isLoading) === true && <Icon.Loading size="small" />}
            </UnstyledButton>
          </Box>
        )}
      </components.Menu>
    </Fragment>
  );
};

const getParamsFromValues = (values: JobsFilters) => {
  const {
    status,
    addressPostcode,
    ref,
    companyCode,
    purchaseOrder,
    date,
    createdAt,
    missingTip,
    invoiceNumber,
  } = values;
  const [dateStart, dateEnd] = date.split(" - ");
  const [createdAtStart, createdAtEnd] = createdAt.split(" - ");

  const params: Record<string, string> = {
    "status:eq": status === "all" ? "" : status,
    "addressPostcode:like": `${addressPostcode.replaceAll(" ", "")}%`,
    "ref:like": `${ref}%`,
    "invoiceNumber:eq": invoiceNumber,
    "purchaseOrder:like": `${purchaseOrder}%`,
    "companyCode:eq": companyCode,
    "date:gte": dateStart
      ? dayjs(dayjs(dateStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "date:lte": dateEnd
      ? dayjs(dayjs(dateEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : dateStart
      ? dayjs(dayjs(dateStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "timestamp:gte": createdAtStart
      ? dayjs(dayjs(createdAtStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "timestamp:lte": createdAtEnd
      ? dayjs(dayjs(createdAtEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : createdAtStart
      ? dayjs(dayjs(createdAtStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    ...(missingTip === "true" ? { "tipId:isNull": "true" } : {}),
    ...defaultParams,
  };

  const updatedParams: Record<string, string> = {};

  Object.entries(params).forEach(([key, value]) => {
    // if the value is not empty and not equal solely to "%" then add it to the updatedParams
    if (!!value && value !== "%") {
      updatedParams[key] = value;
    }
  });

  return buildQuery(updatedParams);
};

interface JobsFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
  onDownloadClick: () => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const defaultParams = {
  orderByDesc: "date",
};

export const JobsFilters = ({
  isFetching,
  updateFilters,
  onDownloadClick,
  currentPage,
  setCurrentPage,
}: JobsFiltersProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const currentUserCompanies = getCurrentUserCompanies();
  const isAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [companiesOptions, setCompaniesOptions] = useState<OptionForSelect[]>(
    [],
  );
  const [totalCompaniesPagination, setTotalCompaniesPagination] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filterInitialValues, setFilterInitialValues] = useState({
    status: params.get("status") ?? "",
    companyCode: params.get("companyCode") ?? "",
    ref: params.get("ref") ?? "",
    addressPostcode: params.get("addressPostcode") ?? "",
    date: params.get("date") ?? "",
    createdAt: params.get("createdAt") ?? "",
    purchaseOrder: params.get("purchaseOrder") ?? "",
    invoiceNumber: params.get("invoiceNumber") ?? "",
    ...(isAdmin ? { missingTip: params.get("missingTip") ?? "" } : {}),
  });

  const { isLoading, refetch } = useQuery(
    ["readCompanies", "jobFilters", offset],
    async () => await readCompaniesForTables("", offset, 1000),
    {
      onSuccess: (data) => {
        if (!data?.items?.length) {
          return setCompaniesOptions(
            parseCompaniesDataForFilter(currentUserCompanies),
          );
        }

        setCompaniesOptions([
          ...companiesOptions,
          ...parseCompaniesDataForFilter(data.items),
        ]);

        setTotalCompaniesPagination(data.pagination.total);
      },
      enabled: !isViewOnly,
    },
  );

  /*
   * This useEffect is used to check if there are any filters in the URL
   * and if there are, it applies the filters
   */
  useEffect(() => {
    // reset companies options
    setOffset(0);
    refetch();

    const filtersExist = Object.values(filterInitialValues).some(
      (value) => value !== "",
    );

    if (filtersExist) {
      const queryParams = getParamsFromValues(filterInitialValues);
      updateFilters(queryParams);
      return;
    }
  }, []);

  /**
   * This useEffect is used to update the URL when the pagination changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    if (currentUrlWithoutBase.includes("page=")) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase.replace(
        /page=\d+/g,
        `page=${currentPage}`,
      );

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
    }
  }, [currentPage]);

  const clearFilters = () => {
    history.push("/jobs");
    setFilterInitialValues({
      status: "all",
      companyCode: "",
      ref: "",
      addressPostcode: "",
      date: "",
      createdAt: "",
      purchaseOrder: "",
      invoiceNumber: "",
      ...(isAdmin ? { missingTip: "" } : {}),
    });
    updateFilters(buildQuery(defaultParams));
  };

  const statusOptions = [
    "all",
    "Completed",
    "Started",
    "Open",
    "On+the+way",
    "Scheduled",
    "Failed",
    "Cancelled",
  ].map((value) => ({
    label: translate(`form.statusOptions.${value}`),
    value,
  }));

  const handleSubmit = (values: typeof filterInitialValues) => {
    values.ref = values.ref.trim();
    values.purchaseOrder = values.purchaseOrder.trim();
    values.missingTip = values.missingTip?.toString();
    const queryParams = getParamsFromValues(values);
    updateFilters(queryParams);
    const filters = Object.entries({
      ...values,
      page: 1,
    }).filter(([_, value]) => value !== "");
    history.push(
      `/jobs?${filters
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join("&")}`,
    );
    setCurrentPage(1);
  };

  return (
    <Form
      initialValues={filterInitialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ setFieldValue, values }) => (
        <Panel styles={{ marginBottom: theme.spacing.large }}>
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <Heading level={1} fontSize="large" color="brand">
              {translate("filters.title")}
            </Heading>

            <div className="flex flex-col md:flex-row items-stretch md:items-center md:self-end md:space-x-3 md:space-y-0 space-y-3 mt-3 md:mt-0">
              {isAdmin && (
                <Button
                  variant="outline"
                  color="usp"
                  size="small"
                  type="button"
                  disabled={isFetching}
                  onClick={onDownloadClick}
                >
                  {translate("filters.buttons.downloadFilteredJobs")}
                  <Icon.Download className="inline-block w-3 h-3 ml-1 -mt-1" />
                </Button>
              )}
              <Button
                variant="outline"
                color="negative"
                size="small"
                type="reset"
                onClick={clearFilters}
              >
                {translate("filters.buttons.reset")}
              </Button>
              {!isViewOnly && (
                <RouteLink href="/jobs/create">
                  <Button
                    as="a"
                    className="text-center"
                    variant="outline"
                    color="accent"
                    size="small"
                  >
                    {translate("filters.buttons.bookAJob")}
                  </Button>
                </RouteLink>
              )}
            </div>
          </div>
          <div className="border-t border-grey-200 mt-4 pt-5 flex flex-col -mx-2">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="status" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={statusOptions}
                      placeholder={translate("form.placeholder.status")}
                      disabled={isFetching}
                      isSearchable
                      defaultValue={statusOptions[0].value}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="companyCode" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Select
                      {...field}
                      options={companiesOptions}
                      value={
                        companiesOptions?.find(
                          ({ value }) => value === values.companyCode,
                        ) ?? null
                      }
                      placeholder={translate("form.placeholder.company")}
                      isClearable
                      isSearchable
                      isLoading={isFetching || isLoading}
                      components={{
                        Menu: (props) => (
                          <CustomMenu
                            {...props}
                            isLoading={isFetching || isLoading}
                            hideLoadMore={
                              totalCompaniesPagination ===
                              companiesOptions.length
                            }
                            onClick={() => setOffset(offset + 1)}
                          />
                        ),
                      }}
                      onChange={(option) => {
                        if (!option) {
                          return setFieldValue("companyCode", "");
                        }
                        setFieldValue("companyCode", option.value);
                      }}
                      styles={{
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          color: theme.colors.brand.base,
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                        }),
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="date" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.RangedDate
                      {...field}
                      placeholder={translate("form.placeholder.bookingDate")}
                      disabled={isFetching}
                      acceptSingleDate
                      collapsable
                      initialValue={filterInitialValues.date.split(" - ")}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="createdAt" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.RangedDate
                      {...field}
                      placeholder={translate("form.placeholder.dateCreated")}
                      disabled={isFetching}
                      acceptSingleDate
                      disabledDays={{
                        after: new Date(),
                      }}
                      collapsable
                      initialValue={filterInitialValues.createdAt.split(" - ")}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="ref" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("form.placeholder.reference")}
                      disabled={isFetching}
                    />
                  )}
                </Field>
              </div>
              <div className="w-full md:flex-1 px-2 mb-3">
                <Field name="addressPostcode" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("form.placeholder.postcode")}
                      disabled={isFetching}
                    />
                  )}
                </Field>
              </div>

              <div className=" md:flex-1 px-2 mb-3">
                <Field name="purchaseOrder" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={"Purchase Order"}
                      disabled={isFetching}
                    />
                  )}
                </Field>
              </div>
              <div className=" md:flex-1 px-2 mb-3">
                <Field name="invoiceNumber" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("form.placeholder.invoiceNumber")}
                      disabled={isFetching}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div
              className={`w-full my-2 flex md:flex-1 px-2 items-start ${
                !isAdmin && "invisible"
              }`}
            >
              <Field name="missingTip" styles={{ margin: 0 }}>
                <Input.Toggle
                  checked={values.missingTip === "true"}
                  onChange={(event) =>
                    setFieldValue("missingTip", event.target.checked.toString())
                  }
                  labelTextStyles={{ fontWeight: 500 }}
                  label="Missing Tip"
                />
              </Field>
            </div>
            <div className="flex justify-end">
              <div>
                <Button
                  id="submitQuoteFilters"
                  type="submit"
                  disabled={isFetching}
                  color="accent"
                  size="small"
                  className="w-full flex justify-center items-center"
                >
                  <SearchIcon className="mr-2" />
                  {translate("form.label.search")}
                </Button>
              </div>
            </div>
          </div>
        </Panel>
      )}
    </Form>
  );
};
