import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { instance } from "@clearabee/ui-sdk";
import {
  INotificationAction,
  ICondition,
  INotificationService,
} from "@clearabee/api-schemas";
import {
  Modal,
  Heading,
  Box,
  Field,
  Input,
  Form,
  Button,
  theme,
  Icon,
  Text,
  UnstyledButton,
} from "@clearabee/ui-library";
import { getErrorMessage, toasts } from "helpers";
import { validationSchema } from "./validation";
import { PreviewSchema } from "components/notifications/components";

interface CreateActionProps {
  notificationId: number;
  selectedAction?: INotificationAction;
  isVisible: boolean;
  services?: INotificationService[];
  onClose: () => void;
  onSubmit: () => void;
  onPreviewTriggerClick?: () => void;
}

const options = [
  { label: "equals", value: "==" },
  { label: "not equals", value: "!=" },
  { label: "greater than", value: ">" },
  { label: "less than", value: "<" },
  {
    label: "greater than or equals",
    value: ">=",
  },
  { label: "less than or equals", value: "<=" },
  { label: "includes", value: "includes" },
  { label: "in", value: "in" },
];

export const CreateAction = ({
  notificationId,
  selectedAction,
  isVisible,
  services,
  onClose,
  onSubmit,
  onPreviewTriggerClick,
}: CreateActionProps): React.ReactElement => {
  const [translate] = useTranslation("notifications");
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
    null,
  );

  const {
    data: conditions,
    isLoading: isLoadingGetConditions,
    refetch,
  }: {
    data: ICondition[] | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useQuery(
    ["getConditions", selectedAction?.id],
    async () => {
      return (
        await instance.notifications.getActionConditions(
          String(selectedAction?.id),
        )
      ).data;
    },
    {
      enabled: !!selectedAction?.id,
    },
  );

  const {
    mutate: postAction,
    isLoading: isLoadingPostAction,
    error: postActionError,
    reset: resetPostAction,
  } = useMutation(
    "postAction",
    async (values: INotificationAction) => {
      return await instance.notifications.postAction(values);
    },
    {
      onSuccess: () => {
        toasts.success({
          message: translate("upsertNotification.toasts.actionCreated"),
        });
        onClose();
        resetPostAction();
        onSubmit();
      },
    },
  );

  const {
    mutate: patchAction,
    isLoading: isLoadingPatchAction,
    error: patchActionError,
    reset: resetPatchAction,
  } = useMutation(
    async (values: INotificationAction) => {
      return await instance.notifications.patchAction(
        String(selectedAction?.id),
        values,
      );
    },
    {
      onSuccess: () => {
        toasts.success({
          message: translate("upsertNotification.toasts.actionUpdated"),
        });
        refetch();
        onSubmit();
      },
    },
  );

  const initialValues = {
    name: selectedAction?.name || "",
    serviceId: !!selectedAction?.serviceId
      ? String(selectedAction?.serviceId)
      : "",
    payload: JSON.stringify(selectedAction?.payload, null, 2) || "",
    conditions: conditions || [],
  };

  const service =
    services?.find((service) => String(service.id) === selectedServiceId)
      ?.schema ?? "";

  return (
    <>
      <Modal
        styles={{
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.small,
            maxHeight: "90vh",
          },
        }}
        width={800}
        modalVisible={isVisible}
        closeButtonStyles={{
          top: 20,
          right: 20,
        }}
        onClose={() => {
          onClose();
          resetPostAction();
          resetPatchAction();
        }}
      >
        <Form
          className="mt-8"
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            if (selectedAction) {
              patchAction({
                ...values,
                payload: JSON.parse(values.payload),
                notificationId,
                serviceId: Number(values.serviceId),
              });
            } else {
              postAction({
                ...values,
                payload: JSON.parse(values.payload),
                notificationId,
                serviceId: Number(values.serviceId),
              });
            }
          }}
        >
          {({ values, setFieldValue }) => {
            useEffect(() => {
              setFieldValue("conditions", conditions || []);
            }, [conditions]);

            return (
              <Box>
                <Heading level={3} color="brand">
                  {selectedAction
                    ? translate("upsertNotification.headings.updateAction")
                    : translate("upsertNotification.headings.createAction")}
                </Heading>
                <Box
                  backgroundColor="greyscale.lightest"
                  className="rounded-md mt-2"
                  styles={{
                    maxHeight: "70vh",
                    overflowY: "scroll",
                    padding: theme.spacing.small,
                    textAlign: "left",
                  }}
                >
                  <Field name="name" label="Action">
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                  <Field name="serviceId" label="Service">
                    {({ field }) => (
                      <Input.Select
                        {...field}
                        options={services?.map((service) => ({
                          label: service.name,
                          value: String(service.id),
                        }))}
                        defaultValue={String(selectedAction?.serviceId)}
                      />
                    )}
                  </Field>
                  <Box className="flex items-center justify-between mt-3">
                    <UnstyledButton
                      type="button"
                      disabled={!values.serviceId}
                      className={!!values.serviceId ? "visible" : "invisible"}
                      onClick={() => setSelectedServiceId(values.serviceId)}
                    >
                      <Text
                        color="brand"
                        styles={{
                          textDecoration: "underline",
                          textDecorationThickness: "2px",
                          textUnderlineOffset: theme.spacing.xsmall2,
                        }}
                        fontSize="small"
                      >
                        {translate(
                          "upsertNotification.links.previewServiceSchema",
                        )}
                      </Text>
                    </UnstyledButton>
                    <UnstyledButton
                      type="button"
                      onClick={onPreviewTriggerClick}
                    >
                      <Text
                        color="brand"
                        styles={{
                          textDecoration: "underline",
                          textDecorationThickness: "2px",
                          textUnderlineOffset: theme.spacing.xsmall2,
                        }}
                        fontSize="small"
                      >
                        {translate("upsertNotification.links.previewTrigger")}
                      </Text>
                    </UnstyledButton>
                  </Box>
                  <Field
                    styles={{ margin: `${theme.spacing.medium} 0` }}
                    name="payload"
                    label="Payload"
                  >
                    {({ field }) => (
                      <AceEditor
                        {...field}
                        mode="json"
                        theme="github"
                        showGutter={false}
                        editorProps={{ $blockScrolling: true }}
                        width="100%"
                        showPrintMargin={false}
                        height="250px"
                        highlightActiveLine={false}
                        enableBasicAutocompletion={true}
                      />
                    )}
                  </Field>
                  <Box className="flex items-center justify-between">
                    <Heading level={4} color="brand">
                      {translate("upsertNotification.headings.conditions")}
                    </Heading>
                    <Button
                      size="small"
                      type="button"
                      onClick={() =>
                        setFieldValue(
                          `conditions[${values.conditions.length}]`,
                          {
                            objectType: "objectType",
                            key: "",
                            operator: "",
                            value: "",
                          },
                        )
                      }
                    >
                      {translate("upsertNotification.buttons.addCondition")}
                    </Button>
                  </Box>
                  {isLoadingGetConditions ? (
                    <Box className="flex justify-center">
                      <Icon.Loading color="greyscale.lighter" size="large" />
                    </Box>
                  ) : !!values.conditions?.length ? (
                    values.conditions.map((condition, index) => (
                      <Box key={index} className="flex gap-4 h-28">
                        <Box className="w-1/3">
                          <Field
                            name={`conditions[${index}].key`}
                            label="Field"
                          >
                            {({ field }) => <Input.Text {...field} />}
                          </Field>
                        </Box>
                        <Box className="w-1/3">
                          <Field
                            name={`conditions[${index}].operator`}
                            label="Operator"
                          >
                            {({ field }) => (
                              <Input.Select {...field} options={options} />
                            )}
                          </Field>
                        </Box>
                        <Box className="w-1/3">
                          <Field
                            name={`conditions[${index}].value`}
                            label="Value"
                          >
                            {({ field }) => <Input.Text {...field} />}
                          </Field>
                        </Box>
                        <UnstyledButton
                          className="mt-1"
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              "conditions",
                              values.conditions.filter(
                                (_, conditionIndex) => conditionIndex !== index,
                              ),
                            )
                          }
                        >
                          <Icon.Trash color="negative" size="medium" />
                        </UnstyledButton>
                      </Box>
                    ))
                  ) : (
                    <Box
                      color="greyscale.lighter"
                      className="flex justify-center"
                    >
                      <Text fontSize="large" className="font-semibold">
                        {translate("upsertNotification.headings.noConditions")}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box className="flex flex-col items-center justify-center mt-4">
                  <Button
                    disabled={
                      isLoadingPostAction ||
                      isLoadingPatchAction ||
                      isLoadingGetConditions
                    }
                    type="submit"
                    size="medium"
                    color="accent"
                    className="flex flex-col items-center justify-center"
                  >
                    {isLoadingPostAction || isLoadingPatchAction ? (
                      <Icon.Loading size="small" />
                    ) : (
                      translate("upsertNotification.buttons.save")
                    )}
                  </Button>
                  {!!postActionError && (
                    <Box
                      className="px-3 mt-3 rounded-sm"
                      color="light"
                      backgroundColor="negative"
                    >
                      {getErrorMessage(postActionError)}
                    </Box>
                  )}
                  {!!patchActionError && (
                    <Box
                      className="px-3 mt-3 rounded-sm"
                      color="light"
                      backgroundColor="negative"
                    >
                      {getErrorMessage(patchActionError)}
                    </Box>
                  )}
                </Box>
              </Box>
            );
          }}
        </Form>
      </Modal>
      <PreviewSchema
        isVisible={!!selectedServiceId}
        onClose={() => setSelectedServiceId(null)}
        heading={translate("upsertNotification.headings.serviceSchema")}
        isSchemaCopyable={true}
        schema={service}
      />
    </>
  );
};
