import React, { useState } from "react";
import { camelToCapital } from "@clearabee/ui-sdk";
import {
  Box,
  Icon,
  Heading,
  Link,
  Button,
  Text,
  Message,
  UnstyledButton,
} from "@clearabee/ui-library";
import { toasts } from "helpers";

interface ViewCompanyDocumentsProps {
  companyCode?: string;
  isLoading?: boolean;
  isRefetching?: boolean;
  documents?:
    | {
        documentName: string | undefined;
        imageUrl: any;
        type: string;
      }[]
    | undefined;
  reloadOnClick?: () => void;
  isError?: boolean;
}

export const ViewCompanyDocuments = ({
  companyCode,
  documents,
  isLoading,
  isRefetching,
  reloadOnClick,
  isError,
}: ViewCompanyDocumentsProps): React.ReactElement => {
  const [activeFileDownload, setActiveFileDownload] = useState("");

  const downloadFile = async (fileURL: string, filename: string) => {
    setActiveFileDownload(filename);
    try {
      const blob = await fetch(fileURL, {
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
      }).then((r) => r.blob());

      const dataURL = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = dataURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setActiveFileDownload("");
    } catch {
      toasts.error({ message: "Failed to download file" });
      setActiveFileDownload("");
    }
  };

  return (
    <Box>
      <Box className="flex mb-2">
        <Box className="flex flex-row items-center justify-center gap-3">
          <Heading color="brand" level={5}>
            Documents
          </Heading>

          {!!reloadOnClick && (
            <Box>
              <Button
                disabled={isLoading || isRefetching}
                size="xsmall"
                onClick={reloadOnClick}
              >
                Reload
              </Button>
            </Box>
          )}

          {/* LOADING */}
          {(isLoading || isRefetching) && <Icon.Loading size="medium" />}

          {/* ERROR */}
          {isError && (
            <Message type="error">
              An error occurred while fetching documents
            </Message>
          )}
        </Box>
      </Box>

      <Box className="flex flex-col gap-2">
        {/* DISPLAY DOCUMENTS */}
        {!isLoading &&
          !isRefetching &&
          !isError &&
          !!documents &&
          !!documents.length &&
          documents.map(({ type, documentName, imageUrl: fileURL }, index) => {
            const isImageType = ["image", "signature"].some((value) =>
              type.includes(value),
            );
            const isPdfType = !!type.includes("pdf");
            const fileName = `${
              !!companyCode ? companyCode + "-" : ""
            }${documentName?.toLowerCase().replaceAll(" ", "")}.${
              isPdfType ? "pdf" : "png"
            }`;
            const isActiveFileDonwload = activeFileDownload === fileName;

            return (
              <Box key={index}>
                <Box className="flex justify-start items-center gap-x-2">
                  <Text className="font-semibold">
                    {camelToCapital(documentName || "")}:
                  </Text>
                  <UnstyledButton
                    disabled={isActiveFileDonwload}
                    styles={{
                      cursor: isActiveFileDonwload ? "not-allowed" : "pointer",
                      opacity: isActiveFileDonwload ? 0.5 : 1,
                    }}
                    onClick={() => downloadFile(fileURL, fileName)}
                  >
                    <Icon.Download size="small" />
                  </UnstyledButton>
                  {isActiveFileDonwload && (
                    <Icon.Loading size="medium" color="brand" />
                  )}
                </Box>
                {isImageType && (
                  <img
                    className="max-w-full my-3 h-auto"
                    src={fileURL}
                    alt={documentName}
                  />
                )}
                {isPdfType && (
                  <Link
                    target="_blank"
                    href={fileURL}
                    className="font-semibold"
                    underline
                  >
                    View PDF file
                  </Link>
                )}
              </Box>
            );
          })}

        {/* NO DOCUMENTS */}
        {!!documents &&
          documents?.length == 0 &&
          !isLoading &&
          !isRefetching && (
            <Text className="text-gray-500">No documents available</Text>
          )}

        {/* PLACEHOLDER */}
        {!documents && <Box className="h-20" />}
      </Box>
    </Box>
  );
};
