import React, { useState } from "react";
import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Table,
  theme,
  Box,
  Modal,
  UnstyledButton,
  Message,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { SearchIcon } from "images";
import { zoneInitialValues, zoneValidationSchema } from "./validation";
import { styles } from "./zones.styles";

const initialValues = {
  name: "",
};

const dayKeys = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

interface FormValues {
  name: string;
  postcodes: string[];
  blackoutDates?: string[];
  blackoutDays?: number[];
  id?: number;
}

type DataType = ApiResponseData<typeof instance.catalogues.getZones>;

export const Zones = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [showModal, setShowModal] = useState(false);
  const [postcodes, setPostcodes] = useState("");
  const [data, setData] = useState<DataType>();
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [formInitialValues, setFormInitialValues] =
    useState<FormValues>(zoneInitialValues);

  const {
    mutate,
    isSuccess,
    isError,
    reset,
    isLoading: postZoneLoading,
  } = useMutation(
    ["postZone", data],
    async (formValues: FormValues) => {
      await instance.catalogues.putZone({
        id: formValues?.id && formValues.id,
        postcodes:
          formValues?.postcodes.map((item) => ({
            postcode: item,
            zoneId: formValues.id && formValues.id,
          })) ?? [],
        zone: formValues?.name ?? "",
        blackoutDates: formValues?.blackoutDates ?? [],
        blackoutDays: formValues?.blackoutDays ?? [],
      });
    },
    {
      onSuccess: () => refetchZones(),
      onError: () => {
        setErrorMessage(translate("zones.errors.zonesFailed"));
      },
    },
  );

  // READ
  const {
    isLoading: load,
    isFetching: fetch,
    refetch: refetchZone,
  } = useQuery(
    ["getZone", selectedZoneIndex],
    async () =>
      (await instance.catalogues.getZone(`${selectedZoneIndex}`)).data,
    {
      onError: () => {
        toasts.error({
          message: translate("zones.errors.zonesFailed"),
        });
      },
      onSuccess: (data) => {
        setFormInitialValues({
          name: data.zone,
          postcodes: data.postcodes?.map((item) => item.postcode) ?? [],
          blackoutDates: data.blackoutDates ?? [],
          blackoutDays: data.blackoutDays ?? [],
          id: data.id,
        });
      },
      refetchOnMount: true,
      enabled: !!selectedZoneIndex,
    },
  );
  const {
    data: zones,
    isLoading,
    isFetching,
    refetch: refetchZones,
  } = useQuery(
    "readZones",
    async () => (await instance.catalogues.getZones()).data,
    {
      onError: () => {
        toasts.error({
          message: translate("zones.errors.zonesFailed"),
        });
      },
      onSuccess: (data) => {
        setData(data);
      },
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  return (
    <Box className="max-w-screen-lg mx-auto py-10 relative">
      <Panel
        styles={{
          paddingTop: theme.spacing.medium,
          paddingBottom: theme.spacing.medium,
          display: "flex",
          flexDirection: "column",
        }}
        className="flex flex-col lg:flex-row gap-x-6 justify-between"
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading fontSize="large" color="brand">
            {translate("zones.headings.zones")}
          </Heading>

          <Button
            color="accent"
            type="button"
            size="small"
            onClick={() => {
              setShowModal(true);
              setFormInitialValues(zoneInitialValues);
            }}
          >
            {translate("zones.buttons.add")}
          </Button>
        </Box>
        <Form
          className="flex flex-row items-center border-t border-grey-200 mt-4 pt-5"
          initialValues={initialValues}
          onSubmit={(values) => {
            if (values.name && zones) {
              return setData(
                zones.filter(({ zone }) =>
                  zone.toLowerCase().includes(values.name.toLowerCase()),
                ),
              );
            }
            setData(zones);
          }}
        >
          {({ resetForm }) => {
            return (
              <>
                <Box className="flex gap-x-3  w-full flex-1">
                  <Box className="w-full md:flex-1 ">
                    <Field
                      name="name"
                      className="flex-1 min-w-full"
                      label={translate("zones.form.labels.search")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "zones.form.placeholders.search",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Button
                  className="md:ml-6 flex items-center relative top-3"
                  size="small"
                  color="accent"
                  type="submit"
                >
                  <SearchIcon className="mr-2" />
                  {translate("zones.buttons.search")}
                </Button>
                <Button
                  className="ml-3 relative top-3"
                  size="small"
                  color="negative"
                  type="reset"
                  onClick={() => {
                    resetForm();
                    setData(zones);
                  }}
                >
                  {translate("zones.buttons.reset")}
                </Button>
              </>
            );
          }}
        </Form>
      </Panel>
      <Box className="relative flex flex-1">
        {isLoading || isFetching ? (
          <Icon.Loading
            color="brand"
            styles={{ margin: `${theme.spacing.xlarge} auto` }}
          />
        ) : (
          <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "70%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
            <Table.Header
              fontSize="small"
              headings={[
                translate("zones.table.headings.id"),
                translate("zones.table.headings.name"),
                translate("zones.table.headings.action"),
              ]}
            />
            <Table.Body>
              {data?.map((item) => (
                <Table.Row key={`table-row-${item.id}`}>
                  <Table.Cell.Text className="truncate">
                    {item.id}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.zone}
                  </Table.Cell.Text>
                  <Table.Cell>
                    <Box className="-ml-2">
                      <Button
                        size="xsmall"
                        type="button"
                        className="inline-block text-center"
                        onClick={() => {
                          item.id && setSelectedZoneIndex(item.id);
                          refetchZone();
                          setShowModal(true);
                        }}
                      >
                        {translate("zones.buttons.edit")}
                      </Button>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Box>
      {showModal && !load && !fetch && (
        <Modal styles={styles.modal}>
          <Form
            initialValues={formInitialValues}
            validationSchema={zoneValidationSchema}
            onSubmit={(formValues) => {
              setErrorMessage("");
              mutate(formValues);
            }}
          >
            {({ setFieldValue, values, submitForm }) => (
              <Box>
                <Box className="flex justify-end">
                  <UnstyledButton
                    onClick={() => {
                      setShowModal(false);
                      setPostcodes("");
                      reset();
                      setErrorMessage("");
                    }}
                  >
                    <Icon.Close size="small" />
                  </UnstyledButton>
                </Box>
                <Box className="flex">
                  <Heading fontSize="large" color="brand">
                    {translate("zones.headings.zone")}
                  </Heading>
                </Box>
                <Field name="name">
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("zones.form.placeholders.name")}
                      className="mr-6 mt-2"
                    />
                  )}
                </Field>
                <Box styles={styles.modalContentWrapper}>
                  <Box className="flex mt-4">
                    <Heading fontSize="large" color="brand">
                      {translate("zones.headings.blackoutDays")}
                    </Heading>
                  </Box>
                  <Box className="flex">
                    {dayKeys.map((key, index) => {
                      return (
                        <Box key={index} className="flex flex-col">
                          <Box className="flex ml-2">
                            {translate(`zones.form.labels.days.${key}`)}
                          </Box>
                          <Field
                            key={key}
                            name={`blackoutDays.${index}`}
                            styles={styles.field}
                          >
                            <Box styles={styles.checkboxContainer}>
                              <Input.Checkbox
                                onChange={(value) => {
                                  if (value.target.checked) {
                                    setFieldValue("blackoutDays", [
                                      ...(values.blackoutDays ?? []),
                                      index,
                                    ]);
                                  }
                                  if (
                                    !value.target.checked &&
                                    values.blackoutDays
                                  ) {
                                    setFieldValue(
                                      "blackoutDays",
                                      values.blackoutDays.filter(
                                        (item) => item !== index,
                                      ),
                                    );
                                  }
                                }}
                                defaultChecked={values.blackoutDays?.includes(
                                  index,
                                )}
                              />
                            </Box>
                          </Field>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box styles={styles.divider} />
                  <Box className="flex mt-4">
                    <Heading fontSize="large" color="brand">
                      {translate("zones.headings.blackoutDates")}
                    </Heading>
                  </Box>
                  <Box styles={styles.dataWrapper}>
                    {values.blackoutDates?.map((item, index) => (
                      <Box styles={styles.dataPin}>
                        <Box className="flex">
                          {dayjs(item).format("DD/MM/YYYY")}
                          <UnstyledButton
                            className="ml-2"
                            onClick={() =>
                              setFieldValue(
                                "blackoutDates",
                                values.blackoutDates?.filter(
                                  (_, i) => i !== index,
                                ),
                              )
                            }
                          >
                            <Icon.Close color="dark.base" size="xsmall" />
                          </UnstyledButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box className="flex justify-between items-center mb-5">
                    <Box className="flex w-full  pr-3">
                      <Input.Date
                        styles={{ width: "100%" }}
                        collapsable
                        readOnly
                        placeholder={translate("zones.form.placeholders.date")}
                        dateFormat="YYYY-MM-DD"
                        onInput={(target) => {
                          const inputBlackoutDate = target.currentTarget.value;

                          if (
                            !inputBlackoutDate ||
                            values.blackoutDates?.includes(inputBlackoutDate)
                          ) {
                            target.currentTarget.value = "";
                            return;
                          }

                          setFieldValue("blackoutDates", [
                            ...(values?.blackoutDates ?? []),
                            inputBlackoutDate,
                          ]);

                          target.currentTarget.value = "";
                        }}
                      />
                    </Box>
                  </Box>
                  <Box styles={styles.divider} />
                  <Box className="flex mt-4">
                    <Heading fontSize="large" color="brand">
                      {translate("zones.headings.postcodes")} (
                      {values.postcodes.length})
                    </Heading>
                  </Box>
                  <Box styles={styles.dataWrapper}>
                    {values.postcodes.map((item, index) => (
                      <Box key={index} styles={styles.dataPin}>
                        <Box className="flex">
                          {item}
                          <UnstyledButton
                            className="ml-2"
                            onClick={() =>
                              setFieldValue(
                                "postcodes",
                                values.postcodes.filter((_, i) => i !== index),
                              )
                            }
                          >
                            <Icon.Close color="dark.base" size="xsmall" />
                          </UnstyledButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box className="flex justify-between items-center mb-5">
                    <Box className="flex w-4/5 pr-3 pt-3 flex-col">
                      <Input.Text
                        value={postcodes}
                        onChange={({ target }) => setPostcodes(target.value)}
                        placeholder={translate(
                          "zones.form.placeholders.postcodes",
                        )}
                      />
                      <Box styles={styles.smallText}>
                        {translate("zones.texts.uploadMultiplePostcodes")}
                      </Box>
                    </Box>
                    <Button
                      styles={styles.button}
                      disabled={!postcodes.length}
                      type="button"
                      size="xsmall"
                      onClick={() => {
                        reset();
                        setErrorMessage("");

                        //Remove spaces and convert to uppercase
                        const updatedPostcodes = postcodes
                          .toUpperCase()
                          .replace(" ", "")
                          .split(",");

                        const res = updatedPostcodes.filter((postcode) => {
                          if (postcode.length < 6 && postcode.length > 1) {
                            return !values.postcodes.includes(postcode);
                          } else {
                            setErrorMessage(
                              translate("zones.errors.invalidPostcode"),
                            );
                            return false;
                          }
                        });
                        setFieldValue("postcodes", [
                          ...values.postcodes,
                          ...res,
                        ]);
                        setPostcodes("");
                      }}
                    >
                      {translate("zones.buttons.addPostcode")}
                    </Button>
                  </Box>
                </Box>
                <Box styles={styles.divider} />
                <Box className="flex justify-between items-center -mt-3">
                  <Box className="w-1/2 flex">
                    {postZoneLoading && <Icon.Loading color="brand" />}
                    {isSuccess && (
                      <Message type="success" color="light" background>
                        {translate("zones.success.zones")}
                      </Message>
                    )}
                    {(isError || errorMessage) && (
                      <Message type="error" background>
                        {errorMessage}
                      </Message>
                    )}
                  </Box>
                  <Box className="mr-3 w-1/2 flex justify-end">
                    <Button
                      disabled={formInitialValues === values || postZoneLoading}
                      type="button"
                      size="small"
                      color="positive"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {translate("zones.buttons.save")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Form>
        </Modal>
      )}
    </Box>
  );
};
