import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { instance } from "@clearabee/ui-sdk";
import { IJob } from "@clearabee/api-schemas";
import {
  Panel,
  Text,
  Box,
  theme,
  Input,
  UnstyledButton,
  Heading,
  formatPostcode,
  Button,
  Form,
  Field,
  Icon,
} from "@clearabee/ui-library";
import {
  DisplayScheduledJobs,
  UnScheduledJobList,
  VehicleList,
} from "./components";
import { LoadingOverlay } from "components/common/components";
import { initialValues, validationSchema } from "./validation";
import { toasts, truncateString } from "helpers";

dayjs.extend(utc);

export const Schedule = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [date, setDate] = useState(new Date());
  const [selectedJob, setSelectedJob] = useState<IJob | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [activePosition, setActivePosition] = useState<number | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  /**
   * Fetch unscheduled jobs
   */
  const {
    data: allUnscheduledJobs,
    isLoading: isLoadingAllUnscheduledJobs,
    isError: isErrorAllUnscheduledJobs,
    isRefetching: isRefetchingAllUnscheduledJobs,
    refetch: refetchAllUnscheduledJobs,
  } = useQuery(
    ["getAllUnscheduledJobs", date],
    async () =>
      (
        await instance.jobs.getJobs({
          params: {
            "date:gte": dayjs(date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            "date:lt": dayjs(date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            "asset:emptyOrNull": "true",
            limit: 1500,
          },
        })
      ).data.items,
    {
      retry: false,
    },
  );

  /**
   * Fetch job by id
   */
  const { mutate: mutateGetJobById, isLoading: isLoadingGetJobById } =
    useMutation(
      "getJobById",
      async (jobId: string) =>
        (await instance.jobs.getJob(jobId)).data as unknown as IJob,
      {
        retry: false,
        onSuccess: (data) => {
          setSelectedJob(data);
        },
      },
    );

  /**
   * Fetch vehicles
   */
  const {
    data: allVehicles,
    isLoading: isLoadingAllVehicles,
    isRefetching: isRefetchingAllVehicles,
    isError: isErrorAllVehicles,
    refetch: refetchAllVehicles,
  } = useQuery(
    "getVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            // only get approved vehicles
            "status:eq": "approved",
            limit: 1500,
          },
        })
      ).data.items,
    {
      retry: false,
    },
  );

  /**
   * Vehicle options for select input
   */
  const vehicleOptions = useMemo(() => {
    if (!allVehicles?.length) {
      return [];
    }
    return allVehicles?.map(({ name, registration }) => ({
      label: name || registration,
      value: registration,
    }));
  }, [allVehicles]);

  /**
   * Fetch scheduled jobs for list view display when vehicle is selected in the vehicle list
   */
  const {
    mutate: mutateScheduledJobsForListView,
    data: scheduledJobsForListView,
    isLoading: isLoadingScheduledJobsForListView,
    isError: isErrorScheduledJobsForListView,
  } = useMutation(
    "getScheduledJobsForListView",
    async (asset: string) => {
      if (!asset) return [];

      return (
        await instance.jobs.getJobsByAsset(asset, {
          params: {
            date: dayjs.utc(date).toISOString(),
            "include-related": true,
          },
        })
      ).data;
    },
    {
      retry: false,
    },
  );

  /**
   * Fetch scheduled jobs for vehicle select input
   */
  const {
    mutate: mutateScheduledJobsForSelectInput,
    data: scheduledJobsForSelectInput,
    isLoading: isLoadingScheduledJobsForSelectInput,
    isError: isErrorScheduledJobsForSelectInput,
  } = useMutation(
    "getScheduledJobsForSelectInput",
    async (asset: string) => {
      if (!asset) return [];

      return (
        await instance.jobs.getJobsByAsset(asset, {
          params: {
            date: dayjs.utc(date).toISOString(),
            "include-related": true,
          },
        })
      ).data;
    },
    {
      retry: false,
    },
  );

  // PATCH JOB
  const {
    mutateAsync: mutateAsyncPatchJob,
    isLoading: isLoadingPatchJob,
    isSuccess: isSuccessPatchJob,
  } = useMutation(
    "patchJob",
    async (
      payload: typeof initialValues & { id: string; isUnassign: boolean },
    ) => {
      const job = (
        await instance.jobs.patchJob(payload.id, {
          ...(payload.plannedStartTime
            ? { plannedStartTime: payload.plannedStartTime }
            : {}),
          ...(payload.duration && Number(payload.duration) !== 0
            ? { duration: payload.duration }
            : {}),
          asset: payload.vehicleReg,
          suppressEmail: true,
        })
      ).data as unknown as IJob;

      return { isUnassign: payload.isUnassign, job };
    },
    {
      retry: false,
      onSuccess: ({ isUnassign, job }) => {
        if (job.ref === selectedJob?.ref) {
          setSelectedJob(job);
        }

        refetchAllUnscheduledJobs();
        toasts.success({
          message: `${
            isUnassign ? "Unassigned" : "Scheduled"
          } job successfully`,
        });
      },
    },
  );

  /**
   * This function is used to handle the patch job request (assign/unassign job)
   */
  const handlePatchJob = async (
    jobId: string,
    values: typeof initialValues | null,
    isUnassign: boolean,
  ) => {
    if (isUnassign) {
      await mutateAsyncPatchJob({
        id: jobId,
        vehicleReg: "",
        plannedStartTime: "",
        duration: 0,
        isUnassign: true,
      });
      return;
    }

    const updatedPlannedStartTime = dayjs
      .utc(
        `${dayjs(date).format("DD-MM-YYYY")} ${
          values?.plannedStartTime || "02:00"
        }`,
        "DD-MM-YYYY HH:mm",
      )
      .toISOString();

    await mutateAsyncPatchJob({
      id: jobId,
      vehicleReg: values?.vehicleReg ?? "",
      plannedStartTime: updatedPlannedStartTime,
      duration: values?.duration ?? 0,
      isUnassign: false,
    });
  };

  /**
   *  This function is used to toggle the fullscreen mode of the container
   */
  const toggleFullScreen = () => {
    const element = document.getElementById("fullScreenContainer");

    const isFullScreenElement = document.fullscreenElement;

    if (isFullScreenElement) {
      document.exitFullscreen();
      return;
    }

    element?.requestFullscreen();
  };

  /**
   * This effect is used to listen to the selected vehicle and update the scheduled jobs for the list view display
   */
  useEffect(() => {
    if (!selectedVehicle) return;

    mutateScheduledJobsForListView(selectedVehicle);

    const element = document.getElementById(`vehicle-${selectedVehicle}`);

    const rect = element?.getBoundingClientRect();

    setActivePosition(rect?.top ?? null);
  }, [selectedVehicle]);

  /**
   * This effect is used to listen to the fullscreen change event, to update the styles of the container
   */
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
    const element = document.getElementById("fullScreenContainer");

    element?.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      element?.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <Box id="fullScreenContainer" className="min-h-full">
      {/* MAIN LOADINGOVERLAY */}
      {isLoadingPatchJob && !activePosition && <LoadingOverlay />}

      {!!activePosition && (
        <>
          <UnstyledButton
            onClick={() => {
              setSelectedVehicle("");
              setActivePosition(null);
            }}
            styles={{
              zIndex: 1,
              height: "100%",
              width: "100%",
              left: 0,
              top: 0,
              overflow: "hidden",
              position: "fixed",
              background: theme.colors.greyscale.lightest,
              opacity: 0.6,
            }}
          />
          <Box
            styles={{
              position: "absolute",
              width: "70%",
              top: activePosition - 40,
              left: "9%",
              padding: theme.spacing.small,
              borderRadius: theme.spacing.xsmall,
              backgroundColor: theme.colors.light.base,
              zIndex: 3,
            }}
          >
            <Box className="flex justify-between mb-3">
              <Box className="flex items-center gap-x-2">
                <Text fontSize="small" className="font-semibold" color="brand">
                  {translate("schedule.texts.vehicleHeading", {
                    name:
                      vehicleOptions.find(
                        ({ value }) => value === selectedVehicle,
                      )?.label ?? "",
                    registration: selectedVehicle,
                    date: dayjs(date).format("DD-MM-YYYY"),
                    jobsCount: scheduledJobsForListView?.length ?? 0,
                  })}
                </Text>
                <Button
                  type="button"
                  size="xsmall"
                  onClick={() =>
                    mutateScheduledJobsForListView(selectedVehicle)
                  }
                >
                  {translate("schedule.buttons.reload")}
                </Button>
              </Box>
              <Button
                size="xsmall"
                type="button"
                variant="outline"
                onClick={() => {
                  setSelectedVehicle("");
                  setActivePosition(null);
                }}
              >
                {translate("schedule.buttons.close")}
              </Button>
            </Box>
            <DisplayScheduledJobs
              data={scheduledJobsForListView}
              selectedJob={selectedJob}
              onClick={(job) => {
                setSelectedJob(job);
              }}
              onClickUnassign={(unassignedJob) => {
                handlePatchJob(String(unassignedJob?.id ?? ""), null, true);
              }}
              isLoading={
                (!!activePosition && isLoadingPatchJob) ||
                isLoadingScheduledJobsForListView
              }
              isError={isErrorScheduledJobsForListView}
            />
          </Box>
        </>
      )}
      <Panel
        shadow={false}
        styles={{
          marginTop: theme.spacing.xsmall,
        }}
      >
        <Box className="flex justify-between items-center">
          <Heading color="brand" level={1} fontSize="large">
            {translate("schedule.headings.jobSchedule")}
          </Heading>
          <Box className="flex items-center gap-x-4">
            <Link
              to="/jobs/schedule/map"
              className="btn-tiny mt-2 sm:mt-0 btn btn-primary hover:bg-primary hover:text-white transition ease-in duration-100"
            >
              {translate("schedule.headings.viewMap")}
            </Link>
            <Link
              to="/jobs/schedule/view"
              className="btn-tiny mt-2 sm:mt-0 btn btn-primary hover:bg-primary hover:text-white transition ease-in duration-100"
            >
              {translate("schedule.headings.viewSchedule")}
            </Link>
            <UnstyledButton type="button" onClick={toggleFullScreen}>
              <Icon.FullScreen size="medium" />
            </UnstyledButton>
          </Box>
        </Box>

        {/* DIVIDER */}
        <Box className="border-t border-gray-300 my-2 flex flex-col h-1" />

        <Box className="w-1/5 mb-10">
          <label className="font-semibold">
            {translate("schedule.form.labels.jobDate")}
          </label>
          <Input.Date
            collapsable
            value={dayjs(date).format("DD-MM-YYYY")}
            dateFormat="DD-MM-YYYY"
            placeholder={translate("schedule.form.placeholders.jobDate")}
            disabledDays={{ before: dayjs().toDate() }}
            onChange={(e) =>
              setDate(dayjs(e.target.value, "DD-MM-YYYY").toDate())
            }
          />
        </Box>

        <Box className="flex justify-between items-start gap-x-2">
          {/* UNSCHEDULED JOBS LIST */}
          <Box className="w-1/5">
            <UnScheduledJobList
              data={allUnscheduledJobs ?? []}
              isLoading={
                isLoadingAllUnscheduledJobs || isRefetchingAllUnscheduledJobs
              }
              isError={isErrorAllUnscheduledJobs}
              refetch={refetchAllUnscheduledJobs}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
              isFullScreen={isFullScreen}
            />
          </Box>
          {/* SHEDULE ACTION */}
          <Box className="w-3/5">
            <Form
              initialValues={{
                ...initialValues,
                vehicleReg: selectedJob?.asset ?? "",
                plannedStartTime: !!selectedJob?.plannedStartTime
                  ? dayjs.utc(selectedJob?.plannedStartTime).format("HH:mm")
                  : "",
                duration: selectedJob?.duration ?? 0,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handlePatchJob(String(selectedJob?.id ?? ""), values, false);
              }}
            >
              {({ values, isValid }) => {
                useEffect(() => {
                  if (!values.vehicleReg) return;

                  mutateScheduledJobsForSelectInput(values.vehicleReg);
                }, [values.vehicleReg]);

                useEffect(() => {
                  if (isSuccessPatchJob) {
                    if (!!activePosition) {
                      mutateScheduledJobsForListView(selectedVehicle);
                      return;
                    }
                    mutateScheduledJobsForSelectInput(values.vehicleReg);
                  }
                }, [isSuccessPatchJob, activePosition]);

                return (
                  <Box
                    styles={{
                      marginTop: "22px",
                      borderWidth: "1px",
                      borderColor: theme.colors.brand.lighter,
                      padding: theme.spacing.xsmall,
                      borderRadius: theme.spacing.xsmall,
                      height: isFullScreen ? "77.5vh" : "77vh",
                    }}
                  >
                    {!!selectedJob && (
                      <>
                        <Box className="w-full h-full flex flex-col">
                          {/* JOB Details */}
                          <Box className="flex flex-col gap-y-2 mt-2 mb-5">
                            <Box className="flex items-center gap-x-2">
                              <Text color="brand" className="font-semibold">
                                {translate("schedule.headings.jobDetails")}
                              </Text>
                              <Button
                                type="button"
                                onClick={() =>
                                  mutateGetJobById(
                                    String(selectedJob?.id ?? ""),
                                  )
                                }
                                size="xsmall"
                                className="flex items-center gap-x-1"
                              >
                                {translate("schedule.buttons.reload")}
                                {isLoadingGetJobById && (
                                  <Icon.Loading size="small" />
                                )}
                              </Button>
                            </Box>
                            <Box className="bg-gray-200 flex gap-x-6 gap-y-4 flex-wrap px-2 py-4 rounded-md">
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.reference")}
                                </Text>
                                <Text fontSize="xsmall">{selectedJob.ref}</Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.status")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {selectedJob.status}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.type")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {truncateString(selectedJob?.type ?? "", 15)}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.date")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {dayjs
                                    .utc(selectedJob.date)
                                    .format("DD/MM/YYYY")}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate(
                                    "schedule.form.labels.plannedStartTime",
                                  )}
                                </Text>
                                <Text fontSize="xsmall">
                                  {!!selectedJob.plannedStartTime
                                    ? dayjs
                                        .utc(selectedJob.plannedStartTime)
                                        .format("HH:mm")
                                    : "N/A"}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.duration")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {!!selectedJob?.duration
                                    ? `${selectedJob.duration} minutes`
                                    : "N/A"}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.timeslot")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {selectedJob?.timeslot || "N/A"}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.asset")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {selectedJob?.asset || "N/A"}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.postcode")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {formatPostcode(selectedJob.addressPostcode)}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.tip")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {truncateString(
                                    selectedJob.tip?.name || "N/A",
                                    20,
                                  )}
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  fontSize="xsmall"
                                  className="font-semibold"
                                >
                                  {translate("schedule.form.labels.bcId")}
                                </Text>
                                <Text fontSize="xsmall">
                                  {selectedJob.bcId}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Box className="flex flex-col gap-y-2 mb-5">
                            <Text color="brand" className="font-semibold">
                              {translate("schedule.headings.update")}
                            </Text>
                            <Box className="bg-gray-200 flex gap-x-2 px-2 py-4 rounded-md">
                              <Field
                                styles={{
                                  flex: 1,
                                  marginTop: theme.spacing.xsmall2,
                                  marginBottom: theme.spacing.xsmall,
                                }}
                                name="vehicleReg"
                                label={translate(
                                  "schedule.form.labels.vehicle",
                                )}
                              >
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    isClearable
                                    isSearchable
                                    placeholder={translate(
                                      "schedule.form.placeholders.vehicle",
                                    )}
                                    value={
                                      vehicleOptions?.find(
                                        ({ value }) => value === field.value,
                                      ) ?? null
                                    }
                                    isLoading={
                                      isLoadingAllVehicles ||
                                      isRefetchingAllVehicles
                                    }
                                    disabled={
                                      isLoadingAllVehicles ||
                                      isRefetchingAllVehicles
                                    }
                                    options={vehicleOptions}
                                    onChange={(option) => {
                                      field.onChange(option?.value);
                                    }}
                                  />
                                )}
                              </Field>
                              <Field
                                styles={{
                                  flex: 1,
                                  marginTop: theme.spacing.xsmall2,
                                  marginBottom: theme.spacing.xsmall,
                                }}
                                name="plannedStartTime"
                                label={translate(
                                  "schedule.form.labels.plannedStartTimeText",
                                )}
                              >
                                {({ field }) => (
                                  <Input.Text
                                    {...field}
                                    type="time"
                                    placeholder={translate(
                                      "schedule.form.placeholders.plannedStartTimeText",
                                    )}
                                  />
                                )}
                              </Field>
                              <Field
                                styles={{
                                  flex: 1,
                                  marginTop: theme.spacing.xsmall2,
                                  marginBottom: theme.spacing.xsmall,
                                }}
                                name="duration"
                                label={translate(
                                  "schedule.form.labels.durationMinute",
                                )}
                              >
                                {({ field }) => (
                                  <Input.Text
                                    {...field}
                                    type="number"
                                    placeholder={translate(
                                      "schedule.form.labels.durationMinute",
                                    )}
                                  />
                                )}
                              </Field>
                            </Box>
                          </Box>
                          {!!values.vehicleReg && (
                            <Box className="flex flex-col gap-y-2 mb-5">
                              <Box className="flex items-center gap-x-2">
                                <Text color="brand" className="font-semibold">
                                  {translate(
                                    "schedule.texts.selectedVehicleHeading",
                                    {
                                      name:
                                        vehicleOptions.find(
                                          ({ value }) =>
                                            value === values.vehicleReg,
                                        )?.label ?? "",
                                      registration: values.vehicleReg,
                                      jobsCount:
                                        scheduledJobsForSelectInput?.length ??
                                        0,
                                    },
                                  )}
                                </Text>
                                <Button
                                  type="button"
                                  size="xsmall"
                                  onClick={() =>
                                    mutateScheduledJobsForSelectInput(
                                      values.vehicleReg,
                                    )
                                  }
                                >
                                  {translate("schedule.buttons.reload")}
                                </Button>
                              </Box>
                              <DisplayScheduledJobs
                                data={scheduledJobsForSelectInput}
                                selectedJob={selectedJob}
                                onClick={(job) => {
                                  setSelectedJob(job);
                                }}
                                onClickUnassign={(unassignedJob) => {
                                  handlePatchJob(
                                    String(unassignedJob?.id ?? ""),
                                    null,
                                    true,
                                  );
                                }}
                                isLoading={isLoadingScheduledJobsForSelectInput}
                                isError={isErrorScheduledJobsForSelectInput}
                              />
                            </Box>
                          )}
                          <Box className="mt-auto flex justify-center gap-x-2">
                            <Button
                              color="negative"
                              size="small"
                              type="button"
                              onClick={() => setSelectedJob(null)}
                            >
                              {translate("schedule.buttons.cancel")}
                            </Button>
                            <Button
                              color="warning"
                              size="small"
                              type="submit"
                              disabled={!isValid}
                            >
                              {translate("schedule.buttons.confirm")}
                            </Button>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                );
              }}
            </Form>
          </Box>
          {/* VEHICLE LIST */}
          <Box
            className="w-1/5"
            styles={{
              zIndex: 2,
            }}
          >
            <VehicleList
              data={allVehicles ?? []}
              isLoading={isLoadingAllVehicles || isRefetchingAllVehicles}
              isError={isErrorAllVehicles}
              refetch={refetchAllVehicles}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
              isFullScreen={isFullScreen}
            />
          </Box>
        </Box>
      </Panel>
    </Box>
  );
};
