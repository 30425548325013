import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select, { OptionTypeBase } from "react-select";
import { IVehicleScheduleResponse } from "@clearabee/api-schemas";
import { Box, theme, Input, Panel, Text } from "@clearabee/ui-library";
import { FlattenedScheduleDate } from "../helpers/helpers";

dayjs.extend(utc);

interface DateCellProps {
  vehicleReg: string;
  vehicleId: number;
  vehicleSchedule: IVehicleScheduleResponse;
  options: OptionTypeBase[];
  date: string;
  disabledOptions: number[];
  setUnsavedChanges: (booleanValue: boolean) => void;
  updateFlatScheduleDates: (
    date: string,
    reg: string,
    scheduleDates: FlattenedScheduleDate[],
  ) => void;
  flatScheduleDates: FlattenedScheduleDate[];
}

export const DateCell = ({
  vehicleReg,
  vehicleSchedule,
  date,
  options,
  vehicleId,
  disabledOptions,
  updateFlatScheduleDates,
  setUnsavedChanges,
  flatScheduleDates,
}: DateCellProps): React.ReactElement => {
  const [time, setTime] = useState(
    vehicleSchedule.schedule.find(
      (scheduleDate) =>
        dayjs.utc(scheduleDate.date).toISOString() ===
        dayjs.utc(date).toISOString(),
    )?.time || "06:00",
  );

  const [message, setMessage] = useState(
    vehicleSchedule.schedule.find(
      (scheduleDate) =>
        dayjs.utc(scheduleDate.date).toISOString() ===
        dayjs.utc(date).toISOString(),
    )?.message || "",
  );

  const [users, setUsers] = useState<OptionTypeBase[]>(
    vehicleSchedule.schedule
      // find users for initial values from the schedule dates
      // filter out empty strings
      .map((scheduleDate) =>
        dayjs.utc(scheduleDate.date).toISOString() ===
        dayjs.utc(date).toISOString()
          ? String(scheduleDate.userId)
          : "",
      )
      .filter((userId) => userId !== "")
      .map((userId) => ({
        value: userId,
        label: options.find((option) => option.value === userId)?.label || "",
      })),
  );

  const setAllAssetDates = (
    userIds: string[],
    time: string,
    message?: string,
  ) => {
    // Create flatten schedule dates from the select input values
    const newAssetDates: FlattenedScheduleDate[] = userIds.map((id) => {
      return {
        user: options.find((option) => option.value === id)?.label || "",
        asset: vehicleReg,
        date: dayjs.utc(date).toISOString(),
        time: time,
        userId: Number(id),
        vehicleId: vehicleId,
        message: message,
      };
    });

    updateFlatScheduleDates(date, vehicleReg, newAssetDates);
  };

  useEffect(() => {
    const found = flatScheduleDates.filter(
      (scheduleDate) =>
        scheduleDate.asset === vehicleReg &&
        dayjs.utc(scheduleDate.date).toISOString() ===
          dayjs.utc(date).toISOString(),
    );

    if (!found) {
      return;
    }

    setTime(found[0]?.time ?? "06:00");

    setMessage(found[0]?.message ?? "");

    setUsers(
      found.map((scheduleDate) => ({
        value: String(scheduleDate.userId),
        label: scheduleDate.user,
      })),
    );
  }, [JSON.stringify(flatScheduleDates), date]);

  return (
    <Box className="w-1/3" key={`${vehicleReg} - ${date} - date cell`}>
      <Panel
        shadow={false}
        styles={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          minHeight: "250px",
          padding: theme.spacing.small,
          border: `1px solid ${theme.colors.brand.lighter}`,
        }}
      >
        <Text className="font-semibold" fontSize="base">
          {dayjs.utc(date).format("DD/MM/YYYY")}
        </Text>

        <Box className="w-full flex flex-col gap-y-3">
          {/* TIME */}
          <Box className="flex flex-col gap-y-1">
            <label className="font-semibold">Time</label>
            <Input.Text
              placeholder="Time"
              type="time"
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
                setAllAssetDates(
                  users.map((user) => user.value),
                  e.target.value,
                  message,
                );
                setUnsavedChanges(true);
              }}
            />
          </Box>
          {/* MESSAGE */}
          <Box className="flex flex-col gap-y-1">
            <label className="font-semibold">Message</label>
            <Input.Textarea
              placeholder="Message"
              slim={true}
              value={message}
              disabled={!users.length}
              onChange={(e) => {
                setMessage(e.target.value);

                setAllAssetDates(
                  users.map((user) => user.value),
                  time,
                  e.target.value,
                );
                setUnsavedChanges(true);
              }}
            />
          </Box>
          {/* DRIVERS */}
          <Box className="flex flex-col gap-y-1">
            <label className="font-semibold">Drivers</label>
            <Select
              isMulti
              options={options}
              value={users.filter((user) => user.label !== "")}
              isOptionDisabled={(option) =>
                disabledOptions.includes(Number(option.value))
              }
              onChange={(options) => {
                if (!options) {
                  setUsers([]);
                  setAllAssetDates([], time, message);
                  return;
                }

                setUsers([...options]);
                setAllAssetDates(
                  options.map((user) => user.value),
                  time,
                  message,
                );

                setUnsavedChanges(true);
              }}
            />
          </Box>
        </Box>
      </Panel>
    </Box>
  );
};
