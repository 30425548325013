import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { instance } from "@clearabee/ui-sdk";
import { IPaymentLink } from "@clearabee/api-schemas";
import { Box, Button, Heading, Icon, Table } from "@clearabee/ui-library";
import { useScreenWidth } from "hooks";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { toasts } from "helpers";
import { buildQuery, getErrorMessage } from "helpers/api";
import { handleException } from "helpers/handleException";
import { IPaginatedResults, TFilters } from "api/types";
import {
  BookAgainPaymentLinkModal,
  CancelPaymentLinkModal,
  PaymentLinksFilters,
  ResendPaymentLinkModal,
} from "./components";
import { LoadingOverlay } from "components/common/components";
import { paginationStyles } from "components/common/resusablePaginationStyles";

export type StatusColors = {
  [Key in IPaymentLink["status"]]?: AllowedColorKeys;
};

export const statusColors: StatusColors = {
  pending: "warning",
  paid: "positive",
  expired: "greyscale",
  cancelled: "negative",
};

// initial results per page for pagination
const initialResultsPerPage = 10;

export const ReadPaymentLinks = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  const [total, setTotal] = useState(0);
  const [activeBasketToken, setActiveBasketToken] = useState("");
  const [activeCancelLink, setActiveCancelLink] = useState<IPaymentLink | null>(
    null,
  );
  const [activeResendLink, setActiveResendLink] = useState<IPaymentLink | null>(
    null,
  );
  const [activeBookAgainLink, setActiveBookAgainLink] =
    useState<IPaymentLink | null>(null);

  const history = useHistory();
  const location = useLocation();
  const { isMobile, isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  /**
   *  function to read the payment links and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readPayments = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IPaymentLink>> => {
    const filterParams = new URLSearchParams(filters);

    // payment link filters
    const status = filterParams?.get("status:eq") ?? "";
    const postcode = filterParams?.get("postcode:like") ?? "";
    const email = filterParams?.get("email:like") ?? "";
    const orderRef = filterParams?.get("orderRef:like") ?? "";
    const jobDate = filterParams?.get("jobDate") ?? "";
    const createdDate = filterParams?.get("createdDate") ?? "";
    const [jobDateStart, jobDateEnd] = jobDate.split(" - ");
    const [createdDateStart, createdDateEnd] = createdDate.split(" - ");

    const { data } = await instance.catalogues.getPaymentLinks({
      params: {
        ...(!!status ? { "status:eq": status } : {}),
        ...(!!postcode
          ? { "postcode:like": `${postcode.replaceAll(" ", "")}%` }
          : {}),
        ...(!!email ? { "email:like": `${email.replace(" ", "")}%` } : {}),
        ...(!!orderRef ? { "orderRef:like": `${orderRef}%` } : {}),
        ...(!!jobDateStart
          ? {
              "date:gte": dayjs(dayjs(jobDateStart, "DD/MM/YYYY"))
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss"),
            }
          : {}),
        ...(!!jobDateEnd
          ? {
              "date:lte": dayjs(dayjs(jobDateEnd, "DD/MM/YYYY"))
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss"),
            }
          : {}),
        ...(!!createdDateStart
          ? {
              "createdOn:gte": dayjs(dayjs(createdDateStart, "DD/MM/YYYY"))
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss"),
            }
          : {}),
        ...(!!createdDateEnd
          ? {
              "createdOn:lte": dayjs(dayjs(createdDateEnd, "DD/MM/YYYY"))
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss"),
            }
          : {}),
        orderByDesc: "createdOn",
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<IPaymentLink>;

    setTotal(response?.pagination?.total || 0);

    return response;
  };

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResultsPerPage,
    query: {
      isFetching: isFetchingPayments,
      isLoading: isLoadingPayments,
      refetch: refetchPayments,
      isSuccess: isSuccessReadPayments,
    },
  } = usePaginatedQuery(
    readPayments,
    "readPaymentLinks",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "orderByDesc=createdOn",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * Resend payment link mutation
   */
  const { mutate: resendPaymentLink, isLoading: isLoadingResendPaymentLink } =
    useMutation(
      async ({ token, email }: { token: string; email?: string }) =>
        await instance.catalogues.putPaymentLink(token, {
          clientOriginUrl: process.env.REACT_APP_WEBSITE_URL as string,
          ...(!!email ? { email } : {}),
        }),
      {
        onError: (error, data) => {
          handleException(
            error,
            {
              data,
            },
            {
              type: "Update Payment Link",
              action: "Resend payment link",
            },
            "",
            false,
          );
          toasts.error({ message: getErrorMessage(error) });
        },
        onSuccess: () =>
          toasts.success({ message: translate("paymentLink.success.resend") }),
        onMutate: ({ token }) => setActiveBasketToken(token),
        onSettled: () => {
          setActiveBasketToken("");
          setActiveResendLink(null);
        },
      },
    );

  /**
   *  Cancel payment link mutation
   */
  const { mutate: cancelPaymentLink, isLoading: isLoadingCancelPaymentLink } =
    useMutation(
      async (token: string) =>
        await instance.catalogues.patchPaymentLink(token, {
          status: "cancelled",
        }),
      {
        onError: (error, data) => {
          handleException(
            error,
            {
              data,
            },
            {
              type: "Update Payment Link",
              action: "Cancel payment link",
            },
            "",
            false,
          );
          toasts.error({ message: getErrorMessage(error) });
        },
        onSuccess: () => {
          refetchPayments();
          toasts.success({ message: translate("paymentLink.success.cancel") });
        },
        onMutate: (token) => setActiveBasketToken(token),
        onSettled: () => {
          setActiveBasketToken("");
          setActiveCancelLink(null);
        },
      },
    );

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}&orderByDesc=createdOn`,
    );
  }, [currentPage, resultsPerPage]);

  const isDisabled = isLoadingResendPaymentLink || isLoadingCancelPaymentLink;

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoadingPayments || isFetchingPayments) && <LoadingOverlay />}

      <Box className="max-w-screen-lg mx-auto py-2 relative">
        <PaymentLinksFilters
          isFetching={isLoadingPayments || isFetchingPayments}
          totalPaymentLinks={total}
          updateFilters={updateFilters}
          setCurrentPage={setCurrentPage}
          setResultsPerPage={setResultsPerPage}
        />
        <Box className="relative flex flex-1">
          {/* DISPLAY NO DATA MESSAGE */}
          {!isLoadingPayments &&
            !isFetchingPayments &&
            !paginatedData.length &&
            isSuccessReadPayments && (
              <Box className="w-full flex justify-center mt-8">
                <Heading level={4}>
                  {translate("paymentLink.errors.noDataMessage")}
                </Heading>
              </Box>
            )}
          {!!paginatedData.length && (
            <Box>
              <Table
                className="mt-10"
                styles={{
                  tableLayout: "fixed",
                  "th:last-of-type > p": {
                    textAlign: "center",
                  },
                }}
              >
                {!isMobile && (
                  <>
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "23%" }} />
                      <col style={{ width: "11%" }} />
                      <col style={{ width: "11%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "15%" }} />
                    </colgroup>
                    <Table.Header
                      className="text-center"
                      fontSize={isDesktop ? "xsmall" : "xsmall2"}
                      headings={[
                        translate("paymentLink.table.headings.status"),
                        translate("paymentLink.table.headings.email"),
                        translate("paymentLink.table.headings.jobDate"),
                        translate("paymentLink.table.headings.createdDate"),
                        translate("paymentLink.table.headings.postcode"),
                        translate("paymentLink.table.headings.orderRef"),
                        translate("paymentLink.table.headings.actions"),
                      ]}
                    />
                  </>
                )}
                <Table.Body>
                  {paginatedData?.map((item) => {
                    const isRowLoading = activeBasketToken === item.basketToken;

                    return (
                      <Table.Row
                        key={`table-row-${item.id}`}
                        className={`cursor-pointer ${
                          isMobile
                            ? "flex flex-col justify-center items-center w-full"
                            : ""
                        }`}
                        onClick={() =>
                          history.push(
                            `/jobs/payment-links/update/${item.basketToken}`,
                          )
                        }
                      >
                        {!isMobile && (
                          <Table.Cell.Pill color={statusColors[item.status]}>
                            {item.status}
                          </Table.Cell.Pill>
                        )}
                        <Table.Cell.Text className="truncate">
                          {isMobile && (
                            <span className="font-semibold">
                              {translate("paymentLink.table.cells.email")}
                            </span>
                          )}
                          {item.email}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {isMobile && (
                            <span className="font-semibold">
                              {translate("paymentLink.table.cells.jobDate")}
                            </span>
                          )}
                          {dayjs(item.date).format("DD/MM/YYYY")}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {isMobile && (
                            <span className="font-semibold">
                              {translate("paymentLink.table.cells.createdDate")}
                            </span>
                          )}
                          {dayjs(item.createdOn).format("DD/MM/YYYY")}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {isMobile && (
                            <span className="font-semibold">
                              {translate("paymentLink.table.cells.postcode")}
                            </span>
                          )}
                          {item.postcode}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {isMobile && (
                            <span className="font-semibold">
                              {translate("paymentLink.table.cells.orderRef")}
                            </span>
                          )}
                          {item.orderRef}
                        </Table.Cell.Text>
                        {isMobile && (
                          <Table.Cell.Pill color={statusColors[item.status]}>
                            {item.status}
                          </Table.Cell.Pill>
                        )}
                        <Table.Cell>
                          {item.status === "pending" && (
                            <>
                              {isRowLoading && (
                                <Icon.Loading
                                  color="positive"
                                  className="mx-auto"
                                />
                              )}
                              {!isRowLoading && (
                                <Box className="flex flex-row items-center gap-x-1">
                                  <Button
                                    size="xsmall"
                                    color="brand"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setActiveResendLink(item);
                                    }}
                                    disabled={isDisabled}
                                  >
                                    {translate("paymentLink.buttons.resend")}
                                  </Button>
                                  <Button
                                    size="xsmall"
                                    color="negative"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setActiveCancelLink(item);
                                    }}
                                    disabled={isDisabled}
                                  >
                                    {translate("paymentLink.buttons.cancel")}
                                  </Button>
                                </Box>
                              )}
                            </>
                          )}
                          {item.status === "expired" && (
                            <>
                              {isRowLoading && (
                                <Icon.Loading
                                  color="positive"
                                  className="mx-auto"
                                />
                              )}
                              {!isRowLoading && (
                                <Button
                                  size="xsmall"
                                  color="warning"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveBookAgainLink(item);
                                  }}
                                  disabled={isDisabled}
                                >
                                  {translate("paymentLink.buttons.bookAgain")}
                                </Button>
                              )}
                            </>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {!!paginatedData.length && (
                <Box className="mt-6">
                  <PaginationComponent />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* RESEND PAYMENT LINK MODAL */}
      <ResendPaymentLinkModal
        resendPaymentLink={activeResendLink}
        setResendPaymentLink={setActiveResendLink}
        onResendPaymentLink={resendPaymentLink}
        isLoading={isLoadingResendPaymentLink}
      />

      {/* CANCEL PAYMENT LINK MODAL */}
      <CancelPaymentLinkModal
        cancelPaymentLink={activeCancelLink}
        setCancelPaymentLink={setActiveCancelLink}
        onCancelPaymentLink={cancelPaymentLink}
        isLoading={isLoadingCancelPaymentLink}
      />

      {/* BOOK AGAIN PAYMENT LINK MODAL */}
      <BookAgainPaymentLinkModal
        activeBookAgainLink={activeBookAgainLink}
        setActiveBookAgainLink={setActiveBookAgainLink}
      />
    </>
  );
};
