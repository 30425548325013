import React from "react";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { ICoupon } from "@clearabee/api-schemas";
import { instance, parseError } from "@clearabee/ui-sdk";
import {
  Form,
  Field,
  Box,
  Heading,
  Input,
  Text,
  theme,
  formatCurrency,
  UnstyledButton,
  Modal,
  Button,
} from "@clearabee/ui-library";
import { toasts } from "helpers";
import { LoadingOverlay } from "components/common/components";
import { EditFormValues, editValidationSchema } from "../../validation";
import { styles } from "./editModal.styles";
import clipboardCopy from "clipboard-copy";

dayjs.extend(utc);

interface EditModalProps {
  setVisibility: (values: undefined) => void;
  selectedCoupon?: ICoupon;
  refetchCoupons: () => void;
  onClose: () => void;
}

export const EditModal = ({
  setVisibility,
  selectedCoupon,
  refetchCoupons,
  onClose,
}: EditModalProps): React.ReactElement => {
  const { t } = useTranslation("coupons");

  const { isLoading: editCouponIsLoading, mutate: editCoupon } = useMutation(
    "editCoupon",
    async (body: EditFormValues) => {
      return await instance.catalogues.patchCoupon(
        String(selectedCoupon?.id),
        body,
      );
    },
    {
      onSuccess: () => {
        setVisibility(undefined);
        refetchCoupons();
        toasts.success({ message: t("success.updated") });
      },
      onError: (error) => {
        setVisibility(undefined);
        const errorMessage = parseError(error, t("errors.generic"));
        toasts.error({ message: errorMessage });
      },
    },
  );

  const handleSubmit = (values: EditFormValues) => {
    editCoupon(values);
  };

  const arrayToCsv = (array: string[]) => {
    return array.join(", ");
  };

  return (
    <>
      {!!selectedCoupon && (
        <Modal width={600} styles={styles.editModalStyles}>
          <Box styles={styles.editContainer}>
            {editCouponIsLoading && (
              <LoadingOverlay
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}
            <Box className="flex flex-col gap-6">
              <Form
                initialValues={{ active: selectedCoupon.active }}
                validationSchema={editValidationSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
                className="flex flex-col justify-start"
              >
                {({ submitForm, setFieldValue }) => (
                  <Box className="flex flex-row bg-gray-200 px-10 py-4 rounded-t-xl justify-between items-center">
                    <Heading color="brand" level={4}>
                      {t("editModal.active")}
                    </Heading>
                    <Field name="active">
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          defaultChecked={selectedCoupon.active}
                          onChange={(event) => {
                            setFieldValue("active", event.target.checked);
                            submitForm();
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                )}
              </Form>
              <Box className="grid grid-cols-2 px-10 gap-5">
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.name")}</Text>
                  <Text>{selectedCoupon.name}</Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.code")}</Text>

                  <Box className="flex">
                    <UnstyledButton
                      onClick={async () => {
                        await clipboardCopy(selectedCoupon.code);
                        toasts.info({
                          message: "Copied to clipboard",
                        });
                      }}
                    >
                      <Box
                        className="px-3 py-1 font-semibold rounded-md"
                        backgroundColor="greyscale.lightest"
                      >
                        <Text>{selectedCoupon.code}</Text>
                      </Box>
                    </UnstyledButton>
                  </Box>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.active")}</Text>
                  <Box
                    color={selectedCoupon.active ? "positive.dark" : "negative"}
                  >
                    <Text className="font-bold">
                      {selectedCoupon.active ? "Yes" : "No"}
                    </Text>
                  </Box>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.expiryDate")}</Text>
                  <Text>
                    {selectedCoupon.expiryDate
                      ? dayjs
                          .utc(selectedCoupon.expiryDate)
                          .format("DD/MM/YYYY")
                      : "None"}
                  </Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.percentOff")}</Text>
                  <Text>
                    {!!selectedCoupon.percentOff
                      ? `${selectedCoupon.percentOff}%`
                      : "N/A"}
                  </Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">{t("table.amountOff")}</Text>
                  <Text>{formatCurrency(selectedCoupon.amountOff || 0)}</Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("table.minimumAmount")}
                  </Text>
                  <Text>
                    {formatCurrency(selectedCoupon.minimumAmount || 0)}
                  </Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("editModal.firstTimeOrderOnly")}
                  </Text>
                  <Text>
                    {selectedCoupon.firstTimeOrderOnly ? "Yes" : "No"}
                  </Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("editModal.applicableEmails")}
                    {!!selectedCoupon.applicableEmails &&
                      ` (${
                        arrayToCsv(selectedCoupon.applicableEmails)
                          .split(",")
                          .filter(Boolean).length
                      })`}
                  </Text>
                  <Box className="max-h-32 pl-2 bg-gray-200 overflow-y-scroll py-2 rounded-md">
                    <Text>
                      {!!selectedCoupon.applicableEmails
                        ? arrayToCsv(selectedCoupon.applicableEmails)
                        : t("editModal.none")}
                    </Text>
                  </Box>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("editModal.applicableProducts")}
                  </Text>
                  <Text>
                    {!!selectedCoupon.applicableProducts
                      ? arrayToCsv(selectedCoupon.applicableProducts)
                      : t("editModal.none")}
                  </Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("editModal.maximumRedemptions")}
                  </Text>
                  <Text>{selectedCoupon.maxRedemptions}</Text>
                </Box>
                <Box className="flex flex-col">
                  <Text className="font-semibold">
                    {t("editModal.timesRedeemed")}
                  </Text>
                  <Text>{selectedCoupon.timesRedeemed}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            size="small"
            color="negative"
            className="mt-8"
            onClick={onClose}
          >
            Close
          </Button>
        </Modal>
      )}
    </>
  );
};
