"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coupons = void 0;
exports.coupons = {
    heading: "Coupons",
    noneFound: "No coupons found",
    errors: {
        generic: "Something went wrong. Please try again.",
    },
    success: {
        created: "Coupon created successfully",
        updated: "Coupon updated successfully",
    },
    form: {
        name: "Name",
    },
    buttons: {
        add: "Add",
        reset: "Reset",
        search: "Search",
        view: "View",
        submit: "Submit",
    },
    table: {
        name: "Name",
        code: "Code",
        active: "Active",
        expiryDate: "Expiry Date",
        percentOff: "Percent Off",
        amountOff: "Amount Off",
        minimumAmount: "Minimum Amount",
        moreDetails: "More Details",
    },
    createModal: {
        heading: "Create Coupon",
        form: {
            name: "Name",
            code: "Code",
            amountOff: "Amount Off",
            percentOff: "Percent Off",
            maxRedemptions: "Max Redemptions",
            minimumAmount: "Minimum Amount",
            firstTimeOrderOnly: "First Order Only",
            active: "Active",
            expiryDate: "Expiry Date",
            applicableEmails: "Applicable Emails (CSV)",
            applicableProducts: "Applicable Products (CSV)",
        },
    },
    editModal: {
        active: "Active",
        firstTimeOrderOnly: "First Order Only",
        createdOn: "Created On",
        applicableEmails: "Applicable Emails",
        applicableProducts: "Applicable Products",
        none: "None",
        maximumRedemptions: "Maximum Redemptions",
        timesRedeemed: "Times Redeemed",
    },
};
