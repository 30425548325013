"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.partners = void 0;
exports.partners = {
    sharp: {
        steps: {
            orderInfo: {
                text1: "Lets book your {{partnerName}} collection",
                text2: "Enter your account number and postcode to begin.",
            },
            customerInfo: {
                text1: "Customer details",
            },
            collectionInfo: {
                text1: "Collection details",
                text2: "Is the waste accessible without a member of the household being present?",
                text3: "Your waste will be collected by:",
                text4: "between 8am and 8pm",
                text5: "You will need to select a convenient collection date during the next step.",
            },
            collectionDate: {
                text1: "Collection date",
            },
            success: {
                text1: "Booking successful!",
                text2: "Your order number is ",
                text3: ", please store it for future reference.",
                text4: "You will soon receive a confirmation email regarding your collection.",
            },
        },
        labels: {
            city: "Collection city",
            country: "Collection county",
            postcode: "Collection postcode",
            addressLine1: "Collection address 1",
            addressLine2: "Collection address 2",
            addressDropdown: "Collection address",
            sharpsAccountNumber: "{{partnerName}} Account Number",
            customerName: "Customer name",
            customerEmail: "Customer email address",
            customerPhone: "Customer phone number",
        },
        placeholders: {
            city: "Enter Collection city",
            country: "Enter collection county",
            postcode: "Enter collection postcode",
            addressLine1: "Enter collection address 1",
            addressLine2: "Enter collection address 2",
            addressDropdown: "Select collection address",
            customerName: "Enter customer name",
            customerEmail: "Enter customer email address",
            customerPhone: "Enter customer phone number",
            sharpsAccountNumber: "e.g. 1W2345",
        },
        buttons: {
            findAddress: "Find address",
            tryAgain: "Try Again",
            continue: "Continue",
            finish: "Finish",
            back: "Back",
        },
        toggle: {
            postcodeAddress: "Use postcode lookup?",
            manualAddress: "Enter address manually?",
        },
        errors: {
            fetch: "Error fetching data, please try again.",
            failedForm: {
                error: "Oh no, there was an issue.",
                hasOrderFailed: "We could not complete your booking at this time.",
                noSharpsAccountNumber: "We cannot find your {{partnerName}} Account Number in our records, or that Account Number has already been used to book a collection.",
                noBasket: "There was a problem connecting to our booking system.",
                paymentError: "There was a problem with processing your payment.",
            },
        },
        validation: {
            fieldRequired: "This is a required field",
            validEmail: "Invalid email address",
            validPhone: "Invalid phone number",
        },
    },
    orderNumber: {
        steps: {
            orderInfo: {
                text1: "Lets book your {{partnerName}} collection",
                text2: "Enter your order number and postcode to begin.",
            },
            customerInfo: {
                text1: "Customer details",
            },
            collectionInfo: {
                text1: "Collection details",
                text2: "Is the waste accessible without a member of the household being present?",
                text3: "Your waste will be collected by:",
                text4: "between 8am and 8pm",
                text5: "You will need to select a convenient collection date during the next step.",
            },
            collectionDate: {
                text1: "Collection date",
            },
            success: {
                text1: "Booking successful!",
                text2: "Your order number is ",
                text3: ", please store it for future reference.",
                text4: "You will soon receive a confirmation email regarding your collection.",
            },
        },
        labels: {
            city: "Collection city",
            country: "Collection county",
            postcode: "Collection postcode",
            addressLine1: "Collection address 1",
            addressLine2: "Collection address 2",
            addressDropdown: "Collection address",
            accountNumber: "{{partnerName}} Order Number",
            orderNumber: "{{partnerName}} Order Number",
            customerName: "Customer name",
            customerEmail: "Customer email address",
            customerPhone: "Customer phone number",
        },
        placeholders: {
            city: "Enter Collection city",
            country: "Enter collection county",
            postcode: "Enter collection postcode",
            addressLine1: "Enter collection address 1",
            addressLine2: "Enter collection address 2",
            addressDropdown: "Select collection address",
            customerName: "Enter customer name",
            customerEmail: "Enter customer email address",
            customerPhone: "Enter customer phone number",
            accountNumber: "e.g. 1W2345",
        },
        buttons: {
            findAddress: "Find address",
            tryAgain: "Try Again",
            continue: "Continue",
            finish: "Finish",
            back: "Back",
        },
        toggle: {
            postcodeAddress: "Use postcode lookup?",
            manualAddress: "Enter address manually?",
        },
        errors: {
            fetch: "Error fetching data, please try again.",
            failedForm: {
                error: "Oh no, there was an issue.",
                hasNoProducts: "We were unable to find prices for the products in your order",
                hasOrderFailed: "We could not complete your booking at this time.",
                noAccountNumber: "We cannot find your {{partnerName}} Order Number in our records, or that Order Number has already been used to book a collection.",
                noBasket: "There was a problem connecting to our booking system.",
                paymentError: "There was a problem with processing your payment.",
            },
        },
        validation: {
            fieldRequired: "This is a required field",
            validEmail: "Invalid email address",
            validPhone: "Invalid phone number",
        },
    },
};
