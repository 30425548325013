import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  Button,
  Icon,
  Box,
  Panel,
  theme,
  Message,
} from "@clearabee/ui-library";
import { FormInput } from "components/core";
import { useMultiFormContext } from "hooks";
import { getJobByOrderRef } from "../../../../api";
import { getErrorMessage } from "../../../../helpers";
import {
  findJobSchema as validationSchema,
  findJobInitialValues as initialValues,
} from "./../validation";
import { IFormState } from "../types";

export const OrderLookup = (): React.ReactElement => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");
  const { nextStep, pushState, formState } = useMultiFormContext<IFormState>();

  /**
   * Get job by ref and postcode.
   */
  const { mutate, isLoading, error } = useMutation(
    "getJobByOrderRef",
    async ({ ref, postcode }: { ref: string; postcode: string }) =>
      await getJobByOrderRef(encodeURIComponent(ref), postcode),
    {
      onSuccess: (data) => {
        pushState({
          ...formState,
          job: data,
        });
        nextStep();
      },
    },
  );

  return (
    <Box className="flex justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          mutate({ ref: values.reference, postcode: values.postcode })
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Panel
              shadow={false}
              className="min-w-screen-sm flex justify-center gap-x-5 mb-5"
            >
              <Box className="w-1/2">
                <label className="font-semibold">
                  {t("create.steps.orderLookup.form.labels.reference")}
                </label>
                <FormInput
                  name="reference"
                  type="reference"
                  placeholder={t(
                    "create.steps.orderLookup.form.placeholders.reference",
                  )}
                  floatingLabel={false}
                  required
                />
              </Box>
              <Box className="w-1/2">
                <label className="font-semibold">
                  {t("create.steps.orderLookup.form.labels.postcode")}
                </label>
                <FormInput
                  name="postcode"
                  type="postcode"
                  placeholder={t(
                    "create.steps.orderLookup.form.placeholders.postcode",
                  )}
                  floatingLabel={false}
                  required
                />
              </Box>
            </Panel>
            {!!error && (
              <Message type="error" background className="my-5">
                {getErrorMessage(error)}
              </Message>
            )}
            <Box className="flex justify-center">
              <Button
                color="accent"
                size="small"
                type="submit"
                variant="outline"
                disabled={isLoading}
                styles={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: theme.spacing.xsmall,
                  opacity: isLoading ? 0.5 : 1,
                  cursor: isLoading ? "not-allowed" : "pointer",
                  ":hover": {
                    background: theme.colors.accent.base,
                  },
                }}
              >
                {t("create.buttons.next")}
                {isLoading && <Icon.Loading size="small" />}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
