import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ICompany } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import {
  Heading,
  Button,
  Box,
  theme,
  Icon,
  Panel,
  Form,
  Field,
  Input,
  Message,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { inBoth, onlyInDatabase, onlyInBigchange } from "./helpers/helpers";
import { JobBlock } from "./components/JobBlock";
import dayjs from "dayjs";

const initialValues = {
  subcontractor: "",
  date: "",
};

const validationSchema = Yup.object().shape({
  subcontractor: Yup.string().required(),
  date: Yup.string().required(),
});

type FormValues = {
  subcontractor: string;
  date: string;
};

const tableHeadings = [
  "Allocated in Both",
  "Only Allocated in Our Database",
  "Only Allocated in BigChange",
];

export const CompareJobs = (): React.ReactElement => {
  const { t } = useTranslation("subcontractors");
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>();
  const [selectedSubcontractorCode, setSelectedSubcontractorCode] =
    useState<string>();

  const handleSubmit = async (values: FormValues) => {
    setFormValues(values);

    setSelectedSubcontractorCode(
      subcontractors?.data.items.find(
        (company: ICompany) =>
          company.resourceId === Number(values.subcontractor),
      )?.companyCode,
    );

    setErrors([]);

    setWarnings([]);
  };

  const { data: subcontractors, isLoading: subcontractorsIsLoading } = useQuery(
    ["getSubcontractors"],
    async () =>
      await axios.get("/companies", {
        params: {
          "resourceId:notNull": "true",
        },
      }),
    {
      onError: () => {
        setErrors((prevErrors) => [
          ...prevErrors,
          "Something went wrong when fetching subcontractors",
        ]);
      },
    },
  );

  const { isLoading: bigChangeJobsIsLoading, data: bigChangeJobs } = useQuery(
    ["getBigChangeJobs", formValues],
    async () =>
      await instance.jobs.getJobsFromBigChange("JobsList", {
        params: {
          Start: dayjs(formValues?.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          End: dayjs(formValues?.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          ResourceId: formValues?.subcontractor,
        },
      }),
    {
      retry: 1,
      cacheTime: 0,
      enabled: !!formValues,
      onSuccess: (data) => {
        if (data?.data.Code !== 0) {
          setErrors((prevErrors) => [
            ...prevErrors,
            "Something went wrong when fetching big change jobs",
          ]);
        }
        if (data?.data.Result === "No results") {
          setWarnings((prevWarnings) => [
            ...prevWarnings,
            "no jobs found in BigChange",
          ]);
        }
      },
    },
  );

  const { data: poolJobs, isLoading: poolJobsLoading } = useQuery(
    ["getPoolJobs", formValues],
    async () =>
      await instance.jobs.getPool({
        params: {
          limit: 1000,
          "job.date:gte": dayjs
            .utc(formValues?.date, "DD/MM/YYYY")
            .startOf("day")
            .toISOString(),
          "job.date:lte": dayjs
            .utc(formValues?.date, "DD/MM/YYYY")
            .endOf("day")
            .toISOString(),
          "companyCode:in": selectedSubcontractorCode,
        },
      }),

    {
      enabled: !!formValues,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data?.data?.items?.length === 0) {
          setWarnings((prevWarnings) => [
            ...prevWarnings,
            "no jobs found in our database",
          ]);
        }
      },
    },
  );

  return (
    <>
      {/* LOADING */}
      {(subcontractorsIsLoading ||
        bigChangeJobsIsLoading ||
        poolJobsLoading) && <LoadingOverlay />}

      <>
        <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <>
              <Panel shadow={false}>
                <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                  <Heading
                    level={5}
                    color="brand"
                    className="pr-4 w-full sm:w-auto"
                  >
                    Compare Subcontractor Jobs
                  </Heading>

                  <Box className="flex justify-end self-end col-start-3 gap-x-3">
                    {/* SUMBIT */}
                    <Button
                      size="small"
                      color="accent"
                      type="submit"
                      className="flex items-center gap-x-1"
                    >
                      <Icon.Search2 size="small" />
                      Search
                    </Button>
                  </Box>
                </Box>

                <Box className="flex flex-row gap-6">
                  {/* SUBCONTRACTOR */}
                  <Box className="w-1/3">
                    <Field
                      name="subcontractor"
                      label="Subcontractor"
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder="Subcontractor"
                          options={subcontractors?.data.items.map(
                            (company: ICompany) => ({
                              label: company.name,
                              value: company.resourceId,
                            }),
                          )}
                          isSearchable
                        />
                      )}
                    </Field>
                  </Box>

                  {/* DATE */}
                  <Box className="w-1/4">
                    <Field
                      name="date"
                      label="Date"
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Date {...field} placeholder="Date" collapsable />
                      )}
                    </Field>
                  </Box>
                </Box>

                {/* WARNINGS */}
                {warnings?.length > 0 && (
                  <Message className="mt-3" type="warn">
                    {warnings.join(` & `)}
                  </Message>
                )}

                {/* ERRORS */}
                {errors.length > 0 && (
                  <Message type="error" background>
                    {errors.join(` & `)}
                  </Message>
                )}
              </Panel>
            </>
          </Form>
        </Box>

        {!!bigChangeJobs && !!poolJobs && (
          <Panel shadow={false}>
            {/* Table Headings */}
            <Box className="flex flex-row mb-3">
              {tableHeadings.map((heading, index) => (
                <Box
                  key={index}
                  className="flex justify-center items-center w-1/3"
                >
                  <Heading color="brand" level={4}>
                    {heading}
                  </Heading>
                </Box>
              ))}
            </Box>

            {/* Divider */}
            <Box className="border-b mb-6" />

            <Box className="flex flex-row gap-3 mb-3">
              <Box className="flex flex-col gap-3 w-1/3">
                {inBoth(poolJobs.data.items, bigChangeJobs?.data.Result)?.map(
                  (job, index) => (
                    <JobBlock
                      key={`both-${index}`}
                      poolStatus={job.status}
                      postcode={job.postcode}
                      reference={job.job.ref}
                      jobStatus={job.job.status}
                      type="both"
                    />
                  ),
                )}
              </Box>
              <Box className="flex flex-col gap-3 w-1/3">
                {onlyInDatabase(
                  poolJobs?.data.items,
                  bigChangeJobs?.data.Result,
                )?.map((job, index) => (
                  <JobBlock
                    key={`db-${index}`}
                    poolStatus={job.status}
                    postcode={job.postcode}
                    reference={job.job.ref}
                    jobStatus={job.job.status}
                    type="only-db"
                  />
                ))}
              </Box>
              <Box className="flex flex-col gap-3 w-1/3">
                {onlyInBigchange(
                  poolJobs?.data.items,
                  bigChangeJobs?.data.Result,
                )?.map((job, index) => (
                  <JobBlock
                    key={`bc-${index}`}
                    jobStatus={job.Status}
                    postcode={job.Postcode}
                    reference={job.Ref}
                    type="only-bc"
                  />
                ))}
              </Box>
            </Box>
          </Panel>
        )}
      </>
    </>
  );
};
