import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as querystring from "querystring";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Table,
  theme,
  usePagination,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { SearchIcon } from "images";

const initialValues = {
  name: "",
};
const paramsInitialValues = {
  name: "",
  offset: 0,
  limit: 10,
};

export const ReadWorksheets = (): React.ReactElement => {
  const [data, setData] = useState<DataType["items"]>();
  const [params, setParams] = useState(paramsInitialValues);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const [translate] = useTranslation("worksheets");
  const query = new URLSearchParams(location.search.replace("?", ""));
  const nameQuery = query.get("name") ?? "";
  const [offsetQuery, setOffsetQuery] = useState(
    query.get("offset") ?? paramsInitialValues.offset,
  );
  const [limitQuery, setLimitQuery] = useState(
    query.get("limit") ?? paramsInitialValues.limit,
  );

  type DataType = ApiResponseData<typeof instance.worksheets.getWorksheets>;

  // READ
  const { isLoading, isFetching } = useQuery(
    ["readWorksheets", params, offsetQuery, limitQuery],
    async () => {
      const queryParams =
        !!nameQuery || !!params.name
          ? {
              offset: offsetQuery || params.offset,
              limit: limitQuery || params.limit,
              "name:like": `%${nameQuery || params.name}%`,
            }
          : {
              offset: params.offset,
              limit: params.limit,
            };

      return (
        await instance.worksheets.getWorksheets({
          params: queryParams,
        })
      ).data;
    },
    {
      onError: () => {
        toasts.error({
          message: translate("readWorksheets.getError"),
        });
      },
      onSuccess: (data) => {
        if (!!nameQuery || !!params.name) {
          setData(
            data?.items.filter(
              ({ name }) =>
                !!name.length &&
                name
                  .toLowerCase()
                  .includes((nameQuery || params.name).toLocaleLowerCase()),
            ),
          );
        } else {
          setData(data.items);
        }
        setTotalPages(
          Math.ceil(
            params.limit === 0
              ? data.pagination.total / paramsInitialValues.limit
              : data.pagination.total / params.limit,
          ),
        );
      },
      refetchOnMount: true,
    },
  );

  const resetFilters = () => {
    history.push(`${window.location.pathname}`);
    setParams(paramsInitialValues);
    setDataRange([paramsInitialValues.offset, paramsInitialValues.limit]);
    setPaginationState(initialPaginationState);
  };

  const {
    PaginationComponent,
    paginatedData,
    dataRange,
    setDataRange,
    paginationState,
    setPaginationState,
    initialPaginationState,
  } = usePagination(data ?? [], {
    totalPages: totalPages,
  });

  useEffect(() => {
    setParams({
      name: nameQuery || "",
      offset: Number(offsetQuery) || dataRange[0],
      limit: Number(limitQuery) || paginationState?.resultsPerPage,
    });
    setOffsetQuery(dataRange[0]);
    setLimitQuery(paginationState?.resultsPerPage);
    if (!!nameQuery) {
      history.push(
        `${window.location.pathname}?${querystring.stringify({
          name: nameQuery,
          offset: offsetQuery,
          limit: limitQuery,
        })}`,
      );
    }
  }, [paginationState]);

  return (
    <div className="max-w-screen-lg mx-auto py-10 relative">
      <Panel
        styles={{
          paddingTop: theme.spacing.medium,
          paddingBottom: theme.spacing.medium,
          display: "flex",
          flexDirection: "column",
        }}
        className="flex flex-col lg:flex-row gap-x-6 justify-between"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Heading fontSize="large" color="brand">
            {translate("headings.read")}
          </Heading>

          <div className="flex">
            <div>
              <Link to="/worksheets/arrange">
                <Button color="brand" type="button" size="small">
                  {translate("readWorksheets.arrangeWorksheets")}
                </Button>
              </Link>
            </div>

            <div className="ml-3">
              <Link to="/worksheets/create">
                <Button color="accent" type="button" size="small">
                  {translate("readWorksheets.add")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Form
          className="flex flex-row items-center border-t border-grey-200 mt-4 pt-5"
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            if (!values.name) {
              resetFilters();
              resetForm();
              return;
            }
            setDataRange([
              paramsInitialValues.offset,
              paramsInitialValues.limit,
            ]);

            setParams({
              ...params,
              name: values.name,
            });
            history.push(
              `${window.location.pathname}?${querystring.stringify({
                name: values?.name,
                offset: paramsInitialValues.offset,
                limit: params?.limit,
              })}`,
            );
            setPaginationState({ ...paginationState, currentPage: 1 });
          }}
        >
          {({ values, setFieldValue, resetForm }) => {
            useEffect(() => {
              if (values?.name === "") {
                setFieldValue("name", nameQuery);
              }
            }, []);

            return (
              <>
                <div className="flex gap-x-3  w-full flex-1">
                  <div className="w-full md:flex-1 ">
                    <Field
                      name="name"
                      className="flex-1 min-w-full"
                      label={translate("readWorksheets.headings.name")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "readWorksheets.headings.name",
                          )}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Button
                  className="md:ml-6 flex items-center relative top-3"
                  size="small"
                  color="accent"
                  type="submit"
                >
                  <SearchIcon className="mr-2" />
                  Search
                </Button>
                <Button
                  className="ml-3 relative top-3"
                  size="small"
                  color="negative"
                  type="reset"
                  onClick={() => {
                    resetFilters();
                    resetForm();
                  }}
                >
                  Reset
                </Button>
              </>
            );
          }}
        </Form>
      </Panel>
      <div className="relative flex flex-1">
        {isLoading || isFetching ? (
          <Icon.Loading
            color="brand"
            styles={{ margin: `${theme.spacing.xlarge} auto` }}
          />
        ) : (
          <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "35%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <Table.Header
              fontSize="small"
              headings={[
                translate("readWorksheets.headings.id"),
                translate("readWorksheets.headings.name"),
                translate("readWorksheets.headings.includeForSnsEvents"),
                translate("readWorksheets.headings.priority"),
                translate("table.headings.action"),
              ]}
            />
            <Table.Body>
              {paginatedData?.map((item) => (
                <Table.Row key={`table-row-${item.id}`}>
                  <Table.Cell.Text className="truncate">
                    {item.id}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.name}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.includeForSnsEvents ? "True" : "False"}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.priority}
                  </Table.Cell.Text>

                  <Table.Cell>
                    <Link to={`/worksheets/update/${String(item.id)}`}>
                      <Button
                        size="xsmall"
                        type="button"
                        className="inline-block text-center"
                      >
                        {translate("table.actions.edit")}
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      <div className="mt-10">
        <PaginationComponent />
      </div>
    </div>
  );
};
