import React from "react";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { ICouponPostBody } from "@clearabee/api-schemas";
import {
  Form,
  Field,
  Box,
  Heading,
  Input,
  Button,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { toasts, getErrorMessage } from "helpers";
import { LoadingOverlay } from "components/common/components";
import {
  CreateCouponFormValues,
  createCouponInitialValues,
  createCouponValidationSchema,
} from "../../validation";
import { styles } from "./createModal.styles";

dayjs.extend(utc);

interface CreateModalProps {
  setVisibility: (value: boolean) => void;
  refetch: () => void;
  isVisibile: boolean;
}

export const CreateModal = ({
  setVisibility,
  refetch,
  isVisibile,
}: CreateModalProps): React.ReactElement => {
  const { t } = useTranslation("coupons");

  const { isLoading: createCouponIsLoading, mutate: createCoupon } =
    useMutation(
      "createCoupon",
      async (
        body: Omit<ICouponPostBody, "expiryDate"> & {
          expiryDate: string | undefined;
        },
      ) => await instance.catalogues.postCoupon(body),
      {
        onSuccess: () => {
          setVisibility(false);
          refetch();
          toasts.success({ message: t("success.created") });
        },
        onError: (error) => {
          toasts.error({ message: getErrorMessage(error) });
        },
      },
    );

  const convertCsvToArray = (csv: string) => {
    return csv.split(",").map((value) => value.trim());
  };

  const handleSubmit = (values: CreateCouponFormValues) => {
    const body: Omit<ICouponPostBody, "expiryDate"> & {
      expiryDate: string | undefined;
    } = {
      name: values.name,
      code: values.code,
      ...(values.percentageDiscount
        ? { percentOff: Number(values.percentOff) }
        : { amountOff: Number(values.amountOff) }),
      maxRedemptions: Number(values.maxRedemptions),
      minimumAmount: Number(values.minimumAmount),
      applicableEmails: !!values.applicableEmails
        ? convertCsvToArray(values.applicableEmails)
        : null,
      applicableProducts: !!values.applicableProducts
        ? convertCsvToArray(values.applicableProducts)
        : null,
      firstTimeOrderOnly: values.firstTimeOrderOnly,
      active: values.active,
      expiryDate: values.expiryDate
        ? dayjs.utc(values.expiryDate, "DD/MM/YYYY").toISOString()
        : undefined,
    };

    createCoupon(body);
  };

  return (
    <Modal
      width={700}
      styles={styles.createModalStyles}
      modalVisible={isVisibile}
      onClose={() => setVisibility(false)}
    >
      <Form
        initialValues={createCouponInitialValues}
        validationSchema={createCouponValidationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values }) => (
          <Box styles={styles.container}>
            <Heading color="brand" level={2} className="text-center">
              {t("createModal.heading")}
            </Heading>

            {createCouponIsLoading && (
              <LoadingOverlay
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}

            <Box
              styles={styles.formContainer}
              className="flex flex-row flex-wrap text-left w-full"
            >
              <Box styles={styles.fieldContianerHalf}>
                <Field name="name" label={t("createModal.form.name")}>
                  {({ field }) => <Input.Text {...field} />}
                </Field>
              </Box>

              <Box styles={styles.fieldContianerHalf}>
                <Field name="code" label={t("createModal.form.code")}>
                  {({ field }) => <Input.Text {...field} />}
                </Field>
              </Box>

              <Box styles={styles.fieldContianerHalf}>
                <Field name="percentageDiscount" label="Percentage Discount">
                  {({ field }) => <Input.Toggle {...field} />}
                </Field>
              </Box>

              {!values.percentageDiscount && (
                <Box styles={styles.fieldContianerHalf}>
                  <Field
                    name="amountOff"
                    label={t("createModal.form.amountOff")}
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>
              )}

              {!!values.percentageDiscount && (
                <Box styles={styles.fieldContianerHalf}>
                  <Field
                    name="percentOff"
                    label={t("createModal.form.percentOff")}
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>
              )}

              <Box styles={styles.fieldContianerHalf}>
                <Field
                  name="maxRedemptions"
                  label={t("createModal.form.maxRedemptions")}
                >
                  {({ field }) => <Input.Text {...field} />}
                </Field>
              </Box>

              <Box styles={styles.fieldContianerHalf}>
                <Field
                  name="minimumAmount"
                  label={t("createModal.form.minimumAmount")}
                >
                  {({ field }) => <Input.Text {...field} />}
                </Field>
              </Box>

              <Box className="flex flex-row w-full justify-between">
                <Box styles={styles.fieldContianerFull}>
                  <Field
                    name="expiryDate"
                    label={t("createModal.form.expiryDate")}
                  >
                    {({ field }) => (
                      <Input.Date
                        {...field}
                        collapsable={true}
                        disabledDays={(day) =>
                          dayjs(day).isBefore(dayjs(), "date")
                        }
                      />
                    )}
                  </Field>
                </Box>
              </Box>

              <Box styles={styles.fieldContianerFull}>
                <Field
                  name="applicableEmails"
                  label={`${t("createModal.form.applicableEmails")} ${
                    !!values.applicableEmails
                      ? `(${
                          values.applicableEmails.split(",").filter(Boolean)
                            .length
                        })`
                      : ""
                  }`}
                >
                  {({ field }) => <Input.Textarea {...field} slim />}
                </Field>
              </Box>

              <Box styles={styles.fieldContianerFull}>
                <Field
                  name="applicableProducts"
                  label={t("createModal.form.applicableProducts")}
                >
                  {({ field }) => <Input.Textarea {...field} slim />}
                </Field>
              </Box>

              <Box className="px-3 w-full mb-6">
                <Box
                  className="bg-gray-200 rounded-md"
                  styles={styles.toggleContainer}
                >
                  <Box className="w-3/12 px-3">
                    <Field
                      name="firstTimeOrderOnly"
                      label={t("createModal.form.firstTimeOrderOnly")}
                    >
                      {({ field }) => <Input.Toggle {...field} />}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="active" label={t("createModal.form.active")}>
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          defaultChecked={createCouponInitialValues.active}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
              </Box>

              <Box className="flex justify-center w-full">
                <Box>
                  <Button size="medium" type="submit">
                    {t("buttons.submit")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Form>
    </Modal>
  );
};
