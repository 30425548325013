import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { FormikProps } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { instance, parseError } from "@clearabee/ui-sdk";
import { IJob, IJobInvoiceBulkPdf } from "@clearabee/api-schemas";
import { IPaginatedResults, TFilters } from "api/types";
import { toasts } from "helpers";
import { buildQuery } from "helpers/api";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import {
  Heading,
  Button,
  Box,
  Modal,
  theme,
  Panel,
  Form,
  Text,
  Input,
  Table,
  Field,
  UnstyledButton,
  formatPostcode,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import {
  validationSchema,
  initialValues,
  JobInvoiceFormValues,
  emailFormValidationSchema,
  emailFormInitialValues,
  EmailFormValues,
} from "./validation";
import { styles } from "./jobInvoices.styles";

dayjs.extend(utc);

const initialResultsPerPage = 10;

export const JobInvoices = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search.replace("?", ""));
  const { t } = useTranslation("invoices");
  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const searchFormRef = useRef<FormikProps<JobInvoiceFormValues>>(null);
  const emailFormRef = useRef<FormikProps<EmailFormValues>>(null);

  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);

  const { isLoading: requestInvoiceEmailLoading, mutate: sendEmails } =
    useMutation(
      "requestInvoiceEmail",
      async (body: IJobInvoiceBulkPdf) => {
        return await instance.jobs.postJobInvoiceBulkPdf(body);
      },
      {
        onSuccess: () => {
          setShowModal(true);
        },
        onError: (error) => {
          toasts.error({ message: parseError(error, t("errors.generic")) });
        },
      },
    );

  const readJobs = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ) => {
    const filterParams = new URLSearchParams(filters);

    //filters
    const reference = filterParams.get("reference:like");
    const purchaseOrderNumber = filterParams.get("purchaseOrder:like");
    const startDate = filterParams.get("date:gte");
    const endDate = filterParams.get("date:lt");

    const { data } = await instance.jobs.getJobs({
      params: {
        ...(!!reference && { "ref:like": `${reference}%` }),
        ...(purchaseOrderNumber && {
          "purchaseOrder:like": `${purchaseOrderNumber}%`,
        }),
        ...(!!startDate && { "date:gte": startDate }),
        ...(!!endDate && { "date:lt": endDate }),
        "status:in": "Started,Completed",
        orderByDesc: "date",
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data;

    return response as unknown as IPaginatedResults<IJob>;
  };

  const {
    PaginationComponent,
    paginatedData,
    currentPage,
    setCurrentPage,
    updateFilters,
    resultsPerPage,
    setResultsPerPage,
    query: { isFetching, isLoading, isSuccess },
  } = usePaginatedQuery(
    readJobs,
    "readVehiclesPaginated",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
  );

  const handleSubmit = (values: typeof initialValues) => {
    const { reference, date, purchaseOrderNumber } = values;
    const updatedFilterObject = {
      ...(!!reference ? { "reference:like": `${reference}` } : {}),
      ...(!!purchaseOrderNumber
        ? { "purchaseOrder:like": `${purchaseOrderNumber}` }
        : {}),
      ...(!!date ? { "date:gte": dateFormatter(date).start } : {}),
      ...(!!date ? { "date:lt": dateFormatter(date).end } : {}),
      "status:eq": "Completed",
      orderByDesc: "date",
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);

    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/jobs/invoices?${queryParam}`);
  };

  const handleEmailSubmit = (values: typeof emailFormInitialValues) => {
    const { email } = values;

    if (selectedEmails.includes(email)) {
      toasts.error({
        message: t("errors.emailAdded"),
      });
      return;
    }

    emailFormRef.current?.resetForm();

    setSelectedEmails([...selectedEmails, email]);
  };

  const handleRequestInvoiceEmail = () => {
    const body = {
      emails: selectedEmails,
      jobRefs: selectedJobs,
    };

    sendEmails(body);
  };

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    const reference = params?.get("reference:like") ?? "";
    const purchaseOrderNumber = params?.get("purchaseOrder:like") ?? "";
    const dateStart = params?.get("date:gte") ?? "";
    const dateEnd = params?.get("date:lt") ?? "";

    const date =
      dateStart && dateEnd
        ? `${dayjs.utc(dateStart).format("DD/MM/YYYY")} - ${dayjs
            .utc(dateEnd)
            .format("DD/MM/YYYY")}`
        : "";

    return {
      reference,
      purchaseOrderNumber,
      date,
    };
  };

  const dateFormatter = (date: string) => {
    const dateArray = date.split(" - ");

    const dateObject = {
      start: "",
      end: "",
    };

    if (dateArray.length === 1) {
      dateObject.start = dayjs
        .utc(dateArray[0], "DD/MM/YYYY")
        .startOf("day")
        .toISOString();
      dateObject.end = dayjs
        .utc(dateArray[0], "DD/MM/YYYY")
        .endOf("day")
        .toISOString();
    }

    if (dateArray.length === 2) {
      dateObject.start = dayjs
        .utc(dateArray[0], "DD/MM/YYYY")
        .startOf("day")
        .toISOString();
      dateObject.end = dayjs
        .utc(dateArray[1], "DD/MM/YYYY")
        .endOf("day")
        .toISOString();
    }

    return dateObject;
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* LOADING */}
      {(isLoading || isFetching || requestInvoiceEmailLoading) && (
        <LoadingOverlay />
      )}

      <Panel shadow={false} className="mb-3">
        <Form
          validationSchema={emailFormValidationSchema}
          initialValues={emailFormInitialValues}
          onSubmit={(values) => handleEmailSubmit(values)}
          innerRef={emailFormRef}
        >
          {({ setFieldError }) => (
            <>
              <Box className="flex flex-row justify-between items-center border-b border-grey-20 pb-6 mb-3">
                <Heading level={2} fontSize="xlarge" color="brand">
                  {t("headings.request")}
                </Heading>

                <Box>
                  <Button
                    color="warning"
                    type="button"
                    size="small"
                    onClick={() => {
                      if (selectedJobs.length === 0) {
                        toasts.error({
                          message: t("errors.addJobs"),
                        });
                        return;
                      }

                      if (selectedEmails.length === 0) {
                        toasts.error({
                          message: t("errors.addEmail"),
                        });
                        return;
                      }

                      if (
                        selectedJobs.length > 0 &&
                        selectedEmails.length > 0
                      ) {
                        handleRequestInvoiceEmail();
                        setFieldError("email", "");
                      }
                    }}
                  >
                    {t("buttons.request")}
                  </Button>
                </Box>
              </Box>

              <Box className="w-full">
                <Box className="flex flex-row justify-start items-start w-full gap-x-3">
                  {/* ADD EMAIL */}
                  <Box className="flex flex-row justify-center items-center w-1/3 gap-x-2">
                    {/* EMAIL INPUT */}
                    <Box className="w-full h-32 -mb-8">
                      <Field name="email" label={t("form.email")}>
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={t("form.enterEmail")}
                          />
                        )}
                      </Field>
                    </Box>

                    {/* ADD EMAIL BUTTON */}
                    <Box className="pt-6">
                      <Button size="xsmall" type="submit">
                        {t("buttons.add")}
                      </Button>
                    </Box>
                  </Box>

                  {/* SELECTED EMAILS */}
                  <Box className="flex flex-col my-4 w-1/3">
                    <Text styles={styles.jobsLabel}>
                      {t("form.selectedEmails")}
                    </Text>
                    <Box styles={styles.jobsContainer}>
                      {selectedEmails.length > 0 ? (
                        selectedEmails?.map((email, index) => (
                          <Box
                            key={`${email} - ${index}`}
                            className="flex flex-row justify-between items-center w-full"
                          >
                            <Text
                              styles={styles.emailContainer}
                              fontSize="small"
                            >
                              {email}
                            </Text>

                            <UnstyledButton
                              onClick={() => {
                                setSelectedEmails((prev) =>
                                  prev.filter((mail) => mail != email),
                                );
                                setFieldError("email", "");
                              }}
                            >
                              <Text
                                color="brand"
                                className="font-bold"
                                fontSize="small"
                              >
                                {t("buttons.remove")}
                              </Text>
                            </UnstyledButton>
                          </Box>
                        ))
                      ) : (
                        <Text
                          styles={{ color: theme.colors.greyscale.lighter }}
                        >
                          {t("noEmails")}
                        </Text>
                      )}
                    </Box>
                  </Box>

                  {/* SELECTED JOBS */}
                  <Box className="flex flex-col my-4 w-1/3">
                    <Text styles={styles.jobsLabel}>
                      {t("form.selectedJobs")}
                    </Text>
                    <Box styles={styles.jobsContainer}>
                      {selectedJobs.length > 0 ? (
                        selectedJobs?.map((job, index) => (
                          <Box
                            key={`${job} - ${index}`}
                            className="flex flex-row justify-between items-center w-full"
                          >
                            <Text fontSize="small">{job}</Text>

                            <UnstyledButton
                              onClick={() =>
                                setSelectedJobs((prev) =>
                                  prev.filter((ref) => ref != job),
                                )
                              }
                            >
                              <Text
                                color="brand"
                                className="font-bold"
                                fontSize="small"
                              >
                                {t("buttons.remove")}
                              </Text>
                            </UnstyledButton>
                          </Box>
                        ))
                      ) : (
                        <Text
                          styles={{ color: theme.colors.greyscale.lighter }}
                        >
                          {t("form.addJobs")}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Panel>

      <Panel styles={styles.pannel} className="mb-3" shadow={false}>
        <Form
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => handleSubmit(values)}
          innerRef={searchFormRef}
        >
          {({ resetForm }) => (
            <>
              <Box className="flex flex-row justify-start items-center w-full gap-x-3">
                <Box className="w-1/3">
                  <Field label={t("form.reference")} name="reference">
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={t("form.searchJobs")}
                      />
                    )}
                  </Field>
                </Box>

                {/* PURCHASE ORDER NUMBER INPUT */}
                <Box className="w-1/3">
                  <Field
                    name="purchaseOrderNumber"
                    label={t("form.purchaseOrder")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={t("form.purchaseOrder")}
                      />
                    )}
                  </Field>
                </Box>

                <Box className="w-1/3">
                  <Field label={t("form.date")} name="date">
                    {({ field }) => (
                      <Input.RangedDate
                        {...field}
                        collapsable
                        placeholder={t("form.date")}
                        initialValue={getInitialValues().date.split(" - ")}
                      />
                    )}
                  </Field>
                </Box>

                <Box className="flex gap-x-3 justify-end pt-5 items-center w-1/3">
                  <Box>
                    <Button
                      type="reset"
                      size="small"
                      color="negative"
                      onClick={() => {
                        resetForm();
                        updateFilters("");
                        setCurrentPage(1);
                        setResultsPerPage(initialResultsPerPage);
                        history.push(
                          `/jobs/invoices?page=1&resultsPerPage=${initialResultsPerPage}`,
                        );
                        searchFormRef.current?.resetForm();
                      }}
                    >
                      {t("buttons.reset")}
                    </Button>
                  </Box>

                  <Box>
                    <Button size="small" color="accent" type="submit">
                      {t("buttons.search")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Panel>

      <Panel styles={styles.pannel} shadow={false}>
        {/* RESULTS TABLE */}
        {!!paginatedData && paginatedData?.length > 0 && (
          <>
            <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
              </colgroup>

              <Table.Header
                fontSize="small"
                headings={[
                  t("table.reference"),
                  t("table.purchaseOrder"),
                  t("table.date"),
                  t("table.postcode"),
                  t("table.status"),
                  t("table.actions"),
                ]}
              />

              <Table.Body>
                {paginatedData?.map((vehicle) => (
                  <Table.Row key={`table-row-${vehicle.id}`}>
                    <Table.Cell.Text className="truncate">
                      {vehicle.ref}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {vehicle.purchaseOrder || t("table.notAvailable")}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {dayjs(vehicle.date).format("DD/MM/YYYY")}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {formatPostcode(vehicle.addressPostcode)}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {vehicle.status}
                    </Table.Cell.Text>

                    <Table.Cell>
                      <Button
                        disabled={selectedJobs.includes(vehicle.ref)}
                        onClick={() => {
                          setSelectedJobs([...selectedJobs, vehicle.ref]);
                        }}
                        size="small"
                      >
                        {t("buttons.add")}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Box className="mt-10 flex justify-center">
              <PaginationComponent />
            </Box>
          </>
        )}

        {/* NO RESULTS */}
        {!isLoading && !isFetching && !paginatedData?.length && isSuccess && (
          <Box className="flex justify-center my-12">
            <Heading color="brand" level={3}>
              {t("noJobs")}
            </Heading>
          </Box>
        )}
      </Panel>

      <Modal width={600} styles={styles.modalStyles} modalVisible={showModal}>
        <Box className="flex flex-col gap-y-5">
          <Heading color="brand" level={3}>
            {t("modal.success")}
          </Heading>

          <Text className="lg:px-12">{t("modal.message")}</Text>

          <Box>
            <Button
              size="small"
              onClick={() => {
                setShowModal(false);
                searchFormRef.current?.resetForm();
                emailFormRef.current?.resetForm();
                setSelectedEmails([]);
                setSelectedJobs([]);
              }}
            >
              {t("modal.okay")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
