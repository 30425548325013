import * as Yup from "yup";
import { stringRequired } from "validation/common";
import { translate } from "@clearabee/i18n";

export const initialValues = {
  groupName: "",
  globalInvoice: "",
  subcontractor: null,
  searchedRef: "",
  jobInvoice: "",
  jobs: [],
};

export const validationSchema = Yup.object().shape({
  groupName: Yup.string(),
  globalInvoice: Yup.string().when("jobs", {
    is: (value: typeof initialValues.jobs) =>
      value.some(({ invoice }) => !invoice),
    then: Yup.string().required(
      translate("portal.subcontractors.allocateJobs.errors.groupInvoice"),
    ),
  }),
  subcontractor: Yup.object()
    .shape({
      label: stringRequired,
      value: stringRequired,
    })
    .required(
      translate("portal.subcontractors.allocateJobs.errors.fieldRequired"),
    )
    .nullable(),
  searchedRef: Yup.string(),
  jobInvoice: Yup.string(),
  jobs: Yup.array().of(
    Yup.object().shape({
      invoice: Yup.string(),
    }),
  ),
});
