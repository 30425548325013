import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Panel, theme, FieldLabel } from "@clearabee/ui-library";
import { camelToCapital } from "@clearabee/ui-sdk";
import { ITip } from "@clearabee/api-schemas";

interface TipDetailsProps {
  tip: ITip;
}

export const TipDetails = ({ tip }: TipDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const keysToExclude = ["id", "timestamp"];

  return (
    <Panel className="my-12" shadow={false}>
      <Heading
        color="brand"
        level={5}
        styles={{ marginBottom: theme.spacing.small }}
      >
        {translate("headings.tipDetails")}
      </Heading>
      <Box className="flex flex-wrap">
        {Object.entries(tip)
          .filter(([key]) => !keysToExclude.includes(key))
          .map(([key, value]) => {
            return (
              <Box className="flex items-center justify-between py-2 w-1/4">
                <FieldLabel
                  label={camelToCapital(key)}
                  key={camelToCapital(key)}
                >
                  <Box styles={{ ...theme.fontDefaults.small }}>
                    {value?.toString()}
                  </Box>
                </FieldLabel>
              </Box>
            );
          })}
      </Box>
    </Panel>
  );
};
