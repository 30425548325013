import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Box, Button, theme } from "@clearabee/ui-library";
import { LoadingSpinner } from "../../../core";
import { useCatalogueContext, useMultiFormContext } from "../../../../hooks";
import { PortalServiceSelection } from "../../components";
import { selectServiceSchema as validationSchema } from "../validation";
import { IFormState } from "../types";

export const SelectService = (): React.ReactElement => {
  const [t] = useTranslation("jobs");

  const { nextStep, pushState, formState } = useMultiFormContext<IFormState>();

  const { job, service } = formState;

  /**
   * Get services items from catalogue.
   */
  const allowedServices = ["SKIPS", "SKIPBAGS", "SOFAREMOVAL", "MANVAN"];

  const {
    catalogueItems,
    isLoading,
    setPostcode: setCataloguePostcode,
  } = useCatalogueContext();
  const adhocCatalogue = catalogueItems?.filter((item) =>
    allowedServices.includes(item.sku),
  );

  /**
   * Set catalogue postcode
   */
  useEffect(() => {
    if (!job || !job.addressPostcode) {
      return;
    }

    setCataloguePostcode(job.addressPostcode);
  }, [job]);

  return (
    <>
      {/* Spinner */}
      {isLoading && (
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <LoadingSpinner
            classes={{
              circle: "text-primary opacity-100",
              bee: "text-primary",
              text: "text-primary opacity-100",
            }}
            isLoading={isLoading}
          />
        </Box>
      )}
      {!isLoading && (
        <Formik
          initialValues={{ service: service || "" }}
          validationSchema={validationSchema}
          onSubmit={({ service }) => {
            pushState({ ...formState, service, items: [] });
            nextStep();
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box className="flex flex-row flex-wrap justify-center mt-4 -mx-2">
                <PortalServiceSelection services={adhocCatalogue || []} />
              </Box>
              <Box className="mt-10 flex justify-around">
                <Button
                  color="accent"
                  size="small"
                  type="submit"
                  disabled={!values.service}
                  variant="outline"
                  styles={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: !values.service ? "not-allowed" : "pointer",
                    opacity: !values.service ? 0.5 : 1,
                    gap: theme.spacing.xsmall,
                    ":hover": {
                      background: theme.colors.accent.base,
                    },
                  }}
                >
                  {t("create.buttons.next")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
