import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";
import dayjs from "dayjs";
import { readCompanies } from "api";
import { instance } from "@clearabee/ui-sdk";
import { IBasket } from "@clearabee/api-schemas";
import {
  Box,
  Text,
  Panel,
  Input,
  Form,
  Button,
  Field,
  theme,
  Heading,
  Icon,
} from "@clearabee/ui-library";
import { JobDetailsModal } from "./components";
import { LoadingOverlay } from "../../common/components";
import { stringRequired } from "validation/common";

export const PendingBaskets = (): React.ReactElement => {
  const [t] = useTranslation("jobs");
  const [selectedJob, setSelectedJob] = useState<
    | (IBasket & {
        orderRef: string;
      })
    | null
  >(null);
  const [showExpiredJobs, setShowExpiredJobs] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ["readCompanies", "pendingBaskets"],
    async () => (await readCompanies("", 0, 5000)).items,
    {
      onSuccess: (data) => {
        if (data.length === 1) {
          mutate(data[0].companyCode);
          setSelectedCompany(data[0].companyCode);
        }
      },
      refetchOnMount: true,
    },
  );

  const {
    data: baskets,
    mutate,
    isLoading,
    isSuccess,
    reset,
  } = useMutation(
    async (code: string) =>
      (
        await instance.catalogues.getBasketsAwaitingApproval(code, {
          params: {
            eager: "[items]",
          },
        })
      ).data.items,
    {
      onSuccess: (data) => {
        if (!!selectedJob) {
          const job = data.find(
            ({ basketToken }) => basketToken === selectedJob.basketToken,
          );
          job && setSelectedJob(job);
        }
      },
    },
  );

  const filterJobs = (
    jobs: (IBasket & {
      orderRef: string;
    })[],
  ) => {
    if (showExpiredJobs) return jobs;
    return jobs.filter(({ expiryDate }) => dayjs(expiryDate).isAfter(dayjs()));
  };

  const initialValues = {
    company: companies?.length === 1 ? companies[0].companyCode : "",
  };

  const displayedJobs = useCallback(() => {
    return (
      !!baskets?.length &&
      filterJobs(baskets).map((job, index) => {
        const isExpired = dayjs(job.expiryDate).isBefore(dayjs());
        return (
          <Panel
            key={index}
            styles={{
              paddingTop: theme.spacing.small,
              paddingBottom: theme.spacing.small,
            }}
            className="flex justify-between items-center my-3"
          >
            <Box className="flex gap-12">
              {!!job.orderReference && (
                <Box>
                  <Heading color="brand" level={5}>
                    {t("form.label.reference")}
                  </Heading>
                  <Text> {job.orderReference}</Text>
                </Box>
              )}

              <Box>
                <Heading color="brand" level={5}>
                  {t("form.label.postcode")}
                </Heading>
                <Text>{job.deliveryAddress?.postcode}</Text>
              </Box>
              <Box>
                <Heading color="brand" level={5}>
                  {t("form.label.date")}
                </Heading>
                <Text>{dayjs(job.date).format("DD/MM/YYYY")}</Text>
              </Box>
            </Box>
            <Box className="flex items-center gap-5">
              {isExpired && (
                <Box
                  backgroundColor="negative"
                  color="light"
                  className="px-4 py-px opacity-60"
                  styles={{
                    borderRadius: theme.spacing.medium,
                  }}
                >
                  {t("buttons.labels.expired")}
                </Box>
              )}
              <Button size="small" onClick={() => setSelectedJob(job)}>
                {t("table.actions.view")}
              </Button>
            </Box>
          </Panel>
        );
      })
    );
  }, [showExpiredJobs, baskets]);

  const companiesOptions =
    !!companies && !!companies.length
      ? companies.map(({ name, companyCode }) => ({
          label: name,
          value: companyCode,
        }))
      : [];

  return (
    <>
      {isLoadingCompanies && <LoadingOverlay />}
      <Panel>
        <Form
          enableReinitialize
          onSubmit={(values) => {
            mutate(values.company);
            setSelectedCompany(values.company);
          }}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            company: stringRequired,
          })}
        >
          <Box className="flex justify-between items-center">
            <Field
              styles={{
                height: "80px",
                flexGrow: 1,
              }}
              name="company"
              label={t("form.label.company")}
            >
              {({ field }) => (
                <Input.Select
                  {...field}
                  disabled={!!initialValues.company}
                  defaultValue={initialValues.company}
                  options={companiesOptions}
                  isSearchable
                  isClearable
                />
              )}
            </Field>
            <Box className="-mb-2">
              <Button
                className="ml-5"
                size="small"
                color="accent"
                disabled={isLoading || !!initialValues.company}
              >
                {t("form.label.search")}
              </Button>
            </Box>
          </Box>
          <Box className="flex justify-end">
            <Input.Toggle
              label={t("form.label.showExpiredJobs")}
              onChange={(value) => {
                setShowExpiredJobs(value.target.checked);
              }}
            />
          </Box>
        </Form>
      </Panel>
      {isLoading && (
        <Box className="flex justify-center mt-10">
          <Icon.Loading size="xlarge3" />
        </Box>
      )}
      {displayedJobs()}
      {!isLoading && isSuccess && !filterJobs(baskets)?.length && (
        <Box className="flex justify-center mt-24">
          <Text>{t("messages.noJobsPendingApproval")}</Text>
        </Box>
      )}
      {!!selectedJob && (
        <JobDetailsModal
          onClose={() => setSelectedJob(null)}
          onSuccess={() => {
            reset();
            mutate(selectedCompany);
          }}
          jobData={selectedJob}
          visible={!!selectedJob}
        />
      )}
    </>
  );
};
