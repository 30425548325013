"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leaderboard = void 0;
exports.leaderboard = {
    headings: {
        dateRange: "Showing you data from the last ",
        totalOrders: "Total Orders",
        totalValue: "Total Order Value",
        totalPhonePayments: "Phone Payments",
        totalPhoneBookings: "Phone Bookings",
        paymentLinksPaid: "Paid Payment Links",
        paymentLinksUnpaid: "Unpaid Payment Links",
        websitePayments: "Website Payments",
        websiteOrders: "Website Orders",
        refresh: "Refresh",
    },
    strapline: {
        start: "We are the",
        middle: "U.K's largest Man & Van",
        end: "clearance company.",
    },
    noData: "No data...",
};
