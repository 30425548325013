import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, { OptionTypeBase } from "react-select";
import { useQuery, useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { IOrderTypeWorksheetIdPutBody } from "@clearabee/api-schemas";
import {
  Button,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Field,
  Table,
  theme,
  Modal,
  displayErrorMessage,
  Box,
  Message,
  Text,
  UnstyledButton,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import {
  getCatalogueOrderTypes,
  createOrderType,
} from "../../../api/catalogues";
import type { IOrderType } from "../../../api/catalogues";
import {
  initialValues,
  updateOrderTypeValidationSchema,
  createOrderTypeValidationSchema,
} from "./validation";

export const OrderTypeIDs = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");

  const [activeOrderType, setActiveOrderType] = useState<IOrderType | null>(
    null,
  );
  const [activeWorksheets, setActiveWorksheets] = useState<
    OptionTypeBase[] | null
  >(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [editing, setEditing] = useState(false);
  const [isEditingWorksheets, setIsEditingWorksheets] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Read order types
   */
  const {
    data: allOrderTypes,
    isLoading: isLoadingAllOrderTypes,
    refetch: refetchAllOrderTypes,
    isFetching: isFetchingAllOrderTypes,
    isError: isErrorAllOrderTypes,
  } = useQuery(
    "readOrderTypeIDs",
    async () => (await getCatalogueOrderTypes())?.items,
    {
      retry: false,
    },
  );

  /**
   * Create new order type
   */
  const {
    mutateAsync: createOrderTypeAsync,
    isError: isErrorCreateOrderType,
    isSuccess: isSuccessCreateOrderType,
    isLoading: isLoadingCreateOrderType,
    reset: resetCreateOrderType,
  } = useMutation(
    (values: Omit<IOrderType, "id" | "createdOn" | "allowPriceOverride">) => {
      const { resourceId, ...rest } = values;

      const updatedValues = {
        ...rest,
        ...(!!resourceId && Number(resourceId) !== 0
          ? {
              resourceId,
            }
          : {}),
      };

      return createOrderType(updatedValues);
    },
    {
      onSuccess: (data) => {
        setActiveOrderType(data);
        setEditing(true);
        refetchAllOrderTypes();
      },
    },
  );

  /**
   * Update order type
   */
  const {
    mutateAsync: updateOrderTypeAsync,
    isError: isErrorUpdateOrderType,
    isSuccess: isSuccessUpdateOrderType,
    isLoading: isLoadingUpdateOrderType,
    reset: resetUpdateOrderType,
  } = useMutation(
    (payload: Omit<IOrderType, "createdOn">) => {
      const { id, resourceId, ...patch } = payload;
      return instance.catalogues.patchOrderType(String(id), {
        ...patch,
        ...(!!resourceId && Number(resourceId) !== 0
          ? {
              resourceId: Number(resourceId),
            }
          : {}),
      });
    },
    {
      onSuccess: () => {
        refetchAllOrderTypes();
      },
    },
  );

  // Get all worksheets
  const {
    data: allWorksheets,
    isLoading: isLoadingAllWorksheets,
    isFetching: isFetchingAllWorksheets,
  } = useQuery(
    "getAllWorksheets",
    async () =>
      (
        await instance.worksheets.getWorksheets({
          params: {
            limit: 1000,
          },
        })
      ).data.items,
    {
      retry: false,
    },
  );

  /**
   * worksheet options
   */
  const worksheetOptions = useMemo(() => {
    if (!allWorksheets?.length) return [];

    return allWorksheets.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [allWorksheets]);

  /**
   * Get worksheets by order type id
   */
  const {
    isError: isErrorGettingWorksheets,
    isLoading: isLoadingWorksheets,
    refetch: refetchWorksheets,
    isRefetching: isRefetchingWorksheets,
  } = useQuery(
    ["worksheetsById", activeOrderType?.id, editing],
    async () => {
      if (!activeOrderType?.id) return [];

      return (
        await instance.worksheets.getWorksheetsByOrderTypeId(
          String(activeOrderType.id),
          {
            params: {
              returnAll: "true",
            },
          },
        )
      ).data;
    },
    {
      onSuccess: (data) =>
        setActiveWorksheets(
          data.map(({ id, name }) => ({ id: id || 0, name })),
        ),
      enabled: !!activeOrderType?.id && !!editing,
      retry: false,
    },
  );

  /**
   * Update worksheets by order type id
   */
  const {
    mutateAsync: updateWorksheetsAsync,
    isLoading: isLoadingUpdateWorksheets,
    isSuccess: isSuccessUpdateWorksheets,
    isError: isErrorUpdateWorksheets,
    reset: resetUpdateWorksheets,
  } = useMutation(
    ["updateWorksheets"],
    async (payload: { id: string; body: IOrderTypeWorksheetIdPutBody }) => {
      if (!payload.id) return;

      await instance.worksheets.putOrderTypeWorksheetIds(
        payload.id,
        payload.body,
      );
    },
    {
      onSuccess: () => {
        refetchWorksheets();
      },
    },
  );

  /**
   * handle form submit
   * @param values
   */
  const handleFormSubmit = async (values: typeof initialValues) => {
    if (isEditingWorksheets) {
      await updateWorksheetsAsync({
        id: String(activeOrderType?.id ?? ""),
        body: {
          worksheetIds: activeWorksheets?.map(({ id }) => id) ?? [],
        },
      });

      return;
    }

    if (editing) {
      await updateOrderTypeAsync({
        name: values.name,
        allowPriceOverride: values.allowPriceOverride,
        bcJobType: values.bcJobType,
        description: values.description,
        parentRef: values.parentRef,
        id: activeOrderType?.id,
        resourceId: values.resourceId,
        priority: values.priority || 0,
      });

      return;
    }

    await createOrderTypeAsync({
      name: values.name,
      bcJobType: values.bcJobType,
      description: values.description,
      parentRef: values.parentRef,
      resourceId: values.resourceId,
      priority: values.priority,
    });
  };

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoadingAllOrderTypes || isFetchingAllOrderTypes) && (
        <LoadingOverlay />
      )}

      <Box className="max-w-screen-lg mx-auto py-10 relative">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
          }}
        >
          <Box className="flex justify-between">
            <Heading fontSize="large" color="brand">
              {translate("orderType.headings.manageOrderTypes")}
            </Heading>
            <Button
              size="small"
              onClick={() => {
                setEditing(false);
                setIsModalOpen(true);
              }}
            >
              {translate("orderType.buttons.add")}
            </Button>
          </Box>
          <Box className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />
          <Form
            initialValues={{
              orderType: "",
            }}
            onSubmit={({ orderType }) => {
              setSearchFilter(orderType);
            }}
          >
            {({ resetForm }) => (
              <Box className="flex items-center justify-between mt-5 gap-x-16">
                <Field
                  styles={{
                    flex: 1,
                  }}
                  name="orderType"
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.form.placeholders.search",
                      )}
                    />
                  )}
                </Field>
                <Box className="flex items-center gap-x-2">
                  <Button
                    type="button"
                    size="small"
                    color="warning"
                    onClick={() => {
                      resetForm();
                      setSearchFilter("");
                    }}
                  >
                    {translate("orderType.buttons.reset")}
                  </Button>
                  <Button type="submit" size="small" color="accent">
                    {translate("orderType.buttons.search")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Panel>

        {/* TABLE */}
        {!isLoadingAllOrderTypes &&
          !isFetchingAllOrderTypes &&
          !!allOrderTypes &&
          !!allOrderTypes.length && (
            <Box className="relative flex flex-1">
              <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
                <colgroup>
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <Table.Header
                  headings={[
                    translate("orderType.table.headings.id"),
                    translate("orderType.table.headings.name"),
                    translate("orderType.table.headings.parentRef"),
                    translate("orderType.table.headings.bcJobType"),
                    translate("orderType.table.headings.resourceId"),
                    translate("orderType.table.headings.priceOverride"),
                    translate("orderType.table.headings.action"),
                  ]}
                />
                <Table.Body>
                  {allOrderTypes
                    .filter(({ name }) =>
                      !!searchFilter
                        ? name
                            .toLowerCase()
                            .includes(searchFilter.toLowerCase())
                        : true,
                    )
                    .map((item, index) => (
                      <Table.Row key={`table-row-${item.id}`}>
                        <Table.Cell.Text className="truncate">
                          {item.id}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {item.name}
                        </Table.Cell.Text>
                        <Table.Cell.Text>
                          {item.parentRef ?? ""}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {item.bcJobType ?? ""}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {item.resourceId ?? ""}
                        </Table.Cell.Text>
                        <Table.Cell>
                          {item.allowPriceOverride ? (
                            <Icon.Tick size="small" color="accent.darker" />
                          ) : (
                            <Icon.Close size="small" color="negative" />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            size="xsmall"
                            as="button"
                            className="inline-block text-center"
                            onClick={() => {
                              setIsModalOpen(true);
                              setEditing(true);

                              const foundOrderType = allOrderTypes.find(
                                ({ id }) => id === item.id,
                              );

                              if (!foundOrderType) return;

                              setActiveOrderType({
                                ...initialValues,
                                ...foundOrderType,
                                resourceId:
                                  foundOrderType.resourceId?.toString() ?? "",
                              });
                            }}
                          >
                            {translate("orderType.buttons.edit")}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Box>
          )}
      </Box>

      {/* DISPLAY SCREEN ERROR */}
      {!!isErrorAllOrderTypes && (
        <Box className="flex justify-center">
          {displayErrorMessage(
            translate("orderType.errors.getOrderTypesError"),
            ({ children }) => (
              <Message type="error" background>
                {children}
              </Message>
            ),
          )}
        </Box>
      )}

      {/* MODAL */}
      {isModalOpen && (
        <Modal
          width={700}
          styles={{
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge} ${theme.spacing.large}`,
            },
            height: "90%",
          }}
          onClose={() => {
            setIsModalOpen(false);
            setEditing(false);
            setIsEditingWorksheets(false);
            setActiveOrderType(null);
            setActiveWorksheets(null);
            resetCreateOrderType();
            resetUpdateOrderType();
            resetUpdateWorksheets();
          }}
        >
          <Form
            initialValues={{
              ...initialValues,
              name: activeOrderType?.name ?? "",
              parentRef: activeOrderType?.parentRef ?? "",
              allowPriceOverride: activeOrderType?.allowPriceOverride ?? false,
              bcJobType: activeOrderType?.bcJobType ?? "",
              resourceId: activeOrderType?.resourceId?.toString() ?? "",
              priority: activeOrderType?.priority ?? 0,
              description: {
                subcontractors:
                  activeOrderType?.description?.subcontractors ?? "",
                drivers: activeOrderType?.description?.drivers ?? "",
              },
            }}
            validationSchema={
              isEditingWorksheets
                ? updateOrderTypeValidationSchema
                : createOrderTypeValidationSchema
            }
            enableReinitialize
            onSubmit={handleFormSubmit}
          >
            {/* MODAL LOADING OVERLAY */}
            {(isLoadingUpdateWorksheets ||
              isLoadingCreateOrderType ||
              isLoadingUpdateOrderType) && (
              <LoadingOverlay
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}
            <Box className="flex items-center justify-between">
              {!editing && (
                <Heading fontSize="large" color="brand">
                  {translate("orderType.headings.createOrderType")}
                </Heading>
              )}
              {/* TABS SWITCHING */}
              {editing && (
                <Box className="w-full flex justify-center items-center rounded-md">
                  <UnstyledButton
                    styles={{
                      background: !isEditingWorksheets
                        ? theme.colors.accent.base
                        : theme.colors.greyscale.lightest,
                      padding: theme.spacing.small,
                      fontWeight: !isEditingWorksheets ? 900 : 400,
                      borderRightWidth: 10,
                      borderRightColor: theme.colors.greyscale.lighter,
                      borderTopLeftRadius: theme.spacing.xsmall,
                      borderBottomLeftRadius: theme.spacing.xsmall,
                    }}
                    onClick={() => {
                      setIsEditingWorksheets(false);
                      resetUpdateWorksheets();
                    }}
                  >
                    {translate("orderType.headings.editOrderType")}
                  </UnstyledButton>
                  <UnstyledButton
                    styles={{
                      padding: theme.spacing.small,
                      background: isEditingWorksheets
                        ? theme.colors.accent.base
                        : theme.colors.greyscale.lightest,
                      fontWeight: isEditingWorksheets ? 900 : 400,
                      borderTopRightRadius: theme.spacing.xsmall,
                      borderBottomRightRadius: theme.spacing.xsmall,
                    }}
                    onClick={() => {
                      setIsEditingWorksheets(true);
                      resetCreateOrderType();
                      resetUpdateOrderType();
                    }}
                  >
                    {translate("orderType.headings.updateWorksheets")}
                  </UnstyledButton>
                </Box>
              )}
            </Box>

            {/* DIVIDER */}
            <Box className="border-t border-gray-300 mt-5 mb-2 flex flex-col h-1" />

            {!isEditingWorksheets && (
              <Box className="grid grid-cols-2 gap-x-5 gap-y-1 text-left">
                {/* NAME */}
                <Field
                  styles={{ flex: 1 }}
                  name="name"
                  label={translate("orderType.form.labels.name")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.form.placeholders.name",
                      )}
                    />
                  )}
                </Field>
                {/* PARENT REFERENCE */}
                <Field
                  styles={{ flex: 1 }}
                  name="parentRef"
                  label={translate("orderType.form.labels.parentRef")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.form.placeholders.parentRef",
                      )}
                    />
                  )}
                </Field>
                {/* BC JOB TYPE */}
                <Field
                  styles={{ flex: 1 }}
                  name="bcJobType"
                  label={translate("orderType.form.labels.bcJobType")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.form.placeholders.bcJobType",
                      )}
                    />
                  )}
                </Field>
                {/* RESOURCE ID */}
                <Field
                  styles={{ flex: 1 }}
                  name="resourceId"
                  label={translate("orderType.form.labels.resourceId")}
                >
                  {({ field }) => (
                    <Input.Text
                      type="number"
                      min="0"
                      {...field}
                      placeholder={translate(
                        "orderType.form.placeholders.resourceId",
                      )}
                    />
                  )}
                </Field>
                {/* PRIORITY */}
                <Field
                  name="priority"
                  label={translate("orderType.form.labels.priority")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      type="number"
                      placeholder={translate(
                        "orderType.form.placeholders.priority",
                      )}
                    />
                  )}
                </Field>
                <Box className="col-span-full">
                  {/* SUBCONTRACTORS DESCRIPTION */}
                  <Field
                    name="description.subcontractors"
                    label={translate("orderType.form.labels.subDescription")}
                  >
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        rows={5}
                        placeholder={translate(
                          "orderType.form.placeholders.subDescription",
                        )}
                      />
                    )}
                  </Field>
                </Box>
                <Box className="col-span-full">
                  {/* DRIVERS DESCRIPTION */}
                  <Field
                    name="description.drivers"
                    label={translate(
                      "orderType.form.labels.driversDescription",
                    )}
                  >
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        rows={5}
                        placeholder={translate(
                          "orderType.form.placeholders.driversDescription",
                        )}
                      />
                    )}
                  </Field>
                </Box>
              </Box>
            )}

            {editing && isEditingWorksheets && (
              <Box className="col-span-full text-left mb-10">
                <Box className="mt-3 mb-5">
                  <Text className="font-semibold" fontSize="small">
                    {translate("orderType.headings.worksheets")}
                  </Text>
                  <Select
                    isSearchable
                    isClearable
                    value={null}
                    isLoading={
                      isLoadingAllWorksheets || isFetchingAllWorksheets
                    }
                    isDisabled={
                      isLoadingAllWorksheets || isFetchingAllWorksheets
                    }
                    options={worksheetOptions.filter(
                      ({ label }) =>
                        !activeWorksheets?.some(({ name }) => name === label),
                    )}
                    onChange={(option) => {
                      if (!option) return;

                      setActiveWorksheets([
                        ...(activeWorksheets ?? []),
                        { name: option.label, id: option.value },
                      ]);
                    }}
                  />
                </Box>

                <Box className="flex items-center gap-x-2 my-5">
                  <Text className="font-semibold" fontSize="small">
                    {`${translate("orderType.headings.associatedWorkshets")} (${
                      activeWorksheets?.length ?? 0
                    }) `}
                  </Text>
                  <Button
                    type="button"
                    onClick={() => {
                      resetUpdateWorksheets();
                      refetchWorksheets();
                    }}
                    size="xsmall"
                  >
                    {translate("orderType.buttons.reload")}
                  </Button>

                  {/* Loading */}
                  {(isRefetchingWorksheets || isLoadingWorksheets) && (
                    <Icon.Loading size="small" color="brand" />
                  )}
                </Box>

                {/* Display worksheets and its links */}
                {!!activeWorksheets &&
                  !!activeWorksheets.length &&
                  !isLoadingWorksheets &&
                  !isRefetchingWorksheets &&
                  !isErrorGettingWorksheets && (
                    <Box
                      styles={{
                        height: "500px",
                        overflowY: "scroll",
                      }}
                    >
                      {activeWorksheets.map(({ id, name }) => (
                        <Box
                          key={`worksheet-${id}`}
                          className="flex flex-row items-center justify-between bg-gray-200 mb-2 p-5 rounded-md"
                        >
                          <Link target="_blank" to={`/worksheets/update/${id}`}>
                            <Box className="flex gap-x-3 items-center mb-2">
                              <Icon.NewWindow size="small" color="brand" />
                              <Text>{name}</Text>
                            </Box>
                          </Link>
                          <Button
                            color="negative"
                            size="xsmall"
                            onClick={() => {
                              setActiveWorksheets((prev) =>
                                !!prev
                                  ? prev.filter((item) => item.id !== id)
                                  : [],
                              );
                            }}
                          >
                            {translate("orderType.buttons.remove")}
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  )}

                {!activeWorksheets?.length &&
                  !isLoadingWorksheets &&
                  !isLoadingWorksheets &&
                  !isRefetchingWorksheets &&
                  !isErrorGettingWorksheets && (
                    <Box className="mt-2">
                      {translate("orderType.errors.noWorksheets")}
                      <Link
                        target="_blank"
                        to={"/worksheets"}
                        className="inline-block pl-1"
                      >
                        <Text
                          color="brand"
                          styles={{
                            textDecoration: "underline",
                            textUnderlineOffset: theme.spacing.xsmall2,
                          }}
                        >
                          {translate("orderType.headings.viewWorksheets")}
                        </Text>
                      </Link>
                    </Box>
                  )}
              </Box>
            )}

            <Box className="flex flex-col items-center justify-center gap-y-1 mb-3">
              {/* DISPLAY MODAL SUCCESS */}
              {!!isSuccessUpdateWorksheets &&
                displayErrorMessage(
                  translate("orderType.success.patchWorksheetsSuccess"),
                  ({ children }) => (
                    <Message type="success">{children}</Message>
                  ),
                )}

              {!!isSuccessCreateOrderType &&
                displayErrorMessage(
                  translate("orderType.success.postOrderTypeSuccess"),
                  ({ children }) => (
                    <Message type="success">{children}</Message>
                  ),
                )}

              {!!isSuccessUpdateOrderType &&
                displayErrorMessage(
                  translate("orderType.success.patchOrderTypeSuccess"),
                  ({ children }) => (
                    <Message type="success">{children}</Message>
                  ),
                )}

              {/* DISPLAY MODAL ERROR */}
              {!!isErrorUpdateWorksheets &&
                displayErrorMessage(
                  translate("orderType.errors.errorPatchWorksheets"),
                  ({ children }) => <Message type="error">{children}</Message>,
                )}

              {!!isErrorGettingWorksheets &&
                displayErrorMessage(
                  translate("orderType.errors.errorGetWorksheets"),
                  ({ children }) => <Message type="error">{children}</Message>,
                )}

              {!!isErrorCreateOrderType &&
                displayErrorMessage(
                  translate("orderType.errors.postOrderTypeError"),
                  ({ children }) => <Message type="error">{children}</Message>,
                )}
              {!!isErrorUpdateOrderType &&
                displayErrorMessage(
                  translate("orderType.errors.patchOrderTypeError"),
                  ({ children }) => <Message type="error">{children}</Message>,
                )}
            </Box>

            <Box className="flex flex-col items-center col-span-full">
              <Button
                color="warning"
                type="submit"
                size="small"
                className="flex justify-center items-center gap-x-2"
                disabled={isLoadingCreateOrderType || isLoadingCreateOrderType}
              >
                {translate(`orderType.buttons.${editing ? "update" : "add"}`)}
                {(isLoadingCreateOrderType || isLoadingCreateOrderType) && (
                  <Icon.Loading size="small" />
                )}
              </Button>
            </Box>
          </Form>
        </Modal>
      )}
    </>
  );
};
