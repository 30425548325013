import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useQuery, useQueryClient } from "react-query";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import {
  CognitoUser,
  ClientMetadata,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import roles from "../constants/roles";
import { readUser } from "../api";
import { ICurrentUser, ICompany } from "../api/types";
import { instance } from "@clearabee/ui-sdk";

interface PasswordExpired {
  expired: boolean;
  apiCallFailed: boolean;
  userEmail: string;
}

export interface IAuthContext {
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  forcePasswordChange: boolean;
  verify2FA: boolean;
  session: CognitoUserSession | null;
  setCurrentUser: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCurrentUser: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTemporaryUser: () => any;
  getCurrentUserAccessToken: () => string | null;
  getCurrentUserEmailAddress: () => string | null;
  getCurrentUserName: (includeSurname?: boolean) => string;
  getCurrentUserId: () => number;
  getCurrentUserCurrentCompany: () => ICompany;
  getCurrentUserCompanies: () => ICompany[];
  getCurrentUserCurrentCompanySettings: () => {
    canInvoice: boolean;
    hideInvoices?: boolean;
    hidePrices?: boolean;
    hidePhotos?: boolean;
    hideTransferNotes?: boolean;
  } | null;
  getIntercommHash: () => string;
  signOut: () => void;
  signIn: (username: string, password: string) => Promise<void>;
  forgotPassword: (
    email: string,
    clientMetaData?: ClientMetadata,
  ) => Promise<void>;
  forgotPasswordSubmit: (data: {
    username: string;
    code: string;
    passwordConfirmation: string;
  }) => void;
  refreshSession: () => void;
  doesUserHaveRole: (role: string | string[]) => boolean;
  isUserAuthenticated: () => boolean;
  isUserInactive: () => boolean;
  completeNewPassword: (newPassword: string) => void;
  handle2FAVerification: (code: string) => Promise<void>;
  setAmplifyConfigured: (configured: boolean) => void;
  passwordExpired: PasswordExpired;
  setPasswordExpired: Dispatch<SetStateAction<PasswordExpired>>;
  refreshTokenAndQueries: () => Promise<void>;
}

const newTokenAttemptLimit = 1;

const addInterceptorToUser = (cognito: CognitoUser): number =>
  instance.axios.interceptors.request.use(
    async (config) => {
      console.log("Auth interceptor...");
      return new Promise((resolve, reject) => {
        cognito?.getSession(
          (error: Error | undefined, session: CognitoUserSession | null) => {
            if (error || !session) {
              return reject(error);
            }
            config.headers = config.headers || {};
            config.headers.Authorization = `Bearer ${session
              .getIdToken()
              .getJwtToken()}`;

            resolve(config);
          },
        );
      });
    },
    (error) => Promise.reject(error),
  );

export const useAuthHandler = (): IAuthContext => {
  const [isLoading, setLoading] = useState(true);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const [verify2FA, setVerify2FA] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState<PasswordExpired>({
    expired: false,
    apiCallFailed: false,
    userEmail: "",
  });
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(null);
  const [amplifyIsConfigured, setAmplifyConfigured] = useState(false);
  const tokenRefreshCount = useRef<number>(0);
  const interceptorRef = useRef<number>();

  // Is migration in progress state check
  const [isMigratingUser, setMigratingUser] = useState(false);

  // Cast to any as user returned back from amplify is (CognitoUser | any)...
  // eslint-disable-next-line
  const [awsUser, setAWSUser] = useState<any>(null);

  const session = useMemo<IAuthContext["session"]>(() => {
    if (!awsUser) {
      return null;
    }

    return awsUser.getSignInUserSession();
  }, [awsUser]);

  const [tempUser, setTempUser] = useState<CognitoUser | null>(null);

  const newTokenAttemps = useRef(0);

  const queryClient = useQueryClient();

  const isUserWithCompany = () => {
    return !!currentUser?.companies?.length;
  };

  const isUserAuthenticated = () => {
    return awsUser !== null;
  };

  const isUserInactive = () => {
    const userHasRole = doesUserHaveRole([
      roles.CLEARABEE_ADMIN,
      roles.CLEARABEE_CUSTOMER_SERVICE,
    ]);

    return (
      !userHasRole &&
      !(currentUser?.companies.some(({ active }) => active) ?? true)
    );
  };

  const getCurrentUser = () => {
    return awsUser;
  };

  // temporary user is the Congito user object of someone who needs to change their password
  // => common things on this object you may need
  // => username (their unique Cognito ID)
  // => challengeName ("NEW_PASSWORD_REQUIRED" is the only one we handle right now)
  const getTemporaryUser = () => {
    return tempUser;
  };

  const getCurrentUserAccessToken = () => {
    return awsUser?.signInUserSession?.idToken?.jwtToken || null;
  };

  const getCurrentUserEmailAddress = () => {
    return awsUser?.attributes?.email || null;
  };

  const getCurrentUserId = () => {
    return currentUser?.id as number;
  };

  const getCurrentUserName = (includeSurname = true) => {
    const firstName = currentUser?.firstName || "";

    if (includeSurname) {
      const lastName = currentUser?.lastName || "";
      return `${firstName} ${lastName}`;
    }

    return firstName;
  };

  const getCurrentUserRoles = () => {
    return currentUser?.roles || [];
  };

  const getIntercommHash = () => {
    return currentUser?.intercomm?.hash;
  };

  // a couple of variables to save calling these functions multiple times in the below functions
  const currentUserEmailAddress = getCurrentUserEmailAddress();
  const currentUserId = getCurrentUserId();
  const isUserSignedIn = isUserAuthenticated();
  const userRoles = getCurrentUserRoles();

  const doesUserHaveRole = useCallback(
    (role: string | string[]) => {
      // this function can accept both a single role string or an array of role strings:
      // => i.e. 'Company Administrator' || ['Company Administrator', 'Company User']
      const userHasRole = userRoles.filter(({ name }: { name: string }) => {
        if (Array.isArray(role)) {
          return role.includes(name);
        } else {
          return role === name;
        }
      });

      return userHasRole.length > 0;
    },
    [userRoles],
  );

  const getCurrentUserCurrentCompany = () => {
    // later on we may store the current company in local storage or something like that
    // => for now, we just grab the first option, as everyone will only have one company
    // => i.e. const currentCompany = localStorage.getItem('currentCompany')
    return currentUser?.companies[0] as ICompany;
  };

  const getCurrentUserCompanies = () => {
    // returns an array of the companies linked to this user. Some users now have
    // more than one company
    return currentUser?.companies as ICompany[];
  };

  const getCurrentUserCurrentCompanySettings = () => {
    // later on we may store the current company in local storage or something like that
    // => for now, we just grab the first option, as everyone will only have one company
    // => i.e. const currentCompany = localStorage.getItem('currentCompany')
    return currentUser?.companies[0]?.settings || null;
  };

  const resetUser = () => {
    setAWSUser(null);
    setCurrentUser(null);

    // this sets the init state when logged out, because we otherwise set it once the user is authenticated
    if (isLoading) {
      setLoading(false);
    }
  };

  const getAWSUser = async () => {
    try {
      const usr = await Auth.currentAuthenticatedUser();
      const { signInUserSession } = usr;
      const {
        idToken: { jwtToken },
      } = signInUserSession;

      axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
      instance.axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;

      // dump query cache as user may have changed
      queryClient.clear();

      setAWSUser(usr);
    } catch {
      resetUser();
    }
  };

  const signIn = async (username: string, password: string) => {
    setLoading(true);
    const auth = await Auth.signIn(username, password);

    try {
      interceptorRef.current = addInterceptorToUser(auth);
    } catch (error) {
      signOut();
      throw error;
    }

    if (auth.challengeName && auth.challengeName === "NEW_PASSWORD_REQUIRED") {
      setForcePasswordChange(true);
      setTempUser(auth);
    }

    if (auth.challengeName && auth.challengeName === "SOFTWARE_TOKEN_MFA") {
      setVerify2FA(true);
      setTempUser(auth);
    }
  };

  const signOut = async () => {
    try {
      if (
        interceptorRef.current !== null &&
        interceptorRef.current !== undefined
      ) {
        instance.axios.interceptors.request.eject(interceptorRef.current);
        interceptorRef.current = undefined;
      }
      await Auth.signOut();
    } catch {
      resetUser();
    }
  };

  const forgotPassword = async (
    username: string,
    clientMetaData?: ClientMetadata,
  ) => {
    try {
      await Auth.forgotPassword(username, clientMetaData);
    } catch (error) {
      throw error;
    }
  };

  // Collect confirmation code and new password
  // This is a mess, fixing types here breaks everything... needs sorting
  const forgotPasswordSubmit = async (data: {
    username: string;
    code: string;
    passwordConfirmation: string;
  }) => {
    const { username, code, passwordConfirmation: new_password } = data;
    try {
      const result = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password,
      );
      return result;
    } catch (error) {
      throw error;
    }
  };

  // attempt to use the refresh token to get a new id token
  // => if it fails, sign the user out
  // => this is called automatically by axios interceptors
  const refreshSession = async () => {
    console.log("Refreshing session...");
    try {
      await Auth.currentSession();
    } catch {
      signOut();
    }
  };

  const manualRefreshTokens = async () => {
    const currentSession = await Auth.currentSession();
    awsUser.refreshSession(
      currentSession.getRefreshToken(),
      // eslint-disable-next-line
      (err: any, session: any) => {
        console.log("session", err, session);
      },
    );
  };

  const handle2FAVerification = async (code: string) => {
    await Auth.confirmSignIn(tempUser, code, "SOFTWARE_TOKEN_MFA");
    setTempUser(null);
    setVerify2FA(false);
  };

  // update the user's password (for new users with temporary credentials)
  const completeNewPassword = async (newPassword: string) => {
    try {
      await Auth.completeNewPassword(tempUser, newPassword);

      // if that was successful, change the state of the forced password change
      setTempUser(null);
      setForcePasswordChange(false);
    } catch (e) {
      // on fail, show error
      throw e;
    }
  };

  /**
   * Temporary patched read user method which catches invalid error responses from api.
   */
  const patchedReadUser = async (emailAddress: string) => {
    const res = await readUser(emailAddress);
    // If invalid response due to current api bug
    if (typeof res === "string" || !res.hasOwnProperty("active")) {
      throw Error("Invalid user response");
    }
    return res;
  };

  const { refetch } = useQuery(
    ["getUserQuery"],
    () => patchedReadUser(currentUserEmailAddress),
    {
      enabled: awsUser !== null,
      retry: false,
      refetchInterval: 1800000, // 30 minutes
      onSuccess: (data) => {
        setCurrentUser(data);
        setLoading(false);
        // reset attempts if necessary
        if (newTokenAttemps.current != 0) newTokenAttemps.current = 0;
      },
      // Cast to any as it's technically unknown
      // eslint-disable-next-line
      onError: (error: Error | any) => {
        console.log("@getUserQuery ERROR: ", error);
        if (
          error.hasOwnProperty("message") &&
          error.message === "Invalid user response"
        ) {
          establishNewConnection();
        } else {
          signOut();
        }
      },
    },
  );

  /**
   * Attempts to generate new tokens and refetch our user details.
   */
  const establishNewConnection = async () => {
    if (newTokenAttemps.current < newTokenAttemptLimit)
      try {
        // Wait 5 seconds before attempting as a precaution
        await new Promise((res) => setTimeout(res, 5000));
        console.log(
          "Attemping to establish new connection... \nAttempt number: ",
          newTokenAttemps.current,
        );
        // Increase attempts by 1
        newTokenAttemps.current = newTokenAttemps.current + 1;
        // Refresh the tokens
        await manualRefreshTokens();
        // Request user details again
        refetch();
        // Exit
        return;
      } catch (err) {
        console.error(err);
      }

    console.log("New connection attempts failed...");
    // If all attemps fail, signout
    newTokenAttemps.current = 0;
    signOut();
  };

  useEffect(() => {
    if (isUserSignedIn && currentUser !== null) {
      const userHasRole = doesUserHaveRole([
        roles.CLEARABEE_ADMIN,
        roles.CLEARABEE_CUSTOMER_SERVICE,
      ]);

      if (!isUserWithCompany() && !userHasRole) {
        signOut();
      }

      // set the user in the sentry client so we can attribute errors to actual users
      Sentry.setUser({
        id: currentUserId?.toString(),
        email: currentUserEmailAddress,
      });
    } else if (!isUserSignedIn) {
      // unset on logout
      Sentry.setUser({});
    }

    return () => Sentry.setUser({});
  }, [isUserSignedIn, currentUser, currentUserId, currentUserEmailAddress]);

  // handle 401 and 403 errors and attempt to refresh the user token first, before logging out
  // => if the refresh is successful, the request will be re-tried
  useEffect(() => {
    /**
     * Fixes interceptor bug. If signed in then refreshed, the interceptor would not be applied.
     */
    if (isUserSignedIn) {
      (async () => {
        const auth = await Auth.currentAuthenticatedUser();
        if (auth) {
          interceptorRef.current = addInterceptorToUser(auth);
        }
      })();
    }

    const instanceInterceptorId = createAuthRefreshInterceptor(
      instance.axios,
      // eslint-disable-next-line
      refreshAuthLogic,
      {
        pauseInstanceWhileRefreshing: true,
        statusCodes: [401, 403],
      },
    );

    const axiosInterceptorId = createAuthRefreshInterceptor(
      axios,
      // eslint-disable-next-line
      refreshAuthLogic,
      {
        pauseInstanceWhileRefreshing: true,
        statusCodes: [401, 403],
      },
    );
    // remove the interceptor on unmount
    return () => {
      instance.axios.interceptors.request.eject(instanceInterceptorId);
      axios.interceptors.request.eject(axiosInterceptorId);
    };
  }, []);

  /**
   * At the moment, this func does the job, but it will refetch multiple queries request, which is not ideal.
   *  Will look into a better solution in the future.
   */
  const refreshAuthLogic = async (): Promise<any> => {
    console.log("Refreshing tokens...");

    try {
      // if requests fail due to permissions 403 or token expired and retried more than 3 times, cancel all queries to prevent infinite loop
      if (tokenRefreshCount.current > 2) {
        await queryClient.cancelQueries();
        tokenRefreshCount.current = 0;
        return;
      }

      await refreshTokenAndQueries();
      tokenRefreshCount.current = tokenRefreshCount.current + 1;
    } catch (error) {
      console.log("Refresh failed with error: ", error);
      signOut();
      return Promise.reject(error);
    }
  };

  const refreshTokenAndQueries = async () => {
    const newToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    instance.axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;

    await queryClient.resetQueries();
  };

  return {
    isLoading: isLoading || isMigratingUser,
    setLoading: setLoading,
    forcePasswordChange,
    verify2FA,
    session,
    getCurrentUser,
    getTemporaryUser,
    getCurrentUserAccessToken,
    getCurrentUserEmailAddress,
    getCurrentUserName,
    getCurrentUserId,
    getCurrentUserCurrentCompany,
    getCurrentUserCompanies,
    getCurrentUserCurrentCompanySettings,
    getIntercommHash,
    isUserInactive,
    setCurrentUser: getAWSUser,
    doesUserHaveRole,
    refreshSession,
    signOut,
    signIn,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    handle2FAVerification,
    isUserAuthenticated,
    setAmplifyConfigured,
    passwordExpired,
    setPasswordExpired,
    refreshTokenAndQueries,
  };
};
