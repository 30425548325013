"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.common = void 0;
exports.common = {
    actions: "Actions",
    acceptProhibitedItems: "I confirm my order does not contain any prohibited items",
    acceptThisJob: "Accept this job",
    activeJobs: "Active jobs",
    addAdditionalNotes: "Add additional notes / comments",
    addAdditionalInformationRequired: "Please describe the other reason",
    addAVehicle: "Add a vehicle",
    addDataAndComplete: "Add data and complete this job",
    addMissingData: "Add missing data",
    address: "Address",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addToBasket: "Add to basket",
    afterPhotosLabel: "After Photos",
    all: "All",
    allActive: "All active",
    amount: "Amount",
    andMore: "and more",
    areYouOnYourWay: "Are you on your way?",
    assign: "Assign",
    assignedVehicle: "Assigned Vehicle",
    back: "Back",
    backToLogin: "Back to login",
    beeloyalCredits: "Beeloyal Credits",
    beeloyalCreditsExpire: "Credits Expires",
    beforePhotosLabel: "Before Photos",
    billingDetails: "Billing Details",
    book: "Book",
    bookAgain: "Book again",
    bookNow: "Book now",
    bookService: "Book a service",
    cancel: "Cancel",
    cantFindAddress: "Can't find your address?",
    cardNumber: "Card Number",
    checkout: "Checkout",
    chooseSubcontractor: "Choose a Subcontractor",
    chooseVehicle: "Choose a Vehicle",
    city: "City",
    clear: "Clear",
    code: "Code",
    collectionAddress: "Collection Address",
    collectionCharge: "Collection Charge",
    collectionDate: "Collection Date",
    collectionReadyDate: "Collection Ready From Date",
    collectionDetails: "Collection Details",
    collectionPostcode: "Collection Postcode",
    collectionOn: "Collection on",
    collectionTime: "Collection Time",
    commercialSkipHire: "Commercial Skip Hire",
    businessWaste: "Business Waste",
    needlesAndSharps: "Needles & Sharps Removal",
    companyName: "Company Name",
    completedJobs: "Completed Jobs",
    completeOrder: "Complete Order",
    confirm: "Confirm",
    confirmNewPassword: "Confirm New Password",
    confirmPassword: "Confirm Password",
    confirmSelection: "Confirm Selection",
    contactMethod: "Contact method",
    contactUs: "Contact us",
    continue: "Continue",
    county: "County",
    coupon: "Coupon",
    couponApplied: "Coupon applied",
    createAccount: "Create Account",
    createNewPassword: "Create new password",
    createPassword: "Create Password",
    currentPassword: "Current Password",
    createWTN: "Create W.T.N.",
    createWTNHere: "Create a W.T.N. here",
    customerDetails: "Customer Details",
    customerName: "Customer Name",
    customerSignature: "Customer Signature",
    cvc: "CVC",
    date: "Date",
    delete: "Delete",
    deleting: "Deleting...",
    deliveryAddress: "Delivery Address",
    deliveryDate: "Delivery Date",
    deliveryOn: "Delivery on",
    driverName: "Driver Name",
    driverSignature: "Driver Signature",
    each: "each",
    edit: "Edit",
    editDetails: "Edit Details",
    email: "Email",
    emailAddress: "Email Address",
    enterAddressManually: "Enter Address Manually?",
    enterAddressManuallyStatement: "Enter address manually",
    enterReg: "Enter reg",
    errorUploadingImages: "There was an error while loading images.",
    exchangeDate: "Exchange Date",
    existingCustomers: "Existing Customers",
    exchangeOrderRef: "Exchange Reference",
    expiry: "Expiry Date",
    findAddress: "Find Address",
    finish: "Finish",
    firstName: "First Name",
    forgotPassword: "Forgot password",
    form: {
        headings: {
            collectionDetails: "Collection details",
            enterOrderNumber: "Enter your order number and postcode to begin.",
            preferredDate: "Preferred collection Date",
            bookingSuccessful: "Booking successful",
            customerDetails: "Customer details",
        },
        placeholder: {
            address: "Select Address",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            code: "Code",
            county: "County",
            companyName: "Company Name",
            confirmPassword: "Confirm Password",
            confirmNewPassword: "Confirm New Password",
            currentPassword: "Current Password",
            date: "Enter date",
            discountCode: "Enter discount code",
            emailAddress: "Email Address",
            firstName: "First Name",
            jobReference: "Enter reference",
            lastName: "Last Name",
            newPassword: "New Password",
            password: "Password",
            phoneNumber: "Phone Number",
            postcode: "Enter postcode",
            ref: "Enter reference",
            collectionPostcode: "Enter collection postcode",
            deliveryPostcode: "Enter delivery postcode",
            wasteType: "Enter waste type",
            search: "Search",
            searchItems: "Search items...",
            searchAppliances: "Search for appliances (eg. washing machine)",
            sic: "Standard Industrial Classification Code",
            wasteDescription: "Enter waste description",
            filterItems: "Filter items",
        },
        labels: {
            kitchenSize: "Kitchen size",
            wasteAccessible: "Is the waste accessible without a member of the household being present?",
            orderNumber: "Order number",
            wrenKitchensOrderNumber: "Wren kitchens order number",
        },
        errors: {
            limitExceeded: "Attempt limit exceeded, please try after some time",
            incorrectPassword: "Incorrect password",
            incorrectUsernameOrPassword: "Incorrect username or password",
            invalidPhone: "Must be a valid phone number",
            invalidEmail: "This email is invalid",
            invalidNumber: "Must be a valid number",
            invalidPostcode: "Invalid postcode",
            requiredField: "This field is required",
            tooShortField: "Minimum {{minimum}} characters",
            tooLongField: "Maximum {{maximum}} characters",
            requiredWhenCustomTip: "Required when entering a custom tip",
            matchField: "Passwords must match",
            mustBeChecked: "This field must be checked",
            invalidDate: "This is an invalid date format",
            failedAddVehicle: "Failed to add vehicle",
            failedSignOut: "Failed to sign out",
            failedImageUpload: "Failed to upload images",
            failedUpdateJob: "Failed to update the job details",
            failedUpdateTimestamp: "Failed to update the timestamp",
            failedUpdateVehicle: "Failed to update the vehicle details",
            pleaseSelectAddress: "Please select an address",
            noWeeKerbsideDates: "We're sorry, but there are currently no available dates. Please try later.",
        },
        buttons: {
            continue: "Continue",
            processing: "Processing...",
            finish: "Finish",
            back: "Back",
        },
        messages: {
            yourOrderNumber: "Your order number is {{orderNumber}},",
            pleaseKeepItSafe: "please keep it safe for future reference.",
            confirmationEmail: "You'll soon receive a confirmation email regarding your collection.",
            noProductsFound: "No products found",
        },
    },
    from: "From",
    furnitureCondition: "Furniture Condition",
    furnitureRemoval: "Furniture Removal",
    houseClearance: "House Clearance",
    findOutMore: "Find out more",
    getDirections: "Get Directions",
    getPrices: "Get Prices",
    getInTouch: "Get in touch",
    hey: "Hey",
    hideItems: "Hide items",
    ifASectionIsClosedWarning: "Please check if any of these sections apply. If a section is closed, no values will be submitted for that section.",
    includingVat: "Including VAT",
    incVat: "inc. VAT",
    invalidCoupon: "Invalid coupon",
    invoiceImage: "Invoice Image",
    invoiceNumber: "Invoice Number",
    invoiceUpload: "Invoice Upload",
    item: "item",
    items: "items",
    noImagesFound: "No images found.",
    haveYouCollected: "Have you collected the waste?",
    haveYouStarted: "Have you started the job?",
    haveYouTipped: "Have you tipped the waste?",
    howManyItems: "How many items?",
    jobImages: "Job Images",
    jobReference: "Job Reference",
    jobValue: "Job Value",
    lastName: "Last Name",
    learnMore: "Learn more",
    leftovers: "Leftovers",
    loadingMessage: "Loading...",
    login: "Login",
    logout: "Logout",
    markAsCollected: "Mark as Collected",
    markAsFailed: "Mark as Job Failed",
    markAsJobStarted: "Mark as Job Started",
    markAsOnWay: "Mark as On The Way",
    markAsTipped: "Mark as Tipped",
    markCollected: "Marked as Collected",
    markOnWay: "Marked as On The Way",
    markStarted: "Marked as Started",
    markTipped: "Marked as Tipped",
    minimumFourVehicleImages: "Please submit at least 4 images.",
    mostPopular: "Most Popular",
    na: "N.A.",
    name: "Name",
    nearestTotalHazardousWeight: "Total weight of the hazardous / chargeable items",
    nearestWeight: "Select the nearest weight",
    new: "New",
    newPassword: "New Password",
    nextDayDelivery: "Next day delivery",
    no: "No",
    noDescriptionProvided: "No description provided",
    notAvailable: "Not available",
    oldPassword: "Old Password",
    optional: "Optional",
    or: "or",
    orderReference: "Order Reference",
    notes: "Notes",
    password: "Password",
    phone: "Phone",
    phoneNumber: "Phone Number",
    pleaseAllowMediaPermissions: "Please Allow media gallery permissions.",
    postcode: "Postcode",
    privacyPolicy: "Privacy Policy",
    product: "Product",
    products: "Products",
    proceedToCheckout: "Proceed to checkout",
    price: "Price",
    preferredCollectionDate: "Preferred Collection Date",
    preferredDeliveryDate: "Preferred Delivery Date",
    preferredDate: "Preferred date",
    provideDescription: "Provide a Description",
    provideLocationPhotos: "Provide photos of the job location",
    quantity: "Quantity",
    ref: "Reference",
    remove: "Remove",
    registration: "Registration",
    requiredWhenNoCustomer: "This is required if no customer is present",
    returnToJobDetails: "Return to Job Details",
    samedayAvailable: "Available Sameday",
    save: "Save",
    search: "Search",
    selectAfterPhotos: "Select After Photos",
    selectAReason: "Please select a reason",
    selectArmChairs: "Select how many arm chairs",
    selectArrivalPhotos: "Select Arrival Photos",
    selectFourSeater: "Select how many four seater sofas",
    selected: "selected",
    selectJobFailureReason: "Select the reason for job failure",
    selectThreeSeater: "Select how many three seater sofas",
    selectTip: "Select tip from the list",
    selectTwoSeater: "Select how many two seater sofas",
    selectVehicle: "Select Vehicle",
    sendResetLink: "Send reset link",
    services: "Services",
    serviceType: "Service Type",
    serviceTypes: {
        manvan: "Man & Van",
        bincol: "Bin Collection",
        skips: "Skip Hire",
    },
    showAll: "Show all",
    signIn: "Sign in",
    signOut: "Sign out",
    signUp: "Sign up",
    skipDropOffPostcode: "Skip drop off postcode",
    skipSize: "Skip size",
    skipWasteTypes: {
        mixedGeneralWaste: "Mixed general waste",
        bulkyWaste: "Bulky waste",
        lightConstructionWaste: "Light construction waste",
        heavyConstructionWaste: "Heavy construction waste",
        soil: "Soil",
    },
    status: "Status",
    statusTypes: {
        accepted: "Accepted",
        onTheWay: "On the way",
        arrived: "Arrived",
        collected: "Collected",
        tipped: "Tipped",
        completed: "Completed",
        failed: "Failed",
    },
    submit: "Submit",
    submitVehicle: "Submit Vehicle For Approval",
    subtotal: "Subtotal",
    suitableFor: "Suitable for",
    takeScreenshotOfCall: "Take a screenshot of your call to the customer",
    tbc: "TBC",
    termsAndConditions: {
        title: "Terms & Conditions",
        message: "I have read and agree to the",
        link: "terms and conditions",
    },
    time: "Time",
    timeSlotCharge: "Time slot charge",
    tipAddress: "Tip Address",
    tipDetailsEnter: "If your tip is not on the list, then you must enter details for the tip you are using.",
    tipLicense: "Tip License Number",
    tipName: "Tip Name",
    tipNotOnList: "Is your tip not on the list?",
    tippingDetails: "Add Tipping Details",
    tipPostcode: "Tip Postcode",
    to: "to",
    total: "Total",
    totalWeightLabel: "Total weight of collection",
    update: "Update",
    updateJob: "Update Job",
    updateJobDetails: "Update the Job Details",
    updateVehicle: "Update Vehicle",
    uploadAfterPhotos: "Upload After Job Photos",
    uploadBeforePhotos: "Upload Before Job Photos",
    uploadFrontBackSidePhotos: "Left side, Front, Rear, Right side",
    uploadInvoice: "Upload Invoice Details",
    uploadInvoiceImage: "Upload Your Invoice",
    uploadPhotos: "Upload photos",
    uploadSiteAttendance: "Upload photos to show site attendance",
    uploadWastePhoto: "Upload Waste Photo",
    uploadWeighbridgeTicket: "Upload Weighbridge Ticket",
    uploadWTN: "Upload Waste Transfer Note",
    usePostcodeLookup: "Use Postcode Lookup?",
    userManagement: "User Management",
    vehicleAddedSuccessfully: "Vehicle added successfully!",
    vehicleId: "Identifiable vehicle nickname",
    verificationCode: "Verification Code",
    viewInMaps: "View in Maps",
    viewJob: "View job",
    viewAll: "View all",
    wasteDescription: "Waste Description",
    wasteDetails: "Waste details",
    wasteTransferNote: "Waste Transfer Note",
    wasteType: "Waste Type",
    weighbridgeTicketImage: "Weighbridge Ticket Image",
    weighbridgeTicketNumber: "Weighbridge Ticket Number",
    weighbridgeTicketUpload: "Weighbridge Ticket Upload",
    welcomeBack: "Welcome back",
    orderTracking: "Track your order",
    weveGotYouCovered: "We've got you covered",
    whatIsTotalWeight: "What is the total weight of the collection?",
    wtnLabel: "Waste Transfer Note Upload",
    yes: "Yes",
};
