import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IVehicle, ICompany } from "@clearabee/api-schemas";
import { toasts } from "../../helpers";
import {
  Heading,
  Button,
  Box,
  Modal,
  theme,
  Panel,
  Form,
  Input,
  Table,
  Icon,
  UnstyledButton,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../common/components";
import { EditVehicleModal, CreateVehicleModal } from "./components";
import {
  filtersInitialValues as initialValues,
  filtersValidationSchema as validationSchema,
} from "./validation";
import { buildQuery, getErrorMessage } from "helpers/api";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { IPaginatedResults, TFilters } from "api/types";

const initialResultsPerPage = 10;

const statusOptions = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
];

const groupOptions = [
  { label: "Subcontractors", value: "subcontractors" },
  { label: "Clearabee", value: "clearabee" },
];

export const ReadVehicles = (): React.ReactElement => {
  const { t } = useTranslation("vehicles");

  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [deleteVehicleId, setDeleteVehicleId] = useState("");

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  /**
   *  function to read the vehicles and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readVehicles = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IVehicle>> => {
    const filterParams = new URLSearchParams(filters);

    //vehicle filters
    const company = filterParams?.get("company:eq") ?? "";
    const registration = filterParams?.get("registration:like") ?? "";
    const status = filterParams?.get("status:eq") ?? "";
    const group = filterParams?.get("group:eq") ?? "";

    const { data } = await instance.vehicles.getVehicles({
      params: {
        ...(!!group &&
          group === "clearabee" && { "company_code:isNull": true }),
        ...(!!group &&
          group === "subcontractors" && { "company_code:notNull": true }),
        ...(!!company ? { "company_code:eq": company } : {}),
        ...(!!registration ? { "registration:like": `%${registration}%` } : {}),
        ...(!!status ? { "status:eq": `${status}` } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<IVehicle>;

    return response;
  };

  /**
   * get Subcontractors Companies
   */
  const { data: subcontractorCompanies, isLoading: subcontractorsIsLoading } =
    useQuery(
      "getSubcontractors",
      async () =>
        (
          await axios.get(
            `${process.env.REACT_APP_MS_API_BASE_URL}/companies`,
            {
              params: {
                orderBy: "name",
                "type:eq": "subcontractor",
                limit: 10000,
              },
            },
          )
        ).data.items,
      {
        retry: false,
        onError: (error) =>
          toasts.error({
            message: getErrorMessage(error),
          }),
      },
    );

  /**
   * get Drivers
   */
  const { data: drivers, isLoading: driversIsLoading } = useQuery(
    "getDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            limit: 10000,
            "roles.name:in": "Clearabee Driver",
          },
        })
      ).data.items,
    {
      retry: false,
      onError: (error) =>
        toasts.error({
          message: getErrorMessage(error),
        }),
    },
  );

  /**
   * get Tips
   */
  const { data: tips, isLoading: tipsIsLoading } = useQuery(
    "getTips",
    async () =>
      (
        await instance.jobs.getTips({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: (error) =>
        toasts.error({
          message: getErrorMessage(error),
        }),
    },
  );

  /**
   *  get Regions
   */
  const { data: regions, isLoading: regionsIsLoading } = useQuery(
    "getRegions",
    async () =>
      (
        await instance.vehicles.getVehicleRegions({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  /**
   * get Cities
   */
  const { data: cities, isLoading: citiesIsLoading } = useQuery(
    "getCities",
    async () =>
      (
        await instance.vehicles.getVehicleCities({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  /**
   * companyOptions
   */
  const companiesOptions = useMemo(() => {
    if (!subcontractorCompanies?.length || subcontractorsIsLoading) return [];

    return subcontractorCompanies.map((subcontractor: ICompany) => ({
      label: subcontractor.name,
      value: subcontractor.companyCode,
    }));
  }, [subcontractorCompanies, subcontractorsIsLoading]);

  /**
   * driverOptions
   */
  const driverOptions = useMemo(() => {
    if (!drivers?.length || driversIsLoading) return [];

    return drivers.map((driver) => ({
      label: `${driver.firstName} ${driver.lastName}`,
      value: driver.id,
    }));
  }, [drivers, driversIsLoading]);

  /**
   * tipOptions
   */
  const tipOptions = useMemo(() => {
    if (!tips?.length || tipsIsLoading) return [];

    return tips.map((tip) => ({
      label: tip.name,
      value: tip.id,
    }));
  }, [tips, tipsIsLoading]);

  const citiesOptions = useMemo(() => {
    if (!cities?.length || citiesIsLoading) return [];

    return cities.map((city) => ({
      label: city.name,
      value: city.id,
    }));
  }, [cities, citiesIsLoading]);

  /**
   * regionsOptions
   */
  const regionsOptions = useMemo(() => {
    if (!regions?.length || regionsIsLoading) return [];

    return regions.map((region) => ({
      label: region.name,
      value: region.id,
    }));
  }, [regions, regionsIsLoading]);

  /**
   * delete vehicle
   */
  const { mutate: deleteVehicle, isLoading: deleteVehicleIsLoading } =
    useMutation(
      "deleteVehicle",
      async (vehicleId: string) =>
        await instance.vehicles.deleteVehicle(vehicleId),
      {
        onSuccess: () => {
          refetchVehicles();
          toasts.success({
            message: t("toasts.deleteSuccess"),
          });
        },
        onError: (error) =>
          toasts.error({
            message: getErrorMessage(error),
          }),
      },
    );

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // vehicle filters
    const registration = params?.get("registration:like") ?? "";
    const status = params?.get("status:eq") ?? "";
    const company = params?.get("company:eq") ?? "";
    const group = params?.get("group:eq") ?? "";

    return {
      registration,
      company,
      status,
      group,
    };
  };

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResultsPerPage,
    query: {
      isFetching: vehiclesIsRefetching,
      isLoading: vehiclesIsLoading,
      refetch: refetchVehicles,
      isSuccess: isSuccessReadVehicles,
    },
  } = usePaginatedQuery(
    readVehicles,
    "readVehiclesPaginated",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "group:eq=clearabee",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
  );

  const handleSubmit = (values: typeof initialValues) => {
    const { company, registration, status, group } = values;
    const updatedFilterObject = {
      ...(!!company ? { "company:eq": company } : {}),
      ...(!!registration ? { "registration:like": registration } : {}),
      ...(!!status ? { "status:eq": status } : {}),
      ...(!!group ? { "group:eq": group } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/vehicles?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}&group:eq=clearabee`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* LOADING */}
      {(vehiclesIsLoading ||
        vehiclesIsRefetching ||
        subcontractorsIsLoading ||
        deleteVehicleIsLoading) && <LoadingOverlay />}

      {/* FILTER FORM */}
      <Panel shadow={false} className="mb-10 mt-10">
        <Box className="flex flex-wrap flex-row items-center justify-between">
          <Heading color="brand" level={1} fontSize="large">
            {t("heading")}
          </Heading>
          <Button
            color="brand"
            size="small"
            onClick={() => setShowCreateModal(true)}
          >
            {t("buttons.addVehicle")}
          </Button>
        </Box>
        <Form
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ Field, isValid, resetForm }) => (
            <Box className="flex flex-wrap sm:flex-no-wrap gap-x-6 border-t border-grey-200 pt-5 mt-4">
              {/* REGISTRATION */}
              <Field
                styles={{ flex: 2, margin: 0 }}
                label="Registration"
                name="registration"
              >
                {({ field }) => <Input.Text {...field} />}
              </Field>
              {/* STATUS */}
              <Field
                styles={{ flex: 2, margin: 0 }}
                label="Status"
                name="status"
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isClearable
                    options={statusOptions}
                  />
                )}
              </Field>
              {/* GROUP */}
              <Field styles={{ flex: 2, margin: 0 }} label="Group" name="group">
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isClearable
                    defaultValue={field.value}
                    options={groupOptions}
                  />
                )}
              </Field>
              {/* COMPANY */}
              <Field
                label="Company"
                styles={{ flex: 2, margin: 0 }}
                name="company"
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isSearchable
                    isClearable
                    defaultValue={field.value}
                    isLoading={subcontractorsIsLoading}
                    options={companiesOptions}
                  />
                )}
              </Field>
              <Box className="flex flex-1 gap-x-6 items-center mt-4 justify-between">
                <Button
                  type="reset"
                  size="small"
                  color="negative"
                  onClick={() => {
                    resetForm();
                    updateFilters("");
                    setCurrentPage(1);
                    setResultsPerPage(initialResultsPerPage);
                    history.push(
                      `/vehicles?page=1&resultsPerPage=${initialResultsPerPage}`,
                    );
                  }}
                >
                  {t("buttons.reset")}
                </Button>
                <Button
                  disabled={!isValid}
                  size="small"
                  color="accent"
                  type="submit"
                >
                  {t("buttons.search")}
                </Button>
              </Box>
            </Box>
          )}
        </Form>
      </Panel>

      {!!paginatedData && paginatedData?.length > 0 && (
        <>
          {/* RESULTS TABLE */}
          <Panel className="mb-6">
            <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
              <colgroup>
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "17%" }} />
              </colgroup>
              <Table.Header
                fontSize="small"
                headings={[
                  t("table.name"),
                  t("table.registration"),
                  t("table.code"),
                  t("table.status"),
                  t("table.type"),
                  t("table.actions"),
                ]}
              />
              <Table.Body>
                {paginatedData?.map((vehicle) => (
                  <Table.Row key={`table-row-${vehicle.id}`}>
                    <Table.Cell.Text className="truncate">
                      {vehicle.name}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {vehicle.registration}
                    </Table.Cell.Text>

                    <Table.Cell.Text className="truncate">
                      {vehicle.companyCode || "None"}
                    </Table.Cell.Text>

                    <Table.Cell.Pill
                      color={
                        vehicle.status === "approved" ? "accent" : "warning"
                      }
                      className="truncate capitalize"
                    >
                      {vehicle.status}
                    </Table.Cell.Pill>

                    <Table.Cell.Text className="truncate">
                      {vehicle.type?.type || "Unknown"}
                    </Table.Cell.Text>

                    <Table.Cell className="flex flex-row gap-3 w-28">
                      <Button
                        size="xsmall"
                        type="button"
                        onClick={() => setSelectedVehicle(vehicle)}
                        className="text-center"
                      >
                        {t("buttons.edit")}
                      </Button>
                      <UnstyledButton>
                        <Icon.Trash
                          name="close"
                          size="large"
                          color="negative"
                          onClick={() => setDeleteVehicleId(String(vehicle.id))}
                        />
                      </UnstyledButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Box className="mt-10 flex justify-center">
              <PaginationComponent />
            </Box>
          </Panel>

          {/* DELETE MODAL */}
          <Modal
            modalVisible={!!deleteVehicleId}
            width={400}
            onClose={() => setDeleteVehicleId("")}
          >
            <Heading
              color="brand"
              level={4}
              styles={{ marginBottom: theme.spacing.small }}
            >
              {t("deleteVehicle")}
            </Heading>
            <Box className="flex justify-center">
              <Button
                size="small"
                color="negative"
                onClick={() => {
                  deleteVehicle(deleteVehicleId);
                  setDeleteVehicleId("");
                }}
              >
                {t("buttons.delete")}
              </Button>
            </Box>
          </Modal>

          {/* EDIT VEHICLE MODAL */}
          <EditVehicleModal
            tipOptions={tipOptions}
            citiesOptions={citiesOptions}
            regionsOptions={regionsOptions}
            driverOptions={driverOptions}
            showModal={!!selectedVehicle}
            selectedVehicle={selectedVehicle}
            refetchVehicles={refetchVehicles}
            setSelectedVehicle={setSelectedVehicle}
          />
        </>
      )}

      {/* CREATE MODAL */}
      <CreateVehicleModal
        citiesOptions={citiesOptions}
        companiesOptions={companiesOptions}
        driverOptions={driverOptions}
        regionsOptions={regionsOptions}
        tipOptions={tipOptions}
        isVisible={showCreateModal}
        onVehicleCreated={() => {
          refetchVehicles();
          toasts.success({
            message: t("toasts.vehicleCreated"),
          });
          setShowCreateModal(false);
        }}
        onClose={() => setShowCreateModal(false)}
      />

      {/* NO RESULTS */}
      {!vehiclesIsLoading &&
        !vehiclesIsRefetching &&
        !paginatedData?.length &&
        isSuccessReadVehicles && (
          <Box className="flex justify-center mt-12">
            <Heading color="brand" level={3}>
              {t("noVehicles")}
            </Heading>
          </Box>
        )}
    </>
  );
};
