import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import { Icon, Pill, Text, theme } from "@clearabee/ui-library";
import { version } from "../../../../package.json";

/**
 * Import routes.
 */
import { Routes } from "../../../helpers/routes";

/**
 * Import images.
 */
import {
  DashboardIcon,
  ViewJobsIcon,
  UsersIcon,
  LeaderboardIcon,
  QuotesIcon,
  CompaniesIcon,
  CataloguesIcon,
  SubcontractorsIcon,
} from "../../../images";

/**
 * Import hooks.
 */
import { useAuthContext, useScreenWidth } from "../../../hooks";

/**
 * Have permission core component.
 */
import { HavePermission, RouteLink } from "../../core";

/**
 * Import components.
 */
import { AccountAvatar, AccountBubble, Submenu } from ".";
import roles from "constants/roles";

/**
 * Sidebar navigation items.
 */
const rt = new Routes();

/**
 * Menu types.
 */
interface IBaseMenuItem {
  name: string;
  nicename: string;
  to: string;
  exact?: boolean;
  hidden?: boolean;
  target?: string;
}

interface ISubMenuItem {
  title: string;
  items: IBaseMenuItem[];
}

interface IMenuItem extends IBaseMenuItem {
  icon: React.ReactNode;
  submenu?: ISubMenuItem;
}

const createJob: IBaseMenuItem = {
  name: "jobs/create",
  nicename: rt.config("jobs/create", "nicename"),
  to: rt.config("jobs/create", "path"),
  exact: true,
};
const viewJob: IBaseMenuItem = {
  name: "jobs",
  nicename: `View ${rt.config("jobs", "nicename")}`,
  to: rt.config("jobs", "path"),
  exact: true,
};

type SidebarType = {
  activateSubmenu?: string;
  resetActivateSubmenu?: () => void;
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Sidebar: React.FC<SidebarType> = ({
  activateSubmenu,
  resetActivateSubmenu,
}) => {
  const location = useLocation();
  const { isDesktop, isMobile } = useScreenWidth();
  const { getCurrentUserCurrentCompanySettings, doesUserHaveRole } =
    useAuthContext();
  const isBookOnly = doesUserHaveRole(roles.BOOK_ONLY);
  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);

  const userCompanyInfo = getCurrentUserCurrentCompanySettings();
  const bulkJobAllowedRoles = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const [bulkJobsAreHidden, setBulkJobsAreHidden] = useState(true);

  useEffect(() => {
    if (bulkJobAllowedRoles === true) {
      setBulkJobsAreHidden(false);
    }

    if (bulkJobAllowedRoles === false && userCompanyInfo?.canInvoice === true) {
      setBulkJobsAreHidden(false);
    }
  }, [userCompanyInfo, bulkJobAllowedRoles]);

  const sidebarListItems: IMenuItem[] = [
    {
      name: "dashboard",
      nicename: rt.config("dashboard", "nicename"),
      to: rt.config("dashboard", "path"),
      icon: <DashboardIcon className="pointer-events-none relative" />,
      exact: true,
    },
    {
      name: "jobs",
      nicename: rt.config("jobs", "nicename"),
      to: rt.config("jobs", "path"),
      icon: <ViewJobsIcon className="pointer-events-none relative" />,
      submenu: {
        title: rt.config("jobs", "nicename"),
        items: [
          viewJob,
          createJob,
          {
            name: "jobs/book-multiple",
            nicename: rt.config("jobs/book-multiple", "nicename"),
            to: rt.config("jobs/book-multiple", "path"),
            exact: false,
            // only visible for users who have "canInvoice = true", on their company info
            hidden: bulkJobsAreHidden,
          },
          {
            name: "jobs/payment",
            nicename: rt.config("jobs/payment", "nicename"),
            to: rt.config("jobs/payment", "path").replace("/:id?", ""),
            exact: false,
          },
          // The sidebar route to ADHOC jobs has been removed. It can be accessed
          // still via regular book jobs
          // {
          //   name: "jobs/create/adhoc",
          //   nicename: rt.config("jobs/create/adhoc", "nicename"),
          //   to: rt
          //     .config("jobs/create", "path")
          //     .replace("jobs/create", "jobs/create?adhoc=true"),
          //   exact: false,
          // },
          {
            name: "jobs/payment-links",
            nicename: rt.config("jobs/payment-links", "nicename"),
            to: rt.config("jobs/payment-links", "path"),
            exact: false,
          },
          {
            name: "jobs/failed",
            nicename: rt.config("jobs/failed", "nicename"),
            to: rt.config("jobs/failed", "path"),
            exact: false,
          },
          {
            name: "jobs/pending",
            nicename: `${rt.config("jobs/pending", "nicename")}`,
            to: rt.config("jobs/pending", "path"),
            exact: false,
          },
          {
            name: "baskets/pending",
            nicename: `${rt.config("baskets/pending", "nicename")}`,
            to: rt.config("baskets/pending", "path"),
            exact: false,
          },
          {
            name: "jobs/task",
            nicename: `${rt.config("jobs/task", "nicename")}`,
            to: rt.config("jobs/task", "path"),
            exact: false,
          },
          {
            name: "jobs/invoices",
            nicename: `${rt.config("jobs/invoices", "nicename")}`,
            to: rt.config("jobs/invoices", "path"),
            exact: false,
          },
        ],
      },
    },
    // {
    //   name: "quotes",
    //   nicename: rt.config("quotes", "nicename"),
    //   icon: <QuotesIcon className="pointer-events-none relative" />,
    //   to: rt.config("quotes", "path"),
    //   submenu: {
    //     title: rt.config("quotes", "nicename"),
    //     items: [
    //       {
    //         name: "quotes",
    //         nicename: `View ${rt.config("quotes", "nicename")}`,
    //         to: rt.config("quotes", "path"),
    //         exact: false,
    //       },
    //       {
    //         name: "quotes/create",
    //         nicename: rt.config("quotes/create", "nicename"),
    //         to: rt.config("quotes/create", "path"),
    //         exact: false,
    //       },
    //     ],
    //   },
    // },
    {
      name: "subcontractors",
      nicename: rt.config("subcontractors", "nicename"),
      icon: <SubcontractorsIcon className="pointer-events-none relative" />,
      to: rt.config("subcontractors", "path"),
      submenu: {
        title: rt.config("subcontractors", "nicename"),
        items: [
          {
            name: "subcontractors/bulk-allocation",
            nicename: rt.config("subcontractors/bulk-allocation", "nicename"),
            to: rt.config("subcontractors/bulk-allocation", "path"),
            exact: false,
          },
          {
            name: "vehicles",
            nicename: rt.config("vehicles", "nicename"),
            to: rt.config("vehicles", "path"),
            exact: false,
          },
          {
            name: "subcontractors/tips",
            nicename: rt.config("subcontractors/tips", "nicename"),
            to: rt.config("subcontractors/tips", "path"),
            exact: false,
          },
          {
            name: "subcontractors/jobs",
            nicename: `View ${rt.config("subcontractors/jobs", "nicename")}`,
            to: rt.config("subcontractors/jobs", "path"),
            exact: false,
          },
        ],
      },
    },
    {
      name: "users",
      nicename: rt.config("users", "nicename"),
      icon: <UsersIcon className="pointer-events-none relative" />,
      to: rt.config("users", "path"),
      submenu: {
        title: rt.config("users", "nicename"),
        items: [
          {
            name: "users",
            nicename: `View ${rt.config("users", "nicename")}`,
            to: rt.config("users", "path"),
            exact: true,
          },
          {
            name: "users/create",
            nicename: rt.config("users/create", "nicename"),
            to: rt.config("users/create", "path"),
            exact: false,
          },
          {
            name: "permissions",
            nicename: `View ${rt.config("permissions", "nicename")}`,
            to: rt.config("permissions", "path"),
            exact: true,
          },
          {
            name: "roles",
            nicename: `View ${rt.config("roles", "nicename")}`,
            to: rt.config("roles", "path"),
            exact: true,
          },
        ],
      },
    },
    {
      name: "companies",
      nicename: rt.config("companies", "nicename"),
      icon: <CompaniesIcon className="pointer-events-none relative" />,
      to: rt.config("companies", "path"),
      submenu: {
        title: rt.config("companies", "nicename"),
        items: [
          {
            name: "companies",
            nicename: `View ${rt.config("companies", "nicename")}`,
            to: rt.config("companies", "path"),
            exact: true,
          },
          {
            name: "companies/create",
            nicename: rt.config("companies/create", "nicename"),
            to: rt.config("companies/create", "path"),
            exact: false,
          },
          {
            name: "suppliers/prices",
            nicename: rt.config("suppliers/prices", "nicename"),
            to: rt.config("suppliers/prices", "path"),
            exact: false,
          },
          // LORENZO - you can un-comment these to make the sidebar links reappear
          // within the companies section
          // SIDEBAR links to SUPPLIERS are to remain hidden in production for now
          // {
          //   name: "suppliers",
          //   nicename: rt.config("suppliers", "nicename"),
          //   to: rt.config("suppliers", "path"),
          //   exact: false,
          // },
          // {
          //   name: "suppliers/create",
          //   nicename: rt.config("suppliers/create", "nicename"),
          //   to: rt.config("suppliers/create", "path"),
          //   exact: false,
          // },
          // {
          //   name: "suppliers/types",
          //   nicename: rt.config("suppliers/types", "nicename"),
          //   to: rt.config("suppliers/types", "path"),
          //   exact: false,
          // },
          // {
          //   name: "suppliers/types/create",
          //   nicename: rt.config("suppliers/types/create", "nicename"),
          //   to: rt.config("suppliers/types/create", "path"),
          //   exact: false,
          // },
        ],
      },
    },
    {
      name: "catalogues",
      nicename: rt.config("catalogues", "nicename"),
      icon: <CataloguesIcon className="pointer-events-none relative" />,
      to: rt.config("catalogues", "path"),
      submenu: {
        title: rt.config("catalogues", "nicename"),
        items: [
          {
            name: "catalogues",
            nicename: `View ${rt.config("catalogues", "nicename")}`,
            to: rt.config("catalogues", "path"),
            exact: true,
          },
          {
            name: "catalogues/templates",
            nicename: rt.config("catalogues/templates", "nicename"),
            to: rt.config("catalogues/templates", "path"),
            exact: false,
          },
          {
            name: "catalogues/blackout",
            nicename: rt.config("catalogues/blackout", "nicename"),
            to: rt.config("catalogues/blackout", "path"),
            exact: false,
          },
          {
            name: "catalogues/order-type-ids",
            nicename: rt.config("catalogues/order-type-ids", "nicename"),
            to: rt.config("catalogues/order-type-ids", "path"),
            exact: false,
          },
          {
            name: "worksheets",
            nicename: `View ${rt.config("worksheets", "nicename")}`,
            to: rt.config("worksheets", "path"),
            exact: false,
          },
          {
            name: "catalogues/zones",
            nicename: rt.config("catalogues/zones", "nicename"),
            to: rt.config("catalogues/zones", "path"),
            exact: false,
          },
          {
            name: "coupons",
            nicename: rt.config("coupons", "nicename"),
            to: rt.config("coupons", "path"),
            exact: false,
          },
        ],
      },
    },
    {
      name: "reports",
      nicename: rt.config("reports", "nicename"),
      to: rt.config("reports", "path"),
      icon: <LeaderboardIcon className="pointer-events-none relative" />,
      submenu: {
        title: rt.config("reports", "nicename"),
        items: [
          // uncomment when there are data from ms-reports, currently no data
          // {
          //   name: "reports/dashboard",
          //   nicename: rt.config("reports/dashboard", "nicename"),
          //   to: rt.config("reports/dashboard", "path"),
          //   exact: false,
          // },
          {
            name: "reports/leaderboard",
            nicename: rt.config("reports/leaderboard", "nicename"),
            to: rt.config("reports/leaderboard", "path"),
            exact: false,
          },
          {
            name: "reports/statistics",
            nicename: rt.config("reports/statistics", "nicename"),
            to: rt.config("reports/statistics", "path"),
            exact: false,
          },
          {
            name: "reports/scheduled",
            nicename: rt.config("reports/scheduled", "nicename"),
            to: rt.config("reports/scheduled", "path"),
            exact: false,
          },
        ],
      },
    },
  ];

  /**
   * Component state.
   */
  const [submenu, setSubmenu] = useState<ISubMenuItem>();
  const [activeItem, setActiveItem] = useState<string | undefined>();
  const [showSubmenu, setShowSubmenu] = useState(false);

  const [sidebarLinks, setSidebarLinks] = useState(sidebarListItems);

  /**
   * Get active menu item
   */
  const getActiveMenuItem = useCallback(() => {
    let active = sidebarListItems.find((item) => {
      if (item.exact) {
        return location.pathname === item.to;
      } else {
        return location.pathname.indexOf(item.to) !== -1;
      }
    });

    // Also check submenu items to set parent item active
    if (!active) {
      active = sidebarListItems.find((item) => {
        let activeSubitem;
        if (item.submenu) {
          activeSubitem = item.submenu.items.find((subitem) => {
            if (subitem.exact) {
              return location.pathname === subitem.to;
            } else {
              return location.pathname.indexOf(subitem.to) !== -1;
            }
          });
        }
        return activeSubitem;
      });
    }

    return active;
  }, [location.pathname]);

  /**
   * Set initial active sidebar item.
   *
   * - Note we use useLayoutEffect, as we want to set some frontend state
   *   before the component is actually rendered.
   */
  useLayoutEffect(() => {
    // Hide submenu each time location changes
    setShowSubmenu(false);

    const active = getActiveMenuItem();

    if (active) {
      setActiveItem(active.name);
    }
  }, [getActiveMenuItem]);

  /**
   * Show submenu button listener
   */
  const handleShowSubmenu = (name: string) => {
    const active = sidebarListItems.find((item) => {
      return item.name === name;
    });

    // set sub items
    if (active && active.submenu) {
      /**
       * Remove hidden items from the list of submenu items to show
       */
      const nonHiddenSubmenuItems = active.submenu.items.filter(
        (item) => item.hidden !== true,
      );
      const subMenuObjectWithHiddenItemsRemoved = {
        ...active.submenu,
        items: nonHiddenSubmenuItems,
      };

      setSubmenu(subMenuObjectWithHiddenItemsRemoved);
      setActiveItem(active.name);
    }

    setShowSubmenu(true);
  };

  /**
   * Handle hide submenu
   */
  const handleHideSubmenu = () => {
    const active = getActiveMenuItem();

    if (active) {
      setActiveItem(active.name);
    }

    setShowSubmenu(false);
    if (resetActivateSubmenu) {
      resetActivateSubmenu();
    }
  };

  /**
   * If passed active menu, set state.
   */
  useEffect(() => {
    if (activateSubmenu) {
      handleShowSubmenu(activateSubmenu);
    }
  }, [activateSubmenu]);

  /**
   * Run through submenu items.
   */
  useEffect(() => {
    const list = sidebarListItems.map((listItem) => {
      // Filter out any items the user can't view
      const items =
        listItem.submenu?.items.filter((item) =>
          doesUserHaveRole(rt.config(item.name, "permissions")),
        ) || [];

      return {
        ...listItem,
        submenu: listItem.submenu
          ? {
              ...listItem.submenu,
              // Override items array with our perm checked items
              items,
            }
          : undefined,
      };
    });
    setSidebarLinks(list);
  }, [doesUserHaveRole]);

  return (
    <>
      <aside className="sidebar overflow-y-auto fixed right-0 bottom-0 left-0 bg-primary z-50">
        {isDesktop && (
          <RouteLink href="/">
            <Icon.Bee
              color="light"
              styles={{
                ":hover": {
                  color: theme.colors.warning.base,
                  cursor: "pointer",
                },
                transition: "color .5s",
              }}
            />
          </RouteLink>
        )}

        <ul className="sidebar-items w-full flex justify-evenly text-center list-none max-w-xl m-auto p-0 lg:pt-24 lg:block">
          {sidebarLinks.map(
            (item, index) =>
              !item.hidden && (
                <HavePermission
                  key={index}
                  requiredRoles={rt.config(item.name, "permissions")}
                  isMenu={true}
                >
                  <li
                    className={cx(
                      item.name,
                      "sidebar-item relative px-2 py-3 md:py-4 text-white ut-transition lg:px-0 lg:py-1 lg:my-4",
                      { active: activeItem === item.name },
                      { hidden: item.name === "dashboard" && isMobile },
                    )}
                  >
                    {item.submenu && item.submenu.items.length && (
                      <button onClick={() => handleShowSubmenu(item.name)}>
                        <div
                          className="sidebar-link flex-col relative inline-flex items-center justify-center"
                          onClick={() => handleShowSubmenu(item.name)}
                        >
                          {item.icon}
                        </div>
                        <Text
                          styles={{
                            marginTop: theme.spacing.xsmall2,
                            fontWeight: 600,
                          }}
                          fontSize="xsmall2"
                        >
                          {item.nicename || item.name}
                        </Text>
                      </button>
                    )}
                    {!(item.submenu && item.submenu.items.length) &&
                      !item.target && (
                        <button>
                          <Link
                            to={item.to}
                            className="sidebar-link flex-col relative inline-flex items-center justify-center"
                          >
                            {item.icon}
                          </Link>
                          <Text
                            styles={{
                              marginTop: theme.spacing.xsmall2,
                              fontWeight: 600,
                            }}
                            fontSize="xsmall2"
                          >
                            {item.nicename || item.name}
                          </Text>
                        </button>
                      )}
                  </li>
                </HavePermission>
              ),
          )}
        </ul>

        {isDesktop && (
          <>
            <div className="pb-8 text-center hidden lg:block">
              <AccountAvatar />
              <Text
                color="light"
                styles={{
                  marginTop: `-${theme.spacing.xsmall}`,
                  fontWeight: 600,
                }}
                fontSize="xsmall2"
              >
                Account
              </Text>
            </div>
            <Pill color="accent" size="small">
              {version}
            </Pill>
          </>
        )}
      </aside>

      {submenu && (
        <Submenu
          menu={submenu}
          toggle={showSubmenu}
          hideCallback={handleHideSubmenu}
        />
      )}
      {isDesktop && <AccountBubble />}
    </>
  );
};
