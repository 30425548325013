import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Input,
  Modal,
  Panel,
  Table,
  theme,
  Text,
  Icon,
} from "@clearabee/ui-library";
import { INotification } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import * as Yup from "yup";
import { IPaginatedResults, TFilters } from "api/types";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { buildQuery } from "helpers/api";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { useScreenWidth } from "hooks/useScreenWidth";
import { toasts } from "helpers/toasts";
import { SearchIcon } from "images";
import { LoadingOverlay } from "components/common/components";

const initialValues = {
  name: "",
  status: "",
  triggerId: "",
  serviceId: "",
};

const initialResultsPerPage = 10;

export const ReadNotifications = (): React.ReactElement => {
  const [translate] = useTranslation("notifications");
  const [totalData, setTotalData] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] =
    useState<INotification>();

  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const { data: triggers, isLoading: isLoadingTriggers } = useQuery(
    "getTriggers",
    async () => {
      return (await instance.notifications.getTriggers()).data.items;
    },
  );

  const { data: services, isLoading: isLioadingServices } = useQuery(
    "getServices",
    async () => {
      return (await instance.notifications.getServices()).data.items;
    },
  );

  /**
   * Mutation to delete notification
   */

  const {
    mutate: mutateDeleteNotification,
    isLoading: isLoadingMutateDeleteNotification,
  } = useMutation(
    "Delete Notification",
    async (id: string) => {
      await instance.notifications.deleteNotification(id);
    },
    {
      onSuccess: () => {
        setDeleteModalVisible(false);
        refetchNotifications();
        toasts.success({ message: translate("deleteNotification") });
      },
    },
  );

  /**
   *  function to read the notification and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */

  const readNotification = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<INotification>> => {
    const filterParams = new URLSearchParams(filters);

    //Notification Filters
    const name = filterParams?.get("name:like") ?? "";
    const status = filterParams?.get("active:eq") ?? "";
    const triggerId = filterParams?.get("triggerId:eq") ?? "";
    const serviceId = filterParams?.get("actions.serviceId:eq") ?? "";

    const { data } = await instance.notifications.getNotifications({
      params: {
        ...(!!name ? { "name:like": `${name}%` } : {}),
        ...(!!status ? { "active:eq": `${status}` } : {}),
        ...(!!triggerId ? { "triggerId:eq": `${triggerId}` } : {}),
        ...(!!serviceId ? { "actions.serviceId:eq": `${serviceId}` } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<INotification>;
    setTotalData(response?.pagination?.total || 0);

    return response;
  };

  const {
    PaginationComponent,
    paginatedData,
    updateFilters,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResultsPerPage,
    query: {
      isFetching: isFetchingNotifications,
      isLoading: isLoadingNotifications,
      isSuccess: isSuccessNotifications,
      refetch: refetchNotifications,
    },
  } = usePaginatedQuery(
    readNotification,
    "readNotificationPaginated",
    Object.keys(existingQueryParamObject).length === 0
      ? ""
      : buildQuery(existingQueryParamObject),
    {
      resultOptions: [5, 10, 20, 50, 100],
      cacheTime: 0,
      enabled: true,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    //PaginatedStyles
    paginationStyles,
  );

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // Notification filters
    const name = params?.get("name:like") ?? "";
    const status = params?.get("active:eq") ?? "";
    const triggerId = params?.get("triggerId:eq") ?? "";
    const serviceId = params?.get("actions.serviceId:eq") ?? "";

    return {
      name,
      status,
      triggerId,
      serviceId,
    } as typeof initialValues;
  };

  const handleSubmit = (values: typeof initialValues) => {
    const { name, status, triggerId, serviceId } = values;

    const updatedFilterObject = {
      ...(!!name ? { "name:like": name } : {}),
      ...(!!status ? { "active:eq": status } : {}),
      ...(!!triggerId ? { "triggerId:eq": triggerId } : {}),

      ...(!!serviceId ? { "actions.serviceId:eq": serviceId } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/notifications?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* OVERLAYLOADING */}
      {(isFetchingNotifications || isLoadingNotifications) && (
        <LoadingOverlay />
      )}

      <Box className="max-w-screen-lg mx-auto py-10 relative">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
            display: "flex",
            flexDirection: "column",
          }}
          className="flex flex-col lg:flex-row gap-x-6 justify-between"
        >
          <Box className="flex items-center justify-between">
            <Heading fontSize="large" color="brand">
              {translate("heading")} ({totalData})
            </Heading>
            <Button
              type="button"
              size="small"
              onClick={() => history.push("/notifications/create")}
            >
              {translate("buttons.addNotification")}
            </Button>
          </Box>
          <Form
            className="flex flex-col border-t border-grey-200 mt-4 pt-5"
            initialValues={getInitialValues()}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              status: Yup.string(),
              triggerId: Yup.string(),
              serviceId: Yup.string(),
            })}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <>
                <Box className="flex w-full mb-3 gap-4">
                  <Box className="flex-grow">
                    <Field
                      name="name"
                      className="flex-1 min-w-full"
                      label={translate("labels.name")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("labels.name")}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="flex-grow">
                    <Field name="status" label="Status">
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder="Select status"
                          options={[
                            { label: "Active", value: "1" },
                            { label: "Paused", value: "0" },
                          ]}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="flex-grow">
                    <Field label="Trigger" name="triggerId">
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isLoading={isLoadingTriggers}
                          options={triggers?.map((trigger) => ({
                            label: trigger.name,
                            value: String(trigger.id),
                          }))}
                          defaultValue={field.value}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="flex-grow">
                    <Field label="Service" name="serviceId">
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isLoading={isLioadingServices}
                          options={services?.map((service) => ({
                            label: service.name,
                            value: String(service.id),
                          }))}
                          defaultValue={field.value}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Box className="flex justify-end">
                  <Button
                    className="flex items-center relative"
                    size="small"
                    color="negative"
                    type="reset"
                    onClick={() => {
                      resetForm();
                      updateFilters("");
                      setCurrentPage(1);
                      setResultsPerPage(initialResultsPerPage);
                      history.push(
                        `/notifications?page=1&resultsPerPage=${initialResultsPerPage}`,
                      );
                    }}
                  >
                    <SearchIcon className="mr-2" />
                    {translate("buttons.reset")}
                  </Button>
                  <Button
                    className="md:ml-6 flex items-center relative"
                    size="small"
                    color="accent"
                    type="submit"
                  >
                    <SearchIcon className="mr-2" />
                    {translate("buttons.search")}
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </Panel>
        <Box className="relative flex flex-1">
          {!!paginatedData.length && !!isSuccessNotifications && (
            <Box>
              <Table
                className="mt-10"
                styles={{
                  tableLayout: "fixed",
                  "th:last-of-type > p": {
                    textAlign: "center",
                  },
                }}
              >
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "35%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <Table.Header
                  fontSize={isDesktop ? "xsmall" : "xsmall2"}
                  headings={[
                    translate("table.status"),
                    translate("table.name"),
                    translate("table.trigger"),
                    translate("table.actions"),
                  ]}
                  styles={{ textAlign: "center" }}
                />
                <Table.Body>
                  {paginatedData?.map((item) => (
                    <Table.Row key={`table-row-${item.id}`}>
                      <Table.Cell>
                        <Box className="w-2/3">
                          {item.active ? (
                            <Box
                              className="rounded-3xl flex font-semibold py-2 justify-center"
                              backgroundColor="positive"
                              color="light"
                            >
                              <Text fontSize="xsmall">
                                {translate("labels.active")}
                              </Text>
                            </Box>
                          ) : (
                            <Box
                              backgroundColor="warning"
                              className="rounded-3xl flex font-semibold py-2 justify-center"
                            >
                              <Text fontSize="xsmall">
                                {translate("labels.paused")}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </Table.Cell>
                      <Table.Cell.Text>{item.name}</Table.Cell.Text>
                      <Table.Cell.Text>
                        {!!triggers?.length
                          ? triggers?.find(
                              (trigger) => trigger.id === item.triggerId,
                            )?.name
                          : item.triggerId}
                      </Table.Cell.Text>
                      <Table.Cell>
                        <Box className="flex justify-center gap-x-2">
                          <Button
                            size="xsmall"
                            type="button"
                            className="text-center"
                            onClick={() =>
                              history.push(`/notifications/update/${item.id}`)
                            }
                          >
                            {translate("buttons.edit")}
                          </Button>
                          <Button
                            size="xsmall"
                            type="button"
                            color="negative"
                            onClick={() => {
                              setSelectedNotification(item);
                              setDeleteModalVisible(true);
                            }}
                            className="text-center"
                          >
                            {translate("buttons.delete")}
                          </Button>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Box className="mt-10 flex justify-center">
                <PaginationComponent />
              </Box>
            </Box>
          )}
          {!paginatedData?.length && !!isSuccessNotifications && (
            <Box className="w-full flex justify-center mt-8">
              <Heading level={4}>{translate("noData")}</Heading>
            </Box>
          )}
        </Box>
      </Box>

      {/* Confirm Delete notification modal */}
      <Modal
        width={450}
        onClose={() => setDeleteModalVisible(false)}
        modalVisible={deleteModalVisible}
        closeButtonStyles={{
          top: 20,
          right: 20,
        }}
        styles={{
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.small,
          },
        }}
      >
        <Box className="mt-4 px-12">
          <Heading level={4} color="brand" fontSize="base">
            {translate("confirmDelete")}{" "}
            <Text color="negative" as="span" fontSize="base">
              &quot;{selectedNotification?.name}&quot;
            </Text>
            ?
          </Heading>
        </Box>
        <Box className="flex mt-6 justify-center gap-4">
          <Button
            onClick={() =>
              !!selectedNotification &&
              mutateDeleteNotification(String(selectedNotification.id))
            }
            color="negative"
            className="flex justify-center"
            disabled={isLoadingMutateDeleteNotification}
            size="small"
          >
            {isLoadingMutateDeleteNotification ? (
              <Icon.Loading size="small" />
            ) : (
              translate("buttons.delete")
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
