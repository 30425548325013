import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "helpers/toasts";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Modal,
} from "@clearabee/ui-library";
import {
  addFormInitialValues as initialValues,
  addFormSchema as validationSchema,
} from "../validation";

interface AddSupplierPriceModalProps {
  refetchData: () => void;
}
export const AddSupplierPriceModal = ({
  refetchData,
}: AddSupplierPriceModalProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { mutate, isLoading } = useMutation(
    "AddSupplierPriceRecord",
    async ({ supplierRef, sku, postcode, price }: typeof initialValues) => {
      if (!supplierRef || !sku || !postcode) return;

      await instance.catalogues.putSupplierPrice(
        supplierRef,
        sku,
        postcode.replace(/\s/g, ""),
        {
          price: Number(price),
        },
      );
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
        refetchData();
        toasts.success({
          message: translate("supplierPrices.success.add"),
        });
      },
      onError: () => {
        toasts.error({
          message: translate("supplierPrices.errors.add"),
        });
      },
    },
  );

  return (
    <>
      {/* ADD */}
      <Button
        size="small"
        type="button"
        color="warning"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {translate("supplierPrices.buttons.add")}
      </Button>
      {isModalOpen && (
        <Modal
          width={600}
          styles={{
            textAlign: "left",
          }}
          onClose={() => setIsModalOpen(false)}
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({}) => (
              <>
                <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                  <Heading
                    level={5}
                    color="brand"
                    className="pr-4 w-full sm:w-auto"
                  >
                    {translate("supplierPrices.headings.newSupplierPrice")}
                  </Heading>
                </Box>

                {/* Supplier Ref */}
                <Field
                  styles={{ flex: 1 }}
                  name="supplierRef"
                  label={translate("supplierPrices.form.labels.supplierRef")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "supplierPrices.form.placeholders.supplierRef",
                      )}
                    />
                  )}
                </Field>
                {/* Supplier Sku */}
                <Field
                  styles={{ flex: 1 }}
                  name="sku"
                  label={translate("supplierPrices.form.labels.sku")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "supplierPrices.form.placeholders.sku",
                      )}
                    />
                  )}
                </Field>
                {/* POSTCODE */}
                <Field
                  styles={{ flex: 1 }}
                  name="postcode"
                  label={translate("supplierPrices.form.labels.postcode")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "supplierPrices.form.placeholders.postcode",
                      )}
                    />
                  )}
                </Field>
                {/* PRICE */}
                <Field
                  styles={{ flex: 1 }}
                  name="price"
                  label={translate("supplierPrices.form.labels.price")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "supplierPrices.form.placeholders.price",
                      )}
                    />
                  )}
                </Field>
                <Box className="flex justify-end mt-5">
                  {/* SUMBIT */}
                  <Button
                    size="small"
                    color="accent"
                    type="submit"
                    disabled={isLoading}
                    className={isLoading ? "flex items-center gap-x-2" : ""}
                  >
                    {translate("supplierPrices.buttons.submit")}
                    {isLoading && <Icon.Loading size="small" />}
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
