import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IUser, IUserAttribute } from "@clearabee/api-schemas";
import {
  Heading,
  Button,
  Box,
  Modal,
  Field,
  Panel,
  Form,
  Input,
  Table,
  Icon,
  theme,
  Text,
  UnstyledButton,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { LoadingOverlay } from "components/common/components";
import { initialValues, validationSchema } from "./validation";

/**
 * Find Attribute value by key
 */
const findAttribute = (
  attributes: IUserAttribute[] | undefined,
  key: string,
): string => {
  return String(
    attributes?.find(({ attrKey }) => attrKey === key)?.attrValue || "",
  );
};

const constantAttributesKeys = {
  slackId: "slackId",
  slackChannelId: "slackChannelId",
  weekendRotation: "weekendRotation",
  asset: "asset",
};

export const DriverPreferences = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [filter, setFilter] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IUser>();
  const [showModalSyncData, setShowModalSyncData] = useState(false);
  const [getUserSlackRooms, setGetUserSlackRooms] = useState(false);

  /**
   * Get Modal Initial Values
   */
  const getModalInitialValues = (
    selectedDriver: IUser,
  ): typeof initialValues => {
    const slackId = findAttribute(selectedDriver.attributes, "slackId");

    const slackChannelId = findAttribute(
      selectedDriver.attributes,
      "slackChannelId",
    );

    const weekendRotation = findAttribute(
      selectedDriver.attributes,
      "weekendRotation",
    );

    const asset = findAttribute(selectedDriver.attributes, "asset");

    return {
      ...initialValues,
      phoneNumber: selectedDriver?.phoneNumber || "",
      slackId,
      slackChannelId,
      weekendRotation,
      asset,
    };
  };

  /**
   * Drivers Query
   */
  const {
    data: drivers,
    refetch: refetchDrivers,
    isLoading: isLoadingDrivers,
    isRefetching: isRefetchingDrivers,
  } = useQuery(
    "getDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver",
            limit: 1000,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: () => {
        toasts.error({
          message: translate("preferences.errors.fetchDrivers"),
        });
      },
    },
  );

  /**
   * Get Slack Rooms
   */
  const {
    data: getSlackRooms,
    refetch: refetchSlackRooms,
    isLoading: isLoadingSlackRooms,
    isRefetching: isRefetchingSlackRooms,
  } = useQuery(
    ["getDrivers", getUserSlackRooms],
    async () =>
      await instance.users.getUserSlackChannels(selectedDriver?.email || ""),
    {
      retry: false,
      enabled: getUserSlackRooms,
      cacheTime: 0,
      onError: () => {
        toasts.error({
          message: translate("Failed to get slack rooms"),
        });
      },
    },
  );

  /**
   * Vehicles Query
   */
  const { data: vehicles, isLoading: isLoadingVehicles } = useQuery(
    "getVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            limit: 10000,
            orderBy: "name",
            "company_code:emptyOrNull": "true",
          },
        })
      ).data.items,
    {
      retry: false,
      onError: () => {
        toasts.error({
          message: translate("preferences.errors.fetchVehicles"),
        });
      },
    },
  );

  /**
   * Vehicle Options
   */
  const vehicleOptions = useMemo(() => {
    if (!vehicles) return [];

    return vehicles.map(({ name, registration }) => ({
      value: registration,
      label: name || registration,
    }));
  }, [vehicles]);

  /**
   * Update Driver Mutation
   */
  const { mutate: updateDriver, isLoading: isLoadingUpdateDriver } =
    useMutation(
      "updateVehicle",
      async (values: typeof initialValues) => {
        if (!selectedDriver) return;

        const { phoneNumber, ...rest } = values;

        const attributeObject: Record<string, string> = {};

        selectedDriver?.attributes?.map(({ attrKey, attrValue }) => {
          attributeObject[attrKey] = attrValue;
        });

        Object.entries(rest).map(([key, value]) => {
          if (!!value) {
            attributeObject[key] = value;
            return;
          }

          delete attributeObject[key];
        });

        const updatedAttributes: IUserAttribute[] = Object.entries(
          attributeObject,
        ).map(([attrKey, attrValue]) => ({
          attrKey,
          attrValue,
          userId: selectedDriver.id || 0,
        }));

        return (
          await instance.users.patchUser(selectedDriver.email, {
            phoneNumber,
            attributes: updatedAttributes,
          })
        ).data as IUser;
      },
      {
        onSuccess: (data) => {
          setSelectedDriver(data);
          refetchDrivers();
          toasts.success({
            message: translate("preferences.success.updateDriver"),
          });
        },
        onError: () => {
          toasts.error({
            message: translate("preferences.errors.updateDriver"),
          });
        },
      },
    );

  /**
   * Sync Data Mutation
   */
  const { mutate: mutateSyncData, isLoading: isLoadingSyncData } = useMutation(
    "syncData",
    async (emailArray: string[]) => {
      const emails = !!emailArray.length ? emailArray.join(",") : undefined;

      await instance.users.postDriverSlackSync({
        emails,
      });
    },
    {
      onSuccess: () => {
        setShowModalSyncData(false);
        toasts.success({
          message: translate("preferences.success.syncData"),
        });
        setShowModalSyncData(false);
        refetchDrivers();
      },
      onError: () => {
        toasts.error({
          message: translate("preferences.errors.syncData"),
        });
      },
    },
  );

  /**
   * Remove Slack Room Mutation
   */
  const { mutate: removeSlackRoom, isLoading: removeSlackRoomIsLoading } =
    useMutation(
      "removeSlackRoom",
      async (body: { userEmail: string; slackChannelId: string }) => {
        await instance.users.deleteUserSlackChannel(
          body.userEmail,
          body.slackChannelId,
        );
      },
      {
        onSuccess: () => {
          setShowModalSyncData(false);
          toasts.success({
            message: translate("preferences.success.slackRoomRemoved"),
          });
          refetchSlackRooms();
        },
        onError: () => {
          toasts.error({
            message: translate("preferences.errors.slackRoomRemoved"),
          });
        },
      },
    );

  return (
    <>
      {/* MAIN LOADING OVERLAY*/}
      {(isLoadingDrivers ||
        isRefetchingDrivers ||
        isLoadingUpdateDriver ||
        isLoadingVehicles) && <LoadingOverlay />}

      <Box className="max-w-screen-xl mx-auto py-10 relative">
        {/* FILTER FORM */}
        <Panel shadow={false} className="mb-10">
          <Box className="flex justify-between items-center border-b border-grey-200 mb-5 pb-4">
            <Heading color="brand" level={1} fontSize="large">
              {translate("preferences.headings.preferences")}
            </Heading>
            <Button
              size="small"
              color="accent"
              type="button"
              onClick={() => setShowModalSyncData(true)}
            >
              {translate("preferences.buttons.syncSlackChannel")}
            </Button>
          </Box>

          <Box className="flex w-1/4">
            <Input.Text
              placeholder={translate(
                "preferences.form.placeholders.filterDrivers",
              )}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Box>
        </Panel>

        {/* RESULTS TABLE */}
        {!!drivers && !!drivers.length && (
          <Panel shadow={false}>
            <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <Table.Header
                fontSize="small"
                headings={[
                  translate("preferences.table.headings.name"),
                  translate("preferences.table.headings.phoneNumber"),
                  translate("preferences.table.headings.asset"),
                  translate("preferences.table.headings.weekendRotation"),
                  translate("preferences.table.headings.slackId"),
                  translate("preferences.table.headings.slackChannelId"),
                  translate("preferences.table.headings.actions"),
                ]}
              />

              <Table.Body>
                {drivers
                  .filter(({ firstName, lastName }) =>
                    `${firstName} ${lastName}`
                      .toLowerCase()
                      .includes(filter.toLowerCase()),
                  )
                  .map((driver) => (
                    <Table.Row key={`table-row-${driver.id}`}>
                      <Table.Cell.Text className="truncate">
                        {`${driver.firstName} ${driver.lastName}`}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {driver.phoneNumber || "N/A"}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {findAttribute(
                          driver.attributes,
                          constantAttributesKeys.asset,
                        ) || "N/A"}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate pl-12">
                        {findAttribute(
                          driver.attributes,
                          constantAttributesKeys.weekendRotation,
                        ) || "N/A"}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {findAttribute(
                          driver.attributes,
                          constantAttributesKeys.slackId,
                        ) || "N/A"}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {findAttribute(
                          driver.attributes,
                          constantAttributesKeys.slackChannelId,
                        ) || "N/A"}
                      </Table.Cell.Text>

                      <Table.Cell className="flex flex-row gap-3 w-28">
                        <Button
                          size="xsmall"
                          type="button"
                          onClick={() => setSelectedDriver(driver)}
                        >
                          {translate("preferences.buttons.edit")}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Panel>
        )}

        {/* NO RESULTS */}
        {!!drivers && !drivers.length && (
          <Box className="flex justify-center mt-12">
            <Heading color="brand" level={3}>
              {translate("preferences.errors.noDrivers")}
            </Heading>
          </Box>
        )}
      </Box>

      {/* EDIT VEHICLE MODAL */}
      {!!selectedDriver && (
        <Modal
          onClose={() => {
            setSelectedDriver(undefined);
            setGetUserSlackRooms(false);
          }}
          width={600}
          styles={{
            padding: `${theme.spacing.large} ${theme.spacing.large}`,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.large} ${theme.spacing.large}`,
            },
          }}
        >
          <Form
            validationSchema={validationSchema}
            initialValues={getModalInitialValues(selectedDriver)}
            enableReinitialize
            onSubmit={(values) => updateDriver(values)}
          >
            <Box className="text-left flex flex-col gap-y-1 overflow-y-scroll">
              {/* MODAL LOADING OVERLAY */}
              {isLoadingUpdateDriver && (
                <LoadingOverlay
                  backgroundLoadingContainerStyles={{
                    borderRadius: theme.spacing.small,
                  }}
                />
              )}
              {/* MODAL HEADING */}
              <Heading color="brand" level={4} className="truncate">
                {translate("preferences.headings.update", {
                  name: `${selectedDriver.firstName} ${selectedDriver.lastName}`,
                })}
              </Heading>
              {/* PHONE NUMBER FIELD */}
              <Field
                name="phoneNumber"
                label={translate("preferences.form.labels.phoneNumber")}
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "preferences.form.placeholders.phoneNumber",
                    )}
                  />
                )}
              </Field>
              {/* ASSET */}
              <Field
                name="asset"
                label={translate("preferences.form.labels.asset")}
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isSearchable
                    isClearable
                    options={vehicleOptions}
                    placeholder={translate(
                      "preferences.form.placeholders.asset",
                    )}
                  />
                )}
              </Field>
              {/* WEEKEND ROTATION FIELD */}
              <Field
                name="weekendRotation"
                label={translate("preferences.form.labels.weekendRotation")}
                styles={{
                  marginBottom: theme.spacing.medium,
                }}
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isSearchable
                    isClearable
                    placeholder={translate(
                      "preferences.form.placeholders.weekendRotation",
                    )}
                    options={[
                      {
                        value: "1",
                        label: "One",
                      },
                      {
                        value: "2",
                        label: "Two",
                      },
                    ]}
                  />
                )}
              </Field>

              {/* GET SLACK ROOMS */}
              <Box className="flex flex-row items-center justify-start mt-1 gap-x-3">
                <Text className="font-bold" fontSize="small">
                  Slack Rooms
                </Text>

                {(isLoadingSlackRooms ||
                  removeSlackRoomIsLoading ||
                  isRefetchingSlackRooms) && <Icon.Loading size="small" />}
              </Box>

              <Box className="flex flex-col mb-3 max-h-48 overflow-y-scroll">
                {!getSlackRooms && (
                  <Box>
                    <Button
                      onClick={() => setGetUserSlackRooms(true)}
                      disabled={isLoadingSlackRooms || isRefetchingSlackRooms}
                      size="xsmall"
                      type="button"
                    >
                      Get slack rooms
                    </Button>
                  </Box>
                )}

                {!!getSlackRooms &&
                  !isLoadingSlackRooms &&
                  !isRefetchingSlackRooms &&
                  typeof getSlackRooms?.data?.length == "number" && (
                    <Box className="flex flex-col gap-y-1 mt-1">
                      {getSlackRooms?.data?.map((room) => (
                        <Box
                          key={room.id}
                          className="flex flex-row items-center justify-between bg-gray-200 mb-2 p-5 rounded-md"
                        >
                          <Text fontSize="small">{room.name}</Text>
                          <Button
                            size="xsmall"
                            color="negative"
                            type="button"
                            disabled={removeSlackRoomIsLoading}
                            onClick={() =>
                              removeSlackRoom({
                                userEmail: selectedDriver?.email || "",
                                slackChannelId: room.id,
                              })
                            }
                          >
                            remove
                          </Button>
                        </Box>
                      )) || "Something went wrong"}
                    </Box>
                  )}

                {getSlackRooms && !getSlackRooms?.data?.length && (
                  <Text className="font-semibold" fontSize="small">
                    No slack rooms found
                  </Text>
                )}
              </Box>

              {/* SLACK ID */}
              <Field
                name="slackId"
                label={translate("preferences.form.labels.slackId")}
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "preferences.form.placeholders.slackId",
                    )}
                  />
                )}
              </Field>
              {/* SLACK CHANNEL ID */}
              <Field
                name="slackChannelId"
                label={translate("preferences.form.labels.slackChannelId")}
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "preferences.form.placeholders.slackChannelId",
                    )}
                  />
                )}
              </Field>

              {/* SUBMIT */}
              <Box className="flex mt-3 justify-center">
                <Button size="small" color="accent" type="submit">
                  <Box className="flex justify-center items-center gap-x-2">
                    {translate("preferences.buttons.save")}
                    {isLoadingDrivers && <Icon.Loading size="small" />}
                  </Box>
                </Button>
              </Box>
            </Box>
          </Form>
        </Modal>
      )}
      {/* SYNC DATA MODAL */}
      {!!showModalSyncData && (
        <Modal
          onClose={() => {
            setShowModalSyncData(false);
            setEmails([]);
          }}
          width={600}
          styles={{
            padding: `${theme.spacing.large} ${theme.spacing.large}`,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.large} ${theme.spacing.large}`,
            },
          }}
        >
          <Form
            initialValues={{ email: "" }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(),
            })}
            onSubmit={() => {
              return;
            }}
          >
            {({ values, isValid, resetForm }) => (
              <Box className="flex flex-col gap-y-8 my-5">
                {/* MODAL LOADING OVERLAY */}
                {isLoadingSyncData && (
                  <LoadingOverlay
                    iconSize="xlarge2"
                    backgroundLoadingContainerStyles={{
                      borderRadius: theme.spacing.small,
                    }}
                  />
                )}
                <Heading level={4} color="brand">
                  {translate("preferences.headings.areYouSure")}
                </Heading>
                <Box className="w-full flex flex-col justify-center gap-y-2 px-6">
                  <Text className="font-medium" fontSize="small">
                    {translate("preferences.copy1")}
                  </Text>
                  <Text className="font-medium" fontSize="small">
                    {translate("preferences.copy2")}
                  </Text>
                </Box>
                <Box className="flex items-center gap-x-2 px-8">
                  <Field
                    styles={{
                      flex: 1,
                      margin: 0,
                    }}
                    name="email"
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "preferences.form.placeholders.email",
                        )}
                      />
                    )}
                  </Field>
                  <Button
                    type="button"
                    size="small"
                    color="warning"
                    disabled={!isValid || !values.email}
                    onClick={() => {
                      resetForm();

                      if (emails.includes(values.email)) return;

                      setEmails([...emails, values.email]);
                    }}
                  >
                    {translate("preferences.buttons.add")}
                  </Button>
                </Box>
                {!!emails.length && (
                  <Box className="px-8 overflow-y-scroll max-h-56">
                    {emails.map((email) => (
                      <Box
                        key={email}
                        className="flex flex-row items-center justify-between bg-gray-200 mb-2 p-4 rounded-md"
                      >
                        <Text>{email}</Text>
                        <Button
                          type="button"
                          size="xsmall"
                          color="negative"
                          onClick={() =>
                            setEmails(emails.filter((e) => e !== email))
                          }
                        >
                          {translate("preferences.buttons.remove")}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box className="flex justify-center items-center gap-x-2 mt-5">
                  <Button
                    color="negative"
                    type="button"
                    size="small"
                    onClick={() => {
                      setShowModalSyncData(false);
                      setEmails([]);
                    }}
                  >
                    {translate("preferences.buttons.cancel")}
                  </Button>
                  <Button
                    size="small"
                    type="button"
                    disabled={!!values.email}
                    onClick={() => mutateSyncData(emails)}
                  >
                    {translate("preferences.buttons.confirm")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
