import React from "react";
import { useMutation } from "react-query";
import {
  Form,
  Field,
  Input,
  Box,
  Button,
  Icon,
  Message,
} from "@clearabee/ui-library";
import { camelToCapital, instance } from "@clearabee/ui-sdk";
import { IReportJob, IReportJobPatchBody } from "@clearabee/api-schemas";
import { reportValidationSchema } from "./reportDetailsValidation";
import { getErrorMessage } from "helpers";

interface ReportDetailsRowProps {
  report: IReportJob;
}

const convertToNumber = (value: string | number | null) => {
  if (value === "" || value === null) return null;
  return Number(value);
};

const disabledFields = [
  "purchaseOrderNumber",
  "invoiceNumber",
  "invoiceAmount",
  "creditAmount",
];

export const ReportDetailsRow = ({
  report,
}: ReportDetailsRowProps): React.ReactElement => {
  const initialValues = {
    purchaseOrderNumber: report.purchaseOrderNumber || "",
    invoiceNumber: report.invoiceNumber || "",
    invoiceAmount: report.invoiceAmount || 0,
    creditAmount: report.creditAmount || 0,
    weight: report.weight,
    volume: report.volume,
    mattress: report.mattress,
    fridges: report.fridges,
    tvMonitor: report.tvMonitor,
    smallPops: report.smallPops,
    largePops: report.largePops,
    footstool: report.footstool,
    armchair: report.armchair,
    sofaTwoSeat: report.sofaTwoSeat,
    sofaThreeSeat: report.sofaThreeSeat,
    bed: report.bed,
    sofabed: report.sofabed,
    cornerSofa: report.cornerSofa,
    otherItems: report.otherItems,
    description: report.description || "",
    lineItem: report.lineItem || "",
  };

  const filteredInitialValues = Object.entries(initialValues).filter(
    ([key, value]) => value !== null && value !== undefined,
  );

  const {
    isLoading: isLoadingPutJob,
    mutate: mutatePutJob,
    isSuccess: isSuccessPutJob,
    isError: isErrorPutJob,
    error: errorPutJob,
  } = useMutation(
    async (values: IReportJobPatchBody) =>
      await instance.reports.patchJob(String(report.id), values),
  );

  return (
    <Form
      initialValues={initialValues}
      validationSchema={reportValidationSchema}
      onSubmit={(values) =>
        mutatePutJob({
          description: values.description,
          lineItem: values.lineItem,
          volume: convertToNumber(values.volume),
          weight: convertToNumber(values.weight),
          armchair: convertToNumber(values.armchair),
          bed: convertToNumber(values.bed),
          cornerSofa: convertToNumber(values.cornerSofa),
          footstool: convertToNumber(values.footstool),
          fridges: convertToNumber(values.fridges),
          largePops: convertToNumber(values.largePops),
          mattress: convertToNumber(values.mattress),
          otherItems: convertToNumber(values.otherItems),
          sofabed: convertToNumber(values.sofabed),
          smallPops: convertToNumber(values.smallPops),
          sofaTwoSeat: convertToNumber(values.sofaTwoSeat),
          tvMonitor: convertToNumber(values.tvMonitor),
          sofaThreeSeat: convertToNumber(values.sofaThreeSeat),
        })
      }
    >
      <Box className="flex flex-wrap py-2 px-5 bg-gray-100 my-6 rounded-md mb-2">
        {/* This is map to display all report props with the value */}
        {filteredInitialValues.map(([key, value], index) => (
          <Box className="w-1/4 px-2" key={`${key}-${index}`}>
            <Field name={key} label={camelToCapital(key)}>
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={disabledFields.includes(key)}
                />
              )}
            </Field>
          </Box>
        ))}
        <Box
          className={`flex w-full ${
            isErrorPutJob || isSuccessPutJob ? "justify-between" : "justify-end"
          } mb-4 items-center`}
        >
          {isSuccessPutJob && (
            <Message className="mt-1" type="success" background>
              Updated successfully
            </Message>
          )}

          {isErrorPutJob && (
            <Message className="mt-1" type="error" background>
              {getErrorMessage(errorPutJob)}
            </Message>
          )}

          <Box>
            <Button
              color="accent"
              type="submit"
              disabled={isLoadingPutJob}
              size="small"
              className="flex justify-center items-center"
            >
              {!isLoadingPutJob ? "Submit" : <Icon.Loading size="small" />}
            </Button>
          </Box>
        </Box>
      </Box>
    </Form>
  );
};
