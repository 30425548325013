import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Panel,
  Table,
  Text,
  Box,
  Icon,
  Heading,
} from "@clearabee/ui-library";
import { CompanyFilters } from "./components";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { buildQuery } from "helpers/api";
import { IPaginatedResults, TFilters } from "api/types";
import { ICompany } from "@clearabee/api-schemas";

export const ReadCompanies = (): React.ReactElement => {
  const [translate] = useTranslation("companies");
  const location = useLocation();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const readCompanies = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<ICompany>> => {
    const filterParams = new URLSearchParams(filters);

    //company filters
    const name = filterParams?.get("name:like") ?? "";
    const active = filterParams?.get("active:eq") ?? "";
    const type = filterParams?.get("type:in") ?? "";
    const companyCode = filterParams?.get("companyCode:like") ?? "";
    const contactEmail = filterParams?.get("contactEmail:like") ?? "";

    const { data } = await axios.get("/companies", {
      params: {
        ...(!!name ? { "name:like": `%${name}%` } : {}),
        ...(!!active ? { "active:eq": `${active}` } : {}),
        ...(!!type ? { "type:in": `${type}` } : {}),
        ...(!!companyCode ? { "companyCode:like": `%${companyCode}%` } : {}),
        ...(!!contactEmail ? { "contactEmail:like": `%${contactEmail}%` } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<ICompany>;

    return response;
  };

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    setCurrentPage,
    currentPage,
    setResultsPerPage,
    query: { isFetching, isLoading },
    resultsPerPage,
  } = usePaginatedQuery(
    readCompanies,
    "readCompanies",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : 10,
    },
    paginationStyles,
  );

  return (
    <Box
      data-testid="readCompaniesComponentIdentifier"
      className="max-w-screen-lg mx-auto"
    >
      <Panel className="mb-8">
        <CompanyFilters
          isFetching={isFetching}
          updateFilters={updateFilters}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      </Panel>
      {(isLoading || isFetching) && (
        <Box className="flex justify-center">
          <Icon.Loading color="brand" />
        </Box>
      )}
      {!!paginatedData.length && !isLoading && !isFetching && (
        <>
          <Table className="mb-5">
            <Table.Header
              headings={[
                translate("table.headings.status"),
                translate("table.headings.name"),
                translate("table.headings.contactEmail"),
                translate("table.headings.companyCode"),
                translate("table.headings.type"),
                translate("table.headings.actions"),
              ]}
            />
            <Table.Body>
              {paginatedData.map((company) => (
                <Table.Row key={company.id}>
                  <Table.Cell>
                    <Box className="flex">
                      {!!company.active ? (
                        <Box
                          className="flex px-2 py-1 rounded-xl"
                          color="light"
                          backgroundColor="positive"
                        >
                          <Text fontSize="xsmall">Active</Text>
                        </Box>
                      ) : (
                        <Box
                          className="flex px-2 py-1 rounded-xl"
                          color="light"
                          backgroundColor="negative"
                        >
                          <Text fontSize="xsmall">Inactive</Text>
                        </Box>
                      )}
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">{company.name}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">{company.contactEmail}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">{company.companyCode}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">{company.type}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/companies/update/${company.id}`}>
                      <Button
                        variant="outline"
                        className="px-2"
                        size="xsmall"
                        type="button"
                      >
                        {translate("table.headings.edit")}
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Box className="flex justify-center">
            <PaginationComponent />
          </Box>
        </>
      )}
      {!paginatedData.length && !isLoading && !isFetching && (
        <Heading styles={{ textAlign: "center" }} level={2} fontSize="large">
          {translate("table.noResults")}
        </Heading>
      )}
    </Box>
  );
};
