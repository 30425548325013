import { Loader, Button, formatCurrency, Text } from "@clearabee/ui-library";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useAuthContext,
  useBasketContext,
  useMultiFormContext,
} from "../../../../hooks";

export const PaymentOrderDetails = (): React.ReactElement => {
  const { items } = useBasketContext();
  const {
    setStep,
    isLoading,
    formState: { timeslotCharge, customTimeslot },
  } = useMultiFormContext();
  const [translate] = useTranslation("jobs");
  const { getCurrentUserCurrentCompanySettings } = useAuthContext();
  const companySettings = getCurrentUserCurrentCompanySettings();

  return (
    <>
      <div className="relative text-white">
        <div className="absolute top-0 right-0">
          <span className="inline-block">
            <Button
              type="button"
              color="warning"
              size="xsmall"
              onClick={() => setStep(1)}
            >
              {translate("buttons.labels.edit")}
            </Button>
          </span>
        </div>
        <h4 className="text-xl font-bold">
          {translate("create.orderSummary.titles.order")}
        </h4>
        <div className="flex flex-row items-center text-2xs py-3">
          <div className="order-summary-qty w-10 pr-2">
            {translate("create.orderSummary.titles.quantity")}
          </div>
          <div className="w-full">
            {translate("create.orderSummary.titles.product")}
          </div>
          {!companySettings?.hidePrices && (
            <div className="order-summary-price text-right">
              {translate("create.orderSummary.titles.price")}
            </div>
          )}
        </div>
      </div>
      <div className="text-white">
        {isLoading && <Loader text={translate("common:loading2")} />}
        {!isLoading &&
          items.map((item) => {
            const { title, quantity, price, sku } = item;
            return (
              <div key={`basket-${sku}`} className="flex mb-2 text-xs pt-2">
                <div className="order-summary-qty w-10 pr-2">{`x${quantity}`}</div>
                <div className="w-full truncate">{title}</div>
                {!companySettings?.hidePrices && (
                  <div className="order-summary-price text-right">
                    {`£${(price || 0).toFixed(2)}`}
                    <div className="mt-1 opacity-75 text-right">each</div>
                  </div>
                )}
              </div>
            );
          })}
        {!isLoading && !!timeslotCharge && (
          <Text color="light" fontSize="xsmall" styles={{ fontWeight: "bold" }}>
            {translate("create.orderSummary.timeslotCharge")}&nbsp;
            {formatCurrency(timeslotCharge)}
          </Text>
        )}
        {!isLoading && !!customTimeslot && !!customTimeslot.charge && (
          <Text color="light" fontSize="xsmall" styles={{ fontWeight: "bold" }}>
            {translate("create.orderSummary.timeslotCharge")}&nbsp;
            {formatCurrency(customTimeslot.charge)}
          </Text>
        )}
      </div>
    </>
  );
};
