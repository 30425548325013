import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useFormikContext } from "formik";
import {
  Box,
  displayErrorMessage,
  Field,
  Icon,
  Input,
  Message,
  Panel,
  theme,
  UnstyledButton,
  Text,
} from "@clearabee/ui-library";
import { InputInternationalPhoneNumber } from "components/common/components";
import {
  OptionForSelect,
  parseCompaniesDataForSelect,
  parseRolesDataForSelect,
} from "components/users/parser";
import { createUser } from "api";
import {
  createUserObject,
  ICreateUserFormState,
} from "components/users/validation";
import { useAuthContext } from "hooks";
import { IUserRole, ICompany } from "api/types";
import { isValidInternationalNumber } from "helpers";

interface CreateUserRowProps {
  index: number;
  rolesData: IUserRole[];
  companiesData: ICompany[];
  isLoadingRoles: boolean;
  isLoadingCompanies: boolean;
  formSubmitted: boolean;
  isClearabeeAdmin: boolean;
  onRemove: (index: number) => void;
}

export const CreateUserRow = ({
  index,
  rolesData,
  companiesData,
  isLoadingRoles,
  isLoadingCompanies,
  formSubmitted,
  isClearabeeAdmin,
  onRemove,
}: CreateUserRowProps): React.ReactElement => {
  const [t] = useTranslation("users");
  const { getCurrentUserCompanies } = useAuthContext();
  const { setFieldValue, values } = useFormikContext<ICreateUserFormState>();

  /**
   * Create user on submit.
   */
  const {
    mutate: createUserMutation,
    isLoading: isLoadingCreateUser,
    error: errorsCreateUser,
    isError: isErrorCreateUser,
    reset: resetCreateUser,
    isSuccess,
  } = useMutation(createUser);

  const companiesOptions = companiesData
    ? parseCompaniesDataForSelect(
        isClearabeeAdmin ? companiesData : getCurrentUserCompanies(),
      )
    : [];
  const companiesChoices = companiesOptions && companiesOptions.length > 0;
  const rolesOptions = parseRolesDataForSelect(
    rolesData || [],
    isClearabeeAdmin ? true : false,
  );
  const rolesChoices = rolesOptions?.length > 0;
  const selectedUser = values.user[index];

  const handleSubmit = () => {
    createUserMutation({
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      phoneNumber: isValidInternationalNumber(selectedUser.phoneNumber)
        ? selectedUser.phoneNumber
        : undefined,
      roles: selectedUser.roles.map(({ value }) => parseInt(value)) ?? [],
      companies:
        values?.user[index].companies?.map(({ value }: OptionForSelect) =>
          Number(value),
        ) ?? [],
      attributes:
        // DefaultCompany always has a length of 1, so we can just grab the first value
        !!values?.user?.[index]?.defaultCompany &&
        !!values?.user?.[index]?.defaultCompany?.length
          ? [
              {
                attrValue:
                  values?.user?.[index]?.defaultCompany?.[0]?.value || "",
                attrKey: "default_company",
              },
            ]
          : [],
    });
  };

  useEffect(() => {
    if (formSubmitted && !isSuccess) {
      handleSubmit();
    }

    if (!formSubmitted) {
      resetCreateUser();
    }
  }, [formSubmitted]);

  return (
    <>
      <Panel
        shadow={false}
        className="mb-10"
        styles={{ padding: theme.spacing.small }}
      >
        <Box className="flex flex-row flex-wrap">
          <Box className="w-1/4 px-3">
            <Field
              name={`user[${index}].firstName`}
              label={t("user.form.labels.firstName")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={t("user.form.placeholders.firstName")}
                />
              )}
            </Field>
          </Box>

          <Box className="w-1/4 px-3">
            <Field
              name={`user[${index}].lastName`}
              label={t("user.form.placeholders.lastName")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={t("user.form.placeholders.lastName")}
                />
              )}
            </Field>
          </Box>

          <Box className="w-1/4 px-3">
            <Field
              name={`user[${index}].email`}
              label={t("user.form.placeholders.emailAddress")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={t("user.form.placeholders.emailAddress")}
                />
              )}
            </Field>
          </Box>

          <Box className="w-1/4 px-3">
            <Field
              name={`user[${index}].phoneNumber`}
              label={t("user.form.placeholders.phoneNumber")}
            >
              {({ field }) => (
                <InputInternationalPhoneNumber
                  {...field}
                  placeholder={t("user.form.placeholders.phoneNumber")}
                />
              )}
            </Field>
          </Box>

          <Box className="w-1/4 px-3 min-h-28">
            <Field
              name={`user[${index}].roles`}
              label={t("user.form.labels.roles")}
            >
              {({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isSearchable
                  isMulti
                  isDisabled={!rolesChoices || isLoadingRoles}
                  menuPlacement={"bottom"}
                  placeholder={
                    isLoadingRoles
                      ? "Loading..."
                      : t("user.form.placeholders.selectRoles")
                  }
                  options={rolesOptions ?? []}
                  onChange={(values: OptionForSelect[]) => {
                    if (values === null)
                      return setFieldValue(`user[${index}].roles`, []);

                    setFieldValue(`user[${index}].roles`, values);
                  }}
                />
              )}
            </Field>
          </Box>
          <Box className="w-1/4 px-3 min-h-28">
            <Field
              styles={{
                flex: 1,
              }}
              name={`user[${index}].companies`}
              label={t("user.form.placeholders.companies")}
            >
              {({ field }) => (
                <Select
                  {...field}
                  placeholder={
                    isLoadingCompanies
                      ? "Loading..."
                      : t("user.form.placeholders.searchCompaniesValue")
                  }
                  isSearchable
                  isClearable
                  isMulti
                  isDisabled={!companiesChoices}
                  options={companiesOptions}
                  className="text-base"
                  onChange={(options: OptionForSelect[]) => {
                    if (options === null) {
                      setFieldValue(`user[${index}].companies`, []);
                      setFieldValue(`user[${index}].defaultCompany`, []);
                      return;
                    }

                    // If defaultCompany is not in the list of companies, remove it
                    if (
                      !!values?.user?.[index]?.defaultCompany &&
                      !!values?.user?.[index]?.defaultCompany?.length &&
                      !options?.find(
                        ({ label }) =>
                          label ===
                          values?.user?.[index]?.defaultCompany?.[0]?.label,
                      )
                    ) {
                      setFieldValue(`user[${index}].defaultCompany`, []);
                    }

                    setFieldValue(`user[${index}].companies`, options);
                  }}
                />
              )}
            </Field>
          </Box>

          <Box className="w-1/4 min-h-28 px-3">
            <Field
              styles={{
                flex: 1,
              }}
              name={`user[${index}].defaultCompany`}
              label={t("user.form.labels.defaultCompany")}
            >
              {({ field }) => (
                <Select
                  {...field}
                  placeholder={
                    isLoadingCompanies
                      ? "Loading..."
                      : t("user.form.placeholders.searchDefaultCompanyValue")
                  }
                  isSearchable
                  isClearable
                  isDisabled={!companiesChoices}
                  options={selectedUser.companies}
                  className="text-base"
                  onChange={(values: OptionForSelect[]) => {
                    if (values === null)
                      return setFieldValue(`user[${index}].defaultCompany`, []);

                    setFieldValue(`user[${index}].defaultCompany`, [values]);
                  }}
                />
              )}
            </Field>
          </Box>
          <Box className="w-1/4 px-3 flex items-center justify-end gap-3">
            <Box className="flex items-center -mb-1 gap-3">
              {isLoadingCreateUser && <Icon.Loading size="medium" />}
              {isErrorCreateUser && (
                <UnstyledButton onClick={() => handleSubmit()}>
                  <Box
                    styles={{
                      backgroundColor: theme.colors.warning.base,
                      "&:hover": {
                        backgroundColor: theme.colors.warning.dark,
                      },
                    }}
                    className="p-2 rounded-md flex flex-col items-center min-w-16"
                  >
                    <Icon.Refresh className="mb-1" size="medium" />
                    <Text fontSize="xsmall">{t("user.buttons.retry")}</Text>
                  </Box>
                </UnstyledButton>
              )}
              <UnstyledButton
                type="button"
                disabled={values.user.length === 1}
                onClick={() => onRemove(index)}
              >
                <Box
                  styles={{
                    backgroundColor: theme.colors.negative.base,
                    "&:hover": {
                      backgroundColor: theme.colors.negative.dark,
                    },
                  }}
                  className="p-2 rounded-md flex flex-col items-center min-w-16"
                >
                  <Icon.Trash className="mb-1" size="medium" color="light" />
                  <Text color="light" fontSize="xsmall">
                    {t("user.buttons.remove")}
                  </Text>
                </Box>
              </UnstyledButton>
              <UnstyledButton
                type="button"
                onClick={() =>
                  setFieldValue("user", [
                    ...values.user,
                    {
                      ...createUserObject,
                      roles: selectedUser.roles,
                      companies: selectedUser.companies,
                      defaultCompany: selectedUser.defaultCompany,
                    },
                  ])
                }
              >
                <Box
                  styles={{
                    backgroundColor: theme.colors.brand.base,
                    "&:hover": {
                      backgroundColor: theme.colors.brand.dark,
                    },
                  }}
                  className="p-2 rounded-md flex flex-col items-center justify-between"
                >
                  <Icon.Copy className="mb-1" size="medium" color="light" />
                  <Text color="light" fontSize="xsmall">
                    {t("user.buttons.duplicate")}
                  </Text>
                </Box>
              </UnstyledButton>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-col items-end">
          {displayErrorMessage(errorsCreateUser, ({ children }) => (
            <Box className="flex flex-col items-center">
              <Message type="error" background>
                {children}
              </Message>
            </Box>
          ))}
          {isSuccess && (
            <Box>
              <Message type="success" background>
                <Text>{t("user.modals.headings.createUserSuccess")}</Text>
              </Message>
            </Box>
          )}
        </Box>
      </Panel>
    </>
  );
};
