import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IJobsPool } from "@clearabee/api-schemas";
import {
  Table,
  formatCurrency,
  Button,
  Text,
  theme,
  Modal,
  formatPostcode,
  Heading,
  Box,
} from "@clearabee/ui-library";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { useScreenWidth } from "hooks/useScreenWidth";

interface TableRowProps {
  jobPool: IJobsPool;
  unassignJob: () => void;
}

dayjs.extend(utc);

const getPillColor = (status: string): AllowedColorKeys => {
  switch (status) {
    case "accepted":
      return "accent";
    case "rejected":
      return "negative";
    case "open":
      return "warning";
    default:
      return "warning";
  }
};

export const TableRow = ({
  jobPool,
  unassignJob,
}: TableRowProps): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");
  const { isDesktop } = useScreenWidth();
  const history = useHistory();

  const [showDescription, setShowDescription] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { companyCode, job, status: poolStatus, notes } = jobPool;

  const {
    id,
    ref,
    date,
    meta,
    tip,
    status: jobStatus,
    description,
    addressPostcode,
  } = job || {};

  return (
    <Table.Row key={ref} className="relative">
      {/* JOB POOL STATUS */}
      <Table.Cell.Pill
        color={getPillColor(poolStatus.toLowerCase())}
        size={isDesktop ? "medium" : "xsmall"}
      >
        {poolStatus}
      </Table.Cell.Pill>

      {/* JOB STATUS */}
      {poolStatus === "rejected" ? (
        <Table.Cell.Text>{translate("jobs.table.NA")}</Table.Cell.Text>
      ) : (
        <Table.Cell.Text>{!!tip ? "Tipped" : jobStatus}</Table.Cell.Text>
      )}

      {/* JOB ID */}
      <Table.Cell.Text
        color="brand"
        className="cursor-pointer"
        onClick={() =>
          history.push(`/jobs/update/${encodeURIComponent(String(id ?? ""))}`)
        }
      >
        {id}
      </Table.Cell.Text>

      {/* JOB REFERENCE */}
      <Table.Cell.Text>{ref}</Table.Cell.Text>

      {/* DESCRIPTION */}
      <Table.Cell>
        <Button size="xsmall" onClick={() => setShowDescription(true)}>
          <Text fontSize={isDesktop ? "small" : "xsmall2"} className="p-1">
            {translate("jobs.buttons.showDetails")}
          </Text>
        </Button>
        {showDescription && (
          <Modal
            width={500}
            styles={{
              [`@media (min-width: ${theme.screens.medium})`]: {
                padding: theme.spacing.xlarge2,
              },
            }}
            onClose={() => setShowDescription(false)}
          >
            <Box className="text-left flex flex-col gap-6">
              <Box>
                <Heading
                  styles={{ marginBottom: theme.spacing.xsmall2 }}
                  color="brand"
                  level={4}
                >
                  {translate("jobs.table.description")}
                </Heading>
                <Text>
                  {description
                    ? description
                    : translate("subcontractors.messages.noDescription")}
                </Text>
              </Box>
              {!!notes && (
                <Box>
                  <Heading
                    color="brand"
                    level={4}
                    styles={{ marginBottom: theme.spacing.xsmall2 }}
                  >
                    {translate("jobs.table.rejectionReason")}
                  </Heading>
                  <Text>{notes}</Text>
                </Box>
              )}
            </Box>
          </Modal>
        )}
      </Table.Cell>

      {/* ASSIGNED SUBCONTRACTOR */}
      <Table.Cell.Text>{companyCode}</Table.Cell.Text>

      {/* COLLECTION DATE */}
      <Table.Cell.Text>{dayjs.utc(date).format("DD/MM/YYYY")}</Table.Cell.Text>

      {/* POSTCODE */}
      <Table.Cell.Text>{formatPostcode(addressPostcode)}</Table.Cell.Text>

      {/* JOB VALUE */}
      <Table.Cell.Text>
        {meta?.subcontractorInvoiceAmount
          ? formatCurrency(Number(meta?.subcontractorInvoiceAmount))
          : "N/A"}
      </Table.Cell.Text>

      {/* ACTIONS */}
      <Table.Cell>
        <Button
          size="xsmall"
          color="warning"
          onClick={() => setModalOpen(true)}
          disabled={!["open", "scheduled"].includes(jobStatus.toLowerCase())}
        >
          <Text fontSize={isDesktop ? "small" : "xsmall2"} className="p-1">
            {translate("jobs.buttons.unassignJob")}
          </Text>
        </Button>
      </Table.Cell>

      {modalOpen && (
        <div>
          <Modal onClose={() => setModalOpen(false)} width={600}>
            <Text>{translate("jobs.areYouSure")}</Text>
            <Text
              color="brand"
              fontSize="xlarge2"
              styles={{ marginTop: theme.spacing.xsmall, fontWeight: 700 }}
            >
              {ref}
            </Text>
            <div className="flex flex-row gap-6 mt-12 justify-center">
              <Button
                color="negative"
                size="medium"
                onClick={() => setModalOpen(false)}
              >
                {translate("jobs.buttons.cancel")}
              </Button>
              <Button
                size="medium"
                color="positive"
                onClick={() => {
                  setModalOpen(false);
                  unassignJob();
                }}
              >
                {translate("jobs.buttons.confirm")}
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </Table.Row>
  );
};
