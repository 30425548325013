import React, { useState } from "react";
import * as Yup from "yup";
import { stringNumberOnly, fieldRequired } from "validation/common";
import { useAuthContext, useScreenWidth } from "hooks";
import {
  Input,
  Heading,
  Text,
  Modal,
  Button,
  Box,
  Form,
  Field,
  theme,
  Message,
  Icon,
} from "@clearabee/ui-library";
import { getErrorMessage } from "helpers";

export const validationSchema = Yup.object({
  code: stringNumberOnly.required(fieldRequired),
});

type FormValues = Yup.InferType<typeof validationSchema>;

export const Verify2FA = (): React.ReactElement => {
  const [error, setError] = useState("");
  const { handle2FAVerification } = useAuthContext();
  const { isMobile } = useScreenWidth();

  const handleSubmit = async (values: FormValues) => {
    setError("");

    try {
      const { code } = values;
      await handle2FAVerification(code);
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  return (
    <Modal
      width={!isMobile ? 450 : 300}
      onClose={() => {
        // reload the page to reset
        window.location.reload();
      }}
      styles={{
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: theme.spacing.large,
        },
        ":before": {
          opacity: 0.9,
        },
      }}
    >
      <Icon.Bee size="xlarge2" color="brand" className="mx-auto mb-4" />
      <Heading
        fontSize="xlarge"
        styles={{ marginBottom: theme.spacing.xsmall2 }}
      >
        Two-Factor Authentication
      </Heading>
      <Text
        styles={{
          marginBottom: theme.spacing.small,
        }}
      >
        Enter the code from your authentication app
      </Text>
      <Form
        initialValues={{ code: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Box styles={{ marginBottom: theme.spacing.small }}>
          <Field name="code">
            {({ field }) => (
              <Input.Text
                {...field}
                styles={{
                  ...theme.fontDefaults.xlarge2,
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: theme.spacing.xsmall,
                }}
              />
            )}
          </Field>
        </Box>
        <Button type="submit" size="small">
          Verify
        </Button>
      </Form>
      {!!error && (
        <Message type="error" background className="mt-5">
          {error}
        </Message>
      )}
    </Modal>
  );
};
