import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { instance } from "@clearabee/ui-sdk";
import { IJob } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Icon,
  Input,
  Panel,
  theme,
  UnstyledButton,
  Text,
  Button,
  getColorFromVariantKey,
  formatPostcode,
} from "@clearabee/ui-library";
import { toasts, truncateString } from "helpers";
import { CheckboxSelect, LoadingOverlay } from "components/common/components";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";

dayjs.extend(utc);

type OptionType = {
  label: string;
  value: string;
};

type StatusColors = {
  [Key in IJob["status"]]: AllowedColorKeys;
};

const statusColors: StatusColors = {
  open: "greyscale",
  scheduled: "warning",
  "on the way": "positive",
  started: "brand",
  failed: "negative",
  cancelled: "negative",
  completed: "positive",
};

const defaultOption = { label: "All Vehicles", value: "all" };

export const ViewSchedule = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [extraScheduledJobs, setExtraScheduledJobs] = useState<IJob[]>([]);
  const [date, setDate] = useState(new Date());
  const [selectedVehicles, setSelectedVehicles] = useState<OptionType[]>([
    defaultOption,
  ]);
  const [isSelectAllVehicles, setIsSelectAllVehicles] = useState(false);
  const [displayVehiclesHaveJobs, setDisplayVehiclesHaveJobs] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedVehicleLoading, setSelectedVehicleLoading] = useState("");

  /**
   * Fetch all vehicles
   */
  const { data: allVehicles, isLoading: isLoadingAllVehicles } = useQuery(
    "getVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            // only get approved vehicles
            "status:eq": "approved",
            limit: 1500,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: () => {
        toasts.error({
          message: translate("viewSchedule.errors.fetchError", {
            error: "vehicles",
          }),
        });
      },
    },
  );

  /**
   * Fetch all scheduled jobs by date and selected vehicles
   */
  const {
    data: allScheduledJobs,
    isLoading: isLoadingAllScheduledJobs,
    isRefetching: isRefetchingAllScheduledJobs,
    refetch: refetchAllScheduledJobs,
  } = useQuery(
    ["getAllScheduledJobs", date, selectedVehicles],
    async () =>
      (
        await instance.jobs.getJobs({
          params: {
            "date:gte": dayjs(date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            "date:lt": dayjs(date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            "asset:in": selectedVehicles
              .map((vehicle) => vehicle.value)
              .join(","),
            limit: 1500,
          },
        })
      ).data.items,
    {
      retry: false,
      enabled:
        !!selectedVehicles.length &&
        selectedVehicles.some(({ value }) => value !== "all"),
      onError: () => {
        toasts.error({
          message: translate("viewSchedule.errors.fetchError", {
            error: "scheduled jobs",
          }),
        });
      },
    },
  );

  /**
   * Fetch scheduled jobs for selected vehicle
   */
  const {
    mutate: mutateallScheduledJobsForSelectedVehicle,
    isLoading: isLoadingallScheduledJobsForSelectedVehicle,
    reset: resetallScheduledJobsForSelectedVehicle,
  } = useMutation(
    "getAllScheduledJobsForSelectedVehicle",
    async (asset: string) => {
      if (!asset) return [];

      return (
        await instance.jobs.getJobsByAsset(asset, {
          params: {
            date: dayjs.utc(date).toISOString(),
            "include-related": true,
          },
        })
      ).data;
    },
    {
      retry: false,
      onSuccess: (data) => {
        setExtraScheduledJobs([...extraScheduledJobs, ...data]);
      },
      onError: () => {
        toasts.error({
          message: translate("viewSchedule.errors.fetchError", {
            error: "scheduled jobs",
          }),
        });
      },
      onSettled: () => {
        setSelectedVehicleLoading("");
      },
    },
  );

  /**
   * Vehicle Options
   */
  const vehicleOptions = useMemo(() => {
    if (!allVehicles) return [];

    return allVehicles.map(({ name, registration }) => ({
      label: name || registration,
      value: registration,
    }));
  }, [allVehicles]);

  /**
   * Get matched jobs for each vehicle
   */
  const getMatchedJobsForVehicle = useCallback(
    (vehicleReg: string) => {
      if (!allScheduledJobs || !vehicleReg) return [];

      const combinedJobs = [...allScheduledJobs, ...extraScheduledJobs];

      const uniqueJobs: IJob[] = [];

      for (const job of combinedJobs) {
        if (
          job.asset === vehicleReg &&
          !uniqueJobs.some((j) => j.ref === job.ref)
        ) {
          uniqueJobs.push(job);
        }
      }

      return uniqueJobs.sort(
        (a, b) =>
          dayjs(a.plannedStartTime).unix() - dayjs(b.plannedStartTime).unix(),
      );
    },
    [allScheduledJobs, extraScheduledJobs],
  );

  /**
   * Filter vehicles based on selected vehicles and display vehicles with jobs
   */
  const filteredVehicles = useMemo(() => {
    if (!allVehicles || !selectedVehicles.length) return [];

    return allVehicles.filter((value) => {
      if (displayVehiclesHaveJobs) {
        return selectedVehicles.some(
          (selectedVehicle) =>
            selectedVehicle.value === value.registration &&
            !!getMatchedJobsForVehicle(value.registration).length,
        );
      }

      if (isSelectAllVehicles) {
        return true;
      }

      return selectedVehicles.some(
        (selectedVehicle) => selectedVehicle.value === value.registration,
      );
    });
  }, [
    displayVehiclesHaveJobs,
    isSelectAllVehicles,
    selectedVehicles,
    getMatchedJobsForVehicle,
    allVehicles,
  ]);

  /**
   *  This function is used to toggle the fullscreen mode of the container
   */
  const toggleFullScreen = () => {
    const element = document.getElementById("fullScreenContainer");

    const isFullScreenElement = document.fullscreenElement;

    if (isFullScreenElement) {
      document.exitFullscreen();
      return;
    }

    element?.requestFullscreen();
  };

  /**
   * This effect is used to listen to the fullscreen change event, to update the styles of the container
   */
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    const element = document.getElementById("fullScreenContainer");

    element?.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      element?.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <Panel
      id="fullScreenContainer"
      shadow={false}
      styles={{
        marginTop: theme.spacing.xsmall,
        paddingTop: theme.spacing.medium,
      }}
    >
      {/* LOADING OVERLAY */}
      {(isLoadingAllVehicles ||
        isLoadingAllScheduledJobs ||
        isRefetchingAllScheduledJobs) && <LoadingOverlay />}

      {!isFullScreen && (
        <Link
          to="/jobs/schedule"
          className="btn-tiny mt-2 mb-5 sm:mt-0 btn btn-primary hover:bg-primary hover:text-white transition ease-in duration-100"
        >
          &larr; {translate("viewSchedule.buttons.back")}
        </Link>
      )}

      <Box className="flex justify-between items-center">
        <Heading color="brand" level={1} fontSize="large">
          {translate("viewSchedule.headings.viewSchedule")}
        </Heading>
        <Box className="flex items-center gap-x-5">
          <Button
            size="xsmall"
            onClick={() => {
              setExtraScheduledJobs([]);
              resetallScheduledJobsForSelectedVehicle();
              refetchAllScheduledJobs();
            }}
          >
            {translate("viewSchedule.buttons.reloadAll")}
          </Button>
          {/* FULLSCREEN ACTION */}
          <UnstyledButton type="button" onClick={toggleFullScreen}>
            <Icon.FullScreen size="medium" />
          </UnstyledButton>
        </Box>
      </Box>

      {/* DIVIDER */}
      <Box className="border-t border-gray-300 my-2 flex flex-col h-1" />

      <Box className="flex justify-start items-start gap-x-5 mb-5">
        {/* CHECKBOX SELECT */}
        <Box className="w-2/5">
          <label className="font-semibold">
            {translate("viewSchedule.labels.vehicles")}
          </label>
          <CheckboxSelect
            value={selectedVehicles}
            options={vehicleOptions}
            isLoadingOptions={isLoadingAllVehicles}
            disabled={isLoadingAllVehicles}
            selectAllOptionsLabel={defaultOption.label}
            placeholder={translate("viewSchedule.placeholders.vehicles")}
            onClearAll={() => setSelectedVehicles([])}
            onChange={(options, isAllSelected) => {
              setIsSelectAllVehicles(!!isAllSelected);
              setSelectedVehicles(options);
            }}
          />
        </Box>
        {/* DATE */}
        <Box className="w-1/5">
          <label className="font-semibold">
            {translate("viewSchedule.labels.date")}
          </label>
          <Input.Date
            collapsable
            value={dayjs(date).format("DD-MM-YYYY")}
            dateFormat="DD-MM-YYYY"
            placeholder={translate("viewSchedule.placeholders.date")}
            onChange={(e) =>
              setDate(dayjs(e.target.value, "DD-MM-YYYY").toDate())
            }
          />
        </Box>
        {/* TOGGLE */}
        <Box>
          <label className="font-semibold">
            {translate("viewSchedule.labels.onlyVehiclesWithJobs")}
          </label>
          <Input.Toggle
            onChange={(e) => setDisplayVehiclesHaveJobs(e.target.checked)}
          />
        </Box>
      </Box>
      <Box
        styles={{
          borderRadius: theme.spacing.xsmall,
          height: isFullScreen ? "90vh" : "65vh",
        }}
      >
        {!!filteredVehicles.length && (
          <Box className="h-full flex flex-col gap-y-5 overflow-y-scroll">
            {filteredVehicles.map(({ name, registration }) => (
              <Box key={registration}>
                {/* DISPLAY VEHICLE HEADING TEXT */}
                <Box className="pl-2 flex items-center gap-x-2 mb-1">
                  <Text fontSize="base" className="font-semibold" color="brand">
                    {translate("viewSchedule.texts.vehicleHeading", {
                      name,
                      registration,
                      date: dayjs(date).format("DD-MM-YYYY"),
                      jobsCount: getMatchedJobsForVehicle(registration).length,
                    })}
                  </Text>
                  <UnstyledButton
                    type="button"
                    onClick={() => {
                      setSelectedVehicleLoading(registration);
                      mutateallScheduledJobsForSelectedVehicle(registration);
                    }}
                  >
                    <Icon.Refresh size="small" />
                  </UnstyledButton>
                  {/* DISPLAY LOADING FOR EACH VEHICLE */}
                  {isLoadingallScheduledJobsForSelectedVehicle &&
                    selectedVehicleLoading === registration && (
                      <Icon.Loading color="brand" size="medium" />
                    )}
                </Box>
                {/* DISPLAY SCHEDULED JOBS */}
                <Box
                  styles={{
                    width: "100%",
                    minHeight: "150px",
                    display: "flex",
                    overflowX: "scroll",
                    borderWidth: "1px",
                    borderColor: theme.colors.brand.lighter,
                    gap: theme.spacing.medium,
                    padding: theme.spacing.xsmall,
                    borderRadius: theme.spacing.xsmall,
                  }}
                >
                  {getMatchedJobsForVehicle(registration).map(
                    ({
                      ref,
                      type,
                      addressPostcode,
                      duration,
                      plannedStartTime,
                      status,
                    }) => (
                      <Box
                        key={ref}
                        className="min-h-40 min-w-40 flex flex-col items-center justify-center gap-y-1 rounded-md bg-gray-200 border-b-8"
                        styles={{
                          borderColor: getColorFromVariantKey(
                            statusColors?.[status.toLowerCase()] || "greyscale",
                          ),
                          ":hover": {
                            transform: "scale(1.05)",
                            transition: "transform 0.3s",
                          },
                        }}
                      >
                        <Text fontSize="xsmall" className="font-semibold">
                          {!duration
                            ? `${dayjs.utc(plannedStartTime).format("H:mm A")}`
                            : `${dayjs.utc(plannedStartTime).format("H:mm A")} -
                        ${dayjs
                          .utc(plannedStartTime)
                          .add(duration, "minute")
                          .format("H:mm A")}`}
                        </Text>
                        {!!duration && (
                          <Text fontSize="xsmall" className="font-semibold">
                            {translate("viewSchedule.texts.duration", {
                              duration,
                            })}
                          </Text>
                        )}
                        <Text fontSize="xsmall">{ref}</Text>
                        <Text fontSize="xsmall">
                          {truncateString(type ?? "", 15)}
                        </Text>
                        <Text fontSize="xsmall">
                          {formatPostcode(addressPostcode)}
                        </Text>
                        <Text fontSize="xsmall">{status}</Text>
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {/* NO VEHICLES WITH JOBS */}
        {!filteredVehicles.length &&
          displayVehiclesHaveJobs &&
          !isLoadingAllVehicles &&
          !isLoadingAllScheduledJobs &&
          !isRefetchingAllScheduledJobs && (
            <Box className="mt-10 flex justify-center">
              <Heading level={2} fontSize="large">
                {translate("viewSchedule.noVehiclesJobs")}
              </Heading>
            </Box>
          )}

        {/* NO DATA */}
        {!filteredVehicles.length &&
          !displayVehiclesHaveJobs &&
          !isLoadingAllVehicles &&
          !isLoadingAllScheduledJobs &&
          !isRefetchingAllScheduledJobs && (
            <Box className="mt-10 flex justify-center">
              <Heading level={2} fontSize="large">
                {translate("viewSchedule.noSelectedVehicle")}
              </Heading>
            </Box>
          )}
      </Box>
    </Panel>
  );
};
