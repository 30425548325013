import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clipboardCopy from "clipboard-copy";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { useAuthContext, useMultiFormContext, useBasketContext } from "hooks";
import { BasicCheckbox, Modal } from "../../../core";
import { PaymentOrderDetails } from "../components";
import { createBasketPaymentLink, processBasketForInvoiceCustomer } from "api";
import { ICreateJobFormState } from "../createJob";
import { BasketTimeslot, IBasketContact } from "models/basket";
import ManageUsers from "images/manage-users.svg";
import roles from "constants/roles";
import { uploadImages } from "../../lib/helpers";
import { StripeBillingForm } from "components/common/components/stripeBillingForm";
import { handleException } from "helpers/handleException";
import dayjs from "dayjs";
import {
  formatCurrency,
  Icon,
  displayErrorMessage,
  Panel,
  Button,
  Text,
  theme,
  Box,
  UnstyledButton,
} from "@clearabee/ui-library";
import { instance, PostBasketResponse } from "@clearabee/ui-sdk";
import { generatePaymentURL, toasts } from "helpers";

export const Payment = (): React.ReactElement => {
  /**
   * Hooks.
   */
  const {
    items,
    calculateTotal,
    clearItems,
    setBasketToken,
    setBasketOrderRef,
    basketToken,
    basketOrderRef,
    catalogueId,
  } = useBasketContext();
  const { setStep, formState, reset, setLoading, isLoading } =
    useMultiFormContext<ICreateJobFormState>();
  const [translate] = useTranslation("jobs");
  const {
    getCurrentUserCurrentCompany,
    doesUserHaveRole,
    getCurrentUserId,
    getCurrentUserCurrentCompanySettings,
  } = useAuthContext();
  const [clientSecret, setClientSecret] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [useSameDetails, setUseSameDetails] = useState(false);
  const [basket, setBasket] = useState<PostBasketResponse>();
  const currentUserId = getCurrentUserId();
  const companySettings = getCurrentUserCurrentCompanySettings();
  /**
   * State.
   */
  const {
    collectionAddress,
    collectionDate,
    wasteDescription,
    accessInformation,
    timeslot,
    timeslotCharge: formStateTimeslotCharge,
    timeslotPreference,
    isCustomTimeslot,
    customTimeslot,
    customer,
    meta,
    company,
    sicCode,
    images,
    noContactDetails,
    companyCanInvoice,
    useAdhoc,
    bigchangeProps,
    reportsMeta,
  } = formState;

  let basketTimeslot: BasketTimeslot | undefined;

  if (timeslot && !isCustomTimeslot) {
    const splitTimeSlot = timeslot.split("-");
    basketTimeslot = {
      from: splitTimeSlot[0],
      to: splitTimeSlot[1],
    };
  }

  if (timeslot && !!isCustomTimeslot) {
    const splitTimeSlot = timeslot.split("-");
    basketTimeslot = {
      from: splitTimeSlot[0],
      to: splitTimeSlot[1],
      title: "custom",
      charge: customTimeslot?.charge,
    };
  }

  const timeslotChargeItem = items.find(
    (item) => item.sku === "DELIVERYCHARGE",
  );
  const timeslotCharge =
    formStateTimeslotCharge || timeslotChargeItem?.price || 0;

  const { settings } = getCurrentUserCurrentCompany() ?? {};
  const canReadCompanies = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const isCompanyAssistant = doesUserHaveRole(roles.COMPANY_ASSISTANT);
  const canInvoice = canReadCompanies
    ? companyCanInvoice
    : settings?.canInvoice;

  /**
   * Functions.
   */
  const clearBasket = () => setBasketToken("");

  const customCustomer = customer as IBasketContact;
  if (customCustomer.email === "") {
    customCustomer.email = undefined;
  }
  if (customCustomer.phoneNumber === "") {
    customCustomer.phoneNumber = undefined;
  }

  const isCompanyCodeLV = !!company?.value && company.value.includes("LV-");
  const isCompanyCodeDIR = !!company?.value && company.value.includes("DIR-");

  const customFields = Object.entries(bigchangeProps)
    .map(([key, value]) => {
      if (
        isCompanyCodeLV &&
        (key === "cust_RiskAddressIsCollectionAddress" ||
          key === "cust_RiskPostcode")
      ) {
        return { [key]: String(value) };
      }

      if (
        isCompanyCodeDIR &&
        (key === "cust_Is the customer vulnerable" || key === "cust_VC Notes")
      ) {
        return { [key]: String(value) };
      }
    })
    .filter(Boolean) as Array<Record<string, string>>;

  const data = {
    catalogueId,
    items: items.map((item) => {
      return { sku: item.sku, qty: item.quantity, price: item.price };
    }),
    companyCode: company?.value ?? "",
    collectionAddress,
    collectionDate: dayjs(new Date(collectionDate)).toISOString(),
    description: wasteDescription,
    accessInformation,
    contact:
      !customer.firstName &&
      !customer.lastName &&
      !customer.email &&
      !customer.phoneNumber
        ? undefined
        : customCustomer,
    meta,
    ...(!!isCustomTimeslot
      ? {
          deliveryCharge: 0,
          deliveryOption: "",
        }
      : {
          deliveryCharge: timeslotCharge,
          deliveryOption: timeslotPreference,
        }),
    timeslot: basketTimeslot,
    sicCode,
    customFields,
  };

  const { mutate, error } = useMutation(
    async () => {
      setLoading(true);

      const data = {
        catalogueId,
        items: items.map((item) => {
          return {
            sku: item.sku,
            qty: item.quantity,
            price: item.price ?? 0,
          };
        }),
        companyCode: company?.value ?? "",
        deliveryAddress: collectionAddress,
        date: new Date(collectionDate).toISOString(),
        description: wasteDescription,
        accessInformation,
        contact:
          !customer.firstName &&
          !customer.lastName &&
          !customer.email &&
          !customer.phoneNumber
            ? undefined
            : customCustomer,
        meta: {
          ...meta,
          reportsMeta: reportsMeta,
        },
        ...(!!isCustomTimeslot
          ? {
              deliveryCharge: 0,
              deliveryOption: "",
            }
          : {
              deliveryCharge: timeslotCharge,
              deliveryOption: timeslotPreference,
            }),
        timeslot: basketTimeslot,
        sicCode,
        customFields,
      };

      const { data: basket } = await instance.catalogues.postBasket(data);
      setBasketToken(basket.basketToken);
      setBasketOrderRef(basket.orderRef);
      setClientSecret(basket.clientSecret ?? "");
      setBasket(basket);
      setLoading(false);
    },
    {
      onError: (error) => {
        handleException(
          error,
          { basket: data },
          {
            type: "Create Job",
          },
          translate("errors.basket"),
          false,
        );
        setLoading(false);
      },
      retry: false,
    },
  );

  const handleJobInvoiceSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (images.length) {
        await uploadImages(images, basketToken);
      }
      await processBasketForInvoiceCustomer(basketToken, meta.poNumber);
      clearBasket();

      setIsSuccess(true);
    } catch (error) {
      handleException(error, { basketToken, meta }, { type: "Create Job" });
    }
    setIsSubmitting(false);
  };

  const handleBeforePayment = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      if (images.length) {
        await uploadImages(images, basketToken);
      }
    } catch (error) {
      handleException(error, { basketToken, images }, { type: "Create Job" });
      setIsSubmitting(false);
      throw error;
    }
  };

  const {
    mutate: createPaymentLink,
    isLoading: isPaymentLinkLoading,
    isSuccess: isPaymentLinkSuccess,
    reset: resetPaymentLink,
  } = useMutation(
    async () => {
      await handleBeforePayment();
      return await createBasketPaymentLink(basket?.basketToken ?? "");
    },
    {
      onError: (error) => {
        handleException(
          error,
          { basketToken },
          { type: "ADHOC Job" },
          translate("errors.paymentLink"),
        );
      },
    },
  );

  const handlePaymentSuccess = () => {
    clearBasket();
    setIsSubmitting(false);
    setIsSuccess(true);
  };

  const restartCreateJob = () => {
    clearItems();
    reset(!useSameDetails);
    resetPaymentLink();
  };

  useEffect(() => {
    mutate();
  }, []);

  /**
   * Scroll to the top of the page when either a payment or paymentlink
   * request is successful. This is so that if the user redirects to the dashboard,
   * the dashboard will be scrolled up to the top of the page.
   */
  useEffect(() => {
    if (isSuccess || isPaymentLinkSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSuccess, isPaymentLinkSuccess]);

  return (
    <>
      <div>
        <div className="xl:px-16">
          <div className="md:flex px-6 py-8 md:px-10 md:max-w-screen-md order-summary mx-auto shadow-filter bg-cover rounded-xl bg-pattern w-full pointer-events-auto is-visible">
            <div className="w-full md:pr-5 md:w-1/2">
              <div className="relative flex -mx-4 text-white">
                <div className="absolute top-0 right-4">
                  <span className="inline-block">
                    <Button
                      type="button"
                      onClick={() => setStep(2)}
                      color="warning"
                      size="xsmall"
                    >
                      {translate("buttons.labels.edit")}
                    </Button>
                  </span>
                </div>
                <div className="w-auto px-4 pr-16">
                  <h4 className="text-xl font-bold mb-3">
                    {translate("create.orderSummary.titles.details")}
                  </h4>
                  {!noContactDetails && (
                    <>
                      <p className="text-2xs">
                        {translate("create.orderSummary.titles.name")}
                      </p>
                      <p className="leading-none mb-3">{`${customer.firstName} ${customer.lastName}`}</p>
                      <p className="text-2xs">
                        {translate("create.orderSummary.titles.phone")}
                      </p>
                      <p className="leading-none mb-3">
                        {customer.phoneNumber}
                      </p>
                      <p className="text-2xs">
                        {translate("create.orderSummary.titles.email")}
                      </p>
                      <p className="leading-none mb-3">{customer.email}</p>
                    </>
                  )}
                  <p className="text-2xs">
                    {translate("create.orderSummary.titles.collection")}
                  </p>
                  {collectionAddress.hasOwnProperty("line1") && (
                    <p className="leading-none">{collectionAddress.line1}</p>
                  )}
                  {collectionAddress.hasOwnProperty("line2") && (
                    <p className="leading-none">{collectionAddress.line2}</p>
                  )}
                  {collectionAddress.hasOwnProperty("city") && (
                    <p className="leading-none">{collectionAddress.city}</p>
                  )}
                  {collectionAddress.hasOwnProperty("county") && (
                    <p className="leading-none">{collectionAddress.county}</p>
                  )}
                  {collectionAddress.hasOwnProperty("postcode") && (
                    <p className="leading-none">{collectionAddress.postcode}</p>
                  )}
                  {collectionDate && (
                    <div className="mt-4">
                      <Text fontSize="xsmall2">
                        {translate("create.orderSummary.titles.selectedDate")}
                      </Text>
                      <Text fontSize="small">
                        {dayjs(collectionDate).format("dddd DD MMM YYYY")}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
              {!canInvoice && (
                <>
                  <div className="my-8 border-b border-white opacity-25 w-full" />
                  <PaymentOrderDetails />
                </>
              )}
              <div className="border-b my-8 md:my-0 border-white opacity-25 w-full visible md:invisible" />
            </div>
            <div className="w-full md:pl-5 md:w-1/2">
              {!canInvoice && !!clientSecret ? (
                <>
                  <h4 className="text-xl font-bold text-white">
                    {translate("create.orderSummary.titles.billing")}
                  </h4>
                  <div className="py-3">
                    {clientSecret && (
                      <>
                        <StripeBillingForm
                          contact={{
                            name: `${customer.firstName} ${customer.lastName}`,
                            phone: customer.phoneNumber,
                            email: customer.email,
                          }}
                          clientSecret={clientSecret}
                          amount={calculateTotal(false) as number}
                          onPaymentSuccess={handlePaymentSuccess}
                          onBeforeFormSubmit={
                            images.length ? handleBeforePayment : undefined
                          }
                          disableButton={isLoading || isSubmitting}
                        />
                        {customer.email && useAdhoc && (
                          <div className="flex">
                            <Button
                              id="sendPaymentLinkButton"
                              size="medium"
                              color="accent"
                              type="button"
                              className="flex items-center gap-x-2 mx-auto my-3"
                              disabled={isPaymentLinkLoading || !basketToken}
                              onClick={() => createPaymentLink()}
                            >
                              {isPaymentLinkLoading && (
                                <Icon.Loading size="small" />
                              )}
                              {translate("create.buttons.paymentLink")}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                    {!clientSecret && (
                      <div className="flex items-center justify-center">
                        <Icon.Loading color="accent" />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <PaymentOrderDetails />
                  <div className="my-4 pt-4 border-b border-white opacity-25 w-full" />
                  <div className="mt-4 text-center flex justify-center">
                    <Button
                      name="complete"
                      type="button"
                      size="medium"
                      color="accent"
                      onClick={() => {
                        if (isCompanyAssistant) {
                          setIsSuccess(true);
                        } else {
                          handleJobInvoiceSubmit();
                        }
                      }}
                      disabled={isSubmitting || isLoading || !!error}
                      className="flex justify-center items-center gap-x-1"
                    >
                      {translate("create.buttons.complete")}
                      {isSubmitting && <Icon.Loading size="small" />}
                    </Button>
                  </div>
                </>
              )}
              {displayErrorMessage(error, ({ children }) => {
                const isValidationError =
                  children.toLowerCase() === "validation error";
                return (
                  <Panel
                    styles={{
                      background: theme.colors.negative.base,
                      padding: theme.spacing.small,
                      marginTop: theme.spacing.xsmall,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    shadow={false}
                  >
                    <div className="text-white text-center">
                      <Text fontSize="small">
                        {translate("create.errorDisclaimer")}
                      </Text>
                      <Box className="mt-2">
                        {isValidationError
                          ? (error as any)?.response?.data?.data?.errors?.map(
                              (item: any, index: number) => (
                                <Text key={index} fontSize="xsmall2">
                                  {item.includes("contact.phoneNumber")
                                    ? "Invalid phone number"
                                    : item}
                                </Text>
                              ),
                            )
                          : children}
                      </Box>
                      <div className="flex justify-center mt-3 mx-auto text-center">
                        <Button
                          size="small"
                          color="warning"
                          onClick={() => mutate()}
                        >
                          {translate("create.buttons.retry")}
                        </Button>
                      </div>
                    </div>
                  </Panel>
                );
              })}
              {!companySettings?.hidePrices && (
                <>
                  <div className="border-b mt-4 border-white opacity-25 w-full" />
                  <div className="flex flex-row justify-between items-center lg:pt-5 lg:mt-5 text-white">
                    <h4 className="text-lg md:text-xl font-bold">
                      {translate("create.orderSummary.titles.total")}
                    </h4>
                    <div className="text-right">
                      <h4 className="text-lg md:text-2xl font-bold leading-extra-tight">
                        {formatCurrency(basket?.totalCost ?? 0)}
                      </h4>
                      <h6 className="text-2xs">
                        {translate(
                          useAdhoc
                            ? "create.orderSummary.incVat"
                            : "create.orderSummary.vat",
                        )}
                      </h6>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-sm mx-auto px-2 pt-1 pb-4 mt-8 text-justify">
        <p>{translate("create.steps.confirmOrder.terms")}</p>
      </div>
      <Modal
        visible={isSuccess || isPaymentLinkSuccess}
        title={
          <>
            {translate("modal.headings.createJobSuccess")}
            <br />
            {translate("modal.headings.ref")}
            <Text as="span" fontSize="small" data-testid="createJobSuccessRef">
              {basketOrderRef}
            </Text>
          </>
        }
        image={ManageUsers}
      >
        {isPaymentLinkSuccess && (
          <Box className="pl-5 flex mt-3 justify-center items-center gap-x-2">
            <Link
              to={{
                pathname: generatePaymentURL(basket?.basketToken ?? ""),
              }}
              style={{
                textDecoration: "underline",
                textUnderlineOffset: theme.spacing.xsmall2,
                fontWeight: "bold",
              }}
              target="_blank"
            >
              {translate("headings.paymentLink")}
            </Link>
            <UnstyledButton
              type="button"
              onClick={async () => {
                await clipboardCopy(
                  generatePaymentURL(basket?.basketToken ?? ""),
                );
                toasts.success({
                  message: translate("toasts.copiedToClipboard"),
                });
              }}
            >
              <Icon.Copy size="medium" color="brand" />
            </UnstyledButton>
          </Box>
        )}
        <Link
          to="/"
          className="w-48 mt-3 btn btn-primary btn-filled ut-transition"
        >
          {translate("modal.buttons.labels.backToDashboard")}
        </Link>
        <button
          type="button"
          onClick={restartCreateJob}
          className="w-48 mt-3 btn btn-secondary hover:btn-secondary-hover ut-transition"
        >
          {translate("modal.buttons.labels.bookAnother")}
        </button>
        <BasicCheckbox
          label={translate("modal.checkbox.saveDetails")}
          classes={{
            label: "text-xs text-left my-auto leading-none",
            wrap: "mt-3 ml-4 mr-4",
          }}
          onClick={() => setUseSameDetails(!useSameDetails)}
        />
      </Modal>
    </>
  );
};
