import React from "react";
import { ISupplierPrice } from "@clearabee/api-schemas";
import { Table } from "@clearabee/ui-library";
import { EditSupplierPriceModal } from "./editSupplierPriceModal";
import { DeleteSupplierPriceModal } from "./deleteSupplierPriceModal";

interface TableRowProps {
  supplierPrice: ISupplierPrice;
  refetchData: () => void;
}

export const TableRow = ({
  supplierPrice,
  refetchData,
}: TableRowProps): React.ReactElement => {
  const { supplierRef, postcode, sku, price, active } = supplierPrice || {};

  return (
    <Table.Row
      key={`${supplierRef}-${sku}-${postcode}-${price}`}
      className="relative"
    >
      {/* SUPPLIER REFERENCE */}
      <Table.Cell.Text>{supplierRef}</Table.Cell.Text>

      {/* SUPPLIER SKU */}
      <Table.Cell.Text>{sku}</Table.Cell.Text>

      {/* POSTCODE */}
      <Table.Cell.Text>{postcode}</Table.Cell.Text>

      {/* SUPPLIER PRICE */}
      <Table.Cell.Currency
        price={price}
        style={{
          fontWeight: 500,
        }}
      />

      {/* SUPPLIER ACTIVE */}
      <Table.Cell.Text>{String(active)}</Table.Cell.Text>

      {/* ACTIONS */}
      <Table.Cell className="flex gap-x-3">
        <EditSupplierPriceModal
          supplierRef={supplierRef}
          sku={sku}
          postcode={postcode}
          price={price}
          refetchData={refetchData}
        />
        <DeleteSupplierPriceModal
          supplierRef={supplierRef}
          sku={sku}
          postcode={postcode}
          refetchData={refetchData}
        />
      </Table.Cell>
    </Table.Row>
  );
};
