import * as Yup from "yup";
import {
  stringRequired,
  fieldRequired,
  email,
  validPostcode,
  phone,
  lettersOnly,
} from "../../validation/common";

export const constantDays = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

export const defaultDays = {
  day: "",
  limit: "",
};

export const defaultDates = {
  date: "",
  limit: "",
};

export const defaultReportsMeta = {
  name: "",
  label: "",
  regex: "",
  validationMessage: "",
  required: false,
};

export const createCompany = Yup.object().shape({
  name: stringRequired,
  companyCode: stringRequired.max(7, "Max 7 characters"),
  companyPrefix: stringRequired.max(3, "Max 3 characters"),
  addressLine1: stringRequired,
  addressPostcode: stringRequired.min(3, validPostcode).max(11, validPostcode),
  contactName: stringRequired,
  contactEmail: email,
  contactPhoneNumber: phone,
  // optional
  addressLine2: Yup.string(),
  addressCity: Yup.string(),
  addressCounty: Yup.string(),
  sicCode: Yup.string().max(10, "Max 10 characters"),
  notes: Yup.string(),
  bookingLimitDates: Yup.array().of(
    Yup.object().shape({
      date: stringRequired,
      limit: Yup.number().required(fieldRequired),
    }),
  ),
  bookingLimitDays: Yup.array().of(
    Yup.object().shape({
      day: Yup.number().required(fieldRequired),
      limit: Yup.number().required(fieldRequired),
    }),
  ),
  bigChangeContactId: Yup.number(),
  settings: Yup.object().shape({
    requireOrderNumber: Yup.boolean(),
    orderNumberValidation: Yup.string(),
    orderNumberValidationMessage: Yup.string(),
    orderNumberLabel: Yup.string(),
    requireContactDetails: Yup.boolean(),
    requirePhoneAndEmail: Yup.boolean(),
    canInvoice: Yup.boolean(),
    hidePrices: Yup.boolean(),
    hideInvoices: Yup.boolean(),
    hideTransferNotes: Yup.boolean(),
    hidePhotos: Yup.boolean(),
    identityProvider: Yup.string(),
    ssoOnly: Yup.boolean(),
  }),
});

export const updateCompany = Yup.object().shape({
  name: stringRequired,
  active: Yup.boolean(),
  companyCode: stringRequired.max(7, "Max 7 characters"),
  companyPrefix: Yup.string(),
  addressLine1: stringRequired,
  addressPostcode: stringRequired.min(3, validPostcode).max(11, validPostcode),
  contactName: stringRequired,
  contactEmail: email,
  contactPhoneNumber: phone,
  // optional
  invoiceAddress: Yup.object().shape(
    {
      addressLine1: Yup.string().when(["addressLine2", "city", "postcode"], {
        is: (addressLine2, city, postcode) =>
          !!addressLine2 || !!city || !!postcode,
        then: stringRequired,
      }),
      addressLine2: Yup.string(),
      city: Yup.string(),
      postcode: Yup.string().when(["addressLine1", "addressLine2", "city"], {
        is: (addressLine1, city, addressLine2) =>
          !!addressLine1 || !!city || !!addressLine2,
        then: stringRequired,
      }),
    },
    [["addressLine1", "postcode"]],
  ),
  type: Yup.string(),
  addressLine2: Yup.string(),
  addressCity: Yup.string(),
  addressCounty: Yup.string(),
  sicCode: Yup.string().max(10, "Max 10 characters"),
  resourceId: Yup.number().when("type", {
    is: (type) => type === "subcontractor",
    then: Yup.number().required(fieldRequired),
    otherwise: Yup.number(),
  }),
  priority: Yup.number().nullable().min(0).max(100),
  catalogue: Yup.string(),
  notes: Yup.string(),
  bigChangeContactId: Yup.number(),
  settings: Yup.object().shape(
    {
      requireOrderNumber: Yup.boolean(),
      orderNumberValidation: Yup.string().when("orderNumberValidationMessage", {
        is: (orderNumberValidationMessage) => !!orderNumberValidationMessage,
        then: stringRequired,
        otherwise: Yup.string(),
      }),
      orderNumberValidationMessage: Yup.string().when("orderNumberValidation", {
        is: (orderNumberValidation) => !!orderNumberValidation,
        then: stringRequired,
        otherwise: Yup.string(),
      }),
      orderNumberLabel: Yup.string(),
      requireContactDetails: Yup.boolean(),
      requirePhoneAndEmail: Yup.boolean(),
      canInvoice: Yup.boolean(),
      hidePrices: Yup.boolean(),
      hideInvoices: Yup.boolean(),
      hideTransferNotes: Yup.boolean(),
      hidePhotos: Yup.boolean(),
      identityProvider: Yup.string(),
      ssoOnly: Yup.boolean(),
      bucketKey: Yup.string(),
      services: Yup.array().of(Yup.string()),
      monthlyVolume: Yup.string(),
    },
    [["orderNumberValidationMessage", "orderNumberValidation"]],
  ),
  bookingLimitDates: Yup.array().of(
    Yup.object().shape({
      date: stringRequired,
      limit: Yup.number().required(fieldRequired),
    }),
  ),
  bookingLimitDays: Yup.array().of(
    Yup.object().shape({
      day: Yup.number().required(fieldRequired),
      limit: Yup.number().required(fieldRequired),
    }),
  ),
  reportsMeta: Yup.array().of(
    Yup.object().shape({
      name: lettersOnly,
      label: stringRequired,
      regex: Yup.string(),
      validationMessage: Yup.string().when("regex", {
        is: (regex) => !!regex,
        then: stringRequired,
        otherwise: Yup.string(),
      }),
      required: Yup.boolean(),
    }),
  ),
});
