import React from "react";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  Field,
  Input,
  Box,
  Panel,
  Heading,
  theme,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { SearchIcon } from "images";
import { buildQuery } from "helpers/api";
import { TFilters } from "api/types";

const initialValues = {
  status: "",
  orderRef: "",
  postcode: "",
  jobDate: "",
  email: "",
  createdDate: "",
};

const validationSchema = Yup.object().shape({
  status: Yup.string(),
  orderRef: Yup.string(),
  postcode: Yup.string(),
  jobDate: Yup.string(),
  email: Yup.string(),
  createdDate: Yup.string(),
});

interface PaymentFiltersProps {
  totalPaymentLinks: number;
  isFetching: boolean;
  setCurrentPage: (value: number) => void;
  setResultsPerPage: (value: number) => void;
  updateFilters: (filters: TFilters) => void;
}

// initial results per page for pagination
const initialResultsPerPage = 10;

const getInitialDate = (date: string): string[] => {
  if (!date) return [""];

  const dateArray = date.split(" - ");

  return dateArray.length === 1 ? [dateArray[0]] : dateArray;
};

export const PaymentLinksFilters = ({
  totalPaymentLinks,
  setCurrentPage,
  setResultsPerPage,
  isFetching,
  updateFilters,
}: PaymentFiltersProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const statusOptions = ["all", "pending", "paid", "expired"].map((value) => ({
    label: translate(`paymentLink.filteredStatus.${value}`),
    value: value === "all" ? "" : value,
  }));

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // payment link filters
    const status = params?.get("status:eq") ?? "";
    const postcode = params?.get("postcode:like") ?? "";
    const email = params?.get("email:like") ?? "";
    const orderRef = params?.get("orderRef:like") ?? "";
    const jobDate = params?.get("jobDate") ?? "";
    const createdDate = params?.get("createdDate") ?? "";

    return {
      status,
      postcode,
      email,
      orderRef,
      jobDate,
      createdDate,
    };
  };

  /**
   * handleSubmit
   * @param values
   */
  const handleSubmit = async (values: typeof initialValues) => {
    const { status, postcode, jobDate, createdDate, orderRef, email } = values;

    const updatedFilterObject = {
      ...(!!status ? { "status:eq": status } : {}),
      ...(!!postcode ? { "postcode:like": postcode } : {}),
      ...(!!email ? { "email:like": email } : {}),
      ...(!!orderRef ? { "orderRef:like": orderRef } : {}),
      ...(!!jobDate ? { jobDate: jobDate } : {}),
      ...(!!createdDate ? { createdDate: createdDate } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
      orderByDesc: "createdOn",
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/jobs/payment-links?${queryParam}`);
  };

  return (
    <Form
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
          }}
          shadow={false}
        >
          <Box className="flex flex-col gap-y-5 md:flex-row md:items-center md:justify-between">
            <Heading color="brand" level={4} className="flex-wrap text-center">
              {translate("paymentLink.headings.paymentLink", {
                count: totalPaymentLinks,
              })}
            </Heading>
            <RouteLink href="/jobs/create">
              <Button
                as="a"
                className="text-center"
                variant="outline"
                color="accent"
                size="small"
              >
                {translate("paymentLink.buttons.bookAJob")}
              </Button>
            </RouteLink>
          </Box>
          <Box className="border-t border-gray-200 my-4" />
          <Box className="flex flex-col gap-y-2 md:flex-row md:gap-x-3 md:items-center mb-3">
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="status"
              label={translate("paymentLink.form.labels.status")}
            >
              {({ field }) => (
                <Input.Select
                  {...field}
                  isSearchable
                  options={statusOptions}
                  placeholder={translate(
                    "paymentLink.form.placeholders.status",
                  )}
                  disabled={isFetching}
                  defaultValue=""
                />
              )}
            </Field>
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="email"
              label={translate("paymentLink.form.labels.email")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={translate("paymentLink.form.placeholders.email")}
                  disabled={isFetching}
                />
              )}
            </Field>
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="orderRef"
              label={translate("paymentLink.form.labels.orderRef")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isFetching}
                  placeholder={translate(
                    "paymentLink.form.placeholders.orderRef",
                  )}
                />
              )}
            </Field>
          </Box>
          <Box className="flex flex-col gap-y-2 md:flex-row md:gap-x-3 md:items-center mb-5">
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="postcode"
              label={translate("paymentLink.form.labels.postcode")}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isFetching}
                  placeholder={translate(
                    "paymentLink.form.placeholders.postcode",
                  )}
                />
              )}
            </Field>
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="jobDate"
              label={translate("paymentLink.form.labels.jobDate")}
            >
              {({ field }) => (
                <Input.RangedDate
                  {...field}
                  acceptSingleDate
                  placeholder={translate(
                    "paymentLink.form.placeholders.jobDate",
                  )}
                  disabled={isFetching}
                  collapsable
                  initialValue={getInitialDate(field.value)}
                />
              )}
            </Field>
            <Field
              styles={{ flex: 1, margin: 0 }}
              name="createdDate"
              label={translate("paymentLink.form.labels.createdDate")}
            >
              {({ field }) => (
                <Input.RangedDate
                  {...field}
                  acceptSingleDate
                  placeholder={translate(
                    "paymentLink.form.placeholders.createdDate",
                  )}
                  disabled={isFetching}
                  disabledDays={{
                    after: new Date(),
                  }}
                  collapsable
                  initialValue={getInitialDate(field.value)}
                />
              )}
            </Field>
          </Box>
          <Box className="flex flex-col gap-y-2 md:flex-row md:gap-x-2 md:justify-end">
            <Button
              color="negative"
              size="small"
              type="reset"
              onClick={() => {
                resetForm();
                updateFilters("");
                setCurrentPage(1);
                setResultsPerPage(initialResultsPerPage);
                history.push(
                  `/jobs/payment-links?page=1&resultsPerPage=${initialResultsPerPage}&orderByDesc=createdOn`,
                );
              }}
            >
              {translate("paymentLink.buttons.reset")}
            </Button>
            <Button
              id="submitPaymentLinkFilters"
              type="submit"
              disabled={isFetching}
              color="accent"
              size="small"
              className="flex justify-center items-center"
            >
              <SearchIcon className="mr-2 -mt-1" />
              {translate("paymentLink.buttons.search")}
            </Button>
          </Box>
        </Panel>
      )}
    </Form>
  );
};
