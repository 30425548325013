import {
  hasBooleanQuestionWithToggleAll,
  isCounterScrollableListInMeta,
  isDefaultSelectedInMeta,
} from "./questionModalHelpers";
import { statusMap } from "components/worksheets/updateWorksheet/updateWorksheetHelpers";
import type { TQuestion } from "../../../worksheetTypes";

export type InitialValues = {
  question: string;
  typeId: number | string;
  position?: number | string; // String to allow for inital value of "" and as string is returned from Input.Text
  bcQuestionId?: number | string; // As above
  parentId?: number | string; // As above
  displayCondition?: string;
  statusId?: keyof typeof statusMap | ""; // We only allow these statusIds to be sent: all others are either automatic or sent through Drivers App
  booleanParentQuestion?: boolean;
  counterAsList?: boolean;
  counterStart?: number;
  counterEnd?: number;
  counterIncrement?: number;
  typeValues?: Array<Required<TQuestion>["type"]["id"]>;
  modalText?: string;
  booleanQuestionToggleAll?: boolean;
  booleanQuestionRequiredValueTrue?: boolean;
  showForSubcontractor?: boolean;
};

export const getInitialValues = (
  questions: TQuestion[],
  question?: TQuestion,
): InitialValues => {
  // With numbers, initial values set to "". (null or undefined cause the warning, 'value' prop on input should not be null.)
  return {
    question: question?.question ?? "",
    // since showForSubcontractor value is 1 or 0, we can use the logical not operator to convert it to a boolean
    showForSubcontractor: question?.hasOwnProperty("showForSubcontractor")
      ? Boolean(question.showForSubcontractor)
      : true,
    typeId: question?.type.id ?? "",
    position: question?.position ?? "",
    // API will not send bcQuestionId if it is null at the DB level, so we need to check for this before accessing the first entry in the array, or it throws an error
    bcQuestionId: question?.questionIds.length
      ? question?.questionIds[0].bcQuestionId
      : "",
    parentId: question?.parentId ?? "",
    displayCondition: question?.displayCondition ?? "",
    statusId: question?.statusId ?? "",
    booleanParentQuestion:
      isDefaultSelectedInMeta(question?.meta ?? {}) || false,
    booleanQuestionToggleAll: hasBooleanQuestionWithToggleAll(questions),
    booleanQuestionRequiredValueTrue: question?.meta?.requiredValue ?? false,
    counterAsList: isCounterScrollableListInMeta(question?.meta ?? {}),
    counterStart: question?.meta?.start ?? 0, // These defaults are set in the Drivers app
    counterEnd: question?.meta?.end ?? 100, // As above
    counterIncrement: question?.meta?.increment ?? 1, // As above
    typeValues: question?.typeValues?.length
      ? question?.typeValues.map(({ id }) => {
          return id as number;
        })
      : [],
    modalText: question?.meta?.text ?? "",
  };
};
