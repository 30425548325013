"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subcontractors = void 0;
exports.subcontractors = {
    headings: {
        companyDetails: "Company Details",
        yourDetails: "Your Details",
        documents: "Documents",
    },
    form: {
        companyName: "Company Name",
        companyPhoneNumber: "Company Phone Number",
        companyRegistrationNumber: "Company Registration Number",
        isVatRegistered: "Are you VAT Registered?",
        vatRegistrationNumber: "VAT Registration Number",
        addressLine1: "Address Line 1",
        addressLine2: "Address Line 2",
        city: "City",
        county: "County",
        postcode: "Postcode",
        wasteCarriersLicenceLink: "Waste Carriers Licence (Link to EA registration)",
        linkToEARegistration: "Link to EA registration",
        firstName: "First Name",
        lastName: "Last Name",
        emailAddress: "Email Address",
        phoneNumber: "Phone Number",
        publicLiabilityInsurance: "Public Liability Insurance*",
        employersLiability: "Employers Liability*",
        wasteCarrierLicence: "Waste Carrier Licence*",
        vehicleInsurance: "Vehicle Insurance*",
        clickToAddFile: "Click to add a file",
    },
    messages: {
        missingDocuments: "Some of the documents are missing",
        formSubmitted: "Your account is now waiting to be approved. An email has been sent to you. You can use the temporary password to set a new password but you will be unable to log in until your account have been approved.",
        errorMessage: "Something went wrong, please try again",
    },
};
