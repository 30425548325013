import React from "react";
import { useTranslation } from "react-i18next";
import clipboardCopy from "clipboard-copy";
import { IFailedJob } from "@clearabee/api-schemas";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { toasts } from "helpers";
import "./custom_edittor_styles.css";

interface ReasonModalProps {
  failedJob: IFailedJob | null;
  setReasonModalOpen: (value: IFailedJob["message"] | null) => void;
  reason: IFailedJob["message"] | null;
}

export const ReasonModal = ({
  failedJob,
  reason,
  setReasonModalOpen,
}: ReasonModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  const { orderRef } = failedJob || {};

  return (
    <Modal
      modalVisible={!!reason}
      width={600}
      onClose={() => setReasonModalOpen(null)}
      styles={{
        padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
        paddingBottom: theme.spacing.xlarge,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.large} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.large,
        },
      }}
    >
      <>
        <Box className="flex items-center gap-2 mb-5">
          <Heading level={4} color="brand">
            {translate("failedJobs.headings.reason", {
              jobRef: orderRef || "",
            })}
          </Heading>
          <Button
            size="xsmall"
            className="flex items-center gap-x-1"
            onClick={async () => {
              await clipboardCopy(JSON.stringify(reason));
              toasts.success(
                {
                  message: translate("failedJobs.copied"),
                },
                { autoClose: 1000 },
              );
            }}
          >
            <Icon.Clipboard size="small" />
            {translate("failedJobs.buttons.copy")}
          </Button>
        </Box>
        {!!reason && (
          <AceEditor
            mode="json"
            theme="github"
            showGutter={false}
            editorProps={{ $blockScrolling: true }}
            width="100%"
            showPrintMargin={false}
            height="200px"
            highlightActiveLine={false}
            enableBasicAutocompletion={true}
            value={JSON.stringify(reason, null, 2)}
            style={{
              borderRadius: theme.spacing.xsmall2,
              borderWidth: "2px",
              borderColor: theme.colors.greyscale.lightest,
            }}
            readOnly
          />
        )}
      </>
    </Modal>
  );
};
