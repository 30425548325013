import * as Yup from "yup";
import {
  stringRequired,
  fieldRequired,
  postcodeRegExp,
  validPostcode,
} from "validation/common";

export const initialValues = {
  orderType: "",
  tip: "",
  garage: "",
  asset: "",
  date: "",
  plannedStartTime: "",
  description: "",
  deliveryAddress: {
    line1: "",
    line2: "",
    city: "",
    postcode: "",
    address: "",
  },
  isManualAddress: false,
};

export const validationSchema = Yup.object().shape(
  {
    orderType: stringRequired,
    tip: Yup.string().when("orderType", {
      is: (orderType) => orderType === "Tip",
      then: stringRequired,
      otherwise: Yup.string(),
    }),
    garage: Yup.string().when("orderType", {
      is: (orderType) => orderType === "Maintenance",
      then: stringRequired,
      otherwise: Yup.string(),
    }),
    asset: stringRequired,
    date: stringRequired,
    plannedStartTime: stringRequired,
    description: Yup.string().max(998),
    isMannualAddress: Yup.boolean(),
    isManualAddress: Yup.boolean(),
    deliveryAddress: Yup.object().shape({
      address: Yup.string().when("isManualAddress", {
        is: false,
        then: stringRequired,
        otherwise: Yup.string(),
      }),
      line1: stringRequired.max(255),
      line2: Yup.string().max(255),
      city: stringRequired.max(50),
      postcode: Yup.string()
        .matches(postcodeRegExp, validPostcode)
        .min(5)
        .max(50)
        .required(fieldRequired),
    }),
  },
  [
    ["orderType", "tip"],
    ["orderType", "garage"],
    ["isManualAddress", "deliveryAddress.address"],
  ],
);
