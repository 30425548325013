import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { QRCodeCanvas } from "qrcode.react";
import {
  Box,
  Button,
  Heading,
  Input,
  Message,
  Modal,
  theme,
  Text,
} from "@clearabee/ui-library";
import { useAuthContext, useScreenWidth } from "hooks";
import { getErrorMessage, toasts } from "helpers";
import { LoadingOverlay } from "components/common/components";

interface MultiFactorAuthProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const MultiFactorAuth = ({
  visible,
  setVisible,
}: MultiFactorAuthProps): React.ReactElement => {
  const { isDesktop, isMobile } = useScreenWidth();
  const { getCurrentUser, getCurrentUserEmailAddress } = useAuthContext();
  const currentUser = getCurrentUser();
  const currentUserEmail = getCurrentUserEmailAddress();
  const [errors, setErrors] = useState("");
  const [qrCode, setQRCode] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handle2FA = async () => {
    setErrors("");
    setIsLoading(true);

    try {
      const secretCode = await Auth.setupTOTP(currentUser);

      const code = `otpauth://totp/Clearabee Portal:${currentUserEmail}?secret=${secretCode}&issuer=${"Clearabee Portal"}`;

      setQRCode(code);
    } catch (error) {
      setErrors(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const verify2FACode = async () => {
    setIsLoading(true);
    setErrors("");

    try {
      await Auth.verifyTotpToken(currentUser, answer);

      // Don't forget to set TOTP as the preferred MFA method.
      await Auth.setPreferredMFA(currentUser, "TOTP");

      setVisible(false);
      toasts.success({
        message: "Two-factor authentication has been enabled successfully.",
      });
    } catch (error) {
      setErrors(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      handle2FA();
    }
  }, [visible]);

  const reset = () => {
    setIsLoading(false);
    setErrors("");
    setQRCode("");
    setAnswer("");
    setVisible(false);
  };

  return (
    <Modal
      width={!isMobile ? 600 : 300}
      modalVisible={visible}
      onClose={reset}
      styles={{
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: theme.spacing.large,
        },
      }}
    >
      {/* MODAL LOADING OVERLAY */}
      {isLoading && (
        <LoadingOverlay
          iconSize="xlarge3"
          backgroundLoadingContainerStyles={{
            borderRadius: theme.spacing.small,
          }}
        />
      )}
      <Box
        className="flex flex-col items-center gap-y-4 overflow-y-scroll"
        styles={{
          maxHeight: "70vh",
          marginTop: isMobile ? theme.spacing.large : theme.spacing.xsmall,
        }}
      >
        <Heading level={4} color="brand">
          Scan QR Code
        </Heading>
        {!!qrCode && (
          <>
            <Text fontSize="small" className="px-10">
              <span className="font-bold">Step 1:</span> Ensure you have an
              authentication app downloaded on your phone such as Google
              Authenticator, etc.
            </Text>
            <Text fontSize="small" className="px-10 pb-1">
              <span className="font-bold">Step 2:</span> Scan the QR code below
              with your authenticator app.
            </Text>

            <QRCodeCanvas
              value={qrCode}
              style={{
                width: isDesktop ? "260px" : "150px",
                height: isDesktop ? "260px" : "150px",
              }}
            />
            <Text fontSize="small" className="px-10">
              <span className="font-bold">Step 3:</span> Enter the code given to
              you by your authenticator app into the field below.
            </Text>
            <Input.Text
              styles={{
                ...theme.fontDefaults.xlarge2,
                width: isDesktop ? "50%" : "80%",
                textAlign: "center",
                fontWeight: "bold",
                letterSpacing: theme.spacing.xsmall,
              }}
              onChange={(event) => setAnswer(event.target.value)}
            />
            <Button
              onClick={verify2FACode}
              size="small"
              type="button"
              disabled={false}
            >
              {"Verify Code"}
            </Button>
          </>
        )}
        {/* DISPLAY ERROR */}
        {!!errors && (
          <Message type="error" background className="mt-2 mx-auto">
            {errors}
          </Message>
        )}
      </Box>
    </Modal>
  );
};
