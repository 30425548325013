import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

/**
 * Import components.
 */
import {
  Form,
  Button,
  displayErrorMessage,
  Message,
  Box,
  Modal,
  Heading,
  theme,
} from "@clearabee/ui-library";
import { BackButton } from "../common/components";

/**
 * Import hooks.
 */
import { useAuthContext } from "../../hooks";

/**
 * Import api.
 */
import { userCompanies, readRoles } from "../../api";

/**
 * Import validation.
 */
import {
  createUserValidation,
  createUserInitialValues,
  createUserObject,
} from "./validation";
import roles from "constants/roles";
import { CreateUserRow } from "./components/createUserRow/createUserRow";
import { FieldArray } from "formik";

export const CreateUser = (): React.ReactElement => {
  const history = useHistory();
  /**
   * Permissions
   */
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);

  /**
   * i18n translation.
   */
  const [t] = useTranslation("users");

  /**
   * State.
   */
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  /**
   * Query hook.
   */
  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery(
    "userCompanies",
    userCompanies,
  );
  const { data: rolesData, isLoading: isLoadingRoles } = useQuery(
    "readRoles",
    readRoles,
  );

  return (
    <>
      <Box className="mb-10">
        <BackButton
          text={t("user.buttons.backToUsers")}
          onClick={() => history.goBack()}
        />
      </Box>
      <Form
        initialValues={createUserInitialValues}
        validationSchema={createUserValidation}
        onSubmit={() => {
          setIsFormSubmitted(true);
        }}
        onReset={() => {
          setIsFormSubmitted(false);
        }}
      >
        {({ values, submitCount, errors, handleSubmit }) => (
          <>
            <FieldArray name="user">
              {({ push, remove }) => (
                <>
                  {values.user.map((_, index) => (
                    <CreateUserRow
                      index={index}
                      isLoadingCompanies={isLoadingCompanies}
                      isLoadingRoles={isLoadingRoles}
                      companiesData={companiesData || []}
                      rolesData={rolesData?.items || []}
                      formSubmitted={isFormSubmitted}
                      isClearabeeAdmin={isClearabeeAdmin}
                      key={index}
                      onRemove={() => remove(index)}
                    />
                  ))}

                  <Box className="flex justify-end">
                    <Box className="rounded-lg">
                      <Box className="flex gap-5 justify-end mb-2">
                        <Button
                          size="medium"
                          name="update"
                          color="accent"
                          type="button"
                          disabled={isFormSubmitted}
                          onClick={() => {
                            setIsCreateUserModalOpen(true);
                          }}
                        >
                          {t("user.buttons.submit")}
                        </Button>
                        {isFormSubmitted ? (
                          <Button size="medium" type="reset" color="negative">
                            {t("user.buttons.resetForm")}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            onClick={() => push(createUserObject)}
                            size="medium"
                          >
                            {t("user.buttons.addAnother")}
                          </Button>
                        )}
                      </Box>
                      {!!submitCount &&
                        !!Object.keys(errors).length &&
                        displayErrorMessage(
                          t("errors.fieldsMissing"),
                          ({ children }) => (
                            <Box className="flex flex-col items-center mt-2">
                              <Message
                                background
                                type="error"
                                styles={{
                                  textAlign: "center",
                                }}
                              >
                                {children}
                              </Message>
                            </Box>
                          ),
                        )}
                    </Box>
                  </Box>
                </>
              )}
            </FieldArray>
            <Modal
              styles={{
                [`@media (min-width: ${theme.screens.medium})`]: {
                  padding: theme.spacing.large,
                },
              }}
              width={500}
              modalVisible={isCreateUserModalOpen}
            >
              <Heading level={3} color="brand">
                {t("user.modals.headings.doYouWantToCreateUser")}
              </Heading>
              <Box className="flex min-w-full justify-center gap-5 mt-8">
                <Button
                  color="negative"
                  type="button"
                  size="medium"
                  onClick={() => setIsCreateUserModalOpen(false)}
                >
                  {t("user.buttons.no")}
                </Button>
                <Button
                  color="positive"
                  size="medium"
                  type="button"
                  onClick={() => {
                    setIsCreateUserModalOpen(false);
                    handleSubmit();
                  }}
                >
                  {t("user.buttons.yes")}
                </Button>
              </Box>
            </Modal>
          </>
        )}
      </Form>
    </>
  );
};
