import React, { useMemo } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Box,
  Heading,
  Text,
  Button,
  Form,
  Field,
  Input,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { BulkAmendItem } from "../helpers/helpers";
import { stringRequired } from "validation/common";
import { CheckboxSelect } from "components/common/components/CheckboxSelect/CheckboxSelect";

dayjs.extend(utc);

interface BulkAmendModalProps {
  showModal: boolean;
  vehicles: Array<{ name: string; asset: string }>;
  dates: string[];
  setShowModal: (value: boolean) => void;
  onChangeBulkAmend: (bulkAmendArray: BulkAmendItem[]) => void;
  removeWeekend: (value: number) => void;
}

interface InitialValuesProps {
  vehicles: Array<{ value: string; label: string }>;
  date: string;
  time: string;
  message: string;
}

const initialValues: InitialValuesProps = {
  vehicles: [],
  date: "",
  time: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  vehicles: Yup.array().of(Yup.string()),
  date: stringRequired,
  time: Yup.string(),
  message: Yup.string(),
});

export const BulkAmendModal = ({
  dates,
  vehicles,
  onChangeBulkAmend,
  showModal,
  setShowModal,
  removeWeekend,
}: BulkAmendModalProps): React.ReactElement => {
  /**
   * Vehicle options
   */
  const vehicleOptions = useMemo(() => {
    if (!vehicles.length) return [];

    return vehicles.map(({ asset, name }) => ({
      label: name || asset,
      value: asset,
    }));
  }, [vehicles]);

  /**
   * Date options
   */
  const dateOptions = useMemo(() => {
    if (!dates.length) return [];

    return dates.map((date) => ({
      value: date,
      label: dayjs.utc(date).format("DD/MM/YYYY"),
    }));
  }, [dates]);

  /**
   * Handle form submit
   */
  const handleSubmit = (values: InitialValuesProps) => {
    const bulkAmendArray: BulkAmendItem[] = values.vehicles.map(
      ({ value: registration }) => ({
        date: values.date,
        time: values.time,
        registration,
        message: values.message,
      }),
    );
    onChangeBulkAmend(bulkAmendArray);
  };

  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Box className="flex flex-col gap-y-3">
                <Heading color="brand" level={4}>
                  Bulk Amend
                </Heading>

                <Text className="px-12">
                  These buttons will remove all users whose weekend rotation is
                  either 1 or 2. Remember to save your changes.
                </Text>

                <Box className="flex flex-row justify-center items-center gap-12 mt-6">
                  <Button
                    color="warning"
                    size="small"
                    onClick={() => removeWeekend(1)}
                    type="button"
                  >
                    Weekend 1
                  </Button>

                  <Button
                    color="warning"
                    size="small"
                    onClick={() => removeWeekend(2)}
                    type="button"
                  >
                    Weekend 2
                  </Button>
                </Box>

                <Box className="w-full border-b my-6" />

                <Text className="px-12">
                  This allows you to edit the inputs on the schedule planner.
                  Remeber to save your changes after bulk amending if you want
                  to keep them.
                </Text>

                <Box className="flex flex-col w-3/4 text-left mx-auto">
                  {/* TIME */}
                  <Field name="time" label="Time">
                    {({ field }) => (
                      <Input.Text {...field} type="time" placeholder="Time" />
                    )}
                  </Field>
                  {/* MESSAGE */}
                  <Field name="message" label="Message">
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        autoGrow={false}
                        placeholder="Message"
                        styles={{ maxHeight: 100, minHeight: 100 }}
                      />
                    )}
                  </Field>

                  <Text color="brand" className="font-bold">
                    Bulk Amend Targets
                  </Text>
                  {/* VEHICLES */}
                  <Field name="vehicles" label="Vehicles">
                    {({ field }) => (
                      <CheckboxSelect
                        {...field}
                        options={vehicleOptions}
                        isLoadingOptions={!vehicleOptions.length}
                        selectAllOptionsLabel="All Vehicles"
                        disabled={!vehicleOptions.length}
                        placeholder="Select Vehicles"
                        onClearAll={() => setFieldValue("vehicles", [])}
                        onChange={(options) => {
                          if (!options) {
                            setFieldValue("vehicles", []);
                            return;
                          }

                          setFieldValue("vehicles", options);
                        }}
                        menuListStyles={{
                          maxHeight: 300,
                        }}
                      />
                    )}
                  </Field>
                  {/* DATE */}
                  <Field name="date" label="Date">
                    {({ field }) => (
                      <Input.Select
                        {...field}
                        isClearable
                        placeholder="Select a date"
                        options={dateOptions}
                      />
                    )}
                  </Field>
                  {/* SUBMIT */}
                  <Box className="mx-auto mt-5">
                    <Button type="submit" size="small">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
