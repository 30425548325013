import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  Form,
  UnstyledButton,
  Text,
  Icon,
  theme,
  formatPostcode,
  Input,
  Field,
} from "@clearabee/ui-library";
import { IJob } from "@clearabee/api-schemas";
import { truncateString } from "helpers";

interface UnScheduledJobListProps {
  data: IJob[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  selectedJob: IJob | null;
  setSelectedJob: (value: IJob | null) => void;
  isFullScreen: boolean;
}
export const UnScheduledJobList = ({
  data: allUnscheduledJobs,
  isLoading,
  isError,
  refetch,
  selectedJob,
  setSelectedJob,
  isFullScreen = false,
}: UnScheduledJobListProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <Form
      initialValues={{ searchRef: "", searchPostcode: "" }}
      validationSchema={Yup.object({
        searchRef: Yup.string(),
        searchPostcode: Yup.string(),
      })}
      onSubmit={() => {
        return;
      }}
    >
      {({ values, resetForm }) => (
        <>
          <Box className="relative flex justify-center mb-1">
            <Text fontSize="xsmall" className="font-semibold uppercase">
              {translate("schedule.headings.unscheduledJobsCount", {
                count: allUnscheduledJobs?.length ?? 0,
              })}
            </Text>
            <UnstyledButton
              type="button"
              disabled={isLoading}
              className="absolute right-0"
              onClick={() => {
                refetch();
                resetForm();
              }}
            >
              <Icon.Refresh size="small" />
            </UnstyledButton>
          </Box>
          <Box
            styles={{
              borderWidth: "1px",
              borderColor: theme.colors.brand.lighter,
              padding: theme.spacing.xsmall,
              borderRadius: theme.spacing.xsmall,
            }}
          >
            <Box className="flex gap-x-2 mb-2">
              <Field
                styles={{
                  margin: 0,
                }}
                name="searchRef"
              >
                {({ field }) => (
                  <>
                    <Text fontSize="xsmall2" className="font-medium">
                      {translate("schedule.form.labels.searchJobRef")}
                    </Text>
                    <Input.Text
                      {...field}
                      disabled={isLoading}
                      placeholder={translate(
                        "schedule.form.placeholders.searchJobRef",
                      )}
                    />
                  </>
                )}
              </Field>
              <Field
                styles={{
                  margin: 0,
                }}
                name="searchPostcode"
              >
                {({ field }) => (
                  <>
                    <Text fontSize="xsmall2" className="font-medium">
                      {translate("schedule.form.labels.searchPostcode")}
                    </Text>
                    <Input.Text
                      {...field}
                      disabled={isLoading}
                      placeholder={translate(
                        "schedule.form.placeholders.searchPostcode",
                      )}
                    />
                  </>
                )}
              </Field>
            </Box>
            <Box
              styles={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing.xsmall,
                overflowY: "scroll",
                height: isFullScreen ? "70vh" : "69vh",
              }}
            >
              {/* LOADING */}
              {isLoading && (
                <Box className="flex items-center justify-center gap-x-1 my-1">
                  <Text fontSize="xsmall" color="brand">
                    {translate("schedule.loadingMessage", {
                      type: "jobs",
                    })}
                  </Text>
                  <Icon.Loading color="brand" size="small" />
                </Box>
              )}
              {/* DISPLAY JOBS */}
              {!!allUnscheduledJobs?.length &&
                !isLoading &&
                allUnscheduledJobs
                  .filter(({ ref, addressPostcode }) => {
                    const isRefIncluded = ref
                      .replaceAll(" ", "")
                      .toLowerCase()
                      .includes(
                        values.searchRef.replaceAll(" ", "").toLowerCase(),
                      );

                    const isPostcodeIncluded = addressPostcode
                      .replaceAll(" ", "")
                      .toLowerCase()
                      .includes(
                        values.searchPostcode.replaceAll(" ", "").toLowerCase(),
                      );

                    if (!values.searchRef && !values.searchPostcode) {
                      return true;
                    }

                    if (!values.searchRef && !!values.searchPostcode) {
                      return isPostcodeIncluded;
                    }

                    if (!!values.searchRef && !values.searchPostcode) {
                      return isRefIncluded;
                    }

                    if (!!values.searchRef && !!values.searchPostcode) {
                      return isRefIncluded && isPostcodeIncluded;
                    }
                  })
                  .map((job) => {
                    const isSelected = selectedJob?.id === job.id;

                    return (
                      <UnstyledButton
                        key={`unscheduled-job-${job.id}`}
                        type="button"
                        onClick={() => setSelectedJob(job)}
                      >
                        <Box
                          className={`h-20 flex flex-col items-start gap-y-1 py-2 pl-2 rounded-md bg-gray-200 hover:bg-gray-400 ${
                            isSelected && "bg-green-light hover:bg-green-dark"
                          }`}
                        >
                          <Text
                            fontSize="small"
                            color="brand"
                            className="font-semibold"
                          >
                            {job.ref}
                          </Text>
                          <Text fontSize="small" className="font-semibold">
                            {truncateString(job?.type ?? "", 15)}
                          </Text>
                          <Text fontSize="small">
                            {formatPostcode(job.addressPostcode)}
                          </Text>
                        </Box>
                      </UnstyledButton>
                    );
                  })}
              {/* NO JOBS */}
              {!allUnscheduledJobs?.length && !isLoading && (
                <Text
                  fontSize="small"
                  color="greyscale"
                  className="text-center uppercase opacity-70"
                >
                  {translate("schedule.errors.noJobsAvailable")}
                </Text>
              )}
              {/* DISPLAY ERROR */}
              {isError && (
                <Text fontSize="small" color="negative" className="text-center">
                  {translate("schedule.errors.clickRefreshIcon", {
                    type: "unscheduled jobs",
                  })}
                </Text>
              )}
            </Box>
          </Box>
        </>
      )}
    </Form>
  );
};
