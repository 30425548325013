import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import {
  Form,
  Field,
  Button,
  Input,
  Box,
  theme,
  Heading,
} from "@clearabee/ui-library";
import { TFilters } from "../../../../api/types";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "images";
import { buildQuery } from "helpers/api";

interface CompanyFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
}

interface InitialValuesProps {
  name: string;
  email: string;
  companyCode: string;
  active: string;
  type: { label: string; value: string }[] | null;
}

const activeOptions = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

const typeOptions = [
  {
    label: "Commercial",
    value: "commercial",
  },
  {
    label: "Subcontractor",
    value: "subcontractor",
  },
  {
    label: "Supplier",
    value: "supplier",
  },
];

// initial results per page for pagination
const initialResultsPerPage = 10;

export const CompanyFilters = ({
  isFetching,
  updateFilters,
  currentPage,
  setCurrentPage,
  resultsPerPage,
  setResultsPerPage,
}: CompanyFiltersProps): React.ReactElement => {
  const [translate] = useTranslation("companies");
  const history = useHistory();
  const params = new URLSearchParams(location.search.replace("?", ""));

  /**
   * getInitialValues
   */
  const getInitialValues = (): InitialValuesProps => {
    //companies filters
    const name = params?.get("name:like") ?? "";
    const active = params?.get("active:eq") ?? "";
    const typeValues = params?.get("type:in") ?? "";
    const companyCode = params?.get("companyCode:like") ?? "";
    const contactEmail = params?.get("contactEmail:like") ?? "";

    const type = !!typeValues.length
      ? typeValues.split(",").map((item) => ({ label: item, value: item }))
      : [];

    return {
      name,
      companyCode,
      email: contactEmail,
      active,
      type,
    };
  };

  const handleSubmit = (values: InitialValuesProps) => {
    const { name, active, companyCode, email, type: typeValues } = values;
    const type = typeValues?.map((item) => item.value).join(",");

    const updatedFilterObject = {
      ...(!!name ? { "name:like": name } : {}),
      ...(!!type ? { "type:in": type } : {}),
      ...(!!active ? { "active:eq": active } : {}),
      ...(!!companyCode ? { "companyCode:like": companyCode } : {}),
      ...(!!email ? { "contactEmail:like": email } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/companies?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(`${currentUrlWithoutBase}?page=1&resultsPerPage=10`);
  }, [currentPage, resultsPerPage]);

  return (
    <Form
      key={JSON.stringify(getInitialValues())}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <>
          <Box className="flex flex-row items-center justify-between">
            <Heading level={1} fontSize="large" color="brand">
              {translate("common.title")}
            </Heading>
            <Link to="/companies/create">
              <Button size="small" className="inline-block" type="button">
                {translate("common.addCompany")}
              </Button>
            </Link>
          </Box>
          <Box className="border-t border-grey-200 mt-4 mb-2" />
          <Box className="grid grid-cols-3 gap-x-1">
            <Field
              styles={{ margin: theme.spacing.xsmall2 }}
              label={translate("filters.optionLabels.name")}
              name="name"
            >
              {({ field }) => <Input.Text {...field} />}
            </Field>
            <Field
              styles={{ margin: theme.spacing.xsmall2 }}
              name="email"
              label={translate("filters.optionLabels.contactEmail")}
            >
              {({ field }) => <Input.Text {...field} />}
            </Field>
            <Field
              styles={{ margin: theme.spacing.xsmall2 }}
              name="companyCode"
              label={translate("filters.optionLabels.companyCode")}
            >
              {({ field }) => <Input.Text {...field} />}
            </Field>
            <Field
              styles={{ margin: theme.spacing.xsmall2 }}
              name="active"
              label={translate("filters.optionLabels.active")}
            >
              {({ field }) => (
                <Input.Select {...field} options={activeOptions} />
              )}
            </Field>
            <Field
              styles={{ margin: theme.spacing.xsmall2 }}
              name="type"
              label={translate("filters.optionLabels.type")}
            >
              {({ field }) => (
                <Select
                  {...field}
                  options={typeOptions}
                  isClearable
                  isSearchable
                  styles={{
                    multiValueLabel: (provided) => ({
                      ...provided,
                      ...theme.fontDefaults.xsmall,
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                  isMulti
                  multiple
                />
              )}
            </Field>
            <Box className="flex justify-end items-end">
              <Box className="mb-1 flex gap-2">
                <Button
                  type="submit"
                  size="small"
                  color="accent"
                  className="w-full flex justify-center items-center"
                  disabled={isFetching}
                >
                  <SearchIcon className="mr-2" />
                  {translate("common:form.buttons.search")}
                </Button>
                <Button
                  type="reset"
                  size="small"
                  variant="outline"
                  color="negative"
                  onClick={() => {
                    resetForm();
                    updateFilters("");
                    setCurrentPage(1);
                    setResultsPerPage(initialResultsPerPage);
                    history.push(
                      `/companies?page=1&resultsPerPage=${initialResultsPerPage}`,
                    );
                  }}
                >
                  {translate("common:form.buttons.reset")}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Form>
  );
};
