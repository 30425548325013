import * as Yup from "yup";
import {
  stringRequired,
  email,
  postcodeRegExp,
  validPostcode,
  phoneRegExp,
  validPhone,
  fieldRequired,
} from "../../validation/common";
import { isCountryCode, isValidInternationalNumber } from "helpers";

const optionalValidInternationalPhoneNumber = Yup.string().test(
  "check-if-populated",
  "Must be a valid international phone number",
  (phoneNumber: string) => {
    if (isCountryCode(phoneNumber)) {
      return true;
    }

    return isValidInternationalNumber(phoneNumber);
  },
);

export interface ICreateUserFormState {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    roles: { label: string; value: string }[];
    companies?: { label: string; value: string }[];
    defaultCompany?: { label: string; value: string }[];
  }[];
}

const optionObject = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});

export const createUserValidation = Yup.object().shape({
  user: Yup.array().of(
    Yup.object().shape({
      firstName: stringRequired,
      lastName: stringRequired,
      email: email,
      phoneNumber: optionalValidInternationalPhoneNumber,
      companies: Yup.array().of(optionObject).required(fieldRequired).min(1),
      defaultCompany: Yup.array().of(optionObject).notRequired(),
      roles: Yup.array().of(optionObject).required(fieldRequired).min(1),
    }),
  ),
});

export const createUserObject = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roles: [],
  companies: [],
  defaultCompany: [],
};

export const createUserInitialValues: ICreateUserFormState = {
  user: [createUserObject],
};

export const updateUser = Yup.object().shape(
  {
    firstName: stringRequired,
    lastName: stringRequired,
    email: email,
    phoneNumber: optionalValidInternationalPhoneNumber,
    companies: Yup.array().when("roles", {
      is: (roles) => !!roles && !!roles.length,
      then: Yup.array().of(optionObject).min(1).required(fieldRequired),
      otherwise: Yup.array().of(optionObject),
    }),
    roles: Yup.array().when("companies", {
      is: (companies) => !!companies && !!companies.length,
      then: Yup.array().of(optionObject).min(1).required(fieldRequired),
      otherwise: Yup.array().of(optionObject),
    }),
    defaultCompany: Yup.array().of(optionObject),
  },
  [["companies", "roles"]],
);

export const createSubcontractor = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp, validPhone),
  wc_license: stringRequired,
  email: email,
  password: stringRequired,
  company: Yup.string(),
  postcode: Yup.string().matches(postcodeRegExp, validPostcode).required(),
});

export const updateSubcontractor = Yup.object().shape({
  username: Yup.string(),
  email: Yup.string(),
  wc_license: Yup.string(),
  phone: Yup.string(),
  company: Yup.string(),
  postcode: Yup.string(),
  status: Yup.string(),
  on_stop: Yup.boolean(),
});
