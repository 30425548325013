import React from "react";
import { css } from "@emotion/react";
import { Icon, UserStylesProps } from "@clearabee/ui-library";
import { styles } from "./InputPassword.styles";

export interface InputPasswordProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  error?: string;
  success?: boolean;
  prefix?: string;
}

export const InputPassword = React.forwardRef<
  HTMLInputElement,
  InputPasswordProps
>(
  (
    { error, success, styles: userStyles, prefix, ...rest }: InputPasswordProps,
    ref,
  ): React.ReactElement => {
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const inputElement = (
      <div css={css(styles.inputContainer)}>
        <input
          {...rest}
          css={css(
            styles.input,
            prefix && styles.inputPrefix,
            success && styles.success,
            error && styles.error,
            userStyles,
          )}
          autoFocus={false}
          type={passwordVisible ? "text" : "password"}
          ref={ref}
        />
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setPasswordVisible(!passwordVisible);
          }}
          css={css(styles.eyeButton)}
        >
          {passwordVisible ? (
            <Icon.Eye color="brand" size="medium" />
          ) : (
            <Icon.EyeClosed color="brand" size="medium" />
          )}
        </button>
      </div>
    );

    if (!prefix) {
      return inputElement;
    }

    return (
      <div css={styles.container}>
        {prefix && <div css={styles.prefix}>{prefix}</div>}
        {inputElement}
      </div>
    );
  },
);
