import React from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IJobWorksheetData } from "@clearabee/api-schemas";
import {
  Heading,
  Icon,
  Panel,
  Box,
  Text,
  Gallery,
  Accordion,
  UnstyledButton,
} from "@clearabee/ui-library";

interface WorksheetsAnswersProps {
  jobRef: string;
}

const renderAnswer = (questionType: string, answer: string) => {
  switch (questionType) {
    case "signature": {
      return (
        <Gallery items={[{ source: answer }]} backgroundColor="light">
          {({ handleClick }) => (
            <UnstyledButton onClick={() => handleClick(0)}>
              <Box className="w-48 h-64">
                <img src={answer} className="h-full border-grey border-2" />
              </Box>
            </UnstyledButton>
          )}
        </Gallery>
      );
    }
    case "image": {
      return (
        <Gallery items={[{ source: answer }]}>
          {({ handleClick }) => (
            <UnstyledButton onClick={() => handleClick(0)}>
              <Box className="w-48 h-64">
                <img src={answer} className="h-full border-grey border-2" />
              </Box>
            </UnstyledButton>
          )}
        </Gallery>
      );
    }
    case "boolean": {
      if (answer.toLowerCase() === "true") {
        return <Text>Yes</Text>;
      } else {
        return <Text>No</Text>;
      }
    }
    default: {
      return <Text>{answer}</Text>;
    }
  }
};

const getAllWorksheets = (answers: IJobWorksheetData[]) => {
  const worksheets: string[] = [];
  answers.forEach((answer) => {
    if (!worksheets.includes(answer.worksheetName)) {
      worksheets.push(answer.worksheetName);
    }
  });
  return worksheets;
};

export const WorksheetsAnswers = ({
  jobRef,
}: WorksheetsAnswersProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { data: worksheetsAnswers, isLoading } = useQuery(
    ["getWorksheetsAnswers", jobRef],
    async () => {
      return (
        await instance.worksheets.getJobWorksheetData(
          encodeURIComponent(jobRef),
        )
      ).data;
    },
  );

  return (
    <Panel shadow={false}>
      <Heading level={5} color="brand">
        {translate("headings.worksheets")}
      </Heading>
      {isLoading && (
        <Box className="flex justify-center my-10">
          <Icon.Loading />
        </Box>
      )}
      {!!worksheetsAnswers?.length && (
        <Accordion color="brand">
          {getAllWorksheets(worksheetsAnswers).map((worksheetName, index) => {
            const worksheetAnswers = worksheetsAnswers.filter(
              (answer) => answer.worksheetName === worksheetName,
            );

            return (
              <Box key={index} className="my-5">
                <Accordion.Item
                  key={index}
                  index={String(index)}
                  label={worksheetName}
                >
                  {worksheetAnswers.map(
                    ({ question, answer, questionType }, index) => (
                      <Box key={index} className="flex flex-col">
                        <Box className="font-semibold mb-1">
                          <Text>{question}</Text>
                        </Box>
                        {renderAnswer(questionType, answer)}
                        {index !== worksheetAnswers.length - 1 && (
                          <Box className="border-t border-gray-400 mt-4 mb-4" />
                        )}
                      </Box>
                    ),
                  )}
                </Accordion.Item>
              </Box>
            );
          })}
        </Accordion>
      )}
      {!isLoading && !worksheetsAnswers?.length && (
        <Box className="text-center my-3">
          <Text>{translate("messages.noData")}</Text>
        </Box>
      )}
    </Panel>
  );
};
