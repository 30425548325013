import React, { useState, useCallback } from "react";

/**
 * Import components.
 */
import { Wrapper } from "./components";
import { DateAddress, SelectItems, CollectionDetails, Payment } from "./steps";
import { MultiForm } from "../../common/components";

/**
 * Import hooks.
 */
import { useAuthContext, useCatalogueContext } from "../../../hooks";

/**
 * Import Contexts.
 */
import { ProvideCatalogue, ProvideBasket } from "../../../contexts";

/**
 * Import form initial values.
 */
import { getInitialValues } from "./validation";

/**
 * Import types.
 */
import { IMultiFormStep } from "../../common/components/multiForm/types";
import { ISelectOption } from "../../core/select/simple";
import { IImage } from "../types";

/**
 * Import roles.
 */
import roles from "constants/roles";

export const createJobSteps: IMultiFormStep[] = [
  {
    id: "date-address",
    component: DateAddress,
    name: "Select address",
    description: "Select the company you are booking for.",
  },
  {
    id: "select-items",
    component: SelectItems,
    name: "Select items",
    description:
      "From the options below select the type of service you require.",
  },
  {
    id: "collection-details",
    component: CollectionDetails,
    name: "Collection details",
    description: "Tell us more about the collection",
  },
  {
    id: "payment",
    component: Payment,
    name: "Order overview",
    description: "Review the order details and confirm payment method",
  },
];

export interface ICreateJobFormState {
  company: ISelectOption;
  companyCanInvoice?: boolean;
  companyOrderNumberRequired?: boolean;
  orderNumberLabel?: string;
  orderNumberValidation?: string;
  orderNumberValidationMessage?: string;
  contactDetailsRequired?: boolean;
  phoneAndEmailRequired?: boolean;
  collectionDate: string;
  collectionAddress: {
    line1: string;
    line2: string;
    city: string;
    county: string;
    postcode: string;
    lat: number | null;
    lng: number | null;
  };
  meta: {
    poNumber: string;
    zendeskTicketId: string;
    wasteType: string;
    what3words: string;
  };
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  noContactDetails: boolean;
  wasteDescription: string;
  accessInformation: string;
  addressLookupPostcode: string;
  addressChoices: string;
  landType: string;
  timeslot: string;
  timeslotPreference: string;
  isCustomTimeslot: boolean;
  customTimeslot: {
    startTime: string;
    endTime: string;
    charge: number;
  };
  timeslotCharge: number;
  images: IImage[];
  sicCode: string;
  useAdhoc: boolean;
  bigchangeProps: {
    cust_RiskPostcode: string;
    cust_RiskAddressIsCollectionAddress: boolean;
    "cust_Is the customer vulnerable": boolean;
    "cust_VC Notes": string;
  };
  reportsMetaValidation?: {
    name: string;
    label: string;
    required: boolean;
    regex?: string;
    validationMessage?: string;
  }[];
  reportsMeta?: {
    [key: string]: string;
  };
}

export const CreateJobStepsWrapper = (): React.ReactElement => {
  const { getCurrentUserCompanies } = useAuthContext();
  const userCompanies = getCurrentUserCompanies() ?? {};

  const activeCompanies = userCompanies.filter(({ active }) => active === true);

  const { setPostcode: setCataloguePostcode } = useCatalogueContext();

  const [formState, setFormState] = useState<ICreateJobFormState>(
    getInitialValues({
      company: {
        label: activeCompanies[0].name,
        value: activeCompanies[0].companyCode,
      },
    }),
  );

  /**
   * Handle state change of multiform state.
   * @param state Form state.
   */
  const handleStateChange = useCallback((state: ICreateJobFormState) => {
    setFormState(state);
    if (state.collectionAddress.postcode)
      setCataloguePostcode(state.collectionAddress.postcode);
  }, []);

  return (
    <>
      <MultiForm
        steps={createJobSteps}
        initialValues={formState}
        onPushState={handleStateChange}
        wrapperComponent={Wrapper}
      />
    </>
  );
};

export const CreateJob = (): React.ReactElement => {
  const { getCurrentUserCurrentCompany, doesUserHaveRole } = useAuthContext();
  const isClearabeeStaff = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const { companyCode } = getCurrentUserCurrentCompany() ?? {};

  return (
    <ProvideCatalogue
      catalogueId="PORTAL"
      postcode={null}
      companyCode={isClearabeeStaff ? null : companyCode}
    >
      <ProvideBasket>
        <CreateJobStepsWrapper />
      </ProvideBasket>
    </ProvideCatalogue>
  );
};
