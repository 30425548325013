import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  active: Yup.boolean(),
  description: Yup.string(),
  trigger: stringRequired,
});

export const initialValues = {
  name: "",
  active: false,
  description: "",
  trigger: "",
};
