import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import clipboardCopy from "clipboard-copy";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { IFailedJob } from "@clearabee/api-schemas";
import {
  Box,
  Button,
  Heading,
  Icon,
  Message,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import { getErrorMessage, toasts } from "helpers";
import "./custom_edittor_styles.css";

interface PayloadModalProps {
  failedJob: IFailedJob | null;
  setPayloadModalOpen: (value: IFailedJob["payload"] | null) => void;
  payload: IFailedJob["payload"] | null;
  onRefetch?: () => void;
}

const checkIfJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const PayloadModal = ({
  failedJob,
  payload,
  setPayloadModalOpen,
  onRefetch,
}: PayloadModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [overrideStringifyPayload, setOverrideStringifyPayload] = useState("");

  useEffect(() => {
    if (!!payload) {
      setOverrideStringifyPayload(JSON.stringify(payload));
    }
  }, [payload]);

  /**
   * Update payload
   */
  const { mutate, isLoading } = useMutation(
    async () => {
      if (!overrideStringifyPayload || !failedJob) return;

      const { contact: overrideContact }: IFailedJob["payload"] = JSON.parse(
        overrideStringifyPayload,
      );

      const originalPhoneNumber = payload?.contact?.phoneNumber || "";

      const overridePhoneNumber = overrideContact?.phoneNumber || "";

      // target phone number changes if different, patch the job
      if (originalPhoneNumber !== overridePhoneNumber) {
        const { firstName, lastName, email } = payload?.contact || {};

        await instance.jobs.patchJob(encodeURIComponent(failedJob.orderRef), {
          contact: {
            firstName: firstName || "",
            lastName: lastName || "",
            email,
            phoneNumber: overrideContact?.phoneNumber || "",
          },
          suppressAllSns: true,
        });
      }

      const { orderRef, topicArn } = failedJob || {};

      await instance.jobs.patchFailedJob(encodeURIComponent(orderRef), {
        orderRef,
        topicArn,
        attempts: 0,
        payload: JSON.parse(overrideStringifyPayload),
      });
    },
    {
      onSuccess: () => {
        setPayloadModalOpen(null);
        toasts.success({
          message: translate("failedJobs.success.updatePayload"),
        });
        onRefetch?.();
      },
      onError: (error) => toasts.error({ message: getErrorMessage(error) }),
    },
  );

  const isValidJson = checkIfJson(overrideStringifyPayload);

  return (
    <Modal
      modalVisible={!!payload}
      width={800}
      onClose={() => {
        setPayloadModalOpen(null);
        setOverrideStringifyPayload("");
      }}
      styles={{
        padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
        paddingBottom: theme.spacing.xlarge,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.large} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.large,
        },
      }}
    >
      {/* MODAL LOADING OVERLAY */}
      {isLoading && (
        <LoadingOverlay
          iconSize="xlarge2"
          backgroundLoadingContainerStyles={{
            borderRadius: theme.spacing.small,
          }}
        />
      )}
      <Box className="flex items-center gap-2 mb-5">
        <Heading level={4} color="brand">
          {translate("failedJobs.headings.payload", {
            jobRef: failedJob?.orderRef || "",
          })}
        </Heading>
        <Button
          size="xsmall"
          className="flex items-center gap-x-1"
          onClick={async () => {
            await clipboardCopy(
              overrideStringifyPayload || JSON.stringify(payload),
            );
            toasts.success(
              {
                message: translate("failedJobs.copied"),
              },
              { autoClose: 1000 },
            );
          }}
        >
          <Icon.Clipboard size="small" />
          {translate("failedJobs.buttons.copy")}
        </Button>
      </Box>
      {!!payload && (
        <AceEditor
          mode="json"
          theme="github"
          showGutter={false}
          editorProps={{ $blockScrolling: true }}
          width="100%"
          showPrintMargin={false}
          height="500px"
          highlightActiveLine={false}
          enableBasicAutocompletion={true}
          defaultValue={JSON.stringify(payload, null, 2)}
          style={{
            borderRadius: theme.spacing.xsmall2,
            borderWidth: "2px",
            borderColor: theme.colors.greyscale.lightest,
          }}
          onChange={(value) => setOverrideStringifyPayload(value)}
        />
      )}
      <Button
        size="small"
        color="accent"
        className="mt-3"
        onClick={() => mutate()}
        disabled={isLoading || !isValidJson}
      >
        {translate("failedJobs.buttons.retry")}
      </Button>
      {!isValidJson && (
        <Message type="error">
          {translate("failedJobs.errors.invalidJson")}
        </Message>
      )}
    </Modal>
  );
};
