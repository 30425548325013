import React, { useState } from "react";
import { useQuery } from "react-query";
import { OptionTypeBase } from "react-select";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IVehicle } from "@clearabee/api-schemas";
import { toasts } from "../../../helpers";
import {
  Heading,
  Button,
  Box,
  Text,
  Panel,
  Input,
  Table,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { EditVehicleModal } from "../components";
import { CreateCityModal, CreateRegionModal } from "./components";

export const VehiclePreferences = (): React.ReactElement => {
  const { t } = useTranslation("vehicles");
  const [filter, setFilter] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [driverOptions, setDriverOptions] = useState<OptionTypeBase[]>([]);
  const [tipOptions, setTipOptions] = useState<OptionTypeBase[]>([]);
  const [citiesOptions, setCitiesOptions] = useState<OptionTypeBase[]>([]);
  const [regionsOptions, setRegionsOptions] = useState<OptionTypeBase[]>([]);
  const [showCreateCityModal, setShowCreateCityModal] = useState(false);
  const [showCreateRegionModal, setShowCreateRegionModal] = useState(false);
  const [allDefaultDrivers, setAllDefaultDrivers] = useState<number[]>([]);

  const {
    data: vehicles,
    refetch: refetchVehicles,
    isLoading: vehiclesIsLoading,
    isRefetching: vehiclesIsRefetching,
  } = useQuery(
    ["getVehicles"],
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            limit: 10000,
            orderBy: "name",
            "company_code:emptyOrNull": "true",
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setAllDefaultDrivers(
          data.reduce((acc: number[], vehicle: IVehicle) => {
            return [...acc, ...(vehicle?.defaultDrivers || [])];
          }, []),
        );
      },
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  const { data: drivers, isLoading: driversIsLoading } = useQuery(
    ["getDrivers"],
    async () =>
      (
        await instance.users.getUsers({
          params: {
            limit: 10000,
            "roles.name:in": "Clearabee Driver",
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setDriverOptions(
          data.map((driver) => ({
            label: `${driver.firstName} ${driver.lastName}`,
            value: driver.id,
          })),
        );
      },
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  const { data: tips, isLoading: tipsIsLoading } = useQuery(
    ["getTips"],
    async () =>
      (
        await instance.jobs.getTips({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setTipOptions(
          data.map((tip) => ({
            label: tip.name,
            value: tip.id,
          })),
        );
      },
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  const { isLoading: citiesIsLoading, refetch: refetchCities } = useQuery(
    ["getCities"],
    async () =>
      (
        await instance.vehicles.getVehicleCities({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setCitiesOptions(
          data.map((city) => ({
            label: city.name,
            value: city.id,
          })),
        );
      },
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  const { isLoading: regionsIsLoading, refetch: refetchRegions } = useQuery(
    ["getRegions"],
    async () =>
      (
        await instance.vehicles.getVehicleRegions({
          params: {
            limit: 10000,
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setRegionsOptions(
          data.map((region) => ({
            label: region.name,
            value: region.id,
          })),
        );
      },
      onError: () => {
        toasts.error({
          message: t("vehiclePreferences.toasts.error"),
        });
      },
    },
  );

  const findDriver = (driverId: number) => {
    const foundDriver = drivers?.find((driver) => driver.id === driverId);

    return foundDriver
      ? `${foundDriver.firstName} ${foundDriver.lastName}`
      : "";
  };

  const findTip = (tipId: number) => {
    const foundTip = tips?.find((tip) => tip.id === tipId);

    return foundTip ? foundTip.name : "";
  };

  return (
    <>
      {/* LOADING */}
      {(vehiclesIsLoading ||
        driversIsLoading ||
        vehiclesIsRefetching ||
        citiesIsLoading ||
        regionsIsLoading ||
        tipsIsLoading) && <LoadingOverlay />}

      {/* FILTER FORM */}
      <Panel shadow={false} className="mb-10 mt-10">
        <Box className="flex flex-row justify-between items-center border-b border-grey-200 mb-5 pb-4">
          <Heading color="brand" level={1} fontSize="large">
            {t("vehiclePreferences.heading")}
          </Heading>

          <Box className="flex justify-center items-center gap-x-3">
            <Button
              size="small"
              color="accent"
              onClick={() => setShowCreateCityModal(true)}
            >
              {t("vehiclePreferences.buttons.addCity")}
            </Button>
            <Button
              size="small"
              color="accent"
              onClick={() => setShowCreateRegionModal(true)}
            >
              {t("vehiclePreferences.buttons.addRegion")}
            </Button>
          </Box>
        </Box>

        <Box className="flex w-1/4">
          <Input.Text
            placeholder="Search Name"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Panel>

      {!!vehicles && vehicles?.length > 0 && (
        <>
          {/* RESULTS TABLE */}
          <Panel className="mb-6">
            <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <Table.Header
                fontSize="small"
                headings={[
                  t("vehiclePreferences.table.name"),
                  t("vehiclePreferences.table.registration"),
                  t("vehiclePreferences.table.preferredTip"),
                  t("vehiclePreferences.table.defaultStartTime"),
                  t("vehiclePreferences.table.defaultDrivers"),
                  t("vehiclePreferences.table.enabled"),
                  t("vehiclePreferences.table.actions"),
                ]}
              />

              <Table.Body>
                {vehicles
                  ?.filter((vehicle) =>
                    vehicle?.name?.toLowerCase().includes(filter.toLowerCase()),
                  )
                  .map((vehicle) => (
                    <Table.Row key={`table-row-${vehicle.id}`}>
                      <Table.Cell.Text className="truncate">
                        {vehicle.name}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {vehicle.registration}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {findTip(vehicle?.tipId || 0) ||
                          t("vehiclePreferences.table.noTip")}
                      </Table.Cell.Text>

                      <Table.Cell.Text className="truncate">
                        {vehicle.defaultStartTime ||
                          t("vehiclePreferences.table.noStartTime")}
                      </Table.Cell.Text>

                      <Table.Cell className="truncate">
                        {(!!vehicle?.defaultDrivers?.length &&
                          vehicle.defaultDrivers?.map((driver: number) => {
                            return (
                              <Text
                                key={`${vehicle.name} - ${driver}`}
                                fontSize="xsmall"
                              >
                                {findDriver(driver)}
                              </Text>
                            );
                          })) ||
                          t("vehiclePreferences.table.noDrivers")}
                      </Table.Cell>

                      <Table.Cell.Text className="truncate capitalize">
                        {String(vehicle.enabled)}
                      </Table.Cell.Text>

                      <Table.Cell className="flex flex-row gap-3 w-28">
                        <Button
                          size="xsmall"
                          type="button"
                          onClick={() => setSelectedVehicle(vehicle)}
                          className="text-center"
                        >
                          {t("vehiclePreferences.buttons.edit")}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Panel>
        </>
      )}

      {/* NO RESULTS */}
      {!!vehicles && vehicles.length === 0 && (
        <Box className="flex justify-center mt-12">
          <Heading color="brand" level={3}>
            {t("vehiclePreferences.noVehicles")}
          </Heading>
        </Box>
      )}

      {/* CREATE CITY MODAL */}
      <CreateCityModal
        showModal={showCreateCityModal}
        setShowModal={setShowCreateCityModal}
        refetchCities={refetchCities}
      />

      {/* CREATE REGION MODAL */}
      <CreateRegionModal
        showModal={showCreateRegionModal}
        setShowModal={setShowCreateRegionModal}
        refetchRegions={refetchRegions}
      />

      {/* EDIT VEHICLE MODAL */}
      <EditVehicleModal
        selectedVehicle={selectedVehicle}
        setSelectedVehicle={setSelectedVehicle}
        driverOptions={driverOptions}
        tipOptions={tipOptions}
        refetchVehicles={refetchVehicles}
        showModal={!!selectedVehicle}
        citiesOptions={citiesOptions}
        regionsOptions={regionsOptions}
        showPhotos={false}
        allDefaultDrivers={allDefaultDrivers}
      />
    </>
  );
};
