import { IJob } from "@clearabee/api-schemas";

export type BigChangeJob = {
  Ref: string;
  PlannedStart: string;
  Postcode: string;
  Status: string;
};

export const onlyInBigchange = (
  databaseJobs: IJob[] | undefined,
  bigchangeJobs: BigChangeJob[] | undefined | string,
): BigChangeJob[] => {
  if (!databaseJobs || !bigchangeJobs) {
    return [];
  }

  if (bigchangeJobs instanceof Array) {
    return bigchangeJobs.filter((bigchangeJob) => {
      return !databaseJobs.some(
        (databaseJob) => databaseJob.ref === bigchangeJob.Ref,
      );
    });
  } else {
    return [];
  }
};

export const onlyInDatabase = (
  databaseJobs: IJob[] | undefined,
  bigchangeJobs: BigChangeJob[] | undefined | string,
): IJob[] => {
  if (!databaseJobs || !bigchangeJobs) {
    return [];
  }

  if (bigchangeJobs instanceof Array) {
    return databaseJobs.filter((databaseJob) => {
      return !bigchangeJobs.some(
        (bigchangeJob) => bigchangeJob.Ref === databaseJob.ref,
      );
    });
  } else {
    return databaseJobs;
  }
};

export const inBoth = (
  databaseJobs: IJob[] | undefined,
  bigchangeJobs: BigChangeJob[] | undefined | string,
): IJob[] => {
  if (!databaseJobs || !bigchangeJobs) {
    return [];
  }

  if (bigchangeJobs instanceof Array) {
    return databaseJobs.filter((databaseJob) => {
      return bigchangeJobs.some(
        (bigchangeJob) => bigchangeJob.Ref === databaseJob.ref,
      );
    });
  } else {
    return [];
  }
};
