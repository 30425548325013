import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  container: {
    maxHeight: "80vh",
    overflowY: "scroll",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    textAlign: "left",
    width: "100%",
  },
  fieldContianerHalf: {
    width: "50%",
    padding: `0px 12px`,
  },
  fieldContianerFull: {
    width: "100%",
    padding: "0px 12px",
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing.small,
    width: "100%",
    padding: theme.spacing.xsmall2,
  },
  createModalStyles: {
    textAlign: "center",
    [`@media (min-width: ${theme.screens.medium})`]: {
      padding: `${theme.spacing.xlarge} ${theme.spacing.xlarge2}`,
    },
  },
});
