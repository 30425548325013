import React, { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { ApolloProvider } from "@apollo/client";
import { Redirect, useLocation } from "react-router-dom";
import { instance } from "@clearabee/ui-sdk";

/**
 * Import styles.
 */
import "./styles/fourOFour.css";
import "./styles/loading.css";
import "react-toastify/dist/ReactToastify.css";

import { Loading } from "./components";
import { Auth } from "../auth";
import { Portal } from "../portal";

import { useAuthContext } from "../../hooks";
import { graphQLClient } from "../../services";

/**
 * App context provider
 */
import { AppProvider } from "../../contexts";
import { CompanySignup } from "components/companySignup";

declare global {
  interface Window {
    zE: (
      type: "messenger",
      command: string,
      payload: Record<string, unknown>,
    ) => void;
  }
}

if (typeof window !== "undefined") {
  window.zE =
    window.zE ||
    (() => {
      // silence is golden
    });
}

axios.defaults.baseURL = process.env.REACT_APP_MS_API_BASE_URL;
axios.defaults.withCredentials = false;
instance.axios.defaults.baseURL = process.env.REACT_APP_MS_API_BASE_URL;

const sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const App = (): React.ReactElement => {
  const {
    isLoading: isLoginLoading,
    forcePasswordChange,
    verify2FA,
    isUserAuthenticated,
    getTemporaryUser,
    getCurrentUserAccessToken,
    getCurrentUserName,
    getCurrentUserEmailAddress,
  } = useAuthContext();
  const tempUser = getTemporaryUser();
  const { pathname } = useLocation();
  const email = getCurrentUserEmailAddress();
  const name = getCurrentUserName();

  /**
   * Zendesk Chat - Prefill zendesk chat details
   */
  useEffect(() => {
    try {
      if (!email) {
        window.zE("messenger", "hide", {});
        return;
      }

      // prefill and identify doen't seem to exist anymore, need to look into a way to set user details.
      // window.zE("messenger", "prefill", {
      //   name: { value: name, readOnly: false },
      //   email: { value: email, readOnly: true },
      // });

      // window.zE("messenger", "identify", { name, email });
      window.zE("messenger", "show", {});
    } catch (error) {
      console.log(error);
    }
  }, [name, email]);

  useEffect(() => {
    // Enable sentry if on preview or production deployment
    if (sentryEnv === "production" || sentryEnv === "staging")
      Sentry.init({
        // eslint-disable-next-line
        environment: sentryEnv,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        beforeSend(event) {
          if (event.exception) {
            /**
             * Only show the popup for errors of type 'error'
             * - Warnings do not need the popup
             * - Fatal errors will likely break JS functionality
             */
            if (event.level === Sentry.Severity.Fatal) {
              Sentry.showReportDialog();
            }
          }

          return event;
        },
        ignoreErrors: [
          // Benign browser error
          "ResizeObserver loop limit exceeded",
          "ResizeObserver loop completed with undelivered notifications.",
          // Random plugins/extensions
          "top.GLOBALS",
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "originalCreateNotification",
          "canvas.contentDocument",
          "MyApp_RemoveAllHighlights",
          "http://tt.epicplay.com",
          "Can't find variable: ZiteReader",
          "jigsaw is not defined",
          "ComboSearch is not defined",
          "http://loading.retry.widdit.com/",
          "atomicFindClose",
          // Facebook borked
          "fb_xd_fragment",
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          "bmi_SafeAddOnload",
          "EBCallBackMessageReceived",
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          "conduitPage",
        ],
        blacklistUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        whitelistUrls: [],
      });
    // disable the next line with eslint, because we only want this fire on first mount
    // eslint-disable-next-line
  }, []);

  if (forcePasswordChange && tempUser !== null) {
    // the user has the correct details, but must change their password first
    // => show them the form to change their password, then they are auto signed in with their new details
    if (pathname !== "/auth/welcome") {
      return <Redirect to="/auth/welcome" />;
    }
  }

  if (isLoginLoading && !forcePasswordChange && !verify2FA)
    return <Loading isLoading />;

  if (!isUserAuthenticated() && pathname === "/signup") {
    return <CompanySignup />;
  }

  return (
    <>
      <div className="container-full">
        <ToastContainer
          toastClassName="toasts-wrap"
          icon={false}
          bodyClassName="toasts-body"
          progressClassName="toasts-progress"
          position="bottom-center"
          closeButton={false}
          autoClose={5000}
          hideProgressBar
        />
        <div className="bg-gray-200">
          {isUserAuthenticated() && !forcePasswordChange ? (
            <ApolloProvider
              client={graphQLClient(getCurrentUserAccessToken() || "")}
            >
              <AppProvider>
                <Portal />
              </AppProvider>
            </ApolloProvider>
          ) : (
            <Auth />
          )}
        </div>
      </div>
    </>
  );
};
