import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Heading,
  Panel,
  InputText,
  Text,
  Button,
  Table,
  theme,
  Icon,
  Box,
  UnstyledButton,
  Modal,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { getTemplates } from "../../../api/templates";
import { Template } from "models/template";
import { getErrorMessage, getQueriedResults, toasts } from "../../../helpers";
import { RouteLink } from "components/core";
import { LoadingOverlay, PaginatedData } from "components/common/components";
import Placeholder from "../../../images/placeholder.svg";
import { useHistory } from "react-router-dom";
import { paginationStyles } from "components/common/resusablePaginationStyles";

export const ReadTemplates = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [search, setSearch] = useState("");
  const [selectedTemplateSku, setSelectedTemplateSku] = useState("");
  const [results, setResults] = useState<Template[]>();
  const history = useHistory();

  /**
   * Read templates query
   */
  const {
    data: initialData,
    isLoading,
    error,
    refetch,
  } = useQuery(["readTemplates"], () => getTemplates({ limit: 2000 }), {
    retry: 0,
  });

  /**
   * Delete template mutation
   */
  const { mutate: deleteTemplate, isLoading: isLoadingDeleteTemplate } =
    useMutation(
      ["deleteTemplate"],
      async (sku: string) => {
        if (!sku) return;

        await instance.catalogues.deleteTemplate(sku);
      },
      {
        onSuccess: () => {
          setSelectedTemplateSku("");
          refetch();
        },
        onError: (error) => {
          toasts.error({
            message: getErrorMessage(error),
          });
        },
      },
    );
  useEffect(() => {
    setResults(
      getQueriedResults(initialData?.items, search, [
        "title",
        "sku",
        "description",
      ]),
    );
  }, [search, initialData?.items]);

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {isLoading && <LoadingOverlay />}

      <Box className="max-w-screen-lg mx-auto py-10">
        <Panel shadow={false} className="mb-10">
          <Box className="flex flex-col sm:flex-row items-center justify-between border-b border-grey-200 pb-4 mb-3">
            <Heading level={1} fontSize="large" color="brand">
              {translate("filters.templatesHeading")}
            </Heading>
            <RouteLink href="/catalogues/templates/create">
              <Button size="small" as="a" className="inline-block mt-3 sm:mt-0">
                {translate("filters.createNewTemplateButton")}
              </Button>
            </RouteLink>
          </Box>
          <Box className="flex flex-wrap sm:flex-no-wrap items-center pt-5 relative">
            <InputText
              placeholder={translate("filters.templateSearchPlaceholder")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Icon.Search size="small" className="absolute right-3" />
          </Box>
        </Panel>

        {!isLoading && (
          <PaginatedData
            data={results || initialData?.items || []}
            error={error}
            paginationStyles={paginationStyles}
          >
            {({ paginatedData }) => (
              <Table styles={{ tableLayout: "fixed" }}>
                <Table.Header
                  headings={[
                    translate("table.headings.image"),
                    translate("table.headings.sku"),
                    translate("table.headings.title"),
                    translate("table.headings.description"),
                    translate("table.headings.price"),
                    translate("table.headings.action"),
                  ]}
                />
                <Table.Body>
                  {paginatedData.map((item) => (
                    <Table.Row
                      key={`table-row-${item.sku}`}
                      data-testid="template-result"
                    >
                      <Table.Cell.Image
                        src={item?.img || Placeholder}
                        css={{
                          height: 60,
                          width: "auto",
                        }}
                        height={60}
                        width={60}
                      />
                      <Table.Cell.Text
                        className="truncate"
                        cellProps={{ styles: { width: 150 } }}
                      >
                        {item.sku}
                      </Table.Cell.Text>
                      <Table.Cell.Text
                        className="truncate"
                        cellProps={{ styles: { width: 80 } }}
                      >
                        {item.title}
                      </Table.Cell.Text>
                      <Table.Cell.Text
                        className="truncate"
                        cellProps={{ styles: { width: 100 } }}
                      >
                        {item.description}
                      </Table.Cell.Text>
                      {item.price ? (
                        <Table.Cell.Currency price={item.price} />
                      ) : (
                        <Table.Cell.Text styles={{ width: 10 }}>
                          {translate("nonApplicable")}
                        </Table.Cell.Text>
                      )}
                      <Table.Cell>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: theme.spacing.xsmall,
                          }}
                        >
                          <UnstyledButton
                            style={{
                              backgroundColor: theme.colors.accent.dark,
                            }}
                            className="p-3 rounded-full cursor-pointer"
                            onClick={() =>
                              history.push(`/catalogues/templates/create`, {
                                item,
                              })
                            }
                          >
                            <Icon.Clipboard size="small" color="light" />
                          </UnstyledButton>
                          <Button
                            size="xsmall"
                            onClick={() =>
                              history.push(
                                `/catalogues/templates/update/${item.sku}`,
                              )
                            }
                          >
                            {translate("table.actions.edit")}
                          </Button>
                          <Button
                            color="negative"
                            size="xsmall"
                            onClick={() => setSelectedTemplateSku(item.sku)}
                          >
                            {translate("table.actions.delete")}
                          </Button>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </PaginatedData>
        )}

        {!!error && (
          <Box className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
            <Text className="block" as="span">
              {translate("errors.loadingResults")}
            </Text>
          </Box>
        )}
      </Box>
      {/* Confirm Delete template modal */}
      <Modal
        width={650}
        modalVisible={!!selectedTemplateSku}
        styles={{
          padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.xlarge,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
          },
        }}
        onClose={() => setSelectedTemplateSku("")}
      >
        {/* MODAL LOADING OVERLAY */}
        {isLoadingDeleteTemplate && (
          <LoadingOverlay
            iconSize="xlarge"
            backgroundLoadingContainerStyles={{
              borderRadius: theme.spacing.small,
            }}
          />
        )}
        <Box className="flex flex-col gap-y-5">
          <Heading color="brand" level={4}>
            {translate("modal.readTemplates.areYouSure")}({selectedTemplateSku})
          </Heading>
          <Text fontSize="small">
            Deleting this product template will impact all products created from
            this template.
            <br />
            Please ensure that no products made from this template are in use or
            in a basket.
          </Text>
          <Box className="flex justify-center">
            <Button
              size="small"
              color="negative"
              onClick={() => deleteTemplate(selectedTemplateSku)}
            >
              {translate("buttons.delete")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
