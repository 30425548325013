import React, { useState } from "react";
import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IPaymentLink } from "@clearabee/api-schemas";
import {
  Box,
  Button,
  FlexGrid,
  Form,
  formatCurrency,
  Heading,
  Input,
  Panel,
  Pill,
  theme,
  UnstyledButton,
  Text,
} from "@clearabee/ui-library";
import { LeftChevron } from "images";
import { statusColors } from "./readPaymentLinks";
import { handleException } from "helpers/handleException";
import { getErrorMessage, toasts } from "helpers";
import {
  BookAgainPaymentLinkModal,
  CancelPaymentLinkModal,
  ResendPaymentLinkModal,
} from "./components";
import { LoadingOverlay } from "components/common/components";

export const UpdatePaymentLink = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [activeCancelLink, setActiveCancelLink] = useState<IPaymentLink | null>(
    null,
  );
  const [activeResendLink, setActiveResendLink] = useState<IPaymentLink | null>(
    null,
  );
  const [activeBookAgainLink, setActiveBookAgainLink] =
    useState<IPaymentLink | null>(null);

  /**
   * get basket data
   */
  const { data: basket, isLoading: isLoadingBasket } = useQuery(
    ["getBasket", token],
    async () => (await instance.catalogues.getBasket(token)).data,
    {
      enabled: !!token,
      retry: false,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      onError: (error) => toasts.errors({ message: getErrorMessage(error) }),
    },
  );
  /**
   * Get payment link details
   */
  const {
    data: paymentLink,
    isLoading: isLoadingPaymentLink,
    refetch: refetchPaymentLink,
  } = useQuery(
    ["getPaymentLink", token],
    async () =>
      (await instance.catalogues.getPaymentLink(token))
        .data as unknown as IPaymentLink,

    {
      enabled: !!token,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      onError: (error) => toasts.errors({ message: getErrorMessage(error) }),
    },
  );

  /**
   * Resend payment link mutation
   */
  const { mutate: resendPaymentLink, isLoading: isLoadingResendPaymentLink } =
    useMutation(
      async ({ token, email }: { token: string; email?: string }) =>
        await instance.catalogues.putPaymentLink(token, {
          clientOriginUrl: process.env.REACT_APP_WEBSITE_URL as string,
          ...(!!email ? { email } : {}),
        }),
      {
        onError: (error) => {
          handleException(
            error,
            {
              basket,
              paymentLink,
            },
            {
              type: "Update Payment Link",
              action: "Resend payment link",
            },
            "",
            false,
          );
          toasts.errors({ message: getErrorMessage(error) });
        },
        onSuccess: () => {
          toasts.success({ message: translate("paymentLink.success.resend") });
        },
        onSettled: () => setActiveResendLink(null),
      },
    );

  /**
   *  Cancel payment link mutation
   */
  const { mutate: cancelPaymentLink, isLoading: isLoadingCancelPaymentLink } =
    useMutation(
      async (token: string) =>
        await instance.catalogues.patchPaymentLink(token, {
          status: "cancelled",
        }),
      {
        onError: (error) => {
          handleException(
            error,
            {
              basket,
              paymentLink,
            },
            {
              type: "Update Payment Link",
              action: "Cancel payment link",
            },
            "",
            false,
          );
          toasts.errors({ message: getErrorMessage(error) });
        },
        onSuccess: () => {
          refetchPaymentLink();
          toasts.success({ message: translate("paymentLink.success.cancel") });
        },
        onSettled: () => setActiveCancelLink(null),
      },
    );

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoadingBasket || isLoadingPaymentLink) && <LoadingOverlay />}

      {!isLoadingBasket && !isLoadingPaymentLink && (
        <Box className="max-w-5xl pt-10 mx-auto">
          <UnstyledButton
            onClick={() => history.goBack()}
            className="flex items-center gap-x-2 mb-2"
          >
            <LeftChevron />
            {translate("paymentLink.buttons.backToPaymentLinks")}
          </UnstyledButton>

          <Panel
            styles={{
              paddingTop: theme.spacing.large,
              paddingBottom: theme.spacing.large,
              marginBottom: theme.spacing.small,
            }}
            shadow={false}
          >
            <Box className="flex items-center">
              <Heading color="brand" level={4} styles={{ marginRight: "auto" }}>
                {translate("paymentLink.headings.jobDetails")}
              </Heading>
              {!!paymentLink && paymentLink.status === "pending" && (
                <Box className="flex justify-center items-center gap-x-2">
                  <Button
                    size="small"
                    onClick={() => setActiveResendLink(paymentLink)}
                  >
                    {translate("paymentLink.buttons.resend")}
                  </Button>
                  <Button
                    size="small"
                    color="negative"
                    onClick={() => setActiveCancelLink(paymentLink)}
                  >
                    {translate("paymentLink.buttons.cancel")}
                  </Button>
                </Box>
              )}
              {!!paymentLink && paymentLink.status === "expired" && (
                <Box className="flex justify-center items-center gap-x-2">
                  <Button
                    size="small"
                    onClick={() => setActiveBookAgainLink(paymentLink)}
                  >
                    {translate("paymentLink.buttons.bookAgain")}
                  </Button>
                </Box>
              )}
            </Box>
            {/* DIVIDER */}
            <Box className="border-t border-gray-300 my-5 flex flex-col h-1" />

            {!!basket && !!paymentLink && (
              <FlexGrid wrap="nowrap" rowSpacing={null}>
                <FlexGrid.Cell base="100%">
                  <Heading
                    level={5}
                    styles={{ marginBottom: theme.spacing.xsmall }}
                  >
                    {translate("headings.status")}
                  </Heading>
                  <Pill color={statusColors[paymentLink.status]}>
                    {paymentLink.status}
                  </Pill>
                </FlexGrid.Cell>
                <FlexGrid.Cell base="100%">
                  <Heading
                    level={5}
                    styles={{ marginBottom: theme.spacing.xsmall }}
                  >
                    {translate("headings.collectionDate")}
                  </Heading>
                  {dayjs(basket.date).format("dddd, MMMM Do YYYY")}
                </FlexGrid.Cell>

                <FlexGrid.Cell base="100%">
                  <Heading
                    level={5}
                    styles={{ marginBottom: theme.spacing.xsmall }}
                  >
                    {translate("headings.jobReference")}
                  </Heading>
                  {basket.orderRef}
                </FlexGrid.Cell>
                <FlexGrid.Cell base="100%">
                  <Heading
                    level={5}
                    styles={{ marginBottom: theme.spacing.xsmall }}
                  >
                    {translate("headings.companyCode")}
                  </Heading>
                  {basket.companyCode}
                </FlexGrid.Cell>
              </FlexGrid>
            )}
          </Panel>

          {!!basket && !isLoadingBasket && (
            <Panel
              styles={{
                paddingTop: theme.spacing.large,
                paddingBottom: theme.spacing.large,
                marginBottom: theme.spacing.small,
              }}
              shadow={false}
            >
              <Form
                initialValues={basket}
                renderFormElement={false}
                onSubmit={() => {
                  // keep form here in case we might want to allow user to edit basket
                }}
              >
                {({ Field }) => (
                  <FlexGrid rowSpacing={null}>
                    <FlexGrid.Cell base="25%">
                      <Heading color="brand" level={5}>
                        {translate("paymentLink.headings.collectionDetails")}
                      </Heading>
                      <Field
                        name="deliveryAddress.line1"
                        label={translate("paymentLink.form.labels.line1")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="deliveryAddress.city"
                        label={translate("paymentLink.form.labels.city")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="deliveryAddress.county"
                        label={translate("paymentLink.form.labels.county")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="deliveryAddress.postcode"
                        label={translate("paymentLink.form.labels.postcode")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                    </FlexGrid.Cell>
                    <FlexGrid.Cell base="25%">
                      <Heading color="brand" level={5}>
                        {translate("paymentLink.headings.customerDetails")}
                      </Heading>
                      <Field
                        name="contact.firstName"
                        label={translate("paymentLink.form.labels.firstName")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="contact.lastName"
                        label={translate("paymentLink.form.labels.lastName")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="contact.email"
                        label={translate("paymentLink.form.labels.email")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                      <Field
                        name="contact.phoneNumber"
                        label={translate("paymentLink.form.labels.phoneNumber")}
                      >
                        {({ field }) => <Input.Text {...field} disabled />}
                      </Field>
                    </FlexGrid.Cell>
                    <FlexGrid.Cell base="50%">
                      <Heading color="brand" level={5}>
                        {translate("paymentLink.headings.wasteDetails")}
                      </Heading>
                      <Field
                        name="description"
                        label={translate(
                          "paymentLink.form.labels.wasteDescription",
                        )}
                      >
                        {({ field }) => (
                          <Input.Textarea {...field} disabled slim />
                        )}
                      </Field>
                      <Field
                        name="accessInformation"
                        label={translate(
                          "paymentLink.form.labels.accessInformation",
                        )}
                      >
                        {({ field }) => (
                          <Input.Textarea {...field} disabled slim />
                        )}
                      </Field>
                    </FlexGrid.Cell>
                  </FlexGrid>
                )}
              </Form>
            </Panel>
          )}

          <Panel
            styles={{
              paddingTop: theme.spacing.small,
              paddingBottom: theme.spacing.small,
            }}
            shadow={false}
          >
            <Box className="flex items-start">
              <Box className="w-1/2 flex flex-col gap-y-2">
                <Heading color="brand" level={5}>
                  {translate("paymentLink.headings.products")}
                </Heading>
                {!!basket?.items.length && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        basket.items
                          ?.map(
                            ({ qty, lineCost, title }) =>
                              `${qty} x ${title} - ${formatCurrency(lineCost)}`,
                          )
                          .join("<br/>") || "",
                    }}
                  />
                )}
              </Box>
              <Box className="w-1/2 flex flex-col gap-y-2">
                <Heading color="brand" level={5}>
                  {translate("paymentLink.headings.totalCost")}
                </Heading>
                <Text>{formatCurrency(basket?.originalCost || 0)}</Text>
              </Box>
            </Box>
          </Panel>
        </Box>
      )}

      {/* RESEND PAYMENT LINK MODAL */}
      <ResendPaymentLinkModal
        resendPaymentLink={activeResendLink}
        setResendPaymentLink={setActiveResendLink}
        onResendPaymentLink={resendPaymentLink}
        isLoading={isLoadingResendPaymentLink}
      />

      {/* CANCEL PAYMENT LINK MODAL */}
      <CancelPaymentLinkModal
        cancelPaymentLink={activeCancelLink}
        setCancelPaymentLink={setActiveCancelLink}
        onCancelPaymentLink={cancelPaymentLink}
        isLoading={isLoadingCancelPaymentLink}
      />

      {/* BOOK AGAIN PAYMENT LINK MODAL */}
      <BookAgainPaymentLinkModal
        activeBookAgainLink={activeBookAgainLink}
        setActiveBookAgainLink={setActiveBookAgainLink}
      />
    </>
  );
};
