"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reporting = void 0;
exports.reporting = {
    headings: {
        jobStats: "Job Stats",
        wasteStreamStats: "Waste Stream Stats",
    },
    scheduleReports: {
        headings: {
            addScheduledReport: "Add Scheduled Report",
            updateScheduledReport: "Update Scheduled Report",
        },
        toasts: {
            reportScheduled: "Report scheduled successfully",
            reportUpdated: "Report updated successfully",
        },
        table: {
            headings: {
                active: "Active",
                name: "Name",
                receipients: "Receipients",
                user: "User",
                email: "Email",
                range: "Range",
                actions: "Actions",
            },
        },
        buttons: {
            scheduleReport: "Add Scheduled Report",
            submit: "Submit",
            edit: "Edit",
            delete: "Delete",
            add: "Add",
            update: "Update",
            reset: "Reset",
            schedule: "Schedule",
        },
        labels: {
            name: "Name",
            status: "Status",
            range: "Range",
            companyCodes: "Company Codes",
            startDate: "Start Date",
            receipientEmail: "Receipient Email",
            receipients: "Receipients",
            active: "Active",
            paused: "Paused",
            noReports: "There are no scheduled reports",
        },
    },
};
