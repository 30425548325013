import * as Yup from "yup";
import { email } from "validation/common";

export const validationSchema = Yup.object().shape({
  reference: Yup.string(),
  purchaseOrderNumber: Yup.string(),
  date: Yup.string(),
});

export const initialValues = {
  reference: "",
  purchaseOrderNumber: "",
  date: "",
};

export type JobInvoiceFormValues = typeof initialValues;

export const emailFormValidationSchema = Yup.object().shape({
  email: email,
});

export const emailFormInitialValues = {
  email: "",
};

export type EmailFormValues = typeof emailFormInitialValues;
