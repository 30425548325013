import * as Yup from "yup";
import { stringRequired } from "validation/common";
import { translate } from "@clearabee/i18n";

export const initialValues = {
  groupName: "",
  subcontractors: null,
  searchedRef: "",
  jobInvoice: "",
  jobs: [],
};

export const validationSchema = Yup.object().shape({
  groupName: Yup.string(),
  subcontractors: Yup.object()
    .shape({
      label: stringRequired,
      value: stringRequired,
    })
    .required(
      translate("portal.subcontractors.allocateJobs.errors.fieldRequired"),
    )
    .nullable(),
  searchedRef: Yup.string(),
  jobs: Yup.array().of(
    Yup.object().shape({
      invoice: stringRequired,
      valid: Yup.boolean().oneOf([true]),
      override: Yup.boolean(),
      message: Yup.string().when("override", {
        is: true,
        then: stringRequired,
        otherwise: Yup.string(),
      }),
    }),
  ),
});
