import React, { useEffect, useState } from "react";
import { Form } from "@clearabee/ui-library";
import dayjs from "dayjs";
import { FormikValues } from "formik";
import { QuoteFiltersFields } from "./quoteFiltersFields";
import { buildQuery } from "../../../../helpers/api";
import { TFilters } from "../../../../api/types";

interface QuoteFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
}

interface QueryParams {
  "companyCode:in"?: string;
  "contactEmail:like"?: string;
  "createdAt:gt"?: string;
  "createdAt:lt"?: string;
  orderByDesc?: string;
  "status:eq"?: string;
  "status:neq"?: string;
}

const defaultParams = {
  orderByDesc: "createdAt",
};

export const QuoteFilters = ({
  isFetching,
  updateFilters,
}: QuoteFiltersProps): React.ReactElement => {
  const [params, setParams] = useState<QueryParams>();
  const [showExpired, setShowExpired] = useState<boolean>(false);

  /**
   * This will trigger a fresh request with the default
   * query params, and clear the content of each filter,
   * and it will reset the state of the query params above
   * to match whether the expired toggle is set to true
   * or false, so that any further queries retain the choice
   * of whether to show expired or non-expired quotes
   */
  const clearFilters = () => {
    updateFilters(buildQuery(defaultParams));
    setParams(showExpired ? { "status:neq": "" } : { "status:neq": "expired" });
  };

  /**
   * All search filters are cleared when landing on the page
   */
  useEffect(() => clearFilters(), []);

  const handleSubmit = (values: FormikValues) => {
    const { status, user, company, date } = values;
    const formatDate = !!date && dayjs(date, "DD/MM/YYYY");

    const params: Record<string, string> = {
      "status:eq": !status || status === "all" ? "" : status,
      "status:neq":
        (status === "all" || status === "") && showExpired === false
          ? "expired"
          : "",
      "contactEmail:like": `%${user || ""}%`,
      "companyCode:in": company || "",
      "createdAt:gt": formatDate
        ? dayjs(formatDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        : "",
      "createdAt:lt": formatDate
        ? dayjs(formatDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : "",
      ...defaultParams,
    };

    setParams(params);
  };

  /**
   * When query params are changed, then make a new request
   * with these new filters
   */
  useEffect(() => {
    if (params) {
      updateFilters(buildQuery(params));
    }
  }, [params]);

  const initialValues = {
    date: "",
    company: "",
    user: "",
    status: "",
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty }) => (
        <QuoteFiltersFields
          dirty={dirty}
          clearFilters={clearFilters}
          isFetching={isFetching}
          // when the showExpired switch is set to false, which is the default
          // then add the query parameter of "don't show expired", to the query
          // params. When showExpired is set to true, then show all the quote status
          // that the query is calling for, including "expired" quotes.
          onExpiredSwitchChange={(showExpired: boolean) => {
            const newParams = { ...params };
            if (!showExpired) {
              setShowExpired(false);
              newParams["status:neq"] = "expired";
            } else {
              setShowExpired(true);
              newParams["status:neq"] = "";
            }
            setParams(newParams);
          }}
        />
      )}
    </Form>
  );
};
