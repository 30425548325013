import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import {
  stringRequired,
  postcodeRegExp,
  validPostcode,
  email,
  fieldRequired,
  emailRegExp,
  nameIsRequired,
  name,
  internationalPhone,
} from "validation/common";

const productValidation = Yup.object().shape({
  sku: stringRequired,
  qty: Yup.number().default(0),
});

const notAllowedPONumberValues = ["'", '"', "&"];

export const createJobValidation = (
  orderNumberValidation?: string,
  orderNumberValidationMessage?: string,
  requireOrderNumber?: boolean,
): Yup.ObjectSchema => {
  const regex = new RegExp(orderNumberValidation ?? "");

  return Yup.object().shape(
    {
      bigchangeProps: Yup.object().shape(
        {
          cust_RiskAddressIsCollectionAddress: Yup.boolean(),
          cust_RiskPostcode: Yup.string()
            .matches(postcodeRegExp, validPostcode)
            .when("cust_RiskAddressIsCollectionAddress", {
              is: true,
              then: stringRequired,
              otherwise: Yup.string(),
            }),
        },
        [["cust_RiskAddressIsCollectionAddress", "cust_RiskPostcode"]],
      ),
      postcode: stringRequired.matches(postcodeRegExp, validPostcode),
      address: Yup.string().required("Address is required"),
      date: Yup.string().required("Date is required"),
      product: Yup.array()
        .of(productValidation)
        .min(1, fieldRequired)
        .required(fieldRequired),
      poNumber: (!!requireOrderNumber ? stringRequired : Yup.string())
        .test(
          "no-spaces",
          translate("portal.jobs.form.validation.invalidChar"),
          async (val) => {
            return val
              ? !notAllowedPONumberValues.some((item) => val.includes(item))
              : true;
          },
        )
        .matches(regex, orderNumberValidationMessage ?? "Invalid field"),
      description: Yup.string(),
      name: Yup.string().when("noContactDetails", {
        is: true,
        then: name,
        otherwise: nameIsRequired,
      }),
      contact: Yup.string().when(["email", "noContactDetails"], {
        is: (email, ncd) => !!email || ncd,
        then: internationalPhone.notRequired(),
        otherwise: internationalPhone.required(fieldRequired),
      }),
      noContactDetails: Yup.boolean(),
      email: Yup.string().when(["contact", "noContactDetails"], {
        is: (contact, ncd) => !!contact || ncd,
        then: email.notRequired(),
        otherwise: email.matches(
          emailRegExp,
          translate("portal.common.form.errors.validEmail"),
        ),
      }),
    },
    [
      ["contact", "email"],
      ["noContactDetails", "contact"],
      ["noContactDetails", "email"],
    ],
  );
};

export const rowsValidation = (
  emailRequired?: boolean,
  orderNumberValidation?: string,
  orderNumberValidationMessage?: string,
  requireOrderNumber?: boolean,
): Yup.ObjectSchema =>
  Yup.object().shape({
    rows: Yup.array().of(
      createJobValidation(
        orderNumberValidation,
        orderNumberValidationMessage,
        requireOrderNumber,
      ),
    ),
    useAdhocCatalogue: Yup.boolean(),
  });

const selectedProductInitialValues = {
  sku: "",
  label: "",
  value: "",
  qty: 1,
  parentSku: "",
  price: 0,
};

export const rowInitialValues = {
  postcode: "",
  address: "",
  product: [] as typeof selectedProductInitialValues[],
  date: "",
  name: "",
  poNumber: "",
  description: "",
  contact: "",
  noContactDetails: false,
  email: "",
  bigchangeProps: {
    cust_RiskAddressIsCollectionAddress: false,
    cust_RiskPostcode: "",
  },
};

export type Row = typeof rowInitialValues;

export interface InitialValues {
  useAdhocCatalogue: boolean;
  rows: Row[];
}

export const initialValues: InitialValues = {
  useAdhocCatalogue: false,
  rows: [rowInitialValues],
};
