import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import dayjs from "dayjs";
import {
  ICatalogueTreeItem,
  ICompany,
  IOrderLine,
  IPaginatedOrderTypes,
} from "@clearabee/api-schemas";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  Field,
  Icon,
  Input,
  Panel,
  Text,
  UnstyledButton,
  theme,
} from "@clearabee/ui-library";

interface JobRowProps {
  index: number;
  orderTypes: IPaginatedOrderTypes | undefined;
  formSubmitted: boolean;
  subcontractors?: ICompany[];
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  catalogueItems: ICatalogueTreeItem[];
  isLoading?: boolean;
  onJobPatched: () => void;
  onJobRemove: (index: number) => void;
}

export const JobRow = ({
  index,
  orderTypes,
  formSubmitted,
  subcontractors,
  jobData,
  catalogueItems,
  isLoading = false,
  onJobPatched,
  onJobRemove,
}: JobRowProps): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");
  const [missingProducts, setMissingProducts] = useState<IOrderLine[]>([]);
  const [initialInvoice, setInitialInvoice] = useState(0);
  const { values, setFieldValue }: FormikProps<FormikValues> =
    useFormikContext();
  const selectedSubcontractors = subcontractors?.filter(
    (item) => values.subcontractors?.value === item.companyCode,
  );

  const preventFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const {
    mutate: patchJob,
    isLoading: patchJobIsLoading,
    isSuccess: patchJobIsSuccess,
    isError: patchJobIsFailed,
  } = useMutation(
    async () => {
      if (!values.subcontractors) return;
      return await instance.jobs.patchJob(String(jobData?.id), {
        subcontractorCodes: [values.subcontractors.value],
        meta: {
          ...jobData?.meta,
          subcontractorInvoiceAmount: values.jobs[index].invoice,
          invoiceAmount: values.jobs[index].invoice,
          group: values.groupName,
          invoiceOverrideReason: values.jobs[index].message,
        },
        suppressEmail: true,
      });
    },
    {
      onSuccess: () => {
        onJobPatched();
      },
    },
  );

  const areNotCoveringPostcode =
    selectedSubcontractors &&
    selectedSubcontractors?.filter(
      ({ postcodes }) =>
        !!postcodes &&
        !postcodes.some(
          (item) =>
            `${item.outwardCode}${item.inwardCode}` ===
            jobData?.addressPostcode,
        ),
    );

  useEffect(() => {
    const catalogueMissingItems: IOrderLine[] = [];

    if (!catalogueItems.length) {
      return;
    }
    const invoices = jobData.items?.map((jobItem) => {
      const catalogueProduct = catalogueItems.find(
        (item) => item.sku === jobItem.sku,
      );
      if (!!catalogueProduct) {
        return (catalogueProduct.price || 0) * jobItem.qty;
      } else {
        catalogueMissingItems.push(jobItem);
        return 0;
      }
    });
    const jobInvoice = invoices?.reduce((acc, curr) => acc + curr, 0);
    if (!!jobInvoice) {
      setInitialInvoice(jobInvoice);
      setFieldValue(`jobs[${index}].invoice`, String(jobInvoice));
    }

    setMissingProducts(catalogueMissingItems);

    if (!!catalogueMissingItems.length) {
      setFieldValue(`jobs[${index}].valid`, false);
    } else {
      setFieldValue(`jobs[${index}].valid`, true);
    }
  }, [JSON.stringify(catalogueItems)]);

  useEffect(() => {
    if (formSubmitted) {
      patchJob();
    }
  }, [formSubmitted]);

  return (
    <>
      <Box className="relative">
        {isLoading && (
          <Box className="absolute w-full h-full z-10 flex justify-center items-center">
            <Icon.Loading />
          </Box>
        )}
        <Box
          backgroundColor="light.base"
          className={isLoading ? "opacity-40" : ""}
        >
          <Panel
            styles={{
              padding: `${theme.spacing.small} ${theme.spacing.large}`,
              marginBottom: theme.spacing.xlarge2,
            }}
          >
            <Box className="flex justify-between items-center">
              <Box className="flex flex-col">
                <Box className="flex items-center gap-5">
                  <Box className="flex min-h-32">
                    <Field
                      name={`jobs[${index}].invoice`}
                      label={translate("allocateJobs.labels.invoiceAmount")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={!values.jobs[index].override}
                          onKeyDown={preventFormSubmit}
                        />
                      )}
                    </Field>
                  </Box>
                  {values.jobs[index].override && (
                    <Box className="flex gap-5 min-h-32">
                      <Field
                        name={`jobs[${index}].message`}
                        label={translate(
                          "allocateJobs.labels.specifyTheReason",
                        )}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>
                      <Box className="flex items-center pb-2">
                        <Button
                          onClick={() => {
                            setFieldValue(`jobs[${index}].override`, false);
                            setFieldValue(
                              `jobs[${index}].invoice`,
                              initialInvoice,
                            );
                            setFieldValue(`jobs[${index}].message`, "");
                          }}
                          type="button"
                          size="xsmall"
                          color="negative"
                        >
                          {translate("allocateJobs.buttons.cancel")}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {!values.jobs[index].override && (
                    <UnstyledButton
                      onClick={() => {
                        setFieldValue(`jobs[${index}].override`, true);
                        setFieldValue(`jobs[${index}].message`, "");
                      }}
                      className="mb-3"
                      type="button"
                      styles={{
                        borderBottom: `2px solid ${theme.colors.brand.base}`,
                      }}
                    >
                      <Text color="brand">
                        {translate("allocateJobs.buttons.edit")}
                      </Text>
                    </UnstyledButton>
                  )}
                </Box>
                <Box className="flex gap-5">
                  <Box className="flex gap-2">
                    <Text className="font-semibold">
                      {translate("allocateJobs.titles.jobReference")}
                    </Text>
                    {jobData?.ref}
                  </Box>
                  {jobData?.date && (
                    <Box className="flex gap-2">
                      <Text className="font-semibold">
                        {translate("allocateJobs.titles.collectionDate")}
                      </Text>
                      {dayjs(jobData?.date).format("DD/MM/YYYY")}
                    </Box>
                  )}
                  {jobData?.addressPostcode && (
                    <Box className="flex gap-2">
                      <Text className="font-semibold">
                        {translate("allocateJobs.titles.postcode")}
                      </Text>
                      {jobData?.addressPostcode}
                    </Box>
                  )}
                  {jobData?.orderTypeId && (
                    <Box className="flex gap-2">
                      <Text className="font-semibold">
                        {translate("allocateJobs.titles.orderType")}
                      </Text>
                      {orderTypes?.items.find(
                        ({ id }) => id === jobData.orderTypeId,
                      )?.name || ""}
                    </Box>
                  )}
                </Box>
                {jobData?.description && (
                  <Box className="flex gap-2">
                    <Text className="font-semibold">
                      {translate("allocateJobs.titles.description")}
                    </Text>
                    {jobData?.description}
                  </Box>
                )}
                {!values.jobs[index].valid && (
                  <Box
                    className="px-2 py-px mt-2 rounded-sm"
                    color="light"
                    backgroundColor="negative.base"
                  >
                    <Text>
                      {translate(
                        "allocateJobs.errors.catalogueMissingProducts",
                      )}
                      {missingProducts.map((item) => item.title).join(", ")}
                    </Text>
                  </Box>
                )}
                {!!areNotCoveringPostcode?.length && !!jobData && (
                  <Box color="warning.dark" className="flex flex-col mt-2">
                    <Box className="flex gap-1">
                      <Icon.Attention size="medium" color="warning" />
                      <Box className="flex flex-col flex-wrap">
                        {translate("allocateJobs.errors.dontCoverPostcode")}
                        <Box className="flex gap-1 flex-wrap">
                          {areNotCoveringPostcode?.map(({ name }, index) => (
                            <Text key={index} fontSize="small">
                              {name},
                            </Text>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <UnstyledButton
                disabled={
                  patchJobIsFailed || patchJobIsLoading || patchJobIsSuccess
                }
                onClick={() => {
                  setFieldValue(
                    "jobs",
                    values.jobs.filter(
                      (_: unknown, jobIndex: number) => index !== jobIndex,
                    ),
                  );
                  onJobRemove(index);
                }}
              >
                {!patchJobIsLoading &&
                  !patchJobIsSuccess &&
                  !patchJobIsFailed && (
                    <Icon.Trash color="negative" size="large" />
                  )}
                {patchJobIsLoading && <Icon.Loading size="large" />}
                {patchJobIsSuccess && (
                  <Icon.Tick2 color="positive" size="large" />
                )}
                {patchJobIsFailed && (
                  <Icon.Close color="negative" size="large" />
                )}
              </UnstyledButton>
            </Box>
          </Panel>
        </Box>
      </Box>
    </>
  );
};
