import React, { useState } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "../../../helpers";
import {
  Button,
  Field,
  Form,
  Heading,
  Input,
  Panel,
  theme,
  Text,
  Message,
  Box,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";

const initialValues = {
  driver: "",
  date: "",
};

type FormValues = typeof initialValues;

const validationSchema = Yup.object().shape({
  driver: Yup.string().required(),
  date: Yup.string().required(),
});

export const DriverJobs = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [selectedDriversReg, setSelectedDriversReg] = useState<string>();
  const [formValues, setFormValues] = useState<FormValues>();
  const [noVehicleAssigned, setNoVehicleAssigned] = useState<boolean>(false);

  /**
   * get all drivers for dropdown
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isFetching: isFetchingAllDrivers,
    isError: isErrorAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver",
            limit: 1000,
          },
        })
      ).data.items,
  );

  /**
   * get jobs with associated vehicle registration
   */
  const {
    data: driverJobsData,
    isLoading: isLoadingDriverJobs,
    isError: isErrorDriverJobs,
  } = useQuery(
    ["getDriverJobs", selectedDriversReg, formValues?.date],
    async () =>
      (
        await instance.jobs.getJobsByAsset(selectedDriversReg || "", {
          params: {
            date: formValues?.date,
            "include-related": true,
          },
        })
      ).data,
    {
      enabled: !!selectedDriversReg,
      cacheTime: 0,
    },
  );

  const handleSubmit = async (values: FormValues) => {
    setNoVehicleAssigned(false);

    if (values.driver !== formValues?.driver) {
      setSelectedDriversReg("");
    }

    setFormValues({
      ...values,
      date: dayjs(values.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    });

    const asset = allDriversData
      ?.find((driver) => driver.email === values.driver)
      ?.attributes?.find((attribute) => attribute.attrKey === "asset");

    !!asset
      ? setSelectedDriversReg(asset?.attrValue)
      : setNoVehicleAssigned(true);
  };

  return (
    <Box className=" mx-auto py-10 relative">
      {/* LOADING */}
      {(isLoadingDriverJobs || isLoadingAllDrivers || isFetchingAllDrivers) && (
        <LoadingOverlay />
      )}

      <Panel className="flex flex-col gap-x-6 justify-between" shadow={false}>
        <Box className="flex justify-between">
          <Heading fontSize="large" color="brand">
            {translate("jobs.headings.read")}
          </Heading>
        </Box>
        <Form
          className="border-t border-grey-200 mt-4 pt-5"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <>
            <Box className="flex flex-row items-center w-full gap-x-3">
              <Box className="w-5/6">
                {/* DRIVERS */}
                <Field
                  name="driver"
                  className="flex-1 min-w-full"
                  label={translate("jobs.form.headings.driver")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isClearable
                      isSearchable
                      options={
                        !!allDriversData?.length
                          ? allDriversData?.map(
                              ({ firstName, lastName, email }) => ({
                                label: `${firstName} ${lastName}`,
                                value: email,
                              }),
                            )
                          : []
                      }
                    />
                  )}
                </Field>
              </Box>

              {/* DATE */}
              <Box className="w-1/4">
                <Field
                  name="date"
                  label="Date"
                  styles={{ margin: theme.spacing.xsmall }}
                >
                  {({ field }) => (
                    <Input.Date {...field} placeholder="Date" collapsable />
                  )}
                </Field>
              </Box>

              <Box className=" w-1/6">
                <Button
                  className="ml-3 relative top-3"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Box>

            {/* DRIVER INFORMATION */}
            <Box>
              {!!selectedDriversReg && (
                <Box className="flex flex-row items-center w-5/6 justify-start gap-x-3 mt-5">
                  <Box>
                    <Text fontSize="xsmall" className="font-bold">
                      {translate("jobs.form.headings.driverEmail")}
                    </Text>
                    <Text fontSize="xsmall">{formValues?.driver}</Text>
                  </Box>

                  <Box>
                    <Text fontSize="xsmall" className="font-bold">
                      {translate("jobs.form.headings.vehicleReg")}
                    </Text>
                    <Text fontSize="xsmall">{selectedDriversReg}</Text>
                  </Box>

                  {!isLoadingDriverJobs && !!driverJobsData?.length && (
                    <Box>
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.totalJobs")}
                      </Text>
                      <Text fontSize="xsmall">{driverJobsData?.length}</Text>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </>
        </Form>
      </Panel>

      {/* ERRORS */}
      <Box className="flex items-center justify-center w-full">
        {!!driverJobsData && !driverJobsData?.length && (
          <Message className="mt-2" type="error" background>
            No jobs found for this selection. Please try again.
          </Message>
        )}
        {isErrorDriverJobs && (
          <Message className="mt-2" type="error" background>
            An error occurred while fetching driver jobs. Please try again.
          </Message>
        )}
        {isErrorAllDrivers && (
          <Message className="mt-2" type="error" background>
            An error occurred while fetching drivers. Please try again.
          </Message>
        )}
        {noVehicleAssigned && (
          <Message className="mt-2" type="error" background>
            {`No vehicle assigned to ${formValues?.driver}. Please try again.`}
          </Message>
        )}
      </Box>

      <Box className="relative flex flex-1">
        {/* DRIVER JOBS */}
        {!isLoadingDriverJobs && !!driverJobsData && !!driverJobsData?.length && (
          <Box className="w-full">
            {driverJobsData
              ?.sort(
                (a, b) =>
                  dayjs(a.plannedStartTime).unix() -
                  dayjs(b.plannedStartTime).unix(),
              )
              ?.map((job, index) => (
                <Panel
                  shadow={false}
                  key={index}
                  className="flex flex-row my-5 w-full"
                  style={{
                    paddingTop: theme.spacing.small,
                    paddingBottom: theme.spacing.small,
                  }}
                >
                  <Box className="flex flex-row items-center justify-between w-full gap-x-3">
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobRef")}
                      </Text>
                      <Text fontSize="xsmall">{job.ref}</Text>
                    </Box>
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobDate")}
                      </Text>
                      <Text fontSize="xsmall">
                        {dayjs(job.date).format("DD/MM/YYYY")}
                      </Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobPostcode")}
                      </Text>
                      <Text fontSize="xsmall">{job.addressPostcode}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobBcId")}
                      </Text>
                      <Text fontSize="xsmall">{job.bcId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobStatus")}
                      </Text>
                      <Text fontSize="xsmall">{job.status}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobStatusId")}
                      </Text>
                      <Text fontSize="xsmall">{job.statusId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-26">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobAsset")}
                      </Text>
                      <Text fontSize="xsmall">{job.asset}</Text>
                    </Box>
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobType")}
                      </Text>
                      <Text fontSize="xsmall">{job.type}</Text>
                    </Box>
                    <Box className="gap-y-3 w-24">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobOrderTypeId")}
                      </Text>
                      <Text fontSize="xsmall">{job.orderTypeId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-56">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobAssetCrew")}
                      </Text>
                      <Box className="flex overflow-scroll flex-col">
                        {!!job.assetCrew?.length ? (
                          job.assetCrew?.map((driver) => (
                            <Text fontSize="xsmall">{driver}</Text>
                          ))
                        ) : (
                          <Text fontSize="xsmall">No drivers assigned</Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Panel>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
