import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import {
  Box,
  Button,
  Heading,
  Panel,
  Table,
  Text,
  Icon,
  theme,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { IPaginatedResults, TFilters } from "api/types";
import { useAuthContext } from "../../hooks";
import { DeleteConfirmationModal } from "../common/components";
import { UserFilters } from "./components";
import roles from "constants/roles";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { buildQuery } from "helpers/api";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { IUser } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";

export const ReadUsers = (): React.ReactElement => {
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);
  const [translate] = useTranslation("users");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const readUsers = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IUser>> => {
    const filterParams = new URLSearchParams(filters);

    //company filters
    const firstName = filterParams?.get("firstName:like") ?? "";
    const lastName = filterParams?.get("lastName:like") ?? "";
    const email = filterParams?.get("email:like") ?? "";
    const company = filterParams?.get("company:eq") ?? "";
    const role = filterParams?.get("role:eq") ?? "";

    const { data } = await instance.users.getUsers({
      params: {
        ...(!!firstName ? { "firstName:like": `%${firstName}%` } : {}),
        ...(!!lastName ? { "lastName:like": `%${lastName}%` } : {}),
        ...(!!email ? { "email:like": `%${email}%` } : {}),
        ...(!!company ? { "companies.companyCode:eq": company } : {}),
        ...(!!role ? { "roles.name:eq": role } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<IUser>;

    return response;
  };

  const { mutate: deleteUser } = useMutation(
    async ({
      email,
      isSubcontractorUser,
      isClearabeeDriverUser,
    }: {
      email: string;
      isSubcontractorUser?: boolean;
      isClearabeeDriverUser?: boolean;
    }) =>
      await instance.users.deleteUser(email, {
        headers: {
          ...(isSubcontractorUser
            ? {
                actionableuserpoolid:
                  process.env.REACT_APP_PARTNERS_USER_POOL_ID || "",
              }
            : {}),
          ...(isClearabeeDriverUser
            ? {
                actionableuserpoolid:
                  process.env.REACT_APP_DRIVERS_USER_POOL_ID || "",
              }
            : {}),
        },
      }),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    setCurrentPage,
    currentPage,
    setResultsPerPage,
    query: { isFetching, isLoading, refetch },
    resultsPerPage,
  } = usePaginatedQuery(
    readUsers,
    "readUsers",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : 10,
    },
    paginationStyles,
  );

  return (
    <Box
      data-testid="readUsersComponentIdentifier"
      className="max-w-screen-lg mx-auto"
    >
      <Panel shadow={false} styles={{ marginBottom: theme.spacing.large }}>
        <Box className="flex flex-row items-center justify-between">
          <Heading level={1} fontSize="large" color="brand">
            {translate("common.title")}
          </Heading>
          <RouteLink href="/users/create">
            <Button size="small" as="a" className="inline-block text-center">
              {translate("common.addUser")}
            </Button>
          </RouteLink>
        </Box>
        {(isClearabeeAdmin || isCompanyAdmin || isClearabeeManager) && (
          <UserFilters
            isFetching={isFetching}
            updateFilters={updateFilters}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
          />
        )}
      </Panel>
      {(isLoading || isFetching) && (
        <Box className="flex justify-center">
          <Icon.Loading color="brand" />
        </Box>
      )}
      {!!paginatedData.length && !isLoading && !isFetching && (
        <>
          <Table className="mb-5">
            <Table.Header
              headings={[
                translate("table.headings.status"),
                translate("table.headings.name"),
                translate("table.headings.email"),
                translate("table.headings.defaultCompany"),
                translate("table.headings.roles"),
              ]}
            />
            <Table.Body>
              {paginatedData.map((user) => (
                <Table.Row key={user.id}>
                  <Table.Cell>
                    <Box className="flex">
                      {!!user.active ? (
                        <Box
                          className="flex px-2 py-1 rounded-xl"
                          color="light"
                          backgroundColor="positive"
                        >
                          <Text fontSize="xsmall">Active</Text>
                        </Box>
                      ) : (
                        <Box
                          className="flex px-2 py-1 rounded-xl"
                          color="light"
                          backgroundColor="negative"
                        >
                          <Text fontSize="xsmall">Inactive</Text>
                        </Box>
                      )}
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">
                      {user.firstName} {user.lastName}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">{user.email}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">
                      {user.company ||
                        (!!user.companies?.length && user.companies[0].name)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text fontSize="xsmall">
                      {user.roles?.map(({ name }) => name).join(", ")}
                    </Text>
                  </Table.Cell>
                  <Table.Cell className="flex gap-3">
                    <Link to={`/users/update/${user.email}`}>
                      <Button
                        variant="outline"
                        className="px-2 flex justify-center"
                        size="xsmall"
                        type="button"
                      >
                        {translate("table.actions.edit")}
                      </Button>
                    </Link>
                    <Button
                      variant="outline"
                      className="px-2"
                      size="xsmall"
                      color="negative"
                      type="button"
                      onClick={() => {
                        setSelectedUser(user);
                        setShowModal(true);
                      }}
                    >
                      {translate("table.actions.delete")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Box className="flex justify-center">
            <PaginationComponent />
          </Box>
        </>
      )}
      {!paginatedData.length && !isLoading && !isFetching && (
        <Heading styles={{ textAlign: "center" }} level={2} fontSize="large">
          {translate("table.noResults")}
        </Heading>
      )}
      <DeleteConfirmationModal
        title="Are you sure you want to delete this?"
        visible={showModal}
        deleteCallback={() => {
          const roles = selectedUser?.roles;

          /**
           * check user is a subcontractor user if has role Subcontractor, this includes subcontractor staff and subcontractor admin
           * user can have multiple roles, but if has one role which is Subcontractor, then we will delete
           */
          const isSubcontractorUser =
            !!roles &&
            roles.length === 1 &&
            !!roles.find(({ name }) =>
              name.toLowerCase().includes("subcontractor"),
            );

          /**
           * check user is a clearabee driver user if has role Clearabee Driver
           * user can have multiple roles, but if has one role which is Clearabee Driver, then we will delete
           */
          const isClearabeeDriverUser =
            !!roles &&
            roles.length === 1 &&
            !!roles.find(({ name }) => name.toLowerCase().includes("driver"));

          deleteUser({
            email: selectedUser?.email || "",
            isSubcontractorUser,
            isClearabeeDriverUser,
          });
        }}
        hideCallback={() => {
          setShowModal(false);
        }}
        yesButtonLabel="Delete"
      />
    </Box>
  );
};
