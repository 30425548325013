"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portal = void 0;
exports.portal = {
    buttons: {
        needHelp: "Need Help?",
        chatWithUs: "Chat with us",
    },
    cta: {
        text: {
            openUntil: "We are open until ",
            callUs: " call us on ",
            today: "today",
            callCentreCurrently: "Our call centre is currently ",
            closed: "closed",
            reopen: ", but we open {{day}} at a ",
        },
    },
};
