import React, { useMemo } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMutation } from "react-query";
import Select from "react-select";
import { instance } from "@clearabee/ui-sdk";
import { IVehicleScheduleResponse } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Text,
  theme,
  Button,
  Input,
  Field,
  Form,
  Modal,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { LoadingOverlay } from "components/common/components";
import { stringRequired, optionField } from "validation/common";

dayjs.extend(utc);

interface AmendSlackRoomsModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  dates: string[];
  allVehicleIds: number[];
  schedule: IVehicleScheduleResponse[];
}

interface InitialValuesProps {
  date: string;
  sendStartMessage: boolean;
  vehicles: Array<{ value: number; label: string }>;
}

const initialValues: InitialValuesProps = {
  date: "",
  sendStartMessage: true,
  vehicles: [],
};

const validationSchema = Yup.object().shape({
  date: stringRequired,
  sendStartMessage: Yup.boolean(),
  vehicles: Yup.array().of(optionField).nullable(),
});

export const AmendSlackRoomsModal = ({
  dates,
  allVehicleIds,
  showModal,
  setShowModal,
  schedule,
}: AmendSlackRoomsModalProps): React.ReactElement => {
  const { mutate, isLoading } = useMutation(
    "amendSlackRooms",
    async ({
      date,
      sendStartMessage,
      vehicleIds,
    }: {
      date: string;
      sendStartMessage: boolean;
      vehicleIds: number[];
    }) => {
      await instance.vehicles.postVehicleScheduleSlackNotifications({
        date,
        vehicleIds: vehicleIds.length === 0 ? allVehicleIds : vehicleIds,
        suppressMessage: !sendStartMessage,
      });
    },
    {
      onSuccess: () => {
        setShowModal(false);
        toasts.success({
          message: "Sent request to amend slack rooms",
        });
      },
      onError: () => {
        toasts.error({
          message:
            "An error occurred while trying to amend the slack rooms, please try again",
        });
      },
    },
  );

  /**
   * Date options
   */
  const dateOptions = useMemo(() => {
    if (!dates.length) return [];

    return dates.map((date) => ({
      label: dayjs.utc(date).format("dddd, D MMMM YYYY"),
      value: date,
    }));
  }, [dates]);

  /**
   * Vehicle options
   */
  const vehicleOptions = useMemo(() => {
    if (!schedule.length) return [];

    return schedule.map((scheduleitem) => ({
      value: scheduleitem.vehicleId,
      label: scheduleitem.name,
    }));
  }, [schedule]);

  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mutate({
                date: dayjs.utc(values.date).toISOString(),
                sendStartMessage: values.sendStartMessage,
                vehicleIds: values?.vehicles?.map(({ value }) => value) || [],
              });
            }}
          >
            {/* MODAL LOADING OVERLAY */}
            {isLoading && (
              <LoadingOverlay
                iconSize="xlarge2"
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}
            <Box className="flex flex-col items-center justify-start gap-y-3">
              <Heading color="brand" level={4}>
                Amend Slack Rooms
              </Heading>

              <Text className="px-8">
                This will trigger a flow for the desired date that will put
                drivers into the the slack room associated to their allocated
                vehicle.
              </Text>

              <Box className="w-1/2 text-left mb-3">
                {/* DATE */}
                <Field
                  styles={{
                    marginBottom: theme.spacing.xsmall2,
                  }}
                  name="date"
                  label="Date"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isClearable
                      placeholder="Select a date"
                      options={dateOptions}
                    />
                  )}
                </Field>

                {/* SEND START MESSAGE */}
                <Box className="flex justify-start">
                  <Field name="sendStartMessage">
                    {({ field }) => (
                      <Box className="flex items-center gap-x-1">
                        <Input.Checkbox
                          {...field}
                          defaultChecked={field.value}
                        />
                        <label className="font-medium">
                          Send start time message
                        </label>
                      </Box>
                    )}
                  </Field>
                </Box>

                {/* VEHICLES */}
                <Box className="w-full">
                  <Field
                    styles={{ marginBottom: theme.spacing.xsmall2 }}
                    name="vehicles"
                    label="Vehicles"
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        isClearable
                        placeholder="Select vehicles"
                        options={vehicleOptions}
                      />
                    )}
                  </Field>
                  <Text fontSize="xsmall">
                    Leave empty to amend all vehicles
                  </Text>
                </Box>
              </Box>
              {/* SUBMIT */}
              <Button type="submit" color="accent" size="small">
                Amend
              </Button>
            </Box>
          </Form>
        </Modal>
      )}
    </>
  );
};
