import React, { useState } from "react";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  Heading,
  Button,
  Box,
  theme,
  Icon,
  Panel,
  Form,
  Field,
  Input,
  Message,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { inBoth, onlyInDatabase, onlyInBigchange } from "./helpers/helpers";
import { JobBlock } from "../../subcontractors/compareJobs/components/JobBlock";
import dayjs from "dayjs";

const initialValues = {
  resourceId: "",
  date: "",
};

const validationSchema = Yup.object().shape({
  resourceId: Yup.string().required(),
  date: Yup.string().required(),
});

type FormValues = {
  resourceId: string;
  date: string;
};

type ResourcesResponseResult = {
  label: string;
  id: number;
};

const tableHeadings = [
  "Allocated in Both",
  "Only Allocated in Our Database",
  "Only Allocated in BigChange",
];

const regEx = /\(([^)]+)\)/;

export const CompareJobs = (): React.ReactElement => {
  const { t } = useTranslation("jobs");
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>();
  const [selectedResourceReg, setSelectedResourceReg] = useState<string>();
  const [resourceOptions, setResourceOptions] =
    useState<{ label: string; value: number }[]>();

  const handleSubmit = async (values: FormValues) => {
    const selectedResourceReg = resourceOptions
      ?.find((resource) => String(resource.value) === values.resourceId)
      ?.label.match(regEx)?.[1];

    setSelectedResourceReg(selectedResourceReg);

    setErrors([]);

    setWarnings([]);

    if (!selectedResourceReg) {
      setWarnings((prevErrors) => [
        ...prevErrors,
        "registration couldn't be extracted from the selected resource",
      ]);
    }

    if (!!selectedResourceReg) {
      setFormValues({
        ...values,
        date: dayjs.utc(values.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
      });
    }
  };

  const { data: resources, isLoading: resourcesIsLoading } = useQuery(
    ["getResources"],
    async () => await instance.jobs.getJobsFromBigChange("Resources"),
    {
      onSuccess: (data) => {
        if (data?.data.Code !== 0) {
          setErrors((prevErrors) => [
            ...prevErrors,
            "Something went wrong when fetching resources",
          ]);
        }
        setResourceOptions(
          data?.data.Result.map((resource: ResourcesResponseResult) => ({
            label: resource.label,
            value: resource.id,
          })),
        );
      },
      onError: () => {
        setErrors((prevErrors) => [
          ...prevErrors,
          "Something went wrong when fetching subcontractors",
        ]);
      },
      cacheTime: 1800000,
    },
  );

  const {
    isLoading: bigChangeJobsIsLoading,
    data: bigChangeJobs,
    refetch: refetchBigChangeJobs,
    isRefetching: bigChangeJobsIsRefetching,
  } = useQuery(
    ["getBigChangeJobs", formValues],
    async () =>
      await instance.jobs.getJobsFromBigChange("JobsList", {
        params: {
          Start: formValues?.date,
          End: formValues?.date,
          ResourceId: formValues?.resourceId,
        },
      }),
    {
      retry: 1,
      cacheTime: 0,
      enabled: !!formValues,
      onSuccess: (data) => {
        if (data?.data.Code !== 0) {
          setErrors((prevErrors) => [
            ...prevErrors,
            "Something went wrong when fetching big change jobs",
          ]);
        }
        if (data?.data.Result === "No results") {
          setWarnings((prevWarnings) => [
            ...prevWarnings,
            "no jobs found in BigChange",
          ]);
        }
      },
    },
  );

  const {
    data: driverJobsData,
    isLoading: driverJobsIsLoading,
    refetch: refetchDriverJobs,
    isRefetching: driverJobsIsRefetching,
  } = useQuery(
    ["getDriverJobs", selectedResourceReg, formValues?.date],
    async () =>
      (
        await instance.jobs.getJobsByAsset(selectedResourceReg || "", {
          params: {
            date: formValues?.date,
            "include-related": true,
          },
        })
      ).data,
    {
      enabled: !!selectedResourceReg,
      cacheTime: 0,
      onError: () => {
        setErrors((prevErrors) => [
          ...prevErrors,
          "Something went wrong when fetching driver jobs",
        ]);
      },
      onSuccess: (data) => {
        if (data?.length === 0) {
          setWarnings((prevWarnings) => [
            ...prevWarnings,
            "no jobs found in our database",
          ]);
        }
      },
    },
  );

  return (
    <>
      {/* Loading */}
      {(bigChangeJobsIsLoading ||
        driverJobsIsLoading ||
        resourcesIsLoading ||
        bigChangeJobsIsRefetching ||
        driverJobsIsRefetching) && <LoadingOverlay />}

      <>
        <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <>
              <Panel shadow={false}>
                <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                  <Heading
                    level={5}
                    color="brand"
                    className="pr-4 w-full sm:w-auto"
                  >
                    Compare Driver Jobs
                  </Heading>

                  <Box className="flex justify-end self-end col-start-3 gap-x-3">
                    <Button
                      onClick={() => {
                        refetchBigChangeJobs();
                        refetchDriverJobs();
                      }}
                      size="small"
                      color="accent"
                      type="button"
                      disabled={!formValues}
                    >
                      Refresh
                    </Button>
                    {/* SUMBIT */}
                    <Button
                      size="small"
                      color="brand"
                      type="submit"
                      className="flex items-center gap-x-1"
                    >
                      <Icon.Search2 size="small" />
                      Search
                    </Button>
                  </Box>
                </Box>

                <Box className="flex flex-row gap-6">
                  {/* RESOURCES */}
                  <Box className="w-1/3">
                    <Field
                      name="resourceId"
                      label="Resource"
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder="Resource"
                          options={resourceOptions}
                          isSearchable
                        />
                      )}
                    </Field>
                  </Box>

                  {/* DATE */}
                  <Box className="w-1/4">
                    <Field
                      name="date"
                      label="Date"
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Date {...field} placeholder="Date" collapsable />
                      )}
                    </Field>
                  </Box>
                </Box>

                {/* WARNINGS */}
                {warnings?.length > 0 && (
                  <Message className="mt-3" type="warn">
                    {warnings.join(` & `)}
                  </Message>
                )}

                {/* ERRORS */}
                {errors?.length > 0 && (
                  <Message type="error" background>
                    {errors.join(` & `)}
                  </Message>
                )}
              </Panel>
            </>
          </Form>
        </Box>

        {!!bigChangeJobs && !!driverJobsData && (
          <Panel shadow={false}>
            {/* Table Headings */}
            <Box className="flex flex-row mb-3">
              {tableHeadings.map((heading, index) => (
                <Box
                  key={index}
                  className="flex justify-center items-center w-1/3"
                >
                  <Heading color="brand" level={4}>
                    {heading}
                  </Heading>
                </Box>
              ))}
            </Box>

            {/* Divider */}
            <Box className="border-b mb-6" />

            <Box className="flex flex-row gap-3 mb-3">
              <Box className="flex flex-col gap-3 w-1/3">
                {inBoth(driverJobsData, bigChangeJobs?.data.Result)
                  ?.sort(
                    (a, b) =>
                      dayjs(a.plannedStartTime).unix() -
                      dayjs(b.plannedStartTime).unix(),
                  )
                  ?.map((job, index) => (
                    <JobBlock
                      key={`both-${index}`}
                      poolStatus={job.status}
                      postcode={job.addressPostcode}
                      reference={job.ref}
                      jobStatus={job.status}
                      type="both"
                      hidePoolStatus={true}
                    />
                  ))}
              </Box>
              <Box className="flex flex-col gap-3 w-1/3">
                {onlyInDatabase(driverJobsData, bigChangeJobs?.data.Result)
                  .sort(
                    (a, b) =>
                      dayjs(a.plannedStartTime).unix() -
                      dayjs(b.plannedStartTime).unix(),
                  )
                  ?.map((job, index) => (
                    <JobBlock
                      key={`db-${index}`}
                      poolStatus={job.status}
                      postcode={job.addressPostcode}
                      reference={job.ref}
                      jobStatus={job.status}
                      type="only-db"
                      hidePoolStatus={true}
                    />
                  ))}
              </Box>
              <Box className="flex flex-col gap-3 w-1/3">
                {onlyInBigchange(driverJobsData, bigChangeJobs?.data.Result)
                  ?.sort(
                    (a, b) =>
                      dayjs(a.PlannedStart).unix() -
                      dayjs(b.PlannedStart).unix(),
                  )
                  ?.map((job, index) => (
                    <JobBlock
                      key={`bc-${index}`}
                      jobStatus={job.Status}
                      postcode={job.Postcode}
                      reference={job.Ref}
                      type="only-bc"
                      hidePoolStatus={true}
                    />
                  ))}
              </Box>
            </Box>
          </Panel>
        )}
      </>
    </>
  );
};
