import React, { useState } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import {
  Box,
  Form,
  Field,
  UnstyledButton,
  Icon,
  theme,
  Modal,
  Input,
  Button,
} from "@clearabee/ui-library";
import { IBasket, IBasketContact } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import { stringRequired } from "validation/common";
import { LoadingOverlay } from "components/common/components";

interface EditJobDetailsModalProps {
  jobData: IBasket & {
    orderRef: string;
  };
  setIsUpdateJobDetails: (value: boolean) => void;
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  firstName: stringRequired,
  lastName: stringRequired,
  email: Yup.string().email("Invalid email"),
  phoneNumber: Yup.string()
    .matches(
      /^(?:0|\+?44)\s?(?:\(0\)){0,1}\s?(?:\d\s?){9,10}$/,
      "Invalid phone number",
    )
    .typeError("Invalid phone number"),
  description: Yup.string(),
});

export const EditJobDetailsModal = ({
  jobData,
  setIsUpdateJobDetails,
  onClose,
  onSuccess,
}: EditJobDetailsModalProps): React.ReactElement => {
  const initialValues = {
    firstName: jobData.contact?.firstName,
    lastName: jobData.contact?.lastName,
    email: jobData.contact?.email,
    phoneNumber: jobData.contact?.phoneNumber,
    description: jobData.description,
  };

  const fieldStyles = {
    minHeight: "85px",
  };

  const [error, setError] = useState("");

  const { mutate: patchBasket, isLoading } = useMutation(
    ["updateJobDetails"],
    async (
      values: IBasketContact & {
        description: string;
      },
    ) => {
      setIsUpdateJobDetails(true);

      await instance.catalogues.patchBasket(jobData.basketToken, {
        contact: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
        },
        description: values.description,
      });
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error: Error) => {
        setError(error.message);
      },
      onSettled: () => setIsUpdateJobDetails(false),
    },
  );

  return (
    <Modal
      styles={{
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          maxHeight: "95vh",
          minWidth: theme.screens.xsmall,
        },
      }}
    >
      {isLoading && (
        <LoadingOverlay
          iconSize="xlarge2"
          backgroundLoadingContainerStyles={{
            borderRadius: theme.spacing.small,
          }}
        />
      )}
      <Box className="flex justify-end">
        <UnstyledButton onClick={onClose}>
          <Icon.Close size="small" color="dark.base" />
        </UnstyledButton>
      </Box>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => patchBasket(values)}
      >
        <Box className="text-left">
          <Field name="firstName" label="First Name" styles={fieldStyles}>
            {({ field }) => <Input.Text {...field} />}
          </Field>
          <Field name="lastName" label="Last Name" styles={fieldStyles}>
            {({ field }) => <Input.Text {...field} />}
          </Field>
          <Field name="email" label="Email" styles={fieldStyles}>
            {({ field }) => <Input.Text {...field} />}
          </Field>
          <Field name="phoneNumber" label="Phone Number" styles={fieldStyles}>
            {({ field }) => <Input.Text {...field} />}
          </Field>
          <Field name="description" label="Description" styles={fieldStyles}>
            {({ field }) => <Input.Text {...field} />}
          </Field>
        </Box>
        <Box className="flex justify-center mb-3">
          <Button
            className="flex justify-center"
            size="small"
            color="accent"
            type="submit"
          >
            Save
          </Button>
        </Box>
        {!!error && (
          <Box className="rounded-md" backgroundColor="negative" color="light">
            {error}
          </Box>
        )}
      </Form>
    </Modal>
  );
};
