import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Input,
  Panel,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import { useTranslation } from "react-i18next";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { initialValues, validationSchema } from "./validation";
import { AddJob, JobRow } from "./components";

export const JobsAllocation = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [selectedJobs, setSelectedJobs] = useState<
    ApiResponseData<typeof instance.jobs.getJobByRef>[]
  >([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const preventFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const { data: vehicles, isLoading } = useQuery("getVehicles", async () => {
    return (
      await instance.vehicles.getVehicles({
        params: {
          limit: 1000,
        },
      })
    ).data.items;
  });

  const { data: drivers } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver",
            limit: 1000,
          },
        })
      ).data.items,
  );

  const { data: orderTypes } = useQuery(
    ["getOrderTypes"],
    async () => (await instance.catalogues.getOrderTypes()).data,
    {
      keepPreviousData: true,
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {
        if (!!selectedJobs.length) {
          setFormSubmitted(true);
        }
      }}
    >
      {({ resetForm, values }) => {
        const assetCrew = drivers?.filter(({ attributes }) =>
          attributes?.some(
            ({ attrKey, attrValue }) =>
              attrKey === "asset" && attrValue === values.vehicle,
          ),
        );

        return (
          <>
            <Panel className="mb-12">
              {isLoading && <LoadingOverlay />}
              <Box className="flex justify-between items-center">
                <Heading color="brand" level={4}>
                  {translate("allocateJobs.headings.bulkAllocation")}
                </Heading>
                <Button
                  onClick={() => {
                    resetForm();
                    setSelectedJobs([]);
                  }}
                  size="small"
                  variant="outline"
                  color="negative"
                >
                  {translate("allocateJobs.buttons.reset")}
                </Button>
              </Box>
              <Box className="border-t border-gray-400 mt-4 pb-6" />
              <Box className="flex items-center justify-between gap-3">
                <Box className="flex-grow h-28">
                  <Field
                    name="vehicle"
                    label={translate("allocateJobs.labels.vehicle")}
                  >
                    {({ field }) => (
                      <Input.Select
                        {...field}
                        isSearchable
                        onKeyDown={preventFormSubmit}
                        options={vehicles?.map((item) => {
                          return {
                            label: item.registration,
                            value: item.registration,
                          };
                        })}
                      />
                    )}
                  </Field>
                </Box>
                <Button type="submit" size="medium">
                  {translate("allocateJobs.buttons.submit")}
                </Button>
              </Box>
            </Panel>
            <AddJob
              addJob={(job) => setSelectedJobs([...selectedJobs, job])}
              refs={selectedJobs.map((job) => job.ref)}
            />
            {selectedJobs.map((selectedJob, index) => (
              <JobRow
                key={index}
                formSubmitted={formSubmitted}
                orderTypes={orderTypes}
                index={index}
                assetCrew={assetCrew?.map(({ email }) => email)}
                jobData={selectedJob}
                onJobPatched={() => setFormSubmitted(false)}
                onJobRemove={() =>
                  setSelectedJobs(
                    selectedJobs.filter((_, jobIndex) => jobIndex !== index),
                  )
                }
              />
            ))}
          </>
        );
      }}
    </Form>
  );
};
