import * as Yup from "yup";
import {
  stringRequired,
  postcodeRegExp,
  validPostcode,
} from "../../../validation/common";

/**
 * Import types.
 */
import { IFormState } from "./types";

/**
 * Payment find job schema
 */
export const findJobSchema = Yup.object().shape({
  reference: stringRequired,
  postcode: stringRequired.matches(postcodeRegExp, validPostcode),
});

export const findJobInitialValues = {
  reference: "",
  postcode: "",
};

/**
 * Select service step.
 */
export const selectServiceSchema = Yup.object().shape({
  service: stringRequired,
});

/**
 * Initial Form state.
 * Used by MultiForm component.
 */
export const formStateInitialValues: IFormState = {
  service: "",
  job: undefined,
  items: [],
};
