import { defaultCountries } from "react-international-phone";

export const isCountryCode = (
  phoneNumber: string | null | undefined,
): boolean => {
  if (!phoneNumber) {
    return false;
  }

  return !!defaultCountries.find(
    (country) => country[2] === phoneNumber.replace("+", ""),
  );
};
