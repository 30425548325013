import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { OptionTypeBase } from "react-select";
import {
  IVehicleSchedulePostBody,
  IVehicleScheduleResponse,
  IVehicleSchedule,
} from "@clearabee/api-schemas";

dayjs.extend(utc);

export type BulkAmendItem = {
  date: string;
  registration: string;
  time: string;
  message?: string;
};

export type FlattenedScheduleDate = {
  user: string;
  asset: string;
  date: string | Date;
  time: string;
  message?: string;
  userId: number;
  vehicleId: number;
};

export const getDates = (startDate: Date, endDate: Date): string[] => {
  // Get all dates between two dates
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days: number) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates.map((date) => dayjs(date).toISOString());
};

export const flattenScheduleDates = (
  schedule: IVehicleScheduleResponse[],
  drivers: OptionTypeBase[],
): FlattenedScheduleDate[] => {
  // Flatten the schedule array and add the user name to each entry

  const flattened = schedule
    .map((item) => {
      return item.schedule.map((schedule) => {
        return {
          ...schedule,
          vehicleId: item.vehicleId,
          asset: item.asset,
        };
      });
    })
    .flat();

  const addEmail: FlattenedScheduleDate[] = flattened.map((item) => ({
    ...item,

    user: drivers.find((option) => {
      return option.value == item.userId;
    })?.label,
  }));

  return addEmail;
};

export const prepareCloneSchedule = (
  scheduleDates: FlattenedScheduleDate[],
  cloneTo: string,
): FlattenedScheduleDate[] => {
  const amendDates = scheduleDates.map((scheduleDate) => {
    return {
      ...scheduleDate,
      date: cloneTo,
    };
  });

  return amendDates;
};

export const transformInput = (
  scheduleDates: FlattenedScheduleDate[],
  selectedDates: string[],
  selectedVehicles: number[],
): IVehicleSchedulePostBody => {
  const result: any = {};

  // If the result does not contain an entry for a selected date, add one with an empty array
  const convertedDates = selectedDates.map((date) =>
    dayjs(date).format("YYYY-MM-DD"),
  );

  convertedDates.forEach((date) => {
    if (!result[date]) {
      result[date] = [];
    }
  });

  scheduleDates
    .filter((scheduleDate) => scheduleDate.userId !== 0)
    .forEach((entry: FlattenedScheduleDate) => {
      const { date, time, userId, asset, vehicleId, message } = entry;
      const dateString = new Date(date).toISOString().split("T")[0];

      if (!result[dateString]) {
        result[dateString] = [];
      }

      // Check if there is already an entry for the same time and vehicleId
      let entryForTimeAndAsset = result[dateString].find(
        (entry: FlattenedScheduleDate) =>
          entry.time === time && entry.vehicleId === vehicleId,
      );

      // If there is no such entry, create a new one
      if (!entryForTimeAndAsset) {
        entryForTimeAndAsset = {
          vehicleId: vehicleId,
          time,
          users: [],
          ...(!!message && { message }),
        };
        result[dateString].push(entryForTimeAndAsset);
      }

      // Add the userId to the users array if it's not already present
      if (!entryForTimeAndAsset.users.includes(userId)) {
        entryForTimeAndAsset.users.push(userId);
      }
    });

  // Look at each date and check that the selected vehicles are included in its array, if not add it
  // This means if a date cell has nothing selected
  convertedDates.forEach((date) => {
    selectedVehicles.forEach((vehicle) => {
      const dateEntry = result[date].find(
        (entry: IVehicleSchedule) => entry.vehicleId === vehicle,
      );

      if (!dateEntry) {
        result[date].push({
          vehicleId: vehicle,
          time: "06:00",
          users: [],
        });
      }
    });
  });

  return result;
};
