import * as Yup from "yup";
import { stringRequired, fieldRequired } from "validation/common";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  companyCodes: Yup.array()
    .of(stringRequired)
    .required(fieldRequired)
    .nullable(),
  receipientEmail: Yup.string().email(),
  receipients: Yup.array()
    .of(stringRequired)
    .required(fieldRequired)
    .nullable(),
  range: stringRequired.nullable(),
  startDate: stringRequired,
  active: Yup.boolean(),
});
