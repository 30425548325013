import React from "react";
import {
  Box,
  Heading,
  Modal,
  Button,
  Form,
  Field,
  Input,
  theme,
  Message,
} from "@clearabee/ui-library";
import { ITip } from "@clearabee/api-schemas";
import { useTranslation } from "react-i18next";
import { instance, readAddresses } from "@clearabee/ui-sdk";
import { useMutation } from "react-query";
import { validationSchema } from "./validation";
import { LoadingOverlay } from "components/common/components";
import { getErrorMessage } from "helpers";

interface ITipDetailsProps {
  isVisible: boolean;
  groupOptions: { label: string; value: string }[];
  tip?: ITip;
  onClose: () => void;
  onChange?: () => void;
}

export const TipDetails = ({
  isVisible,
  groupOptions,
  tip,
  onClose,
  onChange,
}: ITipDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");

  const { mutate, isLoading, error, isSuccess, reset } = useMutation(
    ["Update tip"],
    async (values: ITip) => {
      const { latitude, longitude } = await readAddresses(values.postcode);

      await instance.jobs.patchTip(String(values.id), {
        ...values,
        latitude,
        longitude,
        rate: Number(values?.rate ?? 0),
      });
    },
    {
      onSuccess: () => {
        onChange?.();
      },
    },
  );

  return (
    <>
      {tip && (
        <Modal
          width={600}
          modalVisible={isVisible}
          onClose={() => {
            reset();
            onClose();
          }}
          styles={{
            padding: `${theme.spacing.small} ${theme.spacing.large}`,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.small} ${theme.spacing.large}`,
            },
          }}
        >
          <Form
            onSubmit={(values) => {
              mutate(values);
            }}
            initialValues={{ ...tip, rate: tip?.rate ?? 0 }}
            validationSchema={validationSchema}
            className="text-left"
          >
            {({ values, submitCount, errors, setFieldValue }) => {
              const hasErrors = Object.keys(errors).length > 0;

              return (
                <>
                  {/* MODAL LOADING OVERLAY */}
                  {isLoading && (
                    <LoadingOverlay
                      backgroundLoadingContainerStyles={{
                        borderRadius: theme.spacing.small,
                      }}
                    />
                  )}
                  {/* HEADING */}
                  <Box className="mt-5 mb-2">
                    <Heading color="brand" level={4} className="mb-2">
                      {translate("tips.headings.edit")}
                    </Heading>
                  </Box>

                  {/* DIVIDER */}
                  <Box className="border-t border-gray-300 my-1 flex flex-col h-1" />

                  <Box styles={{ maxHeight: "600px", overflowY: "scroll" }}>
                    <Box className="flex gap-x-2">
                      {/* NAME */}
                      <Field
                        styles={{ flex: 1 }}
                        name="name"
                        label={translate("tips.form.labels.name")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "tips.form.placeholders.name",
                            )}
                          />
                        )}
                      </Field>
                      <Box className="w-1/4 flex justify-center">
                        {/* ACTIVE */}
                        <Field
                          name="active"
                          label={translate("tips.form.labels.active")}
                        >
                          {({ field }) => (
                            <Input.Toggle {...field} checked={values.active} />
                          )}
                        </Field>
                      </Box>
                    </Box>
                    {/* SITE CODE */}
                    <Field
                      styles={{
                        flex: 1,
                      }}
                      name="siteCode"
                      label={translate("tips.form.labels.siteCode")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={translate(
                            "tips.form.placeholders.siteCode",
                          )}
                        />
                      )}
                    </Field>
                    {/* COMPANY CODE */}
                    <Field
                      styles={{
                        flex: 1,
                        marginTop: theme.spacing.xsmall,
                        marginBottom: theme.spacing.small,
                      }}
                      name="companyCode"
                      label={translate("tips.form.labels.companyCode")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "tips.form.placeholders.companyCode",
                          )}
                        />
                      )}
                    </Field>
                    {/* GROUP */}
                    <Field
                      styles={{
                        flex: 1,
                        marginTop: theme.spacing.xsmall,
                        marginBottom: theme.spacing.small,
                      }}
                      name="group"
                      label={translate("tips.form.labels.group")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          options={groupOptions}
                          placeholder={translate(
                            "tips.form.placeholders.group",
                          )}
                        />
                      )}
                    </Field>
                    <Box className="flex gap-x-2">
                      {/* PERMIT NUMBER */}
                      <Field
                        styles={{ flex: 1 }}
                        name="permitNumber"
                        label={translate("tips.form.labels.permitNumber")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "tips.form.placeholders.permitNumber",
                            )}
                          />
                        )}
                      </Field>
                      {/* WASTE MANAGEMENT LICENSE */}
                      <Field
                        styles={{ flex: 1 }}
                        name="wasteManagementLicense"
                        label={translate(
                          "tips.form.labels.wasteManagementLicense",
                        )}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "tips.form.placeholders.wasteManagementLicense",
                            )}
                          />
                        )}
                      </Field>
                    </Box>
                    {/* LANDFILL DRIVERSION RATE */}
                    <Field
                      styles={{ flex: 1, marginBottom: theme.spacing.medium }}
                      name="landfillDiversionRate"
                      label={translate(
                        "tips.form.labels.landfillDiversionRate",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "tips.form.placeholders.landfillDiversionRate",
                          )}
                        />
                      )}
                    </Field>
                    {/* RATE */}
                    <Field
                      styles={{ flex: 1, marginBottom: theme.spacing.medium }}
                      name="rate"
                      label={translate("tips.form.labels.rate")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("tips.form.placeholders.rate")}
                          type="number"
                          onBlur={(e) => {
                            e.preventDefault();

                            const value = Number(values?.rate ?? 0);

                            setFieldValue(
                              "rate",
                              (
                                Math.round(
                                  (value < 0 ? value * -1 : value) * 100,
                                ) / 100
                              ).toFixed(2),
                            );
                          }}
                        />
                      )}
                    </Field>
                    {/* ADDRESS */}
                    <Field
                      styles={{ flex: 1 }}
                      name="address"
                      label={translate("tips.form.labels.address")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "tips.form.placeholders.address",
                          )}
                        />
                      )}
                    </Field>
                    <Box className="flex gap-x-2">
                      {/* POSTCODE */}
                      <Field
                        styles={{ flex: 1 }}
                        name="postcode"
                        label={translate("tips.form.labels.postcode")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "tips.form.placeholders.postcode",
                            )}
                          />
                        )}
                      </Field>
                      {/* CITY */}
                      <Field
                        styles={{ flex: 1 }}
                        name="city"
                        label={translate("tips.form.labels.city")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "tips.form.placeholders.city",
                            )}
                          />
                        )}
                      </Field>
                    </Box>
                    {/* NOTES */}
                    <Field
                      styles={{ flex: 1 }}
                      name="notes"
                      label={translate("tips.form.labels.notes")}
                    >
                      {({ field }) => (
                        <Input.Textarea
                          {...field}
                          autoGrow={false}
                          styles={{ height: 80 }}
                          placeholder={translate(
                            "tips.form.placeholders.notes",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                  {/* DISPLAY FIELDS ERROR */}
                  {submitCount > 0 && hasErrors && (
                    <Box className="flex gap-8 mt-2">
                      <Message type="error" className="text-left">
                        {translate("tips.errors.fieldMissing")}
                      </Message>
                    </Box>
                  )}
                  {/* SUBMIT BUTTON */}
                  <Box className="flex justify-center mt-2">
                    <Button
                      type="submit"
                      color="warning"
                      disabled={isLoading}
                      size="small"
                    >
                      {translate("tips.buttons.update")}
                    </Button>
                  </Box>
                  {/* DISPLAY ERROR */}
                  {!!error && (
                    <Box className="flex justify-center">
                      <Box
                        className="px-2 py-px rounded-md mt-3"
                        backgroundColor="negative"
                        color="light"
                      >
                        {getErrorMessage(error)}
                      </Box>
                    </Box>
                  )}
                  {/* DISPLAY SUCCESS */}
                  {isSuccess && (
                    <Box className="flex justify-center mt-3">
                      <Box
                        color="light"
                        backgroundColor="positive"
                        className="px-2 py-px rounded-md"
                      >
                        {translate("tips.success.patch")}
                      </Box>
                    </Box>
                  )}
                </>
              );
            }}
          </Form>
        </Modal>
      )}
    </>
  );
};
