import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import Select from "react-select";
import { instance } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Message,
  Panel,
  displayErrorMessage,
  Text,
  theme,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import { initialValues, SelectOptions, validationSchema } from "./validation";

export const DriverAllocation = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [error, setError] = useState("");
  const [dynamicInitialValues, setDynamicInitialValues] =
    useState(initialValues);

  /**
   * fetch all vehicles
   */
  const {
    data: allVehiclesData,
    isLoading: isLoadingAllVehicles,
    isRefetching: isRefetchingAllVehicles,
    refetch: refetchAllVehicles,
  } = useQuery(
    "getAllVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            limit: 1000,
          },
        })
      ).data.items,
    {
      onError: () => setError("Error when fetching Vehicles Data"),
    },
  );

  const findInitialDrivers = (vehicleReg: string) => {
    return allDriversData?.filter((driver) => {
      const foundVehicleReg = driver.attributes?.find(
        (attr) => attr.attrKey === "asset",
      )?.attrValue;

      if (foundVehicleReg === vehicleReg) return true;

      return false;
    });
  };

  /**
   * fetch all users with filter of role name :Clearabee Driver
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isRefetching: isRefetchingAllDrivers,
    refetch: refetchAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver",
            limit: 1000,
          },
        })
      ).data.items,
    {
      onError: () => setError("Error when fetching Drivers Data"),
    },
  );

  const {
    mutate: mutateAllocateDrivers,
    isLoading: isLoadingAllocateDrivers,
    reset: resetAllocateDrivers,
    isSuccess: isSuccessAllocateDrivers,
  } = useMutation(
    "allocateDrivers",
    async (values: typeof initialValues) => {
      await instance.users.putVehicleDrivers({
        registration: values.vehicleReg,
        drivers: values.driverEmails,
      });
    },
    {
      onError: () => setError("Error when updating vehicle's drivers"),
      onSuccess: () => {
        refetchAllVehicles();
      },
    },
  );

  /**
   * All drivers select options
   */
  const vehicleSelectOptions = !!allVehiclesData?.length
    ? allVehiclesData?.map(({ registration }) => ({
        label: registration,
        value: registration,
      }))
    : [];

  /**
   * All drivers select options
   */
  const driverSelectOptions = !!allDriversData?.length
    ? allDriversData?.map(({ firstName, lastName, email }) => ({
        label: `${firstName} ${lastName} (${email})`,
        value: email,
      }))
    : [];

  const isLoading =
    isLoadingAllVehicles ||
    isRefetchingAllVehicles ||
    isLoadingAllDrivers ||
    isRefetchingAllDrivers ||
    isLoadingAllocateDrivers;

  return (
    <Box className="max-w-screen-lg mx-auto py-10 relative">
      <Form
        initialValues={dynamicInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => mutateAllocateDrivers(values)}
        enableReinitialize
      >
        {({ resetForm, setFieldValue, values }) => {
          useEffect(() => {
            setDynamicInitialValues({
              vehicleReg: values.vehicleReg,
              driverEmails:
                findInitialDrivers(values.vehicleReg)?.map(
                  ({ email }) => email,
                ) || [],
            });
          }, [values.vehicleReg]);

          return (
            <>
              {/* LOADING */}
              {isLoading && <LoadingOverlay />}
              {/* VEHICLES PANEL */}
              <Panel
                className="flex flex-col gap-x-6 justify-between"
                shadow={false}
              >
                <Box className="flex justify-between">
                  <Heading fontSize="large" color="brand">
                    {translate("allocate.headings.driverAllocation")}
                  </Heading>
                  <Box className="flex flex-row gap-x-3 items-center">
                    <Button
                      size="small"
                      color="negative"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        setError("");
                        refetchAllVehicles();
                        refetchAllDrivers();
                        resetAllocateDrivers();
                      }}
                    >
                      {translate("allocate.buttons.reset")}
                    </Button>
                    <Button size="small" type="submit">
                      {translate("allocate.buttons.update")}
                    </Button>
                  </Box>
                </Box>
                <Box className="w-full gap-x-3 border-t border-grey-200 mt-4">
                  <Box className="w-full">
                    <Field
                      name="vehicleReg"
                      className="flex-1 min-w-full"
                      label={translate("allocate.form.headings.vehicle")}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isMulti={false}
                          isDisabled={
                            isLoadingAllVehicles || isRefetchingAllDrivers
                          }
                          isClearable
                          isSearchable
                          placeholder={translate(
                            isLoadingAllVehicles || isRefetchingAllDrivers
                              ? "allocate.isLoading"
                              : "allocate.form.placeholders.vehicle",
                          )}
                          options={vehicleSelectOptions}
                          value={
                            vehicleSelectOptions.find(
                              (option) => option.value === values.vehicleReg,
                            ) ?? null
                          }
                          onChange={(option: SelectOptions) => {
                            resetAllocateDrivers();

                            if (!option) {
                              setFieldValue("vehicleReg", "");
                              setFieldValue("driverEmails", []);
                              return;
                            }

                            setFieldValue("vehicleReg", option.value);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              ...theme.fontDefaults.small,
                              minHeight: theme.spacing.xlarge,
                              maxHeight: theme.spacing.xlarge2,
                              alignItems: "center",
                              overflow: "scroll",
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                {/* Display Errors if no vehicles data/no drivers data/ error mutation allocate */}
                {error && (
                  <Box className="w-full mt-5">
                    {displayErrorMessage(
                      translate("allocate.errors.driversAllocation", {
                        error,
                      }),
                      ({ children }) => (
                        <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                          <Message type="error" background>
                            {children}
                          </Message>
                        </Box>
                      ),
                    )}
                  </Box>
                )}
                {/* Display success message when successfully allocate vehicle to drivers*/}
                {isSuccessAllocateDrivers && (
                  <Box className="w-full mt-2">
                    <Box className="flex items-center justify-start w-full mb-2 overflow-scroll">
                      <Message type="success" background color="light">
                        {translate(
                          `allocate.success.${
                            !values.driverEmails.length
                              ? "successUnallocatedDrivers"
                              : "successAllocatedDrivers"
                          }`,
                        )}
                      </Message>
                    </Box>
                  </Box>
                )}
              </Panel>
              {/* DRIVERS PANEL */}
              {!!values.vehicleReg && (
                <>
                  <Box className="mt-8 flex flex-col gap-y-2">
                    <Box className="pl-2">
                      <Text fontSize="base" className="font-semibold">
                        {translate("allocate.headings.allocatedDrivers")}
                      </Text>
                    </Box>
                    <Panel
                      className="flex flex-row gap-x-6 justify-between items-center"
                      styles={{
                        paddingTop: 0,
                        paddingBottom: theme.spacing.xsmall,
                        marginBottom: theme.spacing.medium,
                      }}
                      shadow={false}
                    >
                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="driverEmails"
                        label={translate("allocate.form.headings.driver")}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            isMulti={true}
                            isClearable
                            isSearchable
                            isDisabled={
                              isLoadingAllDrivers || isRefetchingAllDrivers
                            }
                            placeholder={translate(
                              isLoadingAllDrivers || isRefetchingAllDrivers
                                ? "allocate.isLoading"
                                : "allocate.form.placeholders.driver",
                            )}
                            options={driverSelectOptions.filter(
                              (option) =>
                                !values.driverEmails.includes(option.value),
                            )}
                            value={
                              driverSelectOptions.filter((option) =>
                                values.driverEmails.includes(option.value),
                              ) ?? null
                            }
                            onChange={(options: SelectOptions[]) => {
                              resetAllocateDrivers();

                              if (!options) {
                                return setFieldValue("driverEmails", []);
                              }

                              setFieldValue(
                                "driverEmails",
                                options.map(({ value }) => value),
                              );
                            }}
                          />
                        )}
                      </Field>
                    </Panel>
                  </Box>
                </>
              )}
            </>
          );
        }}
      </Form>
    </Box>
  );
};
