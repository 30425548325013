import React from "react";
import { Icon } from "../../..";
import { styles } from "./styles";

const links = [
  {
    icon: <Icon.Facebook width={54} height={54} />,
    link: "https://en-gb.facebook.com/Clearabee/",
  },
  {
    icon: <Icon.Linkedin width={54} height={54} />,
    link: "https://www.linkedin.com/company/clearabee/",
  },
  {
    icon: <Icon.Instagram width={54} height={54} />,
    link: "https://www.instagram.com/clearabee/",
  },
  {
    icon: <Icon.X />,
    link: "https://www.x.com/clearabee/",
  },
];

export const SocialLinks = (): React.ReactElement => {
  return (
    <div>
      <ul style={styles.socialLinksContainer}>
        {links.map((item) => (
          <li style={{ paddingRight: "1rem" }} key={item.link}>
            <a
              css={styles.socialLink}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
