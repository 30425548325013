import React, { useState } from "react";
import * as Yup from "yup";
import { OptionTypeBase } from "react-select";
import { useQuery, useMutation } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { instance } from "@clearabee/ui-sdk";
import { IVehicleSchedulePostBody } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Text,
  theme,
  Button,
  Form,
  Field,
  Input,
  Panel,
  Modal,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import {
  FlattenedScheduleDate,
  flattenScheduleDates,
  prepareCloneSchedule,
  transformInput,
} from "../helpers/helpers";
import { LoadingOverlay } from "components/common/components";
import { stringRequired } from "validation/common";

dayjs.extend(utc);

interface CloneScheduleModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  driverOptions: OptionTypeBase[];
  unSavedChanges: boolean;
  setUnsavedChanges: (booleanValue: boolean) => void;
}

const validationSchema = Yup.object().shape(
  {
    cloneFromDate: Yup.string().when("isCloneFromMaster", {
      is: (value) => Boolean(value) === false,
      then: stringRequired,
      otherwise: Yup.string(),
    }),
    isCloneFromMaster: Yup.boolean(),
    cloneToDate: stringRequired,
  },
  [["isCloneFromMaster", "cloneFromDate"]],
);

const initialValues = {
  cloneFromDate: "",
  cloneToDate: "",
  isCloneFromMaster: true,
};

export const CloneScheduleModal = ({
  driverOptions,
  unSavedChanges,
  setUnsavedChanges,
  showModal,
  setShowModal,
}: CloneScheduleModalProps): React.ReactElement => {
  const [cloneFromDate, setCloneFromDate] = useState("");

  const { data: allVehicles, isLoading: isLoadingAllVehicles } = useQuery(
    "getVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            "company_code:emptyOrNull": "true",
            limit: 1000,
          },
        })
      ).data.items,
    {
      retry: false,
    },
  );

  const { data: schedule, isLoading: getScheduleIsLoading } = useQuery(
    ["getSchedule", cloneFromDate],
    async () => {
      if (!cloneFromDate) return;

      return (
        await instance.vehicles.getVehicleSchedules(
          cloneFromDate || "",
          cloneFromDate || "",
          { params: { limit: 10000 } },
        )
      ).data.items;
    },
    {
      cacheTime: 0,
      retry: false,
      enabled: !!cloneFromDate,
    },
  );

  const { mutate: createSchedule, isLoading: createScheduleIsLoading } =
    useMutation(
      ["createAssetDates"],
      async (body: IVehicleSchedulePostBody) => {
        return instance.vehicles.postVehicleSchedule(body);
      },
      {
        retry: false,
        onSuccess: () => {
          toasts.success({
            message: "Schedule cloned successfully.",
          });
          setShowModal(false);
          setUnsavedChanges(false);
        },
        onError: () => {
          toasts.error({
            message: "Failed to clone schedule.",
          });
        },
      },
    );

  const handleSubmit = (values: typeof initialValues) => {
    // CLONE FROM MASTER SHEETS
    if (
      values.isCloneFromMaster &&
      !!driverOptions?.length &&
      !!allVehicles?.length
    ) {
      const defaultFlattenedScheduleDates: FlattenedScheduleDate[] = allVehicles
        .flatMap((vehicle) => {
          return vehicle?.defaultDrivers?.map(
            (driverId: string | null | undefined) => {
              return {
                user:
                  driverOptions.find(
                    ({ value }) => String(value) === String(driverId),
                  )?.label ?? "",
                asset: vehicle.registration,
                date: dayjs.utc(values.cloneToDate, "DD/MM/YYYY").toISOString(),
                time: vehicle?.defaultStartTime || "06:00",
                userId: driverId,
                vehicleId: vehicle.id,
                message: "",
              };
            },
          );
        })
        .filter(Boolean);

      const defaultVehicleIds = allVehicles
        .map((vehicle) => vehicle.id)
        .filter(Boolean) as number[];

      const prepareForClone = prepareCloneSchedule(
        defaultFlattenedScheduleDates,
        dayjs.utc(values.cloneToDate, "DD/MM/YYYY").toISOString(),
      );

      const shapeData = transformInput(
        prepareForClone,
        [dayjs.utc(values.cloneToDate, "DD/MM/YYYY").toISOString()],
        defaultVehicleIds,
      );

      createSchedule(shapeData);
      return;
    }

    // NORMAL CLONE
    if (!schedule?.length) {
      return;
    }

    const flatten = flattenScheduleDates(schedule, []);

    const prepareForClone = prepareCloneSchedule(
      flatten,
      dayjs.utc(values.cloneToDate, "DD/MM/YYYY").toISOString(),
    );

    const shapeData = transformInput(
      prepareForClone,
      [dayjs.utc(values.cloneToDate, "DD/MM/YYYY").toISOString()],
      schedule.map((item) => item.vehicleId),
    );

    createSchedule(shapeData);
  };

  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <>
                {/* MODAL LOADING OVERLAY */}
                {(getScheduleIsLoading || createScheduleIsLoading) && (
                  <LoadingOverlay
                    iconSize="xlarge2"
                    backgroundLoadingContainerStyles={{
                      borderRadius: theme.spacing.small,
                    }}
                  />
                )}
                <Box className="flex flex-col gap-y-3">
                  <Heading className="text-center" color="brand" level={4}>
                    Clone Schedule
                  </Heading>
                  <Text className="text-center px-6">
                    This allows you to take the schedule of one date and and
                    clone it to another. Be wary that the date that you
                    &apos;clone to&apos; will have its schedule completely
                    overwritten.
                  </Text>
                  <Box className="flex justify-center my-5">
                    <Field
                      styles={{
                        margin: 0,
                        padding: 0,
                      }}
                      name="isCloneFromMaster"
                    >
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          label="Clone from vehicle preferences"
                          disabled={unSavedChanges}
                          styles={{
                            opacity: unSavedChanges ? 0.5 : 1,
                          }}
                          defaultChecked={field.value}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="flex flex-row justify-center items-center gap-x-10">
                    {/* CLONE FROM */}
                    {!values.isCloneFromMaster && (
                      <Box className="w-1/3">
                        <Field
                          styles={{
                            marginTop: 0,
                          }}
                          name="cloneFromDate"
                          label="Clone from"
                        >
                          {({ field }) => (
                            <Input.Date
                              {...field}
                              collapsable
                              placeholder="Clone from"
                              // will get back to this if we decide to disable past dates
                              // disabledDays={{ before: dayjs.utc().toDate() }}
                              disabled={
                                getScheduleIsLoading || createScheduleIsLoading
                              }
                              onChange={(e) => {
                                const value = e.target.value;

                                setCloneFromDate(
                                  dayjs.utc(value, "DD/MM/YYYY").toISOString(),
                                );
                                setFieldValue("cloneFromDate", value);
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                    )}
                    {/* CLONE TO */}
                    <Box className="w-1/3">
                      <Field
                        styles={{
                          marginTop: 0,
                        }}
                        name="cloneToDate"
                        label="Clone to"
                      >
                        {({ field }) => (
                          <Input.Date
                            {...field}
                            collapsable
                            placeholder="Clone to"
                            // will get back to this if we decide to disable past dates
                            // disabledDays={{ before: dayjs.utc().toDate() }}
                            disabled={
                              getScheduleIsLoading || createScheduleIsLoading
                            }
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>
                  {/* PREVIEW SCHEDULE */}
                  {!values.isCloneFromMaster && schedule && (
                    <>
                      <Text color="brand" className="font-bold">
                        Schedule Preview
                      </Text>
                      <Panel
                        style={{
                          border: `1px solid ${theme.colors.brand.light}`,
                          padding: theme.spacing.small,
                          maxHeight: "250px",
                          overflowY: "scroll",
                          textAlign: "left",
                        }}
                        shadow={false}
                      >
                        {schedule.map((vehicle, index) => (
                          <Box
                            key={`clone modal - ${vehicle.name} - ${index}`}
                            className="mb-1"
                          >
                            <Box className="flex items-center gap-x-2">
                              <Text className="font-bold">
                                {`${vehicle.name || vehicle.asset}:`}
                              </Text>
                              {!vehicle.schedule.length && (
                                <Text className="font-normal" as="span">
                                  No schedule
                                </Text>
                              )}
                            </Box>
                            {vehicle.schedule.map((schedule, index) => (
                              <Box
                                key={`clone modal - ${schedule.date} - ${schedule.time} - ${schedule.userId} - ${vehicle.asset} -  ${index}`}
                                className="flex flex-col pl-6 mb-3"
                              >
                                <Text className="font-semibold">
                                  {driverOptions.find(
                                    ({ value }) =>
                                      value === String(schedule.userId),
                                  )?.label || ""}
                                </Text>
                                <Text>
                                  {dayjs
                                    .utc(schedule.date)
                                    .format("DD/MM/YYYY")}
                                </Text>
                                <Text key={schedule.time}>{schedule.time}</Text>
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Panel>
                    </>
                  )}
                  {/* SUBMIT */}
                  <Box className="mx-auto mt-5">
                    <Button
                      type="submit"
                      size="small"
                      disabled={!values.cloneToDate || isLoadingAllVehicles}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
