import axios from "axios";

import {
  TFilters,
  ICompany,
  TCreateCompany,
  TUpdateCompany,
  ITabledResults,
  IPaginatedResults,
} from "./types";
import {
  defaultAPILimit,
  generateQueryParams,
  hasMoreResults,
  successResponse,
} from "./generic";

export const endpoints = {
  GET_COMPANIES: "/companies",
};

export const readCompaniesForTables = async (
  filters: TFilters = "",
  currentPage = 0,
  itemsPerPage = defaultAPILimit,
): Promise<ITabledResults<ICompany>> => {
  try {
    const data = await readCompanies(filters, currentPage, itemsPerPage);

    const { items, pagination } = data;

    return {
      items: items.map((item: ICompany) => {
        const { id } = item;

        return {
          ...item,
          singleLink: `/companies/update/${id}`,
        };
      }),
      pagination: {
        ...pagination,
        hasMoreResults: hasMoreResults(
          pagination.total,
          currentPage,
          itemsPerPage,
        ),
      },
    };
  } catch (error) {
    throw error;
  }
};

export const readCompanies = async (
  filters: TFilters = "",
  currentPage = 0,
  itemsPerPage = defaultAPILimit,
): Promise<IPaginatedResults<ICompany>> => {
  try {
    const queryParams = generateQueryParams(filters, currentPage, itemsPerPage);

    const { data } = await axios.get(`/companies${queryParams}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const userCompanies = async (): Promise<ICompany[]> => {
  try {
    const { data } = await axios.get("/companies", { params: { limit: 1500 } });
    const { items } = data;

    return items;
  } catch (error) {
    throw error;
  }
};

export const readCompany = async (id: number): Promise<ICompany> => {
  try {
    const { data } = await axios.get(`/companies/${id}`, {
      params: {
        includeRelated: "true",
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const createCompany: TCreateCompany = async (data: any) => {
  try {
    await axios.post("/companies", {
      ...data,
    });

    return successResponse();
  } catch (error) {
    throw error;
  }
};

export const updateCompany: TUpdateCompany = async (id, data) => {
  try {
    await axios.patch(`/companies/${id}`, {
      ...data,
    });

    return successResponse();
  } catch (error) {
    throw error;
  }
};
