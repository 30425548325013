"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.garages = void 0;
exports.garages = {
    headings: {
        search: "Search Garages",
        add: "Add Garage",
        edit: "Edit Garage",
        confirmDelete: "Are you sure you want to delete this garage?",
    },
    form: {
        labels: {
            name: "Name",
            garage: "Garage",
            address: "Address",
            city: "City",
            postcode: "Postcode",
            group: "Group",
            companyCode: "Company Code",
            notes: "Notes",
            lat: "Latitude",
            lng: "Longitude",
            siteCode: "Site Code",
            contactName: "Contact Name",
            contactEmail: "Contact Email",
            contactPhone: "Contact Phone",
        },
        placeholders: {
            name: "Enter name",
            address: "Enter address",
            city: "Enter city",
            postcode: "Enter postcode",
            group: "Select a group",
            companyCode: "Enter company code",
            notes: "Enter notes",
            lat: "Enter latitude",
            lng: "Enter longitude",
            siteCode: "Enter site code",
            contactName: "Enter contact name",
            contactEmail: "Enter contact email",
            contactPhone: "Enter contact phone",
        },
    },
    success: {
        patch: "Garage has been updated",
        post: "Garage has been created",
        delete: "Garage has been deleted",
    },
    errors: {
        noTips: "No garages found",
        error: "Something went wrong. Please try again.",
        noData: "No data available",
        fieldMissing: "Some of the required fields are missing",
    },
    buttons: {
        search: "Search",
        reset: "Reset",
        update: "Update",
        delete: "Delete",
        remove: "Remove",
        cancel: "Cancel",
        edit: "Edit",
        submit: "Submit",
        add: "Add",
    },
    table: {
        name: "Name",
        siteCode: "Site Code",
        companyCode: "Company Code",
        postcode: "Postcode",
        address: "Address",
        group: "Group",
        city: "City",
        actions: "Actions",
    },
};
