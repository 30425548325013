import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormikProps } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import clipboardCopy from "clipboard-copy";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { ICoupon } from "@clearabee/api-schemas";
import { IPaginatedResults, TFilters } from "api/types";
import { toasts } from "helpers";
import { buildQuery } from "helpers/api";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import {
  Heading,
  Button,
  Box,
  Modal,
  Panel,
  Form,
  Input,
  Field,
  Text,
  UnstyledButton,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { formatCurrency } from "@clearabee/ui-library";
import { CreateModal, EditModal } from "./components";
import {
  searchValidationSchema,
  searchInitialValues,
  SearchFormValues,
} from "./validation";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { styles } from "./readCoupons.styles";

dayjs.extend(utc);

const initialResultsPerPage = 10;

export const ReadCoupons = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("coupons");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<ICoupon>();
  const params = new URLSearchParams(location.search.replace("?", ""));
  const searchFormRef = useRef<FormikProps<SearchFormValues>>(null);
  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  const readJobs = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ) => {
    const filterParams = new URLSearchParams(filters);

    //filters
    const name = filterParams.get("name:like");
    const code = filterParams.get("code:like");

    // TODO: ADD INSTANCE ONCE IT IS AVAILABLE
    const newData = await instance.catalogues.getCoupons({
      params: {
        ...(!!name && { "name:like": `${name}%` }),
        ...(!!code && { "code:like": `${code}%` }),
        limit,
        offset: currentPage * limit,
      },
    });

    return newData.data as unknown as IPaginatedResults<ICoupon>;
  };

  const {
    PaginationComponent,
    paginatedData,
    currentPage,
    setCurrentPage,
    updateFilters,
    resultsPerPage,
    setResultsPerPage,
    query: { isFetching, isLoading, isSuccess, refetch },
  } = usePaginatedQuery(
    readJobs,
    "readVehiclesPaginated",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    paginationStyles,
  );

  const handleSubmit = (values: typeof searchInitialValues) => {
    const { name, code } = values;
    const updatedFilterObject = {
      ...(!!name ? { "name:like": name } : {}),
      ...(!!code ? { "code:like": code } : {}),
      orderByDesc: "date",
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);

    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/coupons?${queryParam}`);
  };

  const getInitialValues = (): typeof searchInitialValues => {
    const name = params?.get("name:like") ?? "";
    const code = params?.get("code:like") ?? "";

    return {
      name,
      code,
    };
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* LOADING */}
      {(isLoading || isFetching) && <LoadingOverlay />}

      <Panel styles={styles.pannel} className="mb-3" shadow={false}>
        <Form
          initialValues={getInitialValues()}
          validationSchema={searchValidationSchema}
          enableReinitialize
          onSubmit={(values) => handleSubmit(values)}
          innerRef={searchFormRef}
        >
          {({ resetForm }) => (
            <>
              <Box className="flex flex-row justify-between items-center border-b border-grey-20 pb-6 my-3">
                <Heading level={2} fontSize="xlarge" color="brand">
                  {t("heading")}
                </Heading>

                <Box>
                  <Button
                    type="button"
                    size="small"
                    color="brand"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                  >
                    {t("buttons.add")}
                  </Button>
                </Box>
              </Box>

              <Box className="flex flex-row justify-start items-center w-full gap-x-3">
                <Box className="w-1/3">
                  <Field label={t("form.name")} name="name">
                    {({ field }) => (
                      <Input.Text {...field} placeholder={t("form.name")} />
                    )}
                  </Field>
                </Box>
                <Box className="w-1/3">
                  <Field label="Code" name="code">
                    {({ field }) => (
                      <Input.Text {...field} placeholder="Code" />
                    )}
                  </Field>
                </Box>

                <Box className="flex flex-row ml-auto gap-x-3 pt-4">
                  <Box>
                    <Button
                      type="reset"
                      size="small"
                      color="negative"
                      onClick={() => {
                        resetForm();
                        updateFilters("");
                        setCurrentPage(1);
                        setResultsPerPage(initialResultsPerPage);
                        history.push(
                          `/coupons?page=1&resultsPerPage=${initialResultsPerPage}`,
                        );
                        searchFormRef.current?.resetForm();
                      }}
                    >
                      {t("buttons.reset")}
                    </Button>
                  </Box>

                  <Box>
                    <Button size="small" color="accent" type="submit">
                      {t("buttons.search")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Panel>

      {/* RESULTS TABLE */}
      {!!paginatedData && paginatedData?.length > 0 && (
        <>
          <Box
            backgroundColor="brand.darkest"
            color="light"
            className="grid w-full px-5 mt-6 mb-3 grid-cols-8 py-4 rounded-md text-center gap-2"
          >
            <Text fontSize="small" className="font-semibold">
              {t("table.name")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.code")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.active")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.expiryDate")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.percentOff")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.amountOff")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              {t("table.minimumAmount")}
            </Text>
            <Text className="font-semibold" fontSize="small">
              Actions
            </Text>
          </Box>
          <Box className="flex flex-col gap-3 min-w-full">
            {paginatedData?.map((coupon, index) => {
              const {
                active,
                amountOff,
                code,
                expiryDate,

                minimumAmount,
                name,
                percentOff,
              } = coupon;

              return (
                <Box
                  backgroundColor="light"
                  className="grid grid-cols-8 justify-center w-full rounded-md p-5 items-center"
                  key={`table-row-${index}`}
                >
                  <Box className="flex items-center gap-1 flex-col">
                    <Text>{name}</Text>
                  </Box>

                  <Box className="flex items-center gap-1 flex-col">
                    <UnstyledButton
                      onClick={async () => {
                        await clipboardCopy(code);
                        toasts.info({
                          message: "Copied to clipboard",
                        });
                      }}
                    >
                      <Box
                        className="px-3 py-1 font-semibold rounded-md"
                        backgroundColor="greyscale.lightest"
                      >
                        <Text>{code}</Text>
                      </Box>
                    </UnstyledButton>
                  </Box>

                  <Box
                    color={active ? "positive.dark" : "negative"}
                    className="flex flex-col items-center"
                  >
                    <Text className="font-bold">{active ? "Yes" : "No"}</Text>
                  </Box>

                  <Box className="flex items-center gap-1 flex-col">
                    {expiryDate ? (
                      <Text>{dayjs.utc(expiryDate).format("DD/MM/YYYY")}</Text>
                    ) : (
                      <Text>{t("editModal.none")}</Text>
                    )}
                  </Box>

                  <Box className="flex items-center gap-1 flex-col">
                    <Text>{!!percentOff ? `${percentOff}%` : "N/A"}</Text>
                  </Box>

                  <Box className="flex items-center gap-1 flex-col">
                    <Text>{formatCurrency(amountOff || 0)}</Text>
                  </Box>

                  <Box className="flex items-center gap-1 flex-col">
                    <Text>{formatCurrency(minimumAmount || 0)}</Text>
                  </Box>

                  <Box className="flex justify-center">
                    <Button
                      onClick={() => {
                        setSelectedCoupon(coupon);
                      }}
                      size="small"
                    >
                      {t("buttons.view")}
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box className="flex justify-center items-center pt-4">
            <PaginationComponent />
          </Box>
        </>
      )}

      {/* NO RESULTS */}
      {!isLoading && !isFetching && !paginatedData?.length && isSuccess && (
        <Box className="flex justify-center my-12">
          <Heading color="brand" level={3}>
            {t("noneFound")}
          </Heading>
        </Box>
      )}
      {/* CREATE MODAL */}
      <CreateModal
        isVisibile={showCreateModal}
        refetch={refetch}
        setVisibility={(value: boolean) => {
          setShowCreateModal(value);
        }}
      />

      {/* DETAILS MODAL */}
      <EditModal
        onClose={() => setSelectedCoupon(undefined)}
        selectedCoupon={selectedCoupon}
        refetchCoupons={refetch}
        setVisibility={(value: undefined) => {
          setSelectedCoupon(value);
        }}
      />
    </>
  );
};
