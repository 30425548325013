"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statistics = void 0;
exports.statistics = {
    total: "Total: ",
    date: "Date: ",
    jobRef: "Job Ref: ",
    downloadCSV: "Download CSV",
    screenshot: "Screenshot",
    graph: {
        totalJobsCompleted: "Total Jobs Completed",
        totalJobsFailed: "Total Jobs Failed",
    },
    headings: {
        statistics: "Statistics",
        overview: "Overview",
        graphs: "Graphs",
        formHeading: "Clearabee Collection Report",
        headlineStats: "Headline Stats",
        itemsCollected: "Items Collected",
        companyName: "Company Name",
        companies: "Companies",
        jobs: "Jobs",
        collections: "Collections",
        orders: "Orders",
        allJobs: "All Jobs by Location",
        jobsByMonth: "Jobs by Month",
        invoiceAmountByMonth: "Invoice Amount by Month",
    },
    defaultTimeRange: {
        last7Days: "Last 7 days",
        last30Days: "Last 30 days",
        currentMonth: "Current month",
        lastMonth: "Last month",
        last3Months: "Last 3 months",
        yearToDate: "Year to Date",
    },
    buttons: {
        submit: "Submit",
        collapseAll: "Collapse All",
        expandAll: "Expand All",
    },
    errors: {
        downloadReport: "Unable to download data report. Please try again.",
        fetchingData: "We were unable to process your request. Please try to narrow down the search criteria.",
        apiTimeout: "API timed out while getting the data", // this will display in the browser console
    },
};
