import { theme, StyleSheet } from "@clearabee/ui-library";

export const styles = StyleSheet({
  pannel: {
    padding: `${theme.spacing.small} ${theme.spacing.xlarge}`,
  },
  jobsLabel: {
    ...theme.fontDefaults.small,
    paddingBottom: theme.spacing.xsmall2,
    fontWeight: 600,
  },
  jobsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: theme.spacing.xsmall,
    borderRadius: theme.spacing.xsmall2,
    padding: theme.spacing.xsmall,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "450px",
    margin: "0 auto",
    padding: theme.spacing.small,
  },
  emailContainer: {
    whiteSpace: "nowrap",
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
