import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IJob } from "@clearabee/api-schemas";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Message,
  Modal,
  Panel,
  Text,
  theme,
  UnstyledButton,
} from "@clearabee/ui-library";
import { postcodeRegExp } from "validation/common";
import { initialValues, validationSchema } from "./validation";
import { LoadingOverlay } from "components/common/components";
import { readAddresses } from "api/libs";
import { useDebounce } from "hooks";
import { getDistance } from "helpers";

dayjs.extend(utc);

const constantErrorTypes = {
  orderTypes: "fetch Order Types data",
  vehicles: "fetch Vehicles data",
  tips: "fetch Tips data",
  garages: "fetch Garages data",
  address: "fetch Address data",
  createTask: "create job task",
};

export const Task = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [errorType, setErrorType] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isTipOrderTypeSelected, setIsTipOrderTypeSelected] = useState(false);
  const [isMaintenanceOrderTypeSelected, setIsMaintenanceOrderTypeSelected] =
    useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [originPostcode, setOriginPostcode] = useState("");
  const [isLoadingOriginLatLng, setIsLoadingOriginLatLng] = useState(false);

  const debouncedPostcodeValue = useDebounce(originPostcode, 1000);

  /*
   * Fetch latitude and longitude by originPostcode
   */
  const { data: originLatLngData } = useQuery(
    ["getOriginPostcode", debouncedPostcodeValue],
    async () => {
      if (!originPostcode.match(postcodeRegExp) || originPostcode.length < 4)
        return;

      const { latitude, longitude } = await readAddresses(originPostcode);

      return {
        latitude,
        longitude,
      };
    },
    {
      enabled: !!debouncedPostcodeValue,
      onSettled: () => setIsLoadingOriginLatLng(false),
      retry: false,
    },
  );

  /**
   * Fetch order types
   */
  const {
    data: orderTypes,
    isLoading: isLoadingOrderTypeOptions,
    isRefetching: isRefetchingOrderTypes,
    refetch: refetchOrderTypes,
  } = useQuery(
    "getOrderTypes",
    async () =>
      (
        await instance.catalogues.getOrderTypes({
          params: {
            limit: 1500,
          },
        })
      ).data.items,
    {
      onError: () => setErrorType(constantErrorTypes.orderTypes),
      retry: false,
    },
  );

  /**
   * Order type options
   */
  const orderTypeOptions = useMemo(() => {
    if (!orderTypes?.length) return [];

    return orderTypes
      .filter(({ name }) =>
        ["maintenance", "tip", "quick job"].includes(name.toLowerCase()),
      )
      .map(({ name }) => {
        // endpoint require specific value for quick job as "Quick job"
        if (name.toLowerCase() === "quick job") {
          return { label: name, value: "Quick job" };
        }
        return { label: name, value: name };
      });
  }, [orderTypes]);

  /**
   * Fetch vehicle options
   */
  const {
    data: vehicles,
    isLoading: isLoadingVehicleOptions,
    isRefetching: isRefetchingVehicles,
    refetch: refetchVehicles,
  } = useQuery(
    "getVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            // only get approved vehicles
            "status:eq": "approved",
            limit: 1500,
          },
        })
      ).data.items,
    {
      onError: () => setErrorType(constantErrorTypes.vehicles),
      retry: false,
    },
  );

  /**
   * Vehicle options
   */
  const vehicleOptions = useMemo(() => {
    if (!vehicles) return [];

    return vehicles.map(({ name, registration }) => ({
      label: name || registration,
      value: registration,
    }));
  }, [vehicles]);

  /**
   * Fetch tip options
   */
  const {
    data: tips,
    isLoading: isLoadingTipOptions,
    isRefetching: isRefetchingTips,
    refetch: refetchTips,
  } = useQuery(
    ["getTips", isTipOrderTypeSelected],
    async () => {
      if (!isTipOrderTypeSelected) return [];

      return (
        await instance.jobs.getTips({
          params: {
            // only get approved tips
            "active:eq": "1",
            limit: 1500,
          },
        })
      ).data.items;
    },
    {
      onError: () => setErrorType(constantErrorTypes.tips),
      enabled: isTipOrderTypeSelected,
      retry: false,
    },
  );

  /**
   * Tip options
   */
  const tipOptions = useMemo(() => {
    if (!tips) return [];

    if (!debouncedPostcodeValue || !originLatLngData) {
      return tips.map((tip) => ({
        label: tip.name,
        value: JSON.stringify(tip),
      }));
    }

    const { latitude, longitude } = originLatLngData;

    return tips
      .sort(
        (a, b) =>
          getDistance(
            a?.latitude || 0,
            a?.longitude || 0,
            latitude,
            longitude,
          ) -
          getDistance(b?.latitude || 0, b?.longitude || 0, latitude, longitude),
      )
      .map((tip) => ({
        label: tip.name,
        value: JSON.stringify(tip),
      }));
  }, [tips, debouncedPostcodeValue, originLatLngData]);

  /**
   * Fetch garage options
   */
  const {
    data: garages,
    isLoading: isLoadingGarageOptions,
    isRefetching: isRefetchingGarages,
    refetch: refetchGarages,
  } = useQuery(
    ["getGarages", isMaintenanceOrderTypeSelected],
    async () => {
      if (!isMaintenanceOrderTypeSelected) return [];

      return (
        await instance.jobs.getGarages({
          params: {
            limit: 1500,
          },
        })
      ).data.items;
    },
    {
      onError: () => setErrorType(constantErrorTypes.garages),
      enabled: isMaintenanceOrderTypeSelected,
      retry: false,
    },
  );

  /**
   * Garage options
   */
  const garageOptions = useMemo(() => {
    if (!garages) return [];

    if (!debouncedPostcodeValue || !originLatLngData) {
      return garages.map((garage) => ({
        label: garage.name,
        value: JSON.stringify(garage),
      }));
    }

    const { latitude, longitude } = originLatLngData;

    return garages
      .sort(
        (a, b) =>
          getDistance(
            a?.latitude || 0,
            a?.longitude || 0,
            latitude,
            longitude,
          ) -
          getDistance(b?.latitude || 0, b?.longitude || 0, latitude, longitude),
      )
      .map((garage) => ({
        label: garage.name,
        value: JSON.stringify(garage),
      }));
  }, [garages, debouncedPostcodeValue, originLatLngData]);

  /*
   * Fetch address by postcode
   */
  const {
    isLoading: isAddressLoading,
    data: addressData,
    mutate: mutateGettingAddressByPostcode,
    reset: resetGettingAddressByPostcode,
  } = useMutation(
    "getAddressByPostcode",
    async (postcode: string) => {
      if (!postcode.match(postcodeRegExp) || postcode.length < 4) return [];

      const response = await readAddresses(postcode);

      return response.addresses.map((address) => ({
        label: `${address.line_1}, ${address.line_2}, ${address.county}, ${address.town_or_city}`,
        value: JSON.stringify(address),
      }));
    },
    {
      onError: () => setErrorType(constantErrorTypes.address),
      retry: false,
    },
  );

  /**
   * Create job task
   */
  const {
    data: createTaskResponse,
    mutate: createTask,
    isLoading: isLoadingCreateTask,
    isSuccess: isSuccessCreateTask,
    reset: resetCreateTask,
  } = useMutation(
    "createTask",
    async (values: typeof initialValues): Promise<IJob> => {
      const { address: _, ...rest } = values.deliveryAddress;

      return (
        await instance.jobs.postTask({
          type: values.orderType,
          orderTypeId: Number(
            orderTypes?.find(
              ({ name }) =>
                name.toLowerCase() === values.orderType.toLocaleLowerCase(),
            )?.id,
          ),
          assetCrew: [],
          asset: values.asset,
          date: dayjs.utc(values.date, "DD-MM-YYYY").toISOString(),
          plannedStartTime: dayjs
            .utc(
              `${values.date} ${values.plannedStartTime}`,
              "DD-MM-YYYY HH:mm",
            )
            .toISOString(),
          description: values.description,
          deliveryAddress: rest,
          tipId: !!values.tip ? JSON.parse(values.tip).id : undefined,
          garageId: !!values.garage ? JSON.parse(values.garage).id : undefined,
        })
      ).data;
    },
    {
      onSuccess: () => setIsSuccessModalOpen(true),
      onError: () => setErrorType(constantErrorTypes.createTask),
    },
  );

  return (
    <>
      {/* LOADING */}
      {(isLoadingOrderTypeOptions ||
        isLoadingVehicleOptions ||
        isLoadingCreateTask ||
        isRefetchingOrderTypes ||
        isRefetchingVehicles) && <LoadingOverlay />}

      <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => createTask(values)}
        >
          {({ resetForm, setFieldValue, values }) => {
            useEffect(() => {
              if (isSuccessCreateTask) {
                resetForm();
              }
            }, [isSuccessCreateTask]);

            useEffect(() => {
              if (
                !!values.deliveryAddress.postcode &&
                !isTipOrderTypeSelected &&
                !isMaintenanceOrderTypeSelected
              ) {
                mutateGettingAddressByPostcode(values.deliveryAddress.postcode);
              }
            }, [
              values.deliveryAddress.postcode,
              isTipOrderTypeSelected,
              isMaintenanceOrderTypeSelected,
            ]);

            return (
              <>
                <Panel
                  shadow={false}
                  styles={{
                    width: "100%",
                    padding: `${theme.spacing.large} ${theme.spacing.xlarge}`,
                  }}
                >
                  <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                    <Heading
                      level={5}
                      color="brand"
                      className="pr-4 w-full sm:w-auto"
                    >
                      {translate("task.form.headings.createTask")}
                    </Heading>

                    {/* BUTTONS */}
                    <Box className="flex justify-end self-end col-start-3 gap-x-3">
                      {/* RESET */}
                      <Button
                        size="small"
                        color="negative"
                        type="reset"
                        onClick={() => {
                          resetForm();
                          setErrorType("");
                          setOriginPostcode("");
                          resetCreateTask();
                          resetGettingAddressByPostcode();
                          refetchOrderTypes();
                          refetchVehicles();
                          refetchTips();
                          refetchGarages();
                        }}
                      >
                        {translate("task.buttons.reset")}
                      </Button>
                      {/* SUMBIT */}
                      <Button size="small" color="accent" type="submit">
                        {translate("task.buttons.create")}
                      </Button>
                    </Box>
                  </Box>

                  <Box className="flex flex-col md:flex-row md:gap-x-5">
                    {/* ORDER TYPE*/}
                    <Field
                      styles={{ flex: 1 }}
                      name="orderType"
                      label={translate("task.form.labels.orderType")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isClearable
                          isSearchable
                          disabled={!orderTypeOptions.length}
                          options={orderTypeOptions}
                          placeholder={translate(
                            "task.form.placeholders.orderType",
                          )}
                          onChange={(e) => {
                            const value = e.target.value;

                            const isTipValue = value.toLowerCase() === "tip";
                            const isMaintenanceValue =
                              value.toLowerCase() === "maintenance";

                            if (!isTipValue || !isMaintenanceValue) {
                              if (!isTipValue) setFieldValue("tip", "");
                              if (!isMaintenanceValue)
                                setFieldValue("garage", "");
                              setFieldValue("deliveryAddress.postcode", "");
                              setFieldValue("deliveryAddress.line1", "");
                              setFieldValue("deliveryAddress.city", "");
                              setOriginPostcode("");
                            }

                            setIsTipOrderTypeSelected(isTipValue);
                            setIsMaintenanceOrderTypeSelected(
                              isMaintenanceValue,
                            );

                            setFieldValue("orderType", value);
                          }}
                        />
                      )}
                    </Field>
                    {/* TIP */}
                    {isTipOrderTypeSelected && (
                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="tip"
                        label={translate("task.form.labels.tip")}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            onInputChange={(input) => {
                              if (
                                !!input.match(postcodeRegExp) &&
                                input.length > 4
                              ) {
                                setOriginPostcode(input);
                                setIsLoadingOriginLatLng(true);
                                return;
                              }

                              setOriginPostcode("");
                              setIsLoadingOriginLatLng(false);
                            }}
                            isLoading={isLoadingTipOptions || isRefetchingTips}
                            isDisabled={
                              isLoadingTipOptions ||
                              isRefetchingTips ||
                              !tipOptions.length
                            }
                            placeholder={translate(
                              "task.form.placeholders.tip",
                            )}
                            value={
                              tipOptions.find(
                                (option) => option.value === values.tip,
                              ) || null
                            }
                            options={tipOptions}
                            onMenuClose={() => {
                              setIsHidden(true);
                              setOriginPostcode("");
                            }}
                            onMenuOpen={() => setIsHidden(false)}
                            onChange={(option) => {
                              const value = option?.value;

                              setFieldValue("isManualAddress", !!value);

                              if (!value) {
                                setFieldValue("deliveryAddress.postcode", "");
                                setFieldValue("deliveryAddress.line1", "");
                                setFieldValue("deliveryAddress.line2", "");
                                setFieldValue("deliveryAddress.city", "");
                                setFieldValue("deliveryAddress.address", "");
                                setFieldValue("tip", "");
                                return;
                              }

                              const { address, postcode, city } =
                                JSON.parse(value) || {};

                              setFieldValue(
                                "deliveryAddress.postcode",
                                postcode,
                              );
                              setFieldValue(
                                "deliveryAddress.line1",
                                address || "None",
                              );
                              setFieldValue(
                                "deliveryAddress.city",
                                city || "None",
                              );
                              setFieldValue("tip", value);
                            }}
                            filterOption={(option, rawInput) => {
                              if (!!originPostcode || !!debouncedPostcodeValue)
                                return true;

                              const label = (option.label || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const jsonValue =
                                JSON.parse(String(option.value)) || {};

                              const postcode = (jsonValue.postcode || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const group = (jsonValue.group || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const input = (rawInput || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              return (
                                label.includes(input) ||
                                postcode.includes(input) ||
                                group.includes(input)
                              );
                            }}
                            formatOptionLabel={({ label, value }) => {
                              const {
                                rate,
                                postcode,
                                group,
                                latitude,
                                longitude,
                              } = JSON.parse(String(value)) || {};

                              return (
                                <>
                                  {isLoadingOriginLatLng && (
                                    <Box className="flex justify-center items-center h-24">
                                      <Icon.Loading
                                        color="brand.base"
                                        size="medium"
                                      />
                                    </Box>
                                  )}
                                  {!isLoadingOriginLatLng && (
                                    <Box
                                      className="flex flex-col gap-y-1 py-1 text-lg"
                                      style={{ whiteSpace: "normal" }}
                                    >
                                      <span className="font-bold truncate text-base">
                                        {label}
                                      </span>
                                      {!!group && !isHidden && (
                                        <span className="text-xs truncate">
                                          <span className="font-semibold">
                                            Group -{" "}
                                          </span>
                                          {group}
                                        </span>
                                      )}
                                      {!!rate && !isHidden && (
                                        <span className="text-xs">
                                          <span className="font-semibold">
                                            Rate -{" "}
                                          </span>
                                          £{rate}
                                        </span>
                                      )}
                                      {!!postcode && !isHidden && (
                                        <span className="text-xs">
                                          <span className="font-semibold">
                                            Postcode -{" "}
                                          </span>
                                          {postcode}
                                        </span>
                                      )}
                                      {!!debouncedPostcodeValue &&
                                        !!originLatLngData &&
                                        !isHidden && (
                                          <span
                                            className={`text-sm font-bold ${
                                              !!values.tip &&
                                              values.tip === String(value)
                                                ? "text-white"
                                                : "text-green-600"
                                            }`}
                                          >
                                            {Math.round(
                                              getDistance(
                                                latitude,
                                                longitude,
                                                originLatLngData.latitude,
                                                originLatLngData.longitude,
                                              ) * 100,
                                            ) / 100}{" "}
                                            miles away
                                          </span>
                                        )}
                                    </Box>
                                  )}
                                </>
                              );
                            }}
                          />
                        )}
                      </Field>
                    )}
                    {/* Garage */}
                    {isMaintenanceOrderTypeSelected && (
                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="garage"
                        label={translate("task.form.labels.garage")}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            isLoading={
                              isLoadingGarageOptions || isRefetchingGarages
                            }
                            isDisabled={
                              isLoadingGarageOptions ||
                              isRefetchingGarages ||
                              !garageOptions.length
                            }
                            placeholder={translate(
                              "task.form.placeholders.garage",
                            )}
                            value={
                              garageOptions.find(
                                (option) => option.value === values.garage,
                              ) || null
                            }
                            options={garageOptions}
                            onMenuClose={() => {
                              setIsHidden(true);
                              setOriginPostcode("");
                            }}
                            onMenuOpen={() => setIsHidden(false)}
                            onInputChange={(input) => {
                              if (
                                !!input.match(postcodeRegExp) &&
                                input.length > 4
                              ) {
                                setOriginPostcode(input);
                                setIsLoadingOriginLatLng(true);
                                return;
                              }

                              setOriginPostcode("");
                              setIsLoadingOriginLatLng(false);
                            }}
                            onChange={(option) => {
                              const value = option?.value;

                              setFieldValue("isManualAddress", !!value);

                              if (!value) {
                                setFieldValue("deliveryAddress.postcode", "");
                                setFieldValue("deliveryAddress.line1", "");
                                setFieldValue("deliveryAddress.line2", "");
                                setFieldValue("deliveryAddress.city", "");
                                setFieldValue("deliveryAddress.address", "");
                                setFieldValue("garage", "");
                                return;
                              }

                              const { address, postcode, city } =
                                JSON.parse(value) || {};

                              setFieldValue(
                                "deliveryAddress.postcode",
                                postcode,
                              );
                              setFieldValue(
                                "deliveryAddress.line1",
                                address || "None",
                              );
                              setFieldValue(
                                "deliveryAddress.city",
                                city || "None",
                              );
                              setFieldValue("garage", value);
                            }}
                            filterOption={(option, rawInput) => {
                              if (!!originPostcode || !!debouncedPostcodeValue)
                                return true;

                              const label = (option.label || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const jsonValue =
                                JSON.parse(String(option.value)) || {};

                              const postcode = (jsonValue.postcode || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const group = (jsonValue.group || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              const input = (rawInput || "")
                                .replaceAll(" ", "")
                                .toLowerCase();

                              return (
                                label.includes(input) ||
                                postcode.includes(input) ||
                                group.includes(input)
                              );
                            }}
                            formatOptionLabel={({ label, value }) => {
                              const { postcode, group, latitude, longitude } =
                                JSON.parse(String(value)) || {};

                              return (
                                <>
                                  {isLoadingOriginLatLng && (
                                    <Box className="flex justify-center items-center h-24">
                                      <Icon.Loading
                                        color="brand.base"
                                        size="medium"
                                      />
                                    </Box>
                                  )}
                                  {!isLoadingOriginLatLng && (
                                    <Box
                                      className="flex flex-col gap-y-1 py-1 text-lg"
                                      style={{ whiteSpace: "normal" }}
                                    >
                                      <span className="font-bold truncate text-base">
                                        {label}
                                      </span>
                                      {!!group && !isHidden && (
                                        <span className="text-xs truncate">
                                          <span className="font-semibold">
                                            Group -{" "}
                                          </span>
                                          {group}
                                        </span>
                                      )}
                                      {!!postcode && !isHidden && (
                                        <span className="text-xs">
                                          <span className="font-semibold">
                                            Postcode -{" "}
                                          </span>
                                          {postcode}
                                        </span>
                                      )}
                                      {!!debouncedPostcodeValue &&
                                        !!originLatLngData &&
                                        !isHidden && (
                                          <span
                                            className={`text-sm font-bold ${
                                              !!values.garage &&
                                              values.garage === String(value)
                                                ? "text-white"
                                                : "text-green-600"
                                            }`}
                                          >
                                            {Math.round(
                                              getDistance(
                                                latitude,
                                                longitude,
                                                originLatLngData.latitude,
                                                originLatLngData.longitude,
                                              ) * 100,
                                            ) / 100}{" "}
                                            miles away
                                          </span>
                                        )}
                                    </Box>
                                  )}
                                </>
                              );
                            }}
                          />
                        )}
                      </Field>
                    )}
                    {/* VEHICLE */}
                    <Field
                      styles={{ flex: 1 }}
                      name="asset"
                      label={
                        !!values.asset
                          ? `${translate("task.form.labels.vehicle")} (${
                              values.asset
                            })`
                          : translate("task.form.labels.vehicle")
                      }
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isClearable
                          isSearchable
                          disabled={!vehicleOptions.length}
                          options={vehicleOptions}
                          placeholder={translate(
                            "task.form.placeholders.vehicle",
                          )}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box className="grid grid-rows-1 md:grid-cols-2 gap-x-5">
                    {/* DATE */}
                    <Field
                      styles={{ flex: 1 }}
                      name="date"
                      label={translate("task.form.labels.date")}
                    >
                      {({ field }) => (
                        <Input.Date
                          {...field}
                          dateFormat="DD-MM-YYYY"
                          placeholder={translate("task.form.placeholders.date")}
                          collapsable
                          disabledDays={{ before: dayjs().toDate() }}
                        />
                      )}
                    </Field>
                    {/* PLANNED START TIME */}
                    <Field
                      styles={{ flex: 1 }}
                      name="plannedStartTime"
                      label={translate("task.form.labels.plannedStartTime")}
                    >
                      {({ field }) => <Input.Text {...field} type="time" />}
                    </Field>
                  </Box>

                  {/* DESCRIPTION */}
                  <Field
                    styles={{ flex: 1 }}
                    name="description"
                    label={translate("task.form.labels.jobDescription")}
                  >
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        autoGrow={false}
                        styles={{ height: 80 }}
                        placeholder={translate(
                          "task.form.placeholders.jobDescription",
                        )}
                      />
                    )}
                  </Field>

                  <Box className="grid grid-rows-1 md:grid-cols-2 gap-x-5">
                    {/* POSTCODE */}
                    <Field
                      styles={{
                        flex: 1,
                      }}
                      name="deliveryAddress.postcode"
                      label={translate("task.form.labels.postcode")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={
                            isTipOrderTypeSelected ||
                            isMaintenanceOrderTypeSelected
                          }
                          placeholder={translate(
                            "task.form.placeholders.postcode",
                          )}
                          onChange={(e) => {
                            setFieldValue(
                              "deliveryAddress.postcode",
                              e.target.value,
                            );
                            setFieldValue("deliveryAddress.line1", "");
                            setFieldValue("deliveryAddress.line2", "");
                            setFieldValue("deliveryAddress.city", "");
                            setFieldValue("deliveryAddress.address", "");
                          }}
                        />
                      )}
                    </Field>
                    {(values.isManualAddress ||
                      isTipOrderTypeSelected ||
                      isMaintenanceOrderTypeSelected) && (
                      <>
                        {/* LINE 1 */}
                        <Field
                          styles={{
                            flex: 1,
                          }}
                          name="deliveryAddress.line1"
                          label={translate("task.form.labels.line1")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={
                                isTipOrderTypeSelected ||
                                isMaintenanceOrderTypeSelected
                              }
                              placeholder={translate(
                                "task.form.placeholders.line1",
                              )}
                            />
                          )}
                        </Field>
                        {/* LINE 2 */}
                        <Field
                          styles={{
                            flex: 1,
                          }}
                          name="deliveryAddress.line2"
                          label={translate("task.form.labels.line2")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={
                                isTipOrderTypeSelected ||
                                isMaintenanceOrderTypeSelected
                              }
                              placeholder={translate(
                                "task.form.placeholders.line2",
                              )}
                            />
                          )}
                        </Field>
                        {/* CITY */}
                        <Field
                          styles={{
                            flex: 1,
                          }}
                          name="deliveryAddress.city"
                          label={translate("task.form.labels.city")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={
                                isTipOrderTypeSelected ||
                                isMaintenanceOrderTypeSelected
                              }
                              placeholder={translate(
                                "task.form.placeholders.city",
                              )}
                            />
                          )}
                        </Field>
                      </>
                    )}

                    {!values.isManualAddress &&
                      !isTipOrderTypeSelected &&
                      !isMaintenanceOrderTypeSelected && (
                        <Box className="flex flex-col gap-y-1">
                          {/* ADDRESS */}
                          <Field
                            styles={{
                              flex: 1,
                            }}
                            name="deliveryAddress.address"
                            label={translate("task.form.labels.address")}
                          >
                            {({ field }) => (
                              <Input.Select
                                {...field}
                                isSearchable
                                isClearable
                                isLoading={isAddressLoading}
                                options={addressData ?? []}
                                placeholder={translate(
                                  "task.form.placeholders.address",
                                )}
                                disabled={
                                  !values.deliveryAddress.postcode ||
                                  isAddressLoading ||
                                  !addressData?.length
                                }
                                onChange={(event) => {
                                  const value = event.target.value;

                                  if (!value) {
                                    setFieldValue("deliveryAddress.line1", "");
                                    setFieldValue("deliveryAddress.line2", "");
                                    setFieldValue("deliveryAddress.city", "");
                                    setFieldValue(
                                      "deliveryAddress.address",
                                      "",
                                    );
                                    return;
                                  }

                                  const address = JSON.parse(value) || {};

                                  setFieldValue(
                                    "deliveryAddress.line1",
                                    address.line_1,
                                  );
                                  setFieldValue(
                                    "deliveryAddress.line2",
                                    address.line_2,
                                  );
                                  setFieldValue(
                                    "deliveryAddress.city",
                                    address?.town_or_city ?? "",
                                  );
                                  setFieldValue(
                                    "deliveryAddress.address",
                                    value,
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </Box>
                      )}
                  </Box>

                  {!isTipOrderTypeSelected && !isMaintenanceOrderTypeSelected && (
                    <Box className="flex justify-center">
                      <Field name="isManualAddress">
                        {({}) => (
                          <UnstyledButton
                            styles={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: theme.colors.brand.base,
                              textUnderlineOffset: theme.spacing.xsmall2,
                            }}
                            onClick={() =>
                              setFieldValue(
                                "isManualAddress",
                                !values.isManualAddress,
                              )
                            }
                          >
                            {translate(
                              `task.postcodeLookup.${
                                values.isManualAddress
                                  ? "enterYourPostcode"
                                  : "cantFindAddress"
                              }`,
                            )}
                          </UnstyledButton>
                        )}
                      </Field>
                    </Box>
                  )}

                  {/* DISPLAY ERRORS */}
                  {!!errorType && (
                    <Message type="error" className="my-5">
                      {errorType !== constantErrorTypes.address && (
                        <>
                          {translate("task.errors.fetchError", {
                            type: errorType,
                          })}
                        </>
                      )}
                      {/* DISPLAY POSTCODE ERROR */}
                      {errorType === constantErrorTypes.address && (
                        <>
                          {translate("task.errors.postcodeError")}
                          <UnstyledButton
                            styles={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: theme.colors.brand.base,
                              textUnderlineOffset: theme.spacing.xsmall2,
                            }}
                            onClick={() => {
                              mutateGettingAddressByPostcode(
                                values.deliveryAddress.postcode,
                              );
                              setErrorType("");
                            }}
                          >
                            {translate("task.buttons.tryAgain")}
                          </UnstyledButton>
                        </>
                      )}
                    </Message>
                  )}
                </Panel>
              </>
            );
          }}
        </Form>
      </Box>

      {/* SUCCESS MODAL */}
      {isSuccessModalOpen && (
        <Modal
          width={600}
          styles={{
            textAlign: "center",
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge} ${theme.spacing.xlarge2} ${theme.spacing.xlarge} ${theme.spacing.xlarge2}`,
            },
          }}
        >
          <Box className="flex flex-col items-center gap-y-8">
            <Heading
              level={2}
              styles={{
                color: theme.colors.accent.dark,
              }}
            >
              {translate("task.form.headings.success")}
            </Heading>
            <Box className="flex flex-col gap-y-1">
              <Text>
                {translate("task.jobReferenceText", {
                  ref: createTaskResponse?.ref ?? "N/A",
                })}
              </Text>
              <UnstyledButton
                styles={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: theme.colors.brand.base,
                  textUnderlineOffset: theme.spacing.xsmall2,
                }}
              >
                <Link to={`/jobs/update/${createTaskResponse?.id}`}>
                  {translate("task.buttons.viewJob")}
                </Link>
              </UnstyledButton>
            </Box>
            <Button
              type="button"
              size="small"
              color="warning"
              onClick={() => setIsSuccessModalOpen(false)}
            >
              {translate("task.buttons.createAnother")}
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};
