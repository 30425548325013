"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catalogues = void 0;
exports.catalogues = {
    headings: {
        newProductTemplate: "New product template",
        edit: "Edit",
        zone: "Zone",
        postcodes: "Postcodes",
        blackoutDates: "Blackout dates",
        blackoutDays: "Blackout days",
    },
    filters: {
        templatesHeading: "Search Product Templates",
        cataloguesHeading: "Search Catalogues",
        createNewTemplateButton: "Create new product template",
        createNewCatalogueButton: "Create new catalogue",
        searchButton: "Search",
        blackoutLabel: "Select templates to apply blackout dates/days to",
        cascadeSearchLabel: "Select catalogues to apply this template to",
        templateSearchPlaceholder: "Type here to search product templates...",
        catalogueSearchPlaceholder: "Type here to search catalogues...",
        searchItems: "Search items...",
        blackoutItemsPlaceholder: "Type here to search items...",
        blackoutItemsLabel: "Select items to apply blackout dates/days to",
        blackoutCataloguesPlaceholder: "Type here to search catalogues...",
        blackoutCataloguesLabel: "Select catalogues to apply blackout dates/days to",
        zones: "Zones",
    },
    table: {
        headings: {
            image: "Image",
            sku: "SKU",
            title: "Title",
            description: "Description",
            price: "Price",
            action: "Action",
            status: "Status",
        },
        actions: {
            edit: "Edit",
            delete: "Delete",
        },
    },
    buttons: {
        delete: "Delete",
    },
    loading: "Loading...",
    errors: {
        loadingResults: "An error has occurred, please try a different search value...",
        updatingCatalogue: "An error occurred while updating the catalogue, please try again...",
        zonesData: "An error has occurred while loading Zones options",
        invalidDate: "Invalid date",
    },
    nonApplicable: "$t(common:nonApplicable)",
    form: {
        headings: {
            meta: "Meta",
            addEditTimeslotOptions: "Add / Edit Timeslot Options",
        },
        placeholders: {
            nameThisTimeSlot: "Name this time slot",
            costOfTimeSlot: "Cost of time slot",
            selectTimeFrom: "Select time from",
            selectTimeUntil: "Select time until",
            id: "Enter an ID",
            sku: "Enter SKU",
            title: "Enter a name",
            description: "Enter description",
            type: "Select category | product",
            pricingModel: "Select pricing model",
            blackOutDates: "Select blackout date",
            cutoffTime: "Select cut off time",
            leadTime: "Enter a lead time",
            baseTime: "Base time",
            unitTime: "Unit time",
            multiplier: "Multiplier",
            requiredPersons: "Enter required persons",
            orderTypeId: "Select order type",
            img: "Image",
            friendlyTitle: "Enter friendly title",
            annotation: "Enter annotation",
            serviceType: "Enter service type",
            itemCount: "00.00",
            priority: "1-10",
            yards: "Yards",
            volume: "Enter volume",
            size: "Enter size",
            weight: "Enter weight",
            price: "Enter price",
        },
        labels: {
            charge: "Charge",
            from: "From",
            to: "To",
            id: "ID",
            active: "Active",
            public: "Public",
            hasVat: "VAT",
            vatRate: "VAT Rate",
            sku: "SKU",
            title: "Name",
            description: "Description",
            applySkipCharge: "Skip charge",
            type: {
                type: "Type",
                category: "Category",
                product: "Product",
            },
            pricingModel: {
                pricingModel: "Pricing model",
                suppliers: "Suppliers",
                override: "Override",
                postcode: "Postcode",
            },
            price: "Price",
            blackOutDays: "Blackout days",
            blackOutDates: "Blackout dates",
            cutoffTime: "Cut off time",
            leadTime: "Lead time",
            baseTime: "Base time",
            unitTime: "Unit time",
            multiplier: "Multiplier",
            requiredPersons: "Required persons",
            orderTypeId: "Order type",
            supplierMargin: "Supplier Margin",
            requiresOrderReference: "Requires order reference",
            noCalloutCharge: "No call out charge",
            imageUpload: "Image upload",
            uploadImage: "Upload image",
            removeImage: "Remove image",
            friendlyTitle: "Friendly title",
            annotation: "Annotation",
            serviceType: "Service type",
            fromPrice: "From price",
            itemCount: "Item count",
            priority: "Priority",
            yards: "Yards",
            volume: "Volume",
            size: "Size",
            weight: "Weight",
            popular: "Popular",
            days: {
                mon: "M",
                tue: "T",
                wed: "W",
                thu: "T",
                fri: "F",
                sat: "S",
                sun: "S",
            },
        },
        buttons: {
            remove: "Remove",
            reset: "Reset",
            save: "Save",
            delete: "Delete",
            cancel: "Cancel",
            cascade: "Cascade",
            duplicate: "Duplicate",
            setTimeslotRanges: "Set Timeslot Ranges",
            addTimeslotRange: "Add Another Timeslot Range",
            addTimeslotOption: "Add a Timeslot Option",
            viewTimeslotOptions: "View Timeslot Options",
            hideTimeslotOptions: "Hide Timeslot Options",
            addPostcode: "Add Postcode",
        },
        errors: {
            duplicateSku: "This SKU already exists",
        },
    },
    addDate: "Add date",
    addAnotherBlackoutDate: "Add another blackout date",
    close: "Close",
    success: {
        heading: "Success",
        message: "Successfully updated a catalogue template",
    },
    modal: {
        update: {
            heading: "Do you want to cascade?",
            message: "Cascade this template to multiple catalogues.",
        },
        cascade: {
            heading: "Success",
            message: "You have successfully cascaded the template to multiple catalogues",
            goToTemplates: "Go to product templates",
            backToTemplate: "Back to template view",
        },
        blackout: {
            heading: "Success",
            message: "You have successfully updated the templates",
        },
        catalogueUpdate: {
            heading: "Success",
            message: "You have successfully updated the catalogue",
            goBack: "Continue editing",
        },
        catalogueUpdateItem: {
            heading: "Success",
            message: "You have successfully updated the catalogue item",
            viewCatalogue: "View catalogue",
        },
        catalogueCreate: {
            heading: "Success",
            message: "You have successfully created the catalogue",
            edit: "Edit",
        },
        deleteItem: {
            heading: "Confirm Deletion",
            message: "Are you sure you want to remove {{ title }}?",
        },
        readTemplates: {
            areYouSure: "Are you sure you want to delete this template?",
        },
    },
    sidebar: {
        products: "Products",
        categories: "Categories",
    },
    blackout: {
        tabs: {
            catalogues: "Catalogues",
            items: "Items",
        },
        itemsSelected: "Items selected",
        cataloguesSelected: "Catalogues selected",
        andMore: "and {{count}} more...",
    },
    catalogueEditing: {
        copyOf: "Copy of {{title}}",
        undo: "Undo",
        advancedEdit: "Advanced Edit",
        enterFullScreen: "Enter full screen mode",
        exitFullScreen: "Exit full screen mode",
        edit: "Edit",
        close: "Close",
        form: {
            title: "Title",
            description: "Description",
            price: "Price",
            categories: "Categories",
        },
        errors: {
            categoryOnly: "You can only drop categories here",
            itemExists: "Item '{{ item }}' already exists in '{{ category }}'",
            noDuplicateCategories: "No duplicate categories allowed",
            categoryAlreadyExists: "{{ title }} already exists",
        },
        tabs: {
            assignedCompanies: "Assigned Companies",
            products: "Products",
            companies: "Companies",
            companiesHeading: "Select Companies",
            companiesContent: "Select the companies you want this catalogue to apply to:",
            companiesSearchPlaceholder: "Type here to search for companies...",
        },
        emptyDragDrop: "Drag and drop products from the side panel to begin.",
    },
    orderType: {
        headings: {
            manageOrderTypes: "Manage Order Types",
            createOrderType: "Create Order Type",
            editOrderType: "Edit Order Type",
            updateWorksheets: "Update Worksheets",
            associatedWorkshets: "Associated Worksheets",
            viewWorksheets: "View Worksheets.",
            worksheets: "Worksheets",
        },
        buttons: {
            add: "Add",
            reload: "Reload",
            update: "Update",
            cancel: "Cancel",
            search: "Search",
            edit: "Edit",
            remove: "Remove",
            reset: "Reset",
        },
        table: {
            headings: {
                bcJobType: "BigChange Job Type",
                id: "ID",
                name: "Name",
                parentRef: "Parent Reference",
                priceOverride: "Allow Price Override",
                resourceId: "Resource ID",
                action: "Action",
            },
        },
        form: {
            labels: {
                name: "Name",
                resourceId: "Resource ID",
                bcJobType: "BigChange Job Type",
                parentRef: "Parent Ref",
                priority: "Priority",
                driversDescription: "Drivers Description",
                subDescription: "Subcontractor Description",
            },
            placeholders: {
                search: "Search",
                name: "Enter Name",
                resourceId: "Enter Resource ID",
                priority: "Enter Priority",
                parentRef: "Enter Parent reference",
                bcJobType: "Enter BigChange job type",
                driversDescription: "Enter Drivers Description",
                subDescription: "Enter Subcontractor Description",
            },
        },
        success: {
            postOrderTypeSuccess: "Order Type ID created successfully",
            patchOrderTypeSuccess: "Order Type updated successfully",
            patchWorksheetsSuccess: "Worksheets updated successfully",
        },
        errors: {
            getOrderTypesError: "Order Type ID list could not be fetched. Try again",
            postOrderTypeError: "Order Type ID could not be created. Try again",
            patchOrderTypeError: "Order Type could not be updated. Try again",
            noWorksheets: " There are no worksheets associated with this order type id.",
            errorGetWorksheets: "Error getting worksheets, please click reload to try again.",
            errorPatchWorksheets: "Error updating worksheets, please try again.",
        },
    },
    zones: {
        headings: {
            zones: "Zones",
            zone: "Zone",
            blackoutDays: "Blackout Days",
            blackoutDates: "Blackout Dates",
            postcodes: "Postcodes",
        },
        buttons: {
            add: "Add",
            cancel: "Cancel",
            search: "Search",
            edit: "Edit",
            reset: "Reset",
            save: "Save",
            addPostcode: "Add Postcode",
        },
        form: {
            labels: {
                search: "Search",
                days: {
                    mon: "M",
                    tue: "T",
                    wed: "W",
                    thu: "T",
                    fri: "F",
                    sat: "S",
                    sun: "S",
                },
            },
            placeholders: {
                name: "Enter a name",
                search: "Search a zone",
                date: "Click to select a date",
                postcodes: "Enter the postcodes",
            },
        },
        table: {
            headings: {
                id: "ID",
                name: "Name",
                action: "Action",
            },
        },
        success: {
            zones: "Updated successfully",
        },
        errors: {
            zonesFailed: "Failed to update zone",
            invalidPostcode: "Invalid postcode",
        },
        texts: {
            uploadMultiplePostcodes: "You can upload multiple postcodes by separating them with a comma",
        },
    },
    tooltips: {
        overrideCascade: "With override selected, the item's information will be completely overridden. For example, if you only have Tuesday selected as a blackout date below but the item targeted has a blackout date of Monday, the Monday will be overridden and deselected while Tuesday will be selected.\n\n With override off, the item will be merged with the selected options below. So again, if you only choose Tuesday from the days below and target an item with a blackout day of Monday, the Tuesday will be added and the Monday selected will be maintained.",
        overrideBlackouts: "With override selected, the item's information will be completely overridden. For example, if you only have Tuesday selected as a blackout date below but the item targeted has a blackout date of Monday, the Monday will be overridden and deselected while Tuesday will be selected.\n\n With override off, the item will be merged with the selected options below. So again, if you only choose Tuesday from the days below and target an item with a blackout day of Monday, the Tuesday will be added and the Monday selected will be maintained.",
    },
    overrideCascade: "Override",
};
