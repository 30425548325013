"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifications = void 0;
exports.notifications = {
    heading: "Notifications",
    error: "Something went wrong. Please try again.",
    noData: "No data available",
    fieldMissing: "Some of the required fields are missing",
    deleteNotification: "Notification has been deleted",
    confirmDelete: "Are you sure you want to delete notification",
    noNotifications: "No notifications found",
    addNotification: "Add Notification",
    buttons: {
        search: "Search",
        reset: "Reset",
        update: "Update",
        delete: "Delete",
        remove: "Remove",
        cancel: "Cancel",
        addNotification: "Add Notification",
        edit: "Edit",
    },
    labels: {
        active: "Active",
        paused: "Paused",
        name: "Name",
        notificationUpdated: "Notification has been updated",
        notificationCreated: "Notification has been created",
        notification: "Notification",
        resetForm: "Reset form",
    },
    table: {
        status: "Status",
        name: "Name",
        trigger: "Trigger",
        actions: "Actions",
    },
    upsertNotification: {
        buttons: {
            reset: "Reset",
            save: "Save",
            addAction: "Add Action",
            addCondition: "Add Condition",
        },
        messages: {
            noSchemaFound: "No schema found for selected trigger",
        },
        toasts: {
            notificationCreated: "Notification has been successfully created",
            notificationUpdated: "Notification has been successfully updated",
            actionCreated: "Action has been successfully created",
            actionUpdated: "Action has been successfully updated",
            actionDeleted: "Action has been successfully deleted",
            serviceSchemaCopied: "Service schema has been copied to clipboard",
        },
        links: {
            previewTrigger: "Preview Trigger Schema",
            previewServiceSchema: "Preview Service Schema",
        },
        headings: {
            actions: "Actions",
            conditions: "Conditions",
            triggerSchema: "Trigger Schema",
            serviceSchema: "Service Schema",
            noActions: "No actions has been added yet",
            noConditions: "No conditions applied",
            updateNotification: "Update Notification",
            createNotification: "Create Notification",
            updateAction: "Update Action",
            createAction: "Create Action",
            fetchingActions: "Fetching actions...",
            service: "Service:",
            type: "Type:",
        },
    },
};
