import * as Yup from "yup";
import { internationalPhone } from "validation/common";

export const initialValues = {
  phoneNumber: "",
  weekendRotation: "",
  slackId: "",
  slackChannelId: "",
  asset: "",
};

export const validationSchema = Yup.object().shape({
  phoneNumber: internationalPhone.notRequired(),
  weekendRotation: Yup.string(),
  slackId: Yup.string(),
  slackChannelId: Yup.string(),
  asset: Yup.string(),
});
