import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import dayjs from "dayjs";
import { ICompany, IPaginatedOrderTypes } from "@clearabee/api-schemas";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Box,
  Field,
  Icon,
  Input,
  Panel,
  Text,
  UnstyledButton,
  theme,
} from "@clearabee/ui-library";

interface JobRowProps {
  index: number;
  orderTypes: IPaginatedOrderTypes | undefined;
  formSubmitted: boolean;
  subcontractors?: ICompany[];
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  onJobPatched: () => void;
  onJobRemove: (index: number) => void;
}

export const JobRow = ({
  index,
  orderTypes,
  formSubmitted,
  subcontractors,
  jobData,
  onJobPatched,
  onJobRemove,
}: JobRowProps): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");
  const { values, setFieldValue }: FormikProps<FormikValues> =
    useFormikContext();

  const selectedSubcontractors = subcontractors?.filter(
    (item) => values.subcontractor.value === item.companyCode,
  );
  const preventFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const {
    mutate: patchJob,
    isLoading: patchJobIsLoading,
    isSuccess: patchJobIsSuccess,
    isError: patchJobIsFailed,
  } = useMutation(
    async () => {
      if (!values.subcontractor) return;
      return await instance.jobs.patchJob(String(jobData?.id), {
        subcontractorCodes: [values.subcontractor.value],
        meta: {
          ...jobData?.meta,
          subcontractorInvoiceAmount: !!values.jobs[index].invoice
            ? values.jobs[index].invoice
            : values.globalInvoice,
          invoiceAmount: !!values.jobs[index].invoice
            ? values.jobs[index].invoice
            : values.globalInvoice,
          group: values.groupName,
        },
        suppressEmail: true,
      });
    },
    {
      onSuccess: () => {
        onJobPatched();
      },
    },
  );

  const areNotCoveringPostcode =
    selectedSubcontractors &&
    selectedSubcontractors?.filter(
      ({ postcodes }) =>
        !!postcodes &&
        !postcodes.some(
          (item) =>
            `${item.outwardCode}${item.inwardCode}` ===
            jobData?.addressPostcode,
        ),
    );

  useEffect(() => {
    if (formSubmitted) {
      patchJob();
    }
  }, [formSubmitted]);

  return (
    <>
      <Panel
        styles={{
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          marginBottom: theme.spacing.xlarge2,
        }}
      >
        <Box className="flex justify-between items-center">
          <Box className="flex flex-col">
            <Box className="flex items-center gap-5">
              <Field
                name={`jobs[${index}].invoice`}
                label={translate("allocateJobs.labels.invoiceAmount")}
              >
                {({ field }) => (
                  <Input.Text {...field} onKeyDown={preventFormSubmit} />
                )}
              </Field>
            </Box>
            <Box className="flex gap-5">
              <Box className="flex gap-2">
                <Text className="font-semibold">
                  {translate("allocateJobs.titles.jobReference")}
                </Text>
                {jobData?.ref}
              </Box>
              {jobData?.date && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.collectionDate")}
                  </Text>
                  {dayjs(jobData?.date).format("DD/MM/YYYY")}
                </Box>
              )}
              {jobData?.addressPostcode && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.postcode")}
                  </Text>
                  {jobData?.addressPostcode}
                </Box>
              )}
              {jobData?.orderTypeId && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.orderType")}
                  </Text>
                  {orderTypes?.items.find(
                    ({ id }) => id === jobData.orderTypeId,
                  )?.name || ""}
                </Box>
              )}
            </Box>
            {jobData?.description && (
              <Box className="flex gap-2">
                <Text className="font-semibold">
                  {translate("allocateJobs.titles.description")}
                </Text>
                {jobData?.description}
              </Box>
            )}
            {!!areNotCoveringPostcode?.length && !!jobData && (
              <Box color="warning.dark" className="flex flex-col mt-2">
                <Box className="flex gap-1">
                  <Icon.Attention size="medium" color="warning" />
                  <Box className="flex flex-col flex-wrap">
                    {translate("allocateJobs.errors.dontCoverPostcode")}
                    <Box className="flex gap-1 flex-wrap">
                      {areNotCoveringPostcode?.map(({ name }, index) => (
                        <Text key={index} fontSize="small">
                          {name},
                        </Text>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <UnstyledButton
            disabled={
              patchJobIsFailed || patchJobIsLoading || patchJobIsSuccess
            }
            onClick={() => {
              setFieldValue(
                "jobs",
                values.jobs.filter(
                  (_: unknown, jobIndex: number) => index !== jobIndex,
                ),
              );
              onJobRemove(index);
            }}
          >
            {!patchJobIsLoading && !patchJobIsSuccess && !patchJobIsFailed && (
              <Icon.Trash color="negative" size="large" />
            )}
            {patchJobIsLoading && <Icon.Loading size="large" />}
            {patchJobIsSuccess && <Icon.Tick2 color="positive" size="large" />}
            {patchJobIsFailed && <Icon.Close color="negative" size="large" />}
          </UnstyledButton>
        </Box>
      </Panel>
    </>
  );
};
