import axios from "axios";
import { ICatalogueTree } from "../models/catalogue";
import { CatalogueItem } from "../models/catalogueItem";
import { filterItemFromResults } from "../helpers/catalogue";
import { Item } from "../models/item";
import {
  Catalogue,
  IPaginatedResults,
  PatchCatalogueItems,
  PostCatalogue,
} from "./types";
import { Template } from "models/template";

export const endpoints = {
  PUT_CATALOGUE: (id: string): string => `/catalogues/${id}`,
  PATCH_CATALOGUE: (id: string): string => `/catalogues/${id}`,
  PATCH_CATALOGUE_ITEM: (
    catalogueId: string,
    itemSku: string,
    append = false,
  ): string =>
    `/catalogues/${catalogueId}/items/${itemSku}${
      append ? "?append=true" : ""
    }`,
  GET_CATALOGUE: (id: string): string => `/catalogues/${id}`,
  GET_CATALOGUES: "/catalogues",
  GET_CATALOGUE_ITEM: (catalogueId: string, itemSku: string): string =>
    `/catalogues/${catalogueId}/items/${itemSku}`,
  POST_CATALOGUE_ITEMS: (id: string): string => `/catalogues/${id}/items`,
  PATCH_CATALOGUE_ITEMS: (id: string): string => `/catalogues/${id}/items`,
  DELETE_CATALOGUE_ITEMS: (id: string): string => `/catalogues/${id}/items`,
  POST_COMPANY_ASSIGN: (id: string): string =>
    `/catalogues/${id}/companies/assign`,
  DELETE_COMPANY_ASSIGN: (id: string): string =>
    `/catalogues/${id}/companies/delete`,
  GET_CALLOUT_CHARGE: (sku: string, catalogueId: string): string =>
    `catalogues/${catalogueId}/callout/${sku}`,
  POST_ORDER_TYPES: "/catalogues/order-types",
  GET_ORDER_TYPES: "/catalogues/order-types",
  PATCH_ORDER_TYPES: (id: string): string => `/catalogues/order-types/${id}`,
};

export type IParsedItems = {
  label: string;
  value: string;
  meta?: Template["meta"];
  price?: number;
};

export interface IOrderType {
  id?: number;
  name: string;
  createdOn?: string;
  parentRef?: string;
  allowPriceOverride?: boolean;
  bcJobType?: string;
  resourceId?: string;
  priority?: number;
  description?: {
    subcontractors?: string;
    drivers?: string;
  };
}

export const parseCatalogueItems = (items: Item[]): IParsedItems[] => {
  return items?.map((item) => {
    return {
      label: item.title,
      value: item.sku,
      meta: item.meta,
      price: item.price || 0,
    };
  });
};

export const getCatalogue = async (
  catalogueId: string,
): Promise<Catalogue | null> => {
  const res = await axios.get(endpoints.GET_CATALOGUE(catalogueId));
  return res.data;
};
export const getFlatCatalogue = async (
  catalogueId: string,
  postcode = "",
): Promise<Catalogue | null> => {
  const res = await axios.get(
    endpoints.GET_CATALOGUE(`${catalogueId}?flatten=true&postcode=${postcode}`),
  );
  return res.data;
};

export const getCatalogueItem = async (
  catalogueId: string,
  itemSku: string,
): Promise<CatalogueItem | null> => {
  const res = await axios.get(
    endpoints.GET_CATALOGUE_ITEM(catalogueId, itemSku),
  );
  return res.data;
};

export const getCatalogueByCompanyCode = async (
  companyCode: string,
): Promise<ICatalogueTree | null> => {
  const res = await axios.get(`/catalogues/company/${companyCode}`);
  return res.data;
};

export const getCatalogueId = async (
  companyId: number,
): Promise<string | null> => {
  const result = await axios.get(
    `/catalogues?companies.id=${companyId}&id:neq=website`,
  );
  if (!result || !result.data) return null;

  return result.data.items[0]?.id;
};

export const getParsedProductsFromService = (
  catalogue: ICatalogueTree | null,
  selectedService: string | null,
): IParsedItems[] | null => {
  if (!catalogue || !selectedService) return null;

  const items = getItemsFromService(selectedService, catalogue);
  if (!items) return null;

  const products = filterItemFromResults(items);

  return parseCatalogueItems(products);
};

export const getItemsFromService = (
  serviceSku: string,
  catalogue: ICatalogueTree,
): CatalogueItem["children"] => {
  const { items } = catalogue;
  return items.find((item: { sku: string }) => item.sku === serviceSku)
    ?.children;
};

export const getCatalogueOrderTypes =
  async (): Promise<IPaginatedResults<IOrderType> | null> => {
    const res = await axios.get(endpoints.GET_ORDER_TYPES, {
      params: { limit: 1000 },
    });
    return res.data;
  };

export const createOrderType = async (
  values: Omit<IOrderType, "id" | "createdOn" | "allowPriceOverride">,
): Promise<IOrderType> => {
  const res = await axios.post(endpoints.POST_ORDER_TYPES, values);
  return res.data;
};

export const patchOrderType = async (
  id: IOrderType["id"],
  patch: Omit<IOrderType, "id" | "createdOn">,
): Promise<IOrderType> => {
  const res = await axios.patch(endpoints.PATCH_ORDER_TYPES(`${id}`), patch);
  return res.data;
};

export const getCatalogues = async (options?: {
  limit?: number;
  offset?: number;
}): Promise<IPaginatedResults<Catalogue>> => {
  const res = await axios.get(endpoints.GET_CATALOGUES, {
    params: { limit: options?.limit, offset: options?.offset },
  });
  return res.data;
};

export const removeCatalogueFromCompanies = async (
  catalogueId: string,
  companyCodes: string[],
): Promise<void> => {
  const { data } = await axios.delete(
    endpoints.DELETE_COMPANY_ASSIGN(catalogueId),
    {
      data: companyCodes,
    },
  );
  return data;
};

export const assignCatalogueToCompanies = async (
  catalogueId: string,
  companyCodes: string[],
): Promise<void> => {
  const res = await axios.post(
    endpoints.POST_COMPANY_ASSIGN(catalogueId),
    companyCodes,
  );
  return res.data;
};

export const updateCatalogue = async (
  id: string,
  catalogue: Partial<Catalogue>,
): Promise<Catalogue> => {
  const res = await axios.patch(endpoints.PATCH_CATALOGUE(id), catalogue);
  return res.data;
};

export const updateCatalogueItem = async (
  id: string,
  itemSku: string,
  catalogue: Partial<CatalogueItem>,
  // if append is true, it will append the data to the existing data, otherwise it will override
  append = false,
): Promise<Catalogue> => {
  const res = await axios.patch(
    endpoints.PATCH_CATALOGUE_ITEM(id, itemSku, append),
    catalogue,
  );
  return res.data;
};

export const createCatalogue = async (
  catalogue: PostCatalogue,
): Promise<Catalogue> => {
  const { id, ...data } = catalogue;

  const res = await axios.put(endpoints.PUT_CATALOGUE(id), data);
  return res.data;
};

export const createCatalogueItems = async (
  catalogueId: string,
  items: CatalogueItem[],
): Promise<void> => {
  const res = await axios.post(
    endpoints.POST_CATALOGUE_ITEMS(catalogueId),
    items,
  );
  return res.data;
};

export const updateCatalogueItems = async (
  catalogueId: string,
  items: PatchCatalogueItems,
): Promise<void> => {
  const res = await axios.patch(
    endpoints.PATCH_CATALOGUE_ITEMS(catalogueId),
    items,
  );
  return res.data;
};

export const deleteCatalogueItems = async (
  catalogueId: string,
  itemSkus: CatalogueItem["sku"][],
): Promise<void> => {
  const res = await axios.delete(endpoints.PATCH_CATALOGUE_ITEMS(catalogueId), {
    data: {
      items: itemSkus,
      removeChildren: true,
    },
  });
  return res.data;
};

export const getCalloutCharge = async (
  sku: string,
  catalogueId: string,
): Promise<CatalogueItem> => {
  const res = await axios.get(endpoints.GET_CALLOUT_CHARGE(sku, catalogueId));

  return res.data;
};
