import React, { useMemo } from "react";
import { Box, Button, theme } from "@clearabee/ui-library";
import { ViewProducts } from "../components/viewProducts";
import { useCatalogueContext, useMultiFormContext } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { IFormState } from "../types";

export const SelectProducts: React.FC = () => {
  const [t] = useTranslation("jobs");
  const { formState, nextStep } = useMultiFormContext<IFormState>();
  const { catalogueItems } = useCatalogueContext();

  const { service } = formState;

  const products = useMemo(() => {
    if (!catalogueItems) return [];

    return (
      catalogueItems
        .find((item) => item.sku === service)
        ?.children?.map((product) => product) || []
    );
  }, [catalogueItems, service]);

  return (
    <>
      <ViewProducts products={products} />
      <Box className="mt-10 flex justify-around">
        <Button
          color="accent"
          size="small"
          type="button"
          disabled={!formState.items.length}
          variant="outline"
          onClick={() => nextStep()}
          styles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: !formState.items.length ? "not-allowed" : "pointer",
            opacity: !formState.items.length ? 0.5 : 1,
            gap: theme.spacing.xsmall,
            ":hover": {
              background: theme.colors.accent.base,
            },
          }}
        >
          {t("create.buttons.next")}
        </Button>
      </Box>
    </>
  );
};
