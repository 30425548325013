import React from "react";
import {
  PhoneInput,
  PhoneInputProps,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import { theme } from "@clearabee/ui-library";
import "react-international-phone/dist/index.css";

type Field = any;

interface InputInternationalPhoneNumberProps extends PhoneInputProps, Field {}

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ["gb", "ie"].includes(iso2);
});

export const InputInternationalPhoneNumber = ({
  ...props
}: InputInternationalPhoneNumberProps): React.ReactElement => {
  return (
    <PhoneInput
      inputStyle={{
        ...theme.fontDefaults.small,
        width: "100%",
        height: "40px",
      }}
      countrySelectorStyleProps={{
        buttonStyle: { height: "40px" },
      }}
      defaultCountry="gb"
      forceDialCode
      countries={countries}
      inputProps={{ role: "phone-input" }}
      {...props}
    />
  );
};
