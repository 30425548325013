import React from "react";
import { Box, Heading, Text } from "@clearabee/ui-library";

interface DisplayJobProps {
  heading: string;
  job: {
    duration: number | string;
    jobId: number | string;
    reference: string;
    asset: string;
    location: string;
    postcode: string;
    type: string;
    status: string;
    plannedStart: string;
    contact: string;
    created: string;
    date: string;
  };
}

export const DisplayJob = ({
  heading,
  job,
}: DisplayJobProps): React.ReactElement => {
  const jobProps = {
    ...job,
  };

  return (
    <Box className="flex flex-col gap-3">
      <Heading level={5} color="brand">
        {heading}
      </Heading>

      {Object.entries(jobProps).map(([key, value]) => (
        <Text key={key + value}>
          <Text className="font-bold" as="span">
            {key}:
          </Text>{" "}
          {value}
        </Text>
      ))}
    </Box>
  );
};
