import React from "react";
import { useMutation } from "react-query";
import {
  Form,
  Field,
  Input,
  UnstyledButton,
  Icon,
  Text,
  theme,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { Link } from "react-router-dom";

interface GlobalSearchProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const GlobalSearch = ({
  visible,
  setVisible,
}: GlobalSearchProps): React.ReactElement => {
  const { mutate, isLoading, data } = useMutation(
    (variables: { searchTerm: string }) => {
      const { searchTerm } = variables;
      return instance.jobs.getJobs({
        params: {
          "purchaseOrder|ref:like": `${searchTerm}%`,
        },
      });
    },
  );
  const results = data?.data?.items;

  const handleSubmit = (values: { search: string }) => {
    const { search } = values;
    const searchTerm = search.trim();

    if (!searchTerm) return;

    mutate({ searchTerm });
  };

  if (!visible) return <></>;

  return (
    <div className="flex justify-center fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-80 z-100">
      <div className="w-full md:max-w-4xl px-4 py-20">
        <Form initialValues={{ search: "" }} onSubmit={handleSubmit}>
          <Field name="search">
            {({ field }) => (
              <div className="w-full relative">
                <Input.Text
                  {...field}
                  required={true}
                  autoFocus={true}
                  autoComplete="off"
                  readOnly={isLoading}
                  className="text-lg font-semibold uppercase"
                  styles={{ padding: "18px" }}
                />
                <div className="flex absolute right-2 top-4 gap-2">
                  {isLoading && (
                    <Icon.Loading size="medium" styles={{ marginTop: "1px" }} />
                  )}

                  <UnstyledButton
                    type="submit"
                    disabled={isLoading}
                    className="text-sm font-bold"
                    styles={{
                      padding: "2px 8px",
                    }}
                  >
                    <Icon.Search2 size="medium" />
                  </UnstyledButton>

                  <UnstyledButton
                    type="button"
                    onClick={() => setVisible(false)}
                    className="text-sm font-bold"
                    styles={{
                      border: `1px solid ${theme.colors.greyscale.base}`,
                      padding: "2px 4px",
                    }}
                  >
                    ESC
                  </UnstyledButton>
                </div>
              </div>
            )}
          </Field>
        </Form>

        {/* Results */}
        {!!results && !!results.length && (
          <div className="flex flex-col bg-white rounded-sm max-h-96 overflow-y-scroll">
            {results.map((item) => (
              <>
                <Link
                  to={`/jobs/update/${item.id}`}
                  onClick={() => setVisible(false)}
                  className="p-4 hover:bg-gray-100"
                >
                  <Text fontSize="small">
                    {[item.ref, item.purchaseOrder, item.addressPostcode]
                      .filter(Boolean)
                      .join(" - ")}
                  </Text>
                </Link>
                <hr />
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
