import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  UnstyledButton,
  Text,
  Icon,
  Field,
  Form,
  theme,
  Input,
} from "@clearabee/ui-library";
import { IVehicle } from "@clearabee/api-schemas";
import { truncateString } from "helpers";

interface VehicleListProps {
  data: IVehicle[];
  refetch: () => void;
  isLoading: boolean;
  isError: boolean;
  selectedVehicle: string;
  setSelectedVehicle: (value: string) => void;
  isFullScreen: boolean;
}

export const VehicleList = ({
  data: allVehicles,
  refetch,
  isError,
  isLoading,
  selectedVehicle,
  setSelectedVehicle,
  isFullScreen = false,
}: VehicleListProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <Form
      initialValues={{ search: "" }}
      validationSchema={Yup.object({
        search: Yup.string(),
      })}
      onSubmit={() => {
        return;
      }}
    >
      {({ values, resetForm }) => (
        <>
          <Box className="relative flex justify-center mb-1">
            <Text fontSize="xsmall" className="font-semibold uppercase">
              {translate("schedule.headings.vehiclesCount", {
                count: allVehicles?.length ?? 0,
              })}
            </Text>
            <UnstyledButton
              type="button"
              className="absolute right-0"
              disabled={isLoading}
              onClick={() => {
                refetch();
                resetForm();
              }}
            >
              <Icon.Refresh size="small" />
            </UnstyledButton>
          </Box>
          <Box
            styles={{
              borderWidth: "1px",
              borderColor: theme.colors.brand.lighter,
              padding: theme.spacing.xsmall,
              borderRadius: theme.spacing.xsmall,
            }}
          >
            <Box className="mb-2">
              <Field
                styles={{
                  margin: 0,
                }}
                name="search"
              >
                {({ field }) => (
                  <>
                    <Text fontSize="xsmall2" className="font-medium">
                      {translate("schedule.form.labels.searchVehicleName")}
                    </Text>
                    <Input.Text
                      {...field}
                      disabled={isLoading}
                      placeholder={translate(
                        "schedule.form.placeholders.searchVehicleName",
                      )}
                    />
                  </>
                )}
              </Field>
            </Box>
            <Box
              styles={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing.xsmall,
                overflowY: "scroll",
                height: isFullScreen ? "70vh" : "69vh",
              }}
            >
              {/* LOADING */}
              {isLoading && (
                <Box className="flex items-center justify-center gap-x-1 my-1">
                  <Text fontSize="xsmall" color="brand">
                    {translate("schedule.loadingMessage", {
                      type: "vehicles",
                    })}
                  </Text>
                  <Icon.Loading color="brand" size="small" />
                </Box>
              )}
              {/* DISPLAY VEHICLES */}
              {!!allVehicles?.length &&
                !isLoading &&
                !isError &&
                allVehicles
                  .filter(({ name }) => {
                    if (!values.search) return true;

                    return name
                      ?.replaceAll(" ", "")
                      .toLowerCase()
                      .includes(
                        values.search.replaceAll(" ", "").toLowerCase(),
                      );
                  })
                  .map(({ name, registration, type }) => {
                    const isSelected = selectedVehicle === registration;

                    return (
                      <UnstyledButton
                        key={`vehicle-${registration}`}
                        type="button"
                        onClick={() => {
                          setSelectedVehicle(registration);
                        }}
                      >
                        <Box
                          id={`vehicle-${registration}`}
                          className={`h-20 flex flex-col items-start gap-y-1 py-2 pl-2 rounded-md bg-gray-200 hover:bg-gray-400 ${
                            isSelected && "bg-green-light hover:bg-green-dark"
                          }`}
                        >
                          <Text
                            fontSize="small"
                            color="brand"
                            className="font-semibold"
                          >
                            {truncateString(name || registration, 20)}
                          </Text>
                          <Text fontSize="small" className="font-semibold">
                            {registration}
                          </Text>
                          <Text fontSize="small">{type?.type}</Text>
                        </Box>
                      </UnstyledButton>
                    );
                  })}
              {/* NO VEHICLES */}
              {!allVehicles?.length && !isLoading && !isError && (
                <Text
                  fontSize="small"
                  color="greyscale"
                  className="text-center uppercase opacity-70"
                >
                  {translate("schedule.errors.noVehiclesAvailable")}
                </Text>
              )}
              {/* DISPLAY ERROR */}
              {isError && (
                <Text fontSize="small" color="negative" className="text-center">
                  {translate("schedule.errors.clickRefreshIcon", {
                    type: "vehicles",
                  })}
                </Text>
              )}
            </Box>
          </Box>
        </>
      )}
    </Form>
  );
};
