import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Text,
  Box,
  theme,
  UnstyledButton,
  Heading,
  formatPostcode,
  Button,
  Icon,
  Modal,
  getColorFromVariantKey,
} from "@clearabee/ui-library";
import { IJob } from "@clearabee/api-schemas";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { truncateString } from "helpers";

dayjs.extend(utc);

type StatusColors = {
  [Key in IJob["status"]]: AllowedColorKeys;
};

const statusColors: StatusColors = {
  open: "greyscale",
  scheduled: "warning",
  "on the way": "positive",
  started: "brand",
  failed: "negative",
  cancelled: "negative",
  completed: "positive",
};

interface DisplayScheduledJobsProps {
  data: IJob[] | undefined;
  selectedJob: IJob | null;
  onClick: (job: IJob) => void;
  onClickUnassign: (job: IJob) => void;
  isLoading: boolean;
  isError: boolean;
}

export const DisplayScheduledJobs = ({
  data,
  onClick,
  onClickUnassign,
  selectedJob,
  isLoading = false,
  isError = false,
}: DisplayScheduledJobsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [activeJob, setActiveJob] = useState<IJob | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* Loading */}
      {isLoading && (
        <Box className="flex justify-center items-center h-40">
          <Icon.Loading color="brand" />
        </Box>
      )}
      {!!data && !!data.length && !isLoading && (
        <Box className="h-64 pl-5 flex gap-x-5 overflow-x-scroll">
          {data
            ?.sort(
              (a, b) =>
                dayjs(a.plannedStartTime).unix() -
                dayjs(b.plannedStartTime).unix(),
            )
            ?.map((job) => {
              const {
                ref,
                plannedStartTime,
                duration,
                type,
                addressPostcode,
                status,
                statusId,
              } = job;

              const isSelected = selectedJob?.ref === ref;

              return (
                <Box
                  key={`scheduled-job-${ref}`}
                  className="flex flex-col justify-center gap-y-3"
                >
                  <UnstyledButton type="button" onClick={() => onClick(job)}>
                    <Box
                      className="min-w-48 flex flex-col items-center justify-center gap-y-1 rounded-md bg-gray-200 border-b-8"
                      styles={{
                        height: "180px",
                        borderColor: getColorFromVariantKey(
                          statusColors?.[status.toLowerCase()] || "greyscale",
                        ),
                        transform: isSelected ? "scale(1.1)" : "scale(1)",
                      }}
                    >
                      <Text fontSize="xsmall" className="font-semibold">
                        {!duration
                          ? `${dayjs.utc(plannedStartTime).format("H:mm A")}`
                          : `${dayjs.utc(plannedStartTime).format("H:mm A")} -
                        ${dayjs
                          .utc(plannedStartTime)
                          .add(duration, "minute")
                          .format("H:mm A")}`}
                      </Text>
                      {!!duration && (
                        <Text fontSize="xsmall" className="font-semibold">
                          {translate("schedule.texts.duration", {
                            duration,
                          })}
                        </Text>
                      )}
                      <Text fontSize="xsmall">{ref}</Text>
                      <Text fontSize="xsmall">
                        {truncateString(type ?? "", 15)}
                      </Text>
                      <Text fontSize="xsmall">
                        {formatPostcode(addressPostcode)}
                      </Text>
                      <Text fontSize="xsmall">{status}</Text>
                    </Box>
                  </UnstyledButton>
                  <UnstyledButton
                    type="button"
                    styles={{
                      visibility:
                        (!!statusId && statusId < 8) || !statusId
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      setIsModalOpen(true);
                      setActiveJob(job);
                    }}
                  >
                    <Text
                      fontSize="xsmall"
                      color="negative"
                      styles={{
                        textDecoration: "underline",
                        textUnderlineOffset: theme.spacing.xsmall2,
                      }}
                    >
                      {translate("schedule.buttons.unassign")}
                    </Text>
                  </UnstyledButton>
                </Box>
              );
            })}
        </Box>
      )}
      {/* NO DATA */}
      {!data?.length && !isLoading && !isError && (
        <Box className="flex justify-center items-center h-40">
          <Text>{translate("schedule.errors.noJobsScheduled")}</Text>
        </Box>
      )}
      {/* DISPLAY ERROR */}
      {isError && (
        <Box className="flex justify-center items-center h-40">
          <Text color="negative">
            {translate("schedule.errors.getJobsError")}
          </Text>
        </Box>
      )}
      {/* UNASSIGN JOB CONFIRMATION MODAL */}
      {isModalOpen && !!activeJob && (
        <Modal
          width={400}
          onClose={() => {
            setIsModalOpen(false);
            setActiveJob(null);
          }}
          styles={{
            textAlign: "center",
            padding: theme.spacing.xlarge2,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: theme.spacing.xlarge2,
            },
          }}
        >
          <Box className="w-full flex flex-col gap-y-5">
            <Heading color="brand" level={4}>
              {translate("schedule.texts.areYouSure", {
                ref: activeJob.ref,
              })}
            </Heading>
            <Text fontSize="small">
              {translate("schedule.texts.ifYes")}
              <strong>{translate("schedule.texts.unscheduledJobs")}</strong>
            </Text>
            <Box className="flex justify-center gap-x-2">
              <Button
                type="button"
                size="small"
                color="negative"
                onClick={() => {
                  setIsModalOpen(false);
                  setActiveJob(null);
                }}
              >
                {translate("schedule.buttons.cancel")}
              </Button>
              <Button
                type="button"
                size="small"
                color="warning"
                onClick={() => {
                  onClickUnassign(activeJob);
                  setIsModalOpen(false);
                  setActiveJob(null);
                }}
              >
                {translate("schedule.buttons.confirm")}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
