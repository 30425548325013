import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  active: Yup.boolean(),
  wasteManagementLicense: Yup.string(),
  landfillDiversionRate: Yup.string(),
  permitNumber: Yup.string(),
  group: Yup.string(),
  companyCode: Yup.string(),
  address: stringRequired,
  city: Yup.string(),
  postcode: stringRequired,
  rate: Yup.number(),
  notes: Yup.string(),
  siteCode: Yup.string(),
});
