import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Box,
  Heading,
  Text,
  theme,
  Button,
  Modal,
} from "@clearabee/ui-library";

dayjs.extend(utc);

interface CreateScheduleModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  dates: string[];
  onConfirm: () => void;
}

export const CreateScheduleModal = ({
  dates,
  onConfirm,
  showModal,
  setShowModal,
}: CreateScheduleModalProps): React.ReactElement => {
  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Box className="flex flex-col gap-3">
            <Heading color="brand" level={4}>
              Are you sure?
            </Heading>
            <Text>
              This will create the schedule for a desired date. Be wary that any
              existing schedule for the chosen date will be completely
              overwritten.
            </Text>
            <Box className="flex flex-col gap-3 mb-3">
              <Text>You are about to create the schedule for:</Text>
              <Text className="font-semibold">
                {dates
                  .map((date) => dayjs.utc(date).format("DD/MM/YYYY"))
                  .join(", ")}
              </Text>
            </Box>
            <Box className="flex justify-center">
              <Button onClick={onConfirm} size="small">
                Okay
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
