import React from "react";
import ReactDOM from "react-dom";
import { theme } from "../../theme";
import { Color, styles } from "./Modal.styles";
import { Heading } from "../../Core/Heading/Heading";
import { Icon } from "../../Core/Icon/Icon";
import { Link } from "../../Core/Link/Link";
import { UserStylesProps } from "../../utils/types";

export interface ModalProps extends UserStylesProps {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  modalVisible?: boolean;
  color?: Color;
  width?: number;
  closeButtonStyles?: React.CSSProperties;
  onClose?: () => void;
}

export const Modal = ({
  heading,
  subheading,
  modalVisible = true, //required by the useModal hook
  children,
  actions,
  color = "light",
  width = 300,
  styles: userStyles,
  closeButtonStyles,
  onClose,
}: ModalProps): React.ReactElement => {
  if (!modalVisible) return <></>;

  return ReactDOM.createPortal(
    <div
      css={[
        styles.modal,
        { ":after": styles[color] },
        { width: `${width}px` },
        userStyles,
      ]}
    >
      {onClose && (
        <Link onClick={onClose} css={{ ...styles.close, ...closeButtonStyles }}>
          <Icon.Close size="small" />
        </Link>
      )}

      {heading && (
        <Heading level={2} styles={{ color: theme.colors.brand.base }}>
          {heading}
        </Heading>
      )}
      {subheading && (
        <Heading level={4} styles={{ color: theme.colors.brand.base }}>
          {subheading}
        </Heading>
      )}
      {children}
      {actions && (
        <div css={styles.actions}>
          {(Array.isArray(actions) ? actions : [actions]).map(
            (action, index) => (
              <div key={index} css={styles.actionChild}>
                {action}
              </div>
            ),
          )}
        </div>
      )}
    </div>,
    document.body,
  );
};
