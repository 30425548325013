import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { IReportSchedule } from "@clearabee/api-schemas";
import {
  Box,
  Field,
  Input,
  Panel,
  Form,
  Button,
  Table,
  Text,
  usePagination,
  Heading,
} from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { LoadingOverlay } from "components/common/components";
import { ScheduledReport } from "./components";

const initialValues = {
  name: "",
  status: "",
  range: "",
};

const rangeOptions = [
  {
    label: "Daily",
    value: "-1D",
  },
  {
    label: "Last 7 days",
    value: "-7D",
  },
  {
    label: "Last Week",
    value: "-1W",
  },
  {
    label: "Last 30 days",
    value: "-30D",
  },
  {
    label: "Last Month",
    value: "-1M",
  },
];

interface QueryParamsInterface {
  name: string | null;
  status: string | null;
  range: string | null;
}

export const ScheduledReports = (): React.ReactElement => {
  const [translate] = useTranslation("reporting");
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const [reportModalIsVisible, setReportModalIsVisible] = useState(false);
  const [params, setParams] = useState<QueryParamsInterface>();
  const [offsetQuery, setOffsetQuery] = useState(0);
  const [limitQuery, setLimitQuery] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedReport, setSelectedReport] = useState<IReportSchedule>();

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ["getAllReports", params, offsetQuery, limitQuery],
    async () => {
      return (
        await instance.reports.getScheduledReports({
          params: {
            ...(!!params?.name && {
              "name:like": `%${decodeURIComponent(params?.name)}%`,
            }),
            ...(!!params?.status && { "active:eq": params?.status }),
            ...(!!params?.range && {
              "range:eq": params?.range,
            }),
            orderByDesc: "createdAt",
            limit: limitQuery,
            offset: offsetQuery,
          },
        })
      ).data;
    },
    {
      onSuccess: (data) => {
        setTotalPages(
          Math.ceil(data.pagination.total / paginationState.resultsPerPage),
        );
      },
    },
  );

  const {
    PaginationComponent,
    paginatedData,
    paginationState,
    dataRange,
    setDataRange,
  } = usePagination(data?.items ?? [], {
    totalPages: totalPages,
  });

  useEffect(() => {
    !!data &&
      setTotalPages(
        Math.ceil(data.pagination.total / paginationState.resultsPerPage),
      );
    setLimitQuery(paginationState.resultsPerPage);
    setOffsetQuery(dataRange[0]);
  }, [paginationState]);

  return (
    <>
      {(isLoading || isRefetching) && <LoadingOverlay />}
      <Panel>
        <Form
          initialValues={initialValues}
          onReset={async () => {
            setParams(initialValues);
            await refetch();
          }}
          onSubmit={(values) => {
            setDataRange([0, limitQuery]);
            setOffsetQuery(0);
            setParams(values);
          }}
        >
          <Box className="flex justify-end">
            <Button
              type="button"
              onClick={() => {
                setSelectedReport(undefined);
                setReportModalIsVisible(true);
              }}
              size="small"
              color="accent"
            >
              {translate("scheduleReports.buttons.scheduleReport")}
            </Button>
          </Box>
          <Box className="flex items-center gap-x-3  w-full flex-1">
            <Box className="flex-grow">
              <Field
                name="name"
                className="flex-1 min-w-full"
                label={translate("scheduleReports.labels.name")}
              >
                {({ field }) => (
                  <Input.Text {...field} placeholder="Search by name" />
                )}
              </Field>
            </Box>
            <Box className="min-w-40">
              <Field
                name="range"
                label={translate("scheduleReports.labels.range")}
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isClearable
                    placeholder="Select range"
                    options={rangeOptions}
                  />
                )}
              </Field>
            </Box>
            <Box className="min-w-40">
              <Field
                name="status"
                label={translate("scheduleReports.labels.status")}
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isClearable
                    placeholder="Select status"
                    options={[
                      {
                        label: translate("scheduleReports.labels.active"),
                        value: "1",
                      },
                      {
                        label: translate("scheduleReports.labels.paused"),
                        value: "0",
                      },
                    ]}
                  />
                )}
              </Field>
            </Box>

            <Box className="pt-6 flex gap-2 items-center">
              <Button size="small">
                {translate("scheduleReports.buttons.submit")}
              </Button>
              <Button size="small" type="reset" color="negative">
                {translate("scheduleReports.buttons.reset")}
              </Button>
            </Box>
          </Box>
        </Form>
      </Panel>
      {!!paginatedData.length || isLoading || isRefetching ? (
        <Box>
          <Table
            className="mt-10"
            styles={{
              tableLayout: "fixed",
              "th:last-of-type > p": {
                textAlign: "center",
              },
            }}
          >
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: isClearabeeAdmin ? "25%" : "40%" }} />
              <col style={{ width: "10%" }} />
              {isClearabeeAdmin && <col style={{ width: "25%" }} />}
              <col style={{ width: "10%" }} />
            </colgroup>
            <Table.Header
              fontSize="small"
              headings={[
                translate("scheduleReports.table.headings.active"),
                translate("scheduleReports.table.headings.name"),
                translate("scheduleReports.table.headings.receipients"),
                translate("scheduleReports.table.headings.range"),
                isClearabeeAdmin &&
                  translate("scheduleReports.table.headings.user"),
                translate("scheduleReports.table.headings.actions"),
              ].filter((heading) => !!heading)}
              styles={{ textAlign: "center" }}
            />
            <Table.Body>
              {paginatedData.map((report, index) => {
                const receipientsList = report.receipients
                  .map((receipient) => receipient)
                  .join(", ");
                return (
                  <Table.Row key={index}>
                    <Table.Cell.Text>
                      <Box className="w-1/2 px-2">
                        {!!report.active ? (
                          <Box
                            className="rounded-3xl flex font-semibold py-2 justify-center"
                            backgroundColor="positive"
                            color="light"
                          >
                            <Text fontSize="xsmall">
                              {translate("scheduleReports.labels.active")}
                            </Text>
                          </Box>
                        ) : (
                          <Box
                            className="rounded-3xl flex font-semibold py-2 justify-center"
                            backgroundColor="warning"
                          >
                            <Text fontSize="xsmall">
                              {translate("scheduleReports.labels.paused")}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Table.Cell.Text>
                    <Table.Cell.Text>{report.name}</Table.Cell.Text>
                    <Table.Cell.Text>
                      {receipientsList.length > 80
                        ? receipientsList.slice(0, 80) + "..."
                        : receipientsList}
                    </Table.Cell.Text>
                    <Table.Cell.Text>
                      {
                        rangeOptions.find(
                          ({ value }) => value === report?.range,
                        )?.label
                      }
                    </Table.Cell.Text>
                    {isClearabeeAdmin && (
                      <Table.Cell.Text>{report.userEmail}</Table.Cell.Text>
                    )}
                    <Table.Cell>
                      <Box className="flex justify-center gap-x-2">
                        <Button
                          size="xsmall"
                          type="button"
                          className="text-center"
                          onClick={() => {
                            setSelectedReport(report);
                            setReportModalIsVisible(true);
                          }}
                        >
                          {translate("scheduleReports.buttons.edit")}
                        </Button>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Box className="mt-10 flex justify-center">
            <PaginationComponent />
          </Box>
        </Box>
      ) : (
        <Box className="mt-16 text-center">
          <Heading level={3} color="brand">
            {translate("scheduleReports.labels.noReports")}
          </Heading>
        </Box>
      )}
      <ScheduledReport
        isVisible={reportModalIsVisible}
        report={selectedReport}
        onClose={() => setReportModalIsVisible(false)}
        onSubmit={() => {
          refetch();
        }}
      />
    </>
  );
};
