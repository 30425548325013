import type { IWorksheetPatchBody } from "@clearabee/api-schemas";
import type { TQuestion, AllowedStatuses } from "../components";
import type { InitialValues } from "./updateWorksheetInitialValues";

/**
 * Map of allowed BC status-codes to their BC status-names
 */
export const statusMap: Record<number, AllowedStatuses> = {
  8: "On the way",
  10: "Started",
  12: "Completed",
  13: "Failed",
  11: "Suspended",
};

/**
 * Remove orphaned questions:
 * questions for which there is no other question with an id that matches its parent-id
 */
export const removeOrphans = (questions: TQuestion[]): TQuestion[] => {
  const questionIds = questions.map((q) => q.id as number);

  return questions.filter(
    (question) =>
      !question.parentId || questionIds.includes(question.parentId as number),
  );
};

/**
 * Traverse the question tree, and return the parent-question if found
 */
const getParent = (
  parentId: number,
  parents: TQuestion[],
): TQuestion | undefined => {
  const arr = [...parents]; // L1 parents

  while (arr.length) {
    const question = arr.shift();
    arr.push(...(question?.children ? question.children : []));

    if (question?.id && question.id === parentId) {
      return question;
    }
  }
};

const addChild = (parent: TQuestion, child: TQuestion): void => {
  parent.children = [...(parent.children ? parent.children : []), child];
};

/**
 * Take a flat array of questions, and create nested questions,
 * with parents and their children as a tree.
 */
export const createConditionalQuestions = (
  questions: TQuestion[],
): TQuestion[] => {
  // Deep clone is required, or it causes pass-by-reference issues when called repeatedly with the same object,
  // resulting in duplication of child questions.
  const deepClone = JSON.parse(JSON.stringify(questions)) as TQuestion[];
  const cleanedQuestions = removeOrphans(deepClone);

  const conditionalQuestions: TQuestion[] = [];

  // While loop required to account for parents and children not being in order.
  // e.g. If a child comes before a parent in the array,
  // it will take at least one pass before the parent is available.

  while (cleanedQuestions.length) {
    cleanedQuestions.forEach((question, index) => {
      if (!question.parentId) {
        // Add top-level questions, those without a parentId property
        conditionalQuestions.push(question);
      } else {
        // Add children to their parent
        const parent = getParent(question.parentId, conditionalQuestions);

        // Return here, as orphaned questions have been removed, which means the parent exists,
        // and will be found on a subsequent pass.
        if (!parent) return;

        addChild(parent, question);
      }

      cleanedQuestions.splice(index, 1); // Remove from the array
    });
  }

  // Sort parent-questions by postion
  sortQuestionsByDisplayConditionAndPosition(conditionalQuestions);

  return conditionalQuestions;
};

/**
 * Given an array of questions, sort by displayCondition first and then by position.\
 * **This mutates the original array, so must be used with caution.**
 *
 * @param questions Array of questions, either flat or as a tree
 */
export const sortQuestionsByDisplayConditionAndPosition = (
  questions: TQuestion[],
): TQuestion[] => {
  questions.sort(
    (
      { displayCondition: displayConditionA, position: positionA, type: typeA },
      { displayCondition: displayConditionB, position: positionB, type: typeB },
    ) => {
      // Move questions with type 'modal' to the top, as they are displayed first in the Drivers App
      if (typeA?.name === "modal" && typeB?.name !== "modal") {
        return -1;
      }

      // Sort next by displayCondition
      const displayConditionComparision = (displayConditionA ?? "")
        .toLowerCase()
        .localeCompare(displayConditionB?.toLowerCase() ?? "");

      if (displayConditionComparision !== 0) return displayConditionComparision;

      // If the displayCondition is the same, sort by position
      return (positionA ?? Number.MAX_VALUE) - (positionB ?? Number.MAX_VALUE); // If position is undefined, move to the end of the array
    },
  );

  questions.forEach(({ children }) => {
    if (children?.length) {
      sortQuestionsByDisplayConditionAndPosition(children);
    }
  });

  return questions;
};

export const getPatchBody = (values: InitialValues): IWorksheetPatchBody => {
  // For optional fields, return null or empty array, so it removes any existing values in the db
  return {
    name: values.name,
    includeForSnsEvents: values.includeForSnsEvents,
    showForSubcontractor: values.showForSubcontractor,
    statusId: !!values.statusId ? Number(values.statusId) : null, // Input returns string, so needs parsing
    orderTypeWorksheetIds: values.orderTypeIds
      ? values.orderTypeIds.map((orderTypeId) => {
          return { orderTypeId };
        })
      : [],
  };
};
