import { theme } from "@clearabee/ui-library-base";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressLookup } from "../../../core";
import { Input } from "./components/input";

/**
 * Import components.
 */

interface IPostcodeManualAddress {
  fieldNamePrefix?: string;
  fieldName?: string;
  menuPlacement?: "top" | "bottom";
}

export const PostcodeManualAddress: React.FC<IPostcodeManualAddress> = ({
  fieldNamePrefix = "address.",
  fieldName = "address",
  menuPlacement = "top",
}) => {
  const [manualAddressVisible, showManualAddress] = useState(false);
  const [translate] = useTranslation("common");

  const { setFieldValue } = useFormikContext();

  return (
    <div className="w-full flex flex-wrap mt-1 mb-2">
      {manualAddressVisible ? (
        <>
          <Input
            name={`${fieldNamePrefix}line1`}
            label={translate("form.labels.addressLine1")}
            placeholder={translate("form.placeholders.addressLine1")}
          />
          <Input
            name={`${fieldNamePrefix}line2`}
            label={translate("form.labels.addressLine2")}
            placeholder={translate("form.placeholders.addressLine2")}
          />
          <Input
            name={`${fieldNamePrefix}city`}
            label={translate("form.labels.city")}
            placeholder={translate("form.placeholders.city")}
          />
          <Input
            name={`${fieldNamePrefix}county`}
            label={translate("form.labels.county")}
            placeholder={translate("form.placeholders.county")}
          />
          <Input
            name={`${fieldNamePrefix}postcode`}
            label={translate("form.labels.postcode")}
            placeholder={translate("form.placeholders.postcode")}
          />
          <button
            type="button"
            className="text-xs text-center mx-auto text-primary font-semibold border-b-2 border-primary"
            onClick={() => showManualAddress(false)}
          >
            {translate("postcodeLookup.switchToLookup")}
          </button>
          <div className="flex w-full flex-col mt-4">
            <Input
              label={translate("form.labels.what3Words")}
              name="meta.what3words"
              className="w-full mb-3"
              placeholder={translate("form.placeholders.what3Words")}
            />
            <div className="flex text-xs px-40 text-center font-semibold items-center flex-col">
              {translate("titles.find3Words")}
              <a
                className="text-center mx-auto text-primary border-b-2 border-primary"
                href="https://what3words.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("buttonLabels.find3Words")}
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <AddressLookup
            // eslint-disable-next-line
            updateAddress={(value: any) => {
              setFieldValue(fieldName, value);
            }}
            postcodeField={{
              containerClasses: "w-full mb-6",
            }}
            addressDropdown={{
              containerClasses: "w-full mb-3",
              fieldClasses: "larger-dropdown drop-right",
            }}
            menuPlacement={menuPlacement}
          />
          <button
            type="button"
            className="text-xs text-center mx-auto text-primary font-semibold border-b-2 border-primary"
            onClick={() => showManualAddress(true)}
          >
            <p style={{ color: theme.colors.greyscale.base }}>
              {translate("postcodeLookup.cantFind")}
            </p>
            {translate("postcodeLookup.switchToManual")}
          </button>
          <div className="flex w-full flex-col mt-4">
            <Input
              label={translate("form.labels.what3Words")}
              name="meta.what3words"
              className="w-full mb-3"
              placeholder={translate("form.placeholders.what3Words")}
            />
            <div className="flex text-xs px-40 text-center font-semibold items-center flex-col">
              {translate("titles.find3Words")}
              <a
                className="text-center mx-auto text-primary border-b-2 border-primary"
                href="https://what3words.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("buttonLabels.find3Words")}
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
