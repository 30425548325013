import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { toasts } from "helpers";
import { instance } from "@clearabee/ui-sdk";
import { IBasket, IBasketInvoicePostBody } from "@clearabee/api-schemas";
import {
  Modal,
  theme,
  Button,
  Box,
  Icon,
  Text,
  Heading,
  UnstyledButton,
  Input,
  Form,
  Field,
} from "@clearabee/ui-library";
import { EditJobDetailsModal } from "./index";
import { LoadingOverlay } from "components/common/components";

interface JobDetailsModalProps {
  jobData: IBasket & {
    orderRef: string;
  };
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

interface PostBasketInvoiceProps {
  token: string;
  body: IBasketInvoicePostBody;
}

export const JobDetailsModal = ({
  jobData,
  visible = false,
  onClose,
  onSuccess,
}: JobDetailsModalProps): React.ReactElement => {
  const [t] = useTranslation("jobs");
  const [error, setError] = useState("");
  const [bookAgain, setBookAgain] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [isUpdateJobDetails, setIsUpdateJobDetails] = useState(false);
  const isExpired = dayjs(jobData.expiryDate).isBefore(dayjs());
  const { doesUserHaveRole } = useAuthContext();
  const isCompanyAssistant = doesUserHaveRole(roles.COMPANY_ASSISTANT);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);
  const isCompanyStaff = doesUserHaveRole(roles.COMPANY_STAFF);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    ({ token, body }: PostBasketInvoiceProps) =>
      instance.catalogues.postBasketInvoice(token, body),
    {
      onSuccess: () => {
        // only patch the basket if it's expired
        if (isExpired) {
          patchBasket();
        }
        onSuccess?.();
      },
      onError: (error: Error) => {
        setError(error.message);
      },
    },
  );

  const { mutate: patchBasket } = useMutation(["rebookedJob"], () =>
    instance.catalogues.patchBasket(jobData.basketToken, {
      status: "rebooked",
    }),
  );

  const {
    data: catalogueItems,
    isLoading: catalogueItemsIsLoading,
    isError: catalogueItemsIsError,
  } = useQuery(
    ["getCatalogueItems", jobData],
    async () => {
      return await Promise.all(
        jobData.items.map(
          async (item) =>
            (
              await instance.catalogues.getCatalogueItem(
                jobData.catalogueId,
                item.sku,
              )
            ).data,
        ),
      );
    },
    {
      onError: (error: Error) => {
        setError(error.message);
        setBookAgain(false);
      },
      enabled: bookAgain,
    },
  );

  const {
    mutate: postBasket,
    data: postBasketData,
    isLoading: postBasketIsLoading,
    isSuccess: postBasketIsSuccess,
  } = useMutation(
    async (date: string) => {
      const { data: basket } = await instance.catalogues.postBasket({
        catalogueId: jobData.catalogueId,
        items: jobData.items,
        companyCode: jobData.companyCode,
        deliveryAddress: jobData.deliveryAddress,
        date: dayjs.utc(date, "D/M/YYYY").toISOString(),
        description: jobData.description,
        accessInformation: jobData.accessInformation,
        contact: jobData.contact,
        meta: jobData.meta,
        customFields: jobData.customFields,
      });

      return basket;
    },
    {
      onSuccess: (data) => {
        mutate({
          token: data.basketToken,
          body: {
            ...data,
            purchaseOrderNumber: data.meta?.poNumber ?? "",
          },
        });
      },
    },
  );

  const blackoutDays = catalogueItems
    ?.map(({ blackoutDays }) => blackoutDays)
    .flat();
  const filteredBlackoutDays = blackoutDays?.filter(
    (day): day is number => day !== null && day !== undefined,
  );
  const blackoutDates = catalogueItems
    ?.map(({ blackoutDates }) => blackoutDates)
    .flat();
  const filteredBlackoutDates = blackoutDates?.filter(
    (day): day is string => day !== null && day !== undefined,
  );

  /**
   * Only company assistants, admins and staff can edit a job basket
   */
  const canEditJob = isCompanyAssistant || isCompanyAdmin || isCompanyStaff;

  return (
    <>
      <Modal
        width={600}
        styles={{
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.small} ${theme.spacing.large}`,
            maxHeight: "95vh",
          },
        }}
        modalVisible={visible}
      >
        {isLoading && (
          <LoadingOverlay
            iconSize="xlarge2"
            backgroundLoadingContainerStyles={{
              borderRadius: theme.spacing.small,
            }}
          />
        )}
        <Box
          styles={{
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <Box
            className={`flex ${
              isExpired ? "justify-between" : "justify-end"
            } mb-5`}
          >
            {isExpired && (
              <Box
                backgroundColor="negative"
                color="light"
                className="px-4 py-px opacity-60"
                styles={{
                  borderRadius: theme.spacing.medium,
                }}
              >
                {t("buttons.labels.expired")}
              </Box>
            )}
            <UnstyledButton
              onClick={() => {
                if (isSuccess) onSuccess?.();
                onClose?.();
                setError("");
              }}
            >
              <Icon.Close size="small" color="dark.base" />
            </UnstyledButton>
          </Box>
          <Box className="flex items-start flex-col gap-3 mb-2">
            {!!jobData.orderReference && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  {t("form.label.reference")}
                </Heading>
                <Text>{jobData.orderReference}</Text>
              </Box>
            )}
            <Box className="flex flex-col items-start">
              <Heading level={5} color="brand">
                {t("form.label.date")}
              </Heading>
              <Text>{dayjs(jobData.date).format("DD/MM/YYYY")}</Text>
            </Box>
            {(!!jobData.contact?.firstName || !!jobData.contact?.lastName) && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  {t("form.label.contactName")}
                </Heading>
                <Text>{`${jobData.contact?.firstName} ${jobData.contact?.lastName}`}</Text>
              </Box>
            )}
            {!!jobData.contact?.email && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  {t("form.label.contactEmail")}
                </Heading>
                <Text>{jobData.contact?.email}</Text>
              </Box>
            )}
            {!!jobData.contact?.phoneNumber && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  {t("form.label.phone")}
                </Heading>
                <Text>{jobData.contact?.phoneNumber}</Text>
              </Box>
            )}
            {!!jobData.description && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  {t("table.headings.description")}
                </Heading>
                <Text>{jobData.description}</Text>
              </Box>
            )}
            <Box className="flex flex-col items-start">
              <Heading level={5} color="brand">
                {t("form.label.deliveryAddress")}
              </Heading>
              <Text>{jobData.deliveryAddress?.line1}</Text>
              <Text>{jobData.deliveryAddress?.line2}</Text>
              <Text>{jobData.deliveryAddress?.city}</Text>
              <Text>{jobData.deliveryAddress?.county}</Text>
              <Text>{jobData.deliveryAddress?.postcode}</Text>
            </Box>
            {jobData.items.length > 0 && (
              <Box className="flex flex-col items-start">
                <Heading level={5} color="brand">
                  Items
                </Heading>
                {jobData.items.map(({ title, qty }) => (
                  <Text key={`${qty} x ${title}`}>{`${qty} x ${title}`}</Text>
                ))}
              </Box>
            )}
            <Box className="flex flex-col items-start">
              <Heading level={5} color="brand">
                {t("table.headings.totalCost")}
              </Heading>
              <Text>{`£${jobData.totalCost ?? 0}`}</Text>
            </Box>
            {bookAgain && (
              <Form
                styles={{
                  width: "100%",
                }}
                initialValues={{
                  date: "",
                }}
                onSubmit={(values) => postBasket(values?.date)}
              >
                {({ values }) => (
                  <>
                    {!catalogueItemsIsError && !isSuccess && (
                      <Box className="flex flex-col">
                        <Box className="flex justify-start">
                          <Heading level={5} color="brand">
                            {t("form.label.newBookingDate")}
                          </Heading>
                        </Box>
                        <Box
                          className="mt-1"
                          styles={{
                            borderTop: `1px solid ${theme.colors.greyscale.lightest}`,
                          }}
                        />
                        {catalogueItemsIsLoading ? (
                          <Box className="flex justify-center my-5">
                            <Icon.Loading />
                          </Box>
                        ) : (
                          <Field name="date">
                            {({ field }) => (
                              <Input.Date
                                {...field}
                                placeholder="Select a date..."
                                multiMonth
                                disabledDays={(day) =>
                                  filteredBlackoutDates?.some(
                                    (date) =>
                                      dayjs(date).format("YYYY-MM-DD") ===
                                      dayjs(day).format("YYYY-MM-DD"),
                                  ) ||
                                  day.getDay() === 0 ||
                                  filteredBlackoutDays?.includes(day.getDay())
                                }
                              />
                            )}
                          </Field>
                        )}
                      </Box>
                    )}
                    {!isSuccess && (
                      <Box className="flex justify-center">
                        <Button
                          type="submit"
                          disabled={
                            isLoading ||
                            postBasketIsLoading ||
                            catalogueItemsIsLoading ||
                            !values.date ||
                            postBasketIsSuccess
                          }
                          size="small"
                          className="flex justify-center"
                        >
                          {isLoading || postBasketIsLoading ? (
                            <Icon.Loading size="small" />
                          ) : (
                            t("buttons.labels.book")
                          )}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Form>
            )}
          </Box>
          <Box className="flex items-center justify-center gap-x-2">
            {/* EDIT */}
            {!isExpired && canEditJob && !isSuccess && (
              <Button
                onClick={() => setEditJob(true)}
                className="flex justify-center"
                size="small"
              >
                {t("buttons.labels.edit")}
              </Button>
            )}
            {/* BOOK AGAIN */}
            {!bookAgain && !isCompanyAssistant && isExpired && (
              <Button onClick={() => setBookAgain(true)} size="small">
                {t("update.buttons.bookAgain")}
              </Button>
            )}
            {/* APPROVE */}
            {!isExpired && !isCompanyAssistant && (
              <Button
                disabled={isSuccess}
                onClick={() =>
                  mutate({
                    token: jobData.basketToken,
                    body: {
                      ...jobData,
                      purchaseOrderNumber:
                        jobData.meta.purchaseOrderNumber ?? "",
                    },
                  })
                }
                color="brand"
                className="flex justify-center items-center gap-x-2"
                size="small"
              >
                {isLoading && <Icon.Loading size="small" />}
                {!isLoading && !isExpired && t("buttons.labels.approve")}
              </Button>
            )}
          </Box>
          {isSuccess && (
            <Box className="flex justify-center">
              <Box
                backgroundColor="positive.base"
                color="light"
                className="px-2 py-px mt-2 rounded"
              >
                <Text fontSize="small">
                  {isExpired
                    ? t("messages.jobApprovedWhenExpired", {
                        ref: postBasketData?.orderReference,
                      })
                    : t("messages.jobApprovedWhenNotExpired")}
                </Text>
              </Box>
            </Box>
          )}
          {(isError || catalogueItemsIsError) && !!error && (
            <Box className="flex justify-center">
              <Box
                backgroundColor="negative.base"
                color="light"
                className="px-2 py-px mt-2 rounded"
              >
                <Text fontSize="small">{error}</Text>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      {editJob && (
        <EditJobDetailsModal
          setIsUpdateJobDetails={setIsUpdateJobDetails}
          jobData={jobData}
          onClose={() => setEditJob(false)}
          onSuccess={() => {
            onSuccess?.();
            setEditJob(false);
            toasts.success({
              message: t("toasts.basketUpdated"),
            });
          }}
        />
      )}
    </>
  );
};
