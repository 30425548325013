import React from "react";
import { Box, Text, Panel, theme } from "@clearabee/ui-library";

interface VehicleRowProps {
  vehicleName: string;
  vehicleReg: string;
  offTheRoad?: boolean;
  children?: React.ReactNode;
}

export const VehicleRow = ({
  vehicleName,
  children,
  offTheRoad,
  vehicleReg,
}: VehicleRowProps): React.ReactElement => {
  return (
    <Panel
      shadow={false}
      styles={{
        padding: "16px",
        border: offTheRoad ? `2px solid ${theme.colors.negative.light}` : "",
      }}
      className="flex flex-col"
      key={`${vehicleName} - vehicle row`}
    >
      <Box className="mb-6">
        <Text fontSize="xlarge" color="brand" className="font-bold">
          {vehicleName}
        </Text>
        <Text fontSize="xsmall" color="brand" className="font-bold">
          {vehicleReg}
        </Text>
        {offTheRoad && (
          <Text className="font-bold" fontSize="small" color="negative">
            OTR
          </Text>
        )}
      </Box>

      <Box className="flex flex-row gap-3">{children}</Box>
    </Panel>
  );
};
