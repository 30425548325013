import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import { instance, stringToDate } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  displayErrorMessage,
  Field,
  Form,
  Heading,
  Input,
  Message,
  Modal,
  Text,
  theme,
  UnstyledButton,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import { toasts } from "helpers/toasts";

interface UpdateCollectionDateModalProps {
  disabled: boolean;
  jobId?: string;
}

const initialValues = {
  collectionDate: "",
};

export const UpdateCollectionDateModal = ({
  disabled,
  jobId,
}: UpdateCollectionDateModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    data: jobData,
    isLoading: isLoadingJobData,
    isRefetching: isRefetchingJobData,
    refetch: refetchJob,
  } = useQuery(
    ["readJobById", jobId],
    async () => {
      if (!jobId) return;

      return (await instance.jobs.getJob(jobId)).data;
    },
    {
      enabled: !!jobId && isOpenModal,
      retry: false,
    },
  );

  const companyCode = jobData?.companyCode ?? "";
  const items =
    jobData?.items?.map((item) => {
      return {
        sku: item.sku,
        qty: item.qty,
      };
    }) ?? [];

  // get company catalouge Id
  const {
    data: companyCatalogueIdData,
    isLoading: isLoadingCompanyCatalogueIdData,
  } = useQuery(
    ["getCompanyCatalogueId", companyCode],
    async () => {
      if (!companyCode) return;

      return (await instance.catalogues.getCatalogueByCompanyCode(companyCode))
        .data["id"];
    },
    {
      enabled: !!companyCode && isOpenModal,
      retry: false,
    },
  );

  const companyCatalogueId = companyCatalogueIdData ?? "portal";

  // get blackouts
  const { data: disabledBlackouts, isLoading: isLoadingDisabledBlackouts } =
    useQuery(
      ["readItemsBlackouts", items, companyCatalogueId],
      async () => {
        const blackoutDates: any[] = [];
        const blackoutDays: any[] = [];
        const cutoffTime: any[] = [];

        for await (const item of items) {
          const blackoutItem = (
            await instance.catalogues.getCatalogueItemBlackouts(
              companyCatalogueId,
              item.sku,
            )
          ).data;

          for (const element of blackoutItem) {
            if (typeof element === "string") blackoutDates.push(element);

            if (
              typeof element === "object" &&
              element.hasOwnProperty("before")
            ) {
              cutoffTime.push(element);
            }
            if (
              typeof element === "object" &&
              element.hasOwnProperty("daysOfWeek")
            ) {
              blackoutDays.push(element);
            }
          }
        }

        const updatedBlackoutDays = blackoutDays.flatMap(
          ({ daysOfWeek }: { daysOfWeek: number[] }) => daysOfWeek,
        );

        const updatedCutoffTime = cutoffTime
          .flatMap(({ before }: { before: string }) =>
            Number(dayjs.utc(before).format("x")),
          )
          .sort((a, b) => a - b);

        const formattedCutoffTime = stringToDate(
          dayjs(updatedCutoffTime[0]).format("YYYY-MM-DD"),
        );

        return [
          ...(!!blackoutDates.length ? stringToDate(blackoutDates) : []),
          {
            daysOfWeek: !!updatedBlackoutDays.length
              ? updatedBlackoutDays
              : [0],
          },
          { before: formattedCutoffTime ?? new Date() },
        ];
      },
      {
        enabled: !!items.length && !!companyCatalogueId && isOpenModal,
        retry: false,
      },
    );

  const {
    mutate: mutateUpdateJobDate,
    isLoading: isLoadingUpdateJobDate,
    error: errorUpdateJobDate,
    isSuccess: isSuccessUpdateJobDate,
    reset: resetUpdateJobDate,
  } = useMutation(
    "updateJobDate",
    async (values: typeof initialValues) => {
      if (!jobId) return;

      await instance.jobs.patchJobDateById(jobId, {
        date: dayjs.utc(values?.collectionDate).toISOString(),
      });
    },
    {
      onSuccess: () => {
        refetchJob();
        toasts.success({ message: "Job date updated successfully" });
      },
    },
  );

  const isLoading =
    isLoadingJobData ||
    isRefetchingJobData ||
    isLoadingDisabledBlackouts ||
    isLoadingCompanyCatalogueIdData ||
    isLoadingUpdateJobDate;

  return (
    <>
      <Button
        size="xsmall"
        onClick={() => setIsOpenModal(true)}
        disabled={disabled}
      >
        {translate("buttons.labels.quickEdit")}
      </Button>
      {isOpenModal && (
        <Modal
          width={600}
          onClose={() => {
            setIsOpenModal(false);
            resetUpdateJobDate();
          }}
          styles={{
            textAlign: "left",

            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.xlarge2} ${theme.spacing.large} ${theme.spacing.xlarge2}`,
            },
          }}
        >
          <Form
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              collectionDate: Yup.string().required(
                "Collection date is required",
              ),
            })}
            onSubmit={(values) => {
              mutateUpdateJobDate(values);
            }}
          >
            {({}) => (
              <>
                {/* Loading */}
                {isLoading && (
                  <LoadingOverlay
                    backgroundLoadingContainerStyles={{
                      borderRadius: theme.spacing.small,
                    }}
                  />
                )}
                <Box className="border-b pb-2 mb-5 flex flex-row justify-between items-center">
                  <Heading
                    level={5}
                    color="brand"
                    className="pr-4 w-full sm:w-auto"
                  >
                    Update Job Date
                  </Heading>
                </Box>
                {!!jobData && (
                  <Box className="mb-10">
                    <Box className="flex gap-x-1 mb-1">
                      <Text fontSize="small" className="font-semibold">
                        Job Reference:
                      </Text>
                      <Text fontSize="small">{jobData.ref}</Text>
                    </Box>
                    <Box className="flex gap-x-1 mb-1">
                      <Text fontSize="small" className="font-semibold">
                        Status:
                      </Text>
                      <Text fontSize="small">{jobData.status}</Text>
                    </Box>
                    <Box className="flex gap-x-1 mb-1">
                      <Text fontSize="small" className="font-semibold">
                        Postcode:
                      </Text>
                      <Text fontSize="small">{jobData?.addressPostcode}</Text>
                    </Box>
                    <Box className="flex gap-x-1 mb-1">
                      <Text fontSize="small" className="font-semibold">
                        Current Collection Date:
                      </Text>
                      <Text fontSize="small">
                        {dayjs(jobData.date).format("DD/MM/YYYY")}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Field
                  name="collectionDate"
                  label={translate("headings.newCollectionDate")}
                >
                  {({ field }) => (
                    <Input.Date
                      {...field}
                      dateFormat="YYYY-MM-DD"
                      disabledDays={
                        disabledBlackouts ?? { before: dayjs().toDate() }
                      }
                      multiMonth={true}
                      firstDay={1}
                    />
                  )}
                </Field>
                <Box className="flex justify-center mt-5">
                  <Button color="warning" size="medium" type="submit">
                    {translate("buttons.labels.submit")}
                  </Button>
                </Box>
                {!!errorUpdateJobDate && (
                  <Box className="mt-5">
                    {displayErrorMessage(errorUpdateJobDate, ({ children }) => (
                      <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                        <Message type="error" background>
                          {children}
                        </Message>
                      </Box>
                    ))}
                  </Box>
                )}
                {isSuccessUpdateJobDate && !!jobData && !isLoadingJobData && (
                  <Box className="flex justify-center mt-5">
                    <UnstyledButton
                      style={{
                        fontSize: theme.spacing.small,
                        color: theme.colors.accent.dark,
                        textDecoration: "underline",
                        textUnderlineOffset: theme.spacing.xsmall,
                      }}
                      onClick={() =>
                        history.push(
                          `/jobs/update/${encodeURIComponent(
                            String(jobData.id ?? ""),
                          )}`,
                        )
                      }
                    >
                      View {jobData.ref}
                    </UnstyledButton>
                  </Box>
                )}
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
