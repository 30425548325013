import React from "react";

/**
 * Import subcomponents.
 */
import { Modal } from "../../core";

/**
 * Import images.
 */
import ManageUsers from "../../../images/manage-users.svg";

/**
 * Types.
 */
interface ISuccessModal {
  title: React.ReactNode;
  subtitle?: string;
  buttonOne: TButton;
  buttonTwo?: TButton;
  buttonThree?: TButton;
  visible: boolean;
  text?: React.ReactNode;
  hideCallback?: () => void;
}

interface IBasicButton {
  label: string;
  to?: string;
  close?: boolean;
  disabled?: boolean;
}

interface IButtonLink {
  to: string;
}

interface IButtonClose {
  close: true;
}

type TButton = IBasicButton & (IButtonLink | IButtonClose);

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SuccessModal: React.FC<ISuccessModal> = ({
  title,
  subtitle,
  buttonOne,
  buttonTwo,
  buttonThree,
  visible,
  text,
  hideCallback,
}) => {
  const buttons = [
    {
      name: "successButtonOne",
      label: buttonOne.label,
      className: "w-48 btn-primary btn-filled",
      to: buttonOne.to,
      onClick: buttonOne.close ? hideCallback : undefined,
      disabled: buttonOne.disabled,
    },
  ];

  if (buttonTwo) {
    buttons.push({
      name: "successButtonTwo",
      label: buttonTwo.label,
      className: "w-48",
      to: buttonTwo.to,
      onClick: buttonTwo.close ? hideCallback : undefined,
      disabled: buttonTwo.disabled,
    });
  }

  if (buttonThree) {
    buttons.push({
      name: "successButtonThree",
      label: buttonThree.label,
      className: "w-48 btn-secondary btn-filled",
      to: buttonThree.to,
      onClick: buttonThree.close ? hideCallback : undefined,
      disabled: buttonThree.disabled,
    });
  }

  return (
    <Modal
      visible={visible}
      title={title}
      subtitle={subtitle}
      image={ManageUsers}
      buttons={buttons}
      text={text}
    />
  );
};
