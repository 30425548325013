import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const searchForminitialValues = {
  supplierRef: "",
  sku: "",
  postcode: "",
  active: "",
};

export const addFormInitialValues = {
  supplierRef: "",
  sku: "",
  postcode: "",
  price: "",
};

export const editFormInitialValues = {
  price: "",
};

export const searchFormSchema = Yup.object().shape({
  supplierRef: Yup.string(),
  sku: Yup.string(),
  postcode: Yup.string(),
  active: Yup.string(),
});

export const addFormSchema = Yup.object().shape({
  supplierRef: stringRequired,
  sku: stringRequired,
  postcode: stringRequired,
  price: Yup.number()
    .test("price", "Number cannot be 0 or lower", (price) => price > 0)
    .required("Required"),
});

export const editFormSchema = Yup.object().shape({
  price: Yup.number()
    .test("price", "Number cannot be 0 or lower", (price) => price > 0)
    .required("Required"),
});
