import React from "react";
import { useMutation } from "react-query";
import Select, { OptionTypeBase } from "react-select";
import * as Yup from "yup";
import dayjs from "dayjs";
import { instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";
import { IVehicle } from "@clearabee/api-schemas";
import { toasts } from "helpers/toasts";
import {
  Box,
  Heading,
  Text,
  Button,
  Form,
  Field,
  Input,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { stringRequired } from "validation/common";
import { LoadingOverlay } from "components/common/components";

dayjs.extend(utc);

interface EditVehicleModalProps {
  showModal: boolean;
  refetchVehicles: () => void;
  driverOptions: OptionTypeBase[];
  tipOptions: OptionTypeBase[];
  citiesOptions: OptionTypeBase[];
  regionsOptions: OptionTypeBase[];
  selectedVehicle: IVehicle | undefined;
  showPhotos?: boolean;
  setSelectedVehicle: (value: IVehicle | undefined) => void;
  allDefaultDrivers?: number[];
}

const statusOptions = [
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const EditVehicleModal = ({
  showModal,
  refetchVehicles,
  driverOptions,
  tipOptions,
  selectedVehicle,
  setSelectedVehicle,
  citiesOptions,
  regionsOptions,
  showPhotos = true,
  allDefaultDrivers,
}: EditVehicleModalProps): React.ReactElement => {
  const { t } = useTranslation("vehicles");

  const validationSchema = Yup.object({
    tip: Yup.string(),
    slackChannelId: Yup.string(),
    defaultStartTime: Yup.string(),
    status: stringRequired,
    defaultDrivers: Yup.array(Yup.string()),
    enabled: Yup.boolean(),
    ...(!!selectedVehicle?.companyCode && {
      name: stringRequired,
    }),
    ...(!selectedVehicle?.companyCode
      ? {
          city: stringRequired,
        }
      : {
          city: Yup.string(),
        }),
    ...(!selectedVehicle?.companyCode
      ? {
          region: stringRequired,
        }
      : { region: Yup.string() }),
  });

  const initialValues = {
    name: selectedVehicle?.name || "",
    tip: selectedVehicle?.tipId || "",
    defaultStartTime: selectedVehicle?.defaultStartTime || "",
    status: selectedVehicle?.status || "",
    slackChannelId: selectedVehicle?.slackChannelId || "",
    defaultDrivers: selectedVehicle?.defaultDrivers || [],
    enabled: selectedVehicle?.enabled || false,
    city: selectedVehicle?.cityId || "",
    region: selectedVehicle?.regionId || "",
  };

  const { mutate: updateVehicle, isLoading: updateVehicleIsLoading } =
    useMutation(
      ["updateVehicle"],
      async (values: typeof initialValues) => {
        const {
          city,
          defaultDrivers,
          defaultStartTime,
          enabled,
          name,
          region,
          slackChannelId,
          tip,
          status,
        } = values;

        return (
          await instance.vehicles.patchVehicle(
            String(selectedVehicle?.id || 0),
            {
              enabled: enabled,
              defaultDrivers: defaultDrivers,
              status: status,
              ...(slackChannelId && {
                slackChannelId: slackChannelId,
              }),
              ...(!!selectedVehicle?.companyCode && {
                name: name,
              }),
              ...(defaultStartTime && {
                defaultStartTime: defaultStartTime,
              }),
              ...(tip && {
                tipId: Number(tip),
              }),
              ...(city && {
                cityId: Number(city),
              }),
              ...(region && {
                regionId: Number(region),
              }),
            },
          )
        ).data;
      },
      {
        onSuccess: (data) => {
          toasts.success({
            message: t("vehiclePreferences.toasts.success"),
          });
          refetchVehicles();
          setSelectedVehicle(data);
        },
        onError: () => {
          toasts.error({
            message: t("vehiclePreferences.toasts.error"),
          });
        },
      },
    );

  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setSelectedVehicle(undefined)}
        >
          {updateVehicleIsLoading && (
            <LoadingOverlay
              iconSize="xlarge2"
              backgroundLoadingContainerStyles={{
                borderRadius: theme.spacing.small,
              }}
            />
          )}

          <Form
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              updateVehicle(values);
            }}
            styles={{
              maxHeight: "60vh",
              textAlign: "left",
              ...(showPhotos && { overflowY: "scroll" }),
            }}
          >
            <Box className="flex flex-row items-center justify-between mt-2 mb-5">
              <Box>
                <Heading color="brand" level={2}>
                  {t("vehiclePreferences.modal.heading")}
                </Heading>
                {!!selectedVehicle?.name && (
                  <Text className="font-medium" fontSize="large">
                    ({selectedVehicle?.name})
                  </Text>
                )}
              </Box>
              <Button size="small" color="accent" type="submit">
                {t("vehiclePreferences.buttons.save")}
              </Button>
            </Box>

            {!!selectedVehicle?.companyCode && (
              <Field label={t("vehiclePreferences.modal.name")} name="name">
                {({ field }) => <Input.Text {...field} />}
              </Field>
            )}

            <Box className="flex justify-start items-center flex-row gap-3">
              <Box className="w-1/2">
                <Field
                  label={t("vehiclePreferences.modal.region")}
                  name="region"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={regionsOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
              </Box>

              <Box className="w-1/2">
                <Field label={t("vehiclePreferences.modal.city")} name="city">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={citiesOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
              </Box>
            </Box>

            <Box>
              <Box className="flex flex-row gap-6">
                <Box className="w-1/2">
                  <Field
                    label={t("vehiclePreferences.modal.slackChannelId")}
                    name="slackChannelId"
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>

                <Box className="flex flex-row w-1/2">
                  <Field
                    name="enabled"
                    renderLabel={false}
                    styles={{
                      marginBottom: "0",
                    }}
                  >
                    {({ field }) => (
                      <Box>
                        <Text fontSize="small" className="font-bold" as="label">
                          Enabled{" "}
                          <Text
                            fontSize="xsmall"
                            className="font-normal"
                            as="span"
                          >
                            {t("vehiclePreferences.modal.enabledDescription")}
                          </Text>
                        </Text>

                        <Input.Toggle
                          {...field}
                          defaultChecked={initialValues.enabled}
                          styles={{
                            marginTop: theme.spacing.xsmall,
                          }}
                        />
                      </Box>
                    )}
                  </Field>
                </Box>
              </Box>

              <Field label="Status" name="status">
                {({ field }) => (
                  <Input.Select {...field} options={statusOptions} />
                )}
              </Field>
              <Field
                label={t("vehiclePreferences.modal.preferredTip")}
                name="tip"
              >
                {({ field }) => (
                  <Input.Select
                    {...field}
                    options={tipOptions}
                    isSearchable
                    isClearable
                  />
                )}
              </Field>

              <Field
                label={t("vehiclePreferences.modal.defaultStartTime")}
                name="defaultStartTime"
              >
                {({ field }) => <Input.Text {...field} type="time" />}
              </Field>

              <Field
                label={t("vehiclePreferences.modal.defaultDrivers")}
                name="defaultDrivers"
              >
                {({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={driverOptions}
                    onChange={(options: readonly OptionTypeBase[]) => {
                      if (!options) {
                        field.onChange([]);
                        return;
                      }

                      field.onChange(options.map(({ value }) => value));
                    }}
                    isOptionDisabled={(option) =>
                      allDefaultDrivers?.includes(Number(option.value))
                    }
                    value={
                      field.value?.length
                        ? driverOptions.filter(({ value: userId }) => {
                            return field.value.includes(userId);
                          })
                        : []
                    }
                  />
                )}
              </Field>

              {showPhotos && (
                <Box className="flex flex-col mt-3">
                  <Box>
                    <Heading color="brand" level={5}>
                      {t("modal.photos")}
                    </Heading>
                    {selectedVehicle?.images ? (
                      <Box className="flex flex-col mt-3 gap-3">
                        {selectedVehicle?.images?.map((image, index) => (
                          <img
                            key={`vehicle-image-${index}`}
                            className="rounded-lg"
                            src={image}
                            alt="vehicle"
                          />
                        ))}
                      </Box>
                    ) : (
                      <Text>{t("modal.noPhotos")}</Text>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Form>
        </Modal>
      )}
    </>
  );
};
