import React from "react";
import { Box, Text, theme, Panel } from "@clearabee/ui-library";

interface JobBlockProps {
  reference?: string;
  postcode?: string;
  poolStatus?: string;
  jobStatus?: string;
  type: "both" | "only-db" | "only-bc";
  hidePoolStatus?: boolean;
}

export const JobBlock = ({
  jobStatus,
  poolStatus,
  postcode,
  reference,
  hidePoolStatus = false,
  type,
}: JobBlockProps): React.ReactElement => {
  const content = [
    { key: "Ref", value: reference?.toLocaleUpperCase() || "n/a" },
    { key: "Postcode", value: postcode?.toLocaleUpperCase() || "n/a" },
    ...(hidePoolStatus
      ? []
      : [
          {
            key: "Pool Status",
            value: poolStatus?.toLocaleLowerCase() || "n/a",
          },
        ]),
    { key: "Job Status", value: jobStatus?.toLocaleLowerCase() || "n/a" },
  ];

  return (
    <Panel
      styles={{
        border: `1px solid ${
          type === "both"
            ? theme.colors.positive.lighter
            : theme.colors.warning.lighter
        }`,
        padding: theme.spacing.small,
      }}
      shadow={false}
    >
      <Box className="flex flex-col gap-1">
        {content.map(({ key, value }, index) => (
          <Text key={index} className="font-bold" color="brand">
            {key}:{" "}
            <Text className="font-normal" color="dark" as="span">
              {value}
            </Text>
          </Text>
        ))}
      </Box>
    </Panel>
  );
};
