import * as Yup from "yup";
import {
  stringNumberOnly,
  stringRequired,
  fieldRequired,
} from "validation/common";

export const searchValidationSchema = Yup.object().shape({
  name: Yup.string(),
  code: Yup.string(),
});

export const searchInitialValues = {
  name: "",
  code: "",
};

export type SearchFormValues = typeof searchInitialValues;

export const editValidationSchema = Yup.object().shape({
  active: Yup.boolean(),
});

export const editInitialValues = {
  active: true,
};

export type EditFormValues = typeof editInitialValues;

export const createCouponValidationSchema = Yup.object().shape({
  name: stringRequired,
  code: stringRequired,
  percentageDiscount: Yup.boolean(),
  amountOff: stringNumberOnly.when("percentageDiscount", {
    is: false,
    then: stringNumberOnly.required(fieldRequired),
  }),
  percentOff: stringNumberOnly.when("percentageDiscount", {
    is: true,
    then: stringNumberOnly.required(fieldRequired),
  }),
  maxRedemptions: stringNumberOnly,
  minimumAmount: stringNumberOnly,
  applicableEmails: Yup.string(),
  applicableProducts: Yup.string(),
  firstTimeOrderOnly: Yup.boolean(),
  expiryDate: Yup.string(),
  active: Yup.boolean(),
});

export const createCouponInitialValues = {
  name: "",
  code: "",
  percentageDiscount: false,
  amountOff: "",
  percentOff: "",
  maxRedemptions: "",
  minimumAmount: "",
  applicableEmails: "",
  applicableProducts: "",
  firstTimeOrderOnly: false,
  expiryDate: "",
  active: true,
};

export type CreateCouponFormValues = typeof createCouponInitialValues;
