import React, { useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import Select, { OptionTypeBase } from "react-select";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Form,
  Heading,
  Input,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import {
  createVehicleInitialValues as initialValues,
  createVehicleValidationSchema as validationSchema,
} from "../../validation";
import { getErrorMessage, toasts } from "../../../../helpers";
import { LoadingOverlay } from "components/common/components";

interface CreateVehicleModalProps {
  isVisible: boolean;
  companiesOptions: OptionTypeBase[];
  regionsOptions: OptionTypeBase[];
  citiesOptions: OptionTypeBase[];
  tipOptions: OptionTypeBase[];
  driverOptions: OptionTypeBase[];
  onVehicleCreated: () => void;
  onClose: () => void;
}

export const CreateVehicleModal = ({
  isVisible,
  companiesOptions,
  regionsOptions,
  citiesOptions,
  tipOptions,
  driverOptions,
  onClose,
  onVehicleCreated,
}: CreateVehicleModalProps): React.ReactElement => {
  const { t } = useTranslation("vehicles");

  /**
   * Get vehicle types query
   */
  const { data: vehicleTypes, isLoading: vehicleTypesIsLoading } = useQuery(
    "getVehicleTypes",
    async () =>
      (
        await instance.vehicles.getVehicleTypes({
          params: {
            limit: 1000,
          },
        })
      ).data.items,
    {
      retry: false,
      onError: (error) => {
        toasts.error({
          message: getErrorMessage(error),
        });
      },
    },
  );

  /**
   * Vehicle types options
   */
  const vehicleTypesOptions = useMemo(() => {
    if (!vehicleTypes?.length || vehicleTypesIsLoading) return [];
    return vehicleTypes?.map(({ type, id }) => ({
      label: type,
      value: String(id),
    }));
  }, [vehicleTypes, vehicleTypesIsLoading]);

  /**
   * Create vehicle mutation
   */
  const { mutate: createVehicle, isLoading: createVehicleIsLoading } =
    useMutation(
      "createVehicle",
      async (values: typeof initialValues) => {
        return await instance.vehicles.postVehicle({
          registration: values.registration,
          vehicleTypeId: Number(values.vehicleTypeId),
          ...(!!values.name && { name: values.name }),
          ...(!!values.tip && { tip: Number(values.tip) }),
          ...(!!values.cityId && { cityId: Number(values.cityId) }),
          ...(!!values.regionId && { regionId: Number(values.regionId) }),
          ...(!!values.companyCode && { companyCode: values.companyCode }),
          ...(!!values.defaultDrivers && {
            defaultDrivers: values.defaultDrivers,
          }),
          ...(!!values.defaultDrivers?.length && {
            defaultDrivers: values.defaultDrivers,
          }),
          ...(!!values.slackChannelId && {
            slackChannelId: values.slackChannelId,
          }),
        });
      },
      {
        onSuccess: () => {
          onVehicleCreated();
        },
        onError: (error) => {
          toasts.error({
            message: getErrorMessage(error),
          });
        },
      },
    );

  return (
    <Modal
      modalVisible={isVisible}
      width={700}
      styles={{
        padding: `${theme.spacing.small} ${theme.spacing.large}`,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: theme.spacing.large,
        },
      }}
      onClose={onClose}
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createVehicle(values);
        }}
      >
        {({ Field, values, setFieldValue }) => {
          useEffect(() => {
            if (!values.companyCode) {
              setFieldValue("name", "");
            }
          }, [values.companyCode]);

          return (
            <Box className="flex flex-col flex-wrap justify-center mx-auto text-left">
              {/* MODAL LOADING OVERLAY */}
              {createVehicleIsLoading && (
                <LoadingOverlay
                  iconSize="xlarge3"
                  backgroundLoadingContainerStyles={{
                    borderRadius: theme.spacing.small,
                  }}
                />
              )}
              <Heading color="brand" level={4} className="mb-2 text-center">
                {t("modal.createVehicle")}
              </Heading>
              {/* NAME */}
              <Field
                styles={{
                  flex: 1,
                }}
                label="Name"
                name="name"
              >
                {({ field }) => (
                  <Input.Text {...field} disabled={!values.companyCode} />
                )}
              </Field>
              <Box className="flex flex-row gap-x-3">
                {/* REGISTRATION */}
                <Field
                  styles={{
                    flex: 1,
                  }}
                  label="Registration"
                  name="registration"
                >
                  {({ field }) => <Input.Text {...field} />}
                </Field>
                {/* VEHICLE TYPE */}
                <Field
                  styles={{ flex: 1 }}
                  label="Vehicle Type"
                  name="vehicleTypeId"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isLoading={vehicleTypesIsLoading}
                      options={vehicleTypesOptions}
                    />
                  )}
                </Field>
              </Box>
              <Box className="flex flex-row gap-x-3">
                {/* COMPANY CODE */}
                <Field
                  styles={{ flex: 1 }}
                  label="Company Code"
                  name="companyCode"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={companiesOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
                {/* SLACK CHANNEL ID */}
                <Field
                  styles={{ flex: 1 }}
                  label="Slack Channel ID"
                  name="slackChannelId"
                >
                  {({ field }) => <Input.Text {...field} />}
                </Field>
              </Box>
              <Box className="flex flex-row gap-x-3">
                {/* REGION */}
                <Field styles={{ flex: 1 }} label="Region" name="regionId">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={regionsOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
                {/* CITY */}
                <Field styles={{ flex: 1 }} label="City" name="cityId">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={citiesOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
              </Box>
              {/* PREFERRED TIP */}
              <Box className="flex flex-row gap-x-3">
                <Field styles={{ flex: 1 }} label="Preferred Tip" name="tip">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={tipOptions}
                      isSearchable
                      isClearable
                    />
                  )}
                </Field>
                {/* DEFAULT START TIME */}
                <Field
                  styles={{ flex: 1 }}
                  label="Default Start Time"
                  name="defaultStartTime"
                >
                  {({ field }) => <Input.Text {...field} type="time" />}
                </Field>
              </Box>
              {/* DEFAULT DRIVERS */}
              <Field label="Default Drivers" name="defaultDrivers">
                {({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={driverOptions}
                    onChange={(options: readonly OptionTypeBase[]) => {
                      if (!options) {
                        field.onChange([]);
                        return;
                      }
                      field.onChange(options.map(({ value }) => value));
                    }}
                    value={
                      field.value?.length
                        ? driverOptions.filter(({ value: userId }) => {
                            return field.value.includes(userId);
                          })
                        : []
                    }
                  />
                )}
              </Field>
              {/* SUBMIT */}
              <Box className="flex items-center mx-auto mt-3">
                <Button
                  className="flex justify-center"
                  size="medium"
                  color="accent"
                  type="submit"
                >
                  {t("buttons.create")}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Form>
    </Modal>
  );
};
