import { stringRequired, fieldRequired } from "validation/common";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  serviceId: stringRequired,
  payload: Yup.object()
    .typeError("Valid object is required")
    .required(fieldRequired),
  conditions: Yup.array().of(
    Yup.object().shape({
      key: stringRequired,
      operator: stringRequired,
      value: stringRequired,
    }),
  ),
});
