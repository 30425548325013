import * as Yup from "yup";
import { stringRequired } from "validation/common";

const includeLetters = /[a-zA-Z]/;

export const createVehicleValidationSchema = Yup.object().shape({
  name: Yup.string().when("companyCode", {
    is: (companyCode) => !!companyCode,
    then: stringRequired.matches(includeLetters, "Must include letters"),
  }),
  registration: stringRequired.max(
    8,
    "Registration number cannot be more than 8 characters",
  ),
  vehicleTypeId: stringRequired,
  companyCode: Yup.string(),
  tip: Yup.string(),
  cityId: Yup.string().when("companyCode", {
    is: (companyCode) => !companyCode,
    then: stringRequired,
  }),
  regionId: Yup.string().when("companyCode", {
    is: (companyCode) => !companyCode,
    then: stringRequired,
  }),
  defaultStartTime: Yup.string(),
  defaultDrivers: Yup.array(Yup.string()),
  slackChannelId: Yup.string(),
});

export const createVehicleInitialValues = {
  name: "",
  registration: "",
  vehicleTypeId: "",
  companyCode: "",
  tip: "",
  cityId: "",
  regionId: "",
  defaultStartTime: "",
  defaultDrivers: [],
  enabled: true,
  slackChannelId: "",
};

export const filtersValidationSchema = Yup.object().shape({
  registration: Yup.string(),
  status: Yup.string(),
  group: Yup.string(),
  company: Yup.string(),
});

export const filtersInitialValues = {
  registration: "",
  status: "",
  group: "",
  company: "",
};
