import React from "react";
import Select from "react-select";
import { useFormikContext } from "formik";
import { ItemFormValues } from "./createUpdateItemForm";
import { Box, Field, theme } from "@clearabee/ui-library";

interface EditTagOptionProps {
  values: ItemFormValues;
}

type OptionsProps = {
  label: string;
  value: string;
};

const defaultTags = {
  Sofas: "sofas",
  Mattresses: "mattresses",
  Fridges: "fridges",
  Furniture: "furniture",
  Appliances: "appliances",
  Lounge: "lounge",
  Bedroom: "bedroom",
  Office: "office",
  Kitchen: "kitchen",
  Gym: "gym",
  Mobility: "mobility",
  Toys: "toys",
  "Garden & DIY": "garden-and-diy",
  Technology: "technology",
  "Baby & Nursery": "baby-and-nursery",
  Sports: "sports",
  Pet: "pet",
  Instruments: "instruments",
  "Household Bins": "household-bins",
};

export const EditTagOption = ({
  values,
}: EditTagOptionProps): React.ReactElement => {
  const { setFieldValue } = useFormikContext<{
    tags: ItemFormValues["tags"];
  }>();

  const options = Object.entries(defaultTags).map(([label, value]) => ({
    label,
    value,
  }));

  const selectedTags =
    values.tags?.map((value) => ({
      label: Object.keys(defaultTags).find(
        (key) => defaultTags[key as keyof typeof defaultTags] === value,
      ),
      value,
    })) || [];

  return (
    <Box>
      <Field styles={{ flex: 1 }} name="tags" label="Tags">
        {({ field }) => (
          <Select
            {...field}
            value={selectedTags}
            isSearchable
            isClearable
            isMulti
            placeholder="Select tags"
            options={options}
            className="text-base"
            onChange={(options: OptionsProps[]) => {
              if (!options?.length) return setFieldValue("tags", []);

              setFieldValue(
                "tags",
                options.map((option) => option.value),
              );
            }}
            styles={{
              control: (base) => ({
                ...base,
                ...theme.fontDefaults.small,
                minHeight: theme.spacing.xlarge2,
                alignItems: "center",
                justifyContent: "center",
              }),
            }}
          />
        )}
      </Field>
    </Box>
  );
};
