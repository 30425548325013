import { StyleSheet } from "../../utils";
import { theme } from "../../theme";

export const styles = StyleSheet({
  list: {
    listStyle: "none",
    padding: 0,
    display: "flex",
    margin: `0 -${theme.spacing.xsmall2}`,
  },
  listItem: {
    padding: `0 ${theme.spacing.xsmall2}`,
  },
  listImage: {
    width: theme.spacing.xlarge5,
  },
  modal: {
    width: "auto",
    ":after": { display: "none" },
    height: "100%",
  },
  modalImage: {
    display: "block",
    maxHeight: "100%",
    width: "auto",
  },
  buttonGrid: {
    display: "flex",
    padding: `${theme.spacing.xsmall} ${theme.spacing.xsmall}`,
    borderTopLeftRadius: theme.spacing.xsmall,
    borderTopRightRadius: theme.spacing.xsmall,
    justifyContent: "space-between",
  },
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: theme.colors.light.base,

    ":disabled": {
      color: theme.colors.greyscale.lighter,
      cursor: "not-allowed",
    },
  },
  imageWrapper: {
    height: "100%",
    borderTopLeftRadius: theme.spacing.xsmall,
    borderTopRightRadius: theme.spacing.xsmall,
  },
  caption: {
    background: theme.colors.brand.base,
    color: theme.colors.light.base,
    padding: theme.spacing.xsmall,
    textAlign: "left",
  },
});
