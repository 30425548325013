import React from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toasts } from "helpers/toasts";
import { instance } from "@clearabee/ui-sdk";
import { IWorksheetPostBody } from "@clearabee/api-schemas";
import {
  Field,
  Panel,
  Icon,
  Heading,
  Button,
  Input,
  Form,
  useModal,
  OptionProps,
  Box,
  Link,
} from "@clearabee/ui-library";
import { statusMap } from "../updateWorksheet/updateWorksheetHelpers";
import { Tooltip, TooltipTrigger, TooltipContent } from "../components";
import {
  worksheetInitialValues,
  worksheetValidationSchema,
} from "./validation";
import { styles } from "./createWorksheet.styles";

export const CreateWorksheet = (): React.ReactElement => {
  const [translate] = useTranslation("worksheets");
  const [SuccessModal, showSuccessModal] = useModal();

  // STATUS OPTIONS
  const statusOptions: OptionProps[] = Object.entries(statusMap).map(
    ([statusId, status]) => ({ label: status, value: Number(statusId) }),
  );

  // GET ORDER TYPES
  const { data: orderTypes } = useQuery(
    ["getOrderTypeIDs"],
    () => instance.catalogues.getOrderTypes(),
    {
      onError: () => {
        toasts.error({
          message: translate("createWorksheets.toasts.genericError"),
        });
      },
    },
  );

  const orderTypesOptions: OptionProps[] =
    orderTypes?.data.items.flatMap(({ name, id }) => ({
      label: name,
      value: id as number, // id is returned from the API, but typed for both post and get as optional prop
    })) ?? [];

  // POST WORKSHEET
  const {
    data,
    isLoading: postWorksheetIsLoading,
    mutate,
  } = useMutation(
    async (values: IWorksheetPostBody) =>
      (await instance.worksheets.postWorksheet(values)).data,
    {
      onSuccess: () => {
        showSuccessModal(true);
      },
      onError: () => {
        toasts.error({
          message: translate("createWorksheets.toasts.postError"),
        });
      },
    },
  );

  // HANDLE SUBMIT WORKSHEET
  const handleSubmitWorksheet = (values: typeof worksheetInitialValues) => {
    const updatedValues = {
      statusId: Number(values.statusId),
      name: values.name.trim(),
      includeForSnsEvents: values.includeForSnsEvents,
      orderTypeWorksheetIds: values.orderTypeIds.map(({ _, value }) => ({
        orderTypeId: value,
      })),
      showForSubcontractor: values.showForSubcontractor,
    };

    mutate({
      ...updatedValues,
      questions: [],
    });
  };

  return (
    <>
      {/* Success Modal */}
      <SuccessModal styles={{ width: "500px" }}>
        <Box className="flex flex-col gap-3 justify-center items-center">
          <Icon.Tick color="positive" size="xlarge2" />
          <Heading level={3} color="brand">
            {translate("createWorksheets.postSuccess")}
          </Heading>
          <Link href={`/worksheets/update/${data?.id}`}>
            <Button size="small">{translate("createWorksheets.edit")}</Button>
          </Link>
          <Link href="/worksheets" underline className="font-bold">
            {translate("createWorksheets.backToView")}
          </Link>
        </Box>
      </SuccessModal>

      <Form
        initialValues={worksheetInitialValues}
        validationSchema={worksheetValidationSchema}
        onSubmit={handleSubmitWorksheet}
        validateOnChange={false}
        className="max-w-screen-2xl pt-10 mx-auto relative"
      >
        {({ isValid }) => {
          return (
            <>
              <Panel styles={styles.topPanel}>
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-between">
                  <Heading fontSize="large" color="brand">
                    {translate("headings.create")}
                  </Heading>
                  <Button
                    color="accent"
                    disabled={!isValid}
                    type="submit"
                    size="small"
                  >
                    {postWorksheetIsLoading ? (
                      <Box className="flex justify-center">
                        <Icon.Loading color="dark" size="medium" />
                      </Box>
                    ) : (
                      translate("createWorksheets.create")
                    )}
                  </Button>
                </Box>

                <Box className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-end">
                  <Field
                    styles={{ flex: 1 }}
                    name="name"
                    label={translate("createWorksheets.placeholders.name")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "createWorksheets.placeholders.name",
                        )}
                      />
                    )}
                  </Field>
                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      styles={{ flex: 1 }}
                      name="statusId"
                      label={translate(
                        "createWorksheets.placeholders.statusId",
                      )}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder={translate(
                            "createWorksheets.placeholders.statusId",
                          )}
                          options={statusOptions}
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.largeTooltipPanel}>
                          {translate("createWorksheets.tooltips.status")}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      name="includeForSnsEvents"
                      label={translate(
                        "createWorksheets.placeholders.includeForSnsEvents",
                      )}
                    >
                      {({ field }) => (
                        <Box className="flex flex-row">
                          <Input.Toggle
                            {...field}
                            placeholder={translate(
                              "createWorksheets.placeholders.includeForSnsEvents",
                            )}
                          />
                        </Box>
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "createWorksheets.tooltips.includeForSnsEvents",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      name="showForSubcontractor"
                      label={translate(
                        "createWorksheets.placeholders.showForSubcontractor",
                      )}
                    >
                      {({ field }) => (
                        <Box className="flex flex-row">
                          <Input.Toggle
                            {...field}
                            defaultChecked={
                              worksheetInitialValues.showForSubcontractor
                            }
                            placeholder={translate(
                              "createWorksheets.placeholders.showForSubcontractor",
                            )}
                          />
                        </Box>
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "createWorksheets.tooltips.showForSubcontractor",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                </Box>
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-end">
                  <Field
                    styles={{ flex: 1 }}
                    name="orderTypeIds"
                    label={translate(
                      "createWorksheets.placeholders.orderTypeIds",
                    )}
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        isSearchable
                        isClearable
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder={translate(
                          "createWorksheets.placeholders.orderTypeIds",
                        )}
                        options={orderTypesOptions}
                        className="text-base"
                      />
                    )}
                  </Field>
                </Box>
              </Panel>
            </>
          );
        }}
      </Form>
    </>
  );
};
