import * as Yup from "yup";
import {
  stringRequired,
  stringNumberOnly,
  fieldRequired,
  email,
  postcodeRegExp,
  validEmail,
  validPostcode,
  internationalPhone,
} from "validation/common";

export const initialValues = {
  companyName: "",
  tradingName: "",
  companyRegistrationNumber: "",
  vatRegistrationNumber: "",
  services: [],
  monthlyVolume: "",
  headOfficeAddress: {
    address: "",
    street: "",
    city: "",
    postcode: "",
  },
  companyEmailDomain: "",
  primaryContact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  financeContact: {
    fullName: "",
    email: "",
    phone: "",
    position: "",
  },
  signature: "",
  isSameContact: false,
  agreeConditions: false,
  hasEmailDomain: true,
};

// this regex is used to validate the email domain
const regex = /^@\S+\.\S+/gm;

export const getValidationSchema = (
  isMannualAddress: boolean,
): Yup.ObjectSchema =>
  Yup.object().shape(
    {
      companyName: stringRequired.trim().min(1),
      tradingName: Yup.string().trim().min(1),
      companyRegistrationNumber: stringNumberOnly.required(fieldRequired),
      vatRegistrationNumber: stringNumberOnly.required(fieldRequired),
      headOfficeAddress: Yup.object().shape({
        address: !isMannualAddress ? stringRequired : Yup.string(),
        street: stringRequired,
        city: stringRequired,
        postcode: Yup.string()
          .matches(postcodeRegExp, validPostcode)
          .required(fieldRequired),
      }),
      hasEmailDomain: Yup.boolean(),
      companyEmailDomain: Yup.string().when("hasEmailDomain", {
        is: true,
        then: stringRequired
          .matches(regex, "Email domain must be valid, e.g. @example.com")
          .trim(),
        otherwise: Yup.string().trim(),
      }),
      services: Yup.array().of(Yup.string()).min(1).required(fieldRequired),
      monthlyVolume: stringRequired,
      primaryContact: Yup.object().shape({
        firstName: stringRequired.trim().min(1),
        lastName: stringRequired.trim().min(1),
        email: email,
        phone: internationalPhone.required(fieldRequired),
      }),
      isSameContact: Yup.boolean(),
      financeContact: Yup.object().when("isSameContact", {
        is: false,
        then: Yup.object().shape({
          fullName: stringRequired.trim().min(1),
          position: Yup.string()
            .max(20, "Must be 20 characters or less")
            .trim(),
          email: email,
          phone: internationalPhone.required(fieldRequired),
        }),
        otherwise: Yup.object().shape({
          fullName: Yup.string().trim().min(1),
          position: Yup.string()
            .max(20, "Must be 20 characters or less")
            .trim(),
          email: Yup.string().email(validEmail).notRequired(),
          phone: internationalPhone.notRequired(),
        }),
      }),
      signature: stringRequired,
      agreeConditions: Yup.boolean().oneOf([true], "Must agree to terms"),
    },
    [
      ["hasEmailDomain", "companyEmailDomain"],
      ["isSameContact", "financeContact.fullName"],
      ["isSameContact", "financeContact.email"],
      ["isSameContact", "financeContact.phone"],
    ],
  );
