"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.worksheets = void 0;
exports.worksheets = {
    headings: {
        read: "Read Worksheets",
        create: "Create Worksheet",
        update: "Update Worksheet",
        arrange: "Arrange Worksheets",
        questions: "Worksheet Questions",
    },
    buttons: {
        add: "Add Question",
    },
    arrange: {
        noData: "There are no worksheets associated with this id",
        instruction: "Arrange worksheets by dragging them up or down",
        success: "Worksheets updated successfully",
        error: "Something went wrong, please try again",
        errorReadTypeIds: "Cannot read order type ids",
        searchButton: "Search",
        updateButton: "Update",
    },
    readWorksheets: {
        arrangeWorksheets: "Arrange Worksheets",
        add: "Add",
        headings: {
            id: "ID",
            name: "Name",
            priority: "Priority",
            includeForSnsEvents: "SNS Events",
        },
        getError: "Worksheets list could not be fetched. Try again",
    },
    createWorksheets: {
        cancel: "Cancel",
        create: "Create",
        add: "Add",
        addNewQuestion: "Add New Question",
        update: "Update",
        reset: "Reset",
        edit: "Edit",
        delete: "Delete",
        backToView: "Back to view worksheets",
        headings: {
            id: "Question ID",
            questions: "Create Questions",
            question: "Question Text",
            type: "Type",
            position: "Position",
            statusId: "Status ID",
            typeValues: "Type Values",
            questionBcQuestionIds: "Big Change ID",
            parentId: "Parent Question ID",
            displayCondition: "Display Condition",
            meta: "Meta",
            key: "Key",
            value: "Value",
        },
        placeholders: {
            name: "Name",
            orderTypeIds: "Order Types",
            includeForSnsEvents: "Automatic Completion",
            showForSubcontractor: "Show For Subcontractor",
            priority: "Priority",
            statusId: "Status Update",
            questions: "Questions",
            questionName: "Question Text",
            questionType: "Type",
            questionTypeValues: "Type Values",
            questionPosition: "Position",
            questionStatusId: "Status ID",
            questionBcQuestionIds: "Big Change ID",
            questionParentId: "Parent ID",
            questionDisplayCondition: "Condition",
            questionMeta: "{{prefix}} Meta",
            questionMetaKey: "Key",
            questionMetaValue: "Value",
        },
        toasts: {
            postError: "Something went wrong",
            genericError: "Something went wrong, try refreshing the page",
        },
        tooltips: {
            includeForSnsEvents: "If checked, neither the worksheet nor its questions will be shown to the driver or subcontractor. (Only check this if you are sure there is functionality to complete this worksheet automatically.)",
            showForSubcontractor: "If checked, the worksheet and its questions will also be shown to subcontractor.",
            status: "This updates the status of the job once the worksheet is submitted. Only one status update is sent per worksheet. A status set on a question overrides a status set on a worksheet. If there are multiple questions with a status, the one sent will be the last question answered. Once submitted, the job’s status will be updated in our API and in Big Change.\n\nIf ‘on-the-way’ ,the driver or subcontractor will be shown the next worksheet. This status is used by our API to automatically contact the customer to let it know the driver is on the way.\n\nIf ‘suspended’, the driver or subcontractor will be directed back to the dashboard, and will only be able to complete the currently suspended job, a quick-job or the next available tip job.\n\nIf ‘failed’, the driver or subcontractor will be directed back to the dashboard, and the job will no longer be visible on the dashboard.\n\nIf ‘completed’, the driver or subcontractor will be directed back to the dashboard, and the job will no longer be visible on the dashboard.",
        },
        postError: "Worksheet could not be created. Try again",
        fieldRequired: "There are fields with outstanding errors below",
        questionFieldRequired: "All required fields should be filled",
        postSuccess: "Worksheet added successfully",
    },
    orderTypeValue: {
        add: "Add",
        update: "Update",
        cancel: "Cancel",
        addNewOrderTypeValue: "Add New Order Type Value",
        editOrderTypeValue: "Edit Order Type Value",
        headings: {
            id: "ID",
            value: "Value",
        },
        placeholders: {
            value: "Value",
        },
        getError: "Order Type Value list could not be fetched. Try again",
        postError: "Order Type Value could not be created. Try again",
        postSuccess: "Order Type Value added successfully",
        patchError: "Order Type Value could not be updated. Try again",
        patchSuccess: "Order Type Value updated",
    },
    table: {
        headings: {
            action: "Action",
        },
        actions: {
            edit: "Edit",
        },
    },
    modal: {
        removal: "Do you want to delete this question?",
        confirm: "Confirm",
        cancel: "Cancel",
    },
    noQuestionText: "New question has not been completed yet",
    expandAllQuestions: "Expand All",
    back: "Back",
    fallbackError: "Something went wrong",
    updateWorksheet: {
        headings: {
            update: "Update Worksheet",
        },
        buttons: {
            update: "Update",
            add: "Add Question",
        },
        placeholders: {
            name: "Name",
            orderTypes: "Start typing to search by order-types",
        },
        labels: {
            name: "Name",
            status: "Status Update",
            includeForSnsEvents: "Automatic Completion",
            showForSubcontractor: "Show For Subcontractor",
            orderTypes: "Order Types",
        },
        tooltips: {
            includeForSnsEvents: "If checked, neither the worksheet nor its questions will be shown to the driver or subcontractor. (Only check this if you are sure there is functionality to complete this worksheet automatically.)",
            showForSubcontractor: "If checked, the worksheet and its questions will also be shown to subcontractor.",
            status: "This updates the status of the job once the worksheet is submitted. Only one status update is sent per worksheet. A status set on a question overrides a status set on a worksheet. If there are multiple questions with a status, the one sent will be the last question answered. Once submitted, the job’s status will be updated in our API and in Big Change.\n\nIf ‘on-the-way’ ,the driver or subcontractor will be shown the next worksheet. This status is used by our API to automatically contact the customer to let it know the driver is on the way.\n\nIf ‘suspended’, the driver or subcontractor will be directed back to the dashboard, and will only be able to complete the currently suspended job, a quick-job or the next available tip job.\n\nIf ‘failed’, the driver or subcontractor will be directed back to the dashboard, and the job will no longer be visible on the dashboard.\n\nIf ‘completed’, the driver or subcontractor will be directed back to the dashboard, and the job will no longer be visible on the dashboard.",
        },
        toasts: {
            patchSuccess: "Worksheet updated",
            patchError: "Something went wrong",
            genericError: "Something went wrong, try refreshing the page",
        },
        questionModal: {
            validation: {
                required: "Required",
                number: "Must be a number",
                wholeNumber: "Must be a whole number",
                unsigned: "Must be zero or positive",
                position: "Numbering starts at 1",
                bcInteger: "Big Change IDs are numbers only",
                bcUnsigned: "Big Change IDs are positive numbers",
                trueOrFalse: "Must be 'true' or 'false'",
                displayCondition: "Required when there is a parent-question",
                counterEnd: "Must be greater than Counter Start",
                counterIncrement: "Increment will not work with this range",
                listItems: "No list items selected",
                status: "Allowed statuses are",
            },
            headings: {
                editQuestion: "Edit Question",
                createQuestion: "Create Question",
                childQuestions: "Child Questions",
                areYouSure: "Are you sure you want to delete this question?",
            },
            labels: {
                question: "Question",
                bcQuestionId: "Big Change Question-ID",
                position: "Position",
                type: "Type",
                booleanParentQuestion: "Display as 'Yes/No' list?",
                booleanToggleAll: "Show Toggle All",
                booleanRequiredValueTrue: "Require true?",
                counterAsList: "Display as list?",
                counterStart: "Start",
                counterEnd: "End",
                counterIncrement: "Increment",
                typeValues: "List Options",
                modalText: "Modal Text",
                parentQuestion: "Parent Question",
                displayCondition: "Parent Answer",
                status: "Status Update",
                showForSubcontractor: "Show for Subcontractor",
            },
            placeholders: {
                question: "Question text",
                type: "Select type",
                typeValues: "Select options for the list",
                parentQuestion: "Start typing to search by question",
                displayCondition: "Start typing to search by answer",
            },
            buttons: {
                delete: "Delete",
                save: "Save",
                back: "Back",
            },
            toasts: {
                error: "Something went wrong",
                updated: "Question updated",
                created: "Question created",
                deleted: "Question deleted",
            },
            tooltips: {
                delete: "You cannot delete a question if it has children.",
                booleanParent: "With this type of boolean question there is no default value, so the driver must select either 'Yes' (true) or 'No' (false).",
                booleanParentDisplayCondition: "When displaying a boolean question as a yes-no list, the answer of the parent question must be 'true' or 'false'. (This will not affect the question displaying to the driver as a yes-no list.)",
                booleanToggleAll: "Displaying a toggle-all button allows the driver to change all the toggles on the page at once.",
                booleanRequiredValueTrue: "Boolean questions (toggles) have a default value of false (no). Selecting this option required the driver to toggle this to true in order to proceed to the next worksheet. (Without toggling to true, the driver would not be able to proceed to the next worksheet.)",
                counterAsList: "Display the counter as a scrollable list with custom start, end and increment.",
                displayCondition: "Select an answer that will show this question.",
                statusUpdate: " Only one status update is sent per worksheet. A status set on a question overrides a status set on a worksheet. If there are multiple questions with a status, the one sent will be the last question answered.",
                counterAsAList: "1 to 100 produces a range of 99. The range must be divisible by the increment, so in this case an increment of 10 is invalid.",
                questionType: "You cannot amend a question's type if it has children.",
                showForSubcontractor: "If checked, this question will also be shown to subcontractor.",
            },
            text: {
                childQuestionIfClause: "If this question's answer is ",
                childQuestionThenClause: ", then show question: ",
            },
        },
    },
};
