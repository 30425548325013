import * as Yup from "yup";
import {
  postcodeRegExp,
  stringRequired,
  validPostcode,
  validEmail,
  emailRegExp,
  validPhone,
  phoneRegExp,
} from "validation/common";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  address: stringRequired,
  city: Yup.string(),
  postcode: Yup.string()
    .matches(postcodeRegExp, validPostcode)
    .min(5)
    .max(10)
    .required(),
  siteCode: Yup.string(),
  group: Yup.string(),
  companyCode: Yup.string(),
  contactName: Yup.string(),
  contactEmail: Yup.string().matches(emailRegExp, validEmail),
  contactPhone: Yup.string().matches(phoneRegExp, validPhone),
  notes: Yup.string(),
});
