import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import {
  Filters,
  Search,
  Results,
  Basket,
  LoadingResults,
  LoadingFilters,
} from "../components";
import { DatePickerInput, FormButton } from "../../../core";
import {
  useCatalogueSearch,
  useCatalogueContext,
  useBasketContext,
  useMultiFormContext,
  useAuthContext,
} from "hooks";
import { filterByBlackoutDate } from "helpers/catalogue";
import { getCalloutCharge, getFlatCatalogue } from "api/catalogues";
import { ICreateJobFormState } from "../createJob";
import axios from "axios";
import { handleException } from "helpers/handleException";
import { useTranslation } from "react-i18next";
import roles from "constants/roles";
import {
  Button,
  Icon,
  Panel,
  theme,
  Text,
  useModal,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { Formik } from "formik";
import Warning from "../../../../images/warning.svg";

export const SelectItems = (): React.ReactElement => {
  const { items, categories, isLoading, isFallback, catalogueId } =
    useCatalogueContext();
  const [isExtra, setIsExtra] = useState<boolean>(false);

  const {
    formState: { collectionDate, company, useAdhoc, addressLookupPostcode },
    nextStep,
    pushState,
  } = useMultiFormContext<ICreateJobFormState>();

  const [translate] = useTranslation("jobs");
  const [Modal, setIsModalVisible] = useModal();
  const [date, setDate] = useState<string>();
  const { hasItems, addItem, hasItem, items: basketItems } = useBasketContext();
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeStaff = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const { data: clearabeeCatalogue, isFetching: isClarabeeCatalogueFetching } =
    useQuery(
      ["getPortalFlatCatalogue"],
      () => getFlatCatalogue("PORTAL", addressLookupPostcode),
      {
        enabled: isClearabeeStaff,
      },
    );

  const { data: bookingLimit, isLoading: bookingLimitIsLoading } = useQuery(
    `bookingLimitDate-${date}`,
    async () =>
      (
        await instance.catalogues.getBookingLimit(
          dayjs(date).format("YYYY-MM-DD"),
        )
      ).data,
    {
      enabled: !isClearabeeStaff,
      retry: 1,
    },
  );

  const {
    mutate,
    isLoading: calloutChargeLoading,
    reset,
  } = useMutation(
    (sku: string) => {
      return getCalloutCharge(sku, catalogueId);
    },
    {
      onSuccess: (item) => {
        if (item && !hasItem(item.sku)) {
          addItem({
            parentSku: item.parentSku,
            title: item.title,
            sku: item.sku,
            price: item.price || 0,
            quantity: 1,
            isCalloutCharge: true,
          });
        }
      },
      onError: (error, sku) => {
        if (axios.isAxiosError(error) && error?.response?.status !== 404) {
          handleException(
            error,
            { sku },
            {
              type: "Select Items",
            },
            translate("errors.basket"),
          );
        }
      },
    },
  );

  const userItems = filterByBlackoutDate(
    items ?? [],
    collectionDate,
    addressLookupPostcode,
  ).sort((a, b) => (b.meta?.priority ?? 0) - (a.meta?.priority ?? 0));

  const {
    results: resultsBefore,
    query,
    setQuery,
    selectedCategories,
    setSelectedCategories,
  } = useCatalogueSearch(userItems);
  const adminItems = filterByBlackoutDate(
    clearabeeCatalogue?.items ?? [],
    collectionDate,
  );
  const {
    results: adminResultsBefore,
    query: adminQuery,
    setQuery: adminSetQuery,
    selectedCategories: adminSelectedCategories,
    setSelectedCategories: adminSetSelectedCategories,
  } = useCatalogueSearch(adminItems);

  const catalogueCategories = [
    "BINCOL",
    "LARGELOAD",
    "XLARGELOAD",
    "SOFAREMOVAL",
    "CALLOUT",
    "MINILOAD",
    "SMALLLOAD",
    "MEDIUMLOAD",
    "XXLARGELOAD",
    "MANVAN",
    "SKIPS",
    "SKIPBAGS",
    "SKIPPERMIT",
  ];

  if (!useAdhoc) {
    catalogueCategories.push("YDGWSKIP");
  }

  useEffect(() => {
    if (
      basketItems?.length &&
      !basketItems.some(({ isCalloutCharge }) => isCalloutCharge)
    ) {
      mutate(basketItems[0].sku);
    } else if (basketItems.length === 0) {
      reset();
    }
  }, [basketItems]);

  const handleSubmit = (values: any) => {
    const data = bookingLimit as any;

    if (
      !!data &&
      data.some(
        (item: any) => item.companyCode === company?.value && !item.canBook,
      )
    ) {
      setIsModalVisible(true);
    } else {
      pushState(values);
      nextStep();
    }
  };

  if (isClarabeeCatalogueFetching && useAdhoc)
    return (
      <Icon.Loading
        color="brand"
        styles={{ margin: `${theme.spacing.xlarge} auto` }}
      />
    );

  return (
    <>
      <Formik
        initialValues={{ collectionDate: collectionDate }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          useEffect(() => {
            setFieldValue("collectionDate", values.collectionDate);
            pushState(values);
            if (values.collectionDate !== date) {
              setDate(values.collectionDate);
            }
          }, [values]);

          return (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="flex flex-col lg:flex-row">
                <div className="w-full order-2 lg:order-1 lg:w-88">
                  {!isLoading ? (
                    <>
                      {!useAdhoc && (
                        <div className="hidden lg:block">
                          <Filters
                            categories={categories}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                          />
                        </div>
                      )}
                      <Panel
                        styles={{
                          padding: theme.spacing.medium,
                          marginTop: theme.spacing.small,
                        }}
                      >
                        <DatePickerInput
                          name="collectionDate"
                          floatingLabel={false}
                          numberOfMonths={1}
                          validate
                          isRanged={false}
                          placeholder={translate(
                            "create.steps.selectItems.selectDate",
                          )}
                          label={{ text: `${translate("form.label.date")}*` }}
                          disabled={!!basketItems.length}
                          disabledDays={[
                            {
                              before: new Date(),
                            },
                            {
                              daysOfWeek: [0],
                            },
                          ]}
                        />
                      </Panel>
                      <div className="lg:mt-4 lg:sticky lg:top-6">
                        <Basket
                          excludedItems={["DELIVERYCHARGE"]}
                          isLoading={calloutChargeLoading}
                          isAdhoc={useAdhoc}
                        />
                        <div className="mt-8 flex items-center justify-center">
                          <FormButton
                            name="next"
                            type="submit"
                            text={translate(
                              "create.steps.selectItems.nextStep",
                            )}
                            variant="secondary"
                            disabled={
                              !hasItems ||
                              calloutChargeLoading ||
                              bookingLimitIsLoading
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <LoadingFilters />
                  )}
                </div>
                <div className="w-full order-1 lg:order-2 lg:ml-6">
                  {!useAdhoc && (
                    <Search
                      isLoading={isLoading}
                      query={query}
                      setQuery={setQuery}
                    />
                  )}
                  <div className="mt-4">
                    {isLoading ? (
                      <LoadingResults />
                    ) : isFallback && !useAdhoc ? (
                      <div className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
                        <span className="block">
                          {translate("create.steps.selectItems.notAvailable")}
                        </span>
                      </div>
                    ) : (
                      <>
                        {!useAdhoc && (
                          <Results
                            results={resultsBefore}
                            categories={[]}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                            excludedItems={["DELIVERYCHARGE"]}
                          />
                        )}
                        {isClearabeeStaff && (
                          <>
                            {!useAdhoc && (
                              <Button
                                className="my-4 cursor-pointer"
                                size="medium"
                                type="button"
                                color="warning"
                                onClick={() => setIsExtra(() => !isExtra)}
                              >
                                {isExtra
                                  ? `${translate(
                                      "create.steps.selectItems.showLess",
                                    )}`
                                  : `${translate(
                                      "create.steps.selectItems.showMore",
                                    )}`}
                              </Button>
                            )}
                            {(isExtra || useAdhoc) && (
                              <>
                                <Panel>
                                  <Search
                                    isLoading={isLoading}
                                    query={adminQuery}
                                    setQuery={adminSetQuery}
                                  />
                                </Panel>
                                <Results
                                  isPortalCatalogue
                                  results={adminResultsBefore.filter((item) =>
                                    catalogueCategories.every(
                                      (index) => !item.sku.includes(index),
                                    ),
                                  )}
                                  categories={[]}
                                  selectedCategories={adminSelectedCategories}
                                  setSelectedCategories={
                                    adminSetSelectedCategories
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <Modal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <div className="mb-6">
          <Text fontSize="small">
            {translate("headings.bookingLimitReached")}
          </Text>
        </div>
        <Button
          color="warning"
          size="medium"
          onClick={() => setIsModalVisible(false)}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </Modal>
    </>
  );
};
