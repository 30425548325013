import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Input,
  Modal,
  Panel,
  Table,
  theme,
} from "@clearabee/ui-library";
import { IGarage } from "@clearabee/api-schemas";
import { toasts } from "helpers/toasts";
import { buildQuery } from "helpers/api";
import { SearchIcon } from "images";
import { useScreenWidth } from "hooks";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { IPaginatedResults, TFilters } from "api/types";
import { GarageDetails, CreateGarage } from "./components";
import { LoadingOverlay } from "components/common/components";
import { paginationStyles } from "components/common/resusablePaginationStyles";

const initialValues = {
  name: "",
  group: "",
};

const groupOptions = [
  { label: "1. Preferred Garages", value: "1. Preferred Garages" },
];

// initial results per page for pagination
const initialResultsPerPage = 10;

export const Garages = (): React.ReactElement => {
  const [translate] = useTranslation("garages");

  const [total, setTotal] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [createGarageVisible, setCreateGarageVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState<IGarage>();

  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  const existingQueryParamObject = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );

  /**
   *  function to read the garages and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readGarages = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<IGarage>> => {
    const filterParams = new URLSearchParams(filters);

    //garages filters
    const name = filterParams?.get("name:like") ?? "";
    const group = filterParams?.get("group:eq") ?? "";

    const { data } = await instance.jobs.getGarages({
      params: {
        ...(!!name ? { "name:like": `${name}%` } : {}),
        ...(!!group ? { "group:eq": `${group}` } : {}),
        limit,
        offset: currentPage * limit,
      },
    });

    const response = data as unknown as IPaginatedResults<IGarage>;

    setTotal(response?.pagination?.total || 0);

    return response;
  };

  /**
   * Mutation to delete garage
   */
  const { mutate: mutateDeleteGarage, isLoading: isLoadingMutateDeleteGarage } =
    useMutation(
      async (id: string) => {
        await instance.jobs.deleteGarage(id);
      },
      {
        onSuccess: () => {
          setDeleteModalVisible(false);
          refetchGarages();
          toasts.success({
            message: translate("success.delete"),
          });
        },
      },
    );

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResultsPerPage,
    query: {
      isFetching: isFetchingGarages,
      isLoading: isLoadingGarages,
      refetch: refetchGarages,
      isSuccess: isSuccessReadGarages,
    },
  } = usePaginatedQuery(
    readGarages,
    "readGaragesPaginated",
    !!Object.keys(existingQueryParamObject).length
      ? buildQuery(existingQueryParamObject)
      : "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: true,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // garages filters
    const name = params?.get("name:like") ?? "";
    const group = params?.get("group:eq") ?? "";
    return {
      name,
      group,
      status,
    } as typeof initialValues;
  };

  const handleSubmit = (values: typeof initialValues) => {
    const { name, group } = values;

    const updatedFilterObject = {
      ...(!!name ? { "name:like": name } : {}),
      ...(!!group ? { "group:eq": group } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/garages?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(
      `${currentUrlWithoutBase}?page=1&resultsPerPage=${initialResultsPerPage}`,
    );
  }, [currentPage, resultsPerPage]);

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {(isLoadingGarages || isFetchingGarages) && <LoadingOverlay />}

      <Box className="max-w-screen-lg mx-auto py-10 relative">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
            display: "flex",
            flexDirection: "column",
          }}
          className="flex flex-col lg:flex-row gap-x-6 justify-between"
        >
          <Box className="flex items-center justify-between">
            <Heading fontSize="large" color="brand">
              {translate("headings.search")} ({total})
            </Heading>
            <Button
              type="button"
              size="small"
              onClick={() => setCreateGarageVisible(true)}
            >
              {translate("buttons.add")}
            </Button>
          </Box>
          <Form
            className="flex flex-row items-center border-t border-grey-200 mt-4 pt-5"
            initialValues={getInitialValues()}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              group: Yup.string(),
              status: Yup.string(),
            })}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <>
                <Box className="flex gap-x-3  w-full flex-1">
                  <Box className="w-full flex-grow">
                    <Field
                      name="name"
                      className="flex-1 min-w-full"
                      label={translate("form.labels.name")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("form.placeholders.name")}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="min-w-56">
                    <Field name="group" label={translate("form.labels.group")}>
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          placeholder={translate("form.placeholders.group")}
                          options={groupOptions}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Button
                  className="md:ml-6 flex items-center relative top-3"
                  size="small"
                  color="accent"
                  type="submit"
                >
                  <SearchIcon className="mr-2" />
                  {translate("buttons.search")}
                </Button>
                <Button
                  className="ml-3 relative top-3"
                  size="small"
                  color="negative"
                  type="reset"
                  onClick={() => {
                    resetForm();
                    updateFilters("");
                    setCurrentPage(1);
                    setResultsPerPage(initialResultsPerPage);
                    history.push(
                      `/garages?page=1&resultsPerPage=${initialResultsPerPage}`,
                    );
                  }}
                >
                  {translate("buttons.reset")}
                </Button>
              </>
            )}
          </Form>
        </Panel>
        <Box className="relative flex flex-1">
          {/* DISPLAY NO DATA MESSAGE */}
          {!isLoadingGarages &&
            !isFetchingGarages &&
            !paginatedData.length &&
            isSuccessReadGarages && (
              <Box className="w-full flex justify-center mt-8">
                <Heading level={4}>{translate("errors.noData")}</Heading>
              </Box>
            )}
          {!!paginatedData.length && (
            <Box>
              <Table
                className="mt-10"
                styles={{
                  tableLayout: "fixed",
                  "th:last-of-type > p": {
                    textAlign: "center",
                  },
                }}
              >
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <Table.Header
                  fontSize={isDesktop ? "xsmall" : "xsmall2"}
                  headings={[
                    translate("table.name"),
                    translate("table.siteCode"),
                    translate("table.companyCode"),
                    translate("table.postcode"),
                    translate("table.group"),
                    translate("table.actions"),
                  ]}
                  styles={{ textAlign: "center" }}
                />
                <Table.Body>
                  {paginatedData?.map((item) => (
                    <Table.Row key={`table-row-${item.id}`}>
                      <Table.Cell.Text>{item.name}</Table.Cell.Text>
                      <Table.Cell.Text>{item.siteCode}</Table.Cell.Text>
                      <Table.Cell.Text>{item.companyCode}</Table.Cell.Text>
                      <Table.Cell.Text>{item.postcode}</Table.Cell.Text>
                      <Table.Cell.Text>{item.group}</Table.Cell.Text>
                      <Table.Cell>
                        <Box className="flex justify-center gap-x-2">
                          <Button
                            size="xsmall"
                            type="button"
                            onClick={() => {
                              setSelectedGarage(item);
                              setModalVisible(true);
                            }}
                            className="text-center"
                          >
                            {translate("buttons.edit")}
                          </Button>
                          <Button
                            size="xsmall"
                            type="button"
                            color="negative"
                            onClick={() => {
                              setSelectedGarage(item);
                              setDeleteModalVisible(true);
                            }}
                            className="text-center"
                          >
                            {translate("buttons.delete")}
                          </Button>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              {!!paginatedData.length && (
                <Box className="mt-10">
                  <PaginationComponent />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <GarageDetails
          isVisible={modalVisible}
          groupOptions={groupOptions}
          garage={selectedGarage}
          onClose={() => setModalVisible(false)}
          onChange={(data) => {
            setSelectedGarage(data);
            refetchGarages();
          }}
        />
        <CreateGarage
          groupOptions={groupOptions}
          onSuccess={(data) => {
            setCreateGarageVisible(false);
            setSelectedGarage(data);
            setModalVisible(true);
            refetchGarages();
          }}
          isVisible={createGarageVisible}
          onClose={() => setCreateGarageVisible(false)}
        />
      </Box>
      {/* Confirm Delete garage modal */}
      <Modal
        width={500}
        onClose={() => setDeleteModalVisible(false)}
        modalVisible={deleteModalVisible}
        styles={{
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.medium} ${theme.spacing.large}`,
          },
        }}
      >
        {/* MODAL LOADING OVERLAY */}
        {isLoadingMutateDeleteGarage && (
          <LoadingOverlay
            iconSize="xlarge2"
            backgroundLoadingContainerStyles={{
              borderRadius: theme.spacing.small,
            }}
          />
        )}
        <Box className="mt-16">
          <Heading level={4} color="brand">
            {translate("headings.confirmDelete")}
          </Heading>
        </Box>
        <Box className="flex mt-8 justify-center gap-4">
          <Button
            onClick={() => setDeleteModalVisible(false)}
            color="negative"
            size="small"
          >
            {translate("buttons.cancel")}
          </Button>
          <Button
            onClick={() =>
              !!selectedGarage && mutateDeleteGarage(String(selectedGarage.id))
            }
            color="positive"
            className="flex justify-center"
            disabled={isLoadingMutateDeleteGarage}
            size="small"
          >
            {translate("buttons.delete")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
