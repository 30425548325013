import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { INotificationAction } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Panel,
  Input,
  Button,
  UnstyledButton,
  Form,
  Field,
  Text,
  Modal,
  Icon,
  theme,
} from "@clearabee/ui-library";
import { toasts } from "helpers";
import { validationSchema } from "./validation";
import { CreateAction } from "./components";
import { PreviewSchema } from "../components";

interface Notification {
  name: string;
  active: boolean;
  description: string;
  trigger: string | null;
}

export const UpdateNotification = (): React.ReactElement => {
  const [showSchemaModal, setShowSchemaModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [selectedAction, setSelectedAction] = useState<INotificationAction>();
  const [translate] = useTranslation("notifications");
  const { id: encodedId } = useParams<{ id: string }>();

  const { data: triggers, isLoading: isLoadingTriggers } = useQuery(
    "getTriggersUpdateNotification",
    async () => {
      return (await instance.notifications.getTriggers()).data;
    },
  );

  const { data: services } = useQuery(
    "getServicesUpdateNotification",
    async () => {
      return (await instance.notifications.getServices()).data;
    },
  );

  const { data: notification, isLoading: isLoadingGetNotification } = useQuery(
    ["getNotification", encodedId],
    async () => {
      if (!!Number(encodedId)) {
        return (await instance.notifications.getNotification(encodedId)).data;
      }
    },
    {
      retry: false,
    },
  );

  const { mutate: patchNotification, isLoading: isLoadingPatchNotification } =
    useMutation(
      async (values: Notification) => {
        return await instance.notifications.patchNotification(encodedId, {
          name: values.name,
          active: values.active,
          description: values.description,
          triggerId: Number(values.trigger),
        });
      },
      {
        onSuccess: () => {
          toasts.success({
            message: translate("upsertNotification.toasts.notificationUpdated"),
          });
        },
      },
    );

  const { mutate: deleteAction, isLoading: isLoadingDeleteAction } =
    useMutation(
      async (id: string) => {
        return await instance.notifications.deleteAction(id);
      },
      {
        onSuccess: () => {
          setRemoveModalVisible(false);
          setSelectedAction(undefined);
          refetch();
          toasts.success({
            message: translate("upsertNotification.toasts.notificationUpdated"),
          });
        },
      },
    );

  const {
    data: actions,
    isLoading: isLoadingActions,
    isRefetching: isRefetchingActions,
    isSuccess,
    refetch,
  } = useQuery(
    ["getActions", encodedId],
    async () => {
      return (await instance.notifications.getNotificationActions(encodedId))
        .data.items;
    },
    {
      retry: false,
    },
  );

  const initialValues = {
    name: notification?.name || "",
    active: !!notification?.active,
    description: notification?.description || "",
    trigger: !!notification?.triggerId ? String(notification?.triggerId) : null,
  };

  return (
    <>
      <Form
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          patchNotification(values);
        }}
      >
        {({ values, setFieldValue }) => {
          const selectedTrigger = triggers?.items.find(
            (trigger) => trigger.id === Number(values.trigger),
          );

          return (
            <>
              <Panel
                styles={{
                  padding: theme.spacing.large,
                }}
              >
                {isLoadingGetNotification && (
                  <>
                    <Box
                      className="absolute flex justify-center items-center min-w-full min-h-full top-0 left-0 rounded-md opacity-60 z-10"
                      backgroundColor="greyscale.lightest"
                    />
                    <Box className="opacity-100 absolute flex justify-center items-center min-w-full min-h-full top-0 left-0 z-10">
                      <Icon.Loading size="xlarge4" color="brand" />
                    </Box>
                  </>
                )}
                <Box className="flex justify-between items-center mb-2">
                  <Heading level={4} color="brand">
                    {translate(
                      "upsertNotification.headings.updateNotification",
                    )}
                  </Heading>
                  <Button
                    className="flex justify-center"
                    size="small"
                    color="accent"
                    type="submit"
                  >
                    {isLoadingPatchNotification ? (
                      <Icon.Loading size="small" />
                    ) : (
                      translate("upsertNotification.buttons.save")
                    )}
                  </Button>
                </Box>
                <Box className="flex gap-4 items-top">
                  <Box className="flex-grow">
                    <Field name="name" label="Name">
                      {({ field }) => <Input.Text {...field} />}
                    </Field>
                  </Box>
                  <Box className="flex-grow">
                    <Field name="trigger" label="Trigger">
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isLoading={isLoadingTriggers}
                          defaultValue={values.trigger}
                          options={
                            !!triggers?.items
                              ? triggers?.items.map((trigger) => ({
                                  label: trigger.name,
                                  value: String(trigger.id),
                                }))
                              : []
                          }
                        />
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="active" label="Active">
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          defaultChecked={notification?.active}
                          onClick={() =>
                            setFieldValue("active", !values.active)
                          }
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Box className="flex -mt-5">
                  <Box className="w-full">
                    <Field name="description" label="Description">
                      {({ field }) => (
                        <Input.Textarea
                          {...field}
                          autoGrowInitialHeight={120}
                          autoGrow
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <UnstyledButton
                  className={!!values.trigger ? "visible" : "invisible"}
                  onClick={() => setShowSchemaModal(true)}
                >
                  <Text
                    color="brand"
                    styles={{
                      textDecoration: "underline",
                      textDecorationThickness: "2px",
                      textUnderlineOffset: theme.spacing.xsmall2,
                    }}
                    fontSize="small"
                  >
                    {translate("upsertNotification.links.previewTrigger")}
                  </Text>
                </UnstyledButton>
              </Panel>
              <Box className="flex items-center justify-between mt-8">
                <Heading level={4} color="brand">
                  {translate("upsertNotification.headings.actions")}
                </Heading>
                <Button
                  type="button"
                  size="small"
                  disabled={!isSuccess}
                  onClick={() => setShowActionModal(true)}
                >
                  {translate("upsertNotification.buttons.addAction")}
                </Button>
              </Box>
              <Box className="mt-5">
                {isLoadingActions || isRefetchingActions ? (
                  <Box color="greyscale.lighter" className="text-center mt-16">
                    <Text fontSize="xlarge" className="font-semibold">
                      {translate("upsertNotification.headings.fetchingActions")}
                    </Text>
                  </Box>
                ) : !!actions?.length ? (
                  actions.map((action, index) => {
                    return (
                      <UnstyledButton
                        key={index}
                        className="w-full my-3"
                        onClick={() => {
                          setShowActionModal(true);
                          setSelectedAction(action);
                        }}
                      >
                        <Panel
                          styles={{
                            padding: theme.spacing.small,
                          }}
                          key={index}
                        >
                          <Box className="flex justify-between items-center">
                            <Box className="flex items-start gap-y-1 gap-x-4">
                              <Text
                                color="brand"
                                fontSize="xlarge"
                                className="font-bold"
                              >
                                {action.name}
                              </Text>
                              <Box className="flex gap-1">
                                <Text
                                  fontSize="large"
                                  className="font-semibold"
                                >
                                  {translate(
                                    "upsertNotification.headings.service",
                                  )}
                                </Text>
                                <Text fontSize="large">
                                  {action.service?.name}
                                </Text>
                              </Box>
                            </Box>
                            <Box className="flex gap-4">
                              <Icon.Cog color="brand" size="medium" />
                              <UnstyledButton
                                disabled={isLoadingDeleteAction}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setRemoveModalVisible(true);
                                  setSelectedAction(action);
                                }}
                              >
                                <Icon.Trash size="medium" color="negative" />
                              </UnstyledButton>
                            </Box>
                          </Box>
                        </Panel>
                      </UnstyledButton>
                    );
                  })
                ) : (
                  <Box color="greyscale.lighter" className="text-center mt-16">
                    <Text fontSize="xlarge" className="font-semibold">
                      {translate("upsertNotification.headings.noActions")}
                    </Text>
                  </Box>
                )}
              </Box>
              {selectedTrigger && (
                <PreviewSchema
                  isVisible={showSchemaModal}
                  schema={selectedTrigger.schema}
                  heading={translate(
                    "upsertNotification.headings.triggerSchema",
                  )}
                  arn={selectedTrigger.arn}
                  onClose={() => setShowSchemaModal(false)}
                />
              )}
            </>
          );
        }}
      </Form>

      <CreateAction
        isVisible={showActionModal}
        selectedAction={selectedAction}
        notificationId={Number(encodedId)}
        services={services?.items}
        onSubmit={() => refetch()}
        onClose={() => {
          setShowActionModal(false);
          setSelectedAction(undefined);
        }}
        onPreviewTriggerClick={() => setShowSchemaModal(!showSchemaModal)}
      />

      <Modal
        width={450}
        closeButtonStyles={{
          top: 20,
          right: 20,
        }}
        onClose={() => {
          setSelectedAction(undefined);
          setRemoveModalVisible(false);
        }}
        modalVisible={removeModalVisible}
        styles={{
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.small,
          },
        }}
      >
        <Box className="mt-4 px-12">
          <Heading fontSize="base" level={4} color="brand">
            {translate("confirmDelete")}{" "}
            <Text color="negative" as="span" fontSize="base">
              &quot;{selectedAction?.name}&quot;
            </Text>
            ?
          </Heading>
        </Box>
        <Box className="flex mt-6 justify-center gap-4">
          <Button
            onClick={() =>
              !!selectedAction && deleteAction(String(selectedAction.id))
            }
            color="negative"
            className="flex justify-center"
            disabled={isLoadingDeleteAction}
            size="small"
          >
            {isLoadingDeleteAction ? (
              <Icon.Loading size="small" />
            ) : (
              translate("buttons.delete")
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
