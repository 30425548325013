import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const initialValues = {
  name: "",
  parentRef: "",
  bcJobType: "",
  resourceId: "",
  priority: 0,
  allowPriceOverride: false,
  description: {
    subcontractors: "",
    drivers: "",
  },
};

export const updateOrderTypeValidationSchema = Yup.object().shape({
  name: Yup.string(),
  parentRef: Yup.string().test(
    "parentRef",
    "This field cannot be a number",
    (value) => {
      return isNaN(value);
    },
  ),
  bcJobType: Yup.string().test(
    "bcJobType",
    "This field cannot be a number",
    (value) => {
      return isNaN(value);
    },
  ),
  resourceId: Yup.string(),
  priority: Yup.number(),
  description: Yup.object().shape({
    subcontractors: Yup.string(),
    drivers: Yup.string(),
  }),
});

export const createOrderTypeValidationSchema = Yup.object().shape({
  name: stringRequired,
  parentRef: Yup.string().test(
    "parentRef",
    "This field cannot be a number",
    (value) => {
      return isNaN(value);
    },
  ),
  bcJobType: Yup.string().test(
    "bcJobType",
    "This field cannot be a number",
    (value) => {
      return isNaN(value);
    },
  ),
  resourceId: Yup.string().matches(/^\d+$/),
  priority: Yup.number().nullable().min(0).max(100),
  description: Yup.object().shape({
    subcontractors: Yup.string(),
    drivers: Yup.string(),
  }),
});
