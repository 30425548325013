import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import dayjs from "dayjs";
import { IPaginatedOrderTypes } from "@clearabee/api-schemas";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Box,
  Icon,
  Panel,
  Text,
  UnstyledButton,
  theme,
} from "@clearabee/ui-library";

interface JobRowProps {
  index: number;
  orderTypes: IPaginatedOrderTypes | undefined;
  formSubmitted: boolean;
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  assetCrew?: string[];
  onJobPatched: () => void;
  onJobRemove: (index: number) => void;
}

export const JobRow = ({
  index,
  orderTypes,
  formSubmitted,
  jobData,
  assetCrew,
  onJobPatched,
  onJobRemove,
}: JobRowProps): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const { values }: FormikProps<FormikValues> = useFormikContext();

  const {
    mutate: patchJob,
    isLoading: patchJobIsLoading,
    isSuccess: patchJobIsSuccess,
    isError: patchJobIsFailed,
  } = useMutation(
    async () => {
      return await instance.jobs.patchJob(String(jobData?.id), {
        asset: values.vehicle,
        assetCrew: assetCrew ?? [],
        suppressEmail: true,
      });
    },
    {
      onSuccess: () => {
        onJobPatched();
      },
    },
  );

  useEffect(() => {
    if (formSubmitted) {
      patchJob();
    }
  }, [formSubmitted]);

  return (
    <>
      <Panel
        styles={{
          padding: theme.spacing.large,
          marginBottom: theme.spacing.xlarge2,
        }}
      >
        <Box className="flex justify-between items-center">
          <Box className="flex flex-col">
            <Box className="flex gap-5">
              <Box className="flex gap-2">
                <Text className="font-semibold">
                  {translate("allocateJobs.titles.jobReference")}
                </Text>
                {jobData?.ref}
              </Box>
              {jobData?.date && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.collectionDate")}
                  </Text>
                  {dayjs(jobData?.date).format("DD/MM/YYYY")}
                </Box>
              )}
              {jobData?.addressPostcode && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.postcode")}
                  </Text>
                  {jobData?.addressPostcode}
                </Box>
              )}
              {jobData?.orderTypeId && (
                <Box className="flex gap-2">
                  <Text className="font-semibold">
                    {translate("allocateJobs.titles.orderType")}
                  </Text>
                  {orderTypes?.items.find(
                    ({ id }) => id === jobData.orderTypeId,
                  )?.name || ""}
                </Box>
              )}
            </Box>
            {jobData?.description && (
              <Box className="flex gap-2">
                <Text className="font-semibold">
                  {translate("allocateJobs.titles.description")}
                </Text>
                {jobData?.description}
              </Box>
            )}
          </Box>
          <UnstyledButton
            onClick={() => {
              onJobRemove(index);
            }}
          >
            {!patchJobIsLoading && !patchJobIsSuccess && !patchJobIsFailed && (
              <Icon.Trash color="negative" size="large" />
            )}
            {patchJobIsLoading && <Icon.Loading size="large" />}
            {patchJobIsSuccess && <Icon.Tick2 color="positive" size="large" />}
            {patchJobIsFailed && <Icon.Close color="negative" size="large" />}
          </UnstyledButton>
        </Box>
      </Panel>
    </>
  );
};
