import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IUser } from "@clearabee/api-schemas";
import {
  Box,
  Button,
  Field,
  Form,
  Input,
  Modal,
  theme,
  Text,
  Icon,
  Heading,
  UnstyledButton,
} from "@clearabee/ui-library";

interface AddUsersModalProps {
  currentCompanyId: number;
  modalVisible: boolean;
  onClose: () => void;
  onGetUsers: (email: string) => void;
  onResetUsers: () => void;
  onPatchUser: ({
    email,
    companyIds,
  }: {
    email: string;
    companyIds: number[];
  }) => Promise<void>;
  users: IUser[];
  isLoadingUsers: boolean;
  isLoadingPatchUser: boolean;
  setIsLoadingPatchUser: (value: boolean) => void;
}

export const AddUsersModal = ({
  currentCompanyId,
  modalVisible,
  onGetUsers,
  onResetUsers,
  onPatchUser,
  users,
  isLoadingUsers,
  isLoadingPatchUser,
  setIsLoadingPatchUser,
  onClose,
}: AddUsersModalProps): React.ReactElement => {
  const [confirmAddAllModal, setConfirmAddAllModal] = useState(false);
  const [counter, setCounter] = useState(0);

  return (
    <>
      <Modal
        width={1000}
        modalVisible={modalVisible}
        onClose={onClose}
        styles={{
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.medium} ${theme.spacing.large}`,
          },
        }}
      >
        <Form
          initialValues={{ email: "" }}
          onSubmit={(values) => onGetUsers(values.email)}
        >
          {({ submitCount, resetForm, values }) => (
            <>
              <Box className="text-left mt-3">
                <Box className="flex items-center gap-x-2">
                  <Heading color="brand" level={4}>
                    Add Users ({users?.length || 0})
                  </Heading>
                  {!!users.length && !isLoadingUsers && (
                    <Button
                      type="button"
                      size="xsmall"
                      onClick={() => setConfirmAddAllModal(true)}
                    >
                      Add All
                    </Button>
                  )}
                </Box>

                <Box className="flex gap-x-5 items-center">
                  <Field
                    styles={{
                      flex: 1,
                    }}
                    name="email"
                    label="Email"
                  >
                    {({ field }) => (
                      <Input.Text {...field} placeholder="Search by email" />
                    )}
                  </Field>
                  <Box className="flex items-center gap-x-2 relative top-3">
                    <Button
                      color="negative"
                      size="small"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        onResetUsers();
                      }}
                    >
                      Reset
                    </Button>
                    <Button color="accent" size="small" type="submit">
                      Search
                    </Button>
                  </Box>
                </Box>
                <Box
                  styles={{
                    height: "60vh",
                  }}
                  className="flex flex-col overflow-y-scroll mx-auto mt-2"
                >
                  {/* LOADING */}
                  {isLoadingUsers && (
                    <Box className="flex justify-center mt-5">
                      <Icon.Loading size="xlarge2" color="brand" />
                    </Box>
                  )}
                  {/* START SEARCH */}
                  {!users.length && !isLoadingUsers && !submitCount && (
                    <Box className="flex justify-center mt-5">
                      <Text fontSize="xlarge" className="font-semibold">
                        Start searching for users to see results
                      </Text>
                    </Box>
                  )}
                  {/* NO DATA */}
                  {!users.length && !isLoadingUsers && !!submitCount && (
                    <Box className="flex justify-center mt-5">
                      <Text fontSize="xlarge" className="font-semibold">
                        No data found
                      </Text>
                    </Box>
                  )}
                  {/* DISPLAY USERS */}
                  {!!users.length &&
                    !isLoadingUsers &&
                    users.map(({ email, lastName, firstName, companies }) => {
                      const isUserCompanyAlreadyAdded = (companies ?? []).find(
                        ({ id }) => Number(id) === currentCompanyId,
                      );

                      return (
                        <Box
                          key={email}
                          className="flex flex-row items-start justify-between bg-gray-200 mb-2 p-5 rounded-md"
                        >
                          <Box className="w-1/3 truncate flex flex-col gap-y-1">
                            <Text fontSize="small" className="font-semibold">
                              Email Address
                            </Text>
                            <Text fontSize="small" className="truncate">
                              {email}
                            </Text>
                          </Box>
                          <Box className="w-1/4 truncate flex flex-col gap-y-1">
                            <Text fontSize="small" className="font-semibold">
                              Name
                            </Text>
                            <Text fontSize="small" className="truncate">
                              {`${firstName || ""} ${lastName || ""}`}
                            </Text>
                          </Box>
                          <Box className="w-1/3 flex flex-col gap-y-1">
                            <Text fontSize="small" className="font-semibold">
                              Companies
                            </Text>
                            {!companies?.length && (
                              <Text fontSize="small">N/A</Text>
                            )}
                            {!!companies?.length &&
                              companies.map((company) => (
                                <Text fontSize="small" key={company.id}>
                                  {company.name}
                                </Text>
                              ))}
                          </Box>
                          {!isUserCompanyAlreadyAdded && (
                            <Box className="w-1/12 flex items-center justify-center">
                              <Button
                                size="xsmall"
                                type="button"
                                disabled={isLoadingPatchUser}
                                onClick={() => {
                                  Promise.all([
                                    onPatchUser({
                                      email,
                                      companyIds: [
                                        ...(companies ?? []).map(({ id }) =>
                                          Number(id),
                                        ),
                                      ],
                                    }),
                                  ]).finally(() => {
                                    setIsLoadingPatchUser(false);
                                    onGetUsers(values.email);
                                  });
                                }}
                              >
                                Add
                              </Button>
                            </Box>
                          )}
                          {isUserCompanyAlreadyAdded && (
                            <Box className="w-1/12 flex items-center justify-center">
                              <Link
                                to={`/users/update/${email}`}
                                target="_blank"
                              >
                                <UnstyledButton>
                                  <Icon.NewWindow color="brand" size="small" />
                                </UnstyledButton>
                              </Link>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              {/* CONFIRM MODAL TO ADD ALL */}
              <Modal
                width={500}
                modalVisible={confirmAddAllModal}
                styles={{
                  padding: `${theme.spacing.small} ${theme.spacing.large}`,
                  [`@media (min-width: ${theme.screens.medium})`]: {
                    padding: `${theme.spacing.medium} ${theme.spacing.large}`,
                  },
                }}
              >
                <Box className="text-center">
                  {!isLoadingPatchUser && (
                    <Heading color="brand" level={4}>
                      Are you sure you want to add all users (
                      {users?.length || 0}) to this company?
                    </Heading>
                  )}

                  {isLoadingPatchUser && (
                    <Box className="flex justify-center  items-center gap-x-2">
                      <Heading color="brand" level={4}>
                        {`Processing ${counter}/${users?.length || 0}`}
                      </Heading>
                      <Icon.Loading size="large" color="brand" />
                    </Box>
                  )}

                  <Box className="flex items-center justify-center gap-x-2 mt-10">
                    <Button
                      color="negative"
                      size="small"
                      disabled={isLoadingPatchUser}
                      type="button"
                      onClick={() => {
                        setCounter(0);
                        setIsLoadingPatchUser(false);
                        setConfirmAddAllModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="accent"
                      size="small"
                      type="button"
                      disabled={isLoadingPatchUser}
                      onClick={() => {
                        Promise.all(
                          users.map(async ({ email, companies }) => {
                            await onPatchUser({
                              email: email,
                              companyIds: [
                                ...(companies ?? []).map(({ id }) =>
                                  Number(id),
                                ),
                              ],
                            });
                            setCounter((prev) => prev + 1);
                          }),
                        ).finally(() => {
                          setIsLoadingPatchUser(false);
                          setCounter(0);
                          setConfirmAddAllModal(false);
                          onGetUsers(values.email);
                        });
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
