import { AxiosError } from "axios";
import { IAPIError } from "../models/api";

/**
 * Check if object is empty.
 */
export const isEmpty = (obj: any): boolean => {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
};

/**
 * See if any property in an object has a value.
 */
export const propertiesHaveValues = (object: any): boolean => {
  let blank = false;

  for (const prop in object) {
    if (object[prop] !== "" && object[prop] !== null) {
      if (typeof object[prop] === "object") {
        blank =
          typeof object[prop].value !== "undefined" &&
          object[prop].value !== "";
      } else {
        blank = true;
      }

      if (blank) {
        return blank;
      }
    }
  }

  return blank;
};

export const formatToPrice = (num: number, currencyChar?: string): string => {
  const currency = currencyChar || "£";
  const rounded = String(num.toFixed(2));
  const [integer, decimal] = rounded.split(".");

  return `${currency}${integer.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ",",
  )}.${decimal}`;
};

export const createRandomString = (
  length: number,
  addNumeric = false,
): string => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let str = "";

  charset.split("").forEach(() => {
    str += charset.charAt(Math.random() * length);
  });

  const formattedStr = str[0].toUpperCase() + str.substr(1, length - 1);

  return addNumeric
    ? formattedStr + Math.floor(Math.random() * Math.floor(10)).toString()
    : formattedStr;
};

export const getErrorMessage = (
  error: any,
  defaultErrorMessage?: string,
): string => {
  return (
    error?.response?.data?.message ||
    error?.message ||
    defaultErrorMessage ||
    "Something went wrong, please try again"
  );
};

/**
 * Builds query string from an object
 */
/* eslint-disable-next-line */
export const buildQuery = (object: any) => {
  return Object.keys(object)
    .map((key) =>
      object[key as keyof typeof object]
        ? `${key}=${encodeURIComponent(object[key as keyof typeof object])}`
        : false,
    )
    .filter((param) => param)
    .join("&");
};
