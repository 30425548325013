import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Modal,
  Text,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { useScreenWidth } from "hooks";
import {
  editFormInitialValues as initialValues,
  editFormSchema as validationSchema,
} from "../validation";

interface EditSupplierPriceModalProps {
  supplierRef: string;
  sku: string;
  postcode: string;
  price: number;
  refetchData: () => void;
}

export const EditSupplierPriceModal = ({
  supplierRef,
  sku,
  postcode,
  price,
  refetchData,
}: EditSupplierPriceModalProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isDesktop } = useScreenWidth();

  const { mutate, isLoading } = useMutation(
    "EditSupplierPriceRecord",
    async (price: number) => {
      if (!supplierRef || !sku || !postcode) return;

      await instance.catalogues.putSupplierPrice(
        supplierRef,
        sku,
        postcode.replace(/\s/g, ""),
        {
          price,
        },
      );
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
        refetchData();
        toasts.success({
          message: translate("supplierPrices.success.update"),
        });
      },
      onError: () => {
        toasts.error({
          message: translate("supplierPrices.errors.update"),
        });
      },
    },
  );

  return (
    <>
      <Button size="xsmall" color="brand">
        <Text
          fontSize={isDesktop ? "small" : "xsmall"}
          className="p-1"
          onClick={() => setIsModalOpen(true)}
        >
          {translate("supplierPrices.buttons.edit")}
        </Text>
      </Button>
      {isModalOpen && (
        <Modal
          width={500}
          styles={{
            textAlign: "left",
          }}
          onClose={() => setIsModalOpen(false)}
        >
          <Form
            initialValues={{ ...initialValues, price }}
            validationSchema={validationSchema}
            onSubmit={({ price }) => {
              mutate(Number(price));
            }}
          >
            {({}) => (
              <>
                <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                  <Heading
                    level={5}
                    color="brand"
                    className="pr-4 w-full sm:w-auto"
                  >
                    {translate("supplierPrices.headings.updateSupplierPrice")}
                  </Heading>
                </Box>
                <Box className="mt-5">
                  <Text fontSize="small">{`${supplierRef} - ${sku} - £${price}`}</Text>
                </Box>

                {/* Price */}
                <Field
                  styles={{ flex: 1 }}
                  name="price"
                  label={translate("supplierPrices.form.labels.price")}
                >
                  {({ field }) => <Input.Text {...field} type="number" />}
                </Field>
                <Box className="flex justify-end mt-5">
                  {/* SUMBIT */}
                  <Button
                    size="small"
                    color="accent"
                    type="submit"
                    disabled={isLoading}
                    className={isLoading ? "flex items-center gap-x-2" : ""}
                  >
                    {translate("supplierPrices.buttons.submit")}
                    {isLoading && <Icon.Loading size="small" />}
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
