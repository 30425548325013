"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companySignup = void 0;
exports.companySignup = {
    form: {
        headings: {
            companySignup: "Company Sign Up",
            details: "Details",
            headOfficeAddress: "Head Office Address",
            primaryContact: "Primary Contact & Account Administator",
            financeContact: "Finance Contact (To receive invoices and documents)",
            isSameContact: "Is Finance Contact the same as Primary Contact?",
            doYouHaveEmailDomain: "Do you have an email domain?",
            success: "Success",
        },
        labels: {
            companyName: "Company Name",
            tradingName: "Trading Name",
            companyRegistrationNumber: "Company Registration Number",
            vatRegistrationNumber: "VAT Registration Number",
            street: "Street",
            city: "City",
            address: "Address",
            postcode: "Postcode",
            companyEmailDomain: "Company Email Domain (Please include @)",
            primaryContact: "Primary Contact & Account Administator",
            firstName: "First Name",
            lastName: "Last Name",
            fullName: "Full Name",
            position: "Position / Job Title",
            email: "Email",
            phone: "Telephone",
            financeContact: "Finance Contact (To receive invoices and documents) (if different to Primary)",
            signature: "Please sign here",
            services: "Services",
            monthlyVolume: "Monthly Volume (Collections)",
        },
        placeholders: {
            companyName: "Enter Company Name",
            tradingName: "If different to Company Name",
            companyRegistrationNumber: "Enter Company registration Number",
            vatRegistrationNumber: "Enter VAT Registration Number",
            street: "Enter Street Address",
            city: "Enter City Address",
            address: "Select an address",
            firstName: "Enter First Name",
            lastName: "Enter Last Name",
            postcode: "Enter Postcode Address",
            companyEmailDomain: "Enter Company Email Domain (e.g. @contoso.com)",
            fullName: "Enter Full Name",
            position: "Enter Position / Job Title",
            email: "Enter Email Address",
            phone: "(e.g. +447555555555)",
        },
    },
    buttons: {
        backToLogin: "Back to Login",
        save: "Save",
        submit: "Submit",
    },
    errorMessage: "Unable to sign up new company, please try again.",
    errorFieldsRequired: "Field(s) requires attention to continue",
    successMessage: "Your account is now waiting to be approved. \n\nAn email has been sent. You can use the temporary password to set a new password but you will be unable to log in until your account have been approved.",
    agreeTermText1: "By checking this box you agree to the",
    agreeTermText2: "terms and conditions",
    postcodeLookup: {
        enterYourPostcode: "Switch back to postcode lookup",
        cantFindAddress: "Enter address manually",
    },
};
