import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IReportJob } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Icon,
  Panel,
  UnstyledButton,
} from "@clearabee/ui-library";
import { ReportDetailsRow } from "./reportDetailsRow";

interface ReportDetailsProps {
  reports: IReportJob[];
}
export const ReportDetails = ({
  reports,
}: ReportDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Panel className="my-12" shadow={false}>
      <Box
        className={`flex justify-between items-center ${isExpanded && "mb-4"}`}
      >
        <Heading color="brand" level={5}>
          {translate("headings.reports", {
            count: reports.length,
          })}
        </Heading>
        <UnstyledButton
          className={isExpanded ? "-mt-1" : "-mb-2"}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Icon.Chevron
            color="brand"
            size="medium"
            styles={{
              transform: `rotate(${isExpanded ? "90deg" : "270deg"})`,
              transition: "transform 0.3s",
              transformOrigin: "center",
            }}
          />
        </UnstyledButton>
      </Box>
      {isExpanded &&
        reports.map((report, index) => (
          <ReportDetailsRow report={report} key={index} />
        ))}
    </Panel>
  );
};
