import React, { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FieldArray } from "formik";
import axios from "axios";
import {
  Heading,
  Panel,
  Field,
  Form,
  Input,
  Box,
  Button,
  Icon,
  theme,
} from "@clearabee/ui-library";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { ICompany } from "@clearabee/api-schemas";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/worksheets/components";
import { JobRow, AddJob } from "./components";
import { LoadingOverlay } from "components/common/components";
import { initialValues, validationSchema } from "./validation";

export const BulkAllocationLegacySubcontractors = (): React.ReactElement => {
  const [translate] = useTranslation("subcontractors");
  const preventFormSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [jobs, setJobs] = useState<
    ApiResponseData<typeof instance.jobs.getJobByRef>[]
  >([]);
  const { data, isLoading: subcontractorsAreLoading } = useQuery<ICompany[]>(
    ["getSubcontractors", "bulkAllocation"],
    async () =>
      (
        await axios.get(`${process.env.REACT_APP_MS_API_BASE_URL}/companies`, {
          params: {
            "active:eq": "1",
            eager: "[postcodes]",
            limit: 5000,
          },
        })
      ).data.items,
  );

  const subcontractors =
    data &&
    data.filter(
      (company) => company.type === "subcontractor" || !!company.resourceId,
    );

  const { data: orderTypes } = useQuery(
    ["getOrderTypes"],
    async () => (await instance.catalogues.getOrderTypes()).data,
    {
      keepPreviousData: true,
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => setFormSubmitted(true)}
      >
        {({ values }) => {
          return (
            <>
              <Panel className="mb-12">
                {subcontractorsAreLoading && <LoadingOverlay />}
                <Box className="flex justify-between items-center">
                  <Heading color="brand" level={4}>
                    {translate("allocateJobs.headings.bulkAllocation")}
                  </Heading>
                  <Button
                    onClick={() => window.location.reload()}
                    size="small"
                    variant="outline"
                    color="negative"
                  >
                    {translate("allocateJobs.buttons.reset")}
                  </Button>
                </Box>
                <Box className="border-t border-gray-400 mt-4 pb-6" />
                <Box
                  className={`flex ${
                    subcontractorsAreLoading ? "items-center" : "items-start"
                  } justify-between gap-3`}
                >
                  <Box className="flex-grow">
                    <Field
                      name="subcontractor"
                      label={translate("allocateJobs.labels.subcontractors")}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          onKeyDown={preventFormSubmit}
                          options={subcontractors?.map((item) => {
                            return {
                              label: item.name,
                              value: item.companyCode,
                            };
                          })}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="flex justify-center items-center gap-3">
                    <Box className="flex flex-row items-start min-h-28">
                      <Field
                        name="globalInvoice"
                        label={translate(
                          "allocateJobs.labels.globalInvoiceAmount",
                        )}
                        messageStyles={{
                          width: "180px",
                          marginBottom: `-${theme.spacing.medium}`,
                        }}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            onKeyDown={preventFormSubmit}
                          />
                        )}
                      </Field>
                      {/* Tooltip */}
                      <Tooltip placement="top">
                        <TooltipTrigger>
                          <Icon.Question
                            className="mt-5"
                            color="brand"
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 999 }}
                          className="Tooltip"
                        >
                          <Panel
                            styles={{
                              padding: theme.spacing.small,
                              maxWidth: theme.screens.xsmall,
                            }}
                          >
                            {translate("allocateJobs.tooltips.invoiceAmount")}
                          </Panel>
                        </TooltipContent>
                      </Tooltip>
                    </Box>
                    <Box className="flex-grow flex flex-1 flex-row items-start min-h-28">
                      <Field
                        name="groupName"
                        label={translate("allocateJobs.labels.groupName")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            onKeyDown={preventFormSubmit}
                          />
                        )}
                      </Field>
                      {/* Tooltip */}
                      <Tooltip placement="top">
                        <TooltipTrigger>
                          <Icon.Question
                            className="mt-5"
                            color="brand"
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 999 }}
                          className="Tooltip"
                        >
                          <Panel
                            styles={{
                              padding: theme.spacing.small,
                              maxWidth: theme.screens.xsmall,
                            }}
                          >
                            {translate("allocateJobs.tooltips.groupName")}
                          </Panel>
                        </TooltipContent>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Button
                        type="submit"
                        disabled={!values.jobs.length}
                        size="medium"
                      >
                        {translate("allocateJobs.buttons.submit")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Panel>
              <AddJob
                addJob={(job) => setJobs((prev) => [...prev, job])}
                jobs={jobs}
              />
              {jobs.map((item, index) => (
                <FieldArray
                  key={index}
                  name={`jobs[${index}]`}
                  render={() => (
                    <>
                      <JobRow
                        orderTypes={orderTypes}
                        index={index}
                        subcontractors={subcontractors}
                        jobData={jobs[index]}
                        formSubmitted={formSubmitted}
                        onJobPatched={() => setFormSubmitted(false)}
                        onJobRemove={() =>
                          setJobs(
                            jobs.filter((_, jobIndex) => jobIndex !== index),
                          )
                        }
                      />
                    </>
                  )}
                />
              ))}
            </>
          );
        }}
      </Form>
    </>
  );
};
