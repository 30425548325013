import * as Yup from "yup";
import { fieldRequired, stringRequired } from "validation/common";

export const initialValues = {
  vehicleReg: "",
  plannedStartTime: "",
  duration: 0,
};

export const validationSchema = Yup.object({
  vehicleReg: stringRequired,
  plannedStartTime: stringRequired,
  duration: Yup.number()
    .required(fieldRequired)
    .test("duration", "Duration must be greater than 0", (value) => value > 0),
});
