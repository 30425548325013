import React, { useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  Text,
  theme,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { useScreenWidth } from "hooks";
import { toasts } from "helpers/toasts";

interface DeleteSupplierPriceModalProps {
  supplierRef: string;
  sku: string;
  postcode: string;
  refetchData: () => void;
}

export const DeleteSupplierPriceModal = ({
  supplierRef,
  sku,
  postcode,
  refetchData,
}: DeleteSupplierPriceModalProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isDesktop } = useScreenWidth();

  const { mutate, isLoading } = useMutation(
    "DeleteSupplierPriceRecord",
    async () => {
      if (!supplierRef || !sku || !postcode) return;

      await instance.catalogues.deleteSupplierPrice(
        supplierRef,
        sku,
        postcode.replace(/\s/g, ""),
      );
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
        refetchData();
        toasts.success({
          message: translate("supplierPrices.success.delete"),
        });
      },
      onError: () => {
        toasts.error({
          message: translate("supplierPrices.errors.delete"),
        });
      },
    },
  );
  return (
    <>
      <Button size="xsmall" color="negative">
        <Text
          fontSize={isDesktop ? "small" : "xsmall"}
          className="p-1"
          onClick={() => setIsModalOpen(true)}
        >
          {translate("supplierPrices.buttons.delete")}
        </Text>
      </Button>
      {isModalOpen && (
        <Modal
          width={500}
          styles={{ textAlign: "center" }}
          onClose={() => setIsModalOpen(false)}
        >
          <Box className="pb-4 mb-2">
            <Heading level={5} color="brand" className="pr-4 w-full sm:w-auto">
              {translate("supplierPrices.headings.deleteSupplierPrice")}
            </Heading>
          </Box>
          <Box className="flex justify-center gap-x-3">
            <Button
              size="small"
              color="warning"
              disabled={isLoading}
              onClick={() => setIsModalOpen(false)}
            >
              {translate("supplierPrices.buttons.cancel")}
            </Button>
            <Button
              size="small"
              color="negative"
              disabled={isLoading}
              onClick={() => mutate()}
              className={isLoading ? "flex items-center gap-x-2" : ""}
            >
              {translate("supplierPrices.buttons.delete")}
              {isLoading && <Icon.Loading size="small" />}
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};
