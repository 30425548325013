import React, { useState } from "react";
import { Modal } from "../Modal/Modal";
import { Icon } from "../../Core/Icon/Icon";
import { Text } from "../../Core/Text/Text";
import { styles } from "./Gallery.styles";
import { Box } from "../../Core/Box/Box";
import { ColorKeyVariants } from "@clearabee/ui-library-base";

export interface GalleryItem {
  source: string;
  type?: string;
  alt?: string;
  caption?: string;
}

interface ChildrenProps {
  items: GalleryItem[];
  handleClick: (index: number) => void;
}
export interface GalleryProps {
  items: GalleryItem[];
  children?: (props: ChildrenProps) => React.ReactNode;
  backgroundColor?: ColorKeyVariants;
}

export const Gallery = ({
  items,
  children,
  backgroundColor,
}: GalleryProps): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const next = () => {
    if (selectedItemIndex === items.length - 1) {
      return;
    }

    setSelectedItemIndex(selectedItemIndex + 1);
  };

  const prev = () => {
    if (selectedItemIndex === 0) {
      return;
    }

    setSelectedItemIndex(selectedItemIndex - 1);
  };

  const handleClick = (index: number) => {
    setSelectedItemIndex(index);
    setShowModal(true);
  };

  return (
    <>
      {children ? (
        children({ items, handleClick })
      ) : (
        <ul css={styles.list}>
          {items.map((item, index) => (
            <li key={index} css={styles.listItem}>
              <img
                src={item.source}
                css={styles.listImage}
                onClick={() => handleClick(index)}
              />
            </li>
          ))}
        </ul>
      )}

      {showModal && (
        <Modal styles={styles.modal}>
          <Box
            backgroundColor={backgroundColor ?? undefined}
            css={styles.imageWrapper}
          >
            <Box backgroundColor="light" css={styles.buttonGrid}>
              <Box className="flex items-center gap-2">
                <button
                  css={styles.button}
                  disabled={selectedItemIndex === 0}
                  onClick={prev}
                >
                  <Box
                    backgroundColor="greyscale.lightest"
                    className="p-2 rounded-md flex justify-center items-center"
                  >
                    <Icon.Chevron
                      size="small"
                      styles={{
                        transform: "rotate(180deg)",
                      }}
                      color="dark"
                    />
                  </Box>
                </button>
                <button
                  css={styles.button}
                  disabled={selectedItemIndex === items.length - 1}
                  onClick={next}
                >
                  <Box
                    backgroundColor="greyscale.lightest"
                    className="p-2 rounded-md flex justify-center items-center"
                  >
                    <Icon.Chevron color="dark" size="small" />
                  </Box>
                </button>
              </Box>
              <Box className="flex items-center">
                <button css={styles.button} onClick={() => setShowModal(false)}>
                  <Box
                    backgroundColor="greyscale.lightest"
                    className="p-2 rounded-md flex justify-center items-center"
                  >
                    <Icon.Close size="small" color="dark" />
                  </Box>
                </button>
              </Box>
            </Box>
            <Box className="border-t border-gray-300" />
            <img
              src={items[selectedItemIndex].source}
              alt={items[selectedItemIndex].alt}
              css={styles.modalImage}
            />
            {items[selectedItemIndex].caption && (
              <Text fontSize="small" styles={styles.caption}>
                {items[selectedItemIndex].caption}
              </Text>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};
