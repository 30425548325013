import React from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Modal,
  Button,
  Form,
  Field,
  Input,
  theme,
  Message,
} from "@clearabee/ui-library";
import { IGarage } from "@clearabee/api-schemas";
import { instance, readAddresses } from "@clearabee/ui-sdk";
import { toasts } from "helpers/toasts";
import { validationSchema } from "./validation";
import { LoadingOverlay } from "components/common/components";
import { getErrorMessage } from "helpers";

interface IGarageDetailsProps {
  isVisible: boolean;
  groupOptions: { label: string; value: string }[];
  garage?: IGarage;
  onClose: () => void;
  onChange?: (data: IGarage) => void;
}

export const GarageDetails = ({
  isVisible,
  groupOptions,
  garage,
  onClose,
  onChange,
}: IGarageDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("garages");

  const { mutate, isLoading, error, reset } = useMutation(
    async (values: IGarage) => {
      const { latitude, longitude } = await readAddresses(values.postcode);

      return (
        await instance.jobs.patchGarage(String(values.id), {
          ...values,
          latitude,
          longitude,
        })
      ).data;
    },
    {
      onSuccess: (data) => {
        toasts.success({
          message: translate("success.patch"),
        });
        onChange?.(data);
      },
    },
  );

  return (
    <>
      {garage && (
        <Modal
          width={600}
          modalVisible={isVisible}
          onClose={() => {
            reset();
            onClose();
          }}
          styles={{
            padding: `${theme.spacing.small} ${theme.spacing.large}`,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.small} ${theme.spacing.large}`,
            },
          }}
        >
          <Form
            onSubmit={(values) => {
              mutate(values);
            }}
            initialValues={garage}
            enableReinitialize
            validationSchema={validationSchema}
            className="text-left"
          >
            {({ submitCount, errors }) => {
              const hasErrors = Object.keys(errors).length > 0;

              return (
                <>
                  {/* MODAL LOADING OVERLAY */}
                  {isLoading && (
                    <LoadingOverlay
                      backgroundLoadingContainerStyles={{
                        borderRadius: theme.spacing.small,
                      }}
                    />
                  )}
                  {/* HEADING */}
                  <Box className="mt-5 mb-2">
                    <Heading color="brand" level={4}>
                      {translate("headings.edit")}
                    </Heading>
                  </Box>

                  {/* DIVIDER */}
                  <Box className="border-t border-gray-300 my-1 flex flex-col h-1" />

                  <Box styles={{ maxHeight: "600px", overflowY: "scroll" }}>
                    <Box className="flex gap-x-2">
                      {/* NAME */}
                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="name"
                        label={translate("form.labels.name")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate("form.placeholders.name")}
                          />
                        )}
                      </Field>
                      {/* COMPANY CODE */}
                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="companyCode"
                        label={translate("form.labels.companyCode")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "form.placeholders.companyCode",
                            )}
                          />
                        )}
                      </Field>
                    </Box>
                    {/* SITE CODE */}
                    <Field
                      styles={{
                        flex: 1,
                      }}
                      name="siteCode"
                      label={translate("form.labels.siteCode")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={translate("form.placeholders.siteCode")}
                        />
                      )}
                    </Field>

                    {/* GROUP */}
                    <Field
                      styles={{
                        flex: 1,
                        marginTop: theme.spacing.xsmall,
                        marginBottom: theme.spacing.small,
                      }}
                      name="group"
                      label={translate("form.labels.group")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          options={groupOptions}
                          placeholder={translate("form.placeholders.group")}
                        />
                      )}
                    </Field>
                    {/* ADDRESS */}
                    <Field
                      styles={{ flex: 1 }}
                      name="address"
                      label={translate("form.labels.address")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("form.placeholders.address")}
                        />
                      )}
                    </Field>
                    <Box className="flex gap-x-2">
                      {/* POSTCODE */}
                      <Field
                        styles={{ flex: 1 }}
                        name="postcode"
                        label={translate("form.labels.postcode")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "form.placeholders.postcode",
                            )}
                          />
                        )}
                      </Field>
                      {/* CITY */}
                      <Field
                        styles={{ flex: 1 }}
                        name="city"
                        label={translate("form.labels.city")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate("form.placeholders.city")}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box className="flex gap-x-2">
                      {/* CONTACT NAME */}
                      <Field
                        styles={{ flex: 1 }}
                        name="contactName"
                        label={translate("form.labels.contactName")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "form.placeholders.contactName",
                            )}
                          />
                        )}
                      </Field>
                      {/* CONTACT PHONE */}
                      <Field
                        styles={{ flex: 1 }}
                        name="contactPhone"
                        label={translate("form.labels.contactPhone")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate(
                              "form.placeholders.contactPhone",
                            )}
                          />
                        )}
                      </Field>
                    </Box>
                    {/* CONTACT EMAIL */}
                    <Field
                      styles={{ flex: 1 }}
                      name="contactEmail"
                      label={translate("form.labels.contactEmail")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "form.placeholders.contactEmail",
                          )}
                        />
                      )}
                    </Field>
                    {/* NOTES */}
                    <Field
                      styles={{ flex: 1 }}
                      name="notes"
                      label={translate("form.labels.notes")}
                    >
                      {({ field }) => (
                        <Input.Textarea
                          {...field}
                          autoGrow={false}
                          styles={{ height: 80 }}
                          placeholder={translate("form.placeholders.notes")}
                        />
                      )}
                    </Field>
                  </Box>
                  {/* DISPLAY FIELDS ERROR */}
                  {submitCount > 0 && hasErrors && (
                    <Box className="flex gap-8 mt-2">
                      <Message type="error" className="text-left">
                        {translate("errors.fieldMissing")}
                      </Message>
                    </Box>
                  )}
                  {/* SUBMIT BUTTON */}
                  <Box className="flex justify-center mt-2">
                    <Button
                      type="submit"
                      color="warning"
                      disabled={isLoading}
                      size="small"
                    >
                      {translate("buttons.submit")}
                    </Button>
                  </Box>
                  {/* DISPLAY ERROR */}
                  {!!error && (
                    <Box className="flex justify-center">
                      <Box
                        className="px-2 py-px rounded-md mt-3"
                        backgroundColor="negative"
                        color="light"
                      >
                        {getErrorMessage(error)}
                      </Box>
                    </Box>
                  )}
                </>
              );
            }}
          </Form>
        </Modal>
      )}
    </>
  );
};
