import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { OptionTypeBase } from "react-select";
import { toasts } from "helpers/toasts";
import {
  IQuestionType,
  IWorksheetPatchBody,
  IWorksheet,
  IQuestionTypeValue,
} from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import {
  Field,
  Panel,
  Icon,
  Heading,
  Button,
  Input,
  Form,
  useModal,
  Box,
  OptionProps,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import {
  RequireID,
  TQuestion,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../components";
import {
  createConditionalQuestions,
  getPatchBody,
  statusMap,
} from "./updateWorksheetHelpers";
import { WorksheetQuestion, QuestionModal } from "./components";
import { validationSchema } from "./updateWorksheetValidation";
import { styles } from "./updateWorksheet.styles";
import { getInitialValues } from "./updateWorksheetInitialValues";

export const UpdateWorksheet = (): React.ReactElement => {
  const [translate] = useTranslation("worksheets");
  const [Modal, setShowModal] = useModal();
  const [selectedQuestionHistory, setSelectedQuestionHistory] =
    useState<RequireID<TQuestion>[]>();

  const worksheetId = parseInt(useParams<{ id: string }>().id);

  // API CALLS
  const getWorksheetById = async (worksheetId: Required<IWorksheet>["id"]) => {
    const { data } = await instance.worksheets.getWorksheet(
      String(worksheetId), // String required as is a path param
    );

    return data;
  };

  const {
    data: worksheet,
    refetch: refetchWorksheet,
    isRefetching: worksheetIsRefetching,
    isLoading: worksheetIsLoading,
  } = useQuery(
    ["getWorksheetbyId", worksheetId],
    () => getWorksheetById(worksheetId),
    {
      onError: () => {
        toasts.error({
          message: translate("updateWorksheet.toasts.genericError"),
        });
      },
    },
  );

  const getOrderTypes = async () => {
    const { data } = await instance.catalogues.getOrderTypes({
      params: { limit: 1000 },
    });
    return data;
  };

  const { data: orderTypes } = useQuery(["getOrderTypes"], getOrderTypes, {
    onError: () => {
      toasts.error({
        message: translate("updateWorksheet.toasts.genericError"),
      });
    },
  });

  const getQuestionTypes = async () => {
    const { data } = await instance.worksheets.getQuestionTypes();
    return data.items as RequireID<IQuestionType>[];
  };

  const { data: questionTypes } = useQuery(
    "getQuestionTypes",
    getQuestionTypes,
    {
      onError: () => {
        toasts.error({
          message: translate("updateWorksheet.toasts.genericError"),
        });
      },
    },
  );

  const getQuestionTypeValues = async () => {
    const { data } = await instance.worksheets.getQuestionTypeValues({
      params: { limit: 1000 },
    });

    return data.items as RequireID<IQuestionTypeValue>[];
  };

  const { data: questionTypeValues } = useQuery(
    ["getQuestionTypeValues"],
    getQuestionTypeValues,
    {
      onError: () => {
        toasts.error({
          message: translate("updateWorksheet.toasts.genericError"),
        });
      },
    },
  );

  // LIST OPTIONS
  const statusOptions: OptionProps[] = Object.entries(statusMap).map(
    ([statusId, status]) => ({ label: status, value: Number(statusId) }),
  );

  const orderTypesOptions =
    orderTypes?.items.map(({ name, id }) => ({
      label: name,
      value: id as number, // Cast, as id is primary key, so will def. be returned from API
    })) ?? [];

  // QUESTIONS (as tree)
  const questionsAsTree = createConditionalQuestions(
    (worksheet?.questions as TQuestion[]) ?? [],
  );

  // UPDATE WORKSHEET
  const { mutate: patchWorksheet, isLoading: isMutationLoading } = useMutation(
    async (patch: IWorksheetPatchBody) => {
      return await instance.worksheets.patchWorksheet(
        String(worksheet?.id as number),
        patch,
      );
    },

    {
      onSuccess: () => {
        toasts.success({
          message: translate("updateWorksheet.toasts.patchSuccess"),
        });
        refetchWorksheet();
      },
      onError: () => {
        toasts.error({
          message: translate("updateWorksheet.toasts.patchError"),
        });
      },
    },
  );

  if (!worksheet) {
    return <LoadingOverlay />;
  }

  const initialValues = getInitialValues(worksheet);

  return (
    <>
      {/* Loading */}
      {(worksheetIsLoading || worksheetIsRefetching) && <LoadingOverlay />}

      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const patchBody = getPatchBody(values);

          patchWorksheet(patchBody);
        }}
        validateOnChange={false}
        className="max-w-screen-2xl mx-auto py-10 relative"
      >
        {({ isValid, errors: validationErrors }) => {
          return (
            <>
              <Panel styles={styles.topPanel}>
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-between">
                  <Heading fontSize="large" color="brand">
                    {translate("updateWorksheet.headings.update")}
                  </Heading>
                  <Button
                    color="accent"
                    disabled={!isValid || isMutationLoading}
                    type="submit"
                    size="small"
                  >
                    {isMutationLoading ? (
                      <Box className="flex justify-center">
                        <Icon.Loading color="dark" size="medium" />
                      </Box>
                    ) : (
                      translate("updateWorksheet.buttons.update")
                    )}
                  </Button>
                </Box>
                <Box className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-end">
                  <Field
                    styles={{ flex: 1 }}
                    name="name"
                    label={translate("updateWorksheet.labels.name")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "updateWorksheet.placeholders.name",
                        )}
                      />
                    )}
                  </Field>

                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      styles={{ flex: 1 }}
                      name="statusId"
                      label={translate("updateWorksheet.labels.status")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isClearable
                          placeholder=""
                          options={statusOptions}
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.largeTooltipPanel}>
                          {translate("updateWorksheet.tooltips.status")}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>

                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      name="includeForSnsEvents"
                      label={translate(
                        "updateWorksheet.labels.includeForSnsEvents",
                      )}
                    >
                      {({ field }) => (
                        <Box className="flex flex-row">
                          <Input.Toggle
                            {...field}
                            defaultChecked={initialValues.includeForSnsEvents}
                          />
                        </Box>
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.tooltips.includeForSnsEvents",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                  <Box className="flex flex-1 flex-row items-start">
                    <Field
                      name="showForSubcontractor"
                      label={translate(
                        "updateWorksheet.labels.showForSubcontractor",
                      )}
                    >
                      {({ field }) => (
                        <Box className="flex flex-row">
                          <Input.Toggle
                            {...field}
                            defaultChecked={initialValues.showForSubcontractor}
                          />
                        </Box>
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.tooltips.showForSubcontractor",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                </Box>
                <Box className="flex flex-col lg:flex-row gap-x-6 justify-end">
                  <Field
                    styles={{ flex: 1 }}
                    name="orderTypeIds"
                    label={translate("updateWorksheet.labels.orderTypes")}
                  >
                    {({ field }) => {
                      return (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder={translate(
                            "updateWorksheet.placeholders.orderTypes",
                          )}
                          options={orderTypesOptions}
                          onChange={(options: readonly OptionTypeBase[]) => {
                            if (!options) {
                              field.onChange([]); // Clear the field if no options are selected
                              return;
                            }

                            field.onChange(options.map(({ value }) => value));
                          }}
                          value={
                            field.value?.length
                              ? orderTypesOptions
                                  .filter(({ value: orderTypeId }) => {
                                    return field.value.includes(orderTypeId);
                                  })
                                  // Sort the order-types alphabetically, so large lists are easy to read
                                  .sort(
                                    ({ label: labelA }, { label: labelB }) =>
                                      labelA.localeCompare(labelB),
                                  )
                              : []
                          }
                          className="text-base"
                        />
                      );
                    }}
                  </Field>
                </Box>
              </Panel>
            </>
          );
        }}
      </Form>

      <Box className="flex flex-row justify-end mb-6">
        <Button
          onClick={() => {
            setSelectedQuestionHistory(undefined);
            setShowModal(true);
          }}
          size="small"
          color="accent"
          disabled={isMutationLoading}
        >
          {translate("updateWorksheet.buttons.add")}
        </Button>
      </Box>

      <Box>
        {!!questionsAsTree.length &&
          questionsAsTree.map((question, index) => {
            return (
              <WorksheetQuestion
                key={`${question.question}-${index}`}
                onClick={(q) => {
                  setSelectedQuestionHistory([
                    ...(selectedQuestionHistory ?? []),
                    q as any,
                  ]);
                  setShowModal(true);
                }}
                question={question}
              />
            );
          })}
      </Box>

      {/* QUESTION MODAL*/}
      {!!questionTypes && !!questionTypeValues && (
        <>
          <Modal
            onClose={() => {
              setShowModal(false);
              setSelectedQuestionHistory(undefined);
            }}
            styles={styles.modal}
          >
            <QuestionModal
              questionHistory={selectedQuestionHistory}
              questions={questionsAsTree}
              questionTypes={questionTypes}
              questionTypeValues={questionTypeValues}
              worksheetId={worksheetId}
              refetchWorksheet={refetchWorksheet}
              setModalVisible={setShowModal}
              setQuestionHistory={setSelectedQuestionHistory}
            />
          </Modal>
        </>
      )}
    </>
  );
};
