import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@clearabee/ui-library";
import {
  SelectService,
  SelectProducts,
  PaymentConfirmation,
  OrderLookup,
} from "../steps";
import { StepsWrapper } from ".";
import { MultiForm } from "../../../common/components";
import { ProvideCatalogue } from "../../../../contexts";
import { formStateInitialValues } from "../validation";

export const Steps = (): React.ReactElement => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");

  /**
   * Steps.
   */
  const steps = [
    {
      id: "order-lookup",
      name: t("create.steps.orderLookup.title"),
      description: t("create.steps.orderLookup.description"),
      component: OrderLookup,
    },
    {
      id: "select-service",
      name: t("create.steps.selectService.title"),
      description: t("create.steps.selectService.description"),
      component: SelectService,
    },
    {
      id: "select-products",
      name: t("create.steps.selectProductOptions.titleVT"),
      description: t("create.steps.selectProductOptions.descriptionVT"),
      component: SelectProducts,
    },
    {
      id: "payment-confirmation",
      name: t("create.steps.confirmOrder.titlePayment"),
      description: t("create.steps.confirmOrder.descriptionPayment"),
      component: PaymentConfirmation,
    },
  ];

  return (
    <Box className="max-w-screen-2xl px-4 py-8 lg:px-16 ml-auto mr-auto">
      <ProvideCatalogue catalogueId="PORTAL" postcode={null} companyCode={null}>
        <MultiForm
          steps={steps}
          initialValues={{
            ...formStateInitialValues,
          }}
          wrapperComponent={StepsWrapper}
        />
      </ProvideCatalogue>
    </Box>
  );
};
