import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { readJobImages } from "api";
import { useTranslation } from "react-i18next";
import {
  Loader,
  Gallery,
  GalleryItem,
  Panel,
  theme,
  Icon,
  UnstyledButton,
  Box,
  Modal,
  Heading,
  Button,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { IJobImage } from "@clearabee/api-schemas";
import { toasts } from "helpers";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { UploadImages } from "./uploadImages/uploadImages";

const ImageBlock = ({
  imageData,
  section,
  removable = false,
  handleClick,
  onRemove,
}: {
  imageData: GalleryItem[] | undefined;
  section: string;
  removable?: boolean;
  handleClick: (index: number) => void;
  onRemove: (image: GalleryItem) => void;
}): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <Box className="w-full">
      <span className="text-xs text-black font-semibold">
        <p className="my-2 text-primary-400 text-xl">
          {!!imageData?.length && (
            <>
              <span className="capitalize">{`${section} `}</span>
              {translate("update.imagesAvailable", {
                length: imageData?.length,
              })}
            </>
          )}

          {!imageData?.length &&
            translate("update.noImagesAvailable", {
              type: section,
            })}
        </p>
      </span>
      <Box className="flex flex-wrap gap-2 w-full h-full mt-4">
        {imageData?.map((image, index) => (
          <Box key={index} className="flex flex-col my-2 mx-5">
            <Box className="rounded-md w-26 h-26" key={index}>
              <img
                className="rounded-md object-cover w-full h-full cursor-pointer"
                src={image.source}
                onClick={() => handleClick(index)}
              />
            </Box>
            {removable && (
              <Box className="flex justify-center -mt-4">
                <UnstyledButton
                  onClick={() => {
                    onRemove(image);
                  }}
                >
                  <Box
                    backgroundColor="warning"
                    className="p-2 -ml-1 rounded-3xl justify-center"
                  >
                    <Icon.Trash className="-mr-px" width={20} height={20} />
                  </Box>
                </UnstyledButton>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const JobImages = ({
  jobRef,
  jobImages,
  onImagesChange,
}: {
  jobRef: string | undefined;
  jobImages: IJobImage[];
  onImagesChange: () => void;
}): React.ReactElement => {
  const [translate] = useTranslation("common");
  const { doesUserHaveRole } = useAuthContext();
  const [image, setImage] = useState<GalleryItem | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeStaff = doesUserHaveRole(roles.CLEARABEE_CUSTOMER_SERVICE);

  const {
    data: images,
    isLoading: imagesLoading,
    isRefetching: imagesRefetching,
    refetch: refetchImages,
  } = useQuery(
    ["readJobImages", jobRef],
    () => readJobImages(encodeURIComponent(jobRef || "")),
    {
      onSuccess: () => {
        if (image) {
          setImage(null);
          toasts.success({
            message: translate("titles.imageDeleted"),
          });
        }
      },
      retry: false,
      enabled: !!jobRef,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const {
    mutate: deleteImage,
    isLoading: deleteImageIsLoading,
    isError,
  } = useMutation(
    async (image: GalleryItem) => {
      const matchingImage = jobImages.find(({ url }) => url === image.source);
      if (matchingImage) {
        await instance.jobs.deleteJobImage(
          encodeURIComponent(jobRef || ""),
          String(matchingImage.id),
        );
      }
    },
    {
      onSuccess: () => {
        refetchImages();
      },
      onError: (error: Error) => {
        setErrorMessage(error.message);
      },
    },
  );

  const sortedImages = images?.reduce((final, next) => {
    const type = next.type.replace("bigchange", "job");
    if (final[type]) final[type].push(next.url);
    else final[type] = [next.url];
    return final;
  }, {} as { [key: string]: string[] });

  /**
   * This filter job images only uploading directly from bigchange
   */
  if (jobRef && sortedImages && sortedImages.job) {
    const jobImages = sortedImages.job.filter((image) => {
      // get the filename image from last index splitted by forward slash
      const fileName = image.split("/").at(-1);

      // get the unix timestamp from fileName
      const timeStampFromFileName = new Date(
        Number(fileName?.split("_").at(0) || 0),
      );

      // check if that timeStamp is valid
      const isValidTimeStamp =
        timeStampFromFileName instanceof Date &&
        !isNaN(timeStampFromFileName.valueOf());

      // jobRef does include /, but the fileName does not so we need to remove it
      // since fileName of images uploaded directly from bigchange does not contain jobRef or unix timestamp
      if (
        !!fileName &&
        !fileName.includes(jobRef.replace("/", "")) &&
        !isValidTimeStamp
      ) {
        return image;
      }
    });

    if (!!jobImages.length) {
      sortedImages.job = jobImages; // assign jobImages back
    } else {
      delete sortedImages.job; // delete property job in sortedImage if no jobImages found
    }
  }

  return (
    <Box className="mt-3">
      {imagesLoading && <Loader text={translate("loading2")} color="brand" />}
      <Box className="grid lg:grid-cols-2 gap-x-10 gap-y-10">
        {sortedImages &&
          Object.keys(sortedImages).map((type, index) => (
            <Panel
              key={index}
              style={{
                padding: theme.spacing.small,
              }}
              shadow={false}
            >
              <Gallery
                key={type}
                items={sortedImages[type].map((image) => ({ source: image }))}
              >
                {({ items, handleClick }) => (
                  <ImageBlock
                    section={type}
                    imageData={items}
                    handleClick={handleClick}
                    onRemove={(image) => setImage(image)}
                    removable={isClearabeeAdmin}
                  />
                )}
              </Gallery>
            </Panel>
          ))}
      </Box>
      <Box>
        {/* upload image is restricted for Clearabee admin and staff only */}
        {(isClearabeeAdmin || isClearabeeStaff) && (
          <Box className="my-10">
            <UploadImages
              jobRef={jobRef ?? ""}
              deleteButtonText="Delete All"
              uploadButtonText="Attach Photo"
              multiple
              onFileChange={(files) => {
                if (!files.length) {
                  refetchImages();
                  onImagesChange();
                }
              }}
            />
          </Box>
        )}
      </Box>
      {image && (
        <Modal
          width={400}
          styles={{
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.large} ${theme.spacing.large}`,
            },
          }}
        >
          <Heading color="brand" level={5}>
            {translate("titles.confirmDeleteImage")}
          </Heading>
          {deleteImageIsLoading || imagesRefetching ? (
            <Box className="flex justify-center mt-8">
              <Icon.Loading />
            </Box>
          ) : (
            <>
              <Box className="flex justify-center gap-2 mt-8">
                <Button
                  size="small"
                  color="negative"
                  onClick={() => setImage(null)}
                >
                  {translate("cancel")}
                </Button>
                <Button
                  size="small"
                  color="positive"
                  onClick={() => {
                    deleteImage(image);
                  }}
                >
                  {translate("form.buttons.remove")}
                </Button>
              </Box>
              {!!errorMessage && isError && (
                <Box className="flex justify-center">
                  <Box
                    className="mt-3 flex px-3 rounded-md"
                    color="light"
                    backgroundColor="negative"
                  >
                    {errorMessage}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Modal>
      )}
    </Box>
  );
};
