import React from "react";
import { IPaymentLink } from "@clearabee/api-schemas";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Modal,
  theme,
  Text,
  Button,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";

interface CancelPaymentLinkModalProps {
  cancelPaymentLink: IPaymentLink | null;
  setCancelPaymentLink: (value: IPaymentLink | null) => void;
  isLoading?: boolean;
  onCancelPaymentLink: (basketToken: string) => void;
}

export const CancelPaymentLinkModal = ({
  cancelPaymentLink,
  setCancelPaymentLink,
  onCancelPaymentLink,
  isLoading = false,
}: CancelPaymentLinkModalProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { orderRef, basketToken } = cancelPaymentLink || {};

  return (
    <Modal
      width={400}
      modalVisible={!!cancelPaymentLink}
      styles={{
        padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
        paddingBottom: theme.spacing.xlarge,
        [`@media (min-width: ${theme.screens.medium})`]: {
          padding: `${theme.spacing.large} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.large,
        },
      }}
    >
      {/* MODAL LOADING OVERLAY */}
      {isLoading && (
        <LoadingOverlay
          iconSize="xlarge2"
          backgroundLoadingContainerStyles={{
            borderRadius: theme.spacing.small,
          }}
        />
      )}
      <Heading color="brand" level={4}>
        {translate("paymentLink.headings.cancelPaymentLink")}
      </Heading>
      <Text color="brand" className="font-bold">
        ({orderRef || ""})
      </Text>
      <Box className="flex justify-center mt-8">
        <Button
          size="small"
          color="accent"
          className="mr-5"
          onClick={() => onCancelPaymentLink(basketToken || "")}
        >
          {translate("paymentLink.buttons.yesCancel")}
        </Button>
        <Button
          size="small"
          color="negative"
          onClick={() => setCancelPaymentLink(null)}
        >
          {translate("paymentLink.buttons.goBack")}
        </Button>
      </Box>
    </Modal>
  );
};
