import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { Link } from "react-router-dom";
import clipboardCopy from "clipboard-copy";
import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  Text,
  theme,
  UnstyledButton,
} from "@clearabee/ui-library";
import {
  useMultiFormContext,
  useAuthContext,
  useCatalogueContext,
} from "hooks";
import { createBasketPaymentLink } from "api";
import { IFormState } from "../types";
import { Job } from "models";
import { IStripeWithBillingForm } from "../../../common/components/stripeWithBillingForm";
import { StripeBillingForm } from "components/common/components/stripeBillingForm";
import { Modal as ComponentModal } from "components/core";
import { LoadingOverlay, SuccessModal } from "../../../common/components";
import { handleException } from "helpers/handleException";
import { generatePaymentURL, getErrorMessage, toasts } from "helpers";
import ManageUsers from "images/manage-users.svg";

/**
 * Payment step component.
 */
export const PaymentConfirmation = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const [isSuccess, setSuccess] = useState(false);
  const { catalogueId } = useCatalogueContext();
  const { getCurrentUserCurrentCompany, refreshTokenAndQueries } =
    useAuthContext();
  const { companyCode } = getCurrentUserCurrentCompany() ?? {};
  const { formState, reset, previousStep } = useMultiFormContext<IFormState>();
  const [retryBasketCount, setRetryBasketCount] = useState(0);

  const { job, items } = formState;

  const {
    ref,
    contactFirstName: firstName,
    contactLastName: lastName,
    contactEmail: email,
    contactPhoneNumber: phoneNumber,
    addressLine1,
    addressLine2,
    addressCity,
    addressCounty,
    addressPostcode,
    date,
  } = job as Job;
  const stripeBillingDetails: IStripeWithBillingForm["contact"] = {
    name: firstName || "",
  };
  // stripe dosen't like passing undefined props, so we leave out of object if not set
  if (phoneNumber) {
    stripeBillingDetails.phone = phoneNumber;
  }
  if (email) {
    stripeBillingDetails.email = email;
  }

  // Set basket products
  const basketProducts = items.map((item) => {
    return {
      sku: item.sku,
      qty: item.quantity,
      price: item.price || undefined,
    };
  });

  const selectedItemsTotalPrice = basketProducts.reduce(
    (acc, item) => acc + (item?.price || 0) * item.qty,
    0,
  );

  const data = {
    catalogueId,
    items: basketProducts,
    deliveryAddress: {
      line1: addressLine1 || "",
      line2: addressLine2 || "",
      city: addressCity || "",
      county: addressCounty || "",
      postcode: addressPostcode || "",
    },
    date: new Date(date ? date : "").toISOString(),
    contact: {
      firstName: firstName || "",
      lastName: firstName || "",
      email,
      phoneNumber,
    },
    existingOrderRef: ref,
  };

  const {
    mutate: processBasket,
    data: basketData,
    isLoading: isLoadingBasket,
    error,
  } = useMutation(
    "additionalPaymentsCreateBasket",
    async () => (await instance.catalogues.postBasket(data)).data,
    {
      onSuccess: async (data) => {
        const isBasketExpire =
          !!data && selectedItemsTotalPrice !== data.originalCost;

        // If basket is not expired, do nothing
        if (!isBasketExpire) {
          return;
        }

        // If basket still is expired and we have tried one more time, handle exception and go back to previous step
        if (isBasketExpire && retryBasketCount > 0) {
          previousStep();
          setRetryBasketCount(0);

          handleException(
            new Error("Failed to create a basket, please try again later"),
            {
              basketToken: String(basketData?.basketToken),
              data,
            },
            {
              type: "Additional Payments",
            },
          );
          return;
        }

        await refreshTokenAndQueries();
        processBasket();
        setRetryBasketCount(retryBasketCount + 1);
      },
      onError: (error) => {
        handleException(
          error,
          {
            basketToken: String(basketData?.basketToken),
            data,
          },
          {
            type: "Additional Payments",
          },
          "",
          false,
        );
      },
    },
  );

  const {
    mutate: createPaymentLink,
    isLoading: isPaymentLinkLoading,
    isSuccess: paymentLinkSuccess,
    reset: resetPaymentLink,
  } = useMutation(
    async () => await createBasketPaymentLink(String(basketData?.basketToken)),
    {
      onError: (error) => {
        handleException(
          error,
          {
            basketToken: String(basketData?.basketToken),
          },
          {
            type: "Additional Payments",
          },
          getErrorMessage(error),
        );
      },
    },
  );

  /**
   * Restart the job creation flow.
   */
  const restartCreateVTJob = () => {
    resetPaymentLink();
    reset(true);
  };

  /**
   * Create basket as we land on this page.
   */
  useEffect(() => {
    processBasket();
  }, []);

  return (
    <>
      {/* MAIN LOADING OVERLAY */}
      {isLoadingBasket && <LoadingOverlay />}
      <Box className="flex justify-center">
        <Box className="w-full md:min-w-screen-lg pb-12 md:pb-6">
          <Box className="md:flex px-6 py-8 md:px-10 md:max-w-screen-md order-summary mx-auto shadow-filter bg-cover rounded-xl bg-pattern w-full pointer-events-auto is-visible">
            <Box className="w-full md:pr-5 md:w-1/2">
              <Box className="relative flex -mx-4 text-white">
                <Box className="w-auto px-4 pr-16 ">
                  <h4 className="text-xl font-bold mb-3">
                    {translate("create.orderSummary.titles.details")}
                  </h4>
                  <p className="text-2xs">
                    {translate("create.orderSummary.titles.name")}
                  </p>
                  <p className="leading-none mb-3">{`${firstName} ${lastName}`}</p>
                  {phoneNumber && (
                    <>
                      <p className="text-2xs">
                        {translate("create.orderSummary.titles.phone")}
                      </p>
                      <p className="leading-none mb-3">{phoneNumber}</p>
                    </>
                  )}
                  {email && (
                    <>
                      <p className="text-2xs">
                        {translate("create.orderSummary.titles.email")}
                      </p>
                      <p className="leading-none mb-3">{email}</p>
                    </>
                  )}
                  <p className="text-2xs">
                    {translate("create.orderSummary.titles.collection")}
                  </p>
                  {addressLine1 && (
                    <p className="leading-none">{addressLine1}</p>
                  )}
                  {addressLine2 && (
                    <p className="leading-none">{addressLine2}</p>
                  )}
                  {addressCity && <p className="leading-none">{addressCity}</p>}
                  {addressCounty && (
                    <p className="leading-none">{addressCounty}</p>
                  )}
                  {addressPostcode && (
                    <p className="leading-none">{addressPostcode}</p>
                  )}
                </Box>
              </Box>
              <Box className="my-8 border-b border-white opacity-25 w-full" />
              <Box className="relative text-white">
                <>
                  <h4 className="text-xl font-bold">
                    {translate("create.orderSummary.titles.order")}
                  </h4>
                  <Box className="flex flex-row items-center text-2xs py-3">
                    <Box className="order-summary-qty w-10 pr-2">
                      {translate("create.orderSummary.titles.quantity")}
                    </Box>
                    <Box className="w-full">
                      {translate("create.orderSummary.titles.product")}
                    </Box>
                    <Box className="order-summary-price text-right">
                      {translate("create.orderSummary.titles.price")}
                    </Box>
                  </Box>
                </>
              </Box>
              {!isLoadingBasket && !!basketData?.items && (
                <Box className="text-white">
                  {basketData.items.map((item) => {
                    const { qty, lineCost, sku } = item;

                    return (
                      <Box
                        key={`basket-${sku}`}
                        className="flex mb-2 text-xs pb-4 pt-2"
                      >
                        <Box className="order-summary-qty w-10 pr-2">
                          {`x${qty}`}
                        </Box>
                        <Box className="w-full truncate">{sku}</Box>
                        <Box className="order-summary-price text-right">
                          {`£${(lineCost || 0).toFixed(2)}`}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Box className="border-b my-8 md:my-0 border-white opacity-25 w-full visible md:invisible" />
            </Box>
            <Box className="w-full md:pl-5 md:w-1/2">
              <Box className="mb-6">
                <h4 className="text-xl font-bold text-white mb-4">
                  {translate("create.orderSummary.titles.billingDetails")}
                </h4>
                {!isLoadingBasket && !!basketData?.clientSecret && (
                  <StripeBillingForm
                    clientSecret={basketData.clientSecret}
                    contact={stripeBillingDetails}
                    amount={basketData?.totalCost || 0}
                    disableButton={
                      !basketData?.basketToken ||
                      !basketData?.orderRef ||
                      isPaymentLinkLoading
                    }
                    onPaymentSuccess={() => setSuccess(true)}
                    showCardholderNameField
                  />
                )}
              </Box>
              {email && (
                <Box className="flex">
                  <Button
                    id="sendPaymentLinkButton"
                    size="small"
                    color="warning"
                    className="mx-auto mb-6 flex items-center gap-x-1"
                    disabled={!basketData?.basketToken || isPaymentLinkLoading}
                    onClick={() => createPaymentLink()}
                  >
                    {translate("create.sendPaymentLink")}
                    {isPaymentLinkLoading && <Icon.Loading size="small" />}
                  </Button>
                </Box>
              )}
              <Box className="border-b border-white opacity-25 w-full" />
              <Box className="flex flex-row justify-between items-center lg:pt-5 lg:mt-5 text-white">
                <h4 className="text-lg md:text-xl font-bold">
                  {translate("create.orderSummary.titles.total")}
                </h4>
                {!isLoadingBasket && (
                  <Box className="text-right">
                    <h4 className="text-lg md:text-2xl font-bold leading-extra-tight">
                      £
                      <span id="paymentTotal">
                        {(basketData?.totalCost || 0).toFixed(2)}
                      </span>
                    </h4>
                    <h6 className="text-2xs">
                      {translate("create.orderSummary.vat")}
                    </h6>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className="max-w-sm mx-auto px-2 pt-1 pb-4 mt-8 text-justify">
            <p>{translate("create.steps.confirmOrder.terms")}</p>
          </Box>
        </Box>
      </Box>
      <SuccessModal
        title={translate("modal.headings.additionalPayment")}
        buttonOne={{
          label: translate("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        buttonTwo={{
          label: translate("modal.buttons.labels.takeAnotherPayment"),
          close: true,
        }}
        hideCallback={restartCreateVTJob}
        visible={isSuccess}
      />
      {/* SUCCESSFULLY SEND PAYMENT LINK COMPONENT MODAL */}
      <ComponentModal
        visible={paymentLinkSuccess}
        title={
          <>
            {translate("modal.headings.ref")}
            <Text as="span" fontSize="small" data-testid="createJobSuccessRef">
              {basketData?.orderRef}
            </Text>
          </>
        }
        image={ManageUsers}
      >
        <Box className="my-3">
          <Text fontSize="xsmall">
            {translate("modal.text.customerWillReceiveAnEmail")}
          </Text>
        </Box>
        <Box className="pl-5 flex mt-3 justify-center items-center gap-x-2">
          <Link
            to={{
              pathname: generatePaymentURL(basketData?.basketToken ?? ""),
            }}
            style={{
              textDecoration: "underline",
              textUnderlineOffset: theme.spacing.xsmall2,
              fontWeight: "bold",
            }}
            target="_blank"
          >
            {translate("headings.paymentLink")}
          </Link>
          <UnstyledButton
            type="button"
            onClick={async () => {
              await clipboardCopy(
                generatePaymentURL(basketData?.basketToken ?? ""),
              );
              toasts.success({
                message: translate("toasts.copiedToClipboard"),
              });
            }}
          >
            <Icon.Copy size="medium" color="brand" />
          </UnstyledButton>
        </Box>
        <Link
          to="/"
          className="w-48 mt-3 btn btn-primary btn-filled ut-transition"
        >
          {translate("modal.buttons.labels.backToDashboard")}
        </Link>
        <button
          type="button"
          onClick={restartCreateVTJob}
          className="w-48 mt-3 btn btn-secondary hover:btn-secondary-hover ut-transition"
        >
          {translate("modal.buttons.labels.takeAnotherPayment")}
        </button>
      </ComponentModal>
      {/* ERROR MODAL */}
      <Modal
        modalVisible={!!error}
        width={400}
        styles={{
          padding: `${theme.spacing.xlarge} ${theme.spacing.large}`,
          paddingBottom: theme.spacing.xlarge,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: `${theme.spacing.xlarge} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
          },
        }}
      >
        <Box className="flex flex-col items-center gap-y-5">
          <Box className="flex flex-col justify-center items-center gap-y-2">
            <Icon.Attention color="negative" size="large" />
            <Heading color="negative" level={4}>
              {translate("errors.failedToCreateBasket")}
            </Heading>
          </Box>
          <Text>{getErrorMessage(error)}</Text>
          <Box className="mt-2 flex justify-center items-center gap-x-3">
            <Button
              type="button"
              onClick={() => previousStep()}
              color="warning"
              size="small"
            >
              {translate("create.buttons.previousStep")}
            </Button>
            <Button
              type="button"
              onClick={() => processBasket()}
              color="brand"
              size="small"
            >
              {translate("create.buttons.retry")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
