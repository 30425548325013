import React from "react";
import { useMutation, useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { toasts } from "../../helpers";

/**
 * Import Yup field validation.
 */
import { reset } from "./validation";

/**
 * Get API function.
 */
import { useAuthContext } from "../../hooks";

/**
 * Import components.
 */
import {
  TitleReset,
  InputPassword,
  Button,
  PasswordRequirements,
} from "./components";
import { Trail } from "../core";

/**
 * Set our initial form values.
 */
const initialValues = {
  password: "",
  passwordConfirmation: "",
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ResetPassword: React.FC = () => {
  /**
   * Get params on load.
   */
  const { username, code }: any = useParams();
  const history = useHistory();

  /**
   * useAuth for forgot password.
   */
  const { forgotPasswordSubmit } = useAuthContext();

  const { mutate, isLoading } = useMutation(
    async (values: {
      password: string;
      passwordConfirmation: string;
    }): Promise<any> => {
      await forgotPasswordSubmit({ username, code, ...values });
      history.push("/auth/login");
    },
    {
      onSuccess: () => {
        toasts.success({
          message: "Password has been reset. Taking you to login.",
        });
      },
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
    },
  );

  const [translate] = useTranslation("permissions");

  // Get Password Policy
  const { data: queryData } = useQuery(
    "password",
    async () => (await instance.users.getPasswordPolicy()).data,
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
    },
  );

  const formFields = [
    <InputPassword />,
    <InputPassword
      name="passwordConfirmation"
      translationKey="passwordConfirmation"
    />,
    <Button linkRoute="" buttonTranslationKey="save" loading={isLoading} />,
  ];
  return (
    <>
      <TitleReset />

      <Formik
        initialValues={initialValues}
        validationSchema={reset}
        onSubmit={(values) => mutate(values)}
      >
        {({ handleSubmit, values }) => {
          return (
            <>
              {!!queryData && (
                <PasswordRequirements
                  passwordPolicy={queryData}
                  password={values.password}
                />
              )}
              <form onSubmit={handleSubmit}>
                <Trail components={formFields} />
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
