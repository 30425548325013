import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FieldArray } from "formik";
import axios from "axios";
import { ApiRequestData, instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Link as GlobalLink,
  Message,
  Panel,
  Text,
  UnstyledButton,
  displayErrorMessage,
  theme,
  Modal,
} from "@clearabee/ui-library";
import { useScreenWidth } from "hooks";
import { getValidationSchema, initialValues } from "./validation";
import { readAddresses } from "api/libs";
import { postcodeRegExp } from "validation/common";

type PostCompanyBody = ApiRequestData<typeof instance.users.postCompanySignUp>;

const servicesData = ["Clearances", "Trade Waste", "Tipping", "Skip Hire"];

export const CompanySignup = (): React.ReactElement => {
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [translate] = useTranslation("companySignup");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const { isMobile, isDesktop, isLargeDesktop, isTablet } = useScreenWidth();

  const {
    isLoading: isAddressLoading,
    data: addressData,
    mutate: mutateGettingAddressByPostcode,
  } = useMutation(["getAddressByPostcode"], async (postcode: string) => {
    if (!postcode.match(postcodeRegExp) || postcode.length < 4) return [];

    const response = await readAddresses(postcode);

    return response.addresses.map((address) => ({
      label: `${address.line_1}, ${address.line_2}, ${address.county}, ${address.town_or_city}`,
      value: JSON.stringify(address),
    }));
  });

  const {
    mutate: mutateCompanySignup,
    isLoading: isLoadingCompanySignup,
    error: errorCompanySignup,
    isSuccess: isSuccessCompanySignup,
  } = useMutation(
    ["companySignup"],
    async (values: typeof initialValues) => {
      // remove non-letters and lowercase
      const companyName = values.companyName
        .replace(/[^a-z]+/gi, "")
        .toLowerCase();

      // get signature URI and file name
      const signatureURL = values.signature;
      const fileName = `${companyName}-signature.png`;
      const file = base64ToFile(signatureURL, fileName);

      // get presigned url
      const { fields, url: presignedURL } =
        (
          await axios({
            method: "PUT",
            url: `${process.env.REACT_APP_MS_API_BASE_URL}/companies/documents/${companyName}/${fileName}`,
            headers: {
              "x-api-key": process.env.REACT_APP_COMPANY_DOCUMENT_API_KEY || "",
            },
          })
        ).data || {};

      // upload image to s3
      const s3ImageURL = await uploadFileToS3(file, presignedURL, fields, {
        acl: "private",
      });

      const body: PostCompanyBody = {
        // Company details
        name: values.companyName.trim(),
        tradingName: values.tradingName.trim(),
        companyRegistrationNumber: values.companyRegistrationNumber ?? null,
        vatRegistrationNumber: values.vatRegistrationNumber ?? null,
        // primary contact
        contactName: `${values.primaryContact.firstName.trim()} ${values.primaryContact.lastName.trim()}`,
        contactEmail: values.primaryContact.email,
        contactPhoneNumber: values.primaryContact.phone,
        // finance contact
        contacts: [
          {
            name: values.financeContact.fullName.trim(),
            email: values.financeContact.email,
            phone: values.financeContact.phone,
            position: values.financeContact.position.trim(),
          },
        ],
        // address
        addressLine1: values.headOfficeAddress.street,
        addressCity: values.headOfficeAddress.city,
        addressPostcode: values.headOfficeAddress.postcode.trim(),
        // temporary password
        temporaryPassword: Math.random().toString(36).slice(-8),
        // company email domain and source
        settings: {
          companyEmailDomain: values.companyEmailDomain.trim(),
          hasEmailDomain: values.hasEmailDomain,
          source: "self-sign-up",
          bucketKey: companyName.replace(/[^a-z]+/gi, "").toLowerCase(),
          services: values.services,
          monthlyVolume: values.monthlyVolume,
        },
        // signature
        documents: [
          {
            description: "Company signature",
            name: fileName,
            url: s3ImageURL,
            type: "signature",
          },
        ],
        clientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || "",
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
      };

      // create new company
      await instance.axios({
        method: "POST",
        url: `${process.env.REACT_APP_MS_API_BASE_URL}/companies/signup`,
        data: body,
        headers: {
          Authorization: "",
        },
      });
    },
    {
      onSuccess: () => setIsSuccessModalOpen(true),
    },
  );

  const getSignatureCanvasSize = () => {
    let width = 0;

    if (isMobile) {
      width = 250;
    }
    if (isTablet) {
      width = 350;
    }
    if (isDesktop) {
      width = 450;
    }
    if (isLargeDesktop) {
      width = 550;
    }

    return {
      width,
      height: 200,
    };
  };

  return (
    <>
      <Form
        className="w-full flex justify-center items-center"
        initialValues={initialValues}
        validationSchema={getValidationSchema(isManualAddress)}
        enableReinitialize
        onSubmit={(values) => {
          mutateCompanySignup(values);
        }}
      >
        {({ values, setFieldValue, resetForm, errors, submitCount }) => {
          useEffect(() => {
            if (values.isSameContact) {
              setFieldValue(
                "financeContact.fullName",
                `${values.primaryContact.firstName.trim()} ${values.primaryContact.lastName.trim()}`,
              );
              setFieldValue(
                "financeContact.email",
                values.primaryContact.email,
              );
              setFieldValue(
                "financeContact.phone",
                values.primaryContact.phone,
              );
              return;
            }

            setFieldValue("financeContact.fullName", "");
            setFieldValue("financeContact.email", "");
            setFieldValue("financeContact.phone", "");
          }, [values.isSameContact]);

          useEffect(() => {
            if (isSuccessCompanySignup) {
              resetForm();
            }
          }, [isSuccessCompanySignup]);

          useEffect(() => {
            mutateGettingAddressByPostcode(values.headOfficeAddress.postcode);
          }, [values.headOfficeAddress.postcode]);

          return (
            <Box className="w-full p-5 md:w-2/3 md:px-0 md:my-8 lg:w-1/2">
              <Panel
                shadow={false}
                styles={{
                  width: "100%",
                  padding: `${theme.spacing.large} ${theme.spacing.xlarge}`,
                }}
              >
                <Link to={"auth/login"}>
                  <Box color="brand" className="flex items-center">
                    <Icon.Chevron
                      size="small"
                      styles={{
                        rotate: "180deg",
                      }}
                    />
                    <Box>{translate("buttons.backToLogin")}</Box>
                  </Box>
                </Link>
                {/* Main Heading */}
                <Box className="flex justify-center mb-5">
                  <Icon.Bee size="xlarge3" color="brand" />
                </Box>
                <Box className="flex justify-center">
                  <Heading level={2} color="brand">
                    {translate("form.headings.companySignup")}
                  </Heading>
                </Box>
                {/* Divider */}
                <Box className="border border-gray-500 my-5 opacity-20" />
                {/* Form Details */}
                {/* Sub Heading */}
                <Heading level={5} color="brand" styles={{ fontWeight: 900 }}>
                  {translate("form.headings.details")}
                </Heading>
                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="companyName"
                    label={translate("form.labels.companyName")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.companyName")}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="tradingName"
                    label={translate("form.labels.tradingName")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.tradingName")}
                      />
                    )}
                  </Field>
                </Box>

                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2 mb-3">
                  <Field
                    styles={{ flex: 1, marginBottom: theme.spacing.xsmall }}
                    name="companyRegistrationNumber"
                    label={translate("form.labels.companyRegistrationNumber")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "form.placeholders.companyRegistrationNumber",
                        )}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{ flex: 1, marginBottom: theme.spacing.xsmall }}
                    name="vatRegistrationNumber"
                    label={translate("form.labels.vatRegistrationNumber")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "form.placeholders.vatRegistrationNumber",
                        )}
                      />
                    )}
                  </Field>
                </Box>

                <Box className="flex flex-col xl:flex-row">
                  <FieldArray
                    name="services"
                    render={() => (
                      <Box
                        role="group"
                        aria-labelledby="checkbox-group"
                        className="flex flex-col flex-wrap w-full xl:w-1/2"
                      >
                        <Text className="font-bold">
                          {translate("form.labels.services")}
                        </Text>
                        <Box className="flex flex-row flex-wrap">
                          {servicesData.map((item, index) => (
                            <Box className="flex w-1/2" key={index}>
                              <Field name="services">
                                {({ field }) => {
                                  return (
                                    <Input.Checkbox
                                      {...field}
                                      label={item}
                                      value={item}
                                    />
                                  );
                                }}
                              </Field>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  />

                  <Field
                    styles={{
                      width: isLargeDesktop ? "50%" : "100%",
                      marginBottom: theme.spacing.small,
                    }}
                    label={translate("form.labels.monthlyVolume")}
                    name="monthlyVolume"
                  >
                    {({ field }) => (
                      <Input.Text {...field} placeholder={"eg: 33"} />
                    )}
                  </Field>
                </Box>

                <Box className="flex flex-col gap-y-2 xl:flex-row xl:items-center xl:gap-x-2 my-8">
                  <Text
                    fontSize="base"
                    styles={{
                      fontWeight: 600,
                    }}
                  >
                    {translate("form.headings.doYouHaveEmailDomain")}
                  </Text>
                  <Field
                    styles={{
                      flex: 1,
                      margin: 0,
                    }}
                    name="hasEmailDomain"
                  >
                    {({ field }) => (
                      <Input.Toggle
                        {...field}
                        size={10}
                        color="accent"
                        checked={values.hasEmailDomain}
                        onChange={(value) => {
                          const isChecked = value.target.checked;

                          if (!isChecked) {
                            setFieldValue("companyEmailDomain", "");
                          }

                          setFieldValue("hasEmailDomain", isChecked);
                        }}
                      />
                    )}
                  </Field>
                </Box>

                <Field
                  styles={{
                    flex: 1,
                    marginBottom: theme.spacing.large,
                  }}
                  name="companyEmailDomain"
                  label={translate("form.labels.companyEmailDomain")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "form.placeholders.companyEmailDomain",
                      )}
                      disabled={!values.hasEmailDomain}
                    />
                  )}
                </Field>

                {/* Sub Heading */}
                <Heading level={5} color="brand" styles={{ fontWeight: 900 }}>
                  {translate("form.headings.headOfficeAddress")}
                </Heading>

                {isManualAddress && (
                  <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                    <Field
                      styles={{ flex: 1, marginBottom: theme.spacing.xsmall2 }}
                      name="headOfficeAddress.street"
                      label={translate("form.labels.street")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("form.placeholders.street")}
                        />
                      )}
                    </Field>
                    <Field
                      styles={{ flex: 1, marginBottom: theme.spacing.xsmall2 }}
                      name="headOfficeAddress.city"
                      label={translate("form.labels.city")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate("form.placeholders.city")}
                        />
                      )}
                    </Field>
                  </Box>
                )}

                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      width: isLargeDesktop ? "50%" : "100%",
                      marginBottom: theme.spacing.xsmall2,
                    }}
                    name="headOfficeAddress.postcode"
                    label={translate("form.labels.postcode")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.postcode")}
                        onChange={(e) => {
                          setFieldValue(
                            "headOfficeAddress.postcode",
                            e.target.value,
                          );
                          setFieldValue("headOfficeAddress.street", "");
                          setFieldValue("headOfficeAddress.city", "");
                          setFieldValue("headOfficeAddress.address", "");
                        }}
                      />
                    )}
                  </Field>
                  {!isManualAddress && (
                    <Field
                      styles={{
                        width: isLargeDesktop ? "50%" : "100%",
                        marginBottom: theme.spacing.xsmall2,
                      }}
                      name="headOfficeAddress.address"
                      label={translate("form.labels.address")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isSearchable
                          isClearable
                          isLoading={isAddressLoading}
                          options={addressData ?? []}
                          placeholder={translate("form.placeholders.address")}
                          disabled={
                            !values.headOfficeAddress.postcode ||
                            isAddressLoading ||
                            !addressData?.length
                          }
                          onChange={(event) => {
                            const targetValue = event.target.value;

                            if (!targetValue) {
                              setFieldValue("headOfficeAddress.street", "");
                              setFieldValue("headOfficeAddress.city", "");
                              setFieldValue("headOfficeAddress.address", "");
                              return;
                            }

                            const address = JSON.parse(targetValue);

                            setFieldValue(
                              "headOfficeAddress.street",
                              `${address.line_1}, ${address.line_2}`,
                            );

                            setFieldValue(
                              "headOfficeAddress.city",
                              address?.town_or_city ?? "",
                            );

                            setFieldValue(
                              "headOfficeAddress.address",
                              targetValue,
                            );
                          }}
                        />
                      )}
                    </Field>
                  )}
                </Box>

                <Box className="flex justify-center my-3">
                  <UnstyledButton
                    styles={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: theme.colors.brand.base,
                      textUnderlineOffset: theme.spacing.xsmall2,
                    }}
                    onClick={() => setIsManualAddress(!isManualAddress)}
                  >
                    {translate(
                      `postcodeLookup.${
                        isManualAddress
                          ? "enterYourPostcode"
                          : "cantFindAddress"
                      }`,
                    )}
                  </UnstyledButton>
                </Box>

                {/* Sub Heading */}
                <Heading level={5} color="brand" styles={{ fontWeight: 900 }}>
                  {translate("form.headings.primaryContact")}
                </Heading>
                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="primaryContact.firstName"
                    label={translate("form.labels.firstName")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.firstName")}
                        onChange={(e) => {
                          setFieldValue(
                            "primaryContact.firstName",
                            e.target.value,
                          );
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="primaryContact.lastName"
                    label={translate("form.labels.lastName")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.lastName")}
                        onChange={(e) => {
                          setFieldValue(
                            "primaryContact.lastName",
                            e.target.value,
                          );
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="primaryContact.email"
                    label={translate("form.labels.email")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.email")}
                        onChange={(e) => {
                          if (values.isSameContact) {
                            setFieldValue(
                              "financeContact.email",
                              e.target.value,
                            );
                          }
                          setFieldValue("primaryContact.email", e.target.value);
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="primaryContact.phone"
                    label={translate("form.labels.phone")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.phone")}
                        onChange={(e) => {
                          if (values.isSameContact) {
                            setFieldValue(
                              "financeContact.phone",
                              e.target.value,
                            );
                          }
                          setFieldValue("primaryContact.phone", e.target.value);
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box className="flex flex-col gap-y-2 xl:flex-row xl:items-center xl:gap-x-2 my-8">
                  <Text
                    fontSize="base"
                    styles={{
                      fontWeight: 600,
                    }}
                  >
                    {translate("form.headings.isSameContact")}
                  </Text>
                  <Field
                    styles={{
                      flex: 1,
                      margin: 0,
                    }}
                    name="isSameContact"
                  >
                    {({ field }) => (
                      <Input.Toggle
                        {...field}
                        size={10}
                        color="accent"
                        checked={values.isSameContact}
                      />
                    )}
                  </Field>
                </Box>
                {/* Sub Heading */}
                <Heading
                  level={5}
                  color="brand"
                  styles={{
                    fontWeight: 900,
                  }}
                >
                  {translate("form.headings.financeContact")}
                </Heading>
                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="financeContact.fullName"
                    label={`${translate("form.labels.fullName")}*`}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        disabled={values.isSameContact}
                        placeholder={translate("form.placeholders.fullName")}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="financeContact.position"
                    label={translate("form.labels.position")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate("form.placeholders.position")}
                      />
                    )}
                  </Field>
                </Box>
                <Box className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-x-2">
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="financeContact.email"
                    label={`${translate("form.labels.email")}*`}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        disabled={values.isSameContact}
                        placeholder={translate("form.placeholders.email")}
                      />
                    )}
                  </Field>
                  <Field
                    styles={{
                      flex: 1,
                      marginBottom: theme.spacing.xsmall,
                    }}
                    name="financeContact.phone"
                    label={`${translate("form.labels.phone")}*`}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        disabled={values.isSameContact}
                        placeholder={translate("form.placeholders.phone")}
                      />
                    )}
                  </Field>
                </Box>
                <Field name="agreeConditions" className="relative">
                  {({ field }) => (
                    <Box className="flex flex-row items-center gap-x-1">
                      <Input.Checkbox
                        {...field}
                        checked={values.agreeConditions}
                      />
                      <Box className="flex flex-col xl:flex-row xl:gap-x-1">
                        <Text fontSize="large">
                          {translate("agreeTermText1")}
                        </Text>
                        <GlobalLink
                          onClick={() =>
                            window.open("https://www.clearabee.co.uk/terms")
                          }
                        >
                          <Text fontSize="large">
                            {" "}
                            {translate("agreeTermText2")}
                          </Text>
                        </GlobalLink>
                      </Box>
                    </Box>
                  )}
                </Field>
                <Field name="signature">
                  <Box
                    className="border-gray-200 border-2 rounded-md relative"
                    styles={getSignatureCanvasSize()}
                  >
                    <Box className="absolute right-0 p-1">
                      <UnstyledButton
                        onClick={() => {
                          signatureRef.current?.clear();
                          setFieldValue("signature", "");
                        }}
                      >
                        <Icon.Refresh size="medium" color="greyscale" />
                      </UnstyledButton>
                    </Box>
                    <Box className="absolute bottom-0 left-0 p-1 opacity-40">
                      <Text color="greyscale" fontSize="xsmall">
                        {translate("form.labels.signature")}
                      </Text>
                    </Box>
                    {!!values.signature && (
                      <Box className="w-full h-full flex justify-center items-center relative">
                        <img
                          className="rounded-lg"
                          src={values.signature}
                          alt="signature"
                          style={{
                            width: "50%",
                            height: "50%",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                    )}
                    {!values.signature && (
                      <SignatureCanvas
                        ref={(ref) => {
                          signatureRef.current = ref;
                        }}
                        onEnd={() => {
                          setFieldValue(
                            "signature",
                            signatureRef.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          );
                        }}
                        penColor="black"
                        canvasProps={{
                          className: "w-full h-full",
                        }}
                      />
                    )}
                  </Box>
                </Field>
                {/* Submit Form */}
                <Box className="flex justify-center mt-8">
                  <Button
                    size="medium"
                    type="submit"
                    color="warning"
                    disabled={isLoadingCompanySignup}
                  >
                    <Box className="flex justify-center items-center gap-x-2">
                      <Text> {translate("buttons.submit")}</Text>
                      {isLoadingCompanySignup && <Icon.Loading size="small" />}
                    </Box>
                  </Button>
                </Box>

                {/* Display Error */}
                {!!errorCompanySignup &&
                  displayErrorMessage(
                    errorCompanySignup ?? translate("errorMessage"),
                    ({ children }) => (
                      <Box className="flex flex-col items-center mt-2">
                        <Message type="error" background>
                          {children}
                        </Message>
                      </Box>
                    ),
                  )}
                {/* Display Form Fields Error */}
                {!!submitCount &&
                  !!Object.keys(errors).length &&
                  displayErrorMessage(
                    translate("errorFieldsRequired"),
                    ({ children }) => (
                      <Box className="flex flex-col items-center mt-2">
                        <Message
                          type="error"
                          styles={{
                            textAlign: "center",
                          }}
                        >
                          {children}
                        </Message>
                      </Box>
                    ),
                  )}
              </Panel>
            </Box>
          );
        }}
      </Form>
      {isSuccessModalOpen && (
        <Modal
          width={600}
          styles={{
            textAlign: "center",
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge} ${theme.spacing.xlarge2} ${theme.spacing.xlarge} ${theme.spacing.xlarge2}`,
            },
          }}
        >
          {/* Display Success */}
          <Box className="flex flex-col items-center gap-y-6">
            <Heading
              level={2}
              styles={{
                color: theme.colors.accent.dark,
              }}
            >
              {translate("form.headings.success")}
            </Heading>
            <Text>{translate("successMessage")}</Text>
            <Button type="button" size="small" color="warning">
              <Link to="/auth/login">{translate("buttons.backToLogin")}</Link>
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

// upload file to s3
const uploadFileToS3 = async (
  file: File,
  presignedURL: string,
  fields: { [key: string]: string },
  options?: { acl?: string },
): Promise<string> => {
  const form = new FormData();

  /**
   * Add returned fields from our presigned url as form-data fields.
   */
  Object.entries(fields).forEach(([field, value]) => {
    form.append(field, value);
  });

  /**
   * Add extra fields required.
   */

  form.append("acl", options?.acl || "public-read");
  form.append("success_action_status", "2xx");
  form.append("file", file);

  const s3Upload = await axios({
    method: "POST",
    url: presignedURL,
    data: form,
    headers: {
      Authorization: "",
      "Content-Type": "multipart/form-data",
    },
  });

  return s3Upload.headers.location;
};

// convert base64 to file
const base64ToFile = (url: string, fileName: string): File => {
  const format = url.substring(
    url.indexOf("data:") + 5,
    url.indexOf(";base64"),
  );
  const arr = url.split(",");

  const data = arr[1];

  const dataStr = atob(data);
  let n = dataStr.length;
  const dataArr = new Uint8Array(n);

  while (n--) {
    dataArr[n] = dataStr.charCodeAt(n);
  }

  const file = new File([dataArr], fileName, { type: format });

  return file;
};
