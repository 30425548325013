import { hexToRgba, theme, StyleSheet } from "@clearabee/ui-library";

export const styles = StyleSheet({
  container: {
    position: "relative",
  },
  inputContainer: {
    position: "relative",
  },
  input: {
    ...theme.fontDefaults.small,
    width: "100%",
    boxSizing: "border-box",
    minHeight: theme.spacing.xlarge,
    padding: theme.spacing.xsmall,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
    borderRadius: theme.spacing.xsmall2,
    outline: "none",

    ":focus": {
      borderColor: theme.colors.focus.base,
    },
    ":disabled": {
      color: theme.colors.greyscale.lighter,
      borderColor: theme.colors.greyscale.lightest,
      backgroundColor: hexToRgba(theme.colors.greyscale.lightest, 0.4),
    },
  },
  inputPrefix: {
    padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
    paddingLeft: theme.spacing.medium,
  },
  error: {
    borderColor: theme.colors.negative.base,
  },
  success: {
    borderColor: theme.colors.positive.base,
  },
  prefix: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: theme.spacing.xsmall,
    userSelect: "none",
  },
  eyeButton: {
    position: "absolute",
    right: "0",
    top: "0",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing.xsmall,
    ":focus-within": {
      boxShadow: "none",
      outline: "none",
    },
  },
});
