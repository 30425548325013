import React from "react";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import axios from "axios";
import dayjs from "dayjs";
import {
  Modal,
  Heading,
  theme,
  Form,
  Field,
  Input,
  Box,
  Icon,
  Text,
  Button,
  UnstyledButton,
  Message,
  displayErrorMessage,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { ICompany, IReportSchedule } from "@clearabee/api-schemas";
import { toasts } from "helpers";
import { useAuthContext } from "hooks";
import { validationSchema } from "./validation";

const modalStyles = {
  [`@media (min-width: ${theme.screens.medium})`]: {
    padding: `${theme.spacing.large} ${theme.spacing.large}`,
  },
};

interface IScheduledReport {
  isVisible: boolean;
  report?: IReportSchedule;
  onClose: () => void;
  onSubmit: () => void;
}

const rangeOptions = [
  {
    label: "Daily",
    value: "-1D",
  },
  {
    label: "Last 7 days",
    value: "-7D",
  },
  {
    label: "Last Week",
    value: "-1W",
  },
  {
    label: "Last 30 days",
    value: "-30D",
  },
  {
    label: "Last Month",
    value: "-1M",
  },
];

export const ScheduledReport = ({
  isVisible,
  report,
  onClose,
  onSubmit,
}: IScheduledReport): React.ReactElement => {
  const { getCurrentUserCompanies } = useAuthContext();
  const [translate] = useTranslation("reporting");
  const { data: allCompanies, isLoading: isLoadingAllCompanies } = useQuery(
    "getAllCompanies",
    async () =>
      (
        await axios.get("/companies", {
          params: {
            limit: 5000,
          },
        })
      ).data.items,
  );

  const getSelectedCompanyCodes = (
    companies: typeof initialValues.companyCodes,
  ): string[] => {
    if (companies[0].value === "all") {
      return allCompanies.map((company: ICompany) => company.companyCode);
    }
    return companies.map((company) => company.value);
  };

  const companyCodes = report?.companyCodes.map((companyCode) => ({
    label:
      allCompanies.find(
        (company: ICompany) => company.companyCode === companyCode,
      )?.name ?? companyCode,
    value: companyCode,
  }));

  const allCompaniesCodes = allCompanies?.map(
    (company: ICompany) => company.companyCode,
  );

  const isAllCompaniesSecected =
    JSON.stringify(report?.companyCodes) === JSON.stringify(allCompaniesCodes);

  const defaultOptionValue = {
    label: "All Companies",
    value: "all",
  };

  const initialValues = {
    name: report?.name || "",
    companyCodes:
      !!companyCodes && !isAllCompaniesSecected
        ? companyCodes
        : [defaultOptionValue],
    receipientEmail: "",
    receipients: report?.receipients || [],
    range: report?.range
      ? {
          label:
            rangeOptions.find(({ value }) => value === report?.range)?.label ||
            "",
          value: report?.range || "",
        }
      : null,
    startDate: report?.startDate
      ? dayjs(report?.startDate).format("DD/MM/YYYY")
      : "",
    active: !!report ? report?.active : true,
  };

  const {
    isLoading,
    mutate: postReport,
    error: postReportError,
    reset: resetPostReport,
  } = useMutation(
    async (values: typeof initialValues) => {
      return await instance.reports.postScheduledReport({
        active: values.active,
        companyCodes: getSelectedCompanyCodes(values.companyCodes),
        name: values.name,
        range: String(values.range?.value),
        receipients: values.receipients,
        startDate: dayjs
          .utc(decodeURIComponent(values.startDate), "DD/MM/YYYY")
          .toISOString(),
      });
    },
    {
      onSuccess: () => {
        onSubmit();
        toasts.success({
          message: translate("scheduleReports.toasts.reportScheduled"),
        });
        onClose();
      },
    },
  );

  const {
    isLoading: isLoadingPatchReport,
    mutate: patchReport,
    error: patchReportError,
    reset: resetPatchReport,
  } = useMutation(
    async (values: typeof initialValues) => {
      return await instance.reports.patchScheduledReport(String(report?.id), {
        active: values.active,
        companyCodes: getSelectedCompanyCodes(values.companyCodes),
        name: values.name,
        range: values.range?.value,
        receipients: values.receipients,
        startDate: dayjs
          .utc(decodeURIComponent(values.startDate), "DD/MM/YYYY")
          .toISOString(),
      });
    },
    {
      onSuccess: () => {
        onSubmit();
        toasts.success({
          message: translate("scheduleReports.toasts.reportUpdated"),
        });
      },
    },
  );

  const companiesOptions = !!allCompanies
    ? allCompanies.map((company: ICompany) => ({
        label: company.name,
        value: company.companyCode,
      }))
    : getCurrentUserCompanies().map((company) => ({
        label: company.name,
        value: company.companyCode,
      }));

  return (
    <Modal
      modalVisible={isVisible}
      onClose={() => {
        onClose();
        resetPostReport();
        resetPatchReport();
      }}
      styles={modalStyles}
      width={700}
    >
      <Heading color="brand" fontSize="xlarge3">
        {!!report
          ? translate("scheduleReports.headings.updateScheduledReport")
          : translate("scheduleReports.headings.addScheduledReport")}
      </Heading>
      {isLoadingAllCompanies ? (
        <Box className="flex justify-center my-10">
          <Icon.Loading color="brand" />
        </Box>
      ) : (
        <Box
          styles={{
            maxHeight: theme.screens.medium,
          }}
          className="overflow-scroll"
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            className="text-left"
            onSubmit={(values) => {
              if (!!report) {
                patchReport(values);
              } else {
                postReport(values);
              }
            }}
          >
            {({ values, setFieldValue, errors, submitCount }) => (
              <>
                <Box className="grid grid-cols-2 gap-x-5">
                  <Field name="name" label="Name">
                    {({ field }) => (
                      <Input.Text {...field} placeholder="Enter name" />
                    )}
                  </Field>
                  <Field name="range" label="Range">
                    {({ field }) => (
                      <Select
                        {...field}
                        options={rangeOptions}
                        placeholder="Enter range"
                      />
                    )}
                  </Field>
                  <Box className="col-span-2">
                    <Field name="companyCodes" label="Company Codes">
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isMulti
                          closeMenuOnSelect={false}
                          options={[defaultOptionValue, ...companiesOptions]}
                          onChange={(value: typeof defaultOptionValue[]) => {
                            const isAllSelected = !!value?.some(
                              (item) => item.value === defaultOptionValue.value,
                            );

                            if (isAllSelected) {
                              setFieldValue("companyCodes", [
                                defaultOptionValue,
                              ]);
                            } else {
                              setFieldValue("companyCodes", value ?? []);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                  <Field name="startDate" label="Start Date">
                    {({ field }) => (
                      <Input.Date
                        {...field}
                        collapsable
                        disabledDays={{ before: dayjs().toDate() }}
                      />
                    )}
                  </Field>
                  <Field
                    name="active"
                    label="Active"
                    styles={{
                      maxWidth: theme.spacing.xlarge,
                    }}
                  >
                    {({ field }) => (
                      <Input.Toggle {...field} checked={values.active} />
                    )}
                  </Field>
                </Box>
                <Box className="flex gap-3 -mb-5 items-center">
                  <Box className="flex-grow min-h-32">
                    <Field name="receipientEmail" label="Receipient Email">
                      {({ field }) => <Input.Text {...field} />}
                    </Field>
                  </Box>
                  <Button
                    color="accent"
                    className="-mt-2"
                    disabled={
                      !values.receipientEmail ||
                      values.receipients.includes(values.receipientEmail)
                    }
                    type="button"
                    onClick={() => {
                      if (!errors.receipientEmail) {
                        setFieldValue("receipients", [
                          ...values.receipients,
                          values.receipientEmail,
                        ]);
                        setFieldValue("receipientEmail", "");
                      }
                    }}
                    size="small"
                  >
                    {translate("scheduleReports.buttons.add")}
                  </Button>
                </Box>
                <Text fontSize="small" className="font-semibold">
                  {translate("scheduleReports.labels.receipients")}
                </Text>
                <Box
                  className="w-full min-h-16 mb-1 gap-2 items-start flex flex-wrap rounded-md p-3"
                  backgroundColor="greyscale.lightest"
                >
                  {values.receipients.map((receipient, index) => (
                    <Box
                      key={index}
                      backgroundColor="warning"
                      className="flex items-center font-semibold gap-2 py-2 px-3 rounded-full"
                    >
                      <Text fontSize="small">{receipient}</Text>
                      <UnstyledButton
                        type="button"
                        onClick={() =>
                          setFieldValue(
                            "receipients",
                            values.receipients.filter(
                              (_, receipientIndex) => receipientIndex !== index,
                            ),
                          )
                        }
                      >
                        <Box
                          backgroundColor="greyscale.light"
                          className="p-2 rounded-full flex justify-center items-center"
                        >
                          <Icon.Close size="xsmall" color="light" />
                        </Box>
                      </UnstyledButton>
                    </Box>
                  ))}
                </Box>
                {!!errors.receipients && submitCount > 0 && (
                  <Message small type="error">
                    {errors.receipients}
                  </Message>
                )}
                <Box className="flex flex-col mt-8 items-center justify-center">
                  <Box>
                    <Button
                      className="flex justify-center"
                      size="medium"
                      disabled={isLoading || isLoadingPatchReport}
                    >
                      {isLoading || isLoadingPatchReport ? (
                        <Icon.Loading size="small" />
                      ) : !!report ? (
                        translate("scheduleReports.buttons.update")
                      ) : (
                        translate("scheduleReports.buttons.schedule")
                      )}
                    </Button>
                  </Box>
                  {(!!postReportError || !!patchReportError) &&
                    displayErrorMessage(
                      postReportError || patchReportError,
                      ({ children }) => (
                        <Box className="mt-4">
                          <Message type="error" background>
                            {children}
                          </Message>
                        </Box>
                      ),
                    )}
                </Box>
              </>
            )}
          </Form>
        </Box>
      )}
    </Modal>
  );
};
