import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISupplierPrice } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import {
  Table,
  theme,
  Panel,
  Heading,
  Input,
  Button,
  Form,
  Field,
  Text,
  Box,
  Icon,
} from "@clearabee/ui-library";
import { IPaginatedResults, TFilters } from "api/types";
import { LoadingOverlay } from "components/common/components";
import { TableRow, AddSupplierPriceModal } from "./components";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { buildQuery } from "helpers/api";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { useScreenWidth } from "hooks/useScreenWidth";
import {
  searchForminitialValues as initialValues,
  searchFormSchema as validationSchema,
} from "./validation";

const activeOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const SuppliersPrices = (): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [total, setTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  const params = new URLSearchParams(location.search.replace("?", ""));

  /**
   *  function to read the suppliers prices and filter with pagination
   * @param filters
   * @param currentPage
   * @param limit
   * @returns
   */
  const readSuppliersPrices = async (
    filters: TFilters = "",
    currentPage = 0,
    limit = 8,
  ): Promise<IPaginatedResults<ISupplierPrice>> => {
    try {
      const filterParams = new URLSearchParams(filters);

      // suppliers prices filters
      const supplierRef = filterParams?.get("supplierRef:eq") ?? "";
      const sku = filterParams?.get("sku:eq") ?? "";
      const postcode = filterParams?.get("postcode:like") ?? "";
      const active = filterParams?.get("active:eq") ?? "";

      const { data } = await instance.catalogues.getSupplierPrices({
        params: {
          ...(!!supplierRef ? { "supplierRef:eq": supplierRef } : {}),
          ...(!!sku ? { "sku:eq": sku } : {}),
          ...(!!postcode
            ? { "postcode:like": `${postcode.replace(/\s/g, "")}%` }
            : {}),
          ...(!!active
            ? {
                "active:eq":
                  String(active).toLowerCase() === "true" ? "1" : "0",
              }
            : {}),
          limit,
          offset: currentPage * limit,
        },
      });

      const response = data as unknown as IPaginatedResults<ISupplierPrice>;
      setTotal(response?.pagination?.total || 0);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    query: {
      isFetching: isFetchingSuppliersPrices,
      isLoading: isLoadingSuppliersPrices,
      refetch: refetchSuppliersPrices,
      isSuccess: isSuccessSuppliersPrices,
    },
  } = usePaginatedQuery(
    readSuppliersPrices,
    "readSuppliersPricesPaginated",
    "",
    {
      resultOptions: [5, 10, 20, 50, 100],
      enabled: isSubmitting,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
      showRowsPerPage: true,
      initialResultSize: 20,
    },
    // paginationStyles
    paginationStyles,
  );

  /**
   * getInitialValues
   */
  const getInitialValues = (): typeof initialValues => {
    // suppliers prices filters
    const supplierRef = params?.get("supplierRef:eq") ?? "";
    const sku = params?.get("sku:eq") ?? "";
    const postcode = params?.get("postcode:like") ?? "";
    const active = params?.get("active:eq") ?? "";

    return {
      supplierRef,
      sku,
      postcode,
      active,
    } as typeof initialValues;
  };

  const handleSubmit = (values: typeof initialValues) => {
    setIsSubmitting(true);

    const { postcode, supplierRef, sku, active } = values;

    const updatedFilterObject = {
      // suppliers prices filters
      ...(!!supplierRef ? { "supplierRef:eq": supplierRef } : {}),
      ...(!!sku ? { "sku:eq": sku } : {}),
      ...(!!postcode
        ? {
            "postcode:like": postcode.replace(/\s/g, ""),
          }
        : {}),
      ...(!!active ? { "active:eq": active } : {}),
      page: 1,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    history.push(`/suppliers/prices?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    const doesIncludePageParam = currentUrlWithoutBase.includes("page=");

    if (!!doesIncludePageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase.replace(
        /page=\d+/g,
        `page=${currentPage}`,
      );

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
    }
  }, [currentPage]);

  /**
   * This useEffect is used to refetch suppliers prices when screen changes/ page refresh
   */
  useEffect(() => {
    const paramObject = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {},
    );

    if (Object.keys(paramObject).length === 0) return;

    setIsSubmitting(true);

    updateFilters(buildQuery(paramObject));
  }, []);

  return (
    <>
      {/* LOADING */}
      {(isLoadingSuppliersPrices || isFetchingSuppliersPrices) && (
        <LoadingOverlay />
      )}
      <Box className="max-w-screen-lg py-5 ml-auto mr-auto">
        <Form
          initialValues={getInitialValues()}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ resetForm, setFieldValue, values }) => (
            <>
              <Panel shadow={false}>
                <Box className="flex flex-col">
                  <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                    <Heading
                      level={5}
                      color="brand"
                      className="pr-4 w-full sm:w-auto"
                    >
                      {translate("supplierPrices.headings.supplierPrices")} (
                      {total})
                    </Heading>
                    <Box className="flex justify-end self-end col-start-2 gap-x-3">
                      {/* ADD modal */}
                      <AddSupplierPriceModal
                        refetchData={refetchSuppliersPrices}
                      />
                    </Box>
                  </Box>
                  <Box className="grid grid-rows-1 grid-cols-4 gap-2 mb-3">
                    {/* Supplier Ref */}
                    <Field
                      name="supplierRef"
                      label={translate(
                        "supplierPrices.form.labels.supplierRef",
                      )}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "supplierPrices.form.placeholders.supplierRef",
                          )}
                        />
                      )}
                    </Field>
                    {/* Sku */}
                    <Field
                      name="sku"
                      label={translate("supplierPrices.form.labels.sku")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "supplierPrices.form.placeholders.sku",
                          )}
                        />
                      )}
                    </Field>
                    {/* postcode */}
                    <Field
                      name="postcode"
                      label={translate("supplierPrices.form.labels.postcode")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "supplierPrices.form.placeholders.postcode",
                          )}
                        />
                      )}
                    </Field>
                    <Field
                      name="active"
                      label={translate("supplierPrices.form.labels.active")}
                      styles={{ margin: theme.spacing.xsmall }}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          placeholder={translate(
                            "supplierPrices.form.placeholders.selectOption",
                          )}
                          options={activeOptions}
                          value={
                            activeOptions?.find(
                              ({ value }) =>
                                value === String(values.active).toLowerCase(),
                            ) ?? null
                          }
                          className="text-base"
                          onChange={(option) => {
                            if (option === null)
                              return setFieldValue("active", "");

                            setFieldValue("active", option?.value);
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                  {/* BUTTONS */}
                  <Box className="flex justify-end self-end col-start-2 gap-x-3">
                    {/* RESET */}
                    <Button
                      size="small"
                      color="negative"
                      disabled={isLoadingSuppliersPrices}
                      type="reset"
                      onClick={() => {
                        resetForm();
                        updateFilters("");
                        setCurrentPage(1);
                        history.push("/suppliers/prices");
                      }}
                    >
                      {translate("supplierPrices.buttons.reset")}
                    </Button>
                    {/* SUMBIT */}
                    <Button
                      size="small"
                      color="accent"
                      type="submit"
                      disabled={isLoadingSuppliersPrices}
                      className="flex items-center gap-x-1"
                    >
                      <Icon.Search2 size="small" />
                      {translate("supplierPrices.buttons.search")}
                    </Button>
                  </Box>
                </Box>
              </Panel>
              {!paginatedData.length &&
                !isLoadingSuppliersPrices &&
                isSuccessSuppliersPrices && (
                  <Panel className="flex justify-center flex-row gap-1 mt-5">
                    <Text>
                      {translate("supplierPrices.errors.noSupplierPrices")}
                    </Text>
                  </Panel>
                )}
            </>
          )}
        </Form>
        {/* SUPPLIERS PRICES */}
        {!!paginatedData.length && (
          <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <Table.Header
              fontSize={isDesktop ? "xsmall" : "xsmall2"}
              headings={[
                translate("supplierPrices.table.headings.supplierRef"),
                translate("supplierPrices.table.headings.sku"),
                translate("supplierPrices.table.headings.postcode"),
                translate("supplierPrices.table.headings.price"),
                translate("supplierPrices.table.headings.active"),
                translate("supplierPrices.table.headings.actions"),
              ]}
            />
            <Table.Body>
              {paginatedData.map((supplierPrice, index) => {
                return (
                  <TableRow
                    key={index}
                    supplierPrice={supplierPrice}
                    refetchData={refetchSuppliersPrices}
                  />
                );
              })}
            </Table.Body>
          </Table>
        )}

        {!!paginatedData.length && (
          <Box className="mt-10">
            <PaginationComponent />
          </Box>
        )}
      </Box>
    </>
  );
};
