import React, { useMemo } from "react";
import { instance } from "@clearabee/ui-sdk";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as Yup from "yup";
import { useMutation } from "react-query";
import Select from "react-select";
import { IVehicleScheduleResponse } from "@clearabee/api-schemas";
import {
  Box,
  Heading,
  Text,
  theme,
  Button,
  Input,
  Field,
  Form,
  Modal,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "components/common/components";
import { toasts } from "helpers/toasts";
import { stringRequired, optionField } from "validation/common";

dayjs.extend(utc);

interface CreateStartOfDayJobsModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  dates: string[];
  schedule: IVehicleScheduleResponse[];
}

interface InitialValuesProps {
  vehicles: Array<{ value: number; label: string }>;
  date: string;
}

const initialValues: InitialValuesProps = {
  date: "",
  vehicles: [],
};

const validationSchema = Yup.object().shape({
  date: stringRequired,
  vehicles: Yup.array().of(optionField).nullable(),
});

export const CreateStartOfDayJobsModal = ({
  dates,
  schedule,
  showModal,
  setShowModal,
}: CreateStartOfDayJobsModalProps): React.ReactElement => {
  const { mutate, isLoading } = useMutation(
    ["createStartOfDayJobs"],
    async (values: { date: string; vehicles: number[] }) =>
      await instance.vehicles.postScheduleTasks({
        date: dayjs.utc(values.date).toISOString(),
        vehicleIds: values.vehicles,
      }),
    {
      onSuccess: () => {
        setShowModal(false);
        toasts.success({
          message: "Sent request to create start and end of day jobs",
        });
      },
      onError: () => {
        toasts.error({
          message:
            "An error occurred while trying to create start and end of day jobs, please try again",
        });
      },
    },
  );

  /**
   * Date options
   */
  const dateOptions = useMemo(() => {
    if (!dates.length) return [];

    return dates.map((date) => ({
      value: dayjs.utc(date).format("YYYY-MM-DD"),
      label: dayjs.utc(date).format("DD/MM/YYYY"),
    }));
  }, [dates]);

  /**
   * Vehicle options
   */
  const vehicleOptions = useMemo(() => {
    if (!schedule.length) return [];

    return schedule.map((scheduleitem) => ({
      value: scheduleitem.vehicleId,
      label: scheduleitem.name,
    }));
  }, [schedule]);

  return (
    <>
      {showModal && (
        <Modal
          width={700}
          styles={{
            padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
            paddingBottom: theme.spacing.xlarge,
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `${theme.spacing.xlarge3} ${theme.spacing.large}`,
              paddingBottom: theme.spacing.xlarge,
            },
          }}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mutate({
                date: dayjs.utc(values.date).toISOString(),
                vehicles:
                  values?.vehicles?.map((vehicle) => vehicle.value) || [],
              });
            }}
          >
            {/* MODAL LOADING OVERLAY */}
            {isLoading && (
              <LoadingOverlay
                iconSize="xlarge2"
                backgroundLoadingContainerStyles={{
                  borderRadius: theme.spacing.small,
                }}
              />
            )}
            <Box className="flex flex-col items-center gap-y-3 text-left">
              <Heading color="brand" level={4}>
                Create Jobs
              </Heading>
              <Text className="px-4 text-center">
                This will trigger the flow that creates both the start of day
                and end of day jobs for the desired date. The start of day time
                and message will be informed by the schedule planner
              </Text>
              {/* DATE */}
              <Box className="w-1/2">
                <Field name="date" label="Date">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isClearable
                      placeholder="Select a date"
                      options={dateOptions}
                    />
                  )}
                </Field>
              </Box>

              {/* VEHICLES */}
              <Box className="w-1/2">
                <Field
                  styles={{ marginBottom: theme.spacing.xsmall2 }}
                  name="vehicles"
                  label="Vehicles"
                >
                  {({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      isClearable
                      placeholder="Select vehicles"
                      options={vehicleOptions}
                    />
                  )}
                </Field>
                <Text className="text-left" fontSize="xsmall">
                  Leave empty to amend all vehicle&apos;s jobs
                </Text>
              </Box>

              {/* SUBMIT */}
              <Box className="mx-auto mt-5">
                <Button type="submit" color="accent" size="small">
                  Create
                </Button>
              </Box>
            </Box>
          </Form>
        </Modal>
      )}
    </>
  );
};
