import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { instance } from "@clearabee/ui-sdk";
import { IJob } from "@clearabee/api-schemas";
import {
  Heading,
  Button,
  Text,
  Box,
  theme,
  Icon,
  Panel,
  Form,
  Field,
  Input,
  Message,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";
import { DisplayJob } from "./components/DisplayJobs";

dayjs.extend(utc);

type BigChangeJob = {
  Asset: string;
  Contact: string;
  ContactGroupId: number;
  ContactId: number;
  ContactRef: string;
  Created: string;
  Duration: number;
  JobId: number;
  Location: string;
  PlannedEnd: string;
  PlannedStart: string;
  Postcode: string;
  Ref: string;
  Status: string;
  StatusId: number;
  Type: string;
  TypeId: number;
};

type BigChangeStatusHookBody = {
  bcJobId: number;
  statusId: number;
  jobDate: string;
  asset: string | null;
  plannedStartTime: string;
};

const initialValues = {
  reference: "",
};

const validationSchema = Yup.object().shape({
  reference: Yup.string().required(),
});

type FormValues = {
  reference: string;
};

export const QuickSync = (): React.ReactElement => {
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>();
  const [foundBigChangeJob, setFoundBigChangeJob] = useState<BigChangeJob>();
  const [foundDataBaseJob, setFoundDataBaseJob] = useState<IJob>();

  const handleSubmit = async (values: FormValues) => {
    setErrors([]);
    setWarnings([]);
    setFormValues(values);
  };

  const {
    isLoading: bigChangeJobIsLoading,
    refetch: refetchBigChangeJob,
    isRefetching: bigChangeJobIsRefetching,
  } = useQuery(
    ["getBigChangeJob", formValues],
    async () =>
      await instance.jobs.getJobsFromBigChange("Job", {
        params: {
          jobRef: formValues?.reference,
        },
      }),
    {
      retry: 1,
      cacheTime: 0,
      enabled: !!formValues,
      onSuccess: (data) => {
        if (data?.data.Code !== 0) {
          const errorMessage =
            "Something went wrong when fetching big change jobs";
          errors.includes(errorMessage)
            ? null
            : setErrors((prevErrors) => [...prevErrors, errorMessage]);
        }

        if (data?.data.Result === "No results") {
          const warningMessage = "no jobs found in BigChange";
          errors.includes(warningMessage)
            ? null
            : setWarnings((prevWarnings) => [...prevWarnings, warningMessage]);
        }

        if (data?.data.Code === 0 && data?.data.Result !== "No results") {
          setFoundBigChangeJob(data?.data.Result);
        }
      },
    },
  );

  const {
    isLoading: dataBaseJobIsLoading,
    refetch: refetchDataBaseJob,
    isRefetching: dataBaseJobIsRefetching,
  } = useQuery(
    ["getDatabaseJob", formValues],
    async () =>
      (await instance.jobs.getJobs({ params: { ref: formValues?.reference } }))
        .data.items,
    {
      retry: 1,
      cacheTime: 0,
      enabled: !!formValues,
      onError() {
        const errorMessage = "Something went wrong when fetching database jobs";
        errors.includes(errorMessage)
          ? null
          : setErrors((prevErrors) => [...prevErrors, errorMessage]);
      },
      onSuccess: (data) => {
        if (data?.length > 0) {
          setFoundDataBaseJob(data[0]);
        }
      },
    },
  );

  const {
    mutate: statusHookMutate,
    isLoading: statusHookIsLoading,
    isSuccess: statusHookIsSuccess,
    reset: resetStatusHook,
  } = useMutation(
    "statusHook",
    async (body: BigChangeStatusHookBody) =>
      await axios.post("/hooks/bigchange/status", body),
    {
      retry: 1,
      onError() {
        const errorMessage = "Something went wrong when syncing the job";
        errors.includes(errorMessage)
          ? null
          : setErrors((prevErrors) => [...prevErrors, errorMessage]);
      },
    },
  );

  const {
    mutate: unnassignAssetMutate,
    isLoading: unnassignAssetIsLoading,
    isSuccess: unnassignAssetIsSuccess,
    reset: resetUnnassignAsset,
  } = useMutation(
    "unnassignAsset",
    async (jobRef: string) =>
      await instance.jobs.patchJob(encodeURIComponent(jobRef), {
        asset: null,
      }),
    {
      retry: 1,
      onError() {
        const errorMessage = "Something went wrong when unassigning the asset";
        errors.includes(errorMessage)
          ? null
          : setErrors((prevErrors) => [...prevErrors, errorMessage]);
      },
      onSuccess() {
        refetchDataBaseJob();
      },
    },
  );

  return (
    <>
      {/* Loading */}
      {(bigChangeJobIsLoading ||
        bigChangeJobIsRefetching ||
        dataBaseJobIsLoading ||
        dataBaseJobIsRefetching ||
        statusHookIsLoading ||
        unnassignAssetIsLoading) && <LoadingOverlay />}

      <>
        <Box className="max-w-screen-2xl py-5 ml-auto mr-auto">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <>
                <Panel shadow={false}>
                  <Box className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                    <Heading
                      level={5}
                      color="brand"
                      className="pr-4 w-full sm:w-auto"
                    >
                      Quick Sync
                    </Heading>

                    <Box className="flex justify-end self-end col-start-3 gap-x-3">
                      <Button
                        onClick={() => {
                          resetForm();
                          setErrors([]);
                          setWarnings([]);
                          setFormValues(undefined);
                          setFoundBigChangeJob(undefined);
                          setFoundDataBaseJob(undefined);
                          resetUnnassignAsset();
                          resetStatusHook();
                        }}
                        size="small"
                        color="negative"
                        type="button"
                        disabled={!formValues}
                      >
                        Reset
                      </Button>

                      <Button
                        onClick={() => {
                          refetchBigChangeJob();
                          refetchDataBaseJob();
                          resetStatusHook();
                        }}
                        size="small"
                        color="accent"
                        type="button"
                        disabled={!formValues}
                      >
                        Refresh
                      </Button>

                      {/* SUMBIT */}
                      <Button
                        size="small"
                        color="brand"
                        type="submit"
                        className="flex items-center gap-x-1"
                      >
                        <Icon.Search2 size="small" />
                        Search
                      </Button>
                    </Box>
                  </Box>

                  <Box className="flex flex-row gap-6">
                    {/* REFERENCE */}
                    <Box className="w-1/3">
                      <Field
                        name="reference"
                        label="Reference"
                        styles={{ margin: theme.spacing.xsmall }}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            disabled={!!foundBigChangeJob}
                            placeholder="Reference"
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>

                  {/* WARNINGS */}
                  {warnings?.length > 0 && (
                    <Message className="mt-3" type="warn">
                      {warnings.join(` & `)}
                    </Message>
                  )}

                  {/* ERRORS */}
                  {errors?.length > 0 && (
                    <Message type="error" background>
                      {errors.join(` & `)}
                    </Message>
                  )}
                </Panel>
              </>
            )}
          </Form>
        </Box>

        {(foundBigChangeJob || foundDataBaseJob) && (
          <Panel shadow={false}>
            <Box className="flex flex-row justify-between border-b pb-4 mb-8">
              <Heading level={5} color="brand">
                Found Job
              </Heading>

              <Box className="flex flex-row justify-center items-center gap-3">
                {statusHookIsSuccess && (
                  <Box>
                    <Message type="success">Sync request sent</Message>
                  </Box>
                )}

                {unnassignAssetIsSuccess && (
                  <Box>
                    <Message type="success">Asset unassigned</Message>
                  </Box>
                )}

                {/* REFRESH DB JOB BUTTON */}
                <Box>
                  <Button
                    size="small"
                    color="accent"
                    onClick={() => {
                      refetchDataBaseJob();
                    }}
                  >
                    Refresh Database Job
                  </Button>
                </Box>

                {/* UNASSIGN ASSET BUTTON */}
                {((!!foundDataBaseJob &&
                  !foundBigChangeJob &&
                  foundDataBaseJob?.asset &&
                  !bigChangeJobIsLoading &&
                  !bigChangeJobIsRefetching) ||
                  (!!foundDataBaseJob &&
                    !foundBigChangeJob?.Asset &&
                    !!foundDataBaseJob?.asset)) && (
                  <Box>
                    <Button
                      size="small"
                      onClick={() => {
                        unnassignAssetMutate(foundDataBaseJob?.ref || "");
                      }}
                      disabled
                    >
                      Unassign Asset
                    </Button>

                    <Text color="negative" fontSize="xsmall">
                      feature coming soon
                    </Text>
                  </Box>
                )}

                {/* SYNC BUTTON */}
                {!!foundBigChangeJob && !!foundBigChangeJob.Asset && (
                  <Box>
                    <Button
                      size="small"
                      disabled={statusHookIsSuccess}
                      onClick={() => {
                        statusHookMutate({
                          bcJobId: foundBigChangeJob.JobId,
                          statusId: foundBigChangeJob.StatusId,
                          jobDate: dayjs
                            .utc(foundBigChangeJob.PlannedStart)
                            .format("YYYY-MM-DD"),
                          asset: foundBigChangeJob.Asset || null,
                          plannedStartTime: dayjs
                            .utc(foundBigChangeJob.PlannedStart)
                            .format("HH:mm:ss"),
                        });
                        refetchDataBaseJob();
                      }}
                    >
                      Sync
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="flex flex-row justify-start items-start gap-8">
              {foundBigChangeJob && (
                <DisplayJob
                  heading="BigChange Job"
                  job={{
                    reference: foundBigChangeJob.Ref,
                    jobId: foundBigChangeJob.JobId,
                    asset: foundBigChangeJob.Asset,
                    contact: foundBigChangeJob.Contact,
                    status: foundBigChangeJob.Status,
                    type: foundBigChangeJob.Type,
                    created: dayjs
                      .utc(foundBigChangeJob.Created)
                      .format("DD/MM/YYYY HH:mm"),
                    date: dayjs
                      .utc(foundBigChangeJob.PlannedStart)
                      .format("DD/MM/YYYY HH:mm"),
                    plannedStart: dayjs
                      .utc(foundBigChangeJob.PlannedStart)
                      .format("DD/MM/YYYY HH:mm"),
                    duration: foundBigChangeJob.Duration,
                    location: foundBigChangeJob.Location,
                    postcode: foundBigChangeJob.Postcode,
                  }}
                />
              )}

              {!!foundBigChangeJob && !!foundDataBaseJob && (
                <Box
                  styles={{
                    height: "480px",
                    width: "4px",
                    borderLeftWidth: "1px",
                  }}
                />
              )}

              {!!foundDataBaseJob && (
                <DisplayJob
                  heading="Database Job"
                  job={{
                    reference: foundDataBaseJob.ref,
                    jobId: foundDataBaseJob.bcId || "No BC ID",
                    asset: foundDataBaseJob?.asset || "No asset",
                    contact: foundDataBaseJob.contactFirstName
                      ? `${foundDataBaseJob.contactFirstName} ${foundDataBaseJob.contactLastName}`
                      : "No contact",
                    status: foundDataBaseJob.status,
                    type: foundDataBaseJob?.type || "No type",
                    created: foundDataBaseJob?.timestamp
                      ? dayjs
                          .utc(foundDataBaseJob?.timestamp)
                          .format("DD/MM/YYYY HH:mm")
                      : "",
                    date: dayjs
                      .utc(foundDataBaseJob.date)
                      .format("DD/MM/YYYY HH:mm"),
                    plannedStart: foundDataBaseJob?.plannedStartTime
                      ? dayjs
                          .utc(foundDataBaseJob?.plannedStartTime)
                          .format("DD/MM/YYYY HH:mm")
                      : "No planned start time",
                    duration: foundDataBaseJob?.duration || "No duration",
                    location: `${foundDataBaseJob.addressLine1}, ${foundDataBaseJob.addressCity}, ${foundDataBaseJob.addressPostcode}`,
                    postcode: foundDataBaseJob.addressPostcode,
                  }}
                />
              )}
            </Box>
          </Panel>
        )}
      </>
    </>
  );
};
