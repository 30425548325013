import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Form, Field, Button, Input, Box, theme } from "@clearabee/ui-library";
import { TFilters } from "../../../../api/types";
import { SearchIcon } from "images";
import { buildQuery } from "helpers/api";
import { readCompaniesForTables, readRoles } from "api";
import { useAuthContext } from "hooks";
import roles from "constants/roles";

interface UsersFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
}

interface InitialValuesProps {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  role: string;
}

// initial results per page for pagination
const initialResultsPerPage = 10;

export const UserFilters = ({
  isFetching,
  updateFilters,
  currentPage,
  setCurrentPage,
  resultsPerPage,
  setResultsPerPage,
}: UsersFiltersProps): React.ReactElement => {
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const history = useHistory();
  const [translate] = useTranslation("users");
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);

  const params = new URLSearchParams(location.search.replace("?", ""));

  const { isLoading, data } = useQuery(["readAllCompanies"], () =>
    readCompaniesForTables("", 0, 2000),
  );

  const { data: rolesForOptions, isLoading: isLoadingRoles } = useQuery(
    "readRoles",
    readRoles,
    {
      enabled: isClearabeeAdmin || isClearabeeManager,
    },
  );

  const companiesOptions = useMemo(() => {
    const allCompanies = data?.items;
    const currentUserCompanies = getCurrentUserCompanies();

    if (isClearabeeAdmin && !!allCompanies?.length)
      return allCompanies.map((company) => ({
        label: company.name,
        value: company.companyCode,
      }));

    if (isCompanyAdmin && !!currentUserCompanies?.length)
      return currentUserCompanies.map((company) => ({
        label: company.name,
        value: company.companyCode,
      }));

    return [];
  }, [isClearabeeAdmin, isCompanyAdmin, data?.items]);

  /**
   * getInitialValues
   */
  const getInitialValues = (): InitialValuesProps => {
    //companies filters
    const firstName = params?.get("firstName:like") ?? "";
    const lastName = params?.get("lastName:like") ?? "";
    const email = params?.get("email:like") ?? "";
    const company = params?.get("company:eq") ?? "";
    const role = params?.get("role:eq") ?? "";

    return {
      firstName,
      lastName,
      email,
      company,
      role,
    };
  };
  const initialValues = getInitialValues();

  const handleSubmit = (values: InitialValuesProps) => {
    const { firstName, lastName, email, company, role } = values;

    const updatedFilterObject = {
      ...(!!firstName ? { "firstName:like": firstName } : {}),
      ...(!!lastName ? { "lastName:like": lastName } : {}),
      ...(!!email ? { "email:like": email } : {}),
      ...(!!company ? { "company:eq": company } : {}),
      ...(!!role ? { "role:eq": role } : {}),
      page: 1,
      resultsPerPage: params.get("resultsPerPage")
        ? Number(params.get("resultsPerPage"))
        : initialResultsPerPage,
    };

    const queryParam = buildQuery(updatedFilterObject);
    updateFilters(queryParam);
    setCurrentPage(1);
    setResultsPerPage(updatedFilterObject.resultsPerPage);
    history.push(`/users?${queryParam}`);
  };

  /**
   * This useEffect is used to update the URL when the pagination component changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    // this is to check if the current url already has page and resultsPerPage params
    const doesIncludePageAndResultsPerPageParam = [
      "page=",
      "resultsPerPage=",
    ].every((value) => currentUrlWithoutBase.includes(value));

    if (!!doesIncludePageAndResultsPerPageParam) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase
        .replace(/page=\d+/g, `page=${currentPage}`)
        .replace(/resultsPerPage=\d+/g, `resultsPerPage=${resultsPerPage}`);

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
      return;
    }

    history.push(`${currentUrlWithoutBase}?page=1&resultsPerPage=10`);
  }, [currentPage, resultsPerPage]);

  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ resetForm, values }) => (
        <>
          <Box className="border-t border-grey-200 mt-4 mb-2" />
          <Box className="flex flex-wrap">
            <Box className="w-1/3">
              <Field
                styles={{ margin: theme.spacing.xsmall2 }}
                label="First Name"
                name="firstName"
              >
                {({ field }) => <Input.Text {...field} />}
              </Field>
            </Box>
            <Box className="w-1/3">
              <Field
                styles={{ margin: theme.spacing.xsmall2 }}
                name="lastName"
                label="Last Name"
              >
                {({ field }) => <Input.Text {...field} />}
              </Field>
            </Box>
            <Box className="w-1/3">
              <Field
                styles={{ margin: theme.spacing.xsmall2 }}
                name="email"
                label="Email"
              >
                {({ field }) => <Input.Text {...field} />}
              </Field>
            </Box>
            {(isClearabeeAdmin || isCompanyAdmin || isClearabeeManager) && (
              <Box className="w-1/3">
                <Field
                  styles={{ margin: theme.spacing.xsmall2 }}
                  name="company"
                  label="Company"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={values.company}
                      options={companiesOptions}
                      isLoading={isLoading}
                      isClearable
                      isSearchable
                    />
                  )}
                </Field>
              </Box>
            )}
            {(isClearabeeAdmin || isClearabeeManager) && (
              <Box className="w-1/3">
                <Field
                  styles={{ margin: theme.spacing.xsmall2 }}
                  name="role"
                  label="Role"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={values.role}
                      options={[
                        ...(rolesForOptions?.items.map(({ name }) => ({
                          label: name,
                          value: name,
                        })) || []),
                        { label: "Website User", value: "Website User" },
                      ]}
                      isLoading={isLoadingRoles}
                      isClearable
                      isSearchable
                    />
                  )}
                </Field>
              </Box>
            )}
            <Box className="flex flex-grow justify-end items-end">
              <Box className="mb-1 flex gap-2">
                <Button
                  type="submit"
                  size="small"
                  color="accent"
                  className="w-full flex justify-center items-center"
                  disabled={isFetching}
                >
                  <SearchIcon className="mr-2" />
                  {translate("common:form.buttons.search")}
                </Button>
                <Button
                  type="reset"
                  size="small"
                  variant="outline"
                  color="negative"
                  onClick={() => {
                    resetForm();
                    updateFilters("");
                    setCurrentPage(1);
                    setResultsPerPage(initialResultsPerPage);
                    history.push(
                      `/users?page=1&resultsPerPage=${initialResultsPerPage}`,
                    );
                  }}
                >
                  {translate("common:form.buttons.reset")}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Form>
  );
};
